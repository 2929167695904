import React, { useEffect, useState, useRef, useContext } from "react";
import Lottie from "react-lottie-player";
import FeatherIcon from "feather-icons-react";
import Xarrow from "react-xarrows";
import { NavLink, useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet";
import translate from "../../shared/utils/translations";
import * as THREE from "three";
import { TypeAnimation } from "react-type-animation";
import Marquee from "react-fast-marquee";
import ModalVideo from "react-modal-video";

const Home = ({ setPage }) => {
  let navigate = useNavigate();

  useEffect(() => {
    setPage("landing");
    currentLanguage(localStorage.getItem("lang") || "EN");
  });
  const box1Ref = useRef(null);
  const box2Ref = useRef(null);
  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // const loggedInUser = useContext(UserContext);
  // useEffect(() => {
  //   console.log(loggedInUser);
  //   if (loggedInUser) {
  //     if (loggedInUser?.role === "admin") {
  //       navigate("/admin-dashboard");
  //     } else {
  //       navigate("/client-dashboard");
  //     }
  //   }
  // }, [loggedInUser]);
  const [currentLang, currentLanguage] = useState({});
  const [isTab, setTab] = useState("is-market");
  const [isMDL, showMDL] = useState(false);
  const [isIMG, setIMG] = useState("");

  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const renderer = new THREE.WebGLRenderer({
      alpha: false,
      antialias: false,
    });
    renderer.setClearColor(0x000000, 0);
    renderer.setSize(container.offsetWidth, container.offsetHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.domElement.style.display = "block";

    const scene = new THREE.Scene();

    const camera = new THREE.PerspectiveCamera(
      75,
      container.offsetWidth / container.offsetHeight,
      0.1,
      1000
    );
    camera.position.z = 6;

    const sphereRadius =
      Math.min(container.offsetWidth, container.offsetHeight) / 200;
    const sphereGeometry = new THREE.SphereGeometry(sphereRadius, 32, 32);
    const sphereMaterial = new THREE.MeshLambertMaterial({ color: 0xffffff });
    const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
    scene.add(sphere);

    const loader = new THREE.TextureLoader();
    const overlayMaterial = new THREE.MeshBasicMaterial({
      map: loader.load("assets/img/texture.png"),
      transparent: false,
    });
    const overlaySphereGeometry = new THREE.SphereGeometry(
      sphereRadius * 1.0015,
      32,
      32
    );
    const overlaySphere = new THREE.Mesh(
      overlaySphereGeometry,
      overlayMaterial
    );
    sphere.add(overlaySphere);

    const animate = () => {
      sphere.rotation.y += 0.005;
      renderer.render(scene, camera);
      requestAnimationFrame(animate);
    };

    animate();

    const onWindowResize = () => {
      renderer.setSize(container.offsetWidth, container.offsetHeight);
      camera.aspect = container.offsetWidth / container.offsetHeight;
      camera.updateProjectionMatrix();
    };

    window.addEventListener("resize", onWindowResize);

    container.appendChild(renderer.domElement);

    return () => {
      window.removeEventListener("resize", onWindowResize);
      container.removeChild(renderer.domElement);
      renderer.dispose();
      sphereGeometry.dispose();
      sphereMaterial.dispose();
      overlaySphereGeometry.dispose();
      overlayMaterial.dispose();
    };
  }, []);

  const [isOpen, setOpen] = useState(false);
  const [isVideo, setVideo] = useState("aLVENf_FOBM");

  return (
    <>
      <Helmet>
        <title>
          {translate("Kepicker 🏆 Transform your Amazon Selling Journey!")}
        </title>
        <meta
          name="description"
          content={translate(
            "Say hello to Industry’s newest Amazon Product Research Tool. Compare all Amazon marketplaces - increase leads with Kepicker. Let's find the most profitable products!"
          )}
        />
      </Helmet>

      <ModalVideo
        channel="youtube"
        youtube={{ mute: 0, autoplay: 0 }}
        isOpen={isOpen}
        videoId={isVideo}
        onClose={() => setOpen(false)}
      />

      <div className={`outer__inner home_m ${currentLang}`}>
        <div className="section main main_kpcker">
          <div className="main__center center">
            <div className="main__preview">
              <div className="img_area">
                <img
                  src="/assets/img/smart_watch_roi.png"
                  className="obj_fade prd__11"
                ></img>
                <img
                  src="/assets/img/product_metrics_example.png"
                  className="obj_fade prd__12"
                ></img>
                <img
                  src="/assets/img/hazmat_product_amazon.png"
                  className="obj_fade prd__13"
                ></img>
                <img
                  src="/assets/img/kepicker_features_icn.png"
                  className="obj_fade prd__14"
                ></img>
                <div className="circless_pp"></div>
                <div className="hero__logo_card">
                  <div className="hero__logo">
                    <img src="/assets/img/logo.png"></img>
                  </div>
                </div>
              </div>
              <div className="main__wrap">
                <div className="main__info info bb bg_bx_div dkstp">
                  {translate("🏆 The Ultimate Amazon Product Research Tool!")}
                </div>
                <div className="main__info info bb bg_bx_div mblle">
                  <span className="vstry">🏆</span>
                  <span>
                    {translate("The Ultimate Amazon Product Research Tool!")}
                  </span>
                </div>
                <h1 className="main__title hero ">
                  {currentLang === "TR" ? (
                    <>
                      {translate("with Kepicker.")}
                      <br />
                      <span className="typing__grdent">
                        <TypeAnimation
                          sequence={[
                            translate("Extract ASINs Quickly"),
                            500,
                            translate("Fetch Sellers Storefront"),
                            500,
                            translate("Compare Products"),
                            500,
                            translate("Calculate ROI"),
                            500,
                            translate("View Keepa Data"),
                            500,
                            translate("Show Product Metrics"),
                            500,
                          ]}
                          wrapper="span"
                          speed={50}
                          style={{ display: "inline-block" }}
                          repeat={Infinity}
                        />
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="typing__grdent">
                        <TypeAnimation
                          sequence={[
                            translate("Extract ASINs Quickly"),
                            500,
                            translate("Fetch Sellers's Storefront"),
                            500,
                            translate("Compare Products"),
                            500,
                            translate("Calculate ROI"),
                            500,
                            translate("View Keepa Data"),
                            500,
                            translate("Show Product Metrics"),
                            500,
                          ]}
                          wrapper="span"
                          speed={50}
                          style={{ display: "inline-block" }}
                          repeat={Infinity}
                        />
                      </span>
                      <br />
                      {translate("with Kepicker.")}
                    </>
                  )}
                </h1>
                <div className="main__info cc info mb_60">
                  {currentLang === "TR" ? (
                    <>
                      Ülkeler arası ürün ve fiyatları karşılaştırın -
                      <br />
                      <strong>Kepicker</strong> ile Satışlarınızı artırın.
                    </>
                  ) : currentLang === "DE" ? (
                    <>
                      Preise vergleichen und filtern -
                      <br />
                      Steigern Sie Ihren Umsatz mit <strong>Kepicker.</strong>
                    </>
                  ) : (
                    <>
                      Compare and filter prices -
                      <br />
                      Increase your revenue with <strong>Kepicker.</strong>
                    </>
                  )}
                </div>

                <div className="sub__infs rr">
                  <div className="sub__inf">
                    <i>
                      <FeatherIcon icon={"check"} />
                    </i>{" "}
                    {translate("No Credit Card Required")}
                  </div>
                  <div className="sub__inf">
                    <i>
                      <FeatherIcon icon={"check"} />
                    </i>{" "}
                    {translate("Token Based Free Trial")}
                  </div>
                </div>

                <NavLink className="button main__button" to="/signup">
                  {translate("Get Started Today!")}
                </NavLink>
                <button
                  className="glightbox_video"
                  onClick={() => {
                    setVideo("aLVENf_FOBM");
                    setOpen(true);
                  }}
                >
                  <svg
                    width="131"
                    height="131"
                    viewBox="0 0 131 131"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      className="inner-circle"
                      d="M65 21C40.1488 21 20 41.1488 20 66C20 90.8512 40.1488 111 65 111C89.8512 111 110 90.8512 110 66C110 41.1488 89.8512 21 65 21Z"
                      fill="#51ae9d"
                    ></path>
                    <circle
                      className="outer_circle"
                      cx="65.5"
                      cy="65.5"
                      r="64"
                      stroke="#51ae9d"
                    ></circle>
                    <path
                      className="playbn"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M60 76V57L77 66.7774L60 76Z"
                      fill="#fff"
                    ></path>
                  </svg>
                </button>

                {/* <NavLink className="main__button iconic bordered" to="/about">
                  <span>{translate("Get to Know Us")}</span>{" "}
                  <FeatherIcon icon={"arrow-up-right"} />
                </NavLink> */}
              </div>
            </div>
          </div>
        </div>

        <div className="section kpc_dd promo_cn bb">
          <div className="kpc_dd__center center">
            <div className="kpc_dd__head">
              <h2 className="kpc_dd__title h2 tt">
                {translate("Compare All Amazon Markets")}
              </h2>
              <div className="kpc_dd__info info ggb">
                {translate(
                  "Leverage Amazon analytics from all over the globe."
                )}

                <ul className="iitm__tags">
                  <Marquee>
                    <li>
                      <span className="tag__mmtm">
                        <i>
                          <FeatherIcon icon={"check"} />
                        </i>
                        United States
                      </span>
                    </li>
                    <li>
                      <span className="tag__mmtm">
                        <i>
                          <FeatherIcon icon={"check"} />
                        </i>
                        Canada
                      </span>
                    </li>
                    <li>
                      <span className="tag__mmtm">
                        <i>
                          <FeatherIcon icon={"check"} />
                        </i>
                        Mexico
                      </span>
                    </li>
                    <li>
                      <span className="tag__mmtm">
                        <i>
                          <FeatherIcon icon={"check"} />
                        </i>
                        Brazil
                      </span>
                    </li>
                    <li>
                      <span className="tag__mmtm">
                        <i>
                          <FeatherIcon icon={"check"} />
                        </i>
                        Germany
                      </span>
                    </li>
                    <li>
                      <span className="tag__mmtm">
                        <i>
                          <FeatherIcon icon={"check"} />
                        </i>
                        United Kingdom
                      </span>
                    </li>
                    <li>
                      <span className="tag__mmtm">
                        <i>
                          <FeatherIcon icon={"check"} />
                        </i>
                        Italy
                      </span>
                    </li>
                    <li>
                      <span className="tag__mmtm">
                        <i>
                          <FeatherIcon icon={"check"} />
                        </i>
                        Spain
                      </span>
                    </li>
                    <li>
                      <span className="tag__mmtm">
                        <i>
                          <FeatherIcon icon={"check"} />
                        </i>
                        Poland
                      </span>
                    </li>
                    <li>
                      <span className="tag__mmtm">
                        <i>
                          <FeatherIcon icon={"check"} />
                        </i>
                        Netherlands
                      </span>
                    </li>
                    <li>
                      <span className="tag__mmtm">
                        <i>
                          <FeatherIcon icon={"check"} />
                        </i>
                        Sweden
                      </span>
                    </li>
                    <li>
                      <span className="tag__mmtm">
                        <i>
                          <FeatherIcon icon={"check"} />
                        </i>
                        United Arab Emirates
                      </span>
                    </li>
                    <li>
                      <span className="tag__mmtm">
                        <i>
                          <FeatherIcon icon={"check"} />
                        </i>
                        Saudi Arabia
                      </span>
                    </li>
                    <li>
                      <span className="tag__mmtm">
                        <i>
                          <FeatherIcon icon={"check"} />
                        </i>
                        Australia
                      </span>
                    </li>
                    <li>
                      <span className="tag__mmtm">
                        <i>
                          <FeatherIcon icon={"check"} />
                        </i>
                        Japan
                      </span>
                    </li>
                    <li>
                      <span className="tag__mmtm">
                        <i>
                          <FeatherIcon icon={"check"} />
                        </i>
                        Singapore
                      </span>
                    </li>
                    <li>
                      <span className="tag__mmtm">
                        <i>
                          <FeatherIcon icon={"check"} />
                        </i>
                        India
                      </span>
                    </li>
                    <li>
                      <span className="tag__mmtm">
                        <i>
                          <FeatherIcon icon={"check"} />
                        </i>
                        China
                      </span>
                    </li>
                  </Marquee>
                </ul>
              </div>
              <div
                className="map__dotted"
                ref={containerRef}
                style={{ width: "550px", height: "550px" }}
              ></div>
            </div>
          </div>
        </div>
        {/* 
        <div className="section onex_w hero__img">
          <img
            className="desk_img"
            src="/assets/img/kepicker_tools.jpg"
            alt="Kepicker Tools"
          ></img>

          <Slider {...settings}>
            <div>
              <img src="/assets/img/ss_1.jpg" alt="Amazon Asin Extractor"></img>
            </div>
            <div>
              <img src="/assets/img/ss_2.jpg" alt="Amazon Asin SPY"></img>
            </div>
            <div>
              <img
                src="/assets/img/ss_3.jpg"
                alt="Compare Amazon Marketplaces"
              ></img>
            </div>
            <div>
              <img
                src="/assets/img/ss_4.jpg"
                alt="Amazon Product Fee and Price Calculator"
              ></img>
            </div>
          </Slider>
        </div> */}

        <div className="footer-push tmpp__area">
          <div className="footer-push-content section-width">
            <div className="item how tt">
              <img src="/assets/img/warehousing.png"></img>
              <div className="text">
                <h3>{translate("Amazon Warehousing Services")}</h3>
                <p>
                  {translate(
                    "We understand the challenges of scaling up your warehousing needs. That’s why we offer flexible and cost-effective warehousing solutions tailored to your growing requirements."
                  )}
                </p>
                <NavLink to="/warehousing" className="tertiary-cta ">
                  {" "}
                  {translate("Learn More")}{" "}
                </NavLink>
              </div>
            </div>
            <div className="item faq">
              <img src="/assets/img/consulting.png"></img>
              <div className="text">
                <h3>{translate("Amazon Consulting Services")}</h3>
                <p>
                  {translate(
                    "Partnering with Amazon experts can help you plan for and hedge against the seasonality and variability that affect online retailers even harder than physical stores."
                  )}{" "}
                </p>
                <NavLink to="/consulting" className="tertiary-cta ">
                  {" "}
                  {translate("Learn More")}{" "}
                </NavLink>
              </div>
            </div>
          </div>
        </div>

        <div className="section onex_w">
          <div className="onex_w__center center big_w">
            <div className="onex_w__head">
              <h2 className="onex_w__title h2">
                {translate("Kepicker Core Tools")}
              </h2>
              <div className="onex_w__info info">
                {translate("Do it easily, quickly and practically")} -<br />
                {translate(
                  "Premium features designed to help your business take-off"
                )}
                .
              </div>
            </div>
            <div className="onex_f__body">
              <div className="onex_f__list">
                <div className="onex_f__item t_1">
                  <div>
                    <div className="onex_w__subtitle">
                      <div className="icn__gg">
                        <span className="bf_wc"></span>
                        <span className="asin_ext ps__aaa"></span>
                      </div>
                      Asin Extractor
                    </div>
                    <div className="onex_w__content">
                      {translate(
                        "With this tool, you can quickly pull all Asins on the current page."
                      )}
                    </div>
                  </div>
                  <img
                    src="/assets/img/asin_extractor_tool.png"
                    width="572"
                    sizes="(max-width: 767px) 95vw, (max-width: 991px) 572px, (max-width: 1439px) 42vw, 572px"
                    alt="Amazon Asin Extractor"
                  ></img>

                  <div className="glow"></div>
                </div>
                <div className="onex_f__item t_2">
                  <div>
                    <div className="onex_w__subtitle">
                      <div className="icn__gg">
                        <span className="bf_wc"></span>
                        <span className="asin_spy ps__aaa"></span>
                      </div>
                      Asin SPY
                    </div>
                    <div className="onex_w__content">
                      {translate(
                        "This tool becomes active while on the seller page. When this tool starts, it automatically runs in the background, page by page, and collects all the seller's Asins."
                      )}
                    </div>
                  </div>
                  <img
                    src="/assets/img/asin_spy_tool.png"
                    width="572"
                    sizes="(max-width: 767px) 95vw, (max-width: 991px) 572px, (max-width: 1439px) 42vw, 572px"
                    alt="Amazon Asin SPY"
                  ></img>
                </div>
                <div className="onex_f__item t_3">
                  <div>
                    <div className="onex_w__subtitle">
                      <div className="icn__gg">
                        <span className="bf_wc"></span>
                        <span className="asin_pbi ps__aaa"></span>
                      </div>
                      {translate("Fees Comparison")}
                    </div>
                    <div className="onex_w__content">
                      {translate(
                        "Estimate selling fees and costs - calculate fees, costs, and revenue for a current or future product."
                      )}
                    </div>
                  </div>
                  <img
                    src="/assets/img/fees_comparison_tool.png"
                    width="572"
                    sizes="(max-width: 767px) 95vw, (max-width: 991px) 572px, (max-width: 1439px) 42vw, 572px"
                    alt="Amazon Product Fees Estimate"
                  ></img>
                </div>
                <div className="onex_f__item t_4">
                  <div>
                    <div className="onex_w__subtitle">
                      <div className="icn__gg">
                        <span className="bf_wc"></span>
                        <span className="prdkt_metrcs ps__aaa"></span>
                      </div>
                      {translate("Product Metrics")}
                    </div>
                    <div className="onex_w__content">
                      {translate(
                        "The extension calculates critical financial indicators such as ROI, profit, expenses, and sales ranking. Maximize your profits with real-time data!"
                      )}
                    </div>
                  </div>
                  <img
                    src="/assets/img/product_based_info_tool.png"
                    width="572"
                    sizes="(max-width: 767px) 95vw, (max-width: 991px) 572px, (max-width: 1439px) 42vw, 572px"
                    alt="Amazon Product Metrics - ROI"
                  ></img>
                </div>
                <div className="onex_f__item t_9">
                  <div>
                    <div className="onex_w__subtitle">
                      <div className="icn__gg">
                        <span className="bf_wc"></span>
                        <span className="asin_cmp ps__aaa"></span>
                      </div>
                      {translate("Compare Marketplaces")}
                    </div>
                    <div className="onex_w__content">
                      {translate(
                        "Compare ASINs across different Amazon marketplaces to pinpoint the most profitable opportunities."
                      )}
                    </div>
                  </div>
                  <img
                    src="/assets/img/compare_marketplaces_tool.png"
                    width="572"
                    sizes="(max-width: 767px) 95vw, (max-width: 991px) 572px, (max-width: 1439px) 42vw, 572px"
                    alt="Amazon Compare Marketplaces"
                  ></img>
                </div>
                <div className="onex_f__item t_5">
                  <div>
                    <div className="onex_w__subtitle">
                      <div className="icn__gg">
                        <span className="bf_wc"></span>
                        <span className="asin_cal ps__aaa"></span>
                      </div>
                      {translate("Kepicker Calculator")}
                    </div>
                    <div className="onex_w__content">
                      {translate(
                        "Make your own calculations by manually entering the source and target market price and shipping price."
                      )}
                    </div>
                  </div>
                  <img
                    src="/assets/img/kepicker_calculator_tool.png"
                    width="572"
                    sizes="(max-width: 767px) 95vw, (max-width: 991px) 572px, (max-width: 1439px) 42vw, 572px"
                    alt="Amazon Product Fee and Price Calculator"
                  ></img>
                </div>
                <div className="onex_f__item t_6">
                  <div>
                    <div className="onex_w__subtitle">
                      <div className="icn__gg">
                        <span className="bf_wc"></span>
                        <span className="asin_uploader ps__aaa"></span>
                      </div>
                      {translate("Uploader Tool")}
                    </div>
                    <div className="onex_w__content">
                      {translate(
                        "Easily upload and compare lists of ASINs. Perfect for bulk analysis and strategic planning."
                      )}
                    </div>
                  </div>
                  <img
                    src="/assets/img/wholesaler_tool.png"
                    width="572"
                    sizes="(max-width: 767px) 95vw, (max-width: 991px) 572px, (max-width: 1439px) 42vw, 572px"
                    alt="Amazon Wholesaler Tool"
                  ></img>
                </div>
                <div className="onex_f__item t_7">
                  <div>
                    <div className="onex_w__subtitle">
                      <div className="icn__gg">
                        <span className="bf_wc"></span>
                        <span className="stock_fetcher ps__aaa"></span>
                      </div>
                      {translate("Stock Fetcher")}
                    </div>
                    <div className="onex_w__content">
                      {translate(
                        "Get detailed stock and storefront data to ensure you never run out of your best sellers."
                      )}
                    </div>
                  </div>
                  <img
                    src="/assets/img/stock_fetcher_tool.png"
                    width="572"
                    sizes="(max-width: 767px) 95vw, (max-width: 991px) 572px, (max-width: 1439px) 42vw, 572px"
                    alt="Amazon Stock Fetcher Tool"
                  ></img>
                </div>
                <div className="onex_f__item t_8">
                  <div>
                    <div className="onex_w__subtitle">
                      <div className="icn__gg">
                        <span className="bf_wc"></span>
                        <span className="historical_data ps__aaa"></span>
                      </div>
                      {translate("Historical Keepa Data")}
                    </div>
                    <div className="onex_w__content">
                      {translate(
                        "View comprehensive historical Keepa data to predict future trends and pricing strategies."
                      )}
                    </div>
                  </div>
                  <img
                    src="/assets/img/historical_data_tool.png"
                    width="572"
                    sizes="(max-width: 767px) 95vw, (max-width: 991px) 572px, (max-width: 1439px) 42vw, 572px"
                    alt="Amazon Stock Fetcher Tool"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section kpc_dd coming__fetaures">
          <div className="kpc_dd__center center">
            <div className="kpc_dd__head">
              <h2 className="kpc_dd__title h2">
                {translate(
                  "Find the Most Profitable Products to Sell on Amazon!"
                )}
              </h2>
              <div className="kpc_dd__info info">
                {translate(
                  "Unlock the Full Potential of your Amazon Selling Experience with Kepicker."
                )}
              </div>
            </div>

            <div className="features__area">
              <div className="features__itm t_1">
                <div className="features_w__subtitle">
                  <div className="features_w__tags">
                    <span className="c_fstr"></span>
                    {/* <span className="ff__tag ff">{translate("Feature")}</span> */}
                  </div>
                  <div className="itm__title">
                    {translate("Fast and Reliable")}
                  </div>
                </div>
                <div className="features_w__content">
                  {translate(
                    "Designed for efficiency, our tools deliver results quickly and accurately."
                  )}
                </div>
              </div>
              <div className="features__itm t_1">
                <div className="features_w__subtitle">
                  <div className="features_w__tags">
                    <span className="c_userint"></span>
                    {/* <span className="ff__tag tt">{translate("Tool")}</span> */}
                  </div>
                  <div className="itm__title">
                    {translate("User-Friendly Interface")}
                  </div>
                </div>
                <div className="features_w__content">
                  {translate(
                    "Easy to navigate and use, regardless of your tech skills."
                  )}
                </div>
              </div>
              <div className="features__itm t_1">
                <div className="features_w__subtitle">
                  <div className="features_w__tags">
                    <span className="c_datad"></span>
                  </div>
                  <div className="itm__title">
                    {translate("Data-Driven Decisions")}
                  </div>
                </div>
                <div className="features_w__content">
                  {translate(
                    "Empower your business decisions with robust data analytics."
                  )}
                </div>
              </div>
              <div className="features__itm t_1">
                <div className="features_w__subtitle">
                  <div className="features_w__tags">
                    <span className="c_multilang"></span>
                  </div>
                  <div className="itm__title">
                    {translate("Multi-Language")}
                  </div>
                </div>
                <div className="features_w__content">
                  {translate(
                    "Kepicker has multiple languages: Türkçe, English, Deutsch.."
                  )}
                </div>
              </div>
              <div className="features__itm t_1">
                <div className="features_w__subtitle">
                  <div className="features_w__tags">
                    <span className="c_spprtt"></span>
                  </div>
                  <div className="itm__title">
                    {translate("Dedicated Support")}
                  </div>
                </div>
                <div className="features_w__content">
                  {translate(
                    "Have a question or need help? Our dedicated support team is just a ticket away."
                  )}
                </div>
              </div>
              <div className="features__itm t_1">
                <div className="features_w__subtitle">
                  <div className="features_w__tags">
                    <span className="c_svtime"></span>
                  </div>
                  <div className="itm__title">{translate("Save Time")}</div>
                </div>
                <div className="features_w__content">
                  {translate(
                    "Our tools are designed to streamline your workflow, reducing the time you spend managing your listings."
                  )}
                </div>
              </div>
              <div className="features__itm t_1"></div>
            </div>
          </div>
        </div>

        <div className="section kpc_dd promo_cn nn">
          <div className="kpc_dd__center center">
            <div className="kpc_dd__head">
              <h2 className="kpc_dd__title h2">
                {translate(
                  "Kepicker empowers to find better products, faster."
                )}
              </h2>
              <div className="kpc_dd__info info">
                {translate(
                  "The all-in-one finding and comparing products platform that saves you time + money. Most comparison sites provide basic information, with Kepicker, we want to help you research the most profitable products. That way we can focus on providing you with the best user experience, service, and settlement."
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="section onex_pl cmp_anim">
          <div className="onex_pl__center center pos_rel">
            <div className="onex_pl__wrap">
              <div className="stage">
                {translate("Boost your productivity!")}
              </div>
              <h2 className="onex_pl__title h2">
                {translate("Kepicker Will Offer You the Following.")}
              </h2>
              <div className="onex_pl__info">
                {translate(
                  "The app offers all the basic features you'll need for product research."
                )}
              </div>
              {currentLang === "TR" ? (
                <>
                  <ul className="checked_items">
                    <li>
                      <div>
                        Tüm <strong>Amazon pazar yerlerini</strong>{" "}
                        karşılaştırın.
                      </div>
                    </li>
                    <li>
                      <div>
                        Diğer eklentilere kıyasla{" "}
                        <strong>daha hızlı ASIN çekin.</strong>
                      </div>
                    </li>
                    <li>
                      <div>
                        <strong>2 mağazayı</strong> ASIN listeniz ile
                        karşılaştırın.
                      </div>
                    </li>
                    <li>
                      <div>
                        Arama sonuçlarından <strong>ASIN toplayın.</strong>
                      </div>
                    </li>
                    <li>
                      <div>
                        Mevcut ürünlerle ilgili{" "}
                        <strong>rakip satıcıları</strong> bulun.
                      </div>
                    </li>
                  </ul>
                </>
              ) : currentLang === "DE" ? (
                <>
                  <ul className="checked_items">
                    <li>
                      <div>
                        Vergleichen Sie <strong>alle Amazon-Marktplätze</strong>
                        .
                      </div>
                    </li>
                    <li>
                      <div>
                        <strong>Rufen Sie Asins</strong> schneller ab als andere
                        Erweiterungen.
                      </div>
                    </li>
                    <li>
                      <div>
                        Vergleichen <strong>Sie 2 Geschäfte</strong> mit Asin
                        List.
                      </div>
                    </li>
                    <li>
                      <div>
                        Rufen Sie Asins aus{" "}
                        <strong>den Suchergebnissen ab.</strong>
                      </div>
                    </li>
                    <li>
                      <div>
                        Finden Sie <strong>konkurrierende Verkäufer</strong> der
                        Produkte.
                      </div>
                    </li>
                  </ul>
                </>
              ) : (
                <>
                  <ul className="checked_items">
                    <li>
                      <div>
                        Compare <strong>all Amazon Marketplaces.</strong>
                      </div>
                    </li>
                    <li>
                      <div>
                        Fetch Asins{" "}
                        <strong>Faster than Other Extensions.</strong>
                      </div>
                    </li>
                    <li>
                      <div>
                        Compare <strong>2 Stores</strong> with Asin List.
                      </div>
                    </li>
                    <li>
                      <div>
                        Fetch Asins from the <strong>Search Results.</strong>
                      </div>
                    </li>
                    <li>
                      <div>
                        Find <strong>Competing Sellers</strong> of the Products.
                      </div>
                    </li>
                  </ul>
                </>
              )}
            </div>
            <div className="onex_pl__gallery xx">
              <div className="wrapper_slider">
                <div className="outer_slider">
                  <div className="card_slider" style={{ "--delay": "-1" }}>
                    <div className="content_slider">
                      <div className="img"></div>
                      <div className="details_slider">
                        <span className="name">
                          {translate("Custom Product")} #143
                        </span>
                        <p>
                          {translate("United Kingdom")} &#8594;{" "}
                          {translate("United States")}
                        </p>
                        <div className="bar_b"></div>
                        <div className="bar_s"></div>
                      </div>
                    </div>
                    <div className="profit">
                      <span className="percentage_b green">11%</span>
                      <span className="title">Roi</span>
                    </div>
                  </div>
                  <div className="card_slider" style={{ "--delay": "0" }}>
                    <div className="content_slider">
                      <div className="img"></div>
                      <div className="details_slider">
                        <span className="name">
                          {translate("Custom Product")} #345
                        </span>
                        <p>{translate("United States")} &#8594; Türkiye</p>
                        <div className="bar_b"></div>
                        <div className="bar_s"></div>
                      </div>
                    </div>
                    <div className="profit">
                      <span className="percentage_b red">-5%</span>
                      <span className="title">Roi</span>
                    </div>
                  </div>
                  <div className="card_slider" style={{ "--delay": "1" }}>
                    <div className="content_slider">
                      <div className="img"></div>
                      <div className="details_slider">
                        <span className="name">
                          {translate("Custom Product")} #221
                        </span>
                        <p>Türkiye &#8594; {translate("Canada")}</p>
                        <div className="bar_b"></div>
                        <div className="bar_s"></div>
                      </div>
                    </div>
                    <div className="profit">
                      <span className="percentage_b green">32%</span>
                      <span className="title">Roi</span>
                    </div>
                  </div>
                  <div className="card_slider" style={{ "--delay": "2" }}>
                    <div className="content_slider">
                      <div className="img"></div>
                      <div className="details_slider">
                        <span className="name">
                          {translate("Custom Product")} #754
                        </span>
                        <p>
                          {translate("Germany")} &#8594; {translate("Canada")}
                        </p>
                        <div className="bar_b"></div>
                        <div className="bar_s"></div>
                      </div>
                    </div>
                    <div className="profit">
                      <span className="percentage_b green">4%</span>
                      <span className="title">Roi</span>
                    </div>
                  </div>
                  <div className="card_slider" style={{ "--delay": "2" }}>
                    <div className="content_slider">
                      <div className="img"></div>
                      <div className="details_slider">
                        <span className="name">
                          {translate("Custom Product")} #085
                        </span>
                        <p>
                          {translate("Canada")} &#8594;{" "}
                          {translate("United Kingdom")}
                        </p>
                        <div className="bar_b"></div>
                        <div className="bar_s"></div>
                      </div>
                    </div>
                    <div className="profit">
                      <span className="percentage_b red">-8%</span>
                      <span className="title">Roi</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section onex_pl cmp_anim extesinon__area">
          <div className="onex_pl__center center pos_rel">
            <div className="onex_pl__wrap">
              <div className="stage">
                {translate("Increase your revenue with Kepicker!")}
              </div>
              <h2 className="onex_pl__title h2">
                {translate("Meet the Kepicker's Product Based Info —")}
              </h2>
              <div className="onex_pl__info">
                {translate(
                  "Kepicker offers all the basic features you'll need for product research."
                )}{" "}
                {translate(
                  "Maximize Profits. Minimize Effort. Navigate. Analyze. Excel."
                )}
              </div>

              <div className="tagst-list-wrapper">
                <ul className="checked_items">
                  <li>
                    <div>{translate("Advanced Stock Fetcher")}</div>
                  </li>
                  <li>
                    <div>{translate("Auto / Manual ROI Calculation")}</div>
                  </li>
                  <li>
                    <div>{translate("Currency Converter")}</div>
                  </li>
                  <li>
                    <div>{translate("Historical Product Data")}</div>
                  </li>
                  <li>
                    <div>{translate("Monthly Sales Count")}</div>
                  </li>
                  <li>
                    <div>{translate("Amazon Best Sellers Rank (BSR)")}</div>
                  </li>
                  <li>
                    <div>{translate("Variation, Hazmat Tags")}</div>
                  </li>
                  <li>
                    <div>{translate("Heat Sensitive, Meltable Tags")}</div>
                  </li>
                  <li>
                    <div>{translate("Business Prime Discounts")}</div>
                  </li>
                  <li>
                    <div>{translate("Add Product to Favorites")}</div>
                  </li>
                </ul>
                <ul className="checked_items">
                  <li>
                    <div>{translate("Asins to Google Sheets")}</div>
                  </li>
                  <li>
                    <div>{translate("Telegram Integration")}</div>
                  </li>
                  <li>
                    <div>{translate("ASIN - EAN - UPC Infos")}</div>
                  </li>
                  <li>
                    <div>{translate("Barcode UPC Lookup")}</div>
                  </li>
                  <li>
                    <div>{translate("Search the Product on Google")}</div>
                  </li>
                  <li>
                    <div>{translate("Check on Amazon Seller Central")}</div>
                  </li>
                  <li>
                    <div>{translate("Dimensional Measurement")}</div>
                  </li>
                  <li>
                    <div>{translate("Take Notes About the Product")}</div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="onex_pl__extesinon__area">
              <div className="pbi__Area">
                <div className="pbi_top">
                  <img src="/assets/img/kepicker_extension.png"></img>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section onex_pl cmp_anim extesinon__area ggh bg-color-white">
          <div className="onex_pl__center center pos_rel">
            <div className="onex_pl__extesinon__area">
              <div className="pbi__Area">
                <div className="pbi_top">
                  <img src="/assets/img/kepicker_amazon_software.png"></img>
                </div>
              </div>
            </div>
            <div className="onex_pl__wrap">
              <div className="stage">
                {translate("Grow your Amazon Marketplace.")}
              </div>
              <h2 className="onex_pl__title h2">
                {translate("Analyze Smarter.")}
                <br />
                {translate("Find Better.")}
                <br />
                {translate("Search Faster.")}
              </h2>
              <div className="onex_pl__info">
                {translate(
                  "Kepicker is your go-to tool for gaining a competitive edge!"
                )}
              </div>

              <div className="tagst-list-wrapper">
                <ul className="checked_items">
                  <li>
                    <div>{translate("Powerful ASIN Grabber")}</div>
                  </li>
                  <li>
                    <div>{translate("Compare All Amazon Marketplaces")}</div>
                  </li>
                  <li>
                    <div>{translate("Custom Shipping Settings for FBM")}</div>
                  </li>
                  <li>
                    <div>{translate("Find Competing Sellers")}</div>
                  </li>
                  <li>
                    <div>{translate("Storefront Data")}</div>
                  </li>
                  <li>
                    <div>{translate("My Product History")}</div>
                  </li>
                </ul>

                <ul className="checked_items">
                  <li>
                    <div>{translate("Upload - Merge ASINs")}</div>
                  </li>
                  <li>
                    <div>{translate("Manage ASIN Lists")}</div>
                  </li>
                  <li>
                    <div>{translate("Advanced Filters")}</div>
                  </li>
                  <li>
                    <div>{translate("Web Based Asin 360° Tool")}</div>
                  </li>
                  <li>
                    <div>{translate("FBA - FBM Mode")}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="section onex_pl cmp_anim extesinon__area">
          <div className="onex_pl__center center pos_rel">
            <div className="onex_pl__wrap">
              <div className="stage">
                {translate("Grow Your Business with Our Chrome Extension.")}
              </div>
              <h2 className="onex_pl__title h2">
                {translate(
                  "FBA/FBM Fee and Profit Calculator for Amazon Sellers!"
                )}
              </h2>
              <div className="onex_pl__info">
                {translate(
                  "Researching and calculating the cost of goods sold, shipping fees, Amazon fees, and other variables is crucial for your business. It helps to understand the true profit margins per unit and ensures you earn enough profit to grow your business and thrive."
                )}
                <br />
                <br />

                {translate(
                  "What will be the benefit of using the Kepicker' calculator on Amazon? Our FBA/FBM Fee and Profit Calculator offers multiple benefits to the sellers, such as it helps you understand your expenses beforehand, including shipping charges, seller fees, and FBA fees. Choose a product with lower fees and maximize profits by calculating profit margins."
                )}
                <br />
                <br />

                {translate(
                  "Additionally, the Amazon Calculator Chrome extension is the best way to estimate expenses and profitability because it provides you with essential data, like:"
                )}
              </div>

              <div className="tagst-list-wrapper">
                <ul className="checked_items">
                  <li>
                    <div>{translate("Net Profit")}</div>
                  </li>
                  <li>
                    <div>{translate("Net Margin")}</div>
                  </li>
                  <li>
                    <div>{translate("Return on  Investment (ROI)")}</div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="onex_pl__extesinon__area">
              <div className="pbi__Area">
                <div className="pbi_top">
                  <img src="/assets/img/amazon_fba_fee_calculator.jpg"></img>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section onex_pl cmp_anim extesinon__area ggh bg-color-white">
          <div className="onex_pl__center center pos_rel">
            <div className="onex_pl__extesinon__area">
              <div className="pbi__Area">
                <div className="pbi_top">
                  <img src="/assets/img/amazon_price_tracker.png"></img>
                </div>
              </div>
            </div>
            <div className="onex_pl__wrap">
              <div className="stage">
                {translate("Price Drop & Availability Alerts")}
              </div>
              <h2 className="onex_pl__title h2">
                {translate("Amazon Price Tracker!")}
              </h2>
              <div className="onex_pl__info">
                {translate(
                  "Set up a price watch directly from the product page. We track any product for you and notify you once the product dropped below your desired price."
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="section onex_pl cmp_anim extesinon__area gghtt">
          <div className="onex_pl__center center pos_rel">
            <div className="onex_pl__wrap">
              <div className="stage">
                {translate("Arbitrage Scanner and Calculator")}
              </div>
              <h2 className="onex_pl__title h2">
                {translate("Barcode Scanner App for Amazon Sellers!")}
              </h2>
              <div className="onex_pl__info">
                {translate(
                  "Kepicker' Barcode Scanner App is an innovative arbitrage finder and FBA profit calculator that helps Amazon sellers identify items you can purchase in local stores and resell on Amazon for a profit. Start your Amazon business with Kepicker' Barcode Scanner now!"
                )}
              </div>
            </div>

            <div className="onex_pl__extesinon__area">
              <div className="pbi__Area">
                <div className="pbi_top">
                  <div className="rdr_b_middle">
                    <img src="/assets/img/barcode_hero.png"></img>
                    <img
                      className="brd_scanng"
                      src="/assets/img/barcode_scanning.png"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section kpc_dd coming__fetaures tstmls">
          <div className="testimonalss__wrapper ptb--100">
            <div className="kpc_dd__head">
              <h2 className="kpc_dd__title h2">
                {translate("Members Love Our Software")}
              </h2>
              <div className="kpc_dd__info info">
                {translate("What People Are Saying About Kepicker Extension")}
              </div>
              <a
                target="_blank"
                className="button view__button revw__button"
                href="https://chromewebstore.google.com/detail/kepicker/hnpablpopdlabbiopkihkmlfmkgpihdc"
              >
                <span>{translate("Read More Review")}</span>{" "}
                <FeatherIcon icon={"star"} />
              </a>
            </div>
            <div className="rbt-testimonial-area bg-color-white overflow-hidden">
              <div className="testimonalss__area">
                <div className="scroll-animation-wrapper pb--30">
                  <div className="scroll-animation scroll-right-left">
                    {[
                      {
                        name: "Yunus Anlamaz",
                        date: "May 20, 2024",
                        review:
                          "Nice program. Congratulations to the dedicated team of developers behind it.",
                      },
                      {
                        name: "Liubov Bezrukova",
                        date: "Apr 3, 2024",
                        review:
                          "VERY EASY TO ANALYZE PRODUCTS WITH THIS PROGRAMME. I REALLY LIKE IT.",
                      },
                      {
                        name: "Mustafa AL",
                        date: "Mar 30, 2024",
                        review: "Nice program.",
                      },
                      {
                        name: "Amazon Email",
                        date: "Mar 21, 2024",
                        review:
                          "Perfect. I believe the Amazon community needed something like this. ",
                      },
                      {
                        name: "Ali Türkmen",
                        date: "Feb 28, 2024",
                        review:
                          "Perfect application for amazon sellers. Highly recommended",
                        helpfulCount: 1,
                      },
                      {
                        name: "Elize Baby",
                        date: "Feb 28, 2024",
                        review: "Wonderful!!! Very nice programme.",
                      },
                      {
                        name: "Şiyar Çelik",
                        date: "Feb 27, 2024",
                        review: "Very ballsy software.",
                      },
                      {
                        name: "Fetih Bal",
                        date: "Feb 24, 2024",
                        review: "Great program.",
                      },
                      {
                        name: "Musalla Masjid Carpets",
                        date: "Feb 22, 2024",
                        review: "Good program.",
                      },
                      {
                        name: "İlhami Kılıç",
                        date: "Feb 22, 2024",
                        review:
                          "Nice programme functional. Recommend to everyone.",
                      },
                      {
                        name: "Saadet Atak",
                        date: "Feb 21, 2024",
                        review: "Good program.",
                      },
                      {
                        name: "Samet Ağlamaz",
                        date: "Jan 17, 2024",
                        review:
                          "Top. Using it for 2 months now and it has been a great help.",
                      },
                      {
                        name: "Berra Altay",
                        date: "Jan 15, 2024",
                        review:
                          "Really a game-changer for me in the product research stage!",
                      },
                      {
                        name: "Sadi Gul",
                        date: "Jan 10, 2024",
                        review: "Top tool for Amazon sellers.",
                      },
                      {
                        name: "Suleyman Solmaz",
                        date: "Jan 9, 2024",
                        review: "Very good.",
                      },
                      {
                        name: "Bora Kara",
                        date: "Jan 8, 2024",
                        review:
                          "Good software, helps a lot in product research. Recommended.",
                      },
                      {
                        name: "Halim Talha Turan",
                        date: "Jan 4, 2024",
                        review: "Amazing tool for product analysis!",
                      },
                    ].map((item, index) => (
                      <div key={index} className="single-column-20">
                        <div className="rbt-testimonial-box">
                          <div className="inner">
                            <div className="clint-info-wrapper">
                              <div className="client-info">
                                <h5 className="title">{item.name}</h5>
                              </div>
                            </div>
                            <div className="description">
                              <p className="subtitle-3">{item.review}</p>
                              <div className="rating mt--20">
                                <span>
                                  <FeatherIcon icon={"star"} />
                                </span>
                                <span>
                                  <FeatherIcon icon={"star"} />
                                </span>
                                <span>
                                  <FeatherIcon icon={"star"} />
                                </span>
                                <span>
                                  <FeatherIcon icon={"star"} />
                                </span>
                                <span>
                                  <FeatherIcon icon={"star"} />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="scroll-animation-wrapper pb--50">
                  <div className="scroll-animation scroll-left-right">
                    {[
                      {
                        name: "Fatih Söyler",
                        date: "Feb 16, 2024",
                        review: "Good program.",
                      },
                      {
                        name: "Ezel Turkaya",
                        date: "Feb 14, 2024",
                        review:
                          "A very useful software. It really made my job a lot easier. I would recommend...!",
                      },
                      {
                        name: "Berkay Keskiner",
                        date: "Feb 14, 2024",
                        review: "Amazing.",
                      },
                      {
                        name: "Murat Güneş",
                        date: "Feb 13, 2024",
                        review: "Perfect service",
                        helpfulCount: 1,
                      },
                      {
                        name: "Can Oto",
                        date: "Feb 13, 2024",
                        review:
                          "Truly a software that deserves recognition. Really cool..",
                        helpfulCount: 1,
                      },
                      {
                        name: "Ibrahim Söylemiş",
                        date: "Feb 13, 2024",
                        review: "Good job",
                        helpfulCount: 1,
                      },
                      {
                        name: "Ali Han",
                        date: "Feb 12, 2024",
                        review: "Good job.",
                      },
                      {
                        name: "Rıdvan Bektaş",
                        date: "Feb 10, 2024",
                        review:
                          "Amazing software. it works on all marketplaces.",
                      },
                      {
                        name: "Gülay Emnacar",
                        date: "Feb 9, 2024",
                        review: "Nice program.  I seriously recommend this!!",
                      },
                      {
                        name: "Edebi Seçki",
                        date: "Feb 7, 2024",
                        review:
                          "Successful. It allowed me to handle all my work with a single software.",
                      },
                      {
                        name: "Fatih Kuşcul",
                        date: "Feb 7, 2024",
                        review:
                          "Great working software. Amazon accelerates my profitability analysis.",
                      },
                      {
                        name: "Berkay Keskiner",
                        date: "Feb 1, 2024",
                        review: "Great. Boosted my sales.",
                      },
                      {
                        name: "Yasemin O Buyrukbilen",
                        date: "Jan 29, 2024",
                        review:
                          "Works like a charm, makes me get rid of all other tools for product search.",
                      },
                      {
                        name: "Ersin Saydam",
                        date: "Jan 26, 2024",
                        review: "Product research is so easy with this tool.",
                      },
                      {
                        name: "Melih Yilmaz",
                        date: "Jan 25, 2024",
                        review:
                          "Very quick and extraordinary for Amazon e-commerce arbitrage system...",
                      },
                      {
                        name: "Osman Dulkadir",
                        date: "Jan 24, 2024",
                        review: "What a nice programme.",
                      },
                      {
                        name: "Cemre Jylmaz",
                        date: "Jan 19, 2024",
                        review: "It is good program.",
                      },
                    ].map((item, index) => (
                      <div key={index} className="single-column-20">
                        <div className="rbt-testimonial-box">
                          <div className="inner">
                            <div className="clint-info-wrapper">
                              <div className="client-info">
                                <h5 className="title">{item.name}</h5>
                              </div>
                            </div>
                            <div className="description">
                              <p className="subtitle-3">{item.review}</p>
                              <div className="rating mt--20">
                                <span>
                                  <FeatherIcon icon={"star"} />
                                </span>
                                <span>
                                  <FeatherIcon icon={"star"} />
                                </span>
                                <span>
                                  <FeatherIcon icon={"star"} />
                                </span>
                                <span>
                                  <FeatherIcon icon={"star"} />
                                </span>
                                <span>
                                  <FeatherIcon icon={"star"} />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section kpc_dd">
          <div className="kpc_dd__center">
            <div className="kpc_dd__head">
              <h2 className="kpc_dd__title h2">{translate("Our Partners")}</h2>
              <ul className="iitm__tags mm">
                <Marquee>
                  <li>
                    <img src="/assets/img/partners/01.png"></img>
                  </li>
                  <li>
                    <img src="/assets/img/partners/02.png"></img>
                  </li>
                  <li>
                    <img src="/assets/img/partners/03.png"></img>
                  </li>
                  <li>
                    <img src="/assets/img/partners/04.png"></img>
                  </li>
                  <li>
                    <img src="/assets/img/partners/05.png"></img>
                  </li>
                  <li>
                    <img src="/assets/img/partners/06.png"></img>
                  </li>
                  <li>
                    <img src="/assets/img/partners/07.png"></img>
                  </li>
                  <li>
                    <img src="/assets/img/partners/08.png"></img>
                  </li>
                  <li>
                    <img src="/assets/img/partners/09.png"></img>
                  </li>
                  <li>
                    <img src="/assets/img/partners/10.png"></img>
                  </li>
                  <li>
                    <img src="/assets/img/partners/11.png"></img>
                  </li>
                  <li>
                    <img src="/assets/img/partners/12.png"></img>
                  </li>
                  <li>
                    <img src="/assets/img/partners/13.png"></img>
                  </li>
                  <li>
                    <img src="/assets/img/partners/14.png"></img>
                  </li>
                  <li>
                    <img src="/assets/img/partners/15.png"></img>
                  </li>
                </Marquee>
              </ul>
            </div>
          </div>
        </div>

        <div className="section view">
          <div className="view__center center promo_sc">
            <div className="view__head">
              <div className="view__details">
                <div className="view__stage">
                  {translate("Ready to get started?")}
                </div>
                <h2 className="view__title h2">
                  {translate("Let's do it. Let's find!")}
                </h2>
                <p className="onex_p__info info">
                  {translate("Say hello to Industry’s newest product finder.")}
                </p>
              </div>
              <NavLink className="button view__button" to="/signup">
                {translate("Get Started With a Free Account")}
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-push">
        <div className="footer-push-content section-width">
          <div className="item how">
            <div className="icon"></div>
            <div className="text">
              <h3>{translate("Blogs, Latest News")}</h3>
              <p>
                {translate(
                  "Here our blogs provide some tips and guides for you to find the best products for your marketplace."
                )}
              </p>
              <NavLink to="/blog" className="tertiary-cta ">
                {" "}
                {translate("Latest News ‍& Updates")}{" "}
              </NavLink>
            </div>
          </div>
          <div className="item faq">
            <div className="icon"></div>
            <div className="text">
              <h3>{translate("Help Center")}</h3>
              <p>
                {translate(
                  "Contact us to learn how we can help your business or get answer to technical questions about Kepicker."
                )}{" "}
              </p>
              <NavLink to="/support" className="tertiary-cta ">
                {" "}
                {translate("Browse the Help Center")}{" "}
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
