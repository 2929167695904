import React, { useState, useEffect } from "react";

import { NavLink, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Table, Modal, Input, InputNumber, Menu, Button, Tooltip } from "antd";
import { FilterFilled } from "@ant-design/icons";
import translate from "../../../../shared/utils/translations";
import EmptyIcon from "../../../../../assets/img/empty.png";
import { Resizable } from "react-resizable";
import ReactDragListView from "react-drag-listview";
import NoImage from "../../../../../assets/img/no_image.png";

const ComparisonTable = ({ dataSource, data, asinListData, resetTrigger }) => {
  const [isTableSort, setTableSort] = useState(() => {
    const savedSort = localStorage.getItem("cmptableSort");
    return savedSort !== null ? savedSort : "list";
  });

  useEffect(() => {
    localStorage.setItem("cmptableSort", isTableSort);
  }, [isTableSort]);

  const [filterValues, setFilterValues] = useState({
    fee: { min: undefined, max: undefined },
    roi: { min: undefined, max: undefined },
    profit_per_item: { min: undefined, max: undefined },
    sales_rank: { min: undefined, max: undefined },
    base_domain_converted_price: { min: undefined, max: undefined },
    target_domain_price: { min: undefined, max: undefined },
    shipping_cost: { min: undefined, max: undefined },
  });

  useEffect(() => {
    if (resetTrigger) {
      setFilterValues({
        fee: { min: undefined, max: undefined },
        roi: { min: undefined, max: undefined },
        profit_per_item: { min: undefined, max: undefined },
        sales_rank: { min: undefined, max: undefined },
        base_domain_converted_price: { min: undefined, max: undefined },
        target_domain_price: { min: undefined, max: undefined },
        shipping_cost: { min: undefined, max: undefined },
      });
    }
  }, [resetTrigger]);

  const getNumericColumnFilterProps = (dataIndex, title) => ({
    filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: "15px" }}>
        <h6>{translate("Filter by")}</h6>
        <div className="fltt_bddy">
          <InputNumber
            placeholder={translate("Min")}
            value={filterValues[dataIndex]?.min}
            onChange={(value) =>
              setFilterValues((prev) => ({
                ...prev,
                [dataIndex]: { ...prev[dataIndex], min: value },
              }))
            }
            style={{ width: "100%" }}
          />
          <InputNumber
            placeholder={translate("Max")}
            value={filterValues[dataIndex]?.max}
            onChange={(value) =>
              setFilterValues((prev) => ({
                ...prev,
                [dataIndex]: { ...prev[dataIndex], max: value },
              }))
            }
            style={{ width: "100%" }}
          />
        </div>

        <div className="fltt__btnss">
          <Button
            type="primary"
            onClick={() => {
              setSelectedKeys([
                [filterValues[dataIndex]?.min, filterValues[dataIndex]?.max],
              ]);
              confirm();
            }}
            size="small"
            style={{ width: "100%" }}
          >
            {translate("Apply")}
          </Button>
          <Button
            onClick={() => {
              setFilterValues((prev) => ({
                ...prev,
                [dataIndex]: { min: undefined, max: undefined },
              }));
              setSelectedKeys([]);
              clearFilters();
            }}
            size="small"
            style={{ width: "100%" }}
          >
            {translate("Reset")}
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const [min, max] = value || []; // Directly get min and max from array
      const recordValue = record[dataIndex]; // Get the value for the current column

      if (min !== undefined && max !== undefined) {
        return recordValue >= min && recordValue <= max; // Filter between min and max
      } else if (min !== undefined) {
        return recordValue >= min; // Filter greater than or equal to min
      } else if (max !== undefined) {
        return recordValue <= max; // Filter less than or equal to max
      }
      return true; // If no filter is applied, return all records
    },
    render: (item) => <>{item}</>,
  });

  const makeReadable = (text) => {
    if (!text) {
      return "";
    }

    return text
      .split("_")
      .map((word) => {
        let words = word.split(/(?=[A-Z])/);
        return words
          .map((w) => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
          .join(" ");
      })
      .join(" ");
  };

  const columns = [
    {
      title: translate("Image"),
      dataIndex: "image_url",
      key: "image_url",
      width: 150,
      minWidth: 100,
      render: (item) => (
        <>
          <div className="column_info">{translate("Image")}</div>
          <img className="table__immg" src={item || NoImage}></img>
        </>
      ),
    },
    {
      title: "Asin",
      dataIndex: "asin",
      key: "asin",
      width: 150,
      minWidth: 100,
      render: (item) => (
        <>
          <div className="column_info">Asin</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Title"),
      dataIndex: "title",
      key: "title",
      width: 200,
      minWidth: 100,
      render: (item, row) => (
        <>
          <div className="column_info">{translate("Title")}</div>
          <a
            className="table__lnk"
            target="_blank"
            href={`https://${asinListData?.target_domain}/dp/${row?.asin}`}
          >
            {item}
          </a>
        </>
      ),
    },
    {
      title: `${asinListData?.base_domain} - ${asinListData?.currency}`,
      dataIndex: "base_domain_converted_price",
      key: "base_domain_converted_price",
      width: 200,
      minWidth: 150,

      ...getNumericColumnFilterProps(
        "base_domain_converted_price",
        "Source Price"
      ),
      render: (item) => (
        <>
          <div className="column_info">www.amazon.com - (USD)</div>
          {item}
        </>
      ),
    },
    {
      title: `${asinListData?.target_domain} - ${asinListData?.currency}`,
      dataIndex: "target_domain_price",
      key: "target_domain_price",
      width: 200,
      minWidth: 150,
      ...getNumericColumnFilterProps("target_domain_price", "Target Price"),
      render: (item) => (
        <>
          <div className="column_info">www.amazon.ca - (USD)</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Inbound Cost"),
      dataIndex: "shipping_cost",
      key: "shipping_cost",
      width: 200,
      minWidth: 150,
      sorter: (a, b) => a.shipping_cost - b.shipping_cost,
      ...getNumericColumnFilterProps("shipping_cost", "Inbound Cost"),
      render: (item) => (
        <>
          <div className="column_info">{translate("Inbound Cost")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Fees"),
      dataIndex: "fee",
      key: "fee",
      width: 100,
      minWidth: 80,

      sorter: (a, b) => a.fee - b.fee,
      ...getNumericColumnFilterProps("fee", "Fees"),
    },
    {
      title: "Roi",
      dataIndex: "roi",
      key: "roi",
      width: 100,
      minWidth: 80,
      sorter: (a, b) => a.roi - b.roi,
      className: "roi_class",
      ...getNumericColumnFilterProps("roi", "Roi"),
      render: (item) => (
        <>
          <div className="column_info">Roi</div>
          <span
            className={`roi__tag 
            ${
              item >= 40
                ? " kp_green"
                : item < 40 && item >= 15
                ? " kp_green_soft"
                : item < 15 && item >= 5
                ? " kp_blue"
                : item < 5 && item >= 0
                ? " kp_orange"
                : item < 0
                ? " kp_red"
                : " "
            }
            `}
          >
            {item}%
          </span>
        </>
      ),
    },
    {
      title: translate("Profit Per Item"),
      dataIndex: "profit_per_item",
      key: "profit_per_item",
      width: 200,
      minWidth: 100,
      sorter: (a, b) => a.profit_per_item - b.profit_per_item,
      ...getNumericColumnFilterProps("profit_per_item", "Profit Per Item"),
      render: (item) => (
        <>
          <div className="column_info">{translate("Profit Per Item")}</div>
          <span
            className={`prft__tag 
            ${
              item > 10
                ? " pr_green"
                : item <= 10 && item >= 0
                ? " pr_green_soft"
                : item < 0
                ? " pr_red"
                : " "
            }
            `}
          >
            {item}
          </span>
        </>
      ),
    },
    {
      title: translate("Sales Rank"),
      dataIndex: "sales_rank",
      key: "sales_rank",
      width: 200,
      minWidth: 100,
      sorter: (a, b) => a.sales_rank - b.sales_rank,
      ...getNumericColumnFilterProps("sales_rank", "Sales Rank"),
      render: (item) => (
        <>
          <div className="column_info">{translate("Sales Rank")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Sales Rank Category"),
      dataIndex: "sales_rank_category",
      key: "sales_rank_category",
      width: 200,
      minWidth: 100,
      render: (item) => (
        <>
          <div className="column_info">{translate("Sales Rank Category")}</div>
          {makeReadable(item)}
        </>
      ),
    },
    {
      title: translate("Has Discount?"),
      dataIndex: "has_discount",
      key: "has_discount",
      width: 200,
      minWidth: 100,
      sorter: (a, b) =>
        a.has_discount === b.has_discount ? 0 : a.has_discount ? -1 : 1,
      filters: [
        {
          text: "True",
          value: "filled",
        },
        {
          text: "False",
          value: "empty",
        },
      ],
      onFilter: (value, record) => {
        if (value === "filled") {
          return (
            record.discount_value !== null &&
            record.discount_value !== undefined &&
            record.discount_value !== false &&
            record.discount_value !== ""
          );
        } else if (value === "empty") {
          return (
            record.discount_value === null ||
            record.discount_value === undefined ||
            record.discount_value === false ||
            record.discount_value === ""
          );
        }
        return false;
      },
      render: (item) => (
        <>
          <div className="column_info">{translate("Has Discount?")}</div>
          <span className={`table__tag ${item ? "is_true" : "is_false"}`}>
            {item ? "True" : "False"}
          </span>
        </>
      ),
    },
    {
      title: translate("Hazmat?"),
      dataIndex: "hazmat",
      key: "hazmat",
      width: 200,
      minWidth: 100,
      sorter: (a, b) => (a.hazmat === b.hazmat ? 0 : a.hazmat ? -1 : 1),
      filters: [
        {
          text: "True",
          value: "filled",
        },
        {
          text: "False",
          value: "empty",
        },
      ],
      onFilter: (value, record) => {
        if (value === "filled") {
          return (
            record.hazmat !== null &&
            record.hazmat !== undefined &&
            record.hazmat !== false &&
            record.hazmat !== ""
          );
        } else if (value === "empty") {
          return (
            record.hazmat === null ||
            record.hazmat === undefined ||
            record.hazmat === false ||
            record.hazmat === ""
          );
        }
        return false;
      },
      render: (item) => (
        <>
          <div className="column_info">{translate("Hazmat?")}</div>
          <span className={`table__tag ${item ? "is_true" : "is_false"}`}>
            {item ? "True" : "False"}
          </span>
        </>
      ),
    },
    {
      title: translate("Total Offer Count"),
      dataIndex: "total_offer_count",
      key: "total_offer_count",
      width: 200,
      minWidth: 100,
      sorter: (a, b) => a.total_offer_count - b.total_offer_count,
      render: (item, record) => (
        <>
          <div className="column_info">{translate("Total Offer Count")}</div>
          {record.fba_seller_count + record.fbm_seller_count}
        </>
      ),
    },
    {
      title: translate("FBA Seller Count"),
      dataIndex: "fba_seller_count",
      key: "fba_seller_count",
      width: 200,
      minWidth: 100,
      sorter: (a, b) => a.fba_seller_count - b.fba_seller_count,
      render: (item) => (
        <>
          <div className="column_info">{translate("FBA Seller Count")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("FBM Seller Count"),
      dataIndex: "fbm_seller_count",
      key: "fbm_seller_count",
      width: 200,
      minWidth: 100,
      sorter: (a, b) => a.fbm_seller_count - b.fbm_seller_count,
      render: (item) => (
        <>
          <div className="column_info">{translate("FBM Seller Count")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Amazon Seller?"),
      dataIndex: "amazon_seller",
      key: "amazon_seller",
      width: 200,
      minWidth: 100,
      sorter: (a, b) =>
        a.amazon_seller === b.amazon_seller ? 0 : a.amazon_seller ? -1 : 1,
      filters: [
        {
          text: "True",
          value: "filled",
        },
        {
          text: "False",
          value: "empty",
        },
      ],
      onFilter: (value, record) => {
        if (value === "filled") {
          return (
            record.amazon_seller !== null &&
            record.amazon_seller !== undefined &&
            record.amazon_seller !== false &&
            record.amazon_seller !== ""
          );
        } else if (value === "empty") {
          return (
            record.amazon_seller === null ||
            record.amazon_seller === undefined ||
            record.amazon_seller === false ||
            record.amazon_seller === ""
          );
        }
        return false;
      },
      render: (item) => (
        <>
          <div className="column_info">{translate("Amazon Seller?")}</div>
          <span className={`table__tag ${item ? "is_true" : "is_false"}`}>
            {item ? "True" : "False"}
          </span>
        </>
      ),
    },
    {
      title: translate("Brand"),
      dataIndex: "brand",
      key: "brand",
      width: 200,
      minWidth: 100,
      sorter: (a, b) => a.brand - b.brand,
      render: (item) => (
        <>
          <div className="column_info">{translate("Brand")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Binding"),
      dataIndex: "binding",
      key: "binding",
      width: 200,
      minWidth: 100,
      sorter: (a, b) => a.binding - b.binding,
      render: (item) => (
        <>
          <div className="column_info">{translate("Binding")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Product Type"),
      dataIndex: "product_type_name",
      key: "product_type_name",
      width: 200,
      minWidth: 100,
      sorter: (a, b) => a.product_type_name - b.product_type_name,
      render: (item) => (
        <>
          <div className="column_info">{translate("Product Type")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Item Weight"),
      dataIndex: "item_weight",
      key: "item_weight",
      width: 200,
      minWidth: 100,
      sorter: (a, b) => a.item_weight - b.item_weight,
      render: (item) => (
        <>
          <div className="column_info">{translate("Item Weight")} (KG)</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Item Measures"),
      dataIndex: "item_dimensions",
      key: "item_dimensions",
      width: 200,
      minWidth: 100,
      sorter: (a, b) => a.item_dimensions - b.item_dimensions,
      render: (item) => (
        <>
          <div className="column_info">{translate("Item Measures")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Package Measures"),
      dataIndex: "package_dimensions",
      key: "package_dimensions",
      width: 200,
      minWidth: 100,
      sorter: (a, b) => a.package_dimensions - b.package_dimensions,
      render: (item) => (
        <>
          <div className="column_info">{translate("Package Measures")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Action"),
      key: "action",
      fixed: "right",
      width: 100,
      minWidth: 80,
      className: "table_actin",
      render: (text, record) => (
        <>
          <div className="column_info">{translate("Details")}</div>

          <Tooltip
            title={translate("Show Product Based Info")}
            placement="left"
          >
            <NavLink
              className={"pbi__link table_icon"}
              to={`/product-based-info/${record.asin}`}
              target="_blank"
            >
              <FeatherIcon icon={"package"} />
            </NavLink>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <>
      <div
        className={`dif__ablee ${isTableSort === "list" ? " " : " block_m"}`}
      >
        <div className="merged___area">
          <div className="table__sortn">
            <span
              onClick={() => setTableSort("list")}
              className={`${isTableSort === "list" ? "active" : ""}`}
            >
              <FeatherIcon icon={"list"} />
            </span>
            <span
              onClick={() => setTableSort("grid")}
              className={`${isTableSort === "grid" ? "active" : ""}`}
            >
              <FeatherIcon icon={"grid"} />
            </span>
          </div>
        </div>
        <Table
          key={`${resetTrigger}-table__cmp`}
          columns={columns}
          dataSource={dataSource}
          pagination={{
            locale: {
              items_per_page: translate("Page"),
            },
            position: ["topRight", "bottomRight"],
          }}
          locale={{
            emptyText: (
              <span className="empty__tble">
                <img src={EmptyIcon} />
              </span>
            ),
          }}
          className="cmp__table has_pag_btn rest_table"
          loading={{
            spinning: !data || data.length === 0,
            indicator: <div className="bt-spinner"></div>,
          }}
        />
      </div>
    </>
  );
};

export default ComparisonTable;
