import React, { useState } from "react";
import { Tooltip, Button, Modal, Space } from "antd";
import FeatherIcon from "feather-icons-react";
import translate from "../../../../shared/utils/translations";
import SellersDataModal from "./_sellers_data_modal";
// erdem gy
const ManualCalculation = ({
  setAsinFetcher,
  targetOffersData,
  preferenceData,
  asin,
  availableMarkets,
  currency,
  setCurrency,
  basePrice,
  setBasePrice,
  targetPrice,
  setTargetPrice,
  shippingCost,
  setShippingCost,
}) => {
  const [isSellerModal, setSellerModal] = useState(false);

  return (
    <>
      <SellersDataModal
        isSellerModal={isSellerModal}
        setSellerModal={setSellerModal}
        setAsinFetcher={setAsinFetcher}
      />

      <div className="product__cmp_info ttr pbi_m_card">
        <div className="lft__Card">
          <div className="mnll__box">
            <div className="calc__inppt">
              <div className="field__label">{translate("Buying Price")}</div>
              <div className="field__wrap">
                <input
                  className="field__input"
                  type="number"
                  placeholder={translate("10 etc.")}
                  value={basePrice}
                  onChange={(e) => setBasePrice(e.target.value)}
                />
              </div>
            </div>

            <div className="calc__inppt">
              <div className="field__label">{translate("Inbound Cost")}</div>
              <div className="field__wrap">
                <input
                  className="field__input"
                  type="number"
                  placeholder={translate("10 etc.")}
                  value={shippingCost}
                  onChange={(e) => setShippingCost(e.target.value)}
                />
              </div>
            </div>
            <div className="calc__inppt">
              <div className="field__label">{translate("Currency")}</div>
              <div className="field__wrap">
                <div class="select__field">
                  <select
                    class="select nice-select app d_block"
                    name="currency"
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                  >
                    {[...new Set(availableMarkets?.map((i) => i.currency))].map(
                      (i, index) => {
                        return (
                          <option key={index} value={i}>
                            {i}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManualCalculation;
