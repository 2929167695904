import React, { useEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import FaqItem from "./_faq_item";
import toast, { Toaster } from "react-hot-toast";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import { requestHandler } from "../../../shared/utils/helpers";
import translate from "../../../shared/utils/translations";
import Lottie from "react-lottie-player";
import sadAnim from "../../../../assets/animations/sad.json";
import landAnim from "../../../../assets/animations/land.json";

const Support = ({ setPage }) => {
  useEffect(() => {
    setPage("landing");
    currentLanguage(localStorage.getItem("lang") || "EN");
  });

  const [formData, setFormdData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [isSelectedFaq, setSelectedFaq] = useState("General");
  const [currentLang, currentLanguage] = useState({});

  const faqItems = [
    {
      id: 1,
      f_n: "01",
      f_t: translate("Which marketplaces does Kepicker work in?"),
      f_d: translate(
        "Kepicker operates across all Amazon marketplaces, whether in the Americas, Europe, or Asia-Pacific. To utilize it in your desired marketplace, you must connect the specific marketplace(s) you wish to work with. This is because the system operates using your SP-API."
      ),
    },
    {
      id: 2,
      f_n: "02",
      f_t: translate("What does Kepicker provide me?"),
      f_d: translate(
        "Kepicker is a company that focuses on the Online Arbitrage model for sellers selling on Amazon and produces software to meet the needs of sellers using this trading system."
      ),
    },
    {
      id: 3,
      f_n: "03",
      f_t: translate(
        "Is Kepicker reliable, and does it view my store information?"
      ),
      f_d: translate(
        "Rest assured, Kepicker does not use third-party software for market connections like some other applications, so be cautious. If you encounter a software using a different tool for market connection, it is advisable to reconsider, as this might mean your data is being shared with other tools. Kepicker's requirement for market connection is due to its operation via SP-API, which is currently the most secure and accurate solution."
      ),
    },
    {
      id: 4,
      f_n: "04",
      f_t: translate("Can I find unlimited products?"),
      f_d: translate(
        "As you can see on the pricing page, we have limits. You can buy the package you need from the pricing page and set sail for very profitable products by taking advantage of these quotas."
      ),
    },
    {
      id: 5,
      f_n: "05",
      f_t: translate("Why is it mandatory to connect the marketplace?"),
      f_d: translate(
        "The system uses your SP-API to perform comparisons across different marketplaces."
      ),
    },
    {
      id: 6,
      f_n: "06",
      f_t: translate("Can I cancel my subscription at any time?"),
      f_d: translate(
        "Certainly, you can cancel your subscription whenever you wish. Upon cancellation, any remaining usage limit will continue until the end of your current billing cycle."
      ),
    },
    {
      id: 7,
      f_n: "07",
      f_t: translate("Can I change my package at any time?"),
      f_d: translate(
        "Absolutely, you may upgrade or downgrade your package whenever you desire."
      ),
    },
    {
      id: 8,
      f_n: "08",
      f_t: translate("Is it possible to delete my account?"),
      f_d: translate(
        "Yes, you can delete your account. When you delete your account, all your personal data will be deleted. Your other data regarding the Kepicker software continues to be kept anonymous."
      ),
    },
    // {
    //   id: 9,
    //   f_n: "09",
    //   f_t: translate(
    //     "If I encounter an issue with the extension, do you provide compensation?"
    //   ),
    //   f_d: translate(
    //     "Do not worry, if you face an issue and it is confirmed, our technical team will immediately send you additional tokens as compensation. After all, this is software, and glitches or problems can occur. We would appreciate your understanding in such situations."
    //   ),
    // },
  ];

  const [isBtnLoading, setBtnLoading] = useState(false);
  const [isOverflow, setOverflow] = useState(null);
  const submit = () => {
    setBtnLoading(true);
    //check if all fields are filled
    if (
      formData.name === "" ||
      formData.email === "" ||
      formData.subject === "" ||
      formData.message === ""
    ) {
      toast.error(translate("Please fill all fields!"));
      setBtnLoading(false);
      return;
    }
    //check if email is valid
    if (!formData.email.includes("@")) {
      toast.error(translate("Please enter a valid email address!"));
      setBtnLoading(false);
      return;
    }
    //check if message is longer than 10 characters
    if (formData.message.length < 10) {
      toast.error(
        translate("Please enter a message longer than 10 characters!")
      );
      setBtnLoading(false);
      return;
    }
    //check if subject is longer than 5 characters
    if (formData.subject.length < 5) {
      toast.error(
        translate("Please enter a subject longer than 5 characters!")
      );
      setBtnLoading(false);
      return;
    }
    requestHandler("post", `/support`, formData, false, null, null).then(
      (res) => {
        toast.success(translate("Your message has been sent successfully!.."));
        setBtnLoading(false);
      }
    );
  };

  const [isRemovedForm, setRemovedForm] = useState(false);
  const [isGuide, setGuide] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const extensionRemoved = queryParams.get("removed");
    const extensionGuide = queryParams.get("guide");

    let originalOverflow = document.body.style.overflow;
    setOverflow(originalOverflow);

    if (extensionRemoved) {
      setRemovedForm(true);
      document.body.style.overflow = "hidden";
    }

    if (extensionGuide) {
      setGuide(true);
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, []);

  const resetOverflow = () => {
    document.body.style.overflow = isOverflow;
  };

  return (
    <>
      <Helmet>
        <title>{translate("Support")}</title>
        <meta
          name="description"
          content={translate(
            "Got A Question? Let's Talk! - Contact us to learn how we can help your business or get answer to technical questions about Kepicker."
          )}
        />
      </Helmet>

      <div className={`user__gide ${isGuide && " show"}`}>
        <div className="card__kpp wlcm marketplace-step modal__it">
          <button
            onClick={() => {
              setGuide(false);
              resetOverflow();
            }}
            className={`close_ui_btn`}
          >
            <FeatherIcon icon={"x"} />
          </button>

          <div className="itmm__wlcc">
            <h3>
              <span>{translate("Hello")}</span>
              <img src="/assets/img/wave.png"></img>
            </h3>
            <p>
              {translate(
                "Have you tried following the suggestions in this list in order?"
              )}
            </p>
          </div>
          <div className="card__kpp_welcome">
            <div className="itmm__wlcc">
              <h4>{translate("Beginner's Guide")}</h4>
              <ul>
                <li>
                  <img src="/assets/img/store.png"></img>
                  <div className="inf__thk">
                    <div className="title__thk">
                      1. {translate("Connect your marketplace.")}
                    </div>
                    <a
                      href="https://www.youtube.com/watch?v=DObvOu2-98Q&list=PLxi7_IEBzcmj2eio4ZJTsAID6AD08s3t8&index=8"
                      target="_blank"
                      className="desc__thk"
                    >
                      {translate("How can I connect my marketplace?")}
                    </a>
                  </div>
                </li>
                <li>
                  <img src="/assets/img/setting.png"></img>
                  <div className="inf__thk">
                    <div className="title__thk">
                      2. {translate("Edit marketplace options.")}
                    </div>
                    <NavLink
                      to="/marketplace-settings"
                      className="desc__thk"
                      onClick={() => {
                        setGuide(false);
                        resetOverflow();
                      }}
                    >
                      {translate("Where are my marketplace settings?")}
                    </NavLink>
                  </div>
                </li>
                <li>
                  <img src="/assets/img/cloud-download.png"></img>
                  <div className="inf__thk">
                    <div className="title__thk">
                      3. {translate("Download the Kepicker extension.")}
                    </div>
                    <a
                      href="https://chromewebstore.google.com/detail/kepicker/hnpablpopdlabbiopkihkmlfmkgpihdc?hl=en"
                      target="_blank"
                      className="desc__thk"
                    >
                      {translate("Where do I download it?")}
                    </a>
                  </div>
                </li>
                <li>
                  <img src="/assets/img/agenda.png"></img>
                  <div className="inf__thk">
                    <div className="title__thk">
                      4. {translate("Now you are ready to use the extension.")}
                    </div>
                    <NavLink
                      to="/how-it-works"
                      className="desc__thk"
                      onClick={() => {
                        setGuide(false);
                        resetOverflow();
                      }}
                    >
                      {translate("User guide for the extension?")}
                    </NavLink>
                  </div>
                </li>
              </ul>
            </div>

            <div className="itmm__wlcc">
              <h4>{translate("Still having problems?")}</h4>

              <div className="contact__arnvb">
                <div className="contact__arnv_itm">
                  <img src="/assets/img/ticket.png"></img>
                  <div className="title__thk">{translate("Create Ticket")}</div>
                  <NavLink
                    to="/tickets"
                    className="desc__thk"
                    onClick={() => {
                      setGuide(false);
                      resetOverflow();
                    }}
                  >
                    {translate("Create a Support Request")}
                  </NavLink>
                </div>
                <div className="contact__arnv_itm">
                  <img src="/assets/img/whatsapp.png"></img>
                  <div className="title__thk">
                    {translate("Whatsapp Support")}
                  </div>
                  <a
                    href="https://wa.me/+905076106514?text=Merhabalar,%20Kepicker%20eklentisi%20ile%20ilgili%20destek%20almak%20istiyorum.%20Yardımcı%20olabilir%20misiniz?"
                    target="_blank"
                    className="desc__thk"
                  >
                    +90 507 610 65 14
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`outer__inner landng gg ${
          isRemovedForm && " removed__mode"
        }`}
      >
        <div className="section onex_d support">
          <div className="onex_d__center center">
            <div className="onex_d__video">
              <img
                src="/assets/img/support.png"
                alt="Kepicker Support Main Image"
              />
            </div>
            <div className="onex_d__head">
              <h1 className="onex_d__title h1">
                {translate("How can we help")}
              </h1>
              <div className="onex_d__info">
                {translate("Got A Question? Let's Talk!")}
              </div>

              {currentLang === "TR" ? (
                <div className="onex_d__btns">
                  <a
                    className="button onex_d__button js-scroll"
                    href="https://onexyazilim.com/amazon/kepicker/tr/blog"
                    target="_blank"
                  >
                    Blog
                  </a>
                  <NavLink
                    className="button-stroke onex_d__button js-scroll"
                    to="/how-it-works"
                  >
                    {translate("How It Works")}
                  </NavLink>
                </div>
              ) : currentLang === "DE" ? (
                <div className="onex_d__btns">
                  <a
                    className="button onex_d__button js-scroll"
                    href="https://onexyazilim.com/amazon/kepicker/de/"
                    target="_blank"
                  >
                    Blog
                  </a>
                  <NavLink
                    className="button-stroke onex_d__button js-scroll"
                    to="/how-it-works"
                  >
                    {translate("How It Works")}
                  </NavLink>
                </div>
              ) : (
                <div className="onex_d__btns">
                  <a
                    className="button onex_d__button js-scroll"
                    href="https://onexyazilim.com/amazon/kepicker/en/blog"
                    target="_blank"
                  >
                    Blog
                  </a>
                  <NavLink
                    className="button-stroke onex_d__button js-scroll"
                    to="/how-it-works"
                  >
                    {translate("How It Works")}
                  </NavLink>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="section faq1 js-tabs">
          <div className="faq1__center center">
            <div className="faq1__top">
              <h2 className="faq1__title h2">
                {translate("Frequently asked questions")}
              </h2>
              {/* <div className="nav">
                <a
                  className={`nav__link ${
                    isSelectedFaq === "General" ? " active" : " "
                  }`}
                  onClick={() => setSelectedFaq("General")}
                  href="#!"
                >
                  General
                </a>
                <a
                  className={`nav__link ${
                    isSelectedFaq === "Support" ? " active" : " "
                  }`}
                  onClick={() => setSelectedFaq("Support")}
                  href="#!"
                >
                  Support
                </a>
                <a
                  className={`nav__link ${
                    isSelectedFaq === "Pricing" ? " active" : " "
                  }`}
                  onClick={() => setSelectedFaq("Pricing")}
                  href="#!"
                >
                  Pricing
                </a>
              </div> */}
            </div>
            <div className="faq1__list">
              {faqItems.map((item, index) => (
                <FaqItem
                  f_n={item.f_n}
                  f_t={item.f_t}
                  f_d={item.f_d}
                  key={index}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="section app section-mb0 question pt__0 form__gbb">
          <div className="lottie__animm">
            <Lottie
              className="ovw_lott land"
              loop
              animationData={landAnim}
              play
            />
            <Lottie
              className="ovw_lott user"
              loop
              animationData={sadAnim}
              play
            />
          </div>
          <div className="question__center center">
            <h2 className="faq1__title h2 gg">{translate("Get In Touch")}</h2>
            <h2 className="faq1__title h2 bb">
              {translate(
                "We're Sorry You Uninstalled Kepicker, Can We Know Why?"
              )}
            </h2>
            <div className="question__form">
              <p className="remov__desc">
                {translate(
                  "We noticed you've removed Kepicker and we're curious to know why. Could you spare a moment to share your feedback through a quick survey? Your insights are invaluable to us for providing better service in the future. We'll always be here, waiting for your return!"
                )}
              </p>
              <div className="question__fieldset">
                <div className="field">
                  <div className="field__label">{translate("Full Name")}</div>
                  <div className="field__wrap">
                    <input
                      className="field__input"
                      type="text"
                      name="name"
                      onChange={(e) => {
                        setFormdData({ ...formData, name: e.target.value });
                      }}
                      required=""
                      wfd-id="id4"
                    />
                  </div>
                </div>
                <div className="field">
                  <div className="field__label">
                    {translate("Email address")}
                  </div>
                  <div className="field__wrap">
                    <input
                      className="field__input"
                      type="email"
                      name="email"
                      onChange={(e) => {
                        setFormdData({ ...formData, email: e.target.value });
                      }}
                      required=""
                      wfd-id="id5"
                    />
                  </div>
                </div>
                <div className="field">
                  <div className="field__label">{translate("Subject")}</div>
                  <div className="field__wrap">
                    <input
                      className="field__input"
                      type="text"
                      name="subject"
                      onChange={(e) => {
                        setFormdData({ ...formData, subject: e.target.value });
                      }}
                      required=""
                      wfd-id="id6"
                    />
                  </div>
                </div>
                <div className="field">
                  <div className="field__label">{translate("Message")}</div>
                  <div className="field__wrap">
                    <textarea
                      className="field__textarea"
                      name="message"
                      placeholder={translate("Say something please...")}
                      onChange={(e) => {
                        setFormdData({ ...formData, message: e.target.value });
                      }}
                      required=""
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="question__btns c_form">
                <button
                  className="button-small question__button app"
                  onClick={submit}
                >
                  {translate("Send message")}
                  {isBtnLoading && <span className="btn-spinner"></span>}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="section onex_p promo_b">
          <div className="onex_p__center center">
            <div className="onex_p__inner">
              <div className="onex_p__head">
                {/* <h2 className="onex_p__title h2">Nulla nec interdum felis</h2> */}
                <div className="onex_p__info info">
                  {translate(
                    "Stuck? Sometimes we just need to talk to a human."
                  )}
                  <h2 className="releases__title h2 mt_40">
                    <a target={"_blank"} href="tel:+902323322151">
                      +90 (232) 332 21 51
                    </a>
                    <br />
                    <a target={"_blank"} href="tel:+905545556218">
                      +90 (554) 555 62 18
                    </a>
                  </h2>
                  <div className="releases__info">
                    <a target={"_blank"} href="mailto:info@kepicker.com">
                      info@kepicker.com
                    </a>
                  </div>
                </div>
              </div>
              <div className="onex_p__wrapper">
                <a
                  target="_blank"
                  href="https://twitter.com/kepicker_"
                  className="social_link twitter"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24">
                    <path d="M24.075 4.325c-.9.375-1.8.675-2.85.75 1.05-.6 1.8-1.575 2.175-2.7-.975.6-2.025.975-3.15 1.2C19.35 2.6 18.075 2 16.725 2c-2.7 0-4.95 2.175-4.95 4.95 0 .375.075.75.15 1.125C7.8 7.85 4.2 5.9 1.725 2.9a4.72 4.72 0 0 0-.675 2.475c0 1.725.9 3.225 2.175 4.125-.825 0-1.575-.225-2.25-.6v.075c0 2.4 1.725 4.35 3.975 4.8-.45.075-.825.15-1.275.15-.3 0-.6 0-.9-.075.6 1.95 2.475 3.375 4.575 3.45-1.65 1.35-3.825 2.1-6.15 2.1-.375 0-.825 0-1.2-.075 2.25 1.35 4.875 2.175 7.65 2.175 9.075 0 14.025-7.5 14.025-14.025v-.6c.9-.75 1.725-1.575 2.4-2.55z"></path>
                  </svg>
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/kepicker"
                  className="social_link instagram"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24">
                    <path d="M12 15.8a3.8 3.8 0 1 0 0-7.6 3.8 3.8 0 0 0-3.291 5.7A3.8 3.8 0 0 0 12 15.8h0zM16.749.6h-9.5A6.66 6.66 0 0 0 .6 7.251v9.5A6.66 6.66 0 0 0 7.251 23.4h9.5a6.66 6.66 0 0 0 6.649-6.651v-9.5A6.66 6.66 0 0 0 16.749.6zM12 17.7A5.71 5.71 0 0 1 6.3 12 5.71 5.71 0 0 1 12 6.3a5.71 5.71 0 0 1 5.7 5.7 5.71 5.71 0 0 1-5.7 5.7zm6.65-11.4a.95.95 0 0 1 0-1.9.95.95 0 1 1 0 1.9z"></path>
                  </svg>
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/kepicker.software/"
                  className="social_link facebook"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24">
                    <path d="M9.347 23.25V13.5H6V9h3.347V5.857c0-3.423 2.167-5.107 5.221-5.107 1.463 0 2.72.109 3.086.158v3.577l-2.118.001c-1.661 0-2.036.789-2.036 1.947V9H18l-1.5 4.5h-3v9.75H9.347z"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
