import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import translate from "../../../shared/utils/translations";
import Marquee from "react-fast-marquee";
import TagsCloud from "./_tags_cloud";
import ModalVideo from "react-modal-video";

function truncateString(str, num) {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
}

const Blog = ({ setPage }) => {
  useEffect(() => {
    setPage("landing");
    currentLanguage(localStorage.getItem("lang") || "EN");
  });
  const [currentLang, currentLanguage] = useState({});

  const [isOpen, setOpen] = useState(false);
  const [isVideo, setVideo] = useState("y3IeeNrqvMk");
  const [isSelectedTag, setSelectedTag] = useState("All");
  return (
    <>
      <Helmet>
        <title>Blog</title>
        <meta
          name="description"
          content={translate("Latest News and Updates about Kepicker.")}
        />
      </Helmet>

      <ModalVideo
        channel="youtube"
        youtube={{ mute: 0, autoplay: 0 }}
        isOpen={isOpen}
        videoId={isVideo}
        onClose={() => setOpen(false)}
      />

      <div className="outer__inner landng blog__page">
        <div className="section app main about">
          <div className="main__center center">
            <div className="main__wrap app">
              <h1 className="onex_d__title h1">
                {translate("Latest Releases")}
              </h1>
              <div className="main__text gghj">
                {translate("Latest News and Updates about Kepicker.")}
              </div>

              {currentLang === "TR" ? (
                <div className="onex_d__btns ssv">
                  <a
                    className="button onex_d__button js-scroll"
                    href="https://onexyazilim.com/amazon/kepicker/tr/blog"
                    target="_blank"
                  >
                    {translate("Useful Articles")}
                  </a>
                  <a
                    className="button-stroke onex_d__button js-scroll"
                    href="https://onexyazilim.com/amazon/kepicker/tr/sss"
                    target="_blank"
                  >
                    {translate("FAQs")}
                  </a>
                </div>
              ) : currentLang === "DE" ? (
                <div className="onex_d__btns ssv">
                  <a
                    className="button onex_d__button js-scroll"
                    href="https://onexyazilim.com/amazon/kepicker/de/"
                    target="_blank"
                  >
                    Blog
                  </a>
                  <a
                    className="button-stroke onex_d__button js-scroll"
                    href="https://onexyazilim.com/amazon/kepicker/de/"
                    target="_blank"
                  >
                    {translate("FAQs")}
                  </a>
                </div>
              ) : (
                <div className="onex_d__btns ssv">
                  <a
                    className="button onex_d__button js-scroll"
                    href="https://onexyazilim.com/amazon/kepicker/en/blog"
                    target="_blank"
                  >
                    Blog
                  </a>
                  <a
                    className="button-stroke onex_d__button js-scroll"
                    href="https://onexyazilim.com/amazon/kepicker/en/faq"
                    target="_blank"
                  >
                    {translate("FAQs")}
                  </a>
                </div>
              )}
              <p className="blg_lined_txt blg">
                {translate("Connect with us on")}
              </p>

              <div className="onex_p__wrapper blg">
                <a
                  target="_blank"
                  href="https://twitter.com/kepicker_"
                  className="social_link twitter"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24">
                    <path d="M24.075 4.325c-.9.375-1.8.675-2.85.75 1.05-.6 1.8-1.575 2.175-2.7-.975.6-2.025.975-3.15 1.2C19.35 2.6 18.075 2 16.725 2c-2.7 0-4.95 2.175-4.95 4.95 0 .375.075.75.15 1.125C7.8 7.85 4.2 5.9 1.725 2.9a4.72 4.72 0 0 0-.675 2.475c0 1.725.9 3.225 2.175 4.125-.825 0-1.575-.225-2.25-.6v.075c0 2.4 1.725 4.35 3.975 4.8-.45.075-.825.15-1.275.15-.3 0-.6 0-.9-.075.6 1.95 2.475 3.375 4.575 3.45-1.65 1.35-3.825 2.1-6.15 2.1-.375 0-.825 0-1.2-.075 2.25 1.35 4.875 2.175 7.65 2.175 9.075 0 14.025-7.5 14.025-14.025v-.6c.9-.75 1.725-1.575 2.4-2.55z"></path>
                  </svg>
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/kepicker"
                  className="social_link instagram"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24">
                    <path d="M12 15.8a3.8 3.8 0 1 0 0-7.6 3.8 3.8 0 0 0-3.291 5.7A3.8 3.8 0 0 0 12 15.8h0zM16.749.6h-9.5A6.66 6.66 0 0 0 .6 7.251v9.5A6.66 6.66 0 0 0 7.251 23.4h9.5a6.66 6.66 0 0 0 6.649-6.651v-9.5A6.66 6.66 0 0 0 16.749.6zM12 17.7A5.71 5.71 0 0 1 6.3 12 5.71 5.71 0 0 1 12 6.3a5.71 5.71 0 0 1 5.7 5.7 5.71 5.71 0 0 1-5.7 5.7zm6.65-11.4a.95.95 0 0 1 0-1.9.95.95 0 1 1 0 1.9z"></path>
                  </svg>
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/kepicker.software/"
                  className="social_link facebook"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24">
                    <path d="M9.347 23.25V13.5H6V9h3.347V5.857c0-3.423 2.167-5.107 5.221-5.107 1.463 0 2.72.109 3.086.158v3.577l-2.118.001c-1.661 0-2.036.789-2.036 1.947V9H18l-1.5 4.5h-3v9.75H9.347z"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="section onex_w hero__img abt">
            <img src="/assets/img/blog.jpg" alt="Kepicker Blog Image"></img>
          </div>

          <div
            style={{ marginBottom: "-15px", marginTop: "0px" }}
            className="section-bg app section-mb0 releases"
          >
            <div className="releases__center center">
              <div className="blog__navs">
                <div className="nav">
                  <a
                    className={`nav__link ${
                      isSelectedTag === "All" ? " active" : " "
                    }`}
                    onClick={() => setSelectedTag("All")}
                    role="button"
                  >
                    {translate("All")}
                  </a>
                  <a
                    className={`nav__link ${
                      isSelectedTag === "Webinar" ? " active" : " "
                    }`}
                    onClick={() => setSelectedTag("Webinar")}
                    role="button"
                  >
                    {translate("Webinar")}
                  </a>
                  <a
                    className={`nav__link ${
                      isSelectedTag === "Event" ? " active" : " "
                    }`}
                    onClick={() => setSelectedTag("Event")}
                    role="button"
                  >
                    {translate("Event")}
                  </a>
                  <a
                    className={`nav__link ${
                      isSelectedTag === "Blog" ? " active" : " "
                    }`}
                    onClick={() => setSelectedTag("Blog")}
                    role="button"
                  >
                    Blog
                  </a>
                </div>
              </div>

              <div className="releases__list">
                {isSelectedTag === "All" || isSelectedTag === "Blog" ? (
                  <>
                    <NavLink
                      className="releases__item"
                      rel="nofollow"
                      to="/boost-amazon-sales-sellersprite-kepicker"
                    >
                      <div className="releases__preview">
                        <img
                          src="/assets/img/blog/sellersprite_kepicker.jpg"
                          alt="SellerSprite & Kepicker"
                        />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "Boost Your Amazon Sales with SellerSprite & Kepicker!",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog blgg">
                            Blog
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_en"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "Discover how SellerSprite and Kepicker can transform your Amazon selling strategy with advanced product research, market insights, and competitive analysis tools.",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </NavLink>
                  </>
                ) : (
                  <></>
                )}

                {isSelectedTag === "All" || isSelectedTag === "Webinar" ? (
                  <>
                    <a
                      className="releases__item"
                      role="button"
                      onClick={() => {
                        setVideo("9EINdIu1pGU");
                        setOpen(true);
                      }}
                    >
                      <div className="releases__preview">
                        <img src="/assets/img/blog/w5.jpg" alt="" />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "Amzzoom ile Kepicker Programının Kullanımı ve Tanıtımı",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog">
                            {translate("Webinar")}
                          </div>
                          <div className="releases__onex_d bdg_blog date">
                            20.05.2024
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_tr"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "Amazon Online Arbitraj ve Dropshipping Satıcılarının Yeni ve İşlevsel Programı Kepicker Türk Yazılımı olan bu programı herkese tavsiye ederim.",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </a>
                    <a
                      className="releases__item"
                      role="button"
                      onClick={() => {
                        setVideo("o2Jq7LRp8KI");
                        setOpen(true);
                      }}
                    >
                      <div className="releases__preview">
                        <img src="/assets/img/blog/w3.jpg" alt="" />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "Berk Boyacıoğlu & Kepicker ile Kepicker Tanıtım Webinarı!",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog">
                            {translate("Webinar")}
                          </div>
                          <div className="releases__onex_d bdg_blog date">
                            11.04.2024
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_tr"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "Basarili bir Amazon saticisi olmanin en buyuk olcutu yuksek karli urunleri satabilmekten geciyor. Urun arama kapasitemizi ne kadar genis tutarsak urun aramadaki basarimiz ayni oranda artar.",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </a>

                    <a
                      className="releases__item"
                      role="button"
                      onClick={() => {
                        setVideo("b2QC2IwG7zU");
                        setOpen(true);
                      }}
                    >
                      <div className="releases__preview">
                        <img src="/assets/img/blog/w4.jpg" alt="" />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "URS Amazon Danışmanlık - Kepicker Tools ile Amazon'da Ürün Bulma!",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog">
                            {translate("Webinar")}
                          </div>
                          <div className="releases__onex_d bdg_blog date">
                            26.03.2024
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_tr"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "Kepicker Tools, Türk yazılımcılar tarafından geliştirilen ve Amazon'da ürün bulma ve analiz etme sürecini tamamen yeniden tanımlayan bir yazılım. Bu muhteşem araç sayesinde, Helium10, Keepa, Egrow, Selleramp ve birçok diğer Amazon yazılımının sunduğu özellikleri tek bir platformda birleştiriyoruz!",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </a>

                    <a
                      className="releases__item"
                      role="button"
                      onClick={() => {
                        setVideo("L0AgzuFoQys");
                        setOpen(true);
                      }}
                    >
                      <div className="releases__preview">
                        <img src="/assets/img/w2.jpg" alt="" />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "Massimo & Kepicker ile Amazon’a Yeni Bir Adım Atın!",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog">
                            {translate("Webinar")}
                          </div>
                          <div className="releases__onex_d bdg_blog date">
                            21.03.2024
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_tr"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "Massimo Gürkan'ın derin satış stratejileri uzmanlığı ve Kepicker'ın yenilikçi teknolojik çözümleri ile tanışın. Bu webinar, Amazon'da satış yapmayı düşünen herkes için kaçırılmayacak bir fırsat sunuyor!...",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </a>

                    <a
                      className="releases__item"
                      role="button"
                      onClick={() => {
                        setVideo("y3IeeNrqvMk");
                        setOpen(true);
                      }}
                    >
                      <div className="releases__preview">
                        <img src="/assets/img/w1.jpg" alt="" />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "Kepicker ve Comfyship Partnerliğinde Kepicker Tanıtım",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog">
                            {translate("Webinar")}
                          </div>
                          <div className="releases__onex_d bdg_blog date">
                            16.03.2024
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_tr"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "🎉💡 Kepicker, Amazon satıcıları için devrim niteliğinde bir araç seti sunar ve bu özel tanıtım videosu, Amazonpazarında nasıl bir fark yaratabileceğinizi derinlemesine inceliyor...",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </a>
                  </>
                ) : (
                  <></>
                )}

                {isSelectedTag === "All" || isSelectedTag === "Event" ? (
                  <>
                    <a
                      className="releases__item"
                      href="https://sksdb.bandirma.edu.tr/tr/sksdb/Haber/Goster/Uluslararasi-Ticaret-Toplulugumuzca-E--Ticarette-Amazon-Pazaryeri-Is-Modelleri-ve-Basarili-Satis-Stratejileri-Konferansi-Yapildi-23704"
                      target="_blank"
                      rel="nofollow noopener"
                    >
                      <div className="releases__preview">
                        <img src="/assets/img/w3.jpg" alt="" />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "E-Ticarette Amazon Pazaryeri: İş Modelleri ve Başarılı Satış Stratejileri",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog knf">
                            {translate("Event")}
                          </div>
                          <div className="releases__onex_d bdg_blog date">
                            12.03.2024
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_tr"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "Geleneksel perakende işletmeleri, ürünleri stoklayarak ve depolayarak önemli miktarda sermaye bağlamak zorundadır. Ancak dropshipping işletmeleri, bu riskleri en aza indirir, çünkü ürünler satılmadığı sürece ödeme yapılmaz....",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </a>
                  </>
                ) : (
                  <></>
                )}

                {isSelectedTag === "All" || isSelectedTag === "Blog" ? (
                  <>
                    <a
                      className="releases__item"
                      href="https://onexyazilim.com/amazon/kepicker/tr/amazon-yurtdisinda-avrupada-turkiyede-en-cok-satilan-urunler"
                      target="_blank"
                    >
                      <div className="releases__preview">
                        <img
                          src="/assets/img/blog/amazon-yurtdisinda-avrupada-turkiyede-en-cok-satilan-urunler.jpg"
                          alt="Amazon Yurtdışında, Avrupa'da ve Türkiye'de En Çok Satılan Ürünler"
                        />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "Amazon Yurtdışında, Avrupa'da ve Türkiye'de En Çok Satılan Ürünleri Keşfedin!",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog blgg">
                            Blog
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_tr"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "Amazon Avrupa'da, yurtdışında ve amazon Türkiye'de en çok satılan ürünler nelerdir? En çok satan Tür...",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </a>

                    <a
                      className="releases__item"
                      href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-karli-urunler"
                      target="_blank"
                    >
                      <div className="releases__preview">
                        <img
                          src="/assets/img/blog/amazonda-karli-urunler.jpg"
                          alt="Amazon'da Karlı Ürünler"
                        />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "Amazon'da Karlı Ürünler: En Çok Ne Satar ve Satış Yapmak Karlı Mı?",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog blgg">
                            Blog
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_tr"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "Amazon'da satış yapmanın karlı olup olmadığını mı merak ediyorsunuz? En çok satan ürünleri ve Amazon...",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </a>
                    <a
                      className="releases__item"
                      href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-satis-yapmak"
                      target="_blank"
                    >
                      <div className="releases__preview">
                        <img
                          src="/assets/img/blog/amazonda-satis-yapmak.jpg"
                          alt="Amazon'da Satış Yapmak"
                        />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "Amazon'da Satış Yapmak: Amazon Üzerinden Satışın Zorlukları ve İpuçları",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog blgg">
                            Blog
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_tr"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "Amazon'da satış yapmanın zorluklarını mı merak ediyorsunuz? Amazon üzerinden satış yapmak için gerek...",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </a>
                    <a
                      className="releases__item"
                      href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-satis-yapmak-icin-gerekli-sermaye"
                      target="_blank"
                    >
                      <div className="releases__preview">
                        <img
                          src="/assets/img/blog/amazonda-satis-yapmak-icin-gerekli-sermaye.jpg"
                          alt="Amazon'da Satış Yapmak İçin Gerekli Sermaye"
                        />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "Amazon'da Satış Yapmak İçin Gerekli Sermaye: Başlangıç Maliyetleri ve İpuçları",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog blgg">
                            Blog
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_tr"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "Amazon'da satış yapmaya başlamak için ne kadar para lazım? Amazon için gerekli sermaye ve başlangıç ...",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </a>
                    <a
                      className="releases__item"
                      href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-para-kazanmak"
                      target="_blank"
                    >
                      <div className="releases__preview">
                        <img
                          src="/assets/img/blog/amazonda-para-kazanmak.jpg"
                          alt="Amazon'da Para Kazanmak"
                        />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "Amazon'da Para Kazanmak: Nasıl para kazanılır ve mümkün mü?",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog blgg">
                            Blog
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_tr"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "Amazon'dan para kazanmak mümkün mü ve nasıl yapılır? Amazon'da satış yaparak gelir elde etmenin yoll...",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </a>
                    <a
                      className="releases__item"
                      href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-urun-karsilastirma-rehberi"
                      target="_blank"
                    >
                      <div className="releases__preview">
                        <img
                          src="/assets/img/blog/amazonda-urun-karsilastirma-rehberi.jpg"
                          alt="Amazon’da Ürün Karşılaştırma Rehberi"
                        />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "Amazon’da Ürün Karşılaştırma Rehberi: Akıllı Alışverişin Püf Noktaları",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog blgg">
                            Blog
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_tr"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "Milyonlarca ürün arasından en iyisini seçmek mi istiyorsunuz? Amazon'da ürün karşılaştırma yaparken ...",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </a>
                    <a
                      className="releases__item"
                      href="https://onexyazilim.com/amazon/kepicker/tr/amazon-urun-ve-ulkeler-arasi-fiyat-karsilastirma"
                      target="_blank"
                    >
                      <div className="releases__preview">
                        <img
                          src="/assets/img/blog/amazon-urun-ve-ulkeler-arasi-fiyat-karsilastirma.jpg"
                          alt="Amazon Ürün Karşılaştırma"
                        />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "Amazon Ürün Karşılaştırma: Ülkeler Arası Fiyat ve Ürün Araştırma Yöntemleri",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog blgg">
                            Blog
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_tr"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "Amazon ürün karşılaştırma nasıl yapılır ve ülkeler arası fiyat farklılıklarını nasıl değerlendirirsi...",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </a>
                    <a
                      className="releases__item"
                      href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-satilan-urunler"
                      target="_blank"
                    >
                      <div className="releases__preview">
                        <img
                          src="/assets/img/blog/amazonda-satilan-urunler.jpg"
                          alt="Amazon'da Satılan Ürünler"
                        />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "Amazon'da Satılan Ürünler: Ne Kadar Satılıyor ve Hangilerini Satabilirsiniz?",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog blgg">
                            Blog
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_tr"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "Amazon tarafından satılan ürünler arasında en popüler olanları mı merak ediyorsunuz? Hangi ürünlerin...",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </a>
                    <a
                      className="releases__item"
                      href="https://onexyazilim.com/amazon/kepicker/tr/en-iyi-amazon-yazilimlari"
                      target="_blank"
                    >
                      <div className="releases__preview">
                        <img
                          src="/assets/img/blog/en-iyi-amazon-yazilimlari.jpg"
                          alt="En İyi Amazon Yazılımları"
                        />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "En İyi Amazon Yazılımları: Amazon için Hangi Yazılım Kullanılır?",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog blgg">
                            Blog
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_tr"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "Amazon için hangi yazılım kullanılır ve en iyi Amazon yazılımları nelerdir? Satışlarınızı optimize...",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </a>
                    <a
                      className="releases__item"
                      href="https://onexyazilim.com/amazon/kepicker/tr/amazon-urun-arastirma-programlari"
                      target="_blank"
                    >
                      <div className="releases__preview">
                        <img
                          src="/assets/img/blog/amazon-urun-arastirma-programlari.jpg"
                          alt="Amazon Ürün Araştırma Programları"
                        />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "Amazon Ürün Araştırma Programları: Ücretsiz Bulma Araçları",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog blgg">
                            Blog
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_tr"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "Amazon'da satış yapmayı düşünenler için ürün araştırma programları ve ücretsiz bulma araçlarına rehb...",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </a>
                    <a
                      className="releases__item"
                      href="https://onexyazilim.com/amazon/kepicker/tr/amazon-dropshipping-yazilimlari-ve-sermaye-gereksinimleri"
                      target="_blank"
                    >
                      <div className="releases__preview">
                        <img
                          src="/assets/img/blog/amazon-dropshipping-yazilimlari-ve-sermaye-gereksinimleri.jpg"
                          alt="Amazon Dropshipping Yazılımları ve Sermaye Gereksinimleri"
                        />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "Amazon Dropshipping Yazılımları: Dropshipping Yapmak ve Sermaye Gereksinimleri",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog blgg">
                            Blog
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_tr"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "Amazon'da dropshipping yapmak isteyenler için gerekli yazılımlar ve başlangıç sermayesi hakkında bil...",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </a>
                    <a
                      className="releases__item"
                      href="https://onexyazilim.com/amazon/kepicker/tr/amazon-fba-para-kazanma-ve-sermaye-gereksinimleri"
                      target="_blank"
                    >
                      <div className="releases__preview">
                        <img
                          src="/assets/img/blog/amazon-fba-para-kazanma-ve-sermaye-gereksinimleri.jpg"
                          alt="Amazon FBA ile Para Kazanma ve Sermaye Gereksinimleri"
                        />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "Amazon FBA ile Para Kazanma: Sermaye Gereksinimleri ve Başarı Hikayeleri",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog blgg">
                            Blog
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_tr"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "Amazon FBA ile para kazanma yolculuğuna başlamadan önce ne kadar sermaye gerektiğini ve başarılı sat...",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </a>
                    <a
                      className="releases__item"
                      href="https://onexyazilim.com/amazon/kepicker/tr/amazon-fba-urun-bulma-ve-maliyet-hesaplama"
                      target="_blank"
                    >
                      <div className="releases__preview">
                        <img
                          src="/assets/img/blog/amazon-fba-urun-bulma-ve-maliyet-hesaplama.jpg"
                          alt="Amazon FBA Ürün Bulma ve Maliyet Hesaplama"
                        />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "Amazon FBA Ürün Bulma ve Maliyet Hesaplama",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog blgg">
                            Blog
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_tr"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "Amazon FBA ürün bulma sürecini başlatın ve maliyetlerinizi doğru bir şekilde hesaplamak için gerekli...",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </a>
                    <a
                      className="releases__item"
                      href="https://onexyazilim.com/amazon/kepicker/tr/amazon-asin-indirme-ve-magaza-kopyalama"
                      target="_blank"
                    >
                      <div className="releases__preview">
                        <img
                          src="/assets/img/blog/amazon-asin-indirme-ve-magaza-kopyalama.jpg"
                          alt="Amazon ASIN Bulma ve Mağaza Kopyalama"
                        />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "Amazon ASIN Bulma ve Kopyalama: Mağaza Kopyalama ve Liste İndirme Rehberi",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog blgg">
                            Blog
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_tr"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "Amazon mağaza kopyalama işlemleri, ASIN bulma, kopyalama ve listesi indirme yöntemleriyle satış stra...",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </a>
                    <a
                      className="releases__item"
                      href="https://onexyazilim.com/amazon/kepicker/tr/amazon-urun-inceleme-rehberi"
                      target="_blank"
                    >
                      <div className="releases__preview">
                        <img
                          src="/assets/img/blog/amazon-urun-inceleme-rehberi.jpg"
                          alt="Amazon Ürün İnceleme Rehberi"
                        />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "Amazon Ürün İnceleme Rehberi: Bloglar ve Trend Ürünler",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog blgg">
                            Blog
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_tr"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "Amazon'da ürün inceleme nasıl yapılır? Etkili yöntemler ve öne çıkan inceleme blogları hakkında bilg...",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </a>
                    <a
                      className="releases__item"
                      href="https://onexyazilim.com/amazon/kepicker/tr/amazon-rekabet-analizi-ve-pazar-arastirma"
                      target="_blank"
                    >
                      <div className="releases__preview">
                        <img
                          src="/assets/img/blog/amazon-rekabet-analizi-ve-pazar-arastirma.jpg"
                          alt="Amazon Rekabet Analizi ve Pazar Araştırma"
                        />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "Amazon Rekabet Analizi ve Pazar Araştırma: En İyi Araçlar ve İzleme Yöntemleri",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog blgg">
                            Blog
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_tr"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "Amazon rekabet analizi araçları, ürün izleme ve pazar araştırma yöntemleriyle satış stratejinizi nas...",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </a>
                    <a
                      className="releases__item"
                      href="https://onexyazilim.com/amazon/kepicker/tr/amazon-hesaplama-rehberi"
                      target="_blank"
                    >
                      <div className="releases__preview">
                        <img
                          src="/assets/img/blog/amazon_hesaplama_rehberi.jpg"
                          alt="Amazon Hesaplama Rehberi"
                        />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "Amazon Kar HESAPLAMA: Amazon Maliyet Hesaplama ve Komisyon Oranlarına Genel Bakış",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog blgg">
                            Blog
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_tr"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "Amazon Kar Hesaplama rehberimizle, Amazon Maliyet HESAPLAMA yöntemlerini keşfedin ve Amazon Komisyon...",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </a>
                    <a
                      className="releases__item"
                      href="https://onexyazilim.com/amazon/kepicker/tr/en-iyi-amazon-dropshipping-yazilimlari-listesi"
                      target="_blank"
                    >
                      <div className="releases__preview">
                        <img
                          src="/assets/img/blog/amazon_dropshipping_yazilimlari_listesi.jpg"
                          alt="En iyi Amazon Dropshipping Yazılımları Listesi"
                        />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "En iyi Amazon Dropshipping Yazılımları, Fiyatları ve Kar Hesaplama Rehberi",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog blgg">
                            Blog
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_tr"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "En iyi Amazon Dropshipping yazılımları arasından seçim yaparken bütçenize uygun Dropshipping yazılım...",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </a>
                    <a
                      className="releases__item"
                      href="https://onexyazilim.com/amazon/kepicker/tr/amazon-dropshipping-rehberi"
                      target="_blank"
                    >
                      <div className="releases__preview">
                        <img
                          src="/assets/img/blog/amazon_dropshipping_rehberi.jpg"
                          alt="Amazon Dropshipping Rehberi"
                        />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "Amazon Dropshipping Ürün Bulma - En İyi Siteler ve Programlar",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog blgg">
                            Blog
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_tr"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "Amazon Dropshipping ürün bulma stratejilerimizle, en popüler Dropshipping ürün bulma siteleri'nden n...",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </a>
                    <a
                      className="releases__item"
                      href="https://onexyazilim.com/amazon/kepicker/tr/amazon-yazilimlari-rehberi"
                      target="_blank"
                    >
                      <div className="releases__preview">
                        <img
                          src="/assets/img/blog/amazon_yazilimlari_rehberi.jpg"
                          alt="Amazon Yazılımları Rehberi"
                        />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "Amazon Analiz Programları ve Chrome Eklentileri - Helium 10 Benzeri Uygulamalar",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog blgg">
                            Blog
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_tr"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "Amazon'da kullanılan programları ve Amazon analiz programlarına genel bir bakış sunuyoruz. Ayrıca, A...",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </a>
                    <a
                      className="releases__item"
                      href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-satis-rehberi"
                      target="_blank"
                    >
                      <div className="releases__preview">
                        <img
                          src="/assets/img/blog/amazonda_satis_rehberi.jpg"
                          alt="Amazonda Satış Rehberi"
                        />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "Amazon'da Satış Yapmak: Gerekli Adımlar, Avrupa Fırsatları ve Mağaza Açma Rehberi",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog blgg">
                            Blog
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_tr"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "Amazon'da satış yapmak için gerekenler hakkında kapsamlı bir rehber mi arıyorsunuz? Amazon'da nasıl ...",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </a>
                    <a
                      className="releases__item"
                      href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-urun-bulmak"
                      target="_blank"
                    >
                      <div className="releases__preview">
                        <img
                          src="/assets/img/blog/amazon_urun_bulma_rehberi.jpg"
                          alt="Amazon Ürün Bulma Rehberi"
                        />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "Amazon'da Ürün Bulma Rehberi: Satış Potansiyeli Yüksek Ürünler ve İstatistikler",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog blgg">
                            Blog
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_tr"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "Amazon'da ürün bulmak ve Amazon'da satılacak ürün bulmak için en etkili yöntemleri keşfedin. Amazond...",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </a>
                    <a
                      className="releases__item"
                      href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-urun-bulma-programi"
                      target="_blank"
                    >
                      <div className="releases__preview">
                        <img
                          src="/assets/img/blog/amazon_urun_bulma_programlari_listesi.jpg"
                          alt="Amazon Ürün Bulma Programları Listesi"
                        />
                      </div>
                      <div className="releases__details">
                        <h4 className="releases__subtitle h4">
                          {truncateString(
                            "En İyi Amazon Ürün Bulma Programı, Ücretsiz Yazılımlar, Amazon Ürün Yükleme Programı",
                            100
                          )}
                        </h4>
                        <div className="blg__ifs">
                          <div className="releases__onex_d bdg_blog blgg">
                            Blog
                          </div>
                          <div className="releases__onex_d bdg_blog lang">
                            <div className="tagg_tr"></div>
                          </div>
                        </div>
                        <div className="releases__content">
                          {truncateString(
                            "Amazon ürün bulma programı ve Amazon'da ürün bulma yazılımı seçeneklerimizle ideal ürünleri kolayca ...",
                            100
                          )}
                        </div>
                      </div>
                      <div className="releases__arrow">
                        <svg className="icon icon-arrow-next">
                          <use xlinkHref="#icon-arrow-next"></use>
                        </svg>
                      </div>
                    </a>
                  </>
                ) : (
                  <></>
                )}
                {/* <NavLink className="releases__item" to="/blog-details">
                  <div className="releases__preview">
                    <img src="/assets/img/content/main-pic-5.jpg" alt="" />
                  </div>
                  <div className="releases__details">
                    <h4 className="releases__subtitle h4">
                      Suspendisse fermentum augue at augue accumsan?
                    </h4>
                    <div className="releases__onex_d">Aliquam</div>
                    <div className="releases__content">
                      Sed malesuada ante sit amet fermentum rutrum. Nulla
                      egestas facilisis nulla sed condimentum. Aenean nulla
                      orci, convallis ac rutrum vel, convallis ac enim.
                    </div>
                  </div>
                  <div className="releases__arrow">
                    <svg className="icon icon-arrow-next">
                      <use xlinkHref="#icon-arrow-next"></use>
                    </svg>
                  </div>
                </NavLink> */}
              </div>
            </div>
          </div>

          <TagsCloud />
        </div>
      </div>
    </>
  );
};

export default Blog;
