import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import translate from "../../shared/utils/translations";
import { Helmet } from "react-helmet";

const Warehousing = ({ setPage }) => {
  useEffect(() => {
    setPage("landing");
  });
  return (
    <>
    <Helmet>
      <title>{translate("Amazon Storage and Warehousing Services for Ecommerce")}</title>
      <meta
        name="description"
        content={translate(
          "Kepicker – your partner for Amazon warehousing and distribution of products in ecommerce. We specialize in storing your inventory in modern fulfillment warehouses and ensure flawless logistics operations."
        )}
      />
    </Helmet>
    <div className="outer__inner landng">
      <div className="section article">
        <div className="section article__body">
          <div className="article__center center">
            <div className="article__head">
              <h1 className="article__title h1">
              {translate("Amazon Warehousing Services")}
              </h1>
              <p className="lnd__p">{translate("As your business continues to expand, finding the right space to store your inventory efficiently can be a game-changer.")}</p>
            </div>
            <div className="player tmt">
              <div className="player__preview">
              <img src="/assets/img/amazon_warehousing_services.png" alt="Amazon Warehousing Services" />
              </div>
            </div>
          </div>
        </div>
        <div className="article__wrap">
          <div className="article__center center">
            <div className="content ppost">
              <h2>{translate("Flexible Warehousing Solutions - We'll Handle Storage!")}</h2>
              <p>
              {translate("Looking for trusted ecommerce warehousing and storage services? Don’t let haphazard storage slow you down. Strategic warehousing minimizes order processing times and prevents inventory shrinkage. However, managing it yourself requires expertise and resources. At Kepicker, we’re committed to helping your business thrive. By choosing our warehousing solutions, you can focus on what you do best—growing your brand and serving your customers—while we take care of your inventory storage needs.")}
              </p>
              <p>
              {translate("As an Amazon seller, you know that the success of your business depends on fast, reliable, and cost-effective logistics solutions. That’s where Kepicker comes in. We’re a third-party logistics provider (3PL) dedicated to helping Amazon sellers streamline their operations and optimize their supply chain management. From warehousing and fulfillment services to freight and transportation solutions, our services cover the entire logistics spectrum for Amazon sellers. We’re here for you if you have any questions. Every customer is offered a free initial assessment because we’re not just looking for paying customers, we’re looking for growing partners.")}
              </p>
              <h3>{translate("Amazon Fulfillment Services (FBA) - What Is Warehousing?")}</h3>
              <p>
              {translate("In the simplest and shortest explanation, warehousing refers to the physical storage of products before they are ordered and shipped. Warehouses are places where products are put for distribution. In order for the trade to continue, it is very important that the products are kept safely in the warehouses. Amazon fulfillment FBA or fulfillment by Amazon is an option offered by Amazon to its 3rd party sellers to facilitate storage, packing and shipping of products sold on the Amazon market place alongside their own Amazon cargo hub via the use of Fulfillment Centres. If you currently sell or are looking to sell on Amazon as a 3rd party seller, whether you will be using fulfilment by Amazon (FBA) centres or your own in-house delivery service, we’re here to help.")}
              </p>
              
              <h4>{translate("Need advice on choosing a fulfillment warehouse?")}</h4>
              <p>
              {translate("Streamline Your Supply Chain by Partnering with Kepicker - Optimize your eCommerce operations with our streamlined solutions. We can be your great business partner and help you growth strongly. Storage is something we do for all our customers with no space limits. As businesses grow, they need more space. However, renting a warehouse can be much more costly than you think. Moreover, you do not have a margin of flexibility against sudden growth. Kepicker is here for all your storage needs.")}
              </p> 
            </div>
          </div>
        </div>
      </div>
    </div>

<div className="section onex_p promo_b">
  <div className="onex_p__center center">
    <div className="onex_p__inner">
      <div className="onex_p__head">
        {/* <h2 className="onex_p__title h2">Nulla nec interdum felis</h2> */}
        <div className="onex_p__info info">
          {translate(
            "Ready to unlock the potential of flexible warehousing?"
          )}
          <h2 className="releases__title h2 mt_40">
            <a target={"_blank"} href="tel:+905076106514">
            +90 (507) 610 65 14
            </a>
            <br />
            <a target={"_blank"} href="tel:+905545556218">
              +90 (554) 555 62 18
            </a>
          </h2>
          <div className="releases__info">
            <a target={"_blank"} href="mailto:info@kepicker.com">
              info@kepicker.com
            </a>
          </div>
        </div>
      </div>
      <div className="onex_p__wrapper">
        <a
          target="_blank"
          href="https://twitter.com/kepicker_"
          className="social_link twitter"
        >
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path d="M24.075 4.325c-.9.375-1.8.675-2.85.75 1.05-.6 1.8-1.575 2.175-2.7-.975.6-2.025.975-3.15 1.2C19.35 2.6 18.075 2 16.725 2c-2.7 0-4.95 2.175-4.95 4.95 0 .375.075.75.15 1.125C7.8 7.85 4.2 5.9 1.725 2.9a4.72 4.72 0 0 0-.675 2.475c0 1.725.9 3.225 2.175 4.125-.825 0-1.575-.225-2.25-.6v.075c0 2.4 1.725 4.35 3.975 4.8-.45.075-.825.15-1.275.15-.3 0-.6 0-.9-.075.6 1.95 2.475 3.375 4.575 3.45-1.65 1.35-3.825 2.1-6.15 2.1-.375 0-.825 0-1.2-.075 2.25 1.35 4.875 2.175 7.65 2.175 9.075 0 14.025-7.5 14.025-14.025v-.6c.9-.75 1.725-1.575 2.4-2.55z"></path>
          </svg>
        </a>
        <a
          target="_blank"
          href="https://www.instagram.com/kepicker"
          className="social_link instagram"
        >
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path d="M12 15.8a3.8 3.8 0 1 0 0-7.6 3.8 3.8 0 0 0-3.291 5.7A3.8 3.8 0 0 0 12 15.8h0zM16.749.6h-9.5A6.66 6.66 0 0 0 .6 7.251v9.5A6.66 6.66 0 0 0 7.251 23.4h9.5a6.66 6.66 0 0 0 6.649-6.651v-9.5A6.66 6.66 0 0 0 16.749.6zM12 17.7A5.71 5.71 0 0 1 6.3 12 5.71 5.71 0 0 1 12 6.3a5.71 5.71 0 0 1 5.7 5.7 5.71 5.71 0 0 1-5.7 5.7zm6.65-11.4a.95.95 0 0 1 0-1.9.95.95 0 1 1 0 1.9z"></path>
          </svg>
        </a>
        <a
          target="_blank"
          href="https://www.facebook.com/kepicker.software/"
          className="social_link facebook"
        >
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path d="M9.347 23.25V13.5H6V9h3.347V5.857c0-3.423 2.167-5.107 5.221-5.107 1.463 0 2.72.109 3.086.158v3.577l-2.118.001c-1.661 0-2.036.789-2.036 1.947V9H18l-1.5 4.5h-3v9.75H9.347z"></path>
          </svg>
        </a>
      </div>
    </div>
  </div>
</div>
    </>
  );
};

export default Warehousing;
