import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Table, Tag, Dropdown, Menu, Button } from "antd";
import translate from "../../../shared/utils/translations";

const AsinListTable = ({ data = [] }) => {
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (item) => (
        <>
          <div className="column_info">ID</div>
          <div> {item}</div>
        </>
      ),
    },
    {
      title: translate("User email"),
      key: "email",
      dataIndex: "email",
      render: (text) => (
        <>
          <div className="column_info">{translate("User email")}</div>
          {text}
        </>
      ),
    },
    {
      title: translate("Asin List Name"),
      key: "name",
      dataIndex: "name",
      render: (text) => (
        <>
          <div className="column_info">{translate("Asin List Name")}</div>
          {text?.length > 20 ? text?.slice(0, 20) + "..." : text}
        </>
      ),
    },
    {
      title: translate("Origin"),
      dataIndex: "origin",
      key: "origin",
      render: (item) => (
        <>
          <div className="column_info">{translate("Origin")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Asin count"),
      dataIndex: "asin_count",
      key: "asin_count",
      render: (item) => (
        <>
          <div className="column_info">{translate("Asin count")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Calculated Asin count"),
      dataIndex: "calculated_asin_count",
      key: "calculated_asin_count",
      render: (item) => (
        <>
          <div className="column_info">
            {translate("Calculated Asin count")}
          </div>
          {item}
        </>
      ),
    },
    {
      title: translate("Status"),
      dataIndex: "status",
      key: "status",
      render: (item) => (
        <>
          <div className="column_info">{translate("Status")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Created date / Last updated"),
      dataIndex: "created_at",
      key: "created_at",
      render: (item) => (
        <>
          <div className="column_info">
            {translate("Created / Updated date")}
          </div>
          {item}
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          locale: {
            items_per_page: translate("Page"),
          },
          position: ["top", "bottom"],
        }}
        className="card_m"
      />
    </>
  );
};

export default AsinListTable;
