import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import TiersTable from "./_tiers_table";
import TierDrawer from "./_tier_drawer";
import axios from "axios";
import { requestHandler } from "../../../shared/utils/helpers";
import translate from "../../../shared/utils/translations";
const Tiers = ({ setPage, setUserDetailsDrawer }) => {
  const navigate = useNavigate();
  useEffect(() => {
    setPage("dashboard-admin");
    localStorage.setItem("dashboard-type", "dashboard-admin");
  });

  const [isDrawer, setDrawer] = useState(false);
  const [selectedTier, setSelectedTier] = useState();
  const [tiers, setTiers] = useState([]);
  const getTiers = async () => {
    const tiers = await requestHandler("get", `/tiers`, null, true, navigate, "/login").then((res) => res?.data);
    setTiers(tiers);
  };

  useEffect(() => {
    getTiers();
  }, []);
  useEffect(() => {
    console.log("selected tier changed", selectedTier);
  }, [selectedTier]);

  return (
    <>
      <TierDrawer selectedTier={selectedTier} isDrawer={isDrawer} setDrawer={setDrawer} refresh={getTiers} />

      <div className="outer__inner">
        <div className="onex_wl bg_white">
          <div className="onex_wl__wrapper">
            <div className="upload__head">
              <h2 className="upload__title h2">{translate("Tier Management")}</h2>
              {/* <a
                    className="button-stroke button-small upload__button"
                    href="#!"
                  >
                    More filter
                  </a> */}
            </div>
            <div className="onex_wl__list">
              <div className="keys__enabled d_block">
                <div className="keys__list">
                  <div className="keys__item ss uu">
                    <div className="activity__top ss ">
                      <div className="right_items"></div>
                    </div>

                    <TiersTable setDrawer={setDrawer} setSelectedTier={setSelectedTier} data={tiers} getTiers={getTiers} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tiers;
