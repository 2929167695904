/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import Lottie from "react-lottie-player";
import lottieJsonSuccess from "../../../assets/animations/success.json";
import lottieJsonError from "../../../assets/animations/error.json";
import { requestHandler } from "../utils/helpers";
import moment from "moment";
import LoaderPage from "../../common/loaders/loader_page";
import translate from "../utils/translations";

const CheckoutComplete = ({ setPage, isPage, paymentData }) => {
  useEffect(() => {
    setPage("landing");
  });
  console.log("paymentData", paymentData);
  //get the query params
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const payment_intent_id = params.get("payment_intent");
  const payment_type_param = params.get("payment_type");
  const [paymentObject, setPaymentObject] = useState(null);

  const getPaymentObject = async (payment_intent_id) => {
    const obj = await requestHandler("post", "/payment/checkPayment", { paymentIntentId: payment_intent_id });
    console.log("obj", obj);
    setPaymentObject(obj?.data);
  };

  useEffect(() => {
    payment_intent_id && getPaymentObject(payment_intent_id);
  }, []);

  useEffect(() => {
    console.log("paymentObject", paymentObject);
  }, [paymentObject]);
  const renderSuccess = (obj) => {
    return (
      <div className="outer__inner">
        <div className="section-mb80 complete chk_comp">
          <div className="complete__center center">
            <div className="complete__row">
              <div className="complete__col">
                <div className="complete__head">
                  <Lottie className="ovw_lott" loop animationData={lottieJsonSuccess} play style={{ width: 100, height: 100 }} />
                  <div>
                    <h2 className="complete__title h2">{translate("Congratulation!")}</h2>
                    <div className="complete__info mm">{translate("Payment Successful!")}</div>
                  </div>
                </div>
                <div className="complete__list">
                  <div className="complete__item">
                    <div className="complete__details">
                      <div className="complete__category">{translate("Product")}</div>
                      <div className="complete__value">
                        {obj?.metadata?.paymentType?.includes("token")
                          ? obj?.metadata?.paymentType
                          : payment_type_param?.capitalize() + " Plan"}
                      </div>
                    </div>
                  </div>
                  <div className="complete__item">
                    <div className="complete__details">
                      <div className="complete__category">{translate("Billing cycle")}</div>
                      <div className="complete__value">{translate("Monthly")}</div>
                    </div>
                  </div>
                </div>
                <div className="complete__table">
                  <div className="complete__flex">
                    <div className="complete__cell">
                      <FeatherIcon icon={"shopping-bag"} />
                      {translate("Transaction ID")}:
                    </div>
                    <div className="complete__cell">{payment_intent_id}</div>
                  </div>
                  <div className="complete__flex">
                    <div className="complete__cell">
                      <FeatherIcon icon={"calendar"} />
                      {translate("Date")}:
                    </div>
                    <div className="complete__cell">{moment.unix(obj?.created).format("YYYY-MM-DD HH:mm:ss")}</div>
                  </div>
                  <div className="complete__flex">
                    <div className="complete__cell">
                      <FeatherIcon icon={"credit-card"} />
                      {translate("Amount Paid")}:
                    </div>
                    <div className="complete__cell">${obj?.amount ? parseInt(obj?.amount) / 100 : "..."}</div>
                  </div>
                  <div className="complete__flex">
                    <div className="complete__cell">
                      <FeatherIcon icon={"copy"} />
                      {translate("Payment method")}:
                    </div>
                    <div className="complete__cell">{translate("Credit card")}</div>
                  </div>
                </div>
                <div className="complete__btns">
                  <NavLink className="button-stroke complete__button" to="/my-plan">
                    {translate("Go to your plan")}
                  </NavLink>
                  {/* <NavLink
                    className="button complete__button"
                    to="/client-dashboard"
                  >
                    Go to dashboard
                  </NavLink> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderError = (obj) => {
    return (
      <div className="outer__inner">
        <div className="section-mb80 complete chk_comp">
          <div className="complete__center center">
            <div className="complete__row">
              <div className="complete__col">
                <div className="complete__head">
                  <Lottie className="ovw_lott" loop animationData={lottieJsonError} play style={{ width: 200, height: 200 }} />
                  <div>
                    <h2 className="complete__title h2">{translate("Failure to Pay!")}</h2>
                    <div className="complete__info eee">{translate("Payment Declined!")}</div>
                  </div>
                </div>
                <div className="complete__list">
                  <p className="err_mm">{translate("There was an issue with your payment. Try again later.")}</p>
                </div>
                <div className="complete__btns">
                  <a role="button" className="button-stroke complete__button" href="/pricing">
                    {translate("Try Again")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderLoading = () => {
    <>
      <LoaderPage />
    </>;
  };

  return (
    <>
      {!paymentObject
        ? renderLoading()
        : paymentObject?.status === "succeeded"
        ? renderSuccess(paymentObject)
        : renderError(paymentObject)}
      {/* {paymentObject?.status === "succeeded" && renderSuccess(paymentObject)}
      {paymentObject?.status !== "succeeded" && renderError(paymentObject)}
      {(!paymentObject || paymentObject?.status) && renderLoading()} */}
    </>
  );
};

export default CheckoutComplete;
