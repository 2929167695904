import React from "react";
import { Table, Tag, Dropdown, Menu, Button, Tooltip } from "antd";
import FeatherIcon from "feather-icons-react";
import translate from "../../../../shared/utils/translations";
import { useNavigate } from "react-router-dom";

const AsinFetcher = ({ isAsinFetcher, setAsinFetcher }) => {
  let navigate = useNavigate();

  return (
    <>
      <div className={`asin_fetcer__card ${isAsinFetcher && " active"}`}>
        <div className="fet_asin_header">
          <h5>Asin SPY</h5>

          <button
            onClick={() => {
              setAsinFetcher(false);
            }}
            className={`close_ui_btn`}
          >
            <FeatherIcon icon={"x"} />
          </button>
        </div>

        <div className="fet_asin_body">
          {/* started - done */}
          <div className="st__lftt">
            <div className="stts__kp">
              <div className="circle-loader__kpp">
                <div className="checkmark__kpp draw__kpp"></div>
              </div>
              <h5>In Progress</h5>
            </div>

            <div className="asin__statss">54 Asin Found</div>
          </div>

          <div className="res__actionsk">
            <button
              onClick={() => {
                navigate("/asin360");
                setAsinFetcher(false);
              }}
              className="pbi__btnn "
            >
              Go to All Asins
            </button>
            {/* <button className="pbi__btnn cancel">Cancel</button> */}
            <button
              onClick={() => setAsinFetcher(false)}
              className="pbi__btnn rest"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AsinFetcher;
