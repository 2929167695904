import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import DiscoveryTable from "./_affiliate_table";
import translate from "../../../shared/utils/translations";
import Input from "../../../common/form_elements/input";
import { requestHandler } from "../../../shared/utils/helpers";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const Discovery = ({ setPage }) => {
  const navigate = useNavigate();
  useEffect(() => {
    setPage("dashboard-admin");
    localStorage.setItem("dashboard-type", "dashboard-admin");
  });

  const [isItem, setItem] = useState("Custom item #1");
  const onChange = (e, name) => {
    setFormData({ ...formData, [name]: e });
  };
  const [formData, setFormData] = useState({ update_required: false });
  const [data, setData] = useState([]);
  const [isDrawer, setDrawer] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedAffiliate, setSelectedAffiliate] = useState({});

  const getAffiliates = async () => {
    const incomingData = await requestHandler(
      "get",
      `/admin/affiliates`,
      null,
      true,
      navigate,
      "/login"
    );
    console.log(incomingData);
    setData(incomingData?.data?.affiliates);
    setUsers(incomingData?.data?.users);
  };

  const send = () => {
    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Are you sure about performing this action?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log(formData);
        if (selectedAffiliate?.id) {
          requestHandler(
            "put",
            `/admin/affiliate/${selectedAffiliate?.id}`,
            formData,
            true,
            navigate,
            "/login"
          )
            .then((res) => {
              console.log("post success", res);
              getAffiliates();
              setData([]);
            })
            .catch((err) => {
              console.log("post error", err);
              setData([]);
            });
        } else {
          requestHandler(
            "post",
            `/admin/affiliate`,
            formData,
            true,
            navigate,
            "/login"
          )
            .then((res) => {
              console.log("post success", res);
              getAffiliates();
              setData([]);
            })
            .catch((err) => {
              console.log("post error", err);
              setData([]);
            });
        }

        setDrawer(false);
      }
    });
  };

  const deleteAffiliate = (id) => {
    Swal.fire({
      icon: "warning",
      title: translate("Delete?"),
      text: translate("Are you sure you want to delete this?"),
      confirmButtonText: translate("Delete"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        requestHandler(
          "delete",
          `/admin/affiliate/${id}`,
          null,
          true,
          navigate,
          "/login"
        )
          .then((res) => {
            console.log("post success", res);
            getAffiliates();
          })
          .catch((err) => {
            console.log("post error", err);
            setData([]);
          });
      }
    });
  };

  useEffect(() => {
    getAffiliates();
  }, []);

  return (
    <>
      <div className="outer__inner">
        <div className="onex_wl bg_white">
          <div className="onex_wl__wrapper">
            <div className="upload__head">
              <h2 className="upload__title h2">{translate("Affiliates Management")}</h2>
              {/* <a
                    className="button-stroke button-small upload__button"
                    href="#!"
                  >
                    More filter
                  </a> */}
            </div>
            <div className={`version__mdl afhh ${isDrawer && " show"}`}>
              <div className="version__mdl_body affl">
                <div className="mdl__header">
                  <h4>{translate("Create/Edit Affiliate")}</h4>
                </div>
                <div className="mdl___cntent">
                  <Input
                    id="affiliate_name"
                    name="affiliate_name"
                    label={translate("Affiliate Name")}
                    type="text"
                    placeholder={translate("Affiliate Name")}
                    cusstomClass="field_icon"
                    hasIcon="icon-description"
                    onChange={onChange}
                  />
                  <Input
                    id="affiliate_code"
                    name="affiliate_code"
                    label={translate("Affiliate Code")}
                    type="text"
                    placeholder={translate("Affiliate Code")}
                    cusstomClass="field_icon"
                    hasIcon="icon-version"
                    onChange={onChange}
                  />

                  <div className="field">
                    <div className="field__label">{translate("User")}</div>
                    <select
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          user_id: parseInt(e.target.value),
                        });
                      }}
                      className="field__input"
                      name="user_id"
                      id="user_id"
                    >
                      <option value="">{translate("Select User")}</option>
                      {users.map((user) => {
                        return (
                          <option key={user.id} value={user.id}>
                            {user.email}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="field">
                    <div className="field__label">{translate("Affiliate Type")}</div>
                    <select
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          ["token_based"]: parseInt(e.target.value) > 0,
                        });
                      }}
                      className="field__input"
                      name="token_based"
                      id="token_based"
                    >
                      <option value="0">{translate("Percentage")}</option>
                      <option value="1">{translate("Token")}</option>
                    </select>
                  </div>

                  {formData?.["token_based"] ? (
                    <Input
                      id="comparison_token_amount"
                      name="comparison_token_amount"
                      label={translate("Comparison Token Amount")}
                      type="text"
                      placeholder={translate("Comparison Token Amount")}
                      cusstomClass="field_icon"
                      hasIcon="icon-version"
                      onChange={onChange}
                    />
                  ) : (
                    <Input
                      id="commision_rate"
                      name="commision_rate"
                      label={translate("Commission Rate")}
                      type="integer"
                      placeholder={translate("Commission Rate")}
                      cusstomClass="field_icon"
                      hasIcon="icon-version"
                      onChange={onChange}
                    />
                  )}

                  <div className="field">
                    <div className="field__label">{translate("Add Token on Register")}</div>
                    <select
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          ["add_token_on_register"]:
                            parseInt(e.target.value) > 0,
                        });
                      }}
                      className="field__input"
                      name="add_token_on_register"
                      id="add_token_on_register"
                    >
                      <option value="0">{translate("No")}</option>
                      <option value="1">{translate("Yes")}</option>
                    </select>
                  </div>

                  {formData?.["add_token_on_register"] && (
                    <>
                      <Input
                        id="comparison_token_to_add_on_register"
                        name="comparison_token_to_add_on_register"
                        label={translate("Comparison Token to Add on Register")}
                        type="integer"
                        placeholder={translate("Comparison Token to Add on Register")}
                        cusstomClass="field_icon"
                        hasIcon="icon-version"
                        onChange={onChange}
                      />
                      <Input
                        id="asin_token_to_add_on_register"
                        name="asin_token_to_add_on_register"
                        label={translate("Asin Token to Add on Register")}
                        type="integer"
                        placeholder={translate("Asin Token to Add on Register")}
                        cusstomClass="field_icon"
                        hasIcon="icon-version"
                        onChange={onChange}
                      />
                    </>
                  )}
                  <div className="field">
                    <div className="field__label">{translate("Is Limited?")}</div>
                    <select
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          ["is_limited"]: parseInt(e.target.value) > 0,
                        });
                      }}
                      className="field__input"
                      name="is_limited"
                      id="is_limited"
                    >
                      <option value="0">{translate("No")}</option>
                      <option value="1">{translate("Yes")}</option>
                    </select>
                  </div>
                  {formData?.["is_limited"] && (
                    <Input
                      id="limit"
                      name="limit_count"
                      label={translate("Limit")}
                      type="integer"
                      placeholder={translate("Limit")}
                      cusstomClass="field_icon"
                      hasIcon="icon-version"
                      onChange={onChange}
                    />
                  )}
                </div>
                <div className="mdl___footer">
                  <button
                    onClick={() => setDrawer(false)}
                    className={`button-stroke onex_d__button js-scroll`}
                  >
                    {translate("Cancel")}
                  </button>
                  <button
                    className={`button services__button`}
                    onClick={() => send()}
                  >
                    {translate("Send")}
                  </button>
                </div>
              </div>
            </div>
            <div className="onex_wl__list">
              <div className="keys__enabled d_block">
                <div className="keys__list">
                  <div className="keys__item ss uu">
                    <div className="activity__top ss">
                      <div className="activity__item activity__item_calendar js-activity-item ">
                        <button
                          className="button-stroke button-small activity__button js-activity-button"
                          onClick={() => {
                            setDrawer(true);
                          }}
                        >
                          <span>{translate("Create New")}</span>
                        </button>

                        <p className="url__copyj">
                        {translate("If you want your friends to register with a discount, you can invite them with the URL you created.")}
                        </p>
                      </div>
                    </div>

                    <DiscoveryTable
                      data={data}
                      setSelectedAffiliate={setSelectedAffiliate}
                      setDrawer={setDrawer}
                      deleteAffiliate={deleteAffiliate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Discovery;
