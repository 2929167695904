import React from "react";
import { Button, Drawer } from "antd";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Select from "../../../common/form_elements/select";
import Input from "../../../common/form_elements/input";

const NewClientDrawer = ({ isNewDrawer, setNewDrawer }) => {
  const [isBtnLoading, setBtnLoading] = useState(false);
  const submit = () => {
    setBtnLoading(true);
    setTimeout(() => {
      notify();
      setBtnLoading(false);
      setNewDrawer(false);
    }, 1200);
  };

  const notify = () => toast.success("Müşteri başarıyla kaydedildi!..");

  return (
    <>
      <Drawer
        title="Client Form"
        placement="right"
        onClose={() => setNewDrawer(false)}
        open={isNewDrawer}
        width={"40%"}
      >
        <>
          <div className="question__form">
            <div className="question__fieldset cvb">
              <Select
                label="Status"
                options={["Active", "Inactive"]}
                customClass="mt_40"
              />

              <Input
                id="f_name"
                name="f_name"
                label="First Name"
                type="text"
                placeholder="First Name"
                customClass="mt_40"
              />

              <Input
                id="l_name"
                name="l_name"
                label="Last Name"
                type="text"
                placeholder="Last Name"
                customClass="mt_40"
              />

              <Input
                id="email"
                name="email"
                label="Email"
                type="email"
                placeholder="Email"
                customClass="mt_40"
              />

              <Input
                id="phone"
                name="phone"
                label="Phone Number"
                type="phone"
                placeholder="Phone Number"
                customClass="mt_40"
              />

              <Input
                id="address"
                name="address"
                label="Address Details"
                type="text"
                placeholder="Enter your address"
                customClass="mt_40"
              />
            </div>
            <div className="question__btns">
              <button
                className="button-small question__button app"
                onClick={submit}
              >
                Save
                {isBtnLoading && <span className="btn-spinner"></span>}
              </button>
            </div>
          </div>
        </>
      </Drawer>
    </>
  );
};

export default NewClientDrawer;
