import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import translate from "../../../shared/utils/translations";
import Input from "../../../common/form_elements/input";
import { requestHandler } from "../../../shared/utils/helpers";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import EmailList from "./_email_table";
import EmailDrawer from "./_new_email_drawer";
import UserListDrawer from "./_user_list_drawer";

const Email = ({ setPage }) => {
  const navigate = useNavigate();
  useEffect(() => {
    setPage("dashboard-admin");
    localStorage.setItem("dashboard-type", "dashboard-admin");
  });

  const [isReviewDrawer, setReviewDrawer] = useState(false);
  const [isEmailDrawer, setEmailDrawer] = useState(false);
  const [isUserListDrawer, setUserListDrawer] = useState(false);

  const sendEmail = () => {
    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Are you sure about performing this action?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log("formData", formData);
        requestHandler("post", `/admin/sendBulkEmail`, formData, true, navigate, "/login")
          .then((res) => {
            toast.success(translate("Emails were sent successfully!"));
            setUserListDrawer(false);
            setEmailDrawer(false);
          })
          .catch((err) => {
            toast.error(translate("Error sending emails!"));
          });
      }
    });
  };

  const saveDraft = () => {
    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Are you sure about performing this action?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        toast.success(translate("Successfully Saved as Draft!"));
        setUserListDrawer(false);
        setEmailDrawer(false);
      }
    });
  };

  const [formData, setFormData] = useState();
  const [users, setUsers] = useState([]);

  const getUsers = async () => {
    const users = await requestHandler("get", `/admin/users`, null, true, navigate, "/login").then((res) => res?.data);
    setUsers(users);
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <EmailDrawer
        isEmailDrawer={isEmailDrawer}
        setEmailDrawer={setEmailDrawer}
        isUserListDrawer={isUserListDrawer}
        setUserListDrawer={setUserListDrawer}
        setFormData={setFormData}
        formData={formData}
      />
      <UserListDrawer
        isUserListDrawer={isUserListDrawer}
        setUserListDrawer={setUserListDrawer}
        sendEmail={sendEmail}
        saveDraft={saveDraft}
        setFormData={setFormData}
        formData={formData}
        users={users}
      />

      <div className="outer__inner">
        <div className="onex_wl bg_white">
          <div className="onex_wl__wrapper">
            <div className="upload__head">
              <h2 className="upload__title h2">{translate("Email Management")}</h2>
              {/* <a
                    className="button-stroke button-small upload__button"
                    href="#!"
                  >
                    More filter
                  </a> */}
            </div>

            <div className="onex_wl__list">
              <div className="keys__enabled d_block">
                <div className="keys__list">
                  <div className="keys__item ss uu">
                    <div className="activity__top ss">
                      <div className="activity__item activity__item_calendar js-activity-item ">
                        <button
                          className="button-stroke button-small activity__button js-activity-button"
                          onClick={() => {
                            setEmailDrawer(true);
                          }}
                        >
                          <span>{translate("New Email")}</span>
                        </button>

                        <p className="url__copyj">
                          {translate("From here you can send custom emails to all registered users or selected users.")}
                        </p>
                      </div>
                    </div>

                    {/* <EmailList sendEmail={sendEmail} isReviewDrawer={isReviewDrawer} setReviewDrawer={setReviewDrawer} /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Email;
