import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import ClientsTable from "./_clients_table";
import NewClientDrawer from "./_new_client_drawer";
import translate from "../../../shared/utils/translations";

const Clients = ({ setPage, setUserDetailsDrawer }) => {
  useEffect(() => {
    setPage("dashboard-admin");
    localStorage.setItem("dashboard-type", "dashboard-admin");
  });

  const [isItem, setItem] = useState("All");
  const [isNewDrawer, setNewDrawer] = useState(false);

  return (
    <>
      <NewClientDrawer isNewDrawer={isNewDrawer} setNewDrawer={setNewDrawer} />

      <div className="outer__inner">
        <div className="onex_wl bg_white">
          <div className="onex_wl__wrapper">
            <div className="upload__head">
              <h2 className="upload__title h2">Clients Management</h2>
              {/* <a
                    className="button-stroke button-small upload__button"
                    href="#!"
                  >
                    More filter
                  </a> */}
            </div>
            <div className="onex_wl__list">
              <div className="keys__enabled d_block">
                <div className="keys__info w_cl">
                  <div className="page_details">
                    <div className="item_c">
                      <h4>Total Clients</h4>
                      <p>520</p>
                    </div>
                    <div className="item_c">
                      <h4>Basic</h4>
                      <p>320</p>
                    </div>
                    <div className="item_c">
                      <h4>Pro</h4>
                      <p>200</p>
                    </div>
                  </div>
                </div>
                <div className="keys__list">
                  <div className="keys__item ss">
                    <div className="activity__top ss">
                      <div className="nav">
                        <button
                          className={`nav__link cursorp ${
                            isItem === "All" ? " active" : " "
                          }`}
                          onClick={() => setItem("All")}
                        >
                          All
                        </button>
                        <button
                          className={`nav__link cursorp ${
                            isItem === "Basic" ? " active" : " "
                          }`}
                          onClick={() => setItem("Basic")}
                        >
                          Basic
                        </button>
                        <button
                          className={`nav__link cursorp ${
                            isItem === "Pro" ? " active" : " "
                          }`}
                          onClick={() => setItem("Pro")}
                        >
                          Pro
                        </button>
                      </div>

                      <div className="right_items">
                        <form className="activity__form">
                          <input
                            className="activity__input"
                            type="text"
                            name="search"
                            placeholder={translate("Search")}
                            required=""
                          />
                          <button className="activity__result">
                            <svg className="icon icon-search">
                              <use xlinkHref="#icon-search"></use>
                            </svg>
                          </button>
                        </form>
                        <div className="activity__item activity__item_calendar js-activity-item ">
                          <button
                            className="button-stroke button-small activity__button js-activity-button"
                            onClick={() => setNewDrawer(true)}
                          >
                            <span>New Client</span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <ClientsTable
                      setNewDrawer={setNewDrawer}
                      setUserDetailsDrawer={setUserDetailsDrawer}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Clients;
