import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet";
import FeatherIcon from "feather-icons-react";
import translate from "../../shared/utils/translations";
import Lottie from "react-lottie-player";
import boxAnim from "../../../assets/animations/box.json";
import starAnim from "../../../assets/animations/star.json";
import Comfetti from "../../common/other_elements/comfetti";
import Swal from "sweetalert2";
import { Tooltip } from "antd";
import { requestHandler } from "../../shared/utils/helpers";

const Raffle = ({ setPage }) => {
  useEffect(() => {
    setPage("auth");
  });

  const [key, setKey] = useState(0);
  const [isSpeed, setSpeed] = useState(0);
  const [isPlay, setPlay] = useState(false);
  const [isStartAnim, setStartAnim] = useState(false);
  const [isWinner, setWinner] = useState(false);
  const [isComfetti, setComfetti] = useState(false);
  const [isNewraffle, setNewraffle] = useState(true);
  const [isGiftModal, setGiftModal] = useState(false);
  const [isStartRaffleModal, setStartRaffleModal] = useState(false);
  const [selectedGift, setSelectedGift] = useState("default");
  const [showStartBtn, setShowStartBtn] = useState(false);
  const [showAddGiftBtn, setAddGiftBtn] = useState(false);
  const [isAffCodeModal, setAffCodeModal] = useState(false);
  const [showAffCodeBtn, setAffCodeBtn] = useState(false);

  const [giftData, setGiftData] = useState([]);
  const [winnerData, setWinnerData] = useState([]);
  const [winnerBuffer, setWinnerBuffer] = useState([]);
  const [affiliateCode, setAffiliateCode] = useState("");
  const [users, setUsers] = useState([]);
  const [tokenBuffer, setTokenBuffer] = useState(0);

  const getUsersByAffiliateCode = async () => {
    if (affiliateCode.trim() === "") return;
    requestHandler(
      "get",
      `/admin/users/affiliate/${affiliateCode}`,
      null,
      true,
      () => {},
      ""
    ).then((res) => {
      console.log(res?.data);
      setUsers(res?.data);
      setAffCodeModal();
    });
  };

  const selectRandomWinner = () => {
    const winner = users[Math.floor(Math.random() * users?.length)];
    console.log(winner);
    setWinnerBuffer({
      user_id: winner.id,
      first_name: winner.first_name,
      last_name: winner.last_name,
      gift_id: selectedGift,
      token: giftData.find((gift) => gift.id === parseInt(selectedGift))?.token,
    });
  };

  const sendGift = async (user_id, gift_token) => {
    requestHandler(
      "post",
      "/admin/ruffle/winner",
      { user_id, gift_token },
      true,
      () => {},
      ""
    ).then((res) => {
      console.log(res?.data);
      toast.success("Kazanan Kişiye Token Gönderildi!");
      setWinnerData([...winnerData, winnerBuffer]);
      setGiftData(
        giftData.filter((gift) => gift.id !== parseInt(winnerBuffer?.gift_id))
      );
      setUsers(users?.filter((user) => user.id !== winnerBuffer?.user_id));
      setAddGiftBtn(false);
      setWinnerBuffer([]);
    });
  };

  const startRaffle = () => {
    Swal.fire({
      icon: "warning",
      title: "Emin misin?",
      text: translate("İşlemi başlatmak istediğine emin misin?"),
      confirmButtonText: translate("Evet"),
      cancelButtonText: translate("İptal Et"),
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success etty",
        cancelButton: "btn btn-danger etty",
        popup: "swal_rr",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        setStartAnim(true);
        setPlay(true);
        setSpeed(0.05);
        setNewraffle(false);
        setGiftModal(false);
        setShowStartBtn(false);
        setAffiliateCode("");
        setAffCodeBtn(false);
        setAffCodeModal(false);

        setStartRaffleModal(false);
        setTimeout(() => {
          setSpeed(1);
          setStartAnim(false);
          showWinner();
        }, 5000);
      }
    });
  };

  const showWinner = async () => {
    setTimeout(async () => {
      setStartAnim(false);
      setWinner(true);
      setComfetti(true);
      setSelectedGift("default");
      setSelectedGift("default");
      setGiftModal(false);
      setStartRaffleModal(false);
      setAddGiftBtn(false);
      setShowStartBtn(false);
      selectRandomWinner();
      setAffiliateCode("");
      setAffCodeBtn(false);
      setAffCodeModal(false);
    }, 3000);
  };

  const endRaffle = async () => {
    setSpeed(0);
    setPlay(false);
    setStartAnim(false);
    setWinner(false);
    setKey((prevKey) => prevKey + 1);
    setComfetti(false);
    setNewraffle(true);
    setGiftModal(false);
    setStartRaffleModal(false);
    setSelectedGift("default");
    setSelectedGift("default");
    await sendGift(winnerBuffer?.user_id, winnerBuffer?.token);
  };

  const handleAddGiftChange = (event) => {
    const value = event.target.value;
    setAddGiftBtn(
      value.trim() !== "" && !isNaN(value) && parseInt(value, 10) > 0
    );
    setTokenBuffer(
      value.trim() !== "" && !isNaN(value) && parseInt(value, 10) > 0
        ? parseInt(value, 10)
        : 0
    );
  };

  const handleSelectGiftChange = (e) => {
    const value = e.target.value;
    setSelectedGift(value);
    setShowStartBtn(value !== "default");
  };

  useEffect(() => {
    setAffCodeBtn(affiliateCode.trim() !== "");
  }, [affiliateCode]);

  const handleAffiliateCodeChange = (e) => {
    setAffiliateCode(e.target.value);
  };

  const deleteGift = (id) => {
    Swal.fire({
      icon: "warning",
      title: "Emin misin?",
      text: translate("Hediyeyi silmek istediğine emin misin?"),
      confirmButtonText: translate("Sil"),
      cancelButtonText: translate("İptal Et"),
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success etty",
        cancelButton: "btn btn-danger etty",
        popup: "swal_rr",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log("Hediye Silindi!");
        setGiftData(giftData.filter((gift) => gift.id !== id));
      }
    });
  };

  useEffect(() => {
    console.log(giftData);
  }, [giftData]);

  return (
    <>
      <Helmet>
        <title>{translate("Çekiliş Zamanı")}</title>
        <meta name="description" />
      </Helmet>
      <div className="mb__warn">
        <div>Bu Ekran Mobil Uyumlu Değildir!</div>
      </div>
      <div className="outer__inner auth raffle__body">
        <div className="raffle__container">
          <div className="raffle__section">
            <div className="raffle_title">
              <h1>
                Kepicker Çekilişine
                <br /> Hoşgeldiniz!..
              </h1>
              {/* <p>
              Belirlenen Şanslı Katılımcılar Arasında Yer Alın ve Kazanma
              Şerefine Ortak Olun. Unutmayın, Her Çekiliş Bir Sürpriz, Her
              Katılım Bir Şans!
            </p> */}
            </div>

            <div className="middle__arrea">
              <div className="raffle__infos">
                {/* Add Affilate Code Modal */}
                <div className={`winner_card tkn  ${isAffCodeModal && "show"}`}>
                  <h5>Affiliate Kodu Giriniz</h5>
                  <div className="field">
                    <div className="field__wrap">
                      <input
                        className="field__input ggh"
                        type="text"
                        name="affiliate"
                        placeholder="Affiliate Kodu.."
                        required
                        onChange={handleAffiliateCodeChange}
                      />
                    </div>
                  </div>

                  <div className="footer_R">
                    {showAffCodeBtn && (
                      <button
                        style={{ width: "50%" }}
                        onClick={() => {
                          getUsersByAffiliateCode();
                        }}
                        className="button-small setting__button"
                      >
                        Uygula
                      </button>
                    )}

                    <button
                      style={{ width: showAffCodeBtn ? "50%" : "100%" }}
                      onClick={() => setAffCodeModal(false)}
                      className="button-small button-stroke setting__button active"
                    >
                      İptal
                    </button>
                  </div>
                </div>

                <div className="inf__area">
                  <div className="inf___card">
                    <h5>
                      Toplam Katılımcı{" "}
                      <Tooltip
                        title={translate("Katılımcıları Seç")}
                        placement="top"
                      >
                        <a
                          href="#!"
                          role="button"
                          className="up_btn token_bt hh ggjj"
                          onClick={() => setAffCodeModal(true)}
                        >
                          <FeatherIcon icon={"plus-square"} />
                        </a>
                      </Tooltip>
                      <Tooltip title={translate("Yenile")} placement="top">
                        <a
                          href="#!"
                          role="button"
                          className="up_btn token_bt hh ggjj"
                          onClick={() => getUsersByAffiliateCode()}
                        >
                          <FeatherIcon icon={"refresh-cw"} />
                        </a>
                      </Tooltip>
                    </h5>

                    <div>
                      <span className="ttl_in">
                        {users?.length > 0 ? users?.length : 0}
                      </span>
                    </div>
                  </div>
                  <div className="inf___card cc">
                    <h5>
                      <img src="/assets/img/trophy.png"></img>Kazananlar
                    </h5>

                    <div className="winner__Cardds">
                      {winnerData?.length > 0 ? (
                        winnerData?.map((winner) => {
                          return (
                            <div className="winner__Cardd">
                              <img src="/assets/img/medal.png"></img>
                              <div className="hhy">
                                <h6>
                                  {(winner?.first_name ||
                                    "İsim Kaydı Bulunamadı!") +
                                    " " +
                                    (winner?.last_name || " ")}
                                </h6>
                                <span>
                                  {winner?.token > 0 ? winner?.token : 0} Token
                                </span>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <>
                          <div className="no__winners">
                            <img src="/assets/img/empty_area.png"></img>
                            <h4>Kazanan Alanı Boş!</h4>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="raffle__animations">
                <div className="box__loader">
                  <Lottie
                    className={`ovw_lotenr ${
                      isStartAnim && isSpeed != 1 ? "shake-slow" : " "
                    } ${isStartAnim && isSpeed === 1 ? "shake-fast" : " "}`}
                    loop={false}
                    animationData={boxAnim}
                    play={isPlay}
                    speed={isSpeed}
                    key={key}
                  />
                  <div
                    className={`loader_r spin_r ${isPlay ? "start" : ""}  ${
                      isSpeed === 1 ? "fast" : ""
                    }`}
                  >
                    <div className="spin__blocker"></div>
                    <div className="spin__top-right"></div>
                    <div className="spin__bottom-left"></div>
                    <div className="spin__bottom-right"></div>
                    <div className="spin__top-left"></div>
                  </div>

                  {/* Winner Modal */}
                  <div className={`winner_card ${isWinner && "show"}`}>
                    <Lottie
                      className={`ovw_lotenr`}
                      loop={true}
                      animationData={starAnim}
                      play
                    />
                    <h5>Kazanan</h5>
                    <div className="mail_r">
                      {(winnerBuffer?.first_name || "İsim Kaydı Bulunamadı!") +
                        " " +
                        (winnerBuffer?.last_name || "")}
                    </div>
                    <div className="gift_r">
                      {winnerBuffer?.token > 0 ? winnerBuffer?.token : 0} Token
                    </div>

                    <div className="footer_R">
                      <button
                        style={{ width: "100%" }}
                        onClick={() => endRaffle()}
                        className="button-small button-stroke setting__button active"
                      >
                        Kapat
                      </button>
                    </div>
                  </div>
                </div>

                {/* Start Raffle Modal */}
                <div
                  className={`winner_card tkn  ${isStartRaffleModal && "show"}`}
                >
                  <h5>Hediye Seçiniz</h5>
                  <div className="select__field">
                    <select
                      className="select nice-select  app d_block"
                      value={selectedGift}
                      onChange={handleSelectGiftChange}
                    >
                      <option value="default">Hediye Seçiniz</option>
                      {giftData?.map((gift) => {
                        return (
                          <option value={gift?.id}>{gift?.token} Token</option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="footer_R">
                    {showStartBtn && (
                      <button
                        style={{ width: "50%" }}
                        onClick={() => startRaffle()}
                        className="button-small setting__button"
                      >
                        Çekilişi Başlat
                      </button>
                    )}

                    <button
                      style={{ width: showStartBtn ? "50%" : "100%" }}
                      onClick={() => endRaffle()}
                      className="button-small button-stroke setting__button active"
                    >
                      Kapat
                    </button>
                  </div>
                </div>
                {/* Ana Buton */}
                {giftData?.length && users?.length > 0 ? (
                  <button
                    onClick={() => setStartRaffleModal(true)}
                    className={`button setting__button nw__rffle ${
                      isNewraffle ? "" : "down"
                    }`}
                  >
                    Yeni Çekiliş
                  </button>
                ) : (
                  <>
                    <button
                      onClick={() =>
                        toast.error("Hediye veya Katılımcı Alanı Boş!")
                      }
                      className={`button setting__button nw__rffle`}
                    >
                      Yeni Çekiliş
                    </button>
                  </>
                )}
              </div>
              <div className="raffle__infos ggmm">
                {/* Add Gift Modal */}
                <div className={`winner_card tkn  ${isGiftModal && "show"}`}>
                  <h5>Token Sayısı</h5>
                  <div className="field">
                    <div className="field__wrap">
                      <input
                        className="field__input ggh"
                        type="number"
                        name="token"
                        placeholder="Token adedi giriniz.."
                        required
                        value={tokenBuffer}
                        onChange={handleAddGiftChange}
                      />
                    </div>
                  </div>

                  <div className="footer_R">
                    {showAddGiftBtn && (
                      <button
                        style={{ width: "50%" }}
                        onClick={() => {
                          setGiftData([
                            ...giftData,
                            { id: giftData.length + 1, token: tokenBuffer },
                          ]);
                          setTokenBuffer(0);
                          setGiftModal(false);
                        }}
                        className="button-small setting__button"
                      >
                        Ekle
                      </button>
                    )}

                    <button
                      style={{ width: showAddGiftBtn ? "50%" : "100%" }}
                      onClick={() => setGiftModal(false)}
                      className="button-small button-stroke setting__button active"
                    >
                      İptal
                    </button>
                  </div>
                </div>

                <div className="inf__area">
                  <div className="inf___card">
                    <h5>
                      <img src="/assets/img/gift-box.png"></img>Hediyeler
                      <Tooltip title={translate("Hediye Ekle")} placement="top">
                        <a
                          href="#!"
                          role="button"
                          className="up_btn token_bt hh ggjj"
                          onClick={() => setGiftModal(true)}
                        >
                          <FeatherIcon icon={"plus-square"} />
                        </a>
                      </Tooltip>
                    </h5>

                    <div className="winner__Cardds">
                      {giftData?.length > 0 ? (
                        giftData?.map((gift) => {
                          return (
                            <div className="winner__Cardd">
                              <img src="/assets/img/gift-box-s.png"></img>
                              <div className="hhy">
                                <span>
                                  {gift?.token > 0 ? gift?.token : 0} Token
                                </span>
                              </div>
                              <Tooltip title={translate("Sil")} placement="top">
                                <a
                                  href="#!"
                                  role="button"
                                  className="up_btn token_bt hh ggjj bbn"
                                  onClick={() => deleteGift(gift?.id)}
                                >
                                  <FeatherIcon icon={"trash"} />
                                </a>
                              </Tooltip>
                            </div>
                          );
                        })
                      ) : (
                        <div className="no__giftss">
                          <img src="/assets/img/empty_area.png"></img>
                          <h4>Hediye Alanı Boş!</h4>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isComfetti && <Comfetti />}
      </div>
    </>
  );
};

export default Raffle;
