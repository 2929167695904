import React from "react";

const Checkbox = ({ label, defaultChecked }) => {
  return (
    <>
      <label className={`checkbox`}>
        <input className="checkbox__input" type="checkbox" defaultChecked />

        <span className="checkbox__inner">
          <span className="checkbox__tick"></span>
          <span className="checkbox__text">{label}</span>
        </span>
      </label>
    </>
  );
};

export default Checkbox;
