import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";

import translate from "../../../shared/utils/translations";
import Input from "../../../common/form_elements/input";
import Select from "../../../common/form_elements/select";
import { NavLink } from "react-router-dom";
// erdem gy
const BarcodeReaderForm = ({ setPage }) => {
  useEffect(() => {
    setPage("dashboard-client");
    localStorage.setItem("dashboard-type", "dashboard-client");
  });

  useEffect(() => {
    const handleScrollToTop = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener("load", handleScrollToTop);

    return () => window.removeEventListener("load", handleScrollToTop);
  }, []);

  const handlePrice = () => {
    console.log("Price");
  };
  const handleCurrency = () => {
    console.log("Currency");
  };
  return (
    <>
      <div className="outer__inner">
        <div className="onex_wl bg_white">
          <div className="onex_wl__wrapper readr_err">
            <div className="barcode_readder_wrapper">
              <div className="barcode_readr__body">
                <div className="rdr_b_hdr">
                  <h5>{translate("Price & Currency")}</h5>

                  <p>
                    {translate(
                      "Enter the price of the product and select the currency."
                    )}
                  </p>
                </div>
                <div className="rdr_b_middle">
                  <div className="setting__fieldset">
                    <div className="setting__row bggh">
                      <Input
                        id="settingname_id"
                        name="price"
                        label={translate("Price")}
                        type="number"
                        placeholder={translate("10 etc.")}
                        onChange={handlePrice}
                        value={10}
                      />
                    </div>
                    <div className="setting__row">
                      <Select
                        label={translate("Currency")}
                        options={["USD", "GBP", "TRY"]}
                        onChange={handleCurrency}
                        name="currency"
                        value={"USD"}
                      />
                    </div>
                    <div className="setting__row ffgh">
                      <NavLink
                        className="button main__button"
                        to="/barcode-reader-products"
                      >
                        {translate("Next")}
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="rdr_b_footr">
                  <div className="rdr__dots">
                    <div className="rdr__dot_itm"></div>
                    <div className="rdr__dot_itm active"></div>
                    <div className="rdr__dot_itm"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BarcodeReaderForm;
