import React from "react";
import translate from "../../shared/utils/translations";

const SelectWithOptions = ({ label, options, customClass, onChange, value, name }) => {
  return (
    <>
      <div className={`field ${customClass && customClass}`}>
        <div className="field__label">{label}</div>
        <div className="field__wrap">
          <div className="select__field">
            <select
              className="select nice-select app d_block"
              onChange={(e) => {
                onChange(e.target.value, name);
              }}
              value={value}
              name={name}
            >
              <option>{translate("Please select")}</option>
              {options.map((option, index) => (
                <option key={index} value={option?.value}>
                  {option?.text}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectWithOptions;
