import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import FeatherIcon from "feather-icons-react";
import Input from "../../../common/form_elements/input";
import translate from "../../../shared/utils/translations";
import { Helmet } from "react-helmet";

const Affilliate = ({ setPage }) => {
  useEffect(() => {
    setPage("dashboard-client");
    localStorage.setItem("dashboard-type", "dashboard-client");
  });

  const [isFunding, setFunding] = useState(false);

  const send = () => {
    toast.success(
      translate(
        "Your request has been sent. We will contact you as soon as possible!.."
      )
    );
  };
  return (
    <>
    <Helmet>
      <title>{translate("Affiliate Management")}</title>
    </Helmet>
      <div className={`version__mdl ${isFunding && " show"}`}>
        <div className="version__mdl_body">
          <div className="mdl__header">
            <h4>{translate("Funding Request")}</h4>
          </div>
          <div className="mdl___cntent">
            <Input
              id="version"
              name="version"
              label={translate("Quantity Requested ($)")}
              type="number"
              placeholder={translate("$100 etc.")}
              cusstomClass="field_icon"
              hasIcon="icon-version"
            />
          </div>

          <div className="mdl___footer">
            <button
              onClick={() => setFunding(false)}
              className={`button-stroke onex_d__button js-scroll`}
            >
              {translate("Cancel")}
            </button>
            <button
              className={`button services__button`}
              onClick={() => {
                send();
                setFunding(false);
              }}
            >
              {translate("Send")}
            </button>
          </div>
        </div>
      </div>

      <div className="outer__inner">
        <div className="onex_wl bg_white">
          <div className="onex_wl__wrapper">
            <div className="upload__head">
              <h2 className="upload__title h2">
                {translate("Affiliate Management")}
              </h2>
            </div>
            <div className="onex_wl__list">
              <div className="keys__enabled d_block">
                <div className="keys__info">
                  {translate(
                    "You can also earn money by inviting your friends for paid packages."
                  )}
                </div>
                <div className="keys__stage">
                  {translate(
                    "Your total earnings show the total amount you can now withdraw!"
                  )}
                </div>

                <div className="afff_itms">
                  <div className="minn__inf">
                    <div className="keys__title h3">
                      <h6>{translate("Total Earnings")}:</h6>
                      <span className="tt">$100</span>
                    </div>
                    <div className="keys__title h3">
                      <h6>{translate("Withdrawable Earnings")}:</h6>
                      <span>$70</span>
                    </div>
                    <div className="keys__title h3">
                      <h6>{translate("Expected Earnings")}:</h6>
                      <span className="ee">$80</span>
                    </div>
                    <div className="keys__title h3">
                      <h6>{translate("Commission Rate")}:</h6>
                      <span>10%</span>
                    </div>
                  </div>

                  <div className="other__infs">
                    <div className="keys__title h3">
                      <h6>{translate("Total of Code Users")}:</h6>
                      <span>85</span>
                    </div>
                    <div className="keys__title h3">
                      <h6>Basic:</h6>
                      <span>25</span>
                    </div>
                    <div className="keys__title h3">
                      <h6>Plus:</h6>
                      <span>30</span>
                    </div>
                    <div className="keys__title h3">
                      <h6>Pro:</h6>
                      <span>25</span>
                    </div>
                  </div>
                </div>

                <div className="keys__email">
                  <svg className="icon icon-help">
                    <use xlinkHref="#icon-help"></use>
                  </svg>
                  {translate(
                    "Payments are transferred to your account after one month."
                  )}
                </div>
                <button
                  onClick={() => setFunding(true)}
                  className="button-stroke button-small upload__button"
                >
                  {translate("Create a Funding Request")}
                </button>
                <div className="keys__list">
                  <div className="keys__item" style={{ maxWidth: "600px" }}>
                    <div className="keys__subtitle">
                      {translate("Your Affiliate Code")}
                    </div>
                    <div className="keys__group">
                      <div className="keys__box">
                        <div className="field">
                          <div className="field__label">
                            {translate("Affiliate Code")}
                          </div>
                          <div className="field__wrap">
                            <div className="entry__correct referral">
                              <FeatherIcon
                                icon="link-2"
                                className={"aff__icn"}
                              />
                              <div className="entry__url">
                                <span>EFGVV34D</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <button className="keys__remove">
                          <FeatherIcon icon="trash-2" className={"aff__icn"} />
                        </button>
                        <button
                          className="keys__remove share"
                          onClick={() =>
                            toast.success(translate("Copied to Clipboard!"))
                          }
                        >
                          <FeatherIcon icon="copy" className={"aff__icn"} />
                        </button>
                      </div>
                    </div>
                    <button className="button-stroke button-small keys__button ">
                      <svg className="icon icon-plus app">
                        <use xlinkHref="#icon-plus"></use>
                      </svg>
                      <span>{translate("Generate New Code")}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Affilliate;
