import React, { useState } from "react";
import { Tooltip, Button, Modal, Space } from "antd";
import FeatherIcon from "feather-icons-react";
import translate from "../../../../shared/utils/translations";
import SellersDataModal from "./_sellers_data_modal";

const MarketComparison = ({
  setAsinFetcher,
  baseOffersData,
  targetOffersData,
  setBasePriceNotConverted,
  basePriceNotConverted,
  preferenceData,
  asin,
  mainData,
}) => {
  const [isSellerModal, setSellerModal] = useState(false);

  return (
    <>
      <SellersDataModal
        isSellerModal={isSellerModal}
        setSellerModal={setSellerModal}
        setAsinFetcher={setAsinFetcher}
      />

      <div className="product__cmp_info">
        <div className="lft__Card">
          <div className="crdd_hdr">
            <div className="crdd__title">
              <span>{translate("Source market")}</span>
              <a
                target="_blank"
                href={`https://${preferenceData?.base_domain}/dp/${asin}`}
              >
                {preferenceData?.base_domain}
              </a>
            </div>
          </div>

          <div className="value_with_inppt">
            <div className="smm_price">
              {mainData?.baseDomainPrice} {targetOffersData?.currency}
            </div>
            <div className="long___line">→</div>
            <div className="calc__inppt">
              <div className="field__wrap">
                <input
                  className="field__input"
                  type="number"
                  value={basePriceNotConverted}
                  onChange={(e) =>
                    setBasePriceNotConverted(parseFloat(e.target.value))
                  }
                  placeholder={`Source price (${baseOffersData?.currency})`}
                />
              </div>
            </div>
            <div className="crrncyy">{baseOffersData?.currency}</div>
          </div>
          {/* 
          <p className="discount__inf">2 Quantity price: 36.1 USD</p> */}
          {baseOffersData?.hasDiscount ? (
            baseOffersData?.buyBoxQuantityTier > 1 ? (
              <>
                <p className="discount__inf">
                  {baseOffersData?.buyBoxQuantityTier}{" "}
                  {translate("Quantity price")}:{baseOffersData?.price}{" "}
                  {baseOffersData?.currency}
                </p>
                <p className="discount__inf">
                  1 {translate("Quantity price")}: {baseOffersData?.price}
                  {baseOffersData?.currency}
                </p>
              </>
            ) : (
              <p className="discount__inf">
                1 {translate("Quantity price")}: {baseOffersData?.price}{" "}
                {baseOffersData?.currency}
              </p>
            )
          ) : (
            ""
          )}

          <div className="divider__tt"></div>

          <div className="inf__fee_card">
            <div className="lft">
              <h5>{translate("Buybox Price")}</h5>
              <span>
                {baseOffersData?.buyBoxPrice || "N/A"}{" "}
                {baseOffersData?.currency}
              </span>
            </div>
            <div className="lft">
              <h5>{translate("Lowest Price")}</h5>
              <span>
                {baseOffersData?.lowestPrice || "N/A"}{" "}
                {baseOffersData?.currency}
              </span>
            </div>
            <div className="rght">
              <div className="rght_iitm">
                <h5>FBA</h5>
                <span>
                  {baseOffersData?.lowestFbaPrice || "N/A"}{" "}
                  {baseOffersData?.currency}
                </span>
              </div>
              <div className="rght_iitm">
                <h5>FBM</h5>
                <span>
                  {baseOffersData?.lowestFbmPrice || "N/A"}{" "}
                  {baseOffersData?.currency}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="middle__icn">
          <img src="/assets/img/icons8-sorting-arrows-horizontal-64.png" />
        </div>

        <div className="rght__Card">
          <div className="crdd_hdr tt">
            <div className="crdd__title">
              <span>{translate("Target market")}</span>
              <a
                target="_blank"
                href={`https://${preferenceData?.target_domain}/dp/${asin}`}
              >
                {preferenceData?.target_domain}
              </a>
            </div>
          </div>

          <div className="smm_price">
            {targetOffersData?.price} {targetOffersData?.currency}
          </div>

          <div className="divider__tt"></div>

          <div className="inf__fee_card">
            <div className="lft">
              <h5>{translate("Buybox Price")}</h5>
              <span>
                {targetOffersData?.buyBoxPrice || "N/A"}{" "}
                {targetOffersData?.currency}
              </span>
            </div>
            <div className="lft">
              <h5>{translate("Lowest Price")}</h5>
              <span>
                {targetOffersData?.lowestPrice || "N/A"}{" "}
                {targetOffersData?.currency}
              </span>
            </div>
            <div className="rght">
              <div className="rght_iitm">
                <h5>FBA</h5>
                <span>
                  {targetOffersData?.lowestFbaPrice || "N/A"}{" "}
                  {targetOffersData?.currency}
                </span>
              </div>
              <div className="rght_iitm">
                <h5>FBM</h5>
                <span>
                  {targetOffersData?.lowestFbmPrice || "N/A"}{" "}
                  {targetOffersData?.currency}
                </span>
              </div>
            </div>
          </div>

          <div className="inf__fee_card">
            <div className="lft">
              <h5>{translate("Total Offer Count")}</h5>
              <span>
                {targetOffersData?.fbaOffersCount +
                  targetOffersData?.fbmOffersCount}
              </span>
            </div>
            <div className="rght">
              <div className="rght_iitm">
                <h5>FBA</h5>
                <span>{targetOffersData?.fbaOffersCount}</span>
              </div>
              <div className="rght_iitm">
                <h5>FBM</h5>
                <span>{targetOffersData?.fbmOffersCount}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketComparison;
