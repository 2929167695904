import React from "react";
import { Tooltip } from "antd";
import FeatherIcon from "feather-icons-react";
import translate from "../../../../shared/utils/translations";

const KepickerCalculator = ({
  mainData,
  setBasePrice,
  setTargetPrice,
  setShippingCost,
  basePrice,
  targetPrice,
  shippingCost,
  reset,
}) => {
  return (
    <>
      <div className="product__cal_info">
        <img src="/assets/img/icons8-calculator-64.png" />
        <div className="calc__inppt">
          <div className="field__label">{translate("Source price")}</div>
          <div className="field__wrap">
            <input
              className="field__input"
              type="number"
              placeholder="Source price"
              value={parseFloat(basePrice).toFixed(2)}
              onChange={(e) => setBasePrice(e.target.value)}
            />
          </div>
        </div>
        <div className="calc__inppt">
          <div className="field__label">{translate("Target price")}</div>
          <div className="field__wrap">
            <input
              className="field__input"
              type="number"
              placeholder="Target price"
              value={parseFloat(targetPrice).toFixed(2)}
              onChange={(e) => setTargetPrice(e.target.value)}
            />
          </div>
        </div>
        <div className="calc__inppt">
          <div className="field__label">{translate("Inbound Cost")}</div>
          <div className="field__wrap">
            <input
              className="field__input"
              type="number"
              placeholder="Inbound Cost"
              value={parseFloat(shippingCost).toFixed(2)}
              onChange={(e) => setShippingCost(e.target.value)}
            />
          </div>
        </div>

        <div className="calcs_Actnss">
          {/* <Tooltip title={"Calculate"} placement="top">
            <button className="icconc__btn">
              <FeatherIcon icon={"check"} />
            </button>
          </Tooltip> */}
          <Tooltip title={"Reset"} placement="top">
            <button onClick={reset} className="icconc__btn">
              <FeatherIcon icon={"rotate-ccw"} />
            </button>
          </Tooltip>
        </div>
      </div>
    </>
  );
};

export default KepickerCalculator;
