import React, { useEffect } from "react";
import { Checkbox, Col, Row, Radio, Drawer } from "antd";
import { useState } from "react";
import translate from "../../../shared/utils/translations";
import "react-quill/dist/quill.snow.css";

const UserListDrawer = ({
  isUserListDrawer,
  setUserListDrawer,
  sendEmail,
  saveDraft,
  users,
  setFormData,
  formData,
}) => {
  const [value, setValue] = useState("");
  const [buffer, setBuffer] = useState([]); //for search
  const [query, setQuery] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const filterUsers = (e) => {
    console.log(e.target.value);
    const d = selectedFilters;
    console.log(d);
    const usersBuffer = users
      ?.map((i) => {
        return {
          ...i,
          hasMarketConnection:
            i?.has_seller_account_na ||
            i?.has_seller_account_eu ||
            i?.has_seller_account_fe,
        };
      })
      .filter((item) => {
        if (e.target.value === "selectAll") {
          return true;
        }
        if (e.target.value === "deselectAll") {
          return true;
        }
        if (e.target.value === "marketConnected") {
          return item.hasMarketConnection;
        }
        if (e.target.value === "marketNotConnected") {
          return !item.hasMarketConnection;
        }
        if (e.target.value === "euConnected") {
          return item.has_seller_account_eu;
        }
        if (e.target.value === "naConnected") {
          return item.has_seller_account_na;
        }
        if (e.target.value === "feConnected") {
          return item.has_seller_account_fe;
        }
        if (e.target.value === "free") {
          return item.tier_id === 1;
        }
        if (e.target.value === "paid") {
          return item.tier_id !== 1;
        }
        if (e.target.value === "basic") {
          return item.tier_id === 2;
        }
        if (e.target.value === "plus") {
          return item.tier_id === 3;
        }
        if (e.target.value === "pro") {
          return item.tier_id === 4;
        }
      });
    console.log("usersBuffer", usersBuffer);
    setBuffer(usersBuffer);
    setSelectedUsers(
      e.target.value === "deselectAll" ? [] : usersBuffer.map((i) => i.id)
    );
  };

  const selectUser = (id) => {
    if (selectedUsers.includes(id)) {
      setSelectedUsers(selectedUsers.filter((item) => item !== id));
    } else {
      setSelectedUsers([...selectedUsers, id]);
    }
  };

  useEffect(() => {
    if (query) {
      const filteredData = users?.filter((item) => {
        return (
          item?.email?.toLowerCase()?.includes(query?.toLowerCase()) ||
          item?.first_name?.toLowerCase()?.includes(query?.toLowerCase()) ||
          item?.last_name?.toString()?.includes(query?.toLowerCase()) ||
          item?.role?.toLowerCase()?.includes(query?.toLowerCase())
        );
      });
      setBuffer(filteredData);
    } else {
      setBuffer(users);
    }
  }, [query]);

  useEffect(() => {
    setBuffer(users);
  }, [users]);

  useEffect(() => {
    console.log("selectedUsers changed", selectedUsers);
    setFormData({ ...formData, users: selectedUsers });
  }, [selectedUsers]);
  return (
    <>
      <Drawer
        title={translate("Create Email")}
        placement="right"
        onClose={() => setUserListDrawer(false)}
        open={isUserListDrawer}
        width={"40%"}
      >
        <>
          <div className="question__form" style={{ maxWidth: "100%" }}>
            <div className="question__fieldset cvb">
              <div className="field">
                <div className="field__label">{translate("Filter Users")}</div>
                <div className="field__wrap">
                  <Radio.Group
                    onChange={(e) => filterUsers(e)}
                    defaultValue="a"
                  >
                    <Radio.Button value="deselectAll">
                      {translate("Custom")}
                    </Radio.Button>
                    <Radio.Button value="selectAll">
                      {translate("All Users")}
                    </Radio.Button>
                    <br />

                    <Radio.Button value="marketConnected">
                      {translate("Just Market Connected")}
                    </Radio.Button>
                    <Radio.Button value="marketNotConnected">
                      {translate("Just NOT Market Connected")}
                    </Radio.Button>
                    <br />

                    <Radio.Button value="euConnected">
                      {translate("Just EU Market Connected")}
                    </Radio.Button>
                    <Radio.Button value="naConnected">
                      {translate("Just NA Market Connected")}
                    </Radio.Button>
                    <Radio.Button value="feConnected">
                      {translate("Just FE Market Connected")}
                    </Radio.Button>
                    <br />

                    <Radio.Button value="free">
                      {translate("All Free")}
                    </Radio.Button>
                    <Radio.Button value="paid">
                      {translate("All Paid (Basic, Plus, Pro)")}
                    </Radio.Button>
                    <Radio.Button value="basic">
                      {translate("All Basic")}
                    </Radio.Button>
                    <Radio.Button value="plus">
                      {translate("All Plus")}
                    </Radio.Button>
                    <Radio.Button value="pro">
                      {translate("All Pro")}
                    </Radio.Button>
                  </Radio.Group>
                </div>

                <input
                  className="activity__input"
                  type="text"
                  name="search"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder={"Search"}
                  required=""
                />
              </div>
              <p>Selected Users: {selectedUsers.length}</p>

              <div className="field mt_40">
                <div className="field__label">{translate("Users List")}</div>
                <div className="field__wrap">
                  <div className="user__lissst">
                    {buffer?.map((user) => {
                      return (
                        <div
                          key={user?.id}
                          className="user__list__item"
                          onClick={() => selectUser(user.id)}
                        >
                          <div className="user__list__item__checkbox">
                            <input
                              type="checkbox"
                              checked={selectedUsers.includes(user.id)}
                            ></input>
                            <div className="user__list__item__email">
                              {user.email}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="question__btns">
              <button
                onClick={() => saveDraft()}
                className="button-small button-stroke question__button app"
                style={{ marginRight: "50px" }}
              >
                {translate("Save as Draft")}
              </button>

              <button
                onClick={() => sendEmail()}
                className="button-small question__button app"
              >
                {translate("Send")}
              </button>
            </div>
          </div>
        </>
      </Drawer>
    </>
  );
};

export default UserListDrawer;
