import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Table, Tag, Input, Modal, Button, Tooltip } from "antd";
import EmptyIcon from "../../../../../assets/img/empty.png";
import Swal from "sweetalert2";
import translate from "../../../../shared/utils/translations";
import toast, { Toaster } from "react-hot-toast";
import { Line, Circle } from "rc-progress";
import {
  copyToClipboard,
  downloadAsinListExcel,
  requestHandler,
} from "../../../../shared/utils/helpers";
import CustomShipping from "../../../../../assets/img/custom_shipping.svg";

const AsinsTable = ({
  isComparisonDrawer,
  setComparisonDrawer,
  data,
  setSelectedAsinLists,
  selectedAsinLists,
  setSelectedAsinList,
  selectedAsinList,
  mergeAsinLists,
  deleteAsinLists,
  startComparisonProcess,
  stopComparisonProcess,
  getAsinListAsins,
  refresh,
  setDownloadActive,
}) => {
  let navigate = useNavigate();
  const [isTableSort, setTableSort] = useState(() => {
    const savedSort = localStorage.getItem("tableSort");
    return savedSort !== null ? savedSort : "list";
  });
  const [selectedAsinListName, setSelectedAsinListName] = useState("");
  const [selectedAsinListComment, setSelectedAsinListComment] = useState("");
  const [selectedAsinListId, setSelectedAsinListId] = useState("");
  useEffect(() => {
    localStorage.setItem("tableSort", isTableSort);
  }, [isTableSort]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  useEffect(() => {
    console.log(selectedRowKeys);
    setSelectedAsinLists(selectedRowKeys);
  }, [selectedRowKeys]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 1;
  const hasDeleted = selectedRowKeys.length > 0;
  useEffect(() => {
    setDownloadActive(hasDeleted);
  }, [hasDeleted]);

  const mergeItems = () => {
    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Are you sure about performing this action?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        mergeAsinLists(selectedRowKeys);
        setSelectedRowKeys([]);
        toast.success(translate("Your action was successful!"));
      }
    });
  };

  const deleteItems = () => {
    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Are you sure about performing this action?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log("deleteItems", selectedRowKeys);
        deleteAsinLists(selectedRowKeys);
        setSelectedRowKeys([]);
        toast.success(translate("Your action was successful!"));
      }
    });
  };
  const deleteItem = (id) => {
    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Are you sure about performing this action?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log("deleteItems", selectedRowKeys);
        deleteAsinLists([id]);
        setSelectedRowKeys([]);
        toast.success(translate("Your action was successful!"));
      }
    });
  };
  const startComparison = (id) => {
    if (user?.token_count >= 1) {
      startComparisonProcess(id);
    } else {
      toast.error(
        translate(
          "Sorry, You're Out of Tokens! Upgrade Your Package or buy extra Tokens."
        )
      );
    }
  };
  const stopComparison = (id) => {
    stopComparisonProcess(id);
  };
  const copyItems = async (id) => {
    const asins = await getAsinListAsins(id).then((res) => {
      console.log(res);
      return res.asins.map((i) => i.asin);
    });
    console.log(asins);
    copyToClipboard(asins.join("\n"));

    toast.success(translate("Asin list copied!"));
  };
  const downloadItems = async (id, name) => {
    const asinData = await getAsinListAsins(id);
    console.log(asinData);
    downloadAsinListExcel(asinData.asins, name);
    toast.success(translate("Asin list download started!"));
  };
  const updateAsinListNameAndComment = async (id) => {
    console.log(id, selectedAsinListName, selectedAsinListComment);
    // updateAsinList(id, selectedAsinListName, selectedAsinListComment);
    await requestHandler("put", `/asinLists/note/${id}`, {
      name: selectedAsinListName,
      comment: selectedAsinListComment,
    }).then(() => {
      toast.success(translate("Asin list updated!"));
      toast.success(translate("Note applied successfully!"));
      setNoteModal(false);
      setNoteBtn(true);
      setSelectedAsinListId("");
      setSelectedAsinListComment("");
      setSelectedAsinListName("");
      refresh();
    });
  };

  const columns = [
    {
      title: translate("Name"),
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name - b.name,
      render: (item) => (
        <>
          <div className="column_info">{translate("Name")}</div>
          {item.startsWith("http://") || item.startsWith("https://") ? (
            <a
              className="table__lnk"
              rel="noopener noreferrer"
              target="_blank"
              href={item}
            >
              {translate("Go to Page")}
            </a>
          ) : (
            <span>{item}</span>
          )}
        </>
      ),
    },
    {
      title: translate("Asin count"),
      dataIndex: "asin_count",
      key: "asin_count",
      sorter: (a, b) => a.asin_count - b.asin_count,
      render: (item) => (
        <>
          <div className="column_info">{translate("Asin count")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Preference Name"),
      dataIndex: "preference_name",
      key: "preference_name",
      sorter: (a, b) => a.preference_name - b.preference_name,
      render: (item, record) => (
        <>
          <div className="column_info">{translate("Preference Name")}</div>
          <div className="table_settngs_infos">
            {record?.is_fba ? (
              <span className="is__fbam_tag ffba">
                {/* Eğer mevcut ayarda Custom Shipping aktif ise img aktif olacak */}
                FBA{" "}
                {record?.shipping_type == "customWeightMap" ? (
                  <img src={CustomShipping}></img>
                ) : (
                  <></>
                )}
              </span>
            ) : (
              <span className="is__fbam_tag ffbm">
                FBM{" "}
                {record?.shipping_type == "customWeightMap" ? (
                  <img src={CustomShipping}></img>
                ) : (
                  <></>
                )}
              </span>
            )}
            <span className="table_settngTitle">{item}</span>
          </div>
        </>
      ),
    },
    {
      title: translate("Status"),
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.process - b.process,
      render: (item, row) => (
        <>
          <div className="column_info">{translate("Status")}</div>

          <div className="kstatus__area">
            <div className="kstatus_infs">
              <div
                className={`sts ${
                  item === "completed"
                    ? "completed"
                    : item === "stopped"
                    ? "stopped"
                    : item === "processing"
                    ? "processing"
                    : item === "queued"
                    ? "queued"
                    : " "
                }`}
              >
                {item === "processing" ? (
                  <div className="loader_v2">
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                    <div className="bar4"></div>
                    <div className="bar5"></div>
                    <div className="bar6"></div>
                    <div className="bar7"></div>
                    <div className="bar8"></div>
                    <div className="bar9"></div>
                    <div className="bar10"></div>
                    <div className="bar11"></div>
                    <div className="bar12"></div>
                  </div>
                ) : (
                  <></>
                )}
                {item}
              </div>
              <span>
                {(parseFloat(row.calculated_asin_count) /
                  parseFloat(row.asin_count)) *
                  100 >=
                100
                  ? 100
                  : (
                      (parseFloat(row.calculated_asin_count) /
                        parseFloat(row.asin_count)) *
                      100
                    ).toFixed(2)}
                %
              </span>
            </div>
            <Line
              percent={
                (parseFloat(row.calculated_asin_count) /
                  parseFloat(row.asin_count)) *
                  100 >=
                100
                  ? 100
                  : (
                      (parseFloat(row.calculated_asin_count) /
                        parseFloat(row.asin_count)) *
                      100
                    ).toFixed(2)
              }
              strokeWidth={2}
              strokeColor={`${
                item === "completed"
                  ? "#6ab04c"
                  : item === "stopped"
                  ? "#eb4d4b"
                  : item === "processing"
                  ? "#22a6b3"
                  : item === "queued"
                  ? "#f9ca24"
                  : " "
              }`}
            />
          </div>
        </>
      ),
    },
    {
      title: translate("Origin"),
      dataIndex: "origin",
      key: "origin",
      sorter: (a, b) => a.origin - b.origin,
      render: (item) => (
        <>
          <div className="column_info">{translate("Origin")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Date"),
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) => a.created_at - b.created_at,
      render: (item) => (
        <>
          <div className="column_info">{translate("Date")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Action"),
      key: "action",
      className: "table_actin",
      render: (text, record) => (
        <>
          <div className="column_info">{translate("Details")}</div>

          <div className="flex_table_buttons">
            {record.status !== "processing" ? (
              <Tooltip title={translate("Start Comparison")} placement="top">
                <Button
                  onClick={() => startComparison(record.id)}
                  icon={<FeatherIcon icon="zap" />}
                  className={"table_icon"}
                />
              </Tooltip>
            ) : (
              <Tooltip title={translate("Stop Comparison")} placement="top">
                <Button
                  onClick={() => stopComparison(record.id)}
                  icon={<FeatherIcon icon="zap" />}
                  className={"table_icon"}
                />
              </Tooltip>
            )}

            <Tooltip title={translate("View Details")} placement="top">
              <Button
                onClick={() => {
                  setComparisonDrawer(true);
                  setSelectedAsinList(record.id);
                }}
                icon={<FeatherIcon icon="eye" />}
                className={"table_icon"}
              />
            </Tooltip>

            <Tooltip title={translate("Copy Asin List")} placement="top">
              <Button
                onClick={() => copyItems(record.id)}
                icon={<FeatherIcon icon="copy" />}
                className={"table_icon"}
              />
            </Tooltip>

            <Tooltip title={translate("Download Asin List")} placement="top">
              <Button
                onClick={() => downloadItems(record.id, record.name)}
                icon={<FeatherIcon icon="download-cloud" />}
                className={"table_icon"}
              />
            </Tooltip>

            <Tooltip title={translate("Product Note")} placement="top">
              <Button
                onClick={() => {
                  setSelectedAsinListName(record.name);
                  setSelectedAsinListComment(record.note);
                  setSelectedAsinListId(record.id);
                  setNoteModal(true);
                }}
                icon={<FeatherIcon icon="edit" />}
                className={`table_icon edit___btn ${record?.note && " active"}`}
              />
            </Tooltip>

            <Tooltip title={translate("Delete")} placement="top">
              <Button
                onClick={() => deleteItem(record.id)}
                icon={<FeatherIcon icon="trash" />}
                className={"table_icon"}
              />
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  const { TextArea } = Input;

  const [isNote, setNote] = useState("");
  const [isNoteModal, setNoteModal] = useState(false);
  const [isNoteBtn, setNoteBtn] = useState(false);

  const handleApplyNote = () => {
    if (selectedAsinListName.trim() === "") {
      toast.error(translate("Note cannot be empty!"));
    } else {
      updateAsinListNameAndComment(selectedAsinListId);
    }
  };

  const [user, setUser] = useState({});
  const getUser = async () => {
    requestHandler("get", `/profile`, null, true, navigate, "/").then((res) => {
      console.log(res);
      setUser(res?.data);
    });
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <Modal
        title={translate("Table Name & Note")}
        centered
        visible={isNoteModal}
        onOk={() => setNoteModal(false)}
        onCancel={() => setNoteModal(false)}
        footer={[
          <Button key="back" onClick={() => setNoteModal(false)}>
            {translate("Cancel")}
          </Button>,
          <Button key="submit" type="primary" onClick={handleApplyNote}>
            {translate("Apply")}
          </Button>,
        ]}
      >
        <div className="coppyy_Asin_Area">
          <p className="ant-copiedd-text">{translate("Change table name:")}</p>
          <div className={`field tty`}>
            <div className="field__wrap">
              <input
                id={"table-clumn-name"}
                name={"table-clumn-name"}
                className="field__input"
                type={"text"}
                placeholder={translate("Table column name..")}
                required=""
                value={selectedAsinListName}
                onChange={(e) => setSelectedAsinListName(e.target.value)}
              />
            </div>
          </div>

          <p className="ant-copiedd-text">
            {translate("Write your note here:")}
          </p>
          <TextArea
            value={selectedAsinListComment}
            onChange={(e) => setSelectedAsinListComment(e.target.value)}
            placeholder={translate("Add a note..")}
            autoSize={{
              minRows: 5,
              maxRows: 6,
            }}
          />
        </div>
      </Modal>
      <div
        className={`dif__ablee ${isTableSort === "list" ? " " : " block_m"}`}
      >
        <div className="merged___area">
          <div className="table__sortn">
            <span
              onClick={() => setTableSort("list")}
              className={`${isTableSort === "list" ? "active" : ""}`}
            >
              <FeatherIcon icon={"list"} />
            </span>
            <span
              onClick={() => setTableSort("grid")}
              className={`${isTableSort === "grid" ? "active" : ""}`}
            >
              <FeatherIcon icon={"grid"} />
            </span>
          </div>
          <button
            onClick={mergeItems}
            disabled={!hasSelected}
            className="button-stroke button-small activity__button js-activity-button cc link"
          >
            <span>
              <FeatherIcon icon={"archive"} /> {translate("Merge Asin Lists")}
            </span>
          </button>
          <button
            onClick={deleteItems}
            disabled={!hasDeleted}
            className="button-stroke button-small activity__button js-activity-button cc link"
          >
            <span>
              <FeatherIcon icon={"trash"} /> {translate("Delete")}
            </span>
          </button>
          {/* <button
            onClick={refresh}
            className="button-stroke button-small activity__button js-activity-button cc link"
          >
            <span>
              <FeatherIcon icon={"candle"} /> Refresh
            </span>
          </button> */}
        </div>
        <Table
          columns={columns}
          dataSource={data}
          rowSelection={rowSelection}
          pagination={{
            locale: {
              items_per_page: translate("Page"),
            },
            position: ["topRight", "bottomRight"],
          }}
          locale={{
            emptyText: (
              <span className="empty__tble">
                <img src={EmptyIcon} />
              </span>
            ),
          }}
          className=" has_pag_btn"
        />
      </div>
    </>
  );
};

export default AsinsTable;
