import React from "react";
import translate from "../../../shared/utils/translations";

const Logs = ({ data }) => {
  return (
    <>
      <div className="logs__area_kk">
        <ul>
          {data?.length ? (
            data?.map((item, index) => (
              <li key={index}>
                <span className="ll_time">{item?.created_at?.replace("T", " ")?.split(".")[0]}</span>
                <span className="ll_content">preference name: {item?.name}</span>
                <span className="ll_content">base domain: {item?.base_domain}</span>
                <span className="ll_content">target domain: {item?.target_domain}</span>
                <span className="ll_content">shipping deal: {item?.shipping_deal}</span>
                <span className="ll_type"></span>
              </li>
            ))
          ) : (
            <li>
              <span className="ll_time">{translate("No logs found!")}</span>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default Logs;
