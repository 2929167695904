import React, { useEffect, useState } from "react";
import Input from "../../common/form_elements/input";
import BottomButtons from "./_bottom_buttons";
import { changePassword } from "../utils/auth";
import translate from "../utils/translations";
import { notify, requestHandler } from "../utils/helpers";
import FeatherIcon from "feather-icons-react";
import toast, { Toaster } from "react-hot-toast";
import Swal from "sweetalert2";
import { NavLink, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
const Security = ({ setPage, isPage }) => {
  const navigate = useNavigate();
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [formData, setFormData] = useState({});
  useEffect(() => {
    if (
      isPage === "dashboard-admin" ||
      localStorage.getItem("dashboard-type") === "dashboard-admin"
    ) {
      setPage("dashboard-admin");
      localStorage.setItem("dashboard-type", "dashboard-admin");
    } else {
      setPage("dashboard-client");
      localStorage.setItem("dashboard-type", "dashboard-client");
    }
  });
  const onChange = (value, name) => {
    setFormData({ ...formData, [name]: value });
  };
  const submit = async () => {
    console.log("data", formData);
    if (!formData.oldPassword || !formData.newPassword)
      notify(translate("Please fill all fields!"), "error");
    else if (formData.oldPassword === formData.newPassword)
      notify("New password can't be the same as old password", "error");
    else {
      setBtnLoading(true);
      changePassword(formData.oldPassword, formData.newPassword)
        .then((res) => {
          console.log(
            "res",
            res,
            JSON.stringify(res),
            typeof res,
            res.message,
            formData.oldPassword,
            formData.newPassword
          );
          if (res.status === 200 || res === true) {
            notify("Password changed successfully", "success");
          } else if (res.status === 401) {
            notify("Old password is incorrect", "error");
          } else {
            notify(res.message, "error");
          }
          setBtnLoading(false);
        })
        .catch((err) => {
          console.log("err", err);
          notify("Something went wrong", "error");
          setBtnLoading(false);
        });
    }
  };
  const deleteAccount = async () => {
    Swal.fire({
      icon: "warning",
      title: translate("Now just a minute!"),
      text: translate(
        "Are you sure to delete your account? If you're sure, click Delete Account!!!"
      ),
      confirmButtonText: translate("Delete Account"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: translate("Please wait.."),
          html: translate("Delete account in progress..."),
          timer: 2000,
          timerProgressBar: true,
          didOpen: async () => {
            await requestHandler(
              "delete",
              "/profile",
              null,
              true,
              navigate,
              "/login"
            );
            localStorage.clear();
            navigate("/");
            window.location.reload();
            Swal.showLoading();
          },
          willClose: () => {
            toast.success(
              translate("All your personal data has been successfully deleted!")
            );
            Swal.fire(translate("Account deletion successful!"), "", "success");
          },
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            console.log("I was closed by the timer");
          }
        });
      } else if (result.isDenied) {
      }
    });
  };

  const [isShowPass, setShowPass] = useState(false);
  return (
    <>
    <Helmet>
      <title>{translate("Login and security")}</title>
    </Helmet>
      <div className="outer__inner">
        <div className="onex_wl bg_white">
          <div className="onex_wl__wrapper">
            <div className="upload__head">
              <div className="dashbordi__nav mm">
                <NavLink className="dashbordi__link" to="/personal-settings">{translate("Personal")}</NavLink>
                <NavLink className="dashbordi__link" to="/security-settings">{translate("Security")}</NavLink>
                <NavLink className="dashbordi__link" to="/marketplace-settings">{translate("Marketplace")}</NavLink>
                <NavLink className="dashbordi__link" to="/integration-settings">{translate("Integrations")}</NavLink>
              </div>
              <h2 className="upload__title h2">
                {translate("Login and security")}
              </h2>
            </div>
            <div className="keys__info">
              {translate(
                "Do not forget to choose strong passwords for your security."
              )}
            </div>
            <div className="onex_wl__list">
              <div className="keys__enabled d_block">
                <div className="keys__list">
                  <div className="keys__item" style={{ maxWidth: "600px" }}>
                    <div className="setting__list">
                      <div className="setting__section">
                        <div className="setting__category">
                          {translate("Change password")}
                        </div>
                        <div className="setting__fieldset">
                          <div className="setting__row">
                            <Input
                              id="old-password"
                              name="oldPassword"
                              label={translate("Old Password")}
                              type="password"
                              placeholder={translate("Enter your old Password")}
                              onChange={onChange}
                              value={formData.oldPassword}
                            />
                          </div>

                          <div className="setting__row stt_re">
                            <Input
                              id="renew-password"
                              name="newPassword"
                              label={translate("New Password")}
                              type={isShowPass ? "text" : "password"}
                              placeholder={translate("Enter your new Password")}
                              onChange={onChange}
                              value={formData.newPassword}
                            />
                            <button
                              className="field__view"
                              onClick={() => setShowPass(!isShowPass)}
                            >
                              <FeatherIcon
                                icon={isShowPass ? "eye-off" : "eye"}
                                width="20px"
                                height="20px"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="setting__controls bbn">
                      <button
                        className="button setting__button"
                        onClick={submit}
                      >
                        {translate("Update")}{" "}
                        {isBtnLoading && <span className="btn-spinner"></span>}
                      </button>
                    </div>
                    <div className="setting__box dltt__acc">
                      <div className="setting__category">
                        {translate("Delete Account")}
                      </div>
                      <div className="setting__notification">
                        <div className="setting__subtitle">
                          {translate("Warning!")}
                        </div>
                        <div className="setting__content">
                          {translate(
                            "After deleting your account, you will not be able to log in anymore. All personal data will be deleted, all tool's data will be anonymized. This is irreversible."
                          )}
                          <br />
                          <br />

                          <strong>
                            {translate(
                              "If you delete your account, you cannot connect the markets you have linked to your new account!!!"
                            )}
                          </strong>
                          <br />
                          <br />
                        </div>
                        <button
                          className="button-stroke button-small setting__button delete_acc"
                          onClick={deleteAccount}
                        >
                          {translate("Delete My Account")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Security;
