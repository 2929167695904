import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Table, Tag, Dropdown, Menu, Button } from "antd";
import translate from "../../../shared/utils/translations";

const DiscoveryTable = () => {
  let navigate = useNavigate();

  const columns = [
    {
      title: "Image",
      dataIndex: "img",
      key: "img",
      render: (item) => (
        <>
          <div className="column_info">Image</div>
          <img
            className="product-photo"
            src={"/assets/img/figure-3.png"}
            alt=""
          />
        </>
      ),
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      render: (item) => (
        <>
          <div className="column_info">ASIN</div>
          {item}
        </>
      ),
    },
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
      render: (item) => (
        <>
          <div className="column_info">Product Name</div>
          {item}
        </>
      ),
    },
    {
      title: "Client",
      dataIndex: "client",
      key: "client",
      render: (item) => (
        <>
          <div className="column_info">Client</div>
          {item}
        </>
      ),
    },
    {
      title: "Sales Rank",
      dataIndex: "salesRank",
      key: "salesRank",
      render: (item) => (
        <>
          <div className="column_info">Sales Rank</div>
          {item}
        </>
      ),
    },
    {
      title: "ROI",
      dataIndex: "roi",
      key: "roi",
      render: (item) => (
        <>
          <div className="column_info">ROI</div>
          {item}
        </>
      ),
    },
    {
      title: "Tags",
      key: "tags",
      dataIndex: "tags",
      render: (tags) => (
        <>
          {tags.map((tag) => {
            // let color = "#52c41a";
            // if (tag === "Bad") {
            //   color = "#820014";
            // }
            // if (tag === "Very Good") {
            //   color = "#237804";
            // }
            return (
              <Tag key={tag} className="default__tag">
                {tag}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      className: "table_actin",
      render: (text, record) => (
        <>
          <div className="column_info">Details</div>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item>Remove item</Menu.Item>
                <Menu.Item>Custom item #2</Menu.Item>
                <Menu.Item>Custom item #3</Menu.Item>
              </Menu>
            }
          >
            <Button
              icon={
                <svg className="icon icon-cog">
                  <use xlinkHref="#icon-cog"></use>
                </svg>
              }
              className={"table_icon"}
            />
          </Dropdown>
        </>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      img: "/assets/img/figure-3.png",
      asin: "##34534222",
      name: "Yellow Couch",
      client: "John Doe",
      salesRank: 3200,
      roi: 25,
      tags: ["Good"],
    },
    {
      key: "2",
      img: "/assets/img/figure-3.png",
      asin: "##34534000",
      name: "Green Couch",
      client: "John Doe",
      salesRank: 2888,
      roi: 25,
      tags: ["Good"],
    },
    {
      key: "3",
      img: "/assets/img/figure-3.png",
      asin: "##34534654",
      name: "Turquoise Seat",
      client: "John Doe",
      salesRank: 1200,
      roi: 25,
      tags: ["Good"],
    },
    {
      key: "4",
      img: "/assets/img/figure-3.png",
      asin: "##34534877",
      name: "Cyan Couch",
      client: "John Doe",
      salesRank: 8560,
      roi: 8,
      tags: ["Bad"],
    },
    {
      key: "5",
      img: "/assets/img/figure-3.png",
      asin: "##34534342",
      name: "Coffee Table",
      client: "John Doe",
      salesRank: 1150,
      roi: 75,
      tags: ["Very Good"],
    },
    {
      key: "7",
      img: "/assets/img/figure-3.png",
      asin: "##34534456",
      name: "Red Couch",
      client: "John Doe",
      salesRank: 9500,
      roi: 5,
      tags: ["Bad"],
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          locale: {
            items_per_page: translate("Page"),
          },
          position: ["top", "bottom"],
        }}
        className="card_m"
      />
    </>
  );
};

export default DiscoveryTable;
