import React, { useEffect, useState } from "react";
import { Tooltip, Modal, Button, Input } from "antd";
import translate from "../../../../shared/utils/translations";
import FeatherIcon from "feather-icons-react";
import {
  calculateManualRoi,
  fastConvertCurrency,
  makeReadable,
  requestHandler,
} from "../../../../shared/utils/helpers";
// erdem gy
const ManualOtherMarketplaces = ({
  availableMarkets,
  asin,
  currency,
  basePrice,
  shippingCost,
  preferences,
  selectedMarketplaces,
  setSelectedMarketplaces,
  setOtherInfos,
  setSelectedAdditionalData,
  setSelectedKeepaData,
  setSalesRankCategory,
  setTitle,
  setImageUrl,
  setItemDimensions,
  setPackageDimensions,
  setItemWeight,
  setPackageWeight,
}) => {
  const [isMarketplacesModal, setMarketplacesModal] = useState(false);
  const [marketplaceData, setMarketplaceData] = useState({});
  const [roiObjs, setRoiObjs] = useState([]);

  const handleSelectedMarketPlaces = (value) => {
    setSelectedMarketplaces((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  const fetchMarketplacesData = async (selectedManualMarketplaces) => {
    //fetch one by one
    for (let i = 0; i < selectedManualMarketplaces.length; i++) {
      await fetchData(selectedManualMarketplaces[i]);
    }
  };
  useEffect(() => {
    const selectedManualMarketplaces = JSON.parse(
      localStorage.getItem("selectedManualMarketplaces")
    );
    if (selectedManualMarketplaces) {
      setSelectedMarketplaces(selectedManualMarketplaces);
    }
    fetchMarketplacesData(selectedManualMarketplaces);
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "selectedManualMarketplaces",
      JSON.stringify(selectedMarketplaces)
    );

    //check if one of the selected marketPlaces has no marketplaceData
    const selectedMarketplacesData = Object.keys(marketplaceData);
    const selectedMarketplacesDifference = selectedMarketplaces.filter(
      (item) => !selectedMarketplacesData.includes(item)
    );

    console.log(
      "selectedMarketplacesDifference",
      selectedMarketplacesDifference
    );
    if (selectedMarketplacesDifference.length > 0) {
      for (let i = 0; i < selectedMarketplacesDifference.length; i++) {
        fetchData(selectedMarketplacesDifference[i]);
      }
    }
  }, [selectedMarketplaces]);

  //fetch other marketplaces data

  const fetchData = async (market) => {
    try {
      const res = await requestHandler(
        "post",
        `/sp-api/manual`,
        { asin, target_domain: market },
        true,
        null,
        null
      );

      const newMarketData = {
        rates: res?.data?.rates,
        targetPrice: parseFloat(res?.data?.targetOffers?.price),
        targetPriceConverted: fastConvertCurrency(
          res?.data?.rates,
          parseFloat(res?.data?.targetOffers?.price),
          res?.data?.targetOffers?.currency,
          currency
        ),
        currency: res?.data?.targetOffers?.currency,
        shippingCost: parseFloat(res?.data?.shippingCost).toFixed(2) || 0,
        fees: parseFloat(res?.data?.fees).toFixed(2),
        category: makeReadable(res?.data?.targetOffers?.salesRankCategory),
        salesRank: res?.data?.targetOffers?.salesRank,
        bsrDrops: {
          drops30: res?.data?.keepaData?.salesRankDrops30,
          drops90: res?.data?.keepaData?.salesRankDrops90,
          drops180: res?.data?.keepaData?.salesRankDrops180,
        },
        additionalData: res?.data?.additionalData,
        keepaData: res?.data?.keepaData,
        targetOffers: res?.data?.targetOffers,
      };
      setSalesRankCategory(
        makeReadable(res?.data?.targetOffers?.salesRankCategory)
      );
      setTitle(res?.data?.additionalData?.title);
      setImageUrl(res?.data?.additionalData?.image);
      console.log(
        "dimensions",
        res?.data?.additionalData?.item_dimensions_cm,
        "package_dimensions_cm",
        res?.data?.additionalData?.package_dimensions_cm,
        "item_weight_kg",
        res?.data?.additionalData?.item_weight_kg,
        "package_weight_kg",
        res?.data?.additionalData?.package_weight_kg
      );
      res?.data?.additionalData?.item_dimensions_cm &&
        setItemDimensions(res?.data?.additionalData?.item_dimensions_cm);
      res?.data?.additionalData?.package_dimensions_cm &&
        setPackageDimensions(res?.data?.additionalData?.package_dimensions_cm);
      res?.data?.additionalData?.item_weight_kg &&
        setItemWeight(res?.data?.additionalData?.item_weight_kg);
      res?.data?.additionalData?.package_weight_kg &&
        setPackageWeight(res?.data?.additionalData?.package_weight_kg);

      setMarketplaceData((prev) => ({
        ...prev,
        [market]: newMarketData,
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    console.log("marketplaceData", marketplaceData);
  }, [marketplaceData]);

  const calculateRoiForMarkets = () => {
    const roiUpdates = Object.keys(marketplaceData).map((key) => {
      const market = marketplaceData[key];
      const currentPreference = preferences.find(
        (item) => item.marketplace === key
      ) || {
        is_fba: true,
        vat_type: "noVat",
        vat_percentage: 19,
      };

      console.log(
        "market shipping cost",
        market?.shippingCost,
        fastConvertCurrency(
          market?.rates,
          parseFloat(market?.shippingCost) || 0,
          market?.currency,
          currency
        )
      );
      const roiObj = calculateManualRoi(
        basePrice,
        market.targetPriceConverted,
        fastConvertCurrency(
          market.rates,
          parseFloat(market.shippingCost) || 0,
          market.currency,
          currency
        ),
        fastConvertCurrency(
          market.rates,
          market.fees,
          market.currency,
          currency
        ),
        currentPreference?.is_fba,
        currentPreference?.vat_type,
        currentPreference?.vat_percentage
      );

      return {
        [key]: {
          ...roiObj,
          price: market.targetPrice,
          currency: market.currency,
          salesRank: market.salesRank,
          salesRankCategory: market.category,
          salesRankDrops30: market.bsrDrops.drops30,
          salesRankDrops90: market.bsrDrops.drops90,
          salesRankDrops180: market.bsrDrops.drops180,
        },
      };
    });

    setRoiObjs(roiUpdates);
  };

  const handleOtherInfos = (market) => {
    if (!marketplaceData[market]) return;
    setSelectedAdditionalData(marketplaceData[market]?.additionalData);
    setSelectedKeepaData(marketplaceData[market]?.keepaData);
    setOtherInfos(true);
  };

  useEffect(() => {
    calculateRoiForMarkets();
  }, [marketplaceData, basePrice, currency, shippingCost]);

  useEffect(() => {
    console.log("roiObjs", roiObjs);
  }, [roiObjs]);
  return (
    <>
      <Modal
        title={translate("Marketplace Selections")}
        centered
        visible={isMarketplacesModal}
        onOk={() => setMarketplacesModal(false)}
        onCancel={() => setMarketplacesModal(false)}
        footer={null}
      >
        <div className="coppyy_Asin_Area">
          <p className="ant-copiedd-text">
            {translate(
              "Your marketplaces that you can use for your connected account."
            )}
          </p>
          <div className="mrktss__sare">
            <div className="setting__fieldset">
              <div className="radio__boxt rrt">
                {/* ITEM 1 */}
                {availableMarkets.map((market, index) => {
                  return (
                    <label
                      className={`checkbox s_check rd`}
                      for={market?.domain}
                    >
                      <input
                        className="checkbox__input"
                        type="checkbox"
                        name="marketplaces"
                        id={market?.domain}
                        checked={selectedMarketplaces?.includes(market?.domain)}
                        onChange={(e) =>
                          handleSelectedMarketPlaces(market?.domain)
                        }
                      />

                      <span className="checkbox__inner">
                        <svg className="icon icon-plus">
                          <use xlinkHref="#icon-plus"></use>
                        </svg>
                        <svg className="icon icon-check">
                          <use xlinkHref="#icon-check"></use>
                        </svg>
                        <span className="checkbox__text">{market?.domain}</span>
                      </span>
                    </label>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <div className="market__cards_wrappera">
        <div
          className={`mrkt__hdr ${
            selectedMarketplaces?.length >= 1 ? "" : " none__markets"
          }`}
        >
          <div>
            <h4>{translate("Results of Marketplaces")}</h4>

            {selectedMarketplaces?.length >= 1 ? (
              <></>
            ) : (
              <p>
                {translate("Please select marketplace(s) to view results!")}
              </p>
            )}
          </div>

          <button
            onClick={() => setMarketplacesModal(true)}
            className="button-stroke button-small onex_wl__button button_upgtty"
          >
            {translate("Add / Remove Marketplaces")}
          </button>
        </div>

        {selectedMarketplaces?.length >= 1 ? (
          <div className="market__cards_area">
            {selectedMarketplaces?.map((card, index) => {
              const roiData = roiObjs.find(
                (obj) => Object.keys(obj)[0] === card
              )?.[card]; // Find ROI data for this marketplace
              const marketData = marketplaceData[card]; // Get marketplace data

              return (
                <div key={index} className="mini_market__card_web">
                  <div className="mini_market__card_body_web">
                    <div className="m_markt_hheader">
                      <a
                        href={`https://${card}/dp/${asin}`}
                        target="_blank"
                        className="m_markt_name_web"
                      >
                        {card}
                      </a>

                      <div className="other_m_actions">
                        <button
                          onClick={() => {
                            handleOtherInfos(card);
                          }}
                          className="up_btn token_bt"
                        >
                          <span>{translate("Other Infos")}</span>
                          <FeatherIcon icon={"arrow-up-right"} />
                        </button>
                      </div>
                    </div>
                    <div className="m_markt_infos">
                      <div className="m_markt_lis_web">
                        <div className="m_markt_lis_web_item_web">
                          <h6>ROI</h6>
                          <div
                            className={`def_vlluee_web roi__vlle_web ${
                              parseFloat(roiData?.roi) <= 1
                                ? "red"
                                : parseFloat(roiData?.roi) > 1 &&
                                  parseFloat(roiData?.roi) <= 30
                                ? "orange"
                                : parseFloat(roiData?.roi) > 30 &&
                                  parseFloat(roiData?.roi) <= 50
                                ? "orange-soft"
                                : parseFloat(roiData?.roi) > 50 &&
                                  parseFloat(roiData?.roi) <= 75
                                ? "green-soft"
                                : parseFloat(roiData?.roi) > 75
                                ? "green"
                                : "default"
                            }`}
                          >
                            {roiData?.roi ? roiData?.roi : " - "}
                          </div>
                        </div>
                        <div className="m_markt_lis_web_item_web">
                          <h6>{translate("Profit")}</h6>
                          <div
                            className={`def_vlluee_web prft__vlle_web ${
                              parseFloat(roiData?.profit) <= 0 ? "red" : "green"
                            }`}
                          >
                            {roiData?.profit
                              ? roiData?.profit + " " + roiData?.currency
                              : " - "}
                            <br />
                            {fastConvertCurrency(
                              marketData?.rates,
                              roiData?.profit,
                              roiData?.currency,
                              currency
                            ) +
                              " " +
                              currency}
                          </div>
                        </div>
                        <div className="m_markt_lis_web_item_web">
                          <h6>{translate("Price")}</h6>
                          <div className="def_vlluee_web">
                            {roiData?.price
                              ? roiData?.price + " " + roiData?.currency
                              : "N/A"}
                            <br />
                            {fastConvertCurrency(
                              marketData?.rates,
                              roiData?.price,
                              roiData?.currency,
                              currency
                            ) +
                              " " +
                              currency}
                          </div>
                        </div>
                      </div>
                      <div className="m_markt_lis_web">
                        <div className="m_markt_lis_web_item_web">
                          <h6>{translate("Buying Price")}</h6>
                          <div className="def_vlluee_web">
                            {basePrice} {marketData?.currency} <br />
                            {fastConvertCurrency(
                              marketData?.rates,
                              basePrice,
                              marketData?.currency,
                              currency
                            ) +
                              " " +
                              currency}
                          </div>
                        </div>
                        <div className="m_markt_lis_web_item_web">
                          <h6>{translate("Fees")}</h6>
                          <div className="def_vlluee_web">
                            {marketData?.fees + " " + marketData?.currency}{" "}
                            <br />
                            {fastConvertCurrency(
                              marketData?.rates,
                              marketData?.fees,
                              marketData?.currency,
                              currency
                            ) +
                              " " +
                              currency}
                          </div>
                        </div>
                        <div className="m_markt_lis_web_item_web">
                          <h6>{translate("Shipping Costs")}</h6>
                          <div className="def_vlluee_web">
                            {marketData?.shippingCost +
                              " " +
                              marketData?.currency}
                            <br />
                            {fastConvertCurrency(
                              marketData?.rates,
                              marketData?.shippingCost,
                              marketData?.currency,
                              currency
                            ) +
                              " " +
                              currency}
                          </div>
                        </div>
                      </div>
                      <div className="m_markt_lis_web">
                        <div className="m_markt_lis_web_item_web">
                          <h6>{translate("Category")}</h6>
                          <div className="def_vlluee_web">
                            {roiData?.salesRankCategory
                              ? roiData?.salesRankCategory
                              : " - "}
                          </div>
                        </div>
                        <div className="m_markt_lis_web_item_web">
                          <h6>{translate("Sales Rank")}</h6>
                          <div className="def_vlluee_web">
                            {roiData?.salesRank ? roiData?.salesRank : " - "}
                          </div>
                        </div>
                        <div className="m_markt_lis_web_item_web">
                          <h6>{translate("BSR Drops")}</h6>
                          <div className="def_vlluee_web">
                            {roiData?.salesRankDrops30
                              ? roiData?.salesRankDrops30 +
                                " / " +
                                roiData?.salesRankDrops90 +
                                " / " +
                                roiData?.salesRankDrops180
                              : "N/A"}
                          </div>
                        </div>
                      </div>
                      <div className="m_markt_lis_web">
                        <div className="m_markt_lis_web_item_web">
                          <h6>{translate("Monthly Sales")}</h6>
                          <div className="def_vlluee_web">
                            {marketData?.keepaData?.monthlySold ||
                              marketData?.keepaData?.salesRankDrops30 + "+"}
                          </div>
                        </div>
                        <div className="m_markt_lis_web_item_web">
                          <h6>{translate(`FBA Seller Count`)}</h6>
                          <div className="def_vlluee_web">
                            {marketData?.targetOffers?.fbaOffersCount}
                          </div>
                        </div>
                        <div className="m_markt_lis_web_item_web">
                          <h6>{translate("FBM Seller Count")}</h6>
                          <div className="def_vlluee_web">
                            {marketData?.targetOffers?.fbmOffersCount}
                          </div>
                        </div>
                      </div>

                      <div className="prdct__Tags">
                        {marketData?.keepaData?.variationCount > 0 && (
                          <Tooltip
                            title={translate(
                              "Variation Product - Variation Count"
                            )}
                            placement="bottom"
                          >
                            <div className="pb_icn_info">
                              <img
                                src={"/assets/img/icons8-variation-50.png"}
                              />
                              <span className="vari__bulp">
                                {marketData?.keepaData?.variationCount}
                              </span>
                            </div>
                          </Tooltip>
                        )}
                        {marketData?.additionalData?.is_hazmat && (
                          <div className="pb_icn_info">
                            <img src="/assets/img/icons8-skull-64.png" />
                            <div className="hover_card_details">
                              <div>
                                <ul>
                                  {marketData?.additionalData?.hazmat_data
                                    ?.filter((i) => i["aspect"] !== "exception")
                                    .map((i, index) => {
                                      delete i["marketplace_id"];
                                      return (
                                        <li key={index}>
                                          {makeReadable(i["aspect"])}:{" "}
                                          {i["value"]}
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                            </div>
                          </div>
                        )}
                        {marketData?.additionalData?.is_meltable && (
                          <Tooltip
                            title={translate("Heat Sensitive - Meltable")}
                            placement="bottom"
                          >
                            <div className="pb_icn_info">
                              <img
                                className="tt"
                                src="/assets/img/icons8-heat-48.png"
                              />
                            </div>
                          </Tooltip>
                        )}
                        {marketData?.targetOffers?.isAmazonIsSeller && (
                          <Tooltip
                            title={translate("Amazon Seller")}
                            placement="bottom"
                          >
                            <div className="pb_icn_info">
                              <img
                                className="tt"
                                src="/assets/img/icons8-amazon-96.png"
                              />
                            </div>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <>
            <div
              onClick={() => setMarketplacesModal(true)}
              className="more_markets_btn_web"
              role="button"
            >
              <div className="earth_markets_vector_web css_vals_web"></div>
              <div className="more_markets_middle_web">
                <div className="icn__add_m_web"></div>
                <h4>{translate("Click Here to Add..")}</h4>

                <p>
                  {translate(
                    "Quickly calculate other marketplaces that come with your connected account."
                  )}
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ManualOtherMarketplaces;
