import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import Lottie from "react-lottie-player";
import { NavLink, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import UserContext from "../../shared/utils/userContext";
import toast, { Toaster } from "react-hot-toast";
import Swal from "sweetalert2";
import translate from "../../shared/utils/translations";
import { requestHandler } from "../../shared/utils/helpers";
import { Tooltip } from "antd";
import { Helmet } from "react-helmet";

const DashboardClient = ({ setPage, isBuyTokenDrawer, setBuyTokenDrawer }) => {
  useEffect(() => {
    setPage("dashboard-client");
    localStorage.setItem("dashboard-type", "dashboard-client");
  });
  let navigate = useNavigate();

  const [user, setUser] = useState({});
  const [sellerIds, setSellerIds] = useState([]);

  useEffect(() => {}, []);

  localStorage.setItem("dashboard-type", "dashboard-admin");

  const handleMarketConnection = (tryCount = 0) => {
    const urlParams = new URLSearchParams(window.location.search);
    const amazonCallbackUri = urlParams?.get("amazon_callback_uri");
    const amazonState = urlParams?.get("amazon_state");
    const version = urlParams?.get("version");
    const sellingPartnerId = urlParams?.get("selling_partner_id");
    const spapi_oauth_code = urlParams?.get("spapi_oauth_code");
    const state = urlParams?.get("state");

    console.log("user", user);
    console.log("amazonCallbackUri", amazonCallbackUri);
    console.log("amazonState", amazonState);
    console.log("version", version);
    console.log("sellingPartnerId", sellingPartnerId);
    console.log("spapi_oauth_code", spapi_oauth_code);

    if (spapi_oauth_code) {
      console.log(
        "market connection triggered",
        state,
        spapi_oauth_code,
        sellingPartnerId
      );
      const token = localStorage.getItem("idToken");
      console.log("spapi_oauth_code sending request", spapi_oauth_code);

      axios
        .post(
          `/auth/amazon/${state}`,
          { code: spapi_oauth_code, selling_partner_id: sellingPartnerId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log("market connection response", res);
          if (res?.data?.message) {
            toast.error(res?.data?.message, "error");
          } else {
            toast.success(translate("Market connection successful!"));
          }
          getUser();
        })
        .catch((err) => {
          console.log("market connection error", err);

          if (tryCount < 3) {
            console.log("tryCount", tryCount);
            handleMarketConnection(tryCount + 1);
          } else {
            toast.error(translate("Market connection failed!"));
          }
        });
    }
  };
  const getUser = async () => {
    requestHandler("get", `/profile`, null, true, navigate, "/").then((res) => {
      console.log(res);
      setUser(res?.data);
    });
  };
  const loggedInUser = useContext(UserContext);

  console.log("loggedInUser", loggedInUser);
  const getSellerIds = async () => {
    requestHandler("get", `/profile/sellerIds`, null, true, navigate, "/").then(
      (res) => {
        console.log(res);
        setSellerIds(res?.data);
      }
    );
  };
  useEffect(() => {
    getUser().then(() => {
      getSellerIds();
      handleMarketConnection();
    });
  }, []);
  useEffect(() => {
    console.log("user", user);
  }, [user]);

  const removeMarket = async (server) => {
    Swal.fire({
      icon: "warning",
      title: translate("Disconnect"),
      text: translate("Are you sure want to disconnect market?"),
      confirmButtonText: translate("Disconnect"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: translate("Please wait.."),
          html: translate("Disconnection process started."),
          timer: 2000,
          timerProgressBar: true,
          didOpen: async () => {
            const updatedUser = await axios.delete(`/auth/amazon/${server}`);

            console.log("updatedUser", updatedUser);
            setUser(updatedUser);
            Swal.showLoading();
          },
          willClose: () => {
            toast.success(translate("Removing the market was successful!"));
            Swal.fire(translate("Disconnect Successful!"), "", "success");
            setTimeout(() => {
              window.location.reload(false);
            }, 1000);
          },
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            console.log("I was closed by the timer");
          }
        });
      } else if (result.isDenied) {
      }
    });
  };

  const [scrolPage, setY] = useState(window.scrollY);

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (scrolPage > window.scrollY) {
        console.log("scrolling up");
      } else if (scrolPage < window.scrollY) {
        console.log("scrolling down");
      }
      setY(window.scrollY);
    },
    [scrolPage]
  );

  useEffect(() => {
    setY(window.scrollY);
    window.addEventListener("scroll", handleNavigation);

    return () => {
      window.removeEventListener("scroll", handleNavigation);
    };
  }, [handleNavigation]);

  const shouldHideDiv =
    user?.has_seller_account_na ||
    user?.has_seller_account_eu ||
    user?.has_seller_account_fe;

  const sellerInfo = (data) => {
    return (
      data && (
        <div className="market__activities">
          <Tooltip title={"Seller ID"} placement="top">
            <span className="mrk__id">{data?.seller_id}</span>
          </Tooltip>
          <div className="inf__areas">
            <Tooltip title={translate("Protected Count")} placement="top">
              <span className="mrk__inf">
                <FeatherIcon icon={"shield"} /> {data?.protected_count}
              </span>
            </Tooltip>
            <Tooltip title={translate("Views Count")} placement="top">
              <span className="mrk__inf tt">
                <FeatherIcon icon={"activity"} /> {data?.request_count}
              </span>
            </Tooltip>
          </div>
        </div>
      )
    );
  };

  return (
    <>
      <Helmet>
        <title>{translate("Dashboard")}</title>
      </Helmet>

      <div className="outer__inner xxc">
        <div className="onex_wl client">
          <div className="onex_wl__wrapper ovww">
            <div className="onex_wl__main ovrvw new__ui">
              <div className="new__dashh">
                <div className="new__dashh__left">
                  <div className="card__kpp wlcm marketplace-step">
                    <div className="card__kpp_welcome">
                      <img
                        src="/assets/img/rocket.png"
                        className="hero__welcome"
                      ></img>

                      <div className="itmm__wlcc">
                        <h3>
                          <span>
                            {translate("Hello")} {user?.first_name}
                          </span>
                          <img src="/assets/img/wave.png"></img>
                        </h3>
                        <p>
                          {translate("We hope you're having a great day..")}
                        </p>
                      </div>

                      <div className="itmm__wlcc">
                        <h4>{translate("Beginner's Guide")}</h4>
                        <ul>
                          <li>
                            <img src="/assets/img/store.png"></img>
                            <div className="inf__thk">
                              <div className="title__thk">
                                1. {translate("Connect your marketplace.")}
                              </div>
                              <a
                                href="https://www.youtube.com/watch?v=DObvOu2-98Q&list=PLxi7_IEBzcmj2eio4ZJTsAID6AD08s3t8&index=8"
                                target="_blank"
                                className="desc__thk"
                              >
                                {translate("How can I connect my marketplace?")}
                              </a>
                            </div>
                          </li>
                          <li>
                            <img src="/assets/img/setting.png"></img>
                            <div className="inf__thk">
                              <div className="title__thk">
                                2. {translate("Edit marketplace options.")}
                              </div>
                              <NavLink
                                to="/marketplace-settings"
                                className="desc__thk"
                              >
                                {translate(
                                  "Where are my marketplace settings?"
                                )}
                              </NavLink>
                            </div>
                          </li>
                          <li>
                            <img src="/assets/img/cloud-download.png"></img>
                            <div className="inf__thk">
                              <div className="title__thk">
                                3.{" "}
                                {translate("Download the Kepicker extension.")}
                              </div>
                              <a
                                href="https://chromewebstore.google.com/detail/kepicker/hnpablpopdlabbiopkihkmlfmkgpihdc?hl=en"
                                target="_blank"
                                className="desc__thk"
                              >
                                {translate("Where do I download it?")}
                              </a>
                            </div>
                          </li>
                          <li>
                            <img src="/assets/img/agenda.png"></img>
                            <div className="inf__thk">
                              <div className="title__thk">
                                4.{" "}
                                {translate(
                                  "Now you are ready to use the extension."
                                )}
                              </div>
                              <NavLink to="/how-it-works" className="desc__thk">
                                {translate("User guide for the extension?")}
                              </NavLink>
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div className="itmm__wlcc">
                        <h4>{translate("Still having problems?")}</h4>

                        <div className="contact__arnvb">
                          <div className="contact__arnv_itm">
                            <img src="/assets/img/ticket.png"></img>
                            <div className="title__thk">
                              {translate("Create Ticket")}
                            </div>
                            <NavLink to="/tickets" className="desc__thk">
                              {translate("Create a Support Request")}
                            </NavLink>
                          </div>
                          <div className="contact__arnv_itm">
                            <img src="/assets/img/whatsapp.png"></img>
                            <div className="title__thk">
                              {translate("Whatsapp Support")}
                            </div>
                            <a
                              href="https://wa.me/+905076106514?text=Merhabalar,%20Kepicker%20eklentisi%20ile%20ilgili%20destek%20almak%20istiyorum.%20Yardımcı%20olabilir%20misiniz?"
                              target="_blank"
                              className="desc__thk"
                            >
                              +90 507 610 65 14
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="new__dashh__middle">
                  <div className="card__kpp">
                    <div className="card__kpp_header">
                      <div className="ll__hdr">
                        <h4>
                          {translate("Usage Limits")}{" "}
                          <Tooltip
                            title={
                              <div>
                                •{" "}
                                {translate(
                                  "1 Product Comparison = 1 Comparison Token"
                                )}
                                <br />•{" "}
                                {translate(
                                  "Opening 1 Product Page = 1 PBI Usage"
                                )}
                                <br />
                                <br />
                                <small>
                                  {translate(
                                    "Refreshing the page and recalculating also reduce your PBI usage limit!"
                                  )}
                                </small>
                              </div>
                            }
                            placement="top"
                          >
                            <span className="inff__text gg">
                              <FeatherIcon icon={"help-circle"} />
                            </span>
                          </Tooltip>
                        </h4>
                        <span>
                          {translate("Your usage rights for your plan.")}
                        </span>
                      </div>
                      <div className="rr__hdr">
                        <div className="tp-price-offer-badge-wrap">
                          <div className="price-shape-line">
                            <svg
                              width="80"
                              height="42"
                              viewBox="0 0 80 42"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M78.5938 8.78059C59.0829 45.2801 2.05127 -8.72021 27.0652 32.28C52.079 73.2801 48.5771 -41.2195 0.550438 18.7821"
                                stroke="#2196F3"
                                strokeDasharray="3 3"
                              ></path>
                            </svg>
                          </div>
                          <div className="price-offer-badge">
                            <span>{translate("Need tokens?")}</span>
                          </div>
                        </div>

                        {user?.tier_id && user.tier_id > 1 ? (
                          <a
                            href="#!"
                            role="button"
                            className="up_btn token_bt hh"
                            onClick={() => setBuyTokenDrawer(true)}
                          >
                            <span>{translate("More tokens")}</span>
                            <FeatherIcon icon={"plus"} />
                          </a>
                        ) : (
                          <a
                            href="#!"
                            role="button"
                            className="up_btn token_bt hh"
                            onClick={() =>
                              toast.error(
                                translate(
                                  "If you want to buy extra comparison tokens, you need to upgrade your package!"
                                )
                              )
                            }
                          >
                            <span>{translate("More tokens")}</span>
                            <FeatherIcon icon={"plus"} />
                          </a>
                        )}
                      </div>
                    </div>
                    <div className="card__kpp_body gg cc">
                      <div className="plan__ihm">
                        <img src="/assets/img/cashback-b.png"></img>
                        <h5>{translate("Comparison Tokens")}</h5>
                        <div className="inf__ihm nnb">
                          {user?.token_count}
                          <small className="tkn__type">
                            {user?.tier_id && user.tier_id <= 1
                              ? translate("for Once")
                              : translate("Monthly")}
                          </small>
                        </div>

                        {/* 100:#00b576
                    80: #7dc443
                    55: #cdd53d
                    25: #e59a39
                    10: #f44336 */}

                        <div className="progress-bar--usage">
                          <span className="bar--usage">
                            <span
                              style={{
                                width: `${
                                  user?.token_count >= 1000
                                    ? 100
                                    : (user?.token_count / 1000) * 100
                                }%`,
                                background:
                                  user?.token_count >= 1000
                                    ? "#00b576"
                                    : user?.token_count >= 800
                                    ? "#7dc443"
                                    : user?.token_count >= 550
                                    ? "#cdd53d"
                                    : user?.token_count >= 250
                                    ? "#e59a39"
                                    : "#f44336",
                              }}
                              className="progress--usage"
                            ></span>
                          </span>
                        </div>
                      </div>
                      <div className="plan__ihm">
                        <img src="/assets/img/timer-b.png"></img>
                        <h5>{translate("Product Based Info Limit")}</h5>
                        <div className="inf__ihm nnb">
                          {user?.asin_count}
                          <small className="tkn__type">
                            {user?.tier_id && user.tier_id <= 1
                              ? translate("for Once")
                              : translate("Daily")}
                          </small>
                        </div>

                        <div className="progress-bar--usage">
                          <span className="bar--usage">
                            <span
                              style={{
                                width: `${
                                  user?.asin_count >= 400
                                    ? 100
                                    : (user?.asin_count / 400) * 100
                                }%`,
                                background:
                                  user?.asin_count >= 400
                                    ? "#00b576"
                                    : user?.asin_count >= 300
                                    ? "#7dc443"
                                    : user?.asin_count >= 250
                                    ? "#cdd53d"
                                    : user?.asin_count >= 200
                                    ? "#e59a39"
                                    : "#f44336",
                              }}
                              className="progress--usage"
                            ></span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card__kpp marketplaceg-card">
                    <div className="card__kpp_header">
                      <div className="ll__hdr">
                        <h4>{translate("Connect your markets")}</h4>
                        <span>
                          <Tooltip
                            title={translate(
                              "We're developing software with SP-Api support, and we use SP-Api to gather essential market and product information while working with you. This is vital for our software's efficiency and providing top-notch service. Don't worry, we do not access your important and personal data. Don't hesitate to reach out for questions or more info. We're here to serve you."
                            )}
                            placement="top"
                          >
                            <span className="inff__text">
                              {translate("Why should I connect my market?")}{" "}
                              <FeatherIcon icon={"help-circle"} />
                            </span>
                          </Tooltip>
                        </span>
                      </div>
                      <div className="rr__hdr">
                        <NavLink
                          className="up_btn token_bt"
                          to="/marketplace-settings"
                        >
                          <span>{translate("Market settings")}</span>
                          <FeatherIcon icon={"settings"} />
                        </NavLink>
                      </div>
                    </div>
                    <div className="card__kpp_body">
                      <div className="mrk__itm">
                        <div
                          className={`mrk__inf ${
                            user?.has_seller_account_na && " active"
                          }`}
                        >
                          {user?.has_seller_account_na ? (
                            <img src="/assets/img/north-america.png"></img>
                          ) : (
                            <img src="/assets/img/north-americad.png"></img>
                          )}
                          <div className="mark_name">
                            <span>{translate("North America")}</span>
                            {sellerIds?.find((i) => i.server === "na") &&
                              sellerInfo(
                                sellerIds?.find((i) => i.server === "na")
                              )}
                          </div>
                        </div>
                        <div className="mrk__actions">
                          {user?.has_seller_account_na ? (
                            <div className="mrk__disconnect_actions">
                              <div className="mrk__connected">
                                <FeatherIcon icon={"check-circle"} />{" "}
                                {translate("Connected")}
                              </div>
                              <div
                                className="mrk__disconnect"
                                onClick={() => removeMarket("na")}
                              >
                                <FeatherIcon icon={"trash-2"} />{" "}
                                {translate("Disconnect")}
                              </div>
                            </div>
                          ) : (
                            <a
                              className="mrk__link"
                              href="https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.d1a57a0a-e0d5-404f-b399-9598caa8841b&state=na"
                              target="_blank"
                            >
                              {translate("Connect")}
                            </a>
                          )}
                        </div>
                      </div>
                      <div className="mrk__itm">
                        <div
                          className={`mrk__inf ${
                            user?.has_seller_account_eu && " active"
                          }`}
                        >
                          {user?.has_seller_account_eu ? (
                            <img src="/assets/img/europe.png"></img>
                          ) : (
                            <img src="/assets/img/europed.png"></img>
                          )}
                          <div className="mark_name">
                            <span>{translate("Europe")}</span>
                            {sellerIds?.find((i) => i.server === "eu") &&
                              sellerInfo(
                                sellerIds?.find((i) => i.server === "eu")
                              )}
                          </div>
                        </div>
                        <div className="mrk__actions">
                          {user?.has_seller_account_eu ? (
                            <div className="mrk__disconnect_actions">
                              <div className="mrk__connected">
                                <FeatherIcon icon={"check-circle"} />{" "}
                                {translate("Connected")}
                              </div>
                              <div
                                className="mrk__disconnect"
                                onClick={() => removeMarket("eu")}
                              >
                                <FeatherIcon icon={"trash-2"} />{" "}
                                {translate("Disconnect")}
                              </div>
                            </div>
                          ) : (
                            <a
                              className="mrk__link"
                              href="https://sellercentral.amazon.co.uk/apps/authorize/consent?application_id=amzn1.sp.solution.d1a57a0a-e0d5-404f-b399-9598caa8841b&state=eu"
                              target="_blank"
                            >
                              {translate("Connect")}
                            </a>
                          )}
                        </div>
                      </div>
                      <div className="mrk__itm">
                        <div
                          className={`mrk__inf ${
                            user?.has_seller_account_fe && " active"
                          }`}
                        >
                          {user?.has_seller_account_fe ? (
                            <img src="/assets/img/asia.png"></img>
                          ) : (
                            <img src="/assets/img/asiad.png"></img>
                          )}
                          <div className="mark_name">
                            <span>{translate("Far East")}</span>
                            {sellerIds?.find((i) => i.server === "fe") &&
                              sellerInfo(
                                sellerIds?.find((i) => i.server === "fe")
                              )}
                          </div>
                        </div>
                        <div className="mrk__actions">
                          {user?.has_seller_account_fe ? (
                            <div className="mrk__disconnect_actions">
                              <div className="mrk__connected">
                                <FeatherIcon icon={"check-circle"} />{" "}
                                {translate("Connected")}
                              </div>
                              <div
                                className="mrk__disconnect"
                                onClick={() => removeMarket("fe")}
                              >
                                <FeatherIcon icon={"trash-2"} />{" "}
                                {translate("Disconnect")}
                              </div>
                            </div>
                          ) : (
                            <a
                              className="mrk__link"
                              href="https://sellercentral-japan.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.d1a57a0a-e0d5-404f-b399-9598caa8841b&state=fe"
                              target="_blank"
                            >
                              {translate("Connect")}
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="new__dashh__right">
                  <div className="card__kpp">
                    <div className="card__kpp_header">
                      <div className="ll__hdr">
                        <h4>{translate("Plan details")}</h4>
                        <span>
                          {translate("Manage your plan and payments.")}
                        </span>
                      </div>
                      <div className="rr__hdr">
                        <NavLink className="up_btn token_bt" to="/my-plan">
                          <span>{translate("Manage plan")}</span>
                          <FeatherIcon icon={"settings"} />
                        </NavLink>
                      </div>
                    </div>
                    <div className="card__kpp_body gg vv">
                      <div className="plan__ihm">
                        <img src="/assets/img/verify.png"></img>
                        <h5>{translate("Current plan")}</h5>
                        <div className="inf__ihm">
                          {user?.user_tier?.capitalize()}
                        </div>
                      </div>
                      <div className="plan__ihm">
                        <img src="/assets/img/credit-card.png"></img>
                        <h5>{translate("Payment")}</h5>
                        <div className="inf__ihm">
                          <span className="t_i">
                            $
                            {user?.tier_price
                              ? parseInt(user?.tier_price) / 100
                              : 0}
                          </span>
                          <span className="t_b">{translate("per month")}</span>
                        </div>
                      </div>
                      <div className="plan__ihm">
                        <img src="/assets/img/check-mark.png"></img>
                        <h5>{translate("Renew at")}</h5>
                        <div className="inf__ihm">
                          {user?.next_payment_due
                            ? new Date(
                                user?.next_payment_due
                              ).toLocaleDateString()
                            : "..."}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card__kpp">
                    <div className="card__kpp_header">
                      <div className="ll__hdr">
                        <h4>{translate("Action center")}</h4>
                        <span>
                          {translate("You can see system notifications here.")}
                        </span>
                      </div>
                      <div className="rr__hdr">
                        <NavLink className="up_btn token_bt" to="/how-it-works">
                          <span>{translate("How It Works")}</span>
                          <FeatherIcon icon={"arrow-up-right"} />
                        </NavLink>
                      </div>
                    </div>
                    <div className="card__kpp_body ssa">
                      {!shouldHideDiv && (
                        <div className="err__itm itmm__a">
                          <div className="title__thk">
                            {translate("Your marketplace is not connected!")}
                          </div>
                          <a
                            href="https://www.youtube.com/watch?v=DObvOu2-98Q&list=PLxi7_IEBzcmj2eio4ZJTsAID6AD08s3t8&index=8"
                            target="_blank"
                            className="desc__thk"
                          >
                            {translate("How can I connect my marketplace?")}
                          </a>
                        </div>
                      )}
                      {user?.token_count <= 200 ? (
                        <div className="wrn__itmxx itmm__a">
                          <div className="title__thk">
                            {translate(
                              "You're running out of comparison tokens!"
                            )}
                          </div>

                          {user?.tier_id && user?.tier_id > 1 ? (
                            <a
                              href="#!"
                              role="button"
                              onClick={() => setBuyTokenDrawer(true)}
                              className="desc__thk"
                            >
                              {translate("Buy comparison tokens.")}
                            </a>
                          ) : (
                            <a
                              href="#!"
                              role="button"
                              onClick={() =>
                                toast.error(
                                  translate(
                                    "If you want to buy extra comparison tokens, you need to upgrade your package!"
                                  )
                                )
                              }
                              className="desc__thk"
                            >
                              {translate("Buy comparison tokens.")}
                            </a>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}

                      {user?.asin_count <= 200 && user?.tier_id <= 1 ? (
                        <div className="wrn__itmxx itmm__a">
                          <div className="title__thk">
                            {translate(
                              "You're running out of Product Based Info limit!"
                            )}
                          </div>

                          <NavLink to="/pricing" className="desc__thk">
                            {translate("You can upgrade your package.")}
                          </NavLink>
                        </div>
                      ) : (
                        <></>
                      )}

                      {shouldHideDiv &&
                      user?.asin_count > 200 &&
                      user?.token_count > 200 ? (
                        <div className="actionss__empty">
                          <div>
                            <img src="/assets/img/confetti.png"></img>
                            <h3>
                              {translate("Congratulation")} {user?.first_name}!
                            </h3>
                            <p>
                              {translate(
                                "Your action center is empty, so everything is fine!"
                              )}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="globe-wrapper immg">
                <img
                  src="/assets/img/world3.png"
                  className="earth"
                  alt="World"
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardClient;
