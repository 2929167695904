import React, { useState } from "react";
import { Table, Tag, Button } from "antd";
import translate from "../../../shared/utils/translations";
import FeatherIcon from "feather-icons-react";

const TiersTable = ({ setSelectedTier, setDrawer, data }) => {
  const columns = [
    {
      title: translate("Action"),
      key: "action",
      width: "120px",
      className: "table_actin",
      render: (text, record) => (
        <>
          <div className="column_info">{translate("Details")}</div>
          <div className="d_flex_center">
            <Button
              onClick={() => {
                setSelectedTier(record);
                setDrawer(true);
              }}
              icon={<FeatherIcon icon={"edit"} />}
              className={"table_icon"}
            />
          </div>
        </>
      ),
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (key) => key,
    },
    {
      title: "type",
      dataIndex: "type",
      key: "type",
      render: (key) => key,
    },
    {
      title: "tier_name",
      dataIndex: "tier_name",
      key: "tier_name",
      render: (item) => (
        <>
          <div className="column_info">tier_name</div>
          {item}
        </>
      ),
    },
    {
      title: "tier_description",
      dataIndex: "tier_description",
      key: "tier_description",
      render: (item) => (
        <>
          <div className="column_info">tier_description</div>
          {item?.length > 20 ? item.slice(0, 20) + "..." : item}
        </>
      ),
    },
    {
      title: "tier_price",
      dataIndex: "tier_price",
      key: "tier_price",
      render: (item) => (
        <>
          <div className="column_info">tier_price</div>
          {item}
        </>
      ),
    },
    {
      title: "asin_token_to_add",
      dataIndex: "asin_token_to_add",
      key: "asin_token_to_add",
      render: (item) => (
        <>
          <div className="column_info">asin_token_to_add</div>
          {item}
        </>
      ),
    },
    {
      title: "comparison_token_to_add",
      dataIndex: "comparison_token_to_add",
      key: "comparison_token_to_add",
      render: (item) => (
        <>
          <div className="column_info">"comparison_token_to_add"</div>
          {item}
        </>
      ),
    },

    {
      title: "tier_image",
      dataIndex: "tier_image",
      key: "tier_image",
      render: (item) => (
        <>
          <div className="column_info">tier_image</div>
          {item}
        </>
      ),
    },
    {
      title: "price_id",
      dataIndex: "price_id",
      key: "price_id",
      render: (item) => (
        <>
          <div className="column_info">price_id</div>
          {item}
        </>
      ),
    },
    {
      title: "product_id",
      dataIndex: "product_id",
      key: "product_id",
      render: (item) => (
        <>
          <div className="column_info">product_id</div>
          {item}
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          locale: {
            items_per_page: translate("Page"),
          },
          position: ["top", "bottom"],
        }}
        className="card_m"
      />
    </>
  );
};

export default TiersTable;
