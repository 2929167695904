import React from "react";

const Textarea = ({ label, name, placeholder, customClass, onChange, value }) => {
  return (
    <>
      <div className={`field ${customClass && customClass}`}>
        <div className="field__label">{label}</div>
        <div className="field__wrap">
          <textarea
            className="field__textarea"
            name={name}
            value={value}
            onChange={(e) => {
              console.log(e.target.value, name);
              onChange(e.target.value, name);
            }}
            placeholder={placeholder}
            required=""
          ></textarea>
        </div>
      </div>
    </>
  );
};

export default Textarea;
