import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { requestHandler } from "../utils/helpers";
import translate from "../utils/translations";
import { Helmet } from "react-helmet";

const Notifications = ({ setPage }) => {
  const [notifications, setNotifications] = useState([]);
  useEffect(() => {
    setPage("landing");
    currentLanguage(localStorage.getItem("lang") || 'EN');
  });
  const [currentLang, currentLanguage] = useState({});

  const getNotifications = async () => {
    const data = await requestHandler("get", "/notifications", null, true, null, null).then((res) => res?.data);
    console.log("notifications", data);
    setNotifications(data);
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const notifyMark = () => {
    requestHandler("put", "/notifications", null, true, null, null).then((res) => {
      console.log("notifications", res);
      toast.success(translate("Notifications are marked as read!.."));

      getNotifications();
    });
  };

  const notifyClear = () => {
    requestHandler("delete", "/notifications", null, true, null, null).then((res) => {
      toast.success(translate("Notifications have been cleared successfully!.."));

      console.log("notifications", res);
      setNotifications([]);
    });
  };

  return (
    <>
    <Helmet>
      <title>{translate("Notifications")}</title>
    </Helmet>
      <div className={`outer__inner notif__Y ${currentLang}`}>
        <div className="notifications">
          <div className="notifications__center center">
            <div className="notifications__body">
              <div className="notifications__top">
                <h4 className="notifications__title h4">{translate("Notifications")}</h4>
                <button className="button-stroke button-small notifications__button" onClick={notifyMark}>
                {translate("Mark all as read")}
                </button>
                <button className="button-stroke button-small notifications__button" onClick={notifyClear}>
                {translate("Clear all")}
                </button>
              </div>
              <div className="notifications__row">
                <div className="notifications__wrapper">
                  <div className="notifications__list custom">
                    {/* Okunmayan - yeni bildirim - active class ı var */}

                    {notifications?.length > 0 ?
                    <>
                    {notifications?.map((notification, index) => {
                      return (
                        <div className={`notifications__item ${!notification?.is_read && "active"}`} key={index}>
                          <div className="notifications__details">
                            <div className="notifications__line">
                              <div className="notifications__subtitle">{notification?.title}</div>
                              <div className="notifications__subtitle">{notification?.message}</div>

                              <div className="notifications__date">
                                {notification?.created_at?.replace("T", " - ")?.split(".")[0]}
                              </div>
                              <div className="notifications__status"></div>
                            </div>
                          </div>
                        </div>
                      );
                    })}</>
                    :
                    <div className="empty_box_div big__Area"><img src="/assets/img/empty-box.png"></img></div>
                  }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notifications;
