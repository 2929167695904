import React, { useEffect, useState, useRef } from "react";
import BottomButtons from "./_bottom_buttons";
import ToggleSwitch from "../../common/form_elements/toggle_switch";
import translate from "../utils/translations";
import { Transfer, Tooltip, Button } from "antd";
import styled from "styled-components";
import { MenuOutlined } from "@ant-design/icons";
import { DndProvider, useDrop, useDrag } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import FeatherIcon from "feather-icons-react";
import Swal from "sweetalert2";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { makeReadable, requestHandler } from "../utils/helpers";
import SheetSettings from "./google_sheets/sheet_settings/sheet_settings";
import MySheets from "./google_sheets/my_sheets/my_sheets";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";

const ItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  > .label {
    display: inline-block;
    max-width: calc(100% - 20px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &.drop-over-downward {
    border-bottom: 2px dashed #1890ff;
  }
  &.drop-over-upward {
    border-top: 2px dashed #1890ff;
  }
`;

const Integrations = ({ setPage, isPage }) => {
  useEffect(() => {
    if (isPage === "dashboard-admin") {
      setPage("dashboard-admin");
      localStorage.setItem("dashboard-type", "dashboard-admin");
    } else {
      setPage("dashboard-client");
      localStorage.setItem("dashboard-type", "dashboard-client");
    }
  });

  useEffect(() => {
    currentLanguage(localStorage.getItem("lang") || "EN");
  });
  const [currentLang, currentLanguage] = useState({});

  const productBaseDefaultColumns = [
    "image",
    "asin",
    "product_link",
    "title",
    "has_discount",
    "base_domain_price",
    "base_domain_converted_price",
    "target_domain_price",
    "shipping_cost",
    "vat",
    "fee",
    "roi",
    "is_hazmat",
    "profit_per_item",
    "is_amazon_seller",
    "total_seller_count",
    "fba_seller_count",
    "fbm_seller_count",
    "sales_rank",
    "sales_rank_category",
    "brand",
    "binding",
    "product_group",
    "product_type_name",
    "bsr_current",
    "bsr_30_days_avg",
    "bsr_90_days_avg",
    "bsr_180_days_avg",
    "bsr_365_days_avg",
    "bsr_drops_30_days_avg",
    "bsr_drops_90_days_avg",
    "bsr_drops_180_days_avg",
    "bsr_drops_365_days_avg",
    "ean",
    "upc",
    "variation_count",
    "price_amazon_0",
    "price_new_0",
    "price_amazon_30",
    "price_new_30",
    "price_amazon_90",
    "price_new_90",
    "price_amazon_180",
    "price_new_180",
    "price_amazon_365",
    "price_new_365",
    "color",
    "item_dimensions",
    "package_dimensions",
  ];
  const comparisonDefaultColumns = [
    "asin",
    "title",
    "has_discount",
    "base_domain_price",
    "base_domain_converted_price",
    "target_domain_price",
    "shipping_cost",
    "vat",
    "fee",
    "roi",
    "is_hazmat",
    "profit_per_item",
    "is_amazon_seller",
    "total_seller_count",
    "fba_seller_count",
    "fbm_seller_count",
    "sales_rank",
    "sales_rank_category",
    "brand",
    "binding",
    "product_group",
    "product_type_name",
  ];
  const [defaultValues, setDefaultValues] = useState(
    productBaseDefaultColumns.map((i, index) => {
      return {
        key: index,
        title: makeReadable(i),
      };
    })
  );
  const [targetKeys, setTargetKeys] = useState([]);
  const [user, setUser] = useState({});
  const [columnName, setColumnName] = useState("");
  const [isComparison, setIsComparison] = useState(false);
  const [sheetPreferences, setSheetsPreferences] = useState([]);
  const [sheets, setSheets] = useState([]);
  const [sheetName, setSheetName] = useState("");
  const [customColumnLabel, setCustomColumnLabel] = useState("");
  const [currentSheet, setCurrentSheet] = useState({});

  const [telegram_chat_id, setTelegram_chat_id] = useState("");

  const getPreferences = async () => {
    requestHandler("get", "/profile", null, true, null, null).then((res) => {
      console.log("preferences", res?.data?.preferences, "user", res?.data);
      setUser(res?.data);
    });
  };
  const getSheetPreferencesWithColumns = async () => {
    requestHandler("get", "/sheet", null, true, null, null).then((res) => {
      console.log("sheet preferences", res?.data);
      setSheetsPreferences(res?.data?.preferences);
      setCurrentSheet(res?.data?.googleSheets[0]);
      setSheets(res?.data?.googleSheets);

      console.log("currentSheet", res?.data?.googleSheets[0]);
    });
  };
  const deleteSheet = async (sheetId) => {
    Swal.fire({
      icon: "warning",
      title: translate("Delete?"),
      text: translate("Are you sure you want to delete this?"),
      confirmButtonText: translate("Delete"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        requestHandler(
          "delete",
          `/sheet/${sheetId}`,
          null,
          true,
          null,
          null
        ).then((res) => {
          console.log("sheet preferences", res?.data);
          getSheetPreferencesWithColumns();
        });
      }
    });
  };

  const saveSheetPreferences = async (
    sheetName,
    is_comparison,
    columnsData
  ) => {
    if (!sheetName) return toast.error("Please enter a name for the setting.");
    if (columnsData.length === 0)
      return toast.error("Please select at least one column.");
    requestHandler(
      "post",
      "/sheet/preferences",
      {
        sheetName,
        is_comparison,
        columnsData,
      },
      true,
      null,
      null
    ).then((res) => {
      console.log("sheet preferences", res?.data);
      getSheetPreferencesWithColumns();
    });
  };

  const setDefaultSheetPreference = async (sheetPreferenceId) => {
    requestHandler(
      "put",
      `/sheet/preferences/${sheetPreferenceId}`,
      {
        is_comparison: isComparison,
      },
      true,
      null,
      null
    ).then((res) => {
      console.log("sheet preferences", res?.data);
      getSheetPreferencesWithColumns();
    });
  };

  const deleteSheetPreference = async (sheetPreferenceId) => {
    requestHandler(
      "delete",
      `/sheet/preferences/${sheetPreferenceId}`,
      null,
      true,
      null,
      null
    ).then((res) => {
      console.log("sheet preferences", res?.data);
      getSheetPreferencesWithColumns();
    });
  };
  const setSelectedSheet = (id) => {
    requestHandler(
      "put",
      `/sheet/selectDefaultSheet/${id}`,
      null,
      true,
      null,
      null
    ).then((res) => {
      console.log("sheet preferences", res?.data);
      getSheetPreferencesWithColumns();
    });
  };
  const createNewSheet = async () => {
    if (!sheetName) return toast.error("Please enter a name for the sheet.");
    requestHandler(
      "post",
      "/sheet/newSheet",
      {
        sheetName,
      },
      true,
      null,
      null
    ).then((res) => {
      console.log("sheet preferences", res?.data);
      getSheetPreferencesWithColumns();
    });
  };

  const handleGoogleAuth = async (tryCount = 0) => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state");
    console.log("code", code);
    console.log("state", state);

    if (code && state) {
      const token = localStorage.getItem("idToken");
      axios
        .post(
          `/auth/google`,
          {
            code,
            user_id: state,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log("market connection response", res);
          if (res?.data?.message) {
            toast.error(res?.data?.message, "error");
          } else {
            toast.success(translate("Google Sheet Connection Successful!"));
            getPreferences();
          }
        })
        .catch((err) => {
          console.log("google connection error", err);

          if (tryCount < 3) {
            console.log("tryCount", tryCount);
            handleGoogleAuth(tryCount + 1);
          } else {
            toast.error(translate("Google Sheet Not Connected!"));
          }
        });
    }
  };

  useEffect(() => {
    getPreferences();
    handleGoogleAuth();
    getSheetPreferencesWithColumns();
  }, []);

  const moveRow = async (dragIndex, hoverIndex) => {
    const clonedList = targetKeys;
    const el = clonedList.splice(dragIndex, 1)[0];
    clonedList.splice(hoverIndex, 0, el);
    onChange(clonedList);
  };
  const onChange = (nextTargetKeys) => {
    setTargetKeys(Array.from(new Set(nextTargetKeys)));
  };

  const filterOption = (inputValue, option) =>
    option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
  const handleChange = (newTargetKeys) => {
    setTargetKeys(newTargetKeys);
  };
  const handleSearch = (dir, value) => {
    console.log("search:", dir, value);
  };

  const removeGoogle = async (server) => {
    Swal.fire({
      icon: "warning",
      title: translate("Disconnect"),
      text: translate("Are you sure want to disconnect?"),
      confirmButtonText: translate("Disconnect"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: translate("Please wait.."),
          html: translate("Disconnection process started."),
          timer: 2000,
          timerProgressBar: true,
          didOpen: async () => {
            requestHandler("delete", "/auth/google", null, true, null, null);

            Swal.showLoading();
          },
          willClose: () => {
            toast.success(translate("Removing successful!"));
            Swal.fire(translate("Disconnect Successful!"), "", "success");
            setTimeout(() => {
              window.location.reload(false);
            }, 1000);
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            console.log("I was closed by the timer");
          }
        });
      } else if (result.isDenied) {
      }
    });
  };

  let connectedStatus = false;

  const deleteSetting = async (id) => {
    Swal.fire({
      icon: "warning",
      title: translate("Delete?"),
      text: translate("Are you sure you want to delete this?"),
      confirmButtonText: translate("Delete"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        deleteSheetPreference(id);
        toast.success(translate("Successfully deleted!.."));
      }
    });
  };

  const [isProf, setProf] = useState(false);
  const [isTelegramDetails, setTelegramDetails] = useState(false);

  const updateTelegramChatId = async () => {
    if (!telegram_chat_id)
      return toast.error(translate("Please enter Chat ID!"));
    requestHandler(
      "post",
      "/auth/telegram",
      { code: telegram_chat_id },
      true,
      null,
      null
    ).then((res) => {
      console.log("telegram chat id", res?.data);
      toast.success("Telegram chat id updated successfully");
    });
  };

  return (
    <>
      <Helmet>
        <title>{translate("3rd-party Integrations")}</title>
      </Helmet>
      <div className="outer__inner">
        <div className="onex_wl bg_white">
          <div className="onex_wl__wrapper">
            <div className="upload__head">
              <div className="dashbordi__nav mm">
                <NavLink className="dashbordi__link" to="/personal-settings">
                  {translate("Personal")}
                </NavLink>
                <NavLink className="dashbordi__link" to="/security-settings">
                  {translate("Security")}
                </NavLink>
                <NavLink className="dashbordi__link" to="/marketplace-settings">
                  {translate("Marketplace")}
                </NavLink>
                <NavLink className="dashbordi__link" to="/integration-settings">
                  {translate("Integrations")}
                </NavLink>
              </div>
              <h2 className="upload__title h2">
                {translate("3rd-party Integrations")}
              </h2>
            </div>
            <div className="keys__info">
              {translate(
                "You can manage your 3rd-party Integration settings here."
              )}
            </div>
            <div className="onex_wl__list">
              <div className="keys__enabled d_block">
                <div className="keys__list">
                  <div className="keys__item" style={{ width: "100%" }}>
                    <div className="setting__list">
                      <div className={`setting__box tt ${isProf && " ggb"}`}>
                        <div className="setting__stage iconic__ttle tt">
                          <img src="/assets/img/google-sheets.svg"></img>Google
                          Sheets
                          <div className="int__statss">
                            {user?.google_sheet_enabled ? (
                              <div className="mrk__disconnect_actions">
                                <div className="mrk__connected">
                                  <FeatherIcon icon={"check-circle"} />{" "}
                                  {translate("Connected")}
                                </div>
                                <div
                                  className="mrk__disconnect"
                                  onClick={() => removeGoogle()}
                                >
                                  <FeatherIcon icon={"trash-2"} />{" "}
                                  {translate("Disconnect")}
                                </div>
                              </div>
                            ) : (
                              <a
                                className="mrk__link"
                                href={`https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=421483275921-b60vots43jomupd0vqkfvoee67v75tt4.apps.googleusercontent.com&redirect_uri=https://kepicker.com/integration-settings&scope=https://www.googleapis.com/auth/spreadsheets&access_type=offline&state=${user?.id}&prompt=consent`}
                              >
                                {translate("Connect")}
                              </a>
                            )}
                          </div>
                        </div>

                        <button
                          onClick={() => setProf(!isProf)}
                          className={`view_det tier_1 shtt ${
                            isProf && " active"
                          }`}
                        >
                          {isProf
                            ? translate("Hide Professional Settings")
                            : translate("Show Professional Settings")}{" "}
                          <FeatherIcon icon={"chevron-right"} />
                        </button>

                        {/* NEW UI */}
                        <div
                          className={`shett_sstings_Area ${
                            !isProf && " hidden"
                          }`}
                        >
                          <SheetSettings
                            sheets={sheetPreferences}
                            deleteSetting={deleteSetting}
                            saveSheetPreferences={saveSheetPreferences}
                            setDefaultSheetPreference={
                              setDefaultSheetPreference
                            }
                          />
                          <MySheets
                            sheets={sheets}
                            setSelectedSheet={setSelectedSheet}
                            createNewSheet={createNewSheet}
                            sheetName={sheetName}
                            setSheetName={setSheetName}
                            deleteSheet={deleteSheet}
                          />
                        </div>

                        {/* Sheet Tabs */}
                        {/* <SheetTabs
                          isComparison={isComparison}
                          setIsComparison={setIsComparison}
                          setTargetKeys={setTargetKeys}
                          setDefaultValues={setDefaultValues}
                          productBaseDefaultColumns={productBaseDefaultColumns}
                          comparisonDefaultColumns={comparisonDefaultColumns}
                          makeReadable={makeReadable}
                          translate={translate}
                        /> */}
                      </div>

                      <div className="setting__box tt">
                        <div className="setting__stage iconic__ttle tt ggh">
                          <img src="/assets/img/telegram.svg"></img>Telegram
                          <div className="int__statss">
                            <span className="cmg_soon">
                              {translate("Beta")}
                            </span>
                          </div>
                        </div>
                        <p className="integr__p_info">
                          {translate(
                            "Without entering the web dashboard or using the extension, simply send the ASIN information of a product to the Kepicker Telegram bot and receive the Product Based Info informations for that product as a message."
                          )}
                        </p>
                        <button
                          onClick={() => setTelegramDetails(!isTelegramDetails)}
                          className={`view_det tier_1 shtt ${
                            isTelegramDetails && " active"
                          }`}
                        >
                          {isTelegramDetails
                            ? translate("Hide Telegram Settings")
                            : translate("Show Telegram Settings")}{" "}
                          <FeatherIcon icon={"chevron-right"} />
                        </button>

                        <div
                          className={`telegram__how__area ${currentLang} ${
                            isTelegramDetails && " active"
                          }`}
                        >
                          <div className="inf__tlgramms">
                            <h4>{translate("How can I use it?")}</h4>
                            <div className="tr_area">
                              <p>
                                <div className="dot__innf"></div>Telegram
                                uygulamasını açın.
                              </p>
                              <p>
                                <div className="dot__innf"></div>Sohbet
                                aramasına <strong>@Kepickerbot</strong> yazarak
                                Kepicker botunu bulunuz.
                              </p>
                              <p>
                                <div className="dot__innf"></div>@Kepicker bota
                                tıklayın ve <strong>Sohbeti Başlat</strong>{" "}
                                diyin.
                              </p>
                              <p>
                                <div className="dot__innf"></div>Sohbet kısmına{" "}
                                <strong>info</strong> yazarak gönderin.
                              </p>
                              <p>
                                <div className="dot__innf"></div>Gelen mesajda{" "}
                                <strong>Chat ID:</strong> sonrasında yazan chat
                                ID'nizi kopyalayın.
                              </p>
                              <p>
                                <div className="dot__innf"></div>Bu ID bilgisini
                                aşağıda yer alan{" "}
                                <strong>Telegram Chat ID</strong> alanına
                                yapıştırın ve <strong>Save</strong> diyin.
                              </p>
                            </div>
                            <div className="en_area">
                              <p>
                                <div className="dot__innf"></div>Open the
                                Telegram app.
                              </p>
                              <p>
                                <div className="dot__innf"></div>Find the
                                Kepicker bot by typing{" "}
                                <strong>@Kepickerbot</strong> into the search
                                bar.
                              </p>
                              <p>
                                <div className="dot__innf"></div>Click on
                                @Kepickerbot and say <strong>Start Chat</strong>
                                .
                              </p>
                              <p>
                                <div className="dot__innf"></div>Type{" "}
                                <strong>info</strong> into the chat and send it.
                              </p>
                              <p>
                                <div className="dot__innf"></div>Copy your chat
                                ID that appears after <strong>Chat ID:</strong>.
                              </p>
                              <p>
                                <div className="dot__innf"></div>Paste this ID
                                into the <strong>Telegram Chat ID</strong> field
                                below and click <strong>Save</strong>.
                              </p>
                            </div>
                            <div className="de_area">
                              <p>
                                <div className="dot__innf"></div>Öffnen Sie die
                                Telegram-App.
                              </p>
                              <p>
                                <div className="dot__innf"></div>Geben Sie{" "}
                                <strong>@Kepickerbot</strong> in die Suchleiste
                                ein, um den Kepicker-Bot zu finden.
                              </p>
                              <p>
                                <div className="dot__innf"></div>Klicken Sie auf
                                @Kepickerbot und sagen Sie{" "}
                                <strong>Chat starten</strong>.
                              </p>
                              <p>
                                <div className="dot__innf"></div>Geben Sie{" "}
                                <strong>info</strong> ins Chat-Fenster ein und
                                senden Sie es.
                              </p>
                              <p>
                                <div className="dot__innf"></div>Kopieren Sie
                                Ihre Chat-ID, die nach <strong>Chat ID:</strong>{" "}
                                erscheint.
                              </p>
                              <p>
                                <div className="dot__innf"></div>Fügen Sie diese
                                ID in das Feld <strong>Telegram Chat ID</strong>{" "}
                                unten ein und klicken Sie auf{" "}
                                <strong>Speichern</strong>.
                              </p>
                            </div>
                            <div className="new_ampt_colmn tt gg">
                              <input
                                type="text"
                                className="field__input"
                                placeholder="Telegram Chat ID"
                                value={telegram_chat_id}
                                onChange={(e) =>
                                  setTelegram_chat_id(e.target.value)
                                }
                              ></input>
                              <Tooltip
                                title={translate("Save")}
                                placement={"right"}
                              >
                                <button
                                  onClick={() => {
                                    updateTelegramChatId();
                                  }}
                                  className="button-stroke button-small activity__button js-activity-button cc link"
                                >
                                  <FeatherIcon icon={"save"} />
                                </button>
                              </Tooltip>
                            </div>
                            <div className="tr_area">
                              <p>
                                <div className="dot__innf"></div>Tekrar Telegram
                                uygulamasına dönün, eğer{" "}
                                <strong>
                                  Uygulama entegrasyonu başarılı..
                                </strong>{" "}
                                mesajı geldiyse artık kullanıma hazırsınız
                                demektir. Böyle bir mesaj gelmedi ise chat
                                ID'nizi yanlış yazmış olabilirsiniz, tekrar
                                deneyin..
                              </p>
                              <p>
                                <div className="dot__innf"></div>Başarılı olması
                                durumunda{" "}
                                <strong>
                                  herhangi bir ürünün ASIN bilgisini mesaj
                                  olarak gönderirseniz
                                </strong>
                                ; sistem size bu ASIN bilgisine dayalı detaylı{" "}
                                <strong>Ürün Botu bilgilerini</strong> bir süre
                                sonra mesaj olarak gönderecektir.{" "}
                              </p>
                            </div>
                            <div className="en_area">
                              <p>
                                <div className="dot__innf"></div>Return to the
                                Telegram app. If you have received the message{" "}
                                <strong>App integration successful..</strong>,
                                then it is ready for use. If you did not receive
                                such a message, you may have entered your chat
                                ID incorrectly, please try again.
                              </p>
                              <p>
                                <div className="dot__innf"></div>If successful,
                                if you send{" "}
                                <strong>
                                  the ASIN information of any product as a
                                  message
                                </strong>
                                , the system will later send you detailed{" "}
                                <strong>Product Based Info informations</strong>{" "}
                                based on this ASIN information.
                              </p>
                            </div>
                            <div className="de_area">
                              <p>
                                <div className="dot__innf"></div>Kehren Sie zur
                                Telegram-App zurück. Wenn Sie die Nachricht{" "}
                                <strong>App-Integration erfolgreich..</strong>{" "}
                                erhalten haben, ist es nun bereit zur Nutzung.
                                Falls Sie solch eine Nachricht nicht erhalten
                                haben, könnten Sie Ihre Chat-ID falsch
                                eingegeben haben, bitte versuchen Sie es erneut.
                              </p>
                              <p>
                                <div className="dot__innf"></div>Bei Erfolg,
                                wenn Sie{" "}
                                <strong>
                                  die ASIN-Information eines beliebigen Produkts
                                  als Nachricht senden
                                </strong>
                                , wird das System Ihnen nach einer Weile
                                detaillierte{" "}
                                <strong>Produkt-Bot-Informationen</strong>{" "}
                                basierend auf dieser ASIN-Information senden.
                              </p>
                            </div>
                            <p>
                              <div className={`custom__ship__inf wrrn ${" "}`}>
                                <FeatherIcon icon={"alert-circle"} />
                                <div className="cntt_body">
                                  {translate(
                                    "Your currently active marketplace setting in your account is used. Every ASIN message drops a Product Based Info token!"
                                  )}
                                </div>
                              </div>
                            </p>
                          </div>
                          <div className="exmpp_immg">
                            <img src="/assets/img/telegram_example.jpg"></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const type = "DraggableItem";

const DraggableItem = ({ index, label, moveRow }) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName:
          dragIndex < index ? ` drop-over-downward` : ` drop-over-upward`,
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  preview(drop(ref));

  return (
    <ItemWrapper
      key={label}
      ref={ref}
      className={`${isOver ? dropClassName : ""}`}
    >
      <span className="label">{label}</span>
      {index !== -1 && (
        <span ref={drag}>
          <MenuOutlined />
        </span>
      )}
    </ItemWrapper>
  );
};

export default Integrations;
