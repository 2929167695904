import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

const BlogDetails = ({ setPage }) => {
  useEffect(() => {
    setPage("landing");
  });
  return (
    <div className="outer__inner landng">
      <div className="section article">
        <div className="section article__body">
          <div className="article__center center">
            <div className="article__head">
              <h1 className="article__title h1">
                Proin consequat elit ut est sollicitudin pulvinar
              </h1>
              <div className="breadcrumbs">
                <div className="breadcrumbs__item">
                  <NavLink className="breadcrumbs__link" to="/blog">
                    Blog
                  </NavLink>
                </div>
                <div className="breadcrumbs__item">Proin consequat elit...</div>
              </div>
            </div>
            <div className="player">
              <div className="player__preview">
                <img src="/assets/img/content/main-pic-2.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="article__wrap">
          <div className="article__center center">
            <div className="content">
              <h3>Introduction</h3>
              <p>
                Ut malesuada pharetra vulputate. Sed rutrum rutrum gravida.
                Nullam egestas suscipit erat sed euismod. Vestibulum quis
                efficitur nisi. Morbi at augue id sem egestas posuere. Quisque
                justo massa, accumsan eget varius at, scelerisque quis mi. Sed
                at accumsan sem. Morbi sit amet eros libero. Sed in molestie
                nisl, a bibendum turpis. Integer ac porttitor lectus, vel rutrum
                orci. Vestibulum maximus ut nunc a porttitor.
              </p>
              <p>
                Aenean quis dolor porta, tincidunt augue et, aliquam neque.
                Morbi quis suscipit ipsum. Nulla aliquam imperdiet nulla a
                aliquam. Mauris semper ipsum magna, in hendrerit urna varius
                quis. Aliquam quis tellus nisl. Sed elementum, ante at congue
                bibendum, erat dui tincidunt justo, eu scelerisque neque magna
                sed urna. Nullam dictum lorem a dolor placerat porttitor. In
                vitae augue quam. Pellentesque commodo ultrices malesuada. Nam
                aliquam est eu laoreet dapibus. Sed eu ultrices nisl, ultricies
                tincidunt magna. Aliquam efficitur non urna a dictum. Donec in
                cursus arcu. Quisque ultricies placerat erat, non efficitur
                metus interdum vel. Curabitur lacus tortor, maximus eget
                ullamcorper vehicula, faucibus eu nunc.
              </p>
              <p>
                Proin consectetur, neque ac malesuada volutpat, sem nisl
                consequat nisi, id dignissim nunc purus eget libero. Donec porta
                commodo lorem, eget pretium sem suscipit quis. Mauris euismod
                nibh at placerat efficitur. Aenean commodo commodo consectetur.
                Donec ultricies ex lorem. Praesent sem magna, sagittis eu
                ultricies a, malesuada ornare ante. In maximus sapien turpis,
                eget scelerisque felis ultrices sed. Cras velit nisi, efficitur
                a turpis vel, hendrerit dictum ante. Vestibulum elementum mollis
                augue eu blandit. In nec pretium eros, vel tincidunt justo.
                Aenean congue eros eros, at tristique lorem facilisis id.
              </p>

              <h3>Duis sit amet efficitur erat?</h3>
              <p>
                Praesent dapibus magna mi, a congue odio commodo ac. Donec at
                pretium ipsum, ac semper mauris. Morbi in enim sapien. Proin
                massa purus, hendrerit sit amet mauris vitae, finibus sagittis
                massa. Curabitur felis turpis, facilisis at molestie eu, congue
                quis ipsum. Vestibulum in sapien mollis, scelerisque dui sit
                amet, congue nunc. In a consectetur enim.
              </p>
              <p>
                Quisque consectetur tincidunt metus quis fermentum. Nullam quis
                lacus turpis. Cras eu est odio. Cras a ligula a massa egestas
                maximus. Sed posuere ac velit in mattis. Mauris felis ante,
                molestie id accumsan vel, ultrices vitae odio. Curabitur
                volutpat, turpis quis fermentum efficitur, ligula magna
                efficitur sapien, eu pellentesque quam dui ut turpis. Nunc
                euismod justo nulla, a interdum ante malesuada at. Etiam
                fermentum orci magna, eu venenatis augue sollicitudin vel.
              </p>

              <figure>
                <img src="/assets/img/content/main-pic-1.jpg" alt="" />
              </figure>

              <h3>Phasellus id lacus sem?</h3>
              <p>
                Suspendisse et auctor tellus. Duis ultricies auctor ipsum id
                sodales. Nunc tempor at lectus et ultrices. Nullam id tortor at
                justo aliquam tempor. Nullam cursus tempor ex eu ultricies.
                Curabitur mattis ut dolor quis placerat. Duis molestie lectus
                sit amet pellentesque elementum.
              </p>
              <p>
                Pellentesque pharetra ante eget leo ornare, sed ultrices magna
                cursus. Nunc sit amet nisi bibendum, consequat massa ac, aliquet
                lorem. Orci varius natoque penatibus et magnis dis parturient
                montes, nascetur ridiculus mus. Fusce velit nisl, malesuada eu
                lacinia a, varius eu sem. Suspendisse feugiat purus in felis
                eleifend, nec commodo quam gravida. In at metus at leo vehicula
                accumsan. Interdum et malesuada fames ac ante ipsum primis in
                faucibus. Etiam at ullamcorper ipsum.
              </p>
              <h3>Understanding the TradingView UI</h3>
              <p>
                Sed faucibus, purus sit amet lacinia vestibulum, dui mauris
                viverra ex, at pretium sem odio in magna. Phasellus ornare sem a
                lacus rutrum ultrices non cursus odio. In at ex id sapien
                finibus porta. Cras euismod purus dui, ut mattis nibh vestibulum
                nec. Donec sagittis convallis posuere. Curabitur sed lacus in
                leo pulvinar cursus. Donec sit amet lacus euismod, egestas neque
                ultrices, luctus ante. Aenean luctus imperdiet semper. Sed vitae
                malesuada mi. Maecenas in lectus a tortor efficitur facilisis.
                Cras enim augue, maximus sit amet metus sit amet, volutpat
                faucibus lectus.
              </p>
              <p>
                Nam id ante tortor. Aliquam maximus risus et dolor ultricies,
                quis commodo lectus dapibus. Sed ac viverra elit. Mauris eget
                euismod mi, sit amet lacinia nunc.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
