import React, { useEffect, useLayoutEffect, useState } from "react";
import Input from "../../../common/form_elements/input";
import Select from "../../../common/form_elements/select";
import BottomButtons from "../_bottom_buttons";
import axios from "axios";
import { getMarketOptions, notify, requestHandler } from "../../utils/helpers";
import FeatherIcon from "feather-icons-react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import translate from "../../utils/translations";
import SettingForm from "./setting_form";
import { Button, Tooltip } from "antd";
import Swal from "sweetalert2";
import toast, { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet";
import CustomShippingSettingForm from "./custom_shipping_form";
import CustomShipping from "../../../../assets/img/custom_shipping.svg";

const Marketplace = ({ setPage, isPage }) => {
  const navigate = useNavigate();
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [formData, setFormData] = useState();
  const [preferences, setPreferences] = useState();
  const [selectedPreference, setSelectedPreference] = useState();
  const [user, setUser] = useState();
  const [customWeightMap, setCustomWeightMap] = useState([]);
  const [customWeightType, setCustomWeightType] = useState("kg");
  const [targetCurrency, setTargetCurrency] = useState("CAD");

  const getPreferences = async () => {
    requestHandler("get", "/preferences", null, true, navigate, "/home").then(
      (res) => {
        console.log(
          "preferences",
          res?.data?.preferences,
          "user",
          res?.data?.user
        );
        setPreferences(res?.data?.preferences);
        setUser(res?.data?.user);
      }
    );
  };
  const deletePreference = async (id) => {
    requestHandler(
      "delete",
      `/preferences/${id}`,
      null,
      true,
      navigate,
      "/home"
    ).then((res) => {
      console.log("preferences", res?.data?.preferences);
      getPreferences();
    });
  };

  useEffect(() => {
    if (isPage === "dashboard-admin") {
      setPage("dashboard-admin");
      localStorage.setItem("dashboard-type", "dashboard-admin");
    } else {
      setPage("dashboard-client");
      localStorage.setItem("dashboard-type", "dashboard-client");
    }
  });
  useEffect(() => {
    requestHandler("get", "/profile", null, true, navigate, "/login").then(
      (res) => {
        console.log("profile", res);
        setFormData(res?.data);
        getPreferences();
      }
    );
  }, []);
  const onChange = (value, name) => {
    setFormData({ ...formData, [name]: value });
  };
  const submit = async () => {
    setBtnLoading(true);
    requestHandler("put", "/profile", formData, true, navigate, "/login")
      .then((res) => {
        console.log("profile", res);
        setBtnLoading(false);
        setSettingForm(false);
        notify(translate("Settings have been saved successfully!.."));
      })
      .catch((err) => {
        console.log("err", err);
        setBtnLoading(false);
        setSettingForm(false);
        notify(translate("Something went wrong, please try again!"));
      });
  };
  const selectPreference = async (id) => {
    requestHandler(
      "put",
      `/preferences/select/${id}`,
      null,
      true,
      navigate,
      "/home"
    ).then((res) => {
      console.log("preferences", res?.data?.preferences);
      getPreferences();
    });
  };

  const [isSettingForm, setSettingForm] = useState(false);

  const deleteSetting = async (id) => {
    Swal.fire({
      icon: "warning",
      title: translate("Delete?"),
      text: translate("Are you sure you want to delete this?"),
      confirmButtonText: translate("Delete"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        deletePreference(id);
        toast.success(translate("Successfully deleted!.."));
      }
    });
  };
  const [isNewSettingBtn, setNewSettingBtn] = useState(false);
  const [isSettingEditable, setSettingEditable] = useState(false);
  useEffect(() => {
    const availableMarkets = getMarketOptions(user);
    if (user && availableMarkets?.length == 0) {
      notify(translate("Please connect your marketplaces first"), "error");
      setNewSettingBtn(true);
    }
  }, [user]);

  const [isShippingSettingForm, setShippingSettingForm] = useState(false);
  const [selectedShippingOption, setselectedShippingOption] =
    useState("default_shipping");
  const handleShippingRadioChange = (event) => {
    setselectedShippingOption(event.target.value);
    if (event.target.value === "custom_shipping") {
      setShippingSettingForm(true);
    }
  };

  const initialShippingInputs = new Array(40).fill("");
  const [shippingInputs, setShippingInputs] = useState(initialShippingInputs);

  const handleShippingInputChange = (value, index) => {
    const newInputs = [...shippingInputs];
    newInputs[index] = value;
    setShippingInputs(newInputs);
  };

  const handleSaveShippingInputs = () => {
    if (shippingInputs.some((input) => input === "")) {
      toast.error(translate("Please fill all fields!"));
    } else {
      toast.success(translate("Your action was successful!"));
      setShippingSettingForm(false);
      console.log("shippingInputs", shippingInputs);
    }
  };

  // değer girilmediyse ve drawer kapatılırsa tekrar default seç
  const checkCustomShippingForm = () => {
    if (shippingInputs.some((input) => input === "")) {
      setselectedShippingOption("default_shipping");
    }

    setShippingSettingForm(false);
  };

  return (
    <>
      <Helmet>
        <title>{translate("Marketplace settings")}</title>
      </Helmet>
      <SettingForm
        isSettingForm={isSettingForm}
        setSettingForm={setSettingForm}
        onChange={onChange}
        getMarketOptions={getMarketOptions}
        isBtnLoading={isBtnLoading}
        setBtnLoading={setBtnLoading}
        submit={submit}
        user={user}
        selectedPreference={selectedPreference}
        refresh={getPreferences}
        isSettingEditable={isSettingEditable}
        selectedShippingOption={selectedShippingOption}
        handleShippingRadioChange={handleShippingRadioChange}
        setShippingSettingForm={setShippingSettingForm}
        isShippingSettingForm={isShippingSettingForm}
        customWeightMap={customWeightMap}
        customWeightType={customWeightType}
        setTargetCurrency={setTargetCurrency}
        targetCurrency={targetCurrency}
        shippingInputs={shippingInputs}
      />

      <div className="outer__inner">
        <div className="onex_wl bg_white">
          <div className="onex_wl__wrapper">
            <div className="mrk__header">
              <div className="upload__head">
                <div className="dashbordi__nav mm">
                  <NavLink className="dashbordi__link" to="/personal-settings">
                    {translate("Personal")}
                  </NavLink>
                  <NavLink className="dashbordi__link" to="/security-settings">
                    {translate("Security")}
                  </NavLink>
                  <NavLink
                    className="dashbordi__link"
                    to="/marketplace-settings"
                  >
                    {translate("Marketplace")}
                  </NavLink>
                  <NavLink
                    className="dashbordi__link"
                    to="/integration-settings"
                  >
                    {translate("Integrations")}
                  </NavLink>
                </div>
                <h2 className="upload__title h2">
                  {translate("Marketplace settings")}
                </h2>
                <div className="keys__info">
                  {translate(
                    "Your adjustments here will also be applied to your extension."
                  )}
                </div>
              </div>

              <div
                className={`mark__status ${
                  user?.tier_id && user.tier_id >= 4 ? " tt" : ""
                }`}
              >
                <FeatherIcon icon={"shield-off"} />
                <div className="mrk__stts">
                  <h5>{translate("Protection Status")}</h5>
                  <span>
                    {user?.tier_id && user.tier_id >= 4
                      ? translate("Protection Active")
                      : translate("Protection Not Active")}
                  </span>

                  {user?.tier_id && user.tier_id >= 4 ? (
                    <></>
                  ) : (
                    <NavLink to="/pricing" className="up_btn token_bt cursorp">
                      <span>{translate("Upgrade to Pro Package")}</span>
                      <FeatherIcon icon={"arrow-up-right"} />
                    </NavLink>
                  )}
                </div>
              </div>
            </div>

            <div className="mrkt__linkss">
              <NavLink
                to="/client-dashboard"
                className="up_btn token_bt cursorp"
              >
                <span>{translate("Connect Marketplaces")}</span>
                <FeatherIcon icon={"arrow-up-right"} />
              </NavLink>
              <a
                href="https://www.youtube.com/watch?v=XtF0HOMMLBA"
                target="_blank"
                className="inff__text"
              >
                {translate("How do I configure the marketplace settings?")}{" "}
                <FeatherIcon icon={"help-circle"} />
              </a>
            </div>
            <div className="onex_wl__list">
              <div className="keys__enabled d_block">
                <div className="keys__list">
                  <div className="keys__item" style={{ maxWidth: "600px" }}>
                    <div className="setting__list">
                      <div className="setting__section">
                        <div className="setting__category cvvf">
                          {translate("Saved Settings")}

                          {isNewSettingBtn ? (
                            <button
                              onClick={() =>
                                notify(
                                  translate(
                                    "Please connect your marketplaces first"
                                  ),
                                  "error"
                                )
                              }
                              className="button-stroke button-small activity__button js-activity-button"
                            >
                              {translate("New Setting")}
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                setSelectedPreference({
                                  id: "new",
                                  user_id: "",
                                  name: translate("My new preference..."),
                                  base_domain: "www.amazon.com",
                                  target_domain: "www.amazon.ca",
                                  currency: "",
                                  shipping_type: "fixed",
                                  shipping_deal: "10",
                                  labeling_deal: "1.5",
                                  fixed_deal: "1.5",
                                  is_fba: true,
                                  uses_keepa: true,
                                  include_discounts: true,
                                  vat_type: "noVat",
                                  vat_percentage: "19",
                                });
                                setSettingForm(true);
                                setSettingEditable(false);
                              }}
                              className="button-stroke button-small activity__button js-activity-button"
                            >
                              {translate("New Setting")}
                            </button>
                          )}
                        </div>
                        <p className="custom__shipp_info">
                          <img src="/assets/img/custom-notification.svg"></img>{" "}
                          -{" "}
                          {translate(
                            "icon means “Custom Shipping Setting” is active."
                          )}
                        </p>
                        <div className="setting__fieldset">
                          <ul>
                            {preferences?.map((i) => {
                              return (
                                <li
                                  key={i?.id}
                                  className={`saved_setting_itm ${
                                    i.selected ? "active" : ""
                                  }`}
                                >
                                  <div
                                    onClick={() => {
                                      selectPreference(i.id);
                                    }}
                                    className="saved_setting_title cnn"
                                  >
                                    {i?.is_fba ? (
                                      <span className="is__fbam_tag ffba">
                                        FBA
                                        {i?.shipping_type ===
                                        "customWeightMap" ? (
                                          <img src={CustomShipping}></img>
                                        ) : (
                                          <></>
                                        )}
                                      </span>
                                    ) : (
                                      <span className="is__fbam_tag ffbm">
                                        FBM
                                        {i?.shipping_type ===
                                        "customWeightMap" ? (
                                          <img src={CustomShipping}></img>
                                        ) : (
                                          <></>
                                        )}
                                      </span>
                                    )}

                                    <span className="stt__ttile">
                                      {i?.name}
                                    </span>
                                  </div>
                                  <div className="saved_setting_actions">
                                    <div className="d_flex_center">
                                      {!i?.selected && (
                                        <Tooltip
                                          title={translate("Delete")}
                                          placement="top"
                                        >
                                          <Button
                                            onClick={() => deleteSetting(i?.id)}
                                            icon={
                                              <FeatherIcon icon={"trash"} />
                                            }
                                            className={"table_icon"}
                                          />
                                        </Tooltip>
                                      )}
                                      <Tooltip
                                        title={translate("Show")}
                                        placement="top"
                                      >
                                        <Button
                                          onClick={() => {
                                            setSettingForm(true);
                                            setSelectedPreference(i);
                                            setSettingEditable(true);
                                          }}
                                          icon={<FeatherIcon icon={"eye"} />}
                                          className={"table_icon"}
                                        />
                                      </Tooltip>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Marketplace;
