import React from "react";

const LoaderPBI = () => {
  return (
    <div className={`page-_loader pbi`}>
      <div className="pbi__bddy">
        <div className="bt-spinner"></div>
      </div>
      {/* <div className="kpc__loader"></div> */}
    </div>
  );
};
export default LoaderPBI;
