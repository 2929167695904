import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import BalanceTable from "./_balance_table";
import FeatherIcon from "feather-icons-react";
import { requestHandler } from "../../../shared/utils/helpers";
import translate from "../../../shared/utils/translations";

const Balance = ({ setPage }) => {
  useEffect(() => {
    setPage("dashboard-admin");
    localStorage.setItem("dashboard-type", "dashboard-admin");
  });

  let navigate = useNavigate();

  const [isItem, setItem] = useState("all");
  const [isInvoice, setInvoice] = useState(false);
  const [data, setData] = useState([]);
  const [summary, setSummary] = useState({});
  const [status, setStatus] = useState(null);
  const [query, setQuery] = useState("");
  const [buffer, setBuffer] = useState([]);

  const getBalanceData = async () => {
    const res = await requestHandler(
      "get",
      "/admin/payments",
      null,
      true,
      navigate,
      "/login"
    );
    console.log("res", res);
    if (res?.data) {
      setData(res?.data?.payments);
      setSummary(res?.data?.summary);
      setBuffer(res?.data?.payments);
    }
  };

  useEffect(() => {
    getBalanceData();
  }, []);

  useEffect(() => {
    if (isItem === "all") {
      setBuffer(data);
    } else {
      setBuffer(
        data.filter(
          (i) =>
            i.tier_name?.includes(isItem) || i.payment_type?.includes(isItem)
        )
      );
    }
  }, [isItem]);

  useEffect(() => {
    if (status === "all") {
      setBuffer(data);
    } else {
      setBuffer(data.filter((i) => i.status === status));
    }
  }, [status]);

  useEffect(() => {
    if (query === "") {
      setBuffer(data);
    } else {
      setBuffer(
        data.filter(
          (i) =>
            i.user_name.toLowerCase().includes(query.toLowerCase()) ||
            i.payment_intent_id.toLowerCase().includes(query.toLowerCase()) ||
            i.created_at.toLowerCase().includes(query.toLowerCase()) ||
            i.amount.toString().toLowerCase().includes(query.toLowerCase()) ||
            i.payment_type.toLowerCase().includes(query.toLowerCase()) ||
            i.status.toLowerCase().includes(query.toLowerCase())
        )
      );
    }
  }, [query]);
  return (
    <>
      <div className="outer__inner">
        <div className="onex_wl bg_white">
          <div className="onex_wl__wrapper">
            <div className="upload__head">
              <h2 className="upload__title h2">
                {translate("Wallet / Balance")}
              </h2>
              {/* <a
                  className="button-stroke button-small upload__button"
                  href="#!"
                >
                  More filter
                </a> */}
            </div>
            <div className="keys__info w_cl horizontal__Scroll">
              <div className="page_details">
                <div className="item_c">
                  <h4>{translate("Balance")}</h4>
                  <p>${summary?.total}</p>
                </div>
                <div className="item_c">
                  <h4>{translate("Basic")}</h4>
                  <p>${summary?.basic}</p>
                </div>
                <div className="item_c">
                  <h4>{translate("Plus")}</h4>
                  <p>${summary?.plus}</p>
                </div>
                <div className="item_c">
                  <h4>{translate("Pro")}</h4>
                  <p>${summary?.pro}</p>
                </div>
                <div className="item_c">
                  <h4>{translate("Token")}</h4>
                  <p>${summary?.token}</p>
                </div>
                <div className="item_c">
                  <h4>{translate("Token")}</h4>
                  <p>${summary?.fail}</p>
                </div>
              </div>
            </div>
            <div className="onex_wl__list">
              <div className="keys__enabled d_block">
                <div className="keys__list">
                  <div className="keys__item ss">
                    <div className="activity__top ss">
                      <div className="nav">
                        <button
                          className={`nav__link cursorp ${
                            isItem === "all" ? " active" : " "
                          }`}
                          onClick={() => setItem("all")}
                        >
                          {translate("All")}
                        </button>
                        <button
                          className={`nav__link cursorp ${
                            isItem === "basic" ? " active" : " "
                          }`}
                          onClick={() => setItem("basic")}
                        >
                          {translate("Basic")}
                        </button>
                        <button
                          className={`nav__link cursorp ${
                            isItem === "plus" ? " active" : " "
                          }`}
                          onClick={() => setItem("plus")}
                        >
                          {translate("Plus")}
                        </button>
                        <button
                          className={`nav__link cursorp ${
                            isItem === "pro" ? " active" : " "
                          }`}
                          onClick={() => setItem("pro")}
                        >
                          {translate("Pro")}
                        </button>
                        <button
                          className={`nav__link cursorp ${
                            isItem === "token" ? " active" : " "
                          }`}
                          onClick={() => setItem("token")}
                        >
                          {translate("Token")}
                        </button>
                      </div>

                      <div className="right_items">
                        <form className="activity__form">
                          <input
                            className="activity__input"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            type="text"
                            name="search"
                            placeholder={translate("Search")}
                            required=""
                          />
                          <button className="activity__result">
                            <svg className="icon icon-search">
                              <use xlinkHref="#icon-search"></use>
                            </svg>
                          </button>
                        </form>
                        <div className="onex_ts__select">
                          <div className="select__field">
                            <select
                              value={status}
                              onChange={(e) => setStatus(e.target.value)}
                              className="select nice-select  app d_block"
                            >
                              <option value="all">{translate("All")}</option>
                              <option value="succeeded">
                                {translate("Successful")}
                              </option>
                              <option value="paid">{translate("Paid")}</option>
                              <option value="failed">
                                {translate("Failed")}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <BalanceTable
                      isInvoice={isInvoice}
                      setInvoice={setInvoice}
                      data={buffer}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Balance;
