import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Table, Tag, Space } from "antd";
import translate from "../../../shared/utils/translations";

const BalanceTable = ({ isInvoice, setInvoice, data }) => {
  const columns = [
    {
      title: translate("Invoice"),
      dataIndex: "payment_intent_id",
      key: "payment_intent_id",
      render: (item) => (
        <span className="table_bold_text w_icn ellipsis_That">
          <span className="icn">
            <FeatherIcon icon={"file"} />
          </span>{" "}
          {item}
        </span>
      ),
    },
    {
      title: translate("Client"),
      dataIndex: "user_name",
      key: "user_name",
      render: (item) => (
        <>
          <div className="column_info">{translate("Client")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Billing Date"),
      dataIndex: "created_at",
      key: "created_at",
      render: (item) => (
        <>
          <div className="column_info">{translate("Billing Date")}</div>
          {item?.replace("T", " ")?.split(".")[0]}
        </>
      ),
    },
    {
      title: translate("Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (item) => (
        <>
          <div className="column_info">{translate("Amount")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Plan"),
      dataIndex: "tier_name",
      key: "tier_name",
      render: (item) => (
        <>
          <div className="column_info">{translate("Plan")}</div>
          {item}
        </>
      ),
    },

    {
      title: translate("Status"),
      key: "status",
      dataIndex: "status",
      render: (status) => (
        <>
          <Tag
            key={status}
            className={`default__tag ${
              status === "failed" ? " unpaid" : " paid"
            }`}
          >
            {status?.capitalize()}
          </Tag>
        </>
      ),
    },
    {
      title: translate("Action"),
      key: "action",
      className: "table_actin",
      render: (text, record) => (
        <>
          <div className="column_info">{translate("Details")}</div>
          <a className="table__lnk" target="_blank" href={record?.invoice_url}>
            {translate("Download")}
          </a>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="sessions__table">
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            locale: {
              items_per_page: translate("Page"),
            },
            position: ["top", "bottom"],
          }}
          className="card_m"
        />
      </div>
    </>
  );
};

export default BalanceTable;
