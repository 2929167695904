import React, { useEffect, useLayoutEffect, useState } from "react";
import Input from "../../common/form_elements/input";
import BottomButtons from "./_bottom_buttons";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import { notify, requestHandler } from "../utils/helpers";
import { Tooltip } from "antd";
import translate from "../utils/translations";
import { Helmet } from "react-helmet";

const Personal = ({ setPage, isPage }) => {
  const navigate = useNavigate();
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [formData, setFormData] = useState({});
  useEffect(() => {
    if (isPage === "dashboard-admin") {
      setPage("dashboard-admin");
      localStorage.setItem("dashboard-type", "dashboard-admin");
    } else {
      setPage("dashboard-client");
      localStorage.setItem("dashboard-type", "dashboard-client");
    }
  });
  useEffect(() => {
    requestHandler("get", "/profile", null, true, navigate, "/login").then(
      (res) => {
        console.log("profile", res);
        setFormData(res?.data);
      }
    );
  }, []);
  const onChange = (value, name) => {
    setFormData({ ...formData, [name]: value });
  };
  const submit = async () => {
    setBtnLoading(true);
    console.log("data", formData);
    return requestHandler("put", "/profile", formData, true, navigate, "/login")
      .then((res) => {
        console.log("profile", res);
        setBtnLoading(false);
        notify(translate("Settings have been saved successfully!.."));
      })
      .catch((err) => {
        console.log("profile", err);
        setBtnLoading(false);
      });
  };
  return (
    <>
      <Helmet>
        <title>{translate("Personal info")}</title>
      </Helmet>
      <div className="outer__inner">
        <div className="onex_wl bg_white">
          <div className="onex_wl__wrapper">
            <div className="upload__head">
              <div className="dashbordi__nav mm">
                <NavLink className="dashbordi__link" to="/personal-settings">
                  {translate("Personal")}
                </NavLink>
                <NavLink className="dashbordi__link" to="/security-settings">
                  {translate("Security")}
                </NavLink>
                <NavLink className="dashbordi__link" to="/marketplace-settings">
                  {translate("Marketplace")}
                </NavLink>
                <NavLink className="dashbordi__link" to="/integration-settings">
                  {translate("Integrations")}
                </NavLink>
              </div>
              <h2 className="upload__title h2">{translate("Personal info")}</h2>
            </div>
            <div className="keys__info">
              {translate(
                "We would like to know you better and offer better solutions to your problems."
              )}
            </div>
            <div className="onex_wl__list">
              <div className="keys__enabled d_block">
                <div className="keys__list">
                  <div className="keys__item" style={{ maxWidth: "600px" }}>
                    <div className="setting__list">
                      <div className="setting__section">
                        <div className="setting__category">
                          {translate("Account info")}
                        </div>
                        <div className="setting__fieldset">
                          <div className="setting__row cp__tt">
                            <Input
                              id="first-name"
                              name="first_name"
                              label={translate("First Name")}
                              type="text"
                              placeholder={translate("Enter your first name")}
                              onChange={onChange}
                              value={formData?.first_name}
                            />

                            <Input
                              id="last-name"
                              name="last_name"
                              label={translate("Last Name")}
                              type="text"
                              placeholder={translate("Enter your last name")}
                              onChange={onChange}
                              value={formData?.last_name}
                            />
                          </div>
                          <div className="setting__row">
                            <Input
                              id="phone"
                              name="phone_number"
                              label={translate("Phone")}
                              type="tel"
                              placeholder={translate("Phone number")}
                              onChange={onChange}
                              value={formData?.phone_number}
                            />
                            <Input
                              disabled={true}
                              id="email"
                              name="email"
                              label={translate("Email")}
                              type="email"
                              placeholder={translate("Email")}
                              onChange={() => {}}
                              value={formData?.email}
                            />
                          </div>
                          <Input
                            id="address"
                            name="address"
                            label={translate("Address Details")}
                            type="text"
                            placeholder={translate("Enter your address")}
                            onChange={onChange}
                            value={formData?.address}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="setting__controls">
                      <button
                        className="button setting__button"
                        onClick={submit}
                      >
                        {translate("Update")}{" "}
                        {isBtnLoading && <span className="btn-spinner"></span>}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Personal;
