import React, { useCallback, useEffect } from "react";
import { Dropdown, Drawer } from "antd";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Tooltip, Modal, Button, Input } from "antd";
import { Slide } from "react-slideshow-image";
import FeatherIcon from "feather-icons-react";
import "react-slideshow-image/dist/styles.css";
import translate from "../../../../shared/utils/translations";
import OutsideAlerter from "../../../../shared/header/_useOutsideAlerter";
import MarketComparison from "./_market_comparsion";
import KepickerCalculator from "./_kepicker_calculator";
import DimensionsInfos from "./_dimensions_infos";
import RoiFees from "./_roi_fees_manual";
import MainInfos from "./_main_infos";
import StockFetcher from "./_stock_fetcher";
import AdditionalInfos from "./_additional_infos";
import KeepaData from "./_keepa_data";
import AsinFetcher from "./_asin_fetcher";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import {
  copyToClipboard,
  requestHandler,
  makeReadable,
  waitSeconds,
  notify,
  getUserFromDb,
  getMarketOptions,
  calculateManualRoi,
  fastConvertCurrency,
} from "../../../../shared/utils/helpers";
import NoImage from "../../../../../assets/img/no_image.png";
import LoaderPage from "../../../../common/loaders/loader_page";
import LoaderPBI from "../../../../common/loaders/loader_pbi";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import ProductMetrics from "./_product_metrics";
import ManualCalculation from "./_manual_calculation";
import SettingFormManual from "../../../../shared/settings/markets/setting_form_manual";
import ManualOtherMarketplaces from "./_manual_other_marketplaces";
import OtherInfosDrawer from "./other_infos_drawer";
// erdem gy
const PBIManualPage = ({ setPage, setBuyTokenDrawer }) => {
  useEffect(() => {
    setPage("dashboard-client");
    localStorage.setItem("dashboard-type", "dashboard-client");
  });
  const selectedDomain = localStorage.getItem("selectedManualDomain");
  const selectedCurrency = localStorage.getItem("selectedManualCurrency");

  const { asin } = useParams();

  const [isMore, setMore] = useState(false);
  const [isTab, setTab] = useState("kd");
  const [isAsinFetcher, setAsinFetcher] = useState(false);
  const [keepaData, setKeepaData] = useState({});
  const [additionalData, setAdditionalData] = useState({});
  const [baseOffersData, setBaseOffersData] = useState({});
  const [targetOffersData, setTargetOffersData] = useState({});
  const [imageUrl, setImageUrl] = useState("");
  const [mainData, setMainData] = useState({});
  const [basePrice, setBasePrice] = useState(0);
  const [currency, setCurrency] = useState(selectedCurrency || "USD");
  const [targetPrice, setTargetPrice] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [preferenceData, setPreferenceData] = useState({});
  const [preferences, setPreferences] = useState([]);
  const [isFav, setFav] = useState(false);
  const [note, setNote] = useState("");
  const [isNoteModal, setNoteModal] = useState(false);
  const [isNoteBtn, setNoteBtn] = useState(false);
  const [user, setUser] = useState({});
  const [availableMarkets, setAvailableMarkets] = useState([]);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [isSettingFormManual, setSettingFormManual] = useState(false);
  const [isOtherInfos, setOtherInfos] = useState(false);
  const [targetDomain, setTargetDomain] = useState(selectedDomain);
  const [title, setTitle] = useState("");
  const [salesRankCategory, setSalesRankCategory] = useState("");
  const [rates, setRates] = useState({});
  const [selectedMarketplaces, setSelectedMarketplaces] = useState([]);
  const { TextArea } = Input;
  const [selectedAdditionalData, setSelectedAdditionalData] = useState({});
  const [selectedKeepaData, setSelectedKeepaData] = useState({});
  const [itemDimensions, setItemDimensions] = useState();
  const [packageDimensions, setPackageDimensions] = useState();
  const [itemWeight, setItemWeight] = useState();
  const [packageWeight, setPackageWeight] = useState();

  useEffect(() => {
    console.log(
      "state changed",
      itemDimensions,
      packageDimensions,
      itemWeight,
      packageWeight
    );
  }, [itemDimensions, packageDimensions, itemWeight, packageWeight]);

  const getPreferences = async () => {
    const res = await requestHandler(
      "get",
      `/preferences`,
      null,
      true,
      null,
      null
    );
    setPreferences(res?.data?.manualPreferences);
  };

  useEffect(() => {
    localStorage.setItem("selectedManualCurrency", currency);
  }, [currency]);

  useEffect(() => {
    getPreferences();

    console.log("selectedDomain", selectedDomain);
    console.log("selectedCurrency", selectedCurrency);
    getUserFromDb().then((res) => {
      console.log("user", res, getMarketOptions(res));
      setUser(res);
      setAvailableMarkets(getMarketOptions(res));
      setCurrency(selectedCurrency || getMarketOptions(res)[0]?.currency);
    });
  }, []);

  const saveNote = async (note) => {
    const res = await requestHandler(
      "put",
      `/asin/note/${asin}`,
      { note, asin },
      true,
      null,
      null
    );
    if (res?.error) {
      toast.error(res?.error);
    } else {
      toast.success(
        translate(
          note ? "Note applied successfully!" : "Note deleted successfully!"
        )
      );
      setNoteModal(false);
      setNoteBtn(true);
    }
  };

  const handleApplyNote = () => {
    if (note.trim() === "") {
      toast.error(translate("Note cannot be empty!"));
    } else {
      saveNote(note);
    }
  };

  const handleRemoveNote = () => {
    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Are you sure about performing this action?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setNote("");
        saveNote();
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>{translate("Product Based Info")}</title>
      </Helmet>

      <SettingFormManual
        isSettingFormManual={isSettingFormManual}
        setSettingFormManual={setSettingFormManual}
        isBtnLoading={isBtnLoading}
        refresh={getPreferences}
        availableMarkets={availableMarkets}
        preferences={preferences}
        setPreferences={setPreferences}
        currency={currency}
      />

      <OtherInfosDrawer
        isOtherInfos={isOtherInfos}
        setOtherInfos={setOtherInfos}
      >
        <div className="other__infos__area">
          <div className="nav sheets_nav">
            <button
              className={`nav__link cursorp ${
                isTab === "kd" ? " active" : " "
              }`}
              onClick={() => setTab("kd")}
            >
              {translate("Keepa Data")}
            </button>
            <button
              className={`nav__link cursorp ${
                isTab === "ai" ? " active" : " "
              }`}
              onClick={() => setTab("ai")}
            >
              {translate("Additional Infos")}
            </button>
          </div>
          {isTab === "ai" ? (
            <AdditionalInfos additionalData={selectedAdditionalData} />
          ) : (
            <></>
          )}
          {isTab === "kd" ? <KeepaData keepaData={selectedKeepaData} /> : <></>}
        </div>
      </OtherInfosDrawer>

      <Modal
        title={translate("Product Note")}
        centered
        visible={isNoteModal}
        onOk={() => setNoteModal(false)}
        onCancel={() => setNoteModal(false)}
        footer={[
          <Button key="back" onClick={handleRemoveNote}>
            {translate("Remove Note!")}
          </Button>,
          <Button key="submit" type="primary" onClick={handleApplyNote}>
            {translate("Apply")}
          </Button>,
        ]}
      >
        <div className="coppyy_Asin_Area">
          <p className="ant-copiedd-text">
            {translate("Write your note here:")}
          </p>
          <TextArea
            value={note}
            onChange={(e) => setNote(e.target.value)}
            placeholder={translate("Add a note..")}
            autoSize={{
              minRows: 5,
              maxRows: 6,
            }}
          />
        </div>
      </Modal>
      <div className="outer__inner">
        <div className="onex_wl bg_white tthb">
          <div className="onex_wl__wrapper ppbbi pbii__manual">
            <div className="onex_wl__list pbbi__body ps_rrel">
              {user?.asin_count >= 1 ? (
                <>
                  <div className="cmp_drw_title tt ffg">
                    <h4>{translate("Scan Results")}</h4>{" "}
                    <div className="rght_itms_gbh">
                      <Tooltip
                        title={translate("Marketplace Settings")}
                        placement="bottom"
                      >
                        <button
                          onClick={() =>
                            setSettingFormManual(!isSettingFormManual)
                          }
                          className="sett___btnn"
                        >
                          <FeatherIcon icon={"settings"} />
                        </button>
                      </Tooltip>

                      <Tooltip
                        title={translate("Product Note")}
                        placement="left"
                      >
                        <button
                          onClick={() => setNoteModal(true)}
                          className={`sett___btnn dffg ${
                            isNoteBtn && " active"
                          }`}
                        >
                          <span>
                            <FeatherIcon icon={"edit"} />
                          </span>
                        </button>
                      </Tooltip>
                    </div>
                  </div>

                  <div className="comparsion__body tty">
                    <div className="left__wrpp"></div>

                    <div className="right__wrpp">
                      {selectedMarketplaces?.length >= 1 ? (
                        <MainInfos
                          title={title}
                          salesRankCategory={salesRankCategory}
                          imageUrl={imageUrl}
                          noImg={NoImage}
                          customClass="is_manual_infos"
                        />
                      ) : (
                        <></>
                      )}

                      {selectedMarketplaces?.length >= 1 ? (
                        <ManualCalculation
                          setAsinFetcher={setAsinFetcher}
                          baseOffersData={baseOffersData}
                          targetOffersData={targetOffersData}
                          preferenceData={preferenceData}
                          asin={asin}
                          availableMarkets={availableMarkets}
                          currency={currency}
                          setCurrency={setCurrency}
                          basePrice={basePrice}
                          setBasePrice={setBasePrice}
                          targetPrice={targetPrice}
                          setTargetPrice={setTargetPrice}
                          shippingCost={shippingCost}
                          setShippingCost={setShippingCost}
                        />
                      ) : (
                        <></>
                      )}

                      <ManualOtherMarketplaces
                        availableMarkets={availableMarkets}
                        asin={asin}
                        currency={currency}
                        basePrice={basePrice}
                        shippingCost={shippingCost}
                        preferences={preferences}
                        selectedMarketplaces={selectedMarketplaces}
                        setSelectedMarketplaces={setSelectedMarketplaces}
                        setOtherInfos={setOtherInfos}
                        setSelectedAdditionalData={setSelectedAdditionalData}
                        setSelectedKeepaData={setSelectedKeepaData}
                        setSalesRankCategory={setSalesRankCategory}
                        setTitle={setTitle}
                        setImageUrl={setImageUrl}
                        setItemDimensions={setItemDimensions}
                        setPackageDimensions={setPackageDimensions}
                        setItemWeight={setItemWeight}
                        setPackageWeight={setPackageWeight}
                      />
                      {selectedMarketplaces?.length >= 1 ? (
                        <DimensionsInfos
                          itemDimensions={itemDimensions}
                          packageDimensions={packageDimensions}
                          itemWeight={itemWeight}
                          packageWeight={packageWeight}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="oppess_card">
                    <FeatherIcon
                      icon={"alert-circle"}
                      className={"alertt__iccn"}
                    />
                    <h4>{translate("Sorry, Daily Limit Reached!")}</h4>
                    <p>
                      {translate(
                        "Your Daily Usage Limit Has Been Reached! Please wait until the next day to use it again.."
                      )}
                    </p>
                    <div className="flexx_btnns">
                      <NavLink
                        to="/asin360"
                        className="up_btn token_bt cursorp"
                      >
                        <span>{translate("Asin 360°")}</span>
                        <FeatherIcon icon={"arrow-up-right"} />
                      </NavLink>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PBIManualPage;
