import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";

import translate from "../../../shared/utils/translations";
// erdem gy
const BarcodeReaderWelcome = ({ setPage }) => {
  useEffect(() => {
    setPage("dashboard-client");
    localStorage.setItem("dashboard-type", "dashboard-client");
  });

  useEffect(() => {
    const handleScrollToTop = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener("load", handleScrollToTop);

    return () => window.removeEventListener("load", handleScrollToTop);
  }, []);

  return (
    <>
      <div className="outer__inner">
        <div className="onex_wl bg_white">
          <div className="onex_wl__wrapper readr_err">
            <div className="barcode_readder_wrapper">
              <div className="barcode_readr__body">
                <div className="rdr_b_hdr">
                  <h5>{translate("Barcode Reader")}</h5>

                  <p>
                    {translate(
                      "Scan the barcode with your camera by pressing the barcode reading button."
                    )}
                  </p>
                </div>
                <div className="rdr_b_middle">
                  <img src="/assets/img/barcode_hero.png"></img>
                  <img
                    className="brd_scanng"
                    src="/assets/img/barcode_scanning.png"
                  ></img>
                </div>
                <div className="rdr_b_footr">
                  <div className="rdr__dots">
                    <div className="rdr__dot_itm active"></div>
                    <div className="rdr__dot_itm"></div>
                    <div className="rdr__dot_itm"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BarcodeReaderWelcome;
