import { Auth } from "aws-amplify";
import toast, { Toaster } from "react-hot-toast";

import axios from "axios";
export async function signUp(
  username,
  password,
  email,
  first_name,
  last_name,
  affiliate_code,
  phone
) {
  try {
    const { user } = await Auth.signUp({
      username,
      password,
      attributes: {
        email,
        "custom:first_name": first_name, // Custom attribute
        "custom:last_name": last_name, // Custom attribute
        "custom:affiliate_code": affiliate_code, // Custom attribute
        "custom:phone": phone,
      },
    });
    console.log(user);
    return user;
  } catch (error) {
    console.log("error signing up:", error, error.message);
    toast.error(error.message);
    return false;
  }
}
export async function signIn(username, password) {
  try {
    const user = await Auth.signIn(username, password);
    console.log(user);
    axiosSetDefaults(`Bearer ${user.signInUserSession.accessToken.jwtToken}`);
    return user;
  } catch (error) {
    console.log("error signing in", error);
    return false;
  }
}

export async function forgotPassword(username) {
  try {
    console.log("Password reset code sent successfully");

    return await Auth.forgotPassword(username);
  } catch (error) {
    console.log("Error while sending code", error);
  }
}
export async function forgotPasswordSubmit(username, code, new_password) {
  try {
    await Auth.forgotPasswordSubmit(username, code, new_password);
    console.log("The new password has been set successfully");
  } catch (error) {
    console.log("Error while resetting the password", error);
  }
}
export async function fetchUserData() {
  try {
    const userData = await Auth.currentAuthenticatedUser();
    console.log("User Data:", userData);
    return userData;
  } catch (error) {
    console.error("Error fetching user data: ", error);
  }
}
export async function signOut() {
  try {
    await Auth.signOut();
    console.log("User logged out");
  } catch (error) {
    console.error("Error logging out: ", error);
  }
}
export async function changePassword(oldPassword, newPassword) {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const result = await Auth.changePassword(user, oldPassword, newPassword);
    return result === "SUCCESS";
  } catch (error) {
    console.error("Error changing password: ", error);
    return error;
  }
}
const axiosSetDefaults = (idToken) => {
  console.log("Setting axios defaults", idToken);
  axios.defaults.headers.common["Authorization"] = idToken;
  axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
  axios.defaults.headers.post["Access-Control-Allow-Methods"] =
    "POST,GET,OPTIONS,DELETE,PUT";
  axios.defaults.headers.post["Content-Type"] = "application/json";
  localStorage.setItem("idToken", idToken);
};
