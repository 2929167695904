import React, { useState, useLayoutEffect, useEffect } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";
import { connect } from "react-redux";
import * as settingsActions from "./components/hooks/actions";
import Layout from "./components/shared/layout/layout";
import Header from "./components/shared/header/header";
import BackToTop from "./components/hooks/scroll_top";
import "./App.css";
import Icons from "./components/common/other_elements/icons";
import SidebarClientDashboard from "./components/pages/dashboard_client/_sidebar_client";
import SidebarAdminDashboard from "./components/pages/dashboard_admin/_sidebar_admin";
import Footer from "./components/shared/footer/footer_main";
import FooterMobile from "./components/shared/footer/footer_mobile";
import { Amplify, Auth } from "aws-amplify";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import ScrollToTop from "react-scroll-to-top";
import PaymentDrawer from "./components/shared/payment/payment_drawer";
import BuyTokenForm from "./components/shared/payment/token_buy_drawer";
import UserDetailsDrawer from "./components/pages/dashboard_admin/user_details/user_details_drawer";
import translate from "./components/shared/utils/translations";
import { logNavigation } from "./components/shared/utils/helpers";

const history = createBrowserHistory();

Amplify.configure({
  Auth: {
    region: "eu-central-1",
    userPoolId: "eu-central-1_R6AWA8Juv",
    userPoolWebClientId: "575tk81rvg1obcfd9c3762t1uk",
  },
});
const App = ({ isDarkMode, setDarkMode }) => {
  useLayoutEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
  }, [isDarkMode]);
  //remember to change in here
  const isLocal = false;
  console.log("app rendered");

  axios.defaults.baseURL = isLocal
    ? "http://localhost:4000"
    : "https://bbymt5vudd.execute-api.eu-central-1.amazonaws.com/";

  const [isPage, setPage] = useState("landing");
  // "landing" -- "auth" -- "dashboard-admin" -- "dashboard-client"

  const [isSidebar, setSidebar] = useState(false);
  const [isPaymentDrawer, setPaymentDrawer] = useState(false);
  const [isBuyTokenDrawer, setBuyTokenDrawer] = useState(false);
  const [isUserDetailsDrawer, setUserDetailsDrawer] = useState(false);
  const [paymentData, setPaymentData] = useState({});
  const [selectedUser, setSelectedUser] = useState(null);

  const axiosSetDefaults = (idToken) => {
    axios.defaults.headers.common["Authorization"] = idToken;
    axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
    axios.defaults.headers.post["Access-Control-Allow-Methods"] =
      "POST,GET,OPTIONS,DELETE,PUT";
    axios.defaults.headers.post["Content-Type"] = "application/json";
    localStorage.setItem("idToken", idToken);
  };

  const refreshToken = async () => {
    console.log("refreshing token triggered");
    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log("user fetched in app via refresh token", user);
        const fetchedToken = user.signInUserSession.idToken.jwtToken;
        axiosSetDefaults(`${fetchedToken}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useLayoutEffect(() => {
    const token = localStorage.getItem("idToken");
    const currentAxiosToken = axios.defaults.headers.common["Authorization"];

    if (
      token &&
      (currentAxiosToken !== token ||
        currentAxiosToken === undefined ||
        currentAxiosToken === null ||
        currentAxiosToken === "")
    ) {
      console.log("setting axios defaults");
      axiosSetDefaults(token);
      setPage("dashboard-client");
    } else if (token === currentAxiosToken) {
      console.log("token already set");
      setPage("dashboard-client");
    } else if (!token) {
      console.log("no token");
      setPage("landing");
    } else {
      console.log("else", token, currentAxiosToken);
    }
  }, []);
  // Refresh token every 10 minutes = 6000000ms
  useEffect(() => {
    refreshToken();
    const interval = setInterval(() => {
      refreshToken();
    }, 600000);
    return () => clearInterval(interval);
  }, []);

  const [delayedIsPaymentDrawer, setDelayedIsPaymentDrawer] = useState(false);
  const [isRenderPaymentDrawer, setRenderPaymentDrawer] = useState(false);
  useEffect(() => {
    if (isPaymentDrawer) {
      const timer = setTimeout(() => {
        setDelayedIsPaymentDrawer(true);
      }, 100);
      setRenderPaymentDrawer(true);
      return () => clearTimeout(timer);
    } else {
      const timer = setTimeout(() => {
        setRenderPaymentDrawer(false);
      }, 300);
      setDelayedIsPaymentDrawer(false);
      return () => clearTimeout(timer);
    }
  }, [isPaymentDrawer]);

  const [delayedIsBuyTokenDrawer, setDelayedBuyTokenDrawer] = useState(false);
  const [isRenderBuyTokenDrawer, setRenderBuyTokenDrawer] = useState(false);
  useEffect(() => {
    if (isBuyTokenDrawer) {
      const timer = setTimeout(() => {
        setDelayedBuyTokenDrawer(true);
      }, 100);
      setRenderBuyTokenDrawer(true);
      return () => clearTimeout(timer);
    } else {
      const timer = setTimeout(() => {
        setRenderBuyTokenDrawer(false);
      }, 300);
      setDelayedBuyTokenDrawer(false);
      return () => clearTimeout(timer);
    }
  }, [isBuyTokenDrawer]);

  const [delayedUserDetailsDrawer, setDelayedUserDetailsDrawer] =
    useState(false);
  const [isRenderUserDetailsDrawer, setRenderUserDetailsDrawer] =
    useState(false);
  useEffect(() => {
    if (isUserDetailsDrawer) {
      const timer = setTimeout(() => {
        setDelayedUserDetailsDrawer(true);
      }, 100);
      setRenderUserDetailsDrawer(true);
      return () => clearTimeout(timer);
    } else {
      const timer = setTimeout(() => {
        setRenderUserDetailsDrawer(false);
      }, 300);
      setDelayedUserDetailsDrawer(false);
      return () => clearTimeout(timer);
    }
  }, [isUserDetailsDrawer]);

  useEffect(() => {
    const isMobile = window.innerWidth <= 768;

    if (!isMobile) {
      const originalTitle = document.title;

      function handleVisibilityChange() {
        if (document.visibilityState === "hidden") {
          document.title = `${translate("Please come back!..")} 😔`;
        } else {
          document.title = originalTitle;
        }
      }

      document.addEventListener("visibilitychange", handleVisibilityChange);

      return () => {
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
      };
    }
  }, []);
  const location = useLocation();

  useEffect(() => {
    console.log("Navigated to:", location.pathname);
    logNavigation(location.pathname); // Call your log function
  }, [location]);

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        gutter={8}
        containerClassName="toast_box"
        containerStyle={{ top: 50 }}
        toastOptions={{
          // Define default options
          className: "",
          duration: 2200,
          style: {
            background: "#fff",
            color: "#23262F",
            padding: "16px",
            border: "2px solid #E6E8EC",
            minWidth: "fit-content",
            fontWeight: "500",
          },

          // Default options for specific types
          success: {
            duration: 2200,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />

      {/* Elements */}
      <BackToTop isPage={isPage} />

      {/* Header */}
      {window.location.pathname === "/login" ||
      window.location.pathname === "/signup" ||
      window.location.pathname === "/forgot" ||
      isPage === "auth" ? (
        <></>
      ) : (
        <div className="kepicker_header">
          <Header
            isPage={isPage}
            setSidebar={setSidebar}
            isSidebar={isSidebar}
          />
        </div>
      )}

      {/* Sidebar */}
      {isPage === "dashboard-admin" ? (
        <SidebarAdminDashboard isSidebar={isSidebar} setSidebar={setSidebar} />
      ) : isPage === "dashboard-client" ? (
        <SidebarClientDashboard
          isSidebar={isSidebar}
          setSidebar={setSidebar}
          setBuyTokenDrawer={setBuyTokenDrawer}
        />
      ) : (
        <></>
      )}

      {/* Buy Token Form */}
      {isRenderBuyTokenDrawer && (
        <BuyTokenForm
          isBuyTokenDrawer={delayedIsBuyTokenDrawer}
          setBuyTokenDrawer={setBuyTokenDrawer}
          setPaymentDrawer={setPaymentDrawer}
          setPaymentData={setPaymentData}
          paymentData={paymentData}
        />
      )}

      {/* Payment Form */}
      {isRenderPaymentDrawer && (
        <PaymentDrawer
          isPaymentDrawer={delayedIsPaymentDrawer}
          setPaymentDrawer={setPaymentDrawer}
          paymentData={paymentData}
        />
      )}

      {/* Client Details Drawer */}
      {isRenderUserDetailsDrawer && (
        <UserDetailsDrawer
          isUserDetailsDrawer={delayedUserDetailsDrawer}
          setUserDetailsDrawer={setUserDetailsDrawer}
          setBuyTokenDrawer={setBuyTokenDrawer}
          selectedUser={selectedUser}
        />
      )}

      {/* Pages */}
      <Layout
        setPage={setPage}
        isPage={isPage}
        paymentData={paymentData}
        setPaymentData={setPaymentData}
        isPaymentDrawer={isPaymentDrawer}
        setPaymentDrawer={setPaymentDrawer}
        isBuyTokenDrawer={isBuyTokenDrawer}
        setBuyTokenDrawer={setBuyTokenDrawer}
        setUserDetailsDrawer={setUserDetailsDrawer}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />

      {/* Footer */}
      {window.location.pathname === "/login" ||
      window.location.pathname === "/signup" ||
      window.location.pathname === "/forgot" ||
      isPage === "auth" ? (
        <></>
      ) : (
        <Footer history={history} isPage={isPage} setSidebar={setSidebar} />
      )}

      {/* Mobile Dashboard Footer */}
      {isPage === "dashboard-admin" ||
      isPage === "dashboard-client" ||
      isPage === "landing" ? (
        <FooterMobile
          history={history}
          isPage={isPage}
          isSidebar={isSidebar}
          setSidebar={setSidebar}
        />
      ) : (
        <></>
      )}

      <Icons />
      <ScrollToTop smooth viewBox="0 0 24 24" svgPath="M17 15L12 10L7 15" />
    </>
  );
};

const mapStateToProps = (state) => ({
  isDarkMode: state.settings.isDarkMode,
});

const mapDispatchToProps = (dispatch) => ({
  setDarkMode: (e) => dispatch(settingsActions.setDarkMode(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
