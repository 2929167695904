import React, { useEffect, useLayoutEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import translate from "../../../utils/translations";
import SettingForm from "./setting_form";
import { Button, Tooltip } from "antd";
import Swal from "sweetalert2";
import toast, { Toaster } from "react-hot-toast";

const SheetSettings = ({
  sheets,
  deleteSetting,
  saveSheetPreferences,
  setDefaultSheetPreference,
}) => {
  console.log(sheets);
  const navigate = useNavigate();
  const [isBtnLoading, setBtnLoading] = useState(false);

  const [isSettingForm, setSettingForm] = useState(false);

  const [isNewSettingBtn, setNewSettingBtn] = useState(false);
  const [isSettingEditable, setSettingEditable] = useState(false);
  const [selectedSetting, setSelectedSetting] = useState({});

  return (
    <>
      <SettingForm
        isSettingForm={isSettingForm}
        setSettingForm={setSettingForm}
        isBtnLoading={isBtnLoading}
        setBtnLoading={setBtnLoading}
        isSettingEditable={isSettingEditable}
        selectedSetting={selectedSetting}
        saveSheetPreferences={saveSheetPreferences}
      />
      <div className="keys__item">
        <div className="setting__list">
          <div className="setting__section">
            <div className="setting__category cvvf">
              {translate("Sheet Settings")}

              <button
                onClick={() => {
                  setSettingForm(true);
                  setSettingEditable(false);
                  setSelectedSetting({});
                }}
                className="button-stroke button-small activity__button js-activity-button"
              >
                {translate("New Setting")}
              </button>
            </div>
            <div className="setting__fieldset">
              <ul>
                {sheets?.map((i) => {
                  return (
                    <li
                      className={`saved_setting_itm ${
                        i?.is_default ? "active" : ""
                      }`}
                    >
                      <div
                        onClick={() => {
                          setDefaultSheetPreference(i.id);
                        }}
                        className="saved_setting_title"
                      >
                        {i.preference_name}
                      </div>
                      <div className="saved_setting_actions">
                        <div className="d_flex_center">
                          <Tooltip title={translate("Delete")} placement="top">
                            <Button
                              onClick={() => deleteSetting(i.id)}
                              icon={<FeatherIcon icon={"trash"} />}
                              className={"table_icon"}
                            />
                          </Tooltip>
                          <Tooltip title={translate("Show")} placement="top">
                            <Button
                              onClick={() => {
                                setSettingForm(true);
                                setSettingEditable(true);
                                setSelectedSetting(i);
                              }}
                              icon={<FeatherIcon icon={"eye"} />}
                              className={"table_icon"}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SheetSettings;
