import React, { useEffect, useState } from "react";
import { Table, Tag, Dropdown, Menu, Button, Tooltip } from "antd";
import FeatherIcon from "feather-icons-react";
import translate from "../../../../shared/utils/translations";
import EmptyIcon from "../../../../../assets/img/empty.png";
import { makeReadable } from "../../../../shared/utils/helpers";

const AdditionalInfos = ({ additionalData }) => {
  const exampleData = {
    asin: "B09X7C7NCZ",
    title:
      "SanDisk 64GB Extreme PRO SDXC card + RescuePRO Deluxe, up to 200MB/s, UHS-I, Class 10, U3, V30",
    sales_rank: 172,
    sales_rank_category: "Computers & Accessories",
    brand: "SanDisk",
    binding: "Personal Computer",
    profuct_group: "Components & Replacement Parts",
    product_type_name: "FLASH_MEMORY",
    dimensionUnit: "inches",
    item_dimensions: "0.94 x 0.09 x 1.26 inches",
    package_dimensions: "4.76 x 6.18 x 1.14 inches",
    item_weight: "0.004",
    package_weight: "0.040",
    item_dimensions_cm: "2.39 x 0.23 x 3.20 cm",
    package_dimensions_cm: "12.09 x 15.70 x 2.90 cm",
    item_weight_kg: "0.002",
    package_weight_kg: "0.018",
    weight_unit: "pounds",
    is_hazmat: false,
    hazmat_data: null,
    image: "https://m.media-amazon.com/images/I/41OFYRHnwkL.jpg",
    has_variation: true,
    is_meltable: false,
    language: "english, english, english, english, english, english, turkish",
    skip_offer: "false",
    model_name: "Extreme PRO",
    bullet_point:
      "Save time with card offload speeds of up to 200MB/s powered by SanDisk QuickFlow Technology, Pair with the SanDisk Professional PRO-READER SD and microSD to achieve maximum speeds (sold separately), Shot speeds up to 90MB/s, Perfect for shooting 4K UHD video and sequential burst mode photography, Capture uninterrupted video with UHS Speed Class 3 (U3) and Video Speed Class 30 (V30)",
    item_package_quantity: "1",
    externally_assigned_product_identifier: "619659188719, 0619659188719",
    package_level: "unit",
    flash_memory: "undefined",
    number_of_items: "1",
    item_package_dimensions: "undefined",
    secure_digital_association_speed_class: "class_10",
    size: "64GB",
    hardware_interface: "sdhc",
    warranty_description: "Lifetime limited",
    part_number: "SDSDXXU-064G-GN4IN",
    style: "UHS-I V30",
    color: "Black",
    read_speed: "200",
    variation_theme: "undefined",
    item_package_weight: "0.018",
    item_length_width: "undefined",
    manufacturer: "San-Disk",
    digital_storage_capacity: "64",
    number_of_lithium_metal_cells: "1",
    recommended_browse_nodes: "1485629031, 296791011",
    model_number: "SDSDXXU-064G-GN4IN",
    memory_storage_capacity: "64",
    supplier_declared_dg_hz_regulation: "not_applicable",
    special_feature: "X_ray_proof, Temperature_proof, Shock_proof, Water_proof",
    gdpr_risk: "no_electronic_information_stored",
    item_name:
      "SanDisk 64GB Extreme PRO SDXC card + RescuePRO Deluxe, up to 200MB/s, UHS-I, Class 10, U3, V30",
    list_price: "undefined",
    batteries_required: "false",
    street_date: "2022-05-30T00:00:01Z",
    item_depth_width_height: "undefined",
    product_site_launch_date: "2022-04-05T18:40:34.655Z",
    compatible_devices: "Camera",
    batteries_included: "false",
    write_speed: "90.0",
    marketplaceId: "A1F83G8C2ARO7P",
    adultProduct: false,
    autographed: false,
    browseClassification: "undefined - undefined",
    itemClassification: "BASE_PRODUCT",
    itemName:
      "SanDisk 64GB Extreme PRO SDXC card + RescuePRO Deluxe, up to 200MB/s, UHS-I, Class 10, U3, V30",
    memorabilia: false,
    modelNumber: "SDSDXXU-064G-GN4IN",
    packageQuantity: 1,
    partNumber: "SDSDXXU-064G-GN4IN",
    releaseDate: "2022-05-30",
    tradeInEligible: false,
    websiteDisplayGroup: "pc_display_on_website",
    websiteDisplayGroupName: "Personal Computer",
  };

  const [dynamicData, setDynamicData] = useState([]);

  useEffect(() => {
    additionalData &&
      additionalData?.asin &&
      setDynamicData(
        Object.keys(additionalData)?.map((key) => {
          const value = additionalData?.[key];
          let renderedValue = "";
          if (typeof value === "object") {
            renderedValue = JSON.stringify(value);
          } else if (typeof value === "boolean") {
            renderedValue = value ? "Yes" : "No";
          } else if (typeof value === "array") {
            renderedValue = value.join(", ");
          } else {
            renderedValue = value;
          }
          return {
            key,
            name: key ? makeReadable(key) : "N/A",
            value: renderedValue,
          };
        })
      );
  }, [additionalData]);

  return (
    <>
      <div className="product__cmp_info stck">
        <div className="minii__tablee dif__ablee add__tblle">
          <Table
            columns={[
              {
                title: translate("Title"),
                dataIndex: "name",
                key: "name",
              },
              {
                title: translate("Value"),
                dataIndex: "value",
                key: "value",
              },
            ]}
            dataSource={dynamicData}
            pagination={false}
            locale={{
              emptyText: (
                <span className="empty__tble">
                  <img src={EmptyIcon} />
                </span>
              ),
            }}
            className=" has_pag_btn no"
          />
        </div>
      </div>
    </>
  );
};

export default AdditionalInfos;
