import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Table, Tag, Dropdown, Menu, Button } from "antd";
import translate from "../../../shared/utils/translations";

const TicketsTable = ({
  isDrawer,
  setDrawer,
  setUserDetailsDrawer,
  setSelectedTicket,
  data,
  setSelectedUser,
}) => {
  let navigate = useNavigate();

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (item) => (
        <>
          <div className="column_info">ID</div>
          <div>{item}</div>
        </>
      ),
    },
    {
      title: translate("Status"),
      key: "status",
      dataIndex: "status",
      render: (status) => (
        <>
          <div className="column_info">{translate("Status")}</div>
          <Tag
            key={status}
            className={` ${
              status === "closed" ? " default__tag" : " error__tag"
            }`}
          >
            {status}
          </Tag>
        </>
      ),
    },
    {
      title: translate("Client"),
      dataIndex: "client",
      key: "client",
      render: (item, record) => (
        <>
          <div className="column_info">{translate("Client")}</div>
          <span
            className="cursorp client_d_link"
            onClick={() => {
              console.log("record", record);
              setSelectedUser({ id: record.user_id });
              setUserDetailsDrawer(true);
            }}
          >
            {item}
          </span>
        </>
      ),
    },
    {
      title: translate("Subject"),
      dataIndex: "subject",
      key: "subject",
      render: (item) => (
        <>
          <div className="column_info">{translate("Subject")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Topic"),
      dataIndex: "topic",
      key: "topic",
      render: (item) => (
        <>
          <div className="column_info">{translate("Topic")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Created date / Last updated"),
      dataIndex: "created_at",
      key: "created_at",
      render: (item) => (
        <>
          <div className="column_info">
            {translate("Created / Updated date")}
          </div>
          {item}
        </>
      ),
    },
    {
      title: translate("Priority"),
      key: "priority",
      dataIndex: "priority",
      render: (priority) => (
        <>
          <div className="column_info">{translate("Priority")}</div>
          <span
            className={`table__tag ${
              priority === 2
                ? " dark_tag"
                : priority === 1
                ? " light_tag"
                : " red__tag"
            }`}
          >
            {priority == 1
              ? translate("Low")
              : priority == 2
              ? translate("Medium")
              : priority == 3
              ? translate("High")
              : translate("Urgent")}
          </span>
        </>
      ),
    },
    {
      title: translate("Action"),
      key: "action",
      className: "table_actin",
      render: (text, record) => (
        <>
          <div className="column_info">{translate("Details")}</div>
          <Button
            onClick={() => {
              setSelectedTicket(record);
              setDrawer(true);
            }}
            icon={<FeatherIcon icon={"message-square"} />}
            className={"table_icon"}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          locale: {
            items_per_page: translate("Page"),
          },
          position: ["top", "bottom"],
        }}
        className="card_m"
      />
    </>
  );
};

export default TicketsTable;
