import React, { useState, useContext, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import translate from "../../shared/utils/translations";
import UserContext from "../../shared/utils/userContext";
import {
  SVGAdmin,
  SVGBalance,
  SVGTiers,
  SVGUsers,
  SVGSellers,
  SVGAsins,
  SVGAffiliates,
  SVGSupport,
  SVGReports,
  SVGEmail,
} from "../../common/other_elements/svg_icons";

const SidebarAdminDashboard = ({ isSidebar, setSidebar }) => {
  const [isSettings, setSettings] = useState(false);
  const location = useLocation();
  let navigate = useNavigate();

  const loggedInUser = useContext(UserContext);
  useEffect(() => {
    console.log(loggedInUser);
    if (loggedInUser) {
      if (loggedInUser?.role === "admin") {
        navigate("/admin-dashboard");
      } else {
        navigate("/client-dashboard");
      }
    }
  }, []);

  return (
    <>
      {isSidebar && (
        <div onClick={() => setSidebar(false)} className="sidebar_bgg"></div>
      )}

      <div className={`onex_wl__sidebar admn ${isSidebar && " actived"}`}>
        <div className="onex_wl__group">
          <div className="onex_wl__top">
            <div className="onex_wl__color bg__1"></div>
            {translate("Dashboard")}
          </div>
          <div className="onex_wl__menu">
            <NavLink
              className="onex_wl__link"
              to="/admin-dashboard"
              onClick={() => setSidebar(false)}
            >
              <SVGAdmin />
              {translate("Dashboard")}
            </NavLink>

            <NavLink
              className="onex_wl__link"
              to="/balance"
              onClick={() => setSidebar(false)}
            >
              <SVGBalance />
              {translate("Balance")}
            </NavLink>

            {/* <NavLink className="onex_wl__link" to="/clients" onClick={() => setSidebar(false)}>
              <svg className="icon icon-user">
                <use xlinkHref="#icon-user"></use>
              </svg>
              Clients Management
            </NavLink> */}
            <NavLink
              className="onex_wl__link"
              to="/admin-tiers"
              onClick={() => setSidebar(false)}
            >
              <SVGTiers />
              {translate("Tiers")}
            </NavLink>
            <NavLink
              className="onex_wl__link"
              to="/users"
              onClick={() => setSidebar(false)}
            >
              <SVGUsers />
              {translate("Users")}
            </NavLink>
            <NavLink
              className="onex_wl__link"
              to="/admin-sellers"
              onClick={() => setSidebar(false)}
            >
              <SVGSellers />
              {translate("Sellers")}
            </NavLink>
            <NavLink
              className="onex_wl__link"
              to="/admin-asin-lists"
              onClick={() => setSidebar(false)}
            >
              <SVGAsins />
              {translate("Asin lists")}
            </NavLink>
            <NavLink
              className="onex_wl__link"
              to="/admin-affiliates"
              onClick={() => setSidebar(false)}
            >
              <SVGAffiliates />
              Affiliates
            </NavLink>

            {/* <NavLink className="onex_wl__link" to="/discovery" onClick={() => setSidebar(false)}>
              <svg className="icon icon-sell">
                <use xlinkHref="#icon-sell"></use>
              </svg>
              Discovery
            </NavLink> */}
            <NavLink
              className="onex_wl__link"
              to="/admin-tickets"
              onClick={() => setSidebar(false)}
            >
              <svg className="icon icon-comment">
                <use xlinkHref="#icon-comment"></use>
              </svg>
              {translate("Tickets")}
            </NavLink>
            <NavLink
              className="onex_wl__link"
              to="/admin-support"
              onClick={() => setSidebar(false)}
            >
              <SVGSupport />
              {translate("Support")}
            </NavLink>
            <NavLink
              className="onex_wl__link"
              to="/admin-reports"
              onClick={() => setSidebar(false)}
            >
              <SVGReports />
              {translate("Reports")}
            </NavLink>
            <NavLink
              className="onex_wl__link"
              to="/versions"
              onClick={() => setSidebar(false)}
            >
              <svg className="icon icon-globe">
                <use xlinkHref="#icon-globe"></use>
              </svg>
              {translate("Versions")}
            </NavLink>
            {/* <NavLink
              className="onex_wl__link"
              to="/admin-contact"
              onClick={() => setSidebar(false)}
            >
              <svg className="icon icon-globe">
                <use xlinkHref="#icon-globe"></use>
              </svg>
              Contacts
            </NavLink> */}
            <NavLink
              className="onex_wl__link"
              to="/admin-email"
              onClick={() => setSidebar(false)}
            >
              <SVGEmail />
              Email
            </NavLink>
            <div
              className={`sidebar__item sidebar__item_dropdown wide ${
                isSettings && " active"
              }`}
            >
              <button
                className={`onex_wl__link js-popup-open openable_link ${
                  isSettings
                    ? " active"
                    : location.pathname === "/personal-settings"
                    ? " active"
                    : location.pathname === "/security-settings"
                    ? " active"
                    : location.pathname === "/notification-settings"
                    ? " active"
                    : location.pathname === "/integration-settings"
                    ? " active"
                    : " "
                }`}
                onClick={() => setSettings(!isSettings)}
              >
                <svg className="icon icon-cog">
                  <use xlinkHref="#icon-cog"></use>
                </svg>
                {translate("Settings")}
                <svg className="icon icon-arrow-down arrw">
                  <use xlinkHref="#icon-arrow-down"></use>
                </svg>
              </button>
              <div className="sidebar__body d_none">
                <NavLink
                  className="sidebar__link"
                  to="/personal-settings"
                  onClick={() => setSidebar(false)}
                >
                  {translate("Personal info")}
                  <svg className="icon icon-arrow-next">
                    <use xlinkHref="#icon-arrow-next"></use>
                  </svg>
                </NavLink>
                <NavLink
                  className="sidebar__link"
                  to="/security-settings"
                  onClick={() => setSidebar(false)}
                >
                  {translate("Security")}
                  <svg className="icon icon-arrow-next">
                    <use xlinkHref="#icon-arrow-next"></use>
                  </svg>
                </NavLink>
                {/* <NavLink
                  className="sidebar__link"
                  to="/notification-settings"
                  onClick={() => setSidebar(false)}
                >
                  Notification
                  <svg className="icon icon-arrow-next">
                    <use xlinkHref="#icon-arrow-next"></use>
                  </svg>
                </NavLink> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SidebarAdminDashboard;
