import React, { useEffect, useLayoutEffect, useState } from "react";
import { Drawer, Tooltip } from "antd";
import Input from "../../../common/form_elements/input";
import Select from "../../../common/form_elements/select";
import translate from "../../utils/translations";
import FeatherIcon from "feather-icons-react";
import { availableDomains, notify, requestHandler } from "../../utils/helpers";
import toast, { Toaster } from "react-hot-toast";
import Swal from "sweetalert2";

const CustomShippingSettingForm = ({
  customDrawerVisible,
  setCustomDrawerVisible,
  isSettingEditable,
  customWeightType,
  setCustomWeightType,
  customCurrency,
  setCustomCurrency,
  isBtnLoading,
  customData,
  setCustomData,
  saveCustom,
  handleInputChange,
  handleAdd,
  handleDelete,
  onEmptyCustomData,
}) => {
  const currencies = availableDomains?.map((domain) => {
    return domain?.currency;
  });

  const [showWarning, setShowWarning] = useState(false);
  useEffect(() => {
    if (
      customWeightType !== "Select.." &&
      customWeightType !== undefined &&
      customCurrency !== "Select.." &&
      customCurrency !== undefined
    ) {
      setShowWarning(false);
    } else {
      setShowWarning(true);
    }
  }, [customWeightType, customCurrency]);

  console.log(customWeightType + "   " + customCurrency);

  const [minValue, setMinValue] = useState("");
  const [maxValue, setMaxValue] = useState("");
  const [priceValue, setPriceValue] = useState("");
  const [isError, setIsError] = useState(false);

  const closeDrawer = async () => {
    if (
      customWeightType !== "Select.." &&
      customWeightType !== undefined &&
      customCurrency !== "Select.." &&
      customCurrency !== undefined &&
      customData.length > 0
    ) {
      setCustomDrawerVisible(false);
    } else {
      toast.error(
        translate(
          "Please add at least one shipping setting or select weight type and currency!"
        )
      );
      setCustomDrawerVisible(false);
      if (customData.length === 0) {
        onEmptyCustomData();
      }
    }
  };

  const handleSave = () => {
    if (customData.length === 0) {
      toast.error(
        translate(
          "No custom shipping settings added. Please add at least one setting before saving."
        )
      );
      return;
    }
    closeDrawer();
  };

  const [hiddenCards, setHiddenCards] = useState([]);

  useEffect(() => {
    const savedHiddenCards =
      JSON.parse(localStorage.getItem("hiddenShiipingInfoCard")) || [];
    setHiddenCards(savedHiddenCards);
  }, []);

  const handleClose = (cardId) => {
    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Are you sure about performing this action?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const newHiddenCards = [...hiddenCards, cardId];
        setHiddenCards(newHiddenCards);
        localStorage.setItem(
          "hiddenShiipingInfoCard",
          JSON.stringify(newHiddenCards)
        );
      }
    });
  };
  return (
    <>
      <Drawer
        title={translate("Custom Shipping Settings")}
        placement="right"
        onClose={() => {
          if (customData.length === 0) {
            onEmptyCustomData();
          }
          setCustomDrawerVisible(false);
        }}
        open={customDrawerVisible}
        width={"50%"}
        className="payment__-drw tkns"
      >
        <>
          <div
            className={`buy_tokens_detials ${
              isSettingEditable && " not__editable"
            }`}
          >
            {isSettingEditable && (
              <div className="warn__card hhn">
                <FeatherIcon icon={"alert-circle"} />
                {translate(
                  "The saved setting cannot be edited! Add new settings if necessary."
                )}
              </div>
            )}
            <div className="mang_setting_area tt ">
              <div className="setting__section">
                <div className="setting__category tbg">
                  {translate("Custom Shipping Pricing Values")}
                  <p>
                    {translate(
                      "Create your detailed shipping values. Also make sure that you enter the values ​​correctly and completely."
                    )}
                  </p>

                  {/* <div className="warn__card">
                    <FeatherIcon icon={"alert-circle"} />
                    {translate("In the currency of the target market!")}
                  </div> */}
                </div>
                <div className="setting__fieldset">
                  <div className="selcctt_Arrs">
                    <div className="field ggnb">
                      <div className="field__label">
                        {translate("Select Weight Type")}
                      </div>
                      <div className="field__wrap">
                        <div className="select__field">
                          <select
                            className="select nice-select app d_block shhpp_select"
                            onChange={(e) => {
                              setCustomWeightType(e.target.value);
                            }}
                          >
                            <option>{translate("Select..")}</option>
                            <option value="kg">kg</option>
                            <option value="libre">lb</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="field ggnb">
                      <div className="field__label">
                        {translate("Select Currency")}
                      </div>
                      <div className="field__wrap">
                        <div className="select__field">
                          <select
                            className="select nice-select app d_block shhpp_select"
                            onChange={(e) => {
                              setCustomCurrency(e.target.value);
                            }}
                          >
                            <option>{translate("Select..")}</option>
                            {currencies?.map((currency) => {
                              return (
                                <option value={currency} key={currency}>
                                  {currency}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ul className="new_ampt_fieldss">
                    {showWarning ? (
                      <div className="warn__card hhn ccv">
                        <FeatherIcon icon={"alert-circle"} />
                        {translate("Please select weight type and currency!")}
                      </div>
                    ) : (
                      <>
                        <li>
                          <div className="li__inffs">
                            <div className="wght_value">
                              {customWeightType} {translate("Valuett")}
                            </div>
                            <div className="currncy__Value">
                              {translate("Currency Value")}
                            </div>
                            <div className="actt__Value">
                              {translate("Actionn")}
                            </div>
                          </div>
                        </li>

                        {customData.map(
                          (item, index) => (
                            console.log("item", item),
                            (
                              <li>
                                <div key={index} className="new_ampt_colmn tt">
                                  <div className="lftt__ar">
                                    <input
                                      type="number"
                                      className="field__input"
                                      placeholder={translate("Min..")}
                                      value={item.weight_min}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "weight_min",
                                          e.target.value
                                        )
                                      }
                                    />
                                    <input
                                      type="number"
                                      className="field__input"
                                      placeholder={translate("Max..")}
                                      value={item.weight_max}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "weight_max",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>

                                  <div className="righht__ar">
                                    <input
                                      type="number"
                                      className="field__input"
                                      placeholder={translate("Price..")}
                                      value={item.price}
                                      onChange={(e) =>
                                        handleInputChange(
                                          index,
                                          "price",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>

                                  <div className="acct__ar">
                                    <Tooltip
                                      title={translate("Delete")}
                                      placement="right"
                                    >
                                      <button
                                        className="button-stroke button-small activity__button js-activity-button cc link"
                                        onClick={() => handleDelete(index)}
                                      >
                                        <FeatherIcon icon="minus" />
                                      </button>
                                    </Tooltip>
                                  </div>
                                </div>
                              </li>
                            )
                          )
                        )}
                        <li>
                          <div
                            key={customData?.length + 1}
                            className="new_ampt_colmn tt"
                          >
                            <div className="lftt__ar">
                              <input
                                type="number"
                                className={`field__input ${isError && "error"}`}
                                placeholder={translate("Min..")}
                                value={minValue}
                                onChange={(e) => {
                                  setMinValue(e.target.value);
                                  setIsError(false);
                                }}
                              />
                              <input
                                type="number"
                                className={`field__input ${isError && "error"}`}
                                placeholder={translate("Max..")}
                                value={maxValue}
                                onChange={(e) => {
                                  setMaxValue(e.target.value);
                                  setIsError(false);
                                }}
                              />
                            </div>

                            <div className="righht__ar">
                              <input
                                type="number"
                                className={`field__input ${isError && "error"}`}
                                placeholder={translate("Price..")}
                                value={priceValue}
                                onChange={(e) => {
                                  setPriceValue(e.target.value);
                                  setIsError(false);
                                }}
                              />
                            </div>

                            <div className="acct__ar">
                              <Tooltip
                                title={translate("Add")}
                                placement="right"
                              >
                                <button
                                  className="button-stroke button-small activity__button js-activity-button cc link"
                                  onClick={() => {
                                    let isHandleAddSuccess = handleAdd(
                                      minValue,
                                      maxValue,
                                      priceValue
                                    );
                                    console.log(
                                      "isHandleAddSuccess",
                                      isHandleAddSuccess
                                    );
                                    if (isHandleAddSuccess === true) {
                                      setMinValue("");
                                      setMaxValue("");
                                      setPriceValue("");
                                      setIsError(false);
                                    } else {
                                      notify(
                                        translate(
                                          "Overlapping or range is not valid"
                                        ),
                                        "error"
                                      );
                                      setIsError(true);
                                    }
                                  }}
                                >
                                  <FeatherIcon icon="plus" />
                                </button>
                              </Tooltip>
                            </div>
                          </div>
                        </li>

                        {["info", "warning1", "warning2"].map(
                          (cardId, index) =>
                            !hiddenCards.includes(cardId) && (
                              <li key={index}>
                                <div
                                  className={`custom__ship__inf ${
                                    cardId !== "info" ? "wrrn" : ""
                                  }`}
                                >
                                  <FeatherIcon
                                    icon={
                                      cardId === "info"
                                        ? "info"
                                        : "alert-circle"
                                    }
                                  />
                                  <div className="cntt_body">
                                    {cardId === "info" ? (
                                      <>
                                        <label>
                                          {translate("For Example:")}
                                        </label>
                                        <p>
                                          {translate(
                                            "If it is between Min 1KG - Max 3KG; 5 USD."
                                          )}
                                          <br />
                                          {translate(
                                            "If it is between Min 3KG - Max 5KG; 10 USD... etc."
                                          )}
                                        </p>
                                      </>
                                    ) : cardId === "warning1" ? (
                                      <>
                                        <label>{translate("Warning!")}</label>
                                        <p>
                                          {translate(
                                            "If the product weight exceeds the maximum value, the calculation will be based on the highest value."
                                          )}
                                        </p>
                                      </>
                                    ) : (
                                      <p>
                                        {translate(
                                          "If the selected currency is different, the system will automatically convert the amount to the target store's currency."
                                        )}
                                      </p>
                                    )}
                                  </div>
                                  <button onClick={() => handleClose(cardId)}>
                                    <FeatherIcon icon={"x-circle"} />
                                  </button>
                                </div>
                              </li>
                            )
                        )}
                      </>
                    )}
                  </ul>
                </div>
              </div>
            </div>

            {isSettingEditable ? (
              <></>
            ) : (
              <div className="drw__btm_actns">
                <button className="button setting__button" onClick={handleSave}>
                  {translate("Save")}{" "}
                  {isBtnLoading && <span className="btn-spinner"></span>}
                </button>
              </div>
            )}
          </div>
        </>
      </Drawer>
    </>
  );
};

export default CustomShippingSettingForm;
