import React from "react";
import { Tooltip } from "antd";
import { NavLink } from "react-router-dom";
import translate from "../../shared/utils/translations";

const Input = ({
  label,
  type,
  placeholder,
  name,
  id,
  customClass,
  hasIcon,
  fieldMap,
  onChange,
  value,
  isPass,
  hasInfo,
  hasInfoURL,
  disabled,
}) => {
  return (
    <>
      <div className={`field ${customClass && customClass}`}>
        <div className="field__label">
          {label}
          {hasInfo && (
            <Tooltip title={hasInfo} placement="right">
              {hasInfoURL ? (
                <a href={hasInfoURL} target="_blank" rel="nofollow">
                  <span className="icn_info"></span>
                </a>
              ) : (
                <span className="icn_info"></span>
              )}
            </Tooltip>
          )}
        </div>
        {isPass && (
          <div className="pass__info">
            <ul>
              <li>
                {translate("must consist of at least 8 characters long,")}
              </li>
              <li>{translate("and at least one number,")}</li>
              <li>{translate("and at least one lowercase letter.")}</li>
            </ul>
          </div>
        )}
        <div className="field__wrap">
          <input
            id={id}
            name={name}
            className="field__input"
            type={type}
            onChange={(e) => {
              console.log(e.target.value);
              onChange && onChange(e.target.value, name);
            }}
            placeholder={placeholder}
            required=""
            value={value}
            disabled={disabled ? "disabled" : false}
          />

          {hasIcon && (
            <div className="field__icon">
              <svg className={`icon ${hasIcon}`}>
                <use xlinkHref={`#${hasIcon}`}></use>
              </svg>
            </div>
          )}

          {fieldMap && <span className="field__map">{fieldMap}</span>}
        </div>
      </div>
    </>
  );
};

export default Input;
