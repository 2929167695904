import React, { useState, useEffect } from "react";

import { NavLink, useNavigate } from "react-router-dom";

import translate from "../../../shared/utils/translations";
import FeatherIcon from "feather-icons-react";
import { Table, Tag, Dropdown, Menu, Button, Tooltip } from "antd";
import EmptyIcon from "../../../../assets/img/empty.png";
import Swal from "sweetalert2";
import toast, { Toaster } from "react-hot-toast";

const HistoryTable = ({ data }) => {
  let navigate = useNavigate();
  const [isTableSort, setTableSort] = useState(() => {
    const savedSort = localStorage.getItem("tableSort");
    return savedSort !== null ? savedSort : "list";
  });
  useEffect(() => {
    localStorage.setItem("tableSort", isTableSort);
  }, [isTableSort]);

  const columns = [
    {
      title: translate("Image"),
      dataIndex: "image",
      key: "image",
      render: (src) => (
        <>
          <img className="product-photo" src={src} alt="" />
        </>
      ),
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      render: (item) => (
        <>
          <div className="column_info">ASIN</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Product Name"),
      dataIndex: "title",
      key: "title",
      render: (item) => (
        <>
          <div className="column_info">{translate("Product Name")}</div>
          {item}
        </>
      ),
    },

    {
      title: translate("Action"),
      key: "action",
      className: "table_actin",
      render: (text, record) => (
        <>
          <div className="column_info">{translate("Details")}</div>

          <div className="flex_table_buttons">
            <Tooltip
              title={translate("Show Product Based Info")}
              placement="left"
            >
              <NavLink
                className={"pbi__link table_icon"}
                to={`/product-based-info/${record.asin}`}
                target="_blank"
              >
                <FeatherIcon icon={"package"} />
              </NavLink>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  // const data = [
  //   {
  //     key: "1",
  //     img: "/assets/img/figure-2.png",
  //     asin: "##34534222",
  //     name: "Yellow Couch",
  //     salesRank: 3200,
  //     roi: 25,
  //     tags: ["Good"],
  //   },
  //   {
  //     key: "2",
  //     img: "/assets/img/figure-2.png",
  //     asin: "##34534000",
  //     name: "Green Couch",
  //     salesRank: 2888,
  //     roi: 25,
  //     tags: ["Good"],
  //   },
  //   {
  //     key: "3",
  //     img: "/assets/img/figure-2.png",
  //     asin: "##34534654",
  //     name: "Turquoise Seat",
  //     salesRank: 1200,
  //     roi: 25,
  //     tags: ["Good"],
  //   },
  //   {
  //     key: "4",
  //     img: "/assets/img/figure-2.png",
  //     asin: "##34534877",
  //     name: "Cyan Couch",
  //     salesRank: 8560,
  //     roi: 8,
  //     tags: ["Bad"],
  //   },
  //   {
  //     key: "5",
  //     img: "/assets/img/figure-2.png",
  //     asin: "##34534342",
  //     name: "Coffee Table",
  //     salesRank: 1150,
  //     roi: 75,
  //     tags: ["Very Good"],
  //   },
  //   {
  //     key: "7",
  //     img: "/assets/img/figure-2.png",
  //     asin: "##34534456",
  //     name: "Red Couch",
  //     salesRank: 9500,
  //     roi: 5,
  //     tags: ["Bad"],
  //   },
  // ];

  return (
    <>
      <div
        className={`dif__ablee ${isTableSort === "list" ? " " : " block_m"}`}
      >
        <div className="merged___area">
          <div className="table__sortn">
            <span
              onClick={() => setTableSort("list")}
              className={`${isTableSort === "list" ? "active" : ""}`}
            >
              <FeatherIcon icon={"list"} />
            </span>
            <span
              onClick={() => setTableSort("grid")}
              className={`${isTableSort === "grid" ? "active" : ""}`}
            >
              <FeatherIcon icon={"grid"} />
            </span>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            locale: {
              items_per_page: translate("Page"),
            },
            position: ["topRight", "bottomRight"],
          }}
          locale={{
            emptyText: (
              <span className="empty__tble">
                <img src={EmptyIcon} />
              </span>
            ),
          }}
          className="mob_bts has_pag_btn"
        />
      </div>
    </>
  );
};

export default HistoryTable;
