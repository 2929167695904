import React from "react";
import translate from "../utils/translations";

const Language = ({ isLanguage, setLanguage, setSelectedLanguage, isSelectedLanguage }) => {
  return (
    <div className={`header__item header__item_language js-header-item ${isLanguage && " active"}`}>
      <button className="header__head js-header-head" onClick={() => setLanguage(!isLanguage)}>
        <svg className="icon icon-globe">
          <use xlinkHref="#icon-globe"></use>
        </svg>
        {isSelectedLanguage === "TR" ? "TR" : isSelectedLanguage === "DE" ? "DE" : "EN"}
      </button>
      <div className="header__body js-header-body">
        <div className="header__list">
          <a
            className={`header__box cursorp  ${isSelectedLanguage === "EN" && " active"}`}
            onClick={() => {
              setSelectedLanguage("EN");
              setLanguage(false);
            }}
          >
            <div className="header__category">EN</div>
            <div className="header__country">United States</div>
          </a>
          <a
            className={`header__box cursorp  ${isSelectedLanguage === "TR" && " active"}`}
            onClick={() => {
              setSelectedLanguage("TR");
              setLanguage(false);
            }}
          >
            <div className="header__category">TR</div>
            <div className="header__country">Türkiye</div>
          </a>
          <a
            className={`header__box cursorp  ${isSelectedLanguage === "DE" && " active"}`}
            onClick={() => {
              setSelectedLanguage("DE");
              setLanguage(false);
            }}
          >
            <div className="header__category">DE</div>
            <div className="header__country">Deutschland</div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Language;
