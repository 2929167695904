import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import translate from "../../../shared/utils/translations";
import ModalVideo from "react-modal-video";

const UpdateExtension = ({ setPage }) => {
  useEffect(() => {
    setPage("landing");
    currentLanguage(localStorage.getItem("lang") || 'EN');
  });

  const [currentLang, currentLanguage] = useState({});

  const [isOpen, setOpen] = useState(true);
  // const [isOverflow, setOverflow] = useState(null);

  // useEffect(() => {
  //   const queryParams = new URLSearchParams(window.location.search);
  //   const extensionInstalled = queryParams.get("update");
  //   let originalOverflow = document.body.style.overflow;
  //   setOverflow(originalOverflow);

  //   if (extensionInstalled) {
  //     setOpen(true);
  //     document.body.style.overflow = "hidden";
  //   }

  //   return () => {
  //     document.body.style.overflow = originalOverflow;
  //   };
  // }, []);

  // const resetOverflow = () => {
  //   document.body.style.overflow = isOverflow;
  // };
  return (
    <>
      <Helmet>
        <title>{translate("How to update Chrome extensions?")}</title>
        <meta name="description" content={translate("How to update Chrome extensions?")} />
      </Helmet>

      <ModalVideo channel="youtube"
        youtube={{ mute: 0, autoplay: 0 }}
        videoId={"oifTTb9vx6Y"} isOpen={isOpen} onClose={() => setOpen(false)} />

      <div className="outer__inner landng">
        <div className="section article">
          <div className="section article__body">
            <div className="article__center center">
              <div className="article__head">
                <h1 className="article__title h1">{translate("How to update Chrome extensions?")}</h1>
                {/* <div className="breadcrumbs">
                <div className="breadcrumbs__item">
                  <NavLink className="breadcrumbs__link" to="/blog">
                    Blog
                  </NavLink>
                </div>
                <div className="breadcrumbs__item">How to update Chrome extensions</div>
              </div> */}
              </div>
              <div className="player mmn" onClick={() => setOpen(true)}>
                <div className="player__preview tt">
                  {currentLang === "EN" ? <img src="/assets/img/update_1.jpg" alt="" /> : <></>}
                  {currentLang === "TR" ? <img src="/assets/img/update_2.jpg" alt="" /> : <></>}
                  {currentLang === "DE" ? <img src="/assets/img/update_3.jpg" alt="" /> : <></>}
                </div>
              </div>
            </div>
          </div>
          <div className="article__wrap">
            <div className="article__center center">
              <div className="content">
                <p>
                  {translate(
                    "It's crucial to keep all your Chrome extensions, including Kepicker, up-to-date for optimal functionality. However, automatic updates can sometimes take up to 5 hours or not run at all. Don't worry, there's a solution! To ensure that you have the latest version of all your extensions, you need to update them manually."
                  )}
                </p>
                <p>
                  {currentLang === "EN" ? (
                    <ul className="cs__ul">
                      <li>
                        Launch the <strong>Chrome browser</strong>.
                      </li>
                      <li>
                        Click on the <strong>three-dot menu</strong> button. It’s in the top-right corner.
                      </li>
                      <li>
                        Highlight the <strong>Extensions</strong> option.
                      </li>
                      <li>
                        Select <strong>Manage Extensions</strong>.
                      </li>
                      <li>
                        Once on the Extensions page, you should see a <strong>Developer mode</strong> in the top-right corner.
                        Toggle it on.
                      </li>
                      <li>
                        A new set of options will show up. Click on <strong>Update</strong>.
                      </li>
                      <li>
                        You will get a status notification in the lower-left corner. It will let you know when your Chrome
                        extensions have finished updating.
                      </li>
                    </ul>
                  ) : (
                    <></>
                  )}
                  {currentLang === "TR" ? (
                    <ul className="cs__ul">
                      <li>
                        <strong>Chrome tarayıcıyı</strong> başlatın.
                      </li>
                      <li>
                        Sağ üst köşede bulunan <strong>üç nokta menü</strong> düğmesine tıklayın.
                      </li>
                      <li>
                        <strong>Uzantılar</strong> seçeneğini belirleyin.
                      </li>
                      <li>
                        <strong>Uzantıları Yönet</strong>'i seçin.
                      </li>
                      <li>
                        Uzantılar sayfasında, sağ üst köşede <strong>Geliştirici modunu</strong> görmelisiniz. Onu açın.
                      </li>
                      <li>
                        Yeni bir seçenekler seti belirecek (sol üst köşede). <strong>Güncelle</strong>'ye tıklayın.
                      </li>
                      <li>
                        Sol alt köşede bir durum bildirimi alacaksınız, tamamlandı diyene kadar bekleyiniz. Chrome
                        eklentilerinizin güncelleme işleminin tamamlandığına dair bildirim aldığınızda işlem tamamlanmış olacak..
                      </li>
                    </ul>
                  ) : (
                    <></>
                  )}
                  {currentLang === "DE" ? (
                    <ul className="cs__ul">
                      <li>
                        Starten Sie den <strong>Chrome-Browser</strong>.
                      </li>
                      <li>
                        Klicken Sie auf die Schaltfläche mit den <strong>drei Punkten</strong>. Sie befindet sich in der oberen
                        rechten Ecke.
                      </li>
                      <li>
                        Heben Sie die Option <strong>Erweiterungen</strong> hervor.
                      </li>
                      <li>
                        Wählen Sie <strong>Erweiterungen verwalten</strong>.
                      </li>
                      <li>
                        Auf der Seite Erweiterungen sollten Sie in der oberen rechten Ecke einen <strong>Entwicklermodus</strong>{" "}
                        sehen. Schalten Sie ihn ein.
                      </li>
                      <li>
                        Ein neuer Satz von Optionen wird angezeigt. Klicken Sie auf <strong>Aktualisieren</strong>.
                      </li>
                      <li>
                        Sie erhalten eine Statusbenachrichtigung in der unteren linken Ecke. Sie informiert Sie, wenn Ihre
                        Chrome-Erweiterungen fertig aktualisiert wurden.
                      </li>
                    </ul>
                  ) : (
                    <></>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateExtension;
