import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import TicketsTable from "./_tickets_table";
import TicketsDrawer from "./_tickets_drawer";
import { requestHandler } from "../../../shared/utils/helpers";
import { useNavigate } from "react-router-dom";
import translate from "../../../shared/utils/translations";

const Tickets = ({ setPage, setUserDetailsDrawer = { setUserDetailsDrawer }, selectedUser, setSelectedUser }) => {
  const navigate = useNavigate();
  useEffect(() => {
    setPage("dashboard-admin");
    localStorage.setItem("dashboard-type", "dashboard-admin");
  });
  const [data, setData] = useState([]);
  const [buffer, setBuffer] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState({});
  const [isItem, setItem] = useState("all");
  const [isDrawer, setDrawer] = useState(false);
  const [query, setQuery] = useState("");
  const [priority, setPriority] = useState("all");

  const getTickets = async () => {
    const res = await requestHandler("get", "/admin/tickets", null, true, navigate, "/login");
    console.log(res);
    if (res && res.data) {
      setData(res.data);
      setBuffer(res.data);
    }
  };
  useEffect(() => {
    getTickets();
  }, []);

  useEffect(() => {
    if (query) {
      const filteredData = data.filter((item) => {
        return (
          item?.subject?.toLowerCase()?.includes(query?.toLowerCase()) ||
          item?.client?.toLowerCase()?.includes(query?.toLowerCase()) ||
          item?.id?.toString()?.includes(query?.toLowerCase()) ||
          item?.status?.toLowerCase()?.includes(query?.toLowerCase()) ||
          item?.topic?.toLowerCase()?.includes(query?.toLowerCase()) ||
          item?.description?.toLowerCase()?.includes(query?.toLowerCase())
        );
      });
      setBuffer(filteredData);
    } else {
      setBuffer(data);
    }
  }, [query]);
  useEffect(() => {
    if (isItem === "all") {
      setBuffer(data);
    } else if (isItem === "open") {
      const filteredData = data.filter((item) => {
        return item?.status?.toLowerCase() === "open";
      });
      setBuffer(filteredData);
    } else if (isItem === "closed") {
      const filteredData = data.filter((item) => {
        return item?.status?.toLowerCase() === "closed";
      });
      setBuffer(filteredData);
    }
  }, [isItem]);
  useEffect(() => {
    if (priority === "all") {
      setBuffer(data);
    } else {
      const filteredData = data.filter((item) => {
        return item?.priority?.toString() === priority;
      });
      setBuffer(filteredData);
    }
  }, [priority]);
  useEffect(() => {
    // setSelectedTicket();
  }, [isDrawer]);
  return (
    <>
      <TicketsDrawer isDrawer={isDrawer} setDrawer={setDrawer} selectedTicket={selectedTicket} />

      <div className="outer__inner">
        <div className="onex_wl bg_white">
          <div className="onex_wl__wrapper">
            <div className="upload__head">
              <h2 className="upload__title h2">{translate("Support / Tickets")}</h2>
              {/* <a
                    className="button-stroke button-small upload__button"
                    href="#!"
                  >
                    More filter
                  </a> */}
            </div>
            <div className="onex_wl__list">
              <div className="keys__enabled d_block">
                <div className="keys__list">
                  <div className="keys__item ss uu">
                    <div className="activity__top ss">
                      <div className="nav">
                        <button
                          className={`nav__link cursorp ${isItem === "all" ? " active" : " "}`}
                          onClick={() => setItem("all")}
                        >
                          {translate("All")}
                        </button>
                        <button
                          className={`nav__link cursorp ${isItem === "open" ? " active" : " "}`}
                          onClick={() => setItem("open")}
                        >
                          {translate("Open")}
                        </button>
                        <button
                          className={`nav__link cursorp ${isItem === "closed" ? " active" : " "}`}
                          onClick={() => setItem("closed")}
                        >
                          {translate("Closed")}
                        </button>
                      </div>

                      <div className="right_items">
                        <form className="activity__form">
                          <input
                            className="activity__input"
                            type="text"
                            name="search"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            placeholder={translate("Search")}
                            required=""
                          />
                          <button className="activity__result">
                            <svg className="icon icon-search">
                              <use xlinkHref="#icon-search"></use>
                            </svg>
                          </button>
                        </form>
                        <div className="onex_ts__select">
                          <div className="select__field">
                            <select
                              value={priority}
                              onChange={(e) => setPriority(e.target.value)}
                              className="select nice-select  app d_block"
                            >
                              <option value="all">{translate("All")}</option>
                              <option value="1">{translate("Low")}</option>
                              <option value="2">{translate("Medium")}</option>
                              <option value="3">{translate("High")}</option>
                              <option value="4">{translate("Urgent")}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <TicketsTable
                      isDrawer={isDrawer}
                      setDrawer={setDrawer}
                      setUserDetailsDrawer={setUserDetailsDrawer}
                      setSelectedTicket={setSelectedTicket}
                      data={buffer}
                      setSelectedUser={setSelectedUser}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tickets;
