const initialState = {
  isLogged: false,
  isDarkMode: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOGGED":
      return {
        ...state,
        isLogged: action.payload,
      };
    case "SET_DARKMODE":
      return {
        ...state,
        isDarkMode: action.payload,
      };
    default:
      return state;
  }
};
