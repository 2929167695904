import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Table, Tag, Dropdown, Menu, Button } from "antd";
import translate from "../../../shared/utils/translations";

const SellersTable = ({ addProtection, removeProtection, data }) => {
  const columns = [
    {
      title: translate("User email"),
      key: "email",
      dataIndex: "email",
      render: (text) => (
        <>
          <div className="column_info">{translate("User email")}</div>
          {text}
        </>
      ),
    },
    {
      title: translate("Server"),
      key: "server",
      dataIndex: "server",
      render: (text) => (
        <>
          <div className="column_info">{translate("Server")}</div>
          {text}
        </>
      ),
    },
    {
      title: translate("Seller ID"),
      key: "seller_id",
      dataIndex: "seller_id",
      render: (text) => (
        <>
          <div className="column_info">{translate("Seller ID")}</div>
          {text}
        </>
      ),
    },
    {
      title: translate("Is Protected?"),
      dataIndex: "is_protected",
      key: "is_protected",
      render: (item) => (
        <>
          <div className="column_info">{translate("Is Protected?")}</div>
          <Tag className={item ? "default__tag" : "error__tag"}>
          {item ? translate("Yes") : translate("No")}
          </Tag>
        </>
      ),
    },
    {
      title: translate("Request Count"),
      dataIndex: "request_count",
      key: "request_count",
      render: (item) => (
        <>
          <div className="column_info">{translate("Request Count")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Protected Count"),
      dataIndex: "protected_count",
      key: "protected_count",
      render: (item) => (
        <>
          <div className="column_info">{translate("Protected Count")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Action"),
      key: "action",
      className: "table_actin",
      render: (text, record) => (
        <>
          {record.is_protected ? (
            <>
            <div className="column_info">{translate("Details")}</div>
            <div className="d_flex_center">
              <a
                className="button-stroke button-small upload__button"
                href="#!"
                onClick={() => removeProtection(record.id)}
              >
                {translate("Remove Protection")}
              </a>
            </div>
            </>
          ) : (
            <>
            <div className="column_info">{translate("Details")}</div>
            <div className="d_flex_center">
              <a
                className="button-stroke button-small upload__button"
                href="#!"
                onClick={() => addProtection(record.id)}
              >
                {translate("Add Protection")}
              </a>
            </div>
            </>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          locale: {
            items_per_page: translate("Page"),
          },
          position: ["top", "bottom"],
        }}
        className="card_m"
      />
    </>
  );
};

export default SellersTable;
