import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  useReducer,
} from "react";
import { Drawer, Tooltip, Checkbox } from "antd";
import Input from "../../../../common/form_elements/input";
import Select from "../../../../common/form_elements/select";
import translate from "../../../utils/translations";
import FeatherIcon from "feather-icons-react";
import styled from "styled-components";
import { MenuOutlined } from "@ant-design/icons";
import { DndProvider, useDrop, useDrag } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { makeReadable, undoMakeReadable } from "../../../utils/helpers";
import toast, { Toaster } from "react-hot-toast";
const base_columns = [
  { id: 1, text: "image", checked: true },
  { id: 2, text: "asin", checked: true },
  { id: 3, text: "product_link", checked: true },
  { id: 4, text: "title", checked: true },
  { id: 5, text: "has_discount", checked: true },
  { id: 6, text: "base_domain_price", checked: true },
  { id: 7, text: "base_domain_converted_price", checked: true },
  { id: 8, text: "target_domain_price", checked: true },
  { id: 9, text: "shipping_cost", checked: true },
  { id: 10, text: "vat", checked: true },
  { id: 11, text: "fee", checked: true },
  { id: 12, text: "roi", checked: true },
  { id: 13, text: "is_hazmat", checked: true },
  { id: 14, text: "profit_per_item", checked: true },
  { id: 15, text: "is_amazon_seller", checked: true },
  { id: 16, text: "total_seller_count", checked: true },
  { id: 17, text: "fba_seller_count", checked: true },
  { id: 18, text: "fbm_seller_count", checked: true },
  { id: 19, text: "sales_rank", checked: true },
  { id: 20, text: "sales_rank_category", checked: true },
  { id: 21, text: "brand", checked: true },
  { id: 22, text: "binding", checked: true },
  { id: 23, text: "product_group", checked: true },
  { id: 24, text: "product_type_name", checked: true },
  { id: 25, text: "bsr_current", checked: true },
  { id: 26, text: "bsr_30_days_avg", checked: true },
  { id: 27, text: "bsr_90_days_avg", checked: true },
  { id: 28, text: "bsr_180_days_avg", checked: true },
  { id: 29, text: "bsr_365_days_avg", checked: true },
  { id: 30, text: "bsr_drops_30_days_avg", checked: true },
  { id: 31, text: "bsr_drops_90_days_avg", checked: true },
  { id: 32, text: "bsr_drops_180_days_avg", checked: true },
  { id: 33, text: "bsr_drops_365_days_avg", checked: true },
  { id: 34, text: "ean", checked: true },
  { id: 35, text: "upc", checked: true },
  { id: 36, text: "variation_count", checked: true },
  { id: 37, text: "price_amazon_0", checked: true },
  { id: 38, text: "price_new_0", checked: true },
  { id: 39, text: "price_amazon_30", checked: true },
  { id: 40, text: "price_new_30", checked: true },
  { id: 41, text: "price_amazon_90", checked: true },
  { id: 42, text: "price_new_90", checked: true },
  { id: 43, text: "price_amazon_180", checked: true },
  { id: 44, text: "price_new_180", checked: true },
  { id: 45, text: "price_amazon_365", checked: true },
  { id: 46, text: "price_new_365", checked: true },
  { id: 47, text: "color", checked: true },
  { id: 48, text: "item_dimensions", checked: true },
  { id: 49, text: "package_dimensions", checked: true },
];
const ItemTypes = { CARD: "card" };

const cardStyle = {
  width: "100%",
  border: "1px dashed gray",
  padding: "0.5rem 1rem",
  marginBottom: ".5rem",
  backgroundColor: "white",
  cursor: "move",
  opacity: 1, // Default opacity
};

const Card = ({ id, text, index, moveCard, checked, toggleChecked }) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Only move when the item is dragged over itself
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      item.index = hoverIndex; // Note: This is a mutation!
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.5 : 1;

  drag(drop(ref));

  const handleCheckboxChange = (e) => {
    toggleChecked(id);
  };
  return (
    <div className="drag__inntm" ref={ref} style={{ ...cardStyle, opacity }}>
      <Checkbox checked={checked} onChange={handleCheckboxChange}>
        {text ? makeReadable(text) : "NaN"}
      </Checkbox>
      <MenuOutlined />
    </div>
  );
};

const columnReducer = (state, action) => {
  console.log("Current state:", state);
  console.log("Action:", action);

  switch (action.type) {
    case "MOVE_COLUMN":
      const { from, to } = action.payload;
      const updatedColumns = [...state];
      const [removed] = updatedColumns.splice(from, 1);
      updatedColumns.splice(to, 0, removed);
      return updatedColumns;
    case "ADD_COLUMN":
      return [{ ...action.payload, checked: true }, ...state];
    case "TOGGLE_CHECKED":
      const newState = state.map((column) =>
        column.id === action.payload.id
          ? { ...column, checked: !column.checked }
          : column
      );
      return newState;
    case "RENDER_COLUMNS":
      const d = {
        id: 14,
        user_id: 2,
        preference_name: "test 6",
        is_comparison: true,
        is_default: true,
        columns: [
          {
            id: 151,
            sheet_id: 14,
            column_name: "Asin",
            column_index: 0,
          },
          {
            id: 152,
            sheet_id: 14,
            column_name: "Title",
            column_index: 1,
          },
          {
            id: 153,
            sheet_id: 14,
            column_name: "Base Domain Price",
            column_index: 3,
          },
          {
            id: 154,
            sheet_id: 14,
            column_name: "Has Discount",
            column_index: 2,
          },
        ],
      };
      //update checked status based on the columns from the selected setting
      const newColumns = action?.payload?.columns?.map((column, index) => {
        return {
          id: index,
          text: undoMakeReadable(column.column_name),
          checked: true,
        };
      });

      return newColumns;
    case "RENDER_INIT":
      return base_columns;
    case "SELECT_ALL":
      return state?.map((column) => ({ ...column, checked: true }));
    case "DESELECT_ALL":
      return state?.map((column) => ({ ...column, checked: false }));
    default:
      return state;
  }
};

const SettingForm = ({
  isSettingForm,
  setSettingForm,
  isBtnLoading,
  setBtnLoading,
  isSettingEditable,
  selectedSetting,
  saveSheetPreferences,
}) => {
  const [columns, dispatch] = useReducer(columnReducer, base_columns);

  useEffect(() => {
    if (selectedSetting) {
      console.log("Selected setting:", selectedSetting);
      const d = {
        id: 14,
        user_id: 2,
        preference_name: "test 6",
        is_comparison: true,
        is_default: true,
        columns: [
          {
            id: 151,
            sheet_id: 14,
            column_name: "Asin",
            column_index: 0,
          },
          {
            id: 152,
            sheet_id: 14,
            column_name: "Title",
            column_index: 1,
          },
          {
            id: 153,
            sheet_id: 14,
            column_name: "Base Domain Price",
            column_index: 3,
          },
          {
            id: 154,
            sheet_id: 14,
            column_name: "Has Discount",
            column_index: 2,
          },
        ],
      };

      if (selectedSetting?.columns?.length > 0) {
        dispatch({ type: "RENDER_COLUMNS", payload: selectedSetting });
      } else {
        dispatch({ type: "RENDER_INIT" });
      }
    }
  }, [selectedSetting]);

  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => setInputValue(e.target.value);

  const handleAddColumn = () => {
    if (!inputValue.trim()) {
      toast.error(translate("Please enter a column name before adding!"));
    } else {
      const newColumn = { id: Date.now().toString(), text: inputValue };
      dispatch({ type: "ADD_COLUMN", payload: newColumn });
      setInputValue("");
    }
  };

  const toggleChecked = (id) => {
    dispatch({
      type: "TOGGLE_CHECKED",
      payload: { id },
    });
  };

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    dispatch({
      type: "MOVE_COLUMN",
      payload: { from: dragIndex, to: hoverIndex },
    });
  }, []);

  const [settingName, setSettingName] = useState("");

  const handleSettingNameChange = (e) => {
    setSettingName(e.target.value);
  };
  const handleSaveSettings = () => {
    if (!settingName.trim()) {
      toast.error("Please enter a setting name before saving.");
    } else if (!columns.some((column) => column.checked)) {
      toast.error("Please select at least one column to save the setting.");
    } else {
      toast.success("Setting saved successfully!");
      setSettingForm(false);
      saveSheetPreferences(
        settingName,
        false,
        columns
          ?.filter((i) => i.checked)
          .map((i, index) => {
            return { column: makeReadable(i.text), index: index };
          })
      );
    }
  };

  return (
    <>
      <Drawer
        title={translate("Manage Setting")}
        placement="right"
        onClose={() => setSettingForm(false)}
        open={isSettingForm}
        width={"50%"}
        className="payment__-drw tkns sheett_drw"
      >
        <>
          <div
            className={`buy_tokens_detials gg ${
              isSettingEditable && " not__editable"
            }`}
          >
            {isSettingEditable && (
              <div className="warn__card hhn">
                <FeatherIcon icon={"alert-circle"} />
                {translate(
                  "The saved setting cannot be edited! Add new settings if necessary."
                )}
              </div>
            )}
            <div className="mang_setting_area tt">
              <div className="setting__section">
                <div className="setting__category tbg">
                  {translate("Sheet Settings")}
                </div>
                <div className="setting__row bggh">
                  <div className="field">
                    <div className="field__label">
                      {translate("Setting Name")}
                    </div>
                    <div className="field__wrap">
                      <input
                        id="settingname_id"
                        name="name"
                        className="field__input"
                        type="text"
                        placeholder={translate("Custom setting etc.")}
                        value={settingName}
                        onChange={handleSettingNameChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="setting__fieldset">
                  <p className="inf__shet">
                    {translate(
                      "Remove the columns you do not want to see in the sheet, edit the column order with drag and drop. If you want, you can also add a empty column to add comments to your sheet."
                    )}
                  </p>
                  <div className="new_ampt_colmnhh">
                    <div className="new_ampt_colmn tt">
                      <input
                        type="text"
                        className="field__input"
                        placeholder={translate("Column name..")}
                        value={inputValue}
                        onChange={handleInputChange}
                      ></input>
                      <Tooltip
                        title={translate("Add New Empty Column")}
                        placement="right"
                      >
                        <button
                          onClick={handleAddColumn}
                          className="button-stroke button-small activity__button js-activity-button cc link"
                        >
                          <FeatherIcon icon={"plus"} />
                        </button>
                      </Tooltip>
                    </div>
                  </div>

                  <div className="columss__list">
                    {/* SELECT ALL BUTTON */}
                    <div className="select__all">
                      <button
                        className="button-stroke button-small activity__button js-activity-button cc link"
                        onClick={() => dispatch({ type: "SELECT_ALL" })}
                      >
                        {translate("Select All")}
                      </button>
                      <button
                        className="button-stroke button-small activity__button js-activity-button cc link"
                        onClick={() => dispatch({ type: "DESELECT_ALL" })}
                      >
                        {translate("Deselect All")}
                      </button>
                    </div>
                    <DndProvider backend={HTML5Backend}>
                      {columns?.map((column, index) => (
                        <Card
                          key={column.id}
                          index={index}
                          text={column.text}
                          moveCard={moveCard}
                          checked={column.checked}
                          toggleChecked={toggleChecked}
                          id={column.id}
                        />
                      ))}
                    </DndProvider>
                  </div>
                </div>
              </div>
            </div>

            {isSettingEditable ? (
              <></>
            ) : (
              <div className="drw__btm_actns">
                <button
                  className="button setting__button"
                  onClick={handleSaveSettings}
                >
                  {translate("Save")}
                </button>
              </div>
            )}
          </div>
        </>
      </Drawer>
    </>
  );
};

export default SettingForm;
