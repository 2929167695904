import React, { useEffect } from "react";
import { Button, Drawer } from "antd";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
  CardElement,
} from "@stripe/react-stripe-js";
import { countryCodes, requestHandler } from "../../../shared/utils/helpers";
import { useNavigate } from "react-router-dom";
import translate from "../../../shared/utils/translations";
import Input from "../../../common/form_elements/input";
import FeatherIcon from "feather-icons-react";
const PaymentDrawer = ({ isPaymentDrawer, setPaymentDrawer, refresh }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState({});
  const [state, setState] = useState(true);
  const [isTop, setTop] = useState(true);

  const [errorMessage, setErrorMessage] = useState(null);
  const onChange = (value, name) => {
    setFormData({ ...formData, [name]: value });
  };
  const checkInputs = () => {
    console.log("formData", formData);
    if (
      !formData?.billing_name ||
      !formData?.billing_address ||
      !formData?.billing_zip_code ||
      !formData?.billing_state ||
      !formData?.billing_city ||
      !formData?.billing_country
    ) {
      toast.error(translate("Please fill all fields!"));
      return;
    } //also check if inputs are not including special characters
    else {
      setState(false);
    }
  };

  const renderBillingDetails = () => {
    return (
      <>
        <div className="setting__section">
          <div className="box__title_area">
            <FeatherIcon icon="map-pin" width="15px" height="15px" />
            <h5>{translate("Billing Address")}</h5>
          </div>
          <div className="setting__fieldset ghh">
            <Input
              id="address"
              name="billing_name"
              label={translate("Person/Company Name")}
              type="text"
              placeholder={translate("Person/Company Name")}
              onChange={onChange}
              value={formData?.billing_name}
            />
            <Input
              id="address"
              name="billing_address"
              label={translate("Address Details")}
              type="text"
              placeholder={translate("Enter your address")}
              onChange={onChange}
              value={formData?.billing_address}
            />

            <div className="setting__row">
              <Input
                id="city"
                name="billing_zip_code"
                label={translate("Zip Code")}
                type="text"
                placeholder={translate("Zip Code")}
                onChange={onChange}
                value={formData?.billing_zip_code}
              />
              <Input
                id="state"
                name="billing_state"
                label={translate("State/Province/Region")}
                type="text"
                placeholder={translate("State/Province/Region")}
                onChange={onChange}
                value={formData?.billing_state}
              />
              {/* add country and city too*/}
            </div>
            <div className="setting__row">
              <Input
                id="city"
                name="billing_city"
                label={translate("City/Town")}
                type="text"
                placeholder={translate("City/Town")}
                onChange={onChange}
                value={formData?.billing_city}
              />
              <div className="field">
                <div className="field__label">
                  {translate("Select Country")}
                </div>
                <select
                  value={formData?.billing_country}
                  onChange={(e) => onChange(e.target.value, "billing_country")}
                  className="field__input"
                >
                  <option>{translate("Select Country")}</option>
                  {countryCodes.map((country) => {
                    return (
                      <option key={country.code} value={country.code}>
                        {country.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              {/* <Input
                id="Country"
                name="billing_country"
                label={translate("Country")}
                type="text"
                placeholder={translate("Country")}
                onChange={onChange}
                value={formData?.billing_country}
              /> */}
              {/* add country and city too*/}
            </div>
            <div className="setting__controls fxdd">
              <button
                className="button setting__button"
                onClick={() => {
                  checkInputs();
                }}
              >
                {translate("Confirm and continue")}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    console.log(
      "process.env.REACT_APP_STRIPE_PUBLIC_KEY 11",
      process.env.REACT_APP_STRIPE_PUBLIC_KEY
    );
    const [isBtnLoading, setBtnLoading] = useState(false);

    const handleNewPaymentMethod = async () => {
      setBtnLoading(true);

      if (!elements || !stripe) {
        setBtnLoading(false);
        return;
      }

      const { error: submitError } = await elements.submit();
      if (submitError) {
        setBtnLoading(false);
        console.log("submitError", submitError);
        return;
      }

      // Create the PaymentMethod using the details collected by the Payment Element
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement("card"),
        billing_details: {
          name: formData?.billing_name,
          email: user?.email,
          address: {
            line1: formData?.billing_address,
            postal_code: formData?.billing_zip_code,
            state: formData?.billing_state,
            city: formData?.billing_city,
            country: formData?.billing_country,
          },
        },
      });

      if (error) {
        // This point is only reached if there's an immediate error when
        // creating the PaymentMethod. Show the error to your customer (for example, payment details incomplete)
        console.log("[error]", error);
        setErrorMessage(error.message);
        setBtnLoading(false);
        return;
      }

      // Send paymentMethodId to your backend
      console.log(
        "payload from add new card",
        {
          paymentMethodId: paymentMethod.id,
          customer: user?.stripe_customer_id,
        },
        user
      );
      const res = await requestHandler(
        "post",
        "/payment/addPaymentMethod",
        {
          paymentMethodId: paymentMethod.id,
          customerId: user?.stripe_customer_id,
        },
        true,
        navigate,
        "/"
      );

      if (res?.data?.errorType) {
        setErrorMessage(res?.data?.errorMessage);
        setBtnLoading(false);
        return;
      } else {
        console.log("payment method created", res?.data);
        setBtnLoading(false);
        refresh();
        setPaymentDrawer(false);
      }
    };

    return (
      <form
        onSubmit={async (event) => {
          event.preventDefault();
          handleNewPaymentMethod();
        }}
      >
        <div className="box__title_area">
          <FeatherIcon icon="credit-card" width="15px" height="15px" />
          <h5>{translate("Payment Method")}</h5>
        </div>
        <CardElement
          options={{
            style: {
              base: {
                fontSize: "16px",
                color: "#424770",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#9e2146",
              },
            },
          }}
        />

        <div className="pay__footer">
          <div className="btn_Sss">
            <button
              className="button upload__button cursorp"
              type="submit"
              disabled={!stripe || !elements || isBtnLoading}
            >
              {translate("Add New Card")}{" "}
              {isBtnLoading && <span className="btn-spinner"></span>}
            </button>
          </div>
          {/* Show error message to your customers */}
          {errorMessage && <div className="pay_mesg">{errorMessage}</div>}
        </div>
      </form>
    );
  };

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  const getUser = async () => {
    const res = await requestHandler(
      "get",
      "/profile",
      null,
      true,
      navigate,
      "/"
    );
    console.log("res", res);
    setUser(res?.data);
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <Drawer
        title={translate("Add New Card")}
        placement="right"
        onClose={() => {
          setPaymentDrawer(false);
          setErrorMessage(null);
        }}
        open={isPaymentDrawer}
        width={"50%"}
        className="payment__-drw ffg"
      >
        <>
          <div className="drw__area" style={{ marginTop: "25px" }}>
            {renderBillingDetails()}
            <Elements stripe={stripePromise}>
              <CheckoutForm />
            </Elements>
          </div>
          {/* <div className="bottom__img">
            <img src="/assets/img/payment.jpg"></img>
          </div> */}
        </>
      </Drawer>
    </>
  );
};

export default PaymentDrawer;
