import React from "react";
import FeatherIcon from "feather-icons-react";
import translate from "../../../../shared/utils/translations";
import { Tooltip, Progress } from "antd";

const ProductMetrics = ({
  mainData,
  keepaData,
  additionalData,
  targetOffersData,
}) => {
  const trendDirection = (value90, value30) => {
    const drop90 = parseFloat(value90) / 90;
    const drop30 = parseFloat(value30) / 30;
    if (drop90 > drop30) {
      return "down";
    } else if (drop90 < drop30) {
      return "up";
    } else {
      return "up";
    }
  };

  return (
    <>
      <div className="product__roi_info">
        <div className="fees__area">
          <div className="fees_item">
            <h4>{translate("Sales Rank")}</h4>
            <span>{targetOffersData?.salesRank}</span>
          </div>
          <div className="fees_item">
            <h4>{translate("BSR Drops (30/90/180)")}</h4>
            <span>
              {keepaData?.BSR30 +
                "/" +
                keepaData?.BSR90 +
                "/" +
                keepaData?.BSR180}
              {trendDirection(keepaData?.BSR90, keepaData?.BSR30) === "down" ? (
                <Tooltip title={"Downward Trend"} placement="top">
                  <img
                    className="trend__up"
                    src="/assets/img/icons8-chart-arrow-descent-64.png"
                  />
                </Tooltip>
              ) : (
                <Tooltip title={"Upward Trend"} placement="top">
                  <img
                    className="trend__up"
                    src="/assets/img/icons8-chart-arrow-rise-64.png"
                  />
                </Tooltip>
              )}
            </span>
          </div>

          <div className="fees_item">
            <h4>{translate("Monthly Sold")}</h4>
            <span className="inf__spn">
              {keepaData?.monthlySold
                ? `${keepaData?.monthlySold} +`
                : keepaData?.salesRankDrops30 > 0
                ? `${keepaData?.salesRankDrops30} +`
                : `N/A`}
              <Tooltip
                title={translate("This data comes from Keepa!")}
                placement="top"
              >
                <FeatherIcon icon={"info"} />
              </Tooltip>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductMetrics;
