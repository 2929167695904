import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Table, Tag, Dropdown, Menu, Button } from "antd";
import translate from "../../../shared/utils/translations";

const VersionsTable = ({ data = [], deleteVersion }) => {
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (item) => (
        <>
          <div className="column_info">ID</div>
          <div> {item}</div>
        </>
      ),
    },
    {
      title: translate("Version"),
      key: "version",
      dataIndex: "version",
      render: (text) => (
        <>
          <div className="column_info">{translate("Version")}</div>
          {text}
        </>
      ),
    },
    {
      title: translate("Description"),
      dataIndex: "description",
      key: "description",
      className: "long__inf",
      render: (item) => (
        <>
          <div className="column_info">{translate("Description")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Update URL"),
      dataIndex: "update_url",
      key: "update_url",
      render: (item) => (
        <>
          <div className="column_info">{translate("Update URL")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Mandatory"),
      dataIndex: "update_required",
      key: "update_required",
      render: (item) => (
        <>
          <div className="column_info">{translate("Mandatory")}</div>
          {item ? "Yes" : "No"}
        </>
      ),
    },
    {
      title: translate("Created date / Last updated"),
      dataIndex: "created_at",
      key: "created_at",
      render: (item) => (
        <>
          <div className="column_info">
            {translate("Created / Updated date")}
          </div>
          {item}
        </>
      ),
    },
    {
      title: translate("Action"),
      key: "action",
      width: "120px",
      className: "table_actin",
      render: (text, record) => (
        <>
          <div className="column_info">{translate("Details")}</div>
          <div className="d_flex_center">
            <a
              className="button-stroke button-small upload__button"
              href="#!"
              onClick={() => deleteVersion(record.id)}
            >
              {translate("Delete")}
            </a>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          locale: {
            items_per_page: translate("Page"),
          },
          position: ["top", "bottom"],
        }}
        className="card_m"
      />
    </>
  );
};

export default VersionsTable;
