import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Tooltip, Modal, Button } from "antd";
import FeatherIcon from "feather-icons-react";
import "react-slideshow-image/dist/styles.css";
import translate from "../../../shared/utils/translations";
import Swal from "sweetalert2";
import Select from "../../../common/form_elements/select";
import Input from "../../../common/form_elements/input";
import TrackingMarketplacesModal from "./_tracking_marketplaces";

const TrackingModal = ({
  isTrackingModal,
  setTrackingModal,
  handleApplyTracking,
  handleCancelTracking,
}) => {
  const trackItems = [
    { label: translate("BuyBox Price"), value: "44 USD" },
    { label: translate("Lowest FBA Price"), value: "38 USD" },
    { label: translate("Lowest FBM Price"), value: "34 USD" },
    { label: translate("Sales Rank"), value: "4589" },
  ];

  const [isMarketplacesModal, setMarketplacesModal] = useState(false);
  return (
    <>
      <Modal
        title={translate("Product Tracking")}
        width={"auto"}
        centered
        open={isTrackingModal}
        onOk={() => setTrackingModal(false)}
        onCancel={() => setTrackingModal(false)}
        footer={[
          <Button key="back" onClick={handleCancelTracking}>
            {translate("Remove Tracking!")}
          </Button>,
          <Button key="submit" type="primary" onClick={handleApplyTracking}>
            {translate("Apply")}
          </Button>,
        ]}
      >
        <div className="coppyy_Asin_Area">
          <div className="setting__fieldset">
            <div className="selected__marketplacess">
              <div className="sltc_mm_headr">
                <label>{translate("Selected Marketplaces")}</label>
                <Tooltip
                  title={translate("Add / Remove Marketplaces")}
                  placement="left"
                >
                  <Button
                    onClick={() => setMarketplacesModal(true)}
                    icon={<FeatherIcon icon="settings" />}
                    className={"table_icon"}
                  />
                </Tooltip>
              </div>

              <div className="slctdd__mms">
                <span>com</span>
                <span>ca</span>
              </div>
            </div>
          </div>

          <div className="Inputss__areaa">
            {trackItems.map((item, index) => (
              <div className="Inputss__itemm" key={index}>
                <div className="setting__fieldset">
                  <div className="track__info">
                    <div className="track__h">{item.label}</div>
                    <div className="track__v">{item.value}</div>
                  </div>
                </div>

                <div className="setting__fieldset">
                  <div className="together__inputs">
                    <label className="infod__lbll">
                      {translate("Value")}{" "}
                      <Tooltip
                        title={
                          <div>
                            •{" "}
                            {translate(
                              "Fixed = Below or above the value I entered; For example, under or over $1500."
                            )}
                            <br />•{" "}
                            {translate(
                              "Percentage (%) = Percentage decrease or increase, For example, if it falls 10% below or rises above the current value."
                            )}
                            <br />
                            <br />
                            <small>
                              {translate("Specify the value to track here.")}
                            </small>
                          </div>
                        }
                        placement="top"
                      >
                        <span className="inff__text gg">
                          <FeatherIcon icon={"help-circle"} />
                        </span>
                      </Tooltip>
                    </label>
                    <div className="setting__bbty">
                      <Select
                        options={[translate("Fixed"), "%"]}
                        name="track_per_fix"
                      />
                      <Input
                        id="tracking-value"
                        name="track_value"
                        type="number"
                        placeholder={translate("10 etc.")}
                      />
                    </div>
                  </div>
                </div>

                <div className="setting__fieldset">
                  <div className="setting__row gheer">
                    <label className="infod__lbll">
                      {translate("Logic")}{" "}
                      <Tooltip
                        title={
                          <div>
                            •{" "}
                            {translate(
                              "More = If it exceeds the value I give."
                            )}
                            <br />•{" "}
                            {translate(
                              "Less = If it goes below the value I gave."
                            )}
                            <br />
                            <br />
                            <small>
                              {translate(
                                "Select whether it will be below or above the value you entered in the 'Value' field."
                              )}
                            </small>
                          </div>
                        }
                        placement="top"
                      >
                        <span className="inff__text gg">
                          <FeatherIcon icon={"help-circle"} />
                        </span>
                      </Tooltip>
                    </label>
                    <Select
                      options={[translate("Less"), translate("More")]}
                      name="track_logic"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="setting__fieldset tyyun">
            <div className="setting__row">
              <Select
                label={translate("Track for:")}
                options={[
                  translate("1 week"),
                  translate("2 weeks"),
                  translate("1 month"),
                  translate("3 months"),
                  translate("6 months"),
                ]}
                name="track_logic"
              />
            </div>
          </div>
        </div>
      </Modal>

      <TrackingMarketplacesModal
        isMarketplacesModal={isMarketplacesModal}
        setMarketplacesModal={setMarketplacesModal}
      />
    </>
  );
};

export default TrackingModal;
