import React from "react";

const LoaderPage = ({ isPage }) => {
  return (
    <div
      className={`page-_loader ${
        isPage === "dashboard-admin" || isPage === "dashboard-client"
          ? " opt"
          : " "
      }`}
    >
      {/* <div className="bt-spinner"></div> */}
      <div className="kpc__loader"></div>
    </div>
  );
};
export default LoaderPage;
