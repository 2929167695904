import React, { useState } from "react";
import { Tooltip, Button, Modal, Table } from "antd";
import translate from "../../../../shared/utils/translations";
import EmptyIcon from "../../../../../assets/img/empty.png";

const SellersDataModal = ({
  isSellerModal,
  setSellerModal,
  setAsinFetcher,
}) => {
  const columns = [
    {
      title: translate("Is Buybox"),
      dataIndex: "is_buybox",
      key: "is_buybox",
      render: (item) => (
        <>
          <div className="column_info">{translate("Is Buybox")}</div>
          {item ? (
            <span className="table__tag is_true">{translate("True")}</span>
          ) : (
            <span className="table__tag is_false">{translate("False")}</span>
          )}
        </>
      ),
    },
    {
      title: translate("Seller Name"),
      dataIndex: "seller",
      key: "seller",
      render: (item) => <>{item}</>,
    },
    {
      title: translate("Product Type"),
      dataIndex: "product_type",
      key: "product_type",
      render: (item) => (
        <>
          <div className="column_info">{translate("Product Type")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Total Price"),
      dataIndex: "total_price",
      key: "total_price",
      render: (item) => (
        <>
          <div className="column_info">{translate("Total Price")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Seller Type"),
      dataIndex: "is_fba",
      key: "is_fba",
      render: (item) => (
        <>
          <div className="column_info">{translate("Seller Type")}</div>
          {item ? (
            <span className="table__tag is_true">{translate("True")}</span>
          ) : (
            <span className="table__tag is_false">{translate("False")}</span>
          )}
        </>
      ),
    },
    {
      title: translate("Seller URL"),
      dataIndex: "seller_url",
      key: "seller_url",
      render: (item) => (
        <>
          <div className="column_info">{translate("Seller URL")}</div>
          <a
            className="table__lnk"
            rel="noopener noreferrer"
            target="_blank"
            href={item}
          >
            {translate("Seller URL")}
          </a>
        </>
      ),
    },
    {
      title: translate("Store Front URL"),
      dataIndex: "seller_store_url",
      key: "seller_store_url",
      render: (item) => (
        <>
          <div className="column_info">{translate("Store Front URL")}</div>
          <a
            className="table__lnk"
            rel="noopener noreferrer"
            target="_blank"
            href={item}
          >
            {translate("Store Front URL")}
          </a>
        </>
      ),
    },
    {
      title: translate("Fetch Seller Asins"),
      dataIndex: "fetch_seller_asins",
      key: "fetch_seller_asins",
      render: (item) => (
        <>
          <div className="column_info">{translate("Fetch Seller Asins")}</div>
          <a
            onClick={() => setAsinFetcher(true)}
            className="table__lnk"
            rel="noopener noreferrer"
            href="#!"
          >
            {translate("Fetch Seller Asins")}
          </a>
        </>
      ),
    },
    {
      title: translate("Ratings"),
      dataIndex: "ratings",
      key: "ratings",
      render: (item) => (
        <>
          <div className="column_info">{translate("Ratings")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Positive Feedbacks"),
      dataIndex: "positive_feedbacks",
      key: "positive_feedbacks",
      render: (item) => (
        <>
          <div className="column_info">{translate("Positive Feedbacks")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Delivery Time"),
      dataIndex: "delivery_time",
      key: "delivery_time",
      render: (item) => (
        <>
          <div className="column_info">{translate("Delivery Time")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Price With Delivery"),
      dataIndex: "price_with_delivery",
      key: "price_with_delivery",
      render: (item) => (
        <>
          <div className="column_info">{translate("Price With Delivery")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Percentage"),
      dataIndex: "percentage",
      key: "percentage",
      render: (item) => (
        <>
          <div className="column_info">{translate("Percentage")}</div>
          {item}%
        </>
      ),
    },
  ];

  const data = [
    {
      key: 1,
      is_buybox: true,
      seller: "Snailax",
      product_type: "New",
      total_price: "99.82 CAD",
      is_fba: true,
      seller_url: "https://www.amazon.com",
      seller_store_url: "https://www.amazon.com",
      ratings: 12313,
      positive_feedbacks: 100,
      delivery_time: "Friday, April 26",
      price_with_delivery: "49.07 CAD",
      percentage: 90,
    },
    {
      key: 2,
      is_buybox: false,
      seller: "Snailax",
      product_type: "New",
      total_price: "99.82 CAD",
      is_fba: false,
      seller_url: "https://www.amazon.com",
      seller_store_url: "https://www.amazon.com",
      ratings: 12313,
      positive_feedbacks: 100,
      delivery_time: "Friday, April 26",
      price_with_delivery: "49.07 CAD",
      percentage: 90,
    },
  ];

  return (
    <>
      <Modal
        visible={isSellerModal}
        title="Sellers Data"
        onCancel={() => setSellerModal(false)}
        className="sellers__mdoall"
        centered
      >
        <div className="minii__tablee dif__ablee add__tblle">
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            locale={{
              emptyText: (
                <span className="empty__tble">
                  <img src={EmptyIcon} />
                </span>
              ),
            }}
            className=" has_pag_btn no"
          />
        </div>
      </Modal>
    </>
  );
};

export default SellersDataModal;
