import React, { useEffect, useState } from "react";
import Lottie from "react-lottie-player";
import FeatherIcon from "feather-icons-react";
import { Helmet } from "react-helmet";
import translate from "../../shared/utils/translations";

const About = ({ setPage }) => {
  useEffect(() => {
    setPage("landing");
  });

  return (
    <>
      <Helmet>
        <title>{translate("About")}</title>
        <meta
          name="description"
          content={translate(
            "Big plus to your productivity with Kepicker tools - Kepicker simplifies product research and It helps improve Amazon marketing strategies."
          )}
        />
      </Helmet>
      <div className="outer__inner landng">
        <div className="section app main about">
          <div className="main__center center">
            <div className="main__wrap app">
              <div className="main__text colord">
                {translate(
                  "Find the most profitable products to sell on Amazon."
                )}
              </div>
              <h1 className="main__title app h1">
                {translate("Kepicker simplifies product research and")} <br />
                {translate("It helps improve marketing strategies.")}
              </h1>
              <p className="about_lined_txt">
                {translate("Big plus to your productivity")}
              </p>
            </div>
          </div>
          <div className="section onex_w hero__img abt">
            <img
              src="/assets/img/amazon_product_research_tools.jpg"
              alt="Amazon Product Research"
            ></img>
          </div>
        </div>

        <div className="section onex_a">
          <div className="onex_a__center center">
            <div className="onex_a__head">
              <h2 className="onex_a__title h2">
                {translate("Why use Kepicker?")}
              </h2>
              <div className="onex_a__info info">
                {translate("Deliver the results that matter most!")}
              </div>
            </div>
            <div className="onex_a__wrapper">
              <div className="onex_a__slider js-slider-onex_a">
                <div className="onex_a__item">
                  <figure className="contact-us-hero-card-illo have tt hh">
                    <img
                      src="/assets/img/t01.png"
                      loading="lazy"
                      alt="gradient card background"
                      className="contact-us-hero-card-illo-image"
                    />
                  </figure>
                  <div className="onex_a__details">
                    <h3 className="onex_a__onex_h3">
                      {translate("Empowered Productivity")}
                    </h3>
                    <div className="onex_a__subtitle">
                      {translate(
                        "Get rid of complexity with its modern & user-friendly interface."
                      )}
                    </div>
                  </div>
                </div>
                <div className="onex_a__item">
                  <figure className="contact-us-hero-card-illo have tt gg">
                    <img
                      src="/assets/img/t02.png"
                      loading="lazy"
                      alt="gradient card background"
                      className="contact-us-hero-card-illo-image"
                    />
                  </figure>
                  <div className="onex_a__details">
                    <h3 className="onex_a__onex_h3">
                      {translate("Faster Than Others")}
                    </h3>
                    <div className="onex_a__subtitle">
                      {translate("It works faster than similar extensions.")}
                    </div>
                  </div>
                </div>
                <div className="onex_a__item">
                  <figure className="contact-us-hero-card-illo have tt">
                    <img
                      src="/assets/img/t03.png"
                      loading="lazy"
                      alt="gradient card background"
                      className="contact-us-hero-card-illo-image"
                    />
                  </figure>
                  <div className="onex_a__details">
                    <h3 className="onex_a__onex_h3">
                      {translate("Save Time")}
                    </h3>
                    <div className="onex_a__subtitle">
                      {translate(
                        "It will help you find the right products as soon as possible."
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section onex_a abt__us">
          <div className="onex_a__center center">
            <div className="onex_a__head">
              <h2 className="onex_a__title h2">{translate("About Us")}</h2>
              <p>
                {translate(
                  "At the heart of our enterprise lies a dedicated team of seasoned software developers, accomplished designers, and Amazon aficionados. Our overarching mission is to furnish Amazon users with a comprehensive, all-in-one solution meticulously crafted to address their diverse requirements."
                )}{" "}
              </p>

              <p>
                {translate(
                  "We remain unwavering in our commitment to the continual refinement and expansion of our software platform."
                )}
              </p>

              <p>
                {translate(
                  "In its initial phase, our system empowers users with the ability to effortlessly extract their product listings, facilitating in-depth comparisons with various Amazon marketplaces. We provide vital information, including real-time stock status, accurate pricing details, and other pivotal product particulars."
                )}{" "}
              </p>

              <p>
                {translate(
                  "Looking forward, our strategic vision entails harnessing state-of-the-art artificial intelligence integrated within our software, coupled with the immense potential of big data. This innovation will enable us to guide newcomers to the Amazon landscape who may be navigating uncharted waters in the realm of plugins. Our overarching objective is to offer curated recommendations for high-performing products, simplifying the wealth accumulation process while elevating the Amazon marketplace to unparalleled heights."
                )}{" "}
              </p>

              <p>
                {translate(
                  "We take immense pride in delivering a feature-rich experience that remains competitively priced in contrast to rival offerings. During the beta phase, should any unforeseen issues arise, please do not hesitate to contact us. We stand ready to promptly address any losses incurred due to errors, prioritizing your experience above all else."
                )}{" "}
              </p>

              <p>
                {translate(
                  "We extend our sincerest well-wishes to all, as you embark on a prosperous and rewarding journey in the world of online sales."
                )}
              </p>
            </div>
            <div className="img__abt">
              <img
                alt="Amazon Extension"
                src="/assets/img/amazon_extension.jpg"
              ></img>
            </div>
          </div>
        </div>

        <div className="section onex_t">
          <div className="onex_t__center center">
            <div className="onex_t__head">
              <h2 className="onex_t__title h2">
                {translate(
                  "Start growing your Amazon account today - increase leads."
                )}
              </h2>
              <div className="onex_t__info info">
                {translate(
                  "Have more time to focus on other things. Our extension is designed for everyone. From beginners to advanced. No need to be an expert to get more earnings."
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="section onex_p promo_b">
          <div className="onex_p__center center">
            <div className="onex_p__inner">
              <div className="onex_p__head">
                <h2 className="onex_p__title h2">
                  {translate("Follow Us on Social Media")}
                </h2>
                <div className="onex_p__info info">
                  {translate("Stay up to date with us!")}
                </div>
              </div>
              <div className="onex_p__wrapper">
                <a
                  target="_blank"
                  href="https://twitter.com/kepicker_"
                  className="social_link twitter"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24">
                    <path d="M24.075 4.325c-.9.375-1.8.675-2.85.75 1.05-.6 1.8-1.575 2.175-2.7-.975.6-2.025.975-3.15 1.2C19.35 2.6 18.075 2 16.725 2c-2.7 0-4.95 2.175-4.95 4.95 0 .375.075.75.15 1.125C7.8 7.85 4.2 5.9 1.725 2.9a4.72 4.72 0 0 0-.675 2.475c0 1.725.9 3.225 2.175 4.125-.825 0-1.575-.225-2.25-.6v.075c0 2.4 1.725 4.35 3.975 4.8-.45.075-.825.15-1.275.15-.3 0-.6 0-.9-.075.6 1.95 2.475 3.375 4.575 3.45-1.65 1.35-3.825 2.1-6.15 2.1-.375 0-.825 0-1.2-.075 2.25 1.35 4.875 2.175 7.65 2.175 9.075 0 14.025-7.5 14.025-14.025v-.6c.9-.75 1.725-1.575 2.4-2.55z"></path>
                  </svg>
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/kepicker"
                  className="social_link instagram"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24">
                    <path d="M12 15.8a3.8 3.8 0 1 0 0-7.6 3.8 3.8 0 0 0-3.291 5.7A3.8 3.8 0 0 0 12 15.8h0zM16.749.6h-9.5A6.66 6.66 0 0 0 .6 7.251v9.5A6.66 6.66 0 0 0 7.251 23.4h9.5a6.66 6.66 0 0 0 6.649-6.651v-9.5A6.66 6.66 0 0 0 16.749.6zM12 17.7A5.71 5.71 0 0 1 6.3 12 5.71 5.71 0 0 1 12 6.3a5.71 5.71 0 0 1 5.7 5.7 5.71 5.71 0 0 1-5.7 5.7zm6.65-11.4a.95.95 0 0 1 0-1.9.95.95 0 1 1 0 1.9z"></path>
                  </svg>
                </a>
                <a
                  target="_blank"
                  href="https://www.facebook.com/kepicker.software/"
                  className="social_link facebook"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24">
                    <path d="M9.347 23.25V13.5H6V9h3.347V5.857c0-3.423 2.167-5.107 5.221-5.107 1.463 0 2.72.109 3.086.158v3.577l-2.118.001c-1.661 0-2.036.789-2.036 1.947V9H18l-1.5 4.5h-3v9.75H9.347z"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
