import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Table, Tag, Dropdown, Menu, Button } from "antd";
import translate from "../../../shared/utils/translations";

const ClientsTable = ({ setNewDrawer, setUserDetailsDrawer }) => {
  let navigate = useNavigate();

  const columns = [
    {
      title: "ID",
      dataIndex: "key",
      key: "key",
      render: (key) => "##" + key,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (item) => (
        <>
          <div className="column_info">Name</div>
          {item}
        </>
      ),
    },
    {
      title: "Surname",
      dataIndex: "surname",
      key: "surname",
      render: (item) => (
        <>
          <div className="column_info">Surname</div>
          {item}
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (item) => (
        <>
          <div className="column_info">Email</div>
          {item}
        </>
      ),
    },
    {
      title: "Plan",
      dataIndex: "plan",
      render: (item) => (
        <>
          <div className="column_info">Plan</div>
          <span className="table__tag dark_tag">{item}</span>
        </>
      ),
    },
    {
      title: "Date of Registration",
      dataIndex: "dRegistration",
      key: "dRegistration",
      render: (item) => (
        <>
          <div className="column_info">Date of Registration</div>
          {item}
        </>
      ),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => (
        <>
          <div className="column_info">Status</div>
          {status.map((tag) => {
            // let color = "#52c41a";
            // if (tag === "Done") {
            //   color = "#820014";
            // }
            // if (tag === "Active") {
            //   color = "#237804";
            // }
            return (
              <Tag
                key={tag}
                className={` ${
                  tag === "Active" ? " default__tag" : " error__tag"
                }`}
              >
                {tag}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "120px",
      className: "table_actin",
      render: (text, record) => (
        <>
          <div className="column_info">Details</div>
          <div className="d_flex_center">
            <Button
              onClick={() => setUserDetailsDrawer(true)}
              icon={<FeatherIcon icon={"info"} />}
              className={"table_icon"}
            />
            <Button
              onClick={() => setNewDrawer(true)}
              icon={<FeatherIcon icon={"edit"} />}
              className={"table_icon"}
            />
          </div>
        </>
      ),
    },
  ];

  const data = [
    {
      key: "342",
      name: "John",
      surname: "Doe",
      email: "john@doe.com",
      plan: ["Basic"],
      dRegistration: "16.01.2023 21:23:05",
      status: ["Active"],
    },
    {
      key: "269",
      name: "John",
      surname: "Doe",
      email: "john@doe.com",
      plan: ["Pro"],
      dRegistration: "16.01.2023 21:23:05",
      status: ["Inactive"],
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          locale: {
            items_per_page: translate("Page"),
          },
          position: ["top", "bottom"],
        }}
        className="card_m"
      />
    </>
  );
};

export default ClientsTable;
