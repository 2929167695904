import React, { useState } from "react";

const FaqItem = ({ key, f_n, f_t, f_d }) => {
  const [open, setOPen] = useState(false);
  return (
    <>
      <div className={`faq1__item ${open && " active"}`} key={key}>
        <div className="faq1__head" onClick={() => setOPen(!open)}>
          <div className="faq1__number">{f_n}</div>
          <div className="faq1__subtitle">{f_t}</div>
          <div className="faq1__arrow">
            <svg className="icon icon-arrow-bottom">
              <use xlinkHref="#icon-arrow-bottom"></use>
            </svg>
          </div>
        </div>
        <div className={`faq1__body ${open && " d_block"}`}>{f_d}</div>
      </div>
    </>
  );
};

export default FaqItem;
