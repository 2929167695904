import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import * as settingsActions from "../../hooks/actions";
import { NavLink, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Input from "../../common/form_elements/input";
import Select from "../../common/form_elements/select";
import { signIn } from "../../shared/utils/auth";
import { Helmet } from "react-helmet";
import UserContext from "../../shared/utils/userContext";
import FeatherIcon from "feather-icons-react";
import translate from "../../shared/utils/translations";

const Login = ({ setLogged, setPage, isLogged }) => {
  useEffect(() => {
    setPage("auth");
  });
  let navigate = useNavigate();

  const [formData, setFormData] = useState({});

  const [isType, setType] = useState("email");

  const [isShowPass, setShowPass] = useState(false);

  const [isBtnLoading, setBtnLoading] = useState(false);
  const submit = async () => {
    setBtnLoading(true);

    const user = await signIn(
      formData.email,
      formData.password,
      formData.email
    );
    console.log("user", user);
    if (!user) {
      notify(translate("Something went wrong, please try again!"), "error");
      setBtnLoading(false);
      return;
    } else {
      setLogged(true);
      console.log(
        "user",
        user,
        user?.signInUserSession?.idToken?.payload?.role
      );
      notify(translate("Login successful, please wait.."), "success");
      //refresh page

      if (user?.signInUserSession?.idToken?.payload?.role === "admin") {
        navigate("/admin-dashboard");
      } else {
        navigate("/client-dashboard");
      }
      window.location.reload();
    }
  };
  const onChange = (e, name) => {
    setFormData({ ...formData, [name]: e });
  };

  const notify = (message, type) =>
    type === "error" ? toast.error(message) : toast.success(message);
  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const loggedInUser = useContext(UserContext);
  useEffect(() => {
    console.log(loggedInUser);
    if (loggedInUser) {
      if (loggedInUser?.role === "admin") {
        navigate("/admin-dashboard");
      } else {
        navigate("/client-dashboard");
      }
    }
  }, []);

  useEffect(() => {
    const handleScrollToTop = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener("load", handleScrollToTop);

    return () => window.removeEventListener("load", handleScrollToTop);
  }, []);

  return (
    <>
      <Helmet>
        <title>{translate("Login")}</title>
        <meta
          name="description"
          content={translate(
            "Sign in to Kepicker - The all-in-one finding and comparing products platform that saves you time + money. Let's do it. Let's find!"
          )}
        />
      </Helmet>
      <div className="outer__inner auth">
        <div className="login">
          <div className="login__col bgb d_none">
            <NavLink className="login__logo" to="/home">
              <img src="/assets/img/logo-dark4.png" alt="" />
              <img src="/assets/img/logo-dark4.png" alt="" />
            </NavLink>
          </div>
          <div className="login__col">
            <NavLink className="login__logo" to="/home">
              <img src="/assets/img/logo-dark4.png" alt="" />
              <img src="/assets/img/logo-dark4.png" alt="" />
            </NavLink>
            <div className="login__head">
              {translate("Don’t have an account?")}
              <NavLink className="login__link" to="/signup">
                {translate("Sign up for free")}
              </NavLink>
            </div>
            <div className="login__wrap">
              <div className="entry">
                <div className="entry__top">
                  <h3 className="entry__title h3">
                    {translate("Sign in to Kepicker")}
                  </h3>
                  {/* <div className="entry__info">
                    Please ensure you are visiting the correct url.
                  </div>
                  <div className="entry__correct">
                    <svg className="icon icon-lock">
                      <use xlinkHref="#icon-lock"></use>
                    </svg>
                    <div className="entry__url">
                      https://<span>accounts.kepicker.com/login</span>
                    </div>
                  </div> */}
                </div>
                <div className="entry__form">
                  <div className="entry__tabs js-tabs">
                    <div className="entry__container">
                      <div className="entry__item js-tabs-item d_block">
                        <Input
                          id="email"
                          name="email"
                          label={translate("Email")}
                          type="email"
                          placeholder={translate("Email address")}
                          onChange={onChange}
                        />
                      </div>
                    </div>

                    <div className="field field_view">
                      <div className="field__label">
                        {translate("Password")}
                      </div>
                      <div className="field__wrap">
                        <input
                          className="field__input"
                          type={isShowPass ? "text" : "password"}
                          name="password"
                          placeholder={translate("Password")}
                          onChange={(e) => onChange(e.target.value, "password")}
                          required=""
                        />
                        <button
                          className="field__view"
                          onClick={() => setShowPass(!isShowPass)}
                        >
                          <FeatherIcon
                            icon={isShowPass ? "eye-off" : "eye"}
                            width="20px"
                            height="20px"
                          />
                        </button>
                      </div>
                    </div>
                    <div className="entry__foot">
                      {/* <button className="entry__scan" type="button">
                        Scan to login
                      </button> */}
                      <NavLink className="entry__link" to="/forgot">
                        {translate("Forgot password?")}
                      </NavLink>
                    </div>
                    <a
                      className="button entry__button cursorp"
                      onClick={submit}
                    >
                      {translate("Login")}{" "}
                      {isBtnLoading && <span className="btn-spinner"></span>}
                    </a>

                    <div style={{ marginTop: "25px" }} className="login__head">
                      {translate("Don’t have an account?")}
                      <NavLink className="login__link" to="/signup">
                        {translate("Sign up for free")}
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <img
            alt="auth-world-screen"
            src="https://partners.poweredbypercent.com/13c4f10f45b00276.png"
            className="AuthBackground_image__IINQD"
          ></img>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLogged: state.settings.isLogged,
});

const mapDispatchToProps = (dispatch) => ({
  setLogged: (e) => dispatch(settingsActions.setLogged(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
