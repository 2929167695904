import React, { useEffect } from "react";
import { Button, Drawer } from "antd";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { requestHandler } from "../utils/helpers";
import { useNavigate } from "react-router-dom";
import translate from "../utils/translations";
import Input from "../../common/form_elements/input";
import FeatherIcon from "feather-icons-react";

const PaymentDrawer = ({ isPaymentDrawer, setPaymentDrawer, paymentData }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState({});
  const [state, setState] = useState(true);
  const [isTop, setTop] = useState(true);

  const [errorMessage, setErrorMessage] = useState(null);
  const onChange = (value, name) => {
    setFormData({ ...formData, [name]: value });
  };
  const checkInputs = () => {
    console.log("formData", formData);
    if (
      !formData?.billing_name ||
      !formData?.billing_address ||
      !formData?.billing_zip_code ||
      !formData?.billing_state ||
      !formData?.billing_city ||
      !formData?.billing_country
    ) {
      toast.error(translate("Please fill all fields!"));
      return;
    } //also check if inputs are not including special characters
    else {
      setState(false);
    }
  };

  const renderBillingDetails = () => {
    return (
      <>
        <div className="setting__section">
          <div className="box__title_area">
            <FeatherIcon icon="map-pin" width="15px" height="15px" />
            <h5>{translate("Billing Address")}</h5>
          </div>
          <div className="setting__fieldset ghh">
            <Input
              id="address"
              name="billing_name"
              label={translate("Person/Company Name")}
              type="text"
              placeholder={translate("Person/Company Name")}
              onChange={onChange}
              value={formData?.billing_name}
            />
            <Input
              id="address"
              name="billing_address"
              label={translate("Address Details")}
              type="text"
              placeholder={translate("Enter your address")}
              onChange={onChange}
              value={formData?.billing_address}
            />

            <div className="setting__row">
              <Input
                id="city"
                name="billing_zip_code"
                label={translate("Zip Code")}
                type="text"
                placeholder={translate("Zip Code")}
                onChange={onChange}
                value={formData?.billing_zip_code}
              />
              <Input
                id="state"
                name="billing_state"
                label={translate("State/Province/Region")}
                type="text"
                placeholder={translate("State/Province/Region")}
                onChange={onChange}
                value={formData?.billing_state}
              />
              {/* add country and city too*/}
            </div>
            <div className="setting__row">
              <Input
                id="city"
                name="billing_city"
                label={translate("City/Town")}
                type="text"
                placeholder={translate("City/Town")}
                onChange={onChange}
                value={formData?.billing_city}
              />
              <Input
                id="Country"
                name="billing_country"
                label={translate("Country")}
                type="text"
                placeholder={translate("Country")}
                onChange={onChange}
                value={formData?.billing_country}
              />
              {/* add country and city too*/}
            </div>
            <div className="setting__controls fxdd">
              <button
                className="button setting__button"
                onClick={() => {
                  checkInputs();
                }}
              >
                {translate("Confirm and continue")}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };
  useEffect(() => {
    setState(paymentData?.type === "subscription" ? true : false);
    setTop(paymentData?.type === "subscription" ? true : false);
  }, [paymentData]);
  const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();
    console.log(
      "process.env.REACT_APP_STRIPE_PUBLIC_KEY 11",
      process.env.REACT_APP_STRIPE_PUBLIC_KEY
    );
    console.log("paymentData", paymentData);
    const [isBtnLoading, setBtnLoading] = useState(false);

    const handlePayment = async () => {
      setBtnLoading(true);

      if (elements == null) {
        setBtnLoading(false);
        return;
      }

      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit();
      if (submitError) {
        // Show error to your customer
        setErrorMessage(submitError.message);
        setBtnLoading(false);
        return;
      }

      // Create the PaymentIntent and obtain clientSecret from your server endpoint

      const res = await requestHandler(
        "post",
        "/payment",
        paymentData,
        true,
        navigate,
        "/"
      );
      console.log("res", res);

      const clientSecret = await res?.data?.client_secret;

      const { error } = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        clientSecret,
        confirmParams: {
          return_url: "https://kepicker.com/checkout",
        },
      });

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        navigate("/checkout");
        setErrorMessage(error.message);
        setBtnLoading(false);
      } else {
        setBtnLoading(false);
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    };

    const handleSubscription = async () => {
      setBtnLoading(true);

      if (!elements || !stripe) {
        setBtnLoading(false);
        return;
      }

      const { error: submitError } = await elements.submit();
      if (submitError) {
        setBtnLoading(false);
        console.log("submitError", submitError);
        return;
      }

      // Create the PaymentMethod using the details collected by the Payment Element
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        elements,
        params: {
          billing_details: {
            name: formData?.billing_name,
            email: user?.email,
            address: {
              line1: formData?.billing_address,
              postal_code: formData?.billing_zip_code,
              state: formData?.billing_state,
              city: formData?.billing_city,
              country: formData?.billing_country,
            },
            //add more billing details like vat number or id number
          },
        },
      });

      if (error) {
        // This point is only reached if there's an immediate error when
        // creating the PaymentMethod. Show the error to your customer (for example, payment details incomplete)
        console.log("[error]", error);
        setErrorMessage(error.message);
        setBtnLoading(false);
        return;
      }
      // Send paymentMethodId to your backend
      const res = await requestHandler(
        "post",
        "/payment/subscription",
        { ...paymentData, paymentMethod },
        true,
        navigate,
        "/"
      );
      const paymentIntentId = res?.data?.latest_invoice?.payment_intent?.id;
      const paymentType = res?.data?.metadata?.paymentType;

      if (res?.data?.errorType) {
        setErrorMessage(res?.data?.errorMessage);
        setBtnLoading(false);
        return;
      } else {
        // Check if the subscription has a payment intent that needs confirmation
        if (
          res?.data?.latest_invoice?.payment_intent?.status ===
          "requires_action"
        ) {
          const { error } = await stripe.confirmCardPayment(
            res?.data.latest_invoice?.payment_intent?.client_secret,
            {
              // Additional options, like return_url, can go here if necessary
              return_url: "https://kepicker.com/checkout",
            }
          );

          if (error) {
            // Handle errors here
            console.log("[error]", error);
            setErrorMessage(error.message);
            setBtnLoading(false);
          } else {
            // The subscription is active!
            if (paymentIntentId) {
              console.log("subscription is active", res?.data, paymentType);
              navigate(`/checkout?payment_intent=${paymentIntentId}`);
            }
            setBtnLoading(false);
          }
        } else {
          console.log("subscription is active", res?.data, paymentType);
          if (paymentIntentId && paymentType) {
            console.log("subscription is active", res?.data, paymentType);
            navigate(
              `/checkout?payment_intent=${paymentIntentId}&payment_type=${paymentType}`
            );
            setPaymentDrawer(false);
            setBtnLoading(false);
          }
          setBtnLoading(false);
        }
      }
    };

    return (
      <form
        onSubmit={async (event) => {
          event.preventDefault();

          if (paymentData?.tier_name?.toLowerCase()?.includes("token")) {
            console.log("selected token plan");
            handlePayment();
          } else {
            console.log("selected subscription plan");
            handleSubscription();
          }
        }}
      >
        <div className="box__title_area">
          <FeatherIcon icon="credit-card" width="15px" height="15px" />
          <h5>{translate("Payment Method")}</h5>
        </div>
        <PaymentElement />

        <div className="pay__footer">
          <div className="btn_Sss">
            {isTop && (
              <button
                className="button upload__button cursorp bck"
                type="reset"
                onClick={() => {
                  setState(true);
                  setErrorMessage(null);
                }}
              >
                {translate("Back")}
              </button>
            )}

            <button
              className="button upload__button cursorp"
              type="submit"
              disabled={!stripe || !elements || state || isBtnLoading}
            >
              {translate("Checkout Now")}{" "}
              {isBtnLoading && <span className="btn-spinner"></span>}
            </button>
          </div>
          {/* Show error message to your customers */}
          {errorMessage && <div className="pay_mesg">{errorMessage}</div>}
        </div>
      </form>
    );
  };

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

  const options = {
    mode: "payment",
    amount: paymentData?.tier_price,
    currency: "usd",
    paymentMethodCreation: "manual",
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  const getUser = async () => {
    const res = await requestHandler(
      "get",
      "/profile",
      null,
      true,
      navigate,
      "/"
    );
    console.log("res", res);
    setUser(res?.data);
  };

  useEffect(() => {
    console.log(paymentData);
  }, [paymentData]);

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <Drawer
        title={translate("Checkout")}
        placement="right"
        onClose={() => {
          setPaymentDrawer(false);
          setErrorMessage(null);
        }}
        open={isPaymentDrawer}
        width={"50%"}
        className="payment__-drw ffg"
      >
        <>
          {isTop ? (
            <>
              <div className="top__status_payment">
                <div className="top_step__itm current">
                  <FeatherIcon icon="home" width="20px" height="20px" />
                  <span>{translate("Address")}</span>
                </div>
                <div className={`top_step_line ${!state && " current"}`}></div>
                <div className={`top_step__itm ${!state && " current"}`}>
                  <FeatherIcon icon="credit-card" width="20px" height="20px" />
                  <span>{translate("Payment")}</span>
                </div>
                <div className="top_step_line"></div>
                <div className="top_step__itm">
                  <FeatherIcon icon="check-square" width="20px" height="20px" />
                  <span>{translate("Result")}</span>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="drw__area">
            {isTop ? (
              <div className="warn__card plln pymnt">
                <FeatherIcon icon={"alert-circle"} />
                {translate(
                  "After upgrading your plan, in the extension; Update your plugin by clicking the Settings → Other Settings (At the bottom) → Synchronize button."
                )}
              </div>
            ) : (
              <></>
            )}
            <div className="box__title_area">
              <FeatherIcon icon="box" width="15px" height="15px" />
              <h5>{translate("Product Review")}</h5>
            </div>
            <div className="payment_detials">
              <div className="inf_item p__inf">
                <h3>{translate("Product")}</h3>
                <span>
                  {paymentData?.tier_name?.capitalize()}{" "}
                  {!paymentData?.tier_name?.toLowerCase()?.includes("token")
                    ? translate("Plan")
                    : ""}
                </span>
                {/* <span>Token</span> */}
              </div>
              <div className="inf_item p__type">
                <h3>{translate("Price Type")}</h3>
                <span>
                  {paymentData?.type === "one_time"
                    ? translate("Until next due")
                    : translate("Monthly")}
                </span>
                {/* <span>Yearly</span>
            <span>For once</span> */}
              </div>
              <div className="inf_item p_price">
                <h3>{translate("Product Price")}</h3>
                <span>${parseFloat(paymentData?.tier_price) / 100.0}</span>
              </div>
            </div>

            {state ? (
              renderBillingDetails()
            ) : (
              <Elements stripe={stripePromise} options={options}>
                <CheckoutForm />
              </Elements>
            )}
          </div>
          {/* <div className="bottom__img">
            <img src="/assets/img/payment.jpg"></img>
          </div> */}
        </>
      </Drawer>
    </>
  );
};

export default PaymentDrawer;
