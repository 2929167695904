import React, { useEffect } from "react";
import { Button, Drawer } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { requestHandler } from "../../../shared/utils/helpers";
import toast, { Toaster } from "react-hot-toast";
import translate from "../../../shared/utils/translations";
import Swal from "sweetalert2";

const TicketsDrawer = ({ isDrawer, setDrawer, selectedTicket }) => {
  const navigate = useNavigate();
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const sendMessage = async () => {
    setBtnLoading(true);
    if (!message) {
      toast.error(translate("Please enter a message!"));
      setBtnLoading(false);
      return;
    }
    const allMessages = await requestHandler(
      "put",
      `admin/ticket/messages/${selectedTicket?.id}`,
      { message },
      true,
      navigate,
      "/login"
    );
    if (allMessages) {
      toast.success(translate("Message sent successfully!"));

      setBtnLoading(false);
      setMessage("");
      setMessages(allMessages.data);
    }
  };
  const getMessages = async (ticketId) => {
    const messages = await requestHandler(
      "get",
      `admin/ticket/messages/${ticketId}`,
      null,
      true,
      navigate,
      "/login"
    ).then((res) => {
      console.log(res);
      setMessages(res?.data);
    });
    return messages;
  };

  const closeTicket = async () => {
    if (!selectedTicket?.id) {
      toast.error(translate("No ticket selected!"));
      return;
    }
    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Should the ticket status be set to Closed?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setBtnLoading(true);
        const result = await requestHandler(
          "put",
          `/admin/ticket/close/${selectedTicket?.id}`,
          null,
          true,
          navigate,
          "/login"
        );
        if (result?.success) {
          toast.success(translate("Ticket closed successfully!"));
        } else {
          toast.error(translate("Failed to close the ticket!"));
        }
        setBtnLoading(false);
      }
    });
  };

  useEffect(() => {
    setMessage("");
    selectedTicket?.id && getMessages(selectedTicket?.id);
    console.log("selectedTicket", selectedTicket);
  }, [selectedTicket]);

  useEffect(() => {
    console.log("messages", messages);
  }, [messages]);
  useEffect(() => {
    setMessage("");
    setMessages([]);
  }, [isDrawer]);
  return (
    <>
      <Drawer
        title={
          <div className="close-ticket-hedrr">
            <h5>{selectedTicket?.subject}</h5>
            <Button type="primary" onClick={closeTicket} loading={isBtnLoading}>
              {translate("Close Ticket")}
            </Button>
          </div>
        }
        placement="right"
        onClose={() => setDrawer(false)}
        open={isDrawer}
        width={"50%"}
        className="ad_ticket_drw"
      >
        <>
          <div className="messages__wrapper">
            <div className="messages__inner">
              <div className="messages__date">
                {selectedTicket?.created_at?.replace("T", "-")?.split(".")[0]}
              </div>
              <div className="messages__list">
                {messages?.length ? (
                  messages?.map((message) => {
                    if (message?.from !== "me") {
                      return (
                        <div key={message?.id} className="messages__author">
                          <div className="messages__text">
                            {message?.message}
                          </div>
                          <div className="messages__time">
                            {
                              message?.created_at
                                ?.replace("T", "-")
                                ?.split(".")[0]
                            }
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <>
                          <div className="messages__text">
                            {message?.message}
                          </div>
                          <div className="messages__time">
                            {
                              message?.created_at
                                ?.replace("T", "-")
                                ?.split(".")[0]
                            }
                          </div>
                        </>
                      );
                    }
                  })
                ) : (
                  <div className="no__msgs_warn">
                    <img src="/assets/img/multimedia.svg"></img>
                  </div>
                )}
              </div>
            </div>
            <div className="subscription">
              <textarea
                className="subscription__input"
                type="text"
                name="text"
                value={message}
                placeholder={translate("Type here...")}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                required="required"
              />
              <button className="subscription__btn cvv" onClick={sendMessage}>
                {isBtnLoading ? (
                  <span className="btn-spinner"></span>
                ) : (
                  <svg className="icon icon-arrow-next">
                    <use xlinkHref="#icon-arrow-next"></use>
                  </svg>
                )}
              </button>
            </div>
          </div>
        </>
      </Drawer>
    </>
  );
};

export default TicketsDrawer;
