import React, { useEffect } from "react";
import { Button, Drawer } from "antd";
import { useState } from "react";
import Select from "../../common/form_elements/select";
import Input from "../../common/form_elements/input";
import { useNavigate } from "react-router-dom";
import translate from "../utils/translations";
import FeatherIcon from "feather-icons-react";
import { requestHandler } from "../utils/helpers";

const BuyTokenForm = ({
  isBuyTokenDrawer,
  setBuyTokenDrawer,
  setPaymentDrawer,
  setPaymentData,
  paymentData,
}) => {
  const navigate = useNavigate();

  const [tiers, setTiers] = useState([]);

  const getTiers = async () => {
    console.log("get tiers triggered");
    const res = await requestHandler(
      "get",
      "/tiers",
      null,
      true,
      navigate,
      "/"
    );
    console.log("res", res);
    setTiers(res?.data?.filter((i) => i.type === "one_time"));
  };

  useEffect(() => {
    console.log("buy token form rendered");
    getTiers();
  }, []);
  useEffect(() => {
    console.log("buy token form rendered");
    getTiers();
  }, [isBuyTokenDrawer]);

  const [showBuy, setShowBuy] = useState(-1);

  return (
    <>
      <Drawer
        title={translate("Extra Comparison Tokens")}
        placement="right"
        onClose={() => setBuyTokenDrawer(false)}
        open={isBuyTokenDrawer}
        width={"50%"}
        className="payment__-drw tkns"
      >
        <>
          <div className="buy_tokens_detials">
            <div className="kpf_icn_token"></div>
            <fieldset className="radio-group">
              <legend className="radio-group-legend">
                {translate("Please choose the amount you need.")}
              </legend>
              <div className="trrr_warn">
                <FeatherIcon icon={"alert-circle"} />
                <span>
                  {translate(
                    "The comparison tokens you purchase will be valid until the expiry date of your current package."
                  )}
                </span>
              </div>

              <div className="tt_radios">
                {tiers?.map((item, index) => {
                  return (
                    <div id={item?.id} className="radio" key={index}>
                      <label className="radio-wrapper" for={item?.tier_price}>
                        <input
                          type="radio"
                          className="radio-input"
                          name="token"
                          value={item?.tier_price}
                          id={item?.tier_price}
                          checked={paymentData?.id === item?.id}
                          onChange={() => {
                            setPaymentData(item);
                            setShowBuy(index);
                          }}
                        />
                        <span className="radio-tile">
                          <span className="radio-icon">
                            {parseInt(item.tier_name?.match(/\d+/)[0], 10)}
                          </span>
                          <span className="radio-label">
                            {translate("DTokens")}
                          </span>
                          <small>
                            ${parseInt(item.tier_price) / 100}{" "}
                            <span>({translate("for Once")})</span>
                          </small>
                        </span>
                      </label>
                    </div>
                  );
                })}
              </div>

              {/* Satın Alma */}
              <button
                onClick={() => setPaymentDrawer(true)}
                className={`button complete__button byuy ${
                  showBuy >= 0 ? " active" : ""
                }`}
              >
                {translate("Buy")}
              </button>
            </fieldset>
          </div>
        </>
      </Drawer>
    </>
  );
};

export default BuyTokenForm;
