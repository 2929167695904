import React, { useEffect, useState } from "react";
import { Table, Tag, Dropdown, Menu, Button, Tooltip } from "antd";
import FeatherIcon from "feather-icons-react";
import translate from "../../../../shared/utils/translations";
import EmptyIcon from "../../../../../assets/img/empty.png";
import { makeReadable, makeSmaller } from "../../../../shared/utils/helpers";

const KeepaData = ({ keepaData }) => {
  const columnsKeepa = [
    {
      title: translate("Historical Data"),
      dataIndex: "historical_data",
      key: "historical_data",
      render: (item) => (
        <>
          <div className="column_info">{translate("Historical Data")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("30 Day Avg"),
      dataIndex: "v30_day_avg",
      key: "v30_day_avg",
      render: (item) => (
        <>
          <div className="column_info">{translate("30 Day Avg")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("90 Day Avg"),
      dataIndex: "v90_day_avg",
      key: "v90_day_avg",
      render: (item) => (
        <>
          <div className="column_info">{translate("90 Day Avg")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("180 Day Avg"),
      dataIndex: "v180_day_avg",
      key: "v180_day_avg",
      render: (item) => (
        <>
          <div className="column_info">{translate("180 Day Avg")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("365 Day Avg"),
      dataIndex: "v365_day_avg",
      key: "v365_day_avg",
      render: (item) => (
        <>
          <div className="column_info">{translate("365 Day Avg")}</div>
          {item}
        </>
      ),
    },
  ];

  const dataKeepa = [
    {
      key: 1,
      historical_data: "BSR",
      v30_day_avg: keepaData?.BSR30 || "N/A",
      v90_day_avg: keepaData?.BSR90 || "N/A",
      v180_day_avg: keepaData?.BSR180 || "N/A",
      v365_day_avg: keepaData?.BSR365 || "N/A",
    },
    {
      key: 2,
      historical_data: "BSR Drops",
      v30_day_avg: keepaData?.salesRankDrops30 || "N/A",
      v90_day_avg: keepaData?.salesRankDrops90 || "N/A",
      v180_day_avg: keepaData?.salesRankDrops180 || "N/A",
      v365_day_avg: keepaData?.salesRankDrops365 || "N/A",
    },
    {
      key: 3,
      historical_data: "Amazon Price",
      v30_day_avg: keepaData?.priceAmazon30 || "N/A",
      v90_day_avg: keepaData?.priceAmazon90 || "N/A",
      v180_day_avg: keepaData?.priceAmazon180 || "N/A",
      v365_day_avg: keepaData?.priceAmazon365 || "N/A",
    },
    {
      key: 4,
      historical_data: "New Offer Price",
      v30_day_avg: keepaData?.priceNew30 || "N/A",
      v90_day_avg: keepaData?.priceNew90 || "N/A",
      v180_day_avg: keepaData?.priceNew180 || "N/A",
      v365_day_avg: keepaData?.priceNew365 || "N/A",
    },
  ];
  const [dynamicData, setDynamicData] = useState([]);

  const columns = [
    {
      title: "Data",
      dataIndex: "name",
      key: "name",
      render: (item) => (
        <>
          <div className="column_info">{translate("Data")}</div>
          {item}
        </>
      ),
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      render: (item) => (
        <>
          <div className="column_info">{translate("Value")}</div>
          {item}
        </>
      ),
    },
  ];

  const data = [
    {
      key: 1,
      data: "Title##1",
      value: "Value##1",
    },
    {
      key: 2,
      data: "Title##2",
      value: "Value##2",
    },
    {
      key: 3,
      data: "Title##3",
      value: "Value##3",
    },
  ];

  useEffect(() => {
    console.log(
      Object.keys(keepaData).map((key) => {
        if (keepaData[key] === null || keepaData[key] === undefined) {
          return {
            name: makeReadable(key),
            value: "N/A",
          };
        } else if (typeof keepaData[key] === "object") {
          return {
            name: makeReadable(key),
            value: JSON.stringify(keepaData[key]),
          };
        } else if (typeof keepaData[key] === "boolean") {
          return {
            name: makeReadable(key),
            value: keepaData[key] ? "Yes" : "No",
          };
        } else if (typeof keepaData[key] === "array") {
          return {
            name: makeReadable(key),
            value: keepaData[key].join(", "),
          };
        } else {
          return {
            name: makeReadable(key),
            value: keepaData[key],
          };
        }
      })
    );
    setDynamicData(
      keepaData
        ? Object.keys(keepaData).map((key) => {
            if (keepaData[key] === null || keepaData[key] === undefined) {
              return {
                name: makeReadable(key),
                value: "N/A",
              };
            } else if (typeof keepaData[key] === "object") {
              return {
                name: makeReadable(key),
                value: makeSmaller(JSON.stringify(keepaData[key]), 50),
              };
            } else if (typeof keepaData[key] === "boolean") {
              return {
                name: makeReadable(key),
                value: keepaData[key] ? "Yes" : "No",
              };
            } else if (typeof keepaData[key] === "array") {
              return {
                name: makeReadable(key),
                value: makeSmaller(keepaData[key].join(", "), 50),
              };
            } else {
              return {
                name: makeReadable(key),
                value: makeSmaller(keepaData[key], 50),
              };
            }
          })
        : []
    );
  }, [keepaData]);

  return (
    <>
      <div className="product__cmp_info stck">
        <h5 className="ttle_tbl">{translate("Keepa Data")}</h5>
        <div className="minii__tablee dif__ablee add__tblle">
          <Table
            columns={columnsKeepa}
            dataSource={dataKeepa}
            pagination={false}
            locale={{
              emptyText: (
                <span className="empty__tble">
                  <img src={EmptyIcon} />
                </span>
              ),
            }}
            className=" has_pag_btn no"
          />
        </div>

        <div className="dvvddr"></div>
        <h5 className="ttle_tbl">{translate("Other Data")}</h5>
        <div className="minii__tablee dif__ablee add__tblle">
          <Table
            columns={columns}
            dataSource={dynamicData}
            pagination={false}
            locale={{
              emptyText: (
                <span className="empty__tble">
                  <img src={EmptyIcon} />
                </span>
              ),
            }}
            className=" has_pag_btn no"
          />
        </div>
      </div>
    </>
  );
};

export default KeepaData;
