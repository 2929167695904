import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import translate from "../../shared/utils/translations";
import EnTerms from "./terms_items/en_terms";
import TrTerms from "./terms_items/tr_terms";
import DeTerms from "./terms_items/de_terms";

const Privacy = ({ setPage }) => {
  useEffect(() => {
    setPage("landing");
    currentLanguage(localStorage.getItem("lang") || 'EN');
  });

  const [isPolicy, setPolicy] = useState(true);
  const [currentLang, currentLanguage] = useState({});

  return (
    <>
      <Helmet>
        <title>{translate("Privacy Policy")}</title>
        <meta
          name="description"
          content={translate(
            "Our Privacy Policy ensures that both you and our other users' personal data is secure. This policy clearly outlines what information is collected, how it's used, and how it's safeguarded. We respect your privacy and work diligently to protect your information to the best of our ability."
          )}
        />
      </Helmet>
      <div className="outer__inner landng">
        <div className="section article">
          <div className="section article__body">
            <div className="article__center center">
              <div className="article__head">
                <h1 className="article__title h1">
                  {translate("Privacy Policy")}
                </h1>
                <div className="breadcrumbs">
                  <div className="breadcrumbs__item">
                    <NavLink className="breadcrumbs__link" to="/">
                      {translate("Go Home")}
                    </NavLink>
                  </div>
                  <div className="breadcrumbs__item">
                    {translate("Privacy Policy")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="article__wrap privacy_content">
            <div className="article__center center">
              <div className="dashbordi__nav">
                {/* <a
                  className={`dashbordi__link ${isPolicy && " active"}`}
                  role="button"
                  href="#!"
                  onClick={() => setPolicy(true)}
                >
                  {translate("Privacy Policy")}
                </a>
                <a
                  className={`dashbordi__link ${!isPolicy && " active"}`}
                  role="button"
                  href="#!"
                  onClick={() => setPolicy(false)}
                >
                  {translate("Terms of Use")}
                </a> */}
              </div>

              {currentLang === "TR" ? (
                <TrTerms isPolicy={isPolicy} />
              ) : currentLang === "DE" ? (
                <DeTerms isPolicy={isPolicy} />
              ) : (
                <EnTerms isPolicy={isPolicy} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;
