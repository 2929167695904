import React from "react";

const TrTerms = ({ isPolicy }) => {
  return (
    <>
      {isPolicy ? (
        <div className="content">
          <p>Son güncelleme tarihi: 21 Ekim 2023</p>
          <p>
            Bu Gizlilik Politikası, Servisi kullanırken Sizin bilgilerinizin
            toplanması, kullanılması ve açıklanması hakkındaki politika ve
            prosedürlerimizi açıklar ve sizin gizlilik haklarınızı ve yasaların
            Sizi nasıl koruduğunu anlatır.
          </p>
          <p>
            Sizin Kişisel verilerinizi, Servisi sağlamak ve geliştirmek amacıyla
            kullanırız. Servisi kullanarak, bu Gizlilik Politikası'na uygun
            olarak bilgilerin toplanması ve kullanılmasını kabul etmiş
            olursunuz.
            <br />
            <strong>
              Gizlilik Politikamız, hem sizin hem de diğer kullanıcılarımızın
              kişisel verilerinin güvende olduğundan emin olur. Bu politika,
              hangi bilgilerin toplandığını, nasıl kullanıldığını ve nasıl
              korunduğunu açıkça belirtir. Gizliliğinizi saygı duyar ve
              bilgilerinizi en iyi şekilde koruma amacıyla özenle çalışırız.
            </strong>
          </p>
          <h1>Terimlerin Yorumlanması ve Tanımlar</h1>
          <h2>Yorumlama</h2>
          <p>
            İlk harfi büyük olan kelimeler, aşağıdaki koşullar altında
            tanımlanan anlamlara sahiptir. Aşağıdaki tanımlamalar, tekil veya
            çoğul olarak görünseler bile aynı anlama sahip olacaktır.
          </p>
          <h2>Tanımlar</h2>
          <p>Bu Gizlilik Politikası'nın amaçları için:</p>

          <ul>
            <li>
              <p>
                <strong>Hesap</strong>, Servisimize veya Servisimizin
                bölümlerine erişim sağlamak için oluşturulan size özel bir
                hesabı ifade eder.
              </p>
            </li>
            <li>
              <p>
                <strong>İş Ortağı</strong>, bir tarafı kontrol eden, kontrol
                edilen veya ortak kontrol altında olan bir varlığı ifade eder;
                burada "kontrol" yönetim kurulu üyelerinin veya diğer yönetim
                otoritelerinin seçimi için oy kullanma hakkına sahip olan
                paylar, özkaynak payı veya diğer menkul kıymetlerin %50 veya
                daha fazlasının sahibi olma anlamına gelir.
              </p>
            </li>
            <li>
              <p>
                <strong>Şirket</strong> (bu Sözleşme'de "Şirket", "Biz", "Bize"
                veya "Bizim" olarak adlandırılır), Kepicker Software LLC
                şirketini ifade eder, Delaware - ABD.
              </p>
            </li>
            <li>
              <p>
                <strong>Çerezler</strong>, bir web sitesi tarafından
                bilgisayarınıza, mobil cihazınıza veya başka bir cihaza
                yerleştirilen küçük dosyalardır ve bu dosyalar, o web
                sitesindeki gezinme geçmişinizin ayrıntılarını içeren birçok
                amaç arasında bulunur.
              </p>
            </li>
            <li>
              <p>
                <strong>Ülke</strong>, Delaware, Amerika Birleşik Devletleri'ni
                ifade eder.
              </p>
            </li>
            <li>
              <p>
                <strong>Cihaz</strong>, bir bilgisayar, cep telefonu veya
                dijital tablet gibi Servise erişebilen herhangi bir cihazı ifade
                eder.
              </p>
            </li>
            <li>
              <p>
                <strong>Kişisel Veri</strong>, tanımlanmış veya tanımlanabilir
                bir bireyle ilgili bilgileri ifade eder.
              </p>
            </li>
            <li>
              <p>
                <strong>Servis</strong>, Websitesine atıfta bulunur.
              </p>
            </li>
            <li>
              <p>
                <strong>Servis Sağlayıcı</strong>, Şirket adına verileri işleyen
                herhangi bir doğal veya tüzel kişiyi ifade eder. Bu, Servisi
                kolaylaştırmak, Şirket adına Servisi sağlamak, Servis ile ilgili
                hizmetleri sunmak veya Servisin nasıl kullanıldığını analiz
                etmek için Şirket tarafından istihdam edilen üçüncü taraf
                şirketleri veya bireyleri içerir.
              </p>
            </li>
            <li>
              <p>
                <strong>Kullanım Verileri</strong>, otomatik olarak toplanan
                verilere atıfta bulunur, Servisin kullanımından veya Servis
                altyapısından kaynaklanan verileri içerir (örneğin, bir sayfa
                ziyaretinin süresi).
              </p>
            </li>
            <li>
              <p>
                <strong>Websitesi</strong>,{" "}
                <a
                  href="https://kepicker.com"
                  rel="external nofollow noopener"
                  target="_blank"
                >
                  https://kepicker.com
                </a>{" "}
                adresinden erişilebilen Kepicker'i ifade eder.
              </p>
            </li>
            <li>
              <p>
                <strong>Siz</strong>, Servisi erişen veya kullanan bireyi veya
                uygun olduğunda bu birey adına erişen veya hizmeti kullanan
                şirketi veya diğer yasal varlığı ifade eder.
              </p>
            </li>
          </ul>

          <h1>Kişisel Verilerinizin Toplanması ve Kullanılması</h1>
          <div>
            <h2>Kepicker Chrome Uzantısı Gizlilik Politikası</h2>
            <p>
              <strong>Son Güncelleme:</strong> 17/01/2024
            </p>

            <h3>Bu Politika Hakkında</h3>
            <p>
              Bu bölüm, Kepicker Chrome uzantısının veri işleme uygulamalarını
              ele almakta ve ana gizlilik politikamızda özetlenen genel gizlilik
              uygulamalarını tamamlamaktadır.
            </p>

            <h3>1. Uzantıda Veri Toplama</h3>
            <h4>Toplanan Veri Türleri</h4>
            <p>
              Kepicker uzantısı, kayıt ve giriş işlemi sırasında kullanıcıların
              e-posta adreslerini toplar. Bu, uzantı içinde işlediğimiz kişisel
              verinin temel türüdür.
            </p>

            <h4>Toplama Yöntemi</h4>
            <p>
              Kullanıcı e-posta adresleri, kayıt veya giriş sırasında kullanıcı
              girdisi aracılığıyla doğrudan toplanır.
            </p>

            <h3>2. Veri Kullanımı</h3>
            <h4>Veri Toplama Amacı</h4>
            <p>
              E-posta adreslerinin toplanması, temelde kullanıcı hesaplarını
              oluşturmak, giriş işlemlerini kolaylaştırmak ve uzantı
              özelliklerine güvenli erişimi sağlamak içindir.
            </p>

            <h4>Veri İşleme</h4>
            <p>
              Hesap yönetimi ve kimlik doğrulama dışında e-posta verilerini
              işlemiyor veya analiz etmiyoruz.
            </p>

            <h3>3. Veri Paylaşımı ve Açıklama</h3>
            <h4>Ödeme İşlemleri İçin Üçüncü Taraf Paylaşımı</h4>
            <p>
              Kepicker üzerinden yapılan alışverişlerde, ödeme işlemlerini
              kolaylaştırmak için gerekli verileri, e-posta adresleri ve isimler
              gibi, ödeme işlemi ortağımız Stripe ile paylaşıyoruz.
            </p>

            <h4>Hukuki Gereklilikler</h4>
            <p>
              Hukuken zorunlu kılınan durumlarda veya hukuki süreçlere uymak
              için kişisel verileri paylaşabiliriz.
            </p>

            <h3>4. Kullanıcı Onayı ve Kontrolü</h3>
            <h4>Onay Mekanizması</h4>
            <p>
              E-posta adreslerinin toplanması için kullanıcı onayı, kayıt süreci
              sırasında alınır. Kullanıcılar, veri toplama ve amacı hakkında
              bilgilendirilir ve devam etmeden önce buna onay vermelidir.
            </p>

            <h4>Kullanıcı Kontrol Seçenekleri</h4>
            <p>
              Kullanıcılar, e-posta bilgilerini güncelleyebilir veya uzantının
              hesap ayarları üzerinden hesaplarının silinmesini talep edebilir.
              Veri silme talepleri, geçerli veri koruma yasalarına uygun olarak
              işlenir.
            </p>

            <h3>5. Veri Güvenliği</h3>
            <p>
              Kullanıcı verilerini korumak için çeşitli güvenlik önlemleri
              alıyoruz, bunlar arasında güvenli veri iletimi ve saklama yer
              alır. Veri güvenliği taahhüdümüz, Stripe gibi üçüncü taraf
              hizmetlerle olan etkileşimlerimizi de kapsar.
            </p>

            <h3>6. İletişim Bilgileri</h3>
            <p>
              Kepicker uzantısı içindeki veri işleme uygulamalarımızla ilgili
              herhangi bir sorunuz veya endişeniz varsa, lütfen{" "}
              <a href="mailto:info@kepicker.com">info@kepicker.com</a>{" "}
              adresinden bizimle iletişime geçin.
            </p>

            <p>
              Lütfen bu gizlilik politikasının değişikliklere ve güncellemelere
              tabi olduğunu ve bu sayfada yansıtılacağını unutmayın.
              Kullanıcıların politikamızı periyodik olarak gözden geçirmeleri
              önerilir.
            </p>
          </div>
          <h3>Kişisel Veri</h3>
          <p>
            Servisimizi kullanırken, sizden sizinle iletişim kurmak veya sizi
            tanımlamak için kullanılabilecek belirli kişisel tanımlanabilir
            bilgileri bize sağlamanızı isteyebiliriz. Kişisel tanımlanabilir
            bilgiler şunları içerebilir, ancak bunlarla sınırlı değildir:
          </p>
          <ul>
            <li>
              <p>E-posta adresi</p>
            </li>
            <li>
              <p>Ad ve soyad</p>
            </li>
            <li>
              <p>Telefon numarası</p>
            </li>
            <li>
              <p>Adres, Eyalet, İlçe, Posta Kodu, Şehir</p>
            </li>
            <li>
              <p>Kullanım Verileri</p>
            </li>
          </ul>
          <h3>Kullanım Verileri</h3>
          <p>
            Kullanım Verileri, Servisi kullanırken otomatik olarak toplanır.
          </p>
          <p>
            Kullanım Verileri, cihazınızın İnternet Protokol adresi (IP adresi),
            tarayıcı türü, tarayıcı sürümü, ziyaret ettiğiniz Servis sayfaları,
            ziyaretinizin tarihi ve saati, bu sayfalar üzerinde geçirdiğiniz
            süre, benzersiz cihaz tanımlayıcıları ve diğer teşhis verileri gibi
            bilgileri içerebilir.
          </p>
          <p>
            Servise bir mobil cihazla eriştiğinizde, belirli bilgileri otomatik
            olarak toplayabiliriz, bunlar arasında kullandığınız mobil cihazın
            türü, mobil cihazınızın benzersiz kimliği, mobil cihazınızın IP
            adresi, mobil cihazınızın işletim sistemi, kullandığınız mobil
            İnternet tarayıcısının türü, benzersiz cihaz tanımlayıcıları ve
            diğer teşhis verileri bulunabilir.
          </p>
          <p>
            Ayrıca, tarayıcınızın her ziyaretinizde veya bir mobil cihazla
            Servise eriştiğinizde gönderdiği bilgileri de toplayabiliriz.
          </p>

          <h3>Takip Teknolojileri ve Çerezler</h3>
          <p>
            Servisimizdeki etkinliği takip etmek ve belirli bilgileri saklamak
            için Çerezler ve benzeri takip teknolojilerini kullanıyoruz.
            Kullandığımız takip teknolojileri, bilgi toplamak ve takip etmek,
            Servisimizi iyileştirmek ve analiz etmek için farlar, etiketler ve
            betikler içerebilir. Kullandığımız teknolojiler şunları içerebilir:
          </p>
          <ul>
            <li>
              <strong>Çerezler veya Tarayıcı Çerezleri.</strong> Bir çerez,
              Cihazınıza yerleştirilen küçük bir dosyadır. Tarayıcınıza tüm
              Çerezleri reddetmesini veya bir Çerez gönderildiğinde bildirmesini
              talimat verebilirsiniz. Ancak, Çerezleri kabul etmezseniz,
              Servisimizin bazı bölümlerini kullanamayabilirsiniz. Tarayıcınızı
              öyle ayarlamadıysanız, Servisimiz Çerezler kullanabilir.
            </li>
            <li>
              <strong>Web Farları.</strong> Servisimizin belirli bölümleri ve
              e-postalarımız, web farları olarak bilinen küçük elektronik
              dosyaları içerebilir (açık gifler, piksel etiketler ve tek piksel
              gifleri olarak da adlandırılır), bu dosyalar örneğin, bu sayfaları
              ziyaret eden veya bir e-postayı açan kullanıcıları saymamıza veya
              diğer ilgili web sitesi istatistiklerini kaydetmemize (örneğin,
              belirli bir bölümün popülerliğini kaydetmek ve sistem ve sunucu
              bütünlüğünü doğrulamak) izin verir.
            </li>
          </ul>
          <p>
            Çerezler "Kalıcı" veya "Oturum" Çerezleri olabilir. Kalıcı Çerezler,
            çevrimdışıyken kişisel bilgisayarınızda veya mobil cihazınızda
            kalırken, Oturum Çerezleri web tarayıcınızı kapattığınız anda
            silinir. Çerezler hakkında daha fazla bilgiyi{" "}
            <a
              href="https://www.kaspersky.com/resource-center/definitions/cookies"
              target="_blank"
            >
              Kaspersky web sitesi makalesinde
            </a>{" "}
            bulabilirsiniz.
          </p>
          <p>
            Aşağıda belirtilen amaçlar için hem Oturum hem de Kalıcı Çerezler
            kullanıyoruz:
          </p>
          <ul>
            <li>
              <p>
                <strong>Zorunlu / Temel Çerezler</strong>
              </p>
              <p>Tür: Oturum Çerezleri</p>
              <p>Yürütücü: Biz</p>
              <p>
                Amaç: Bu Çerezler, Web sitesi aracılığıyla sunulan hizmetleri
                sağlamak ve bazı özelliklerini kullanabilmeniz için esastır.
                Kullanıcıları kimlik doğrulamada yardımcı olur ve kullanıcı
                hesaplarının hileli kullanımını önler. Bu Çerezler olmadan
                istenen hizmetler sunulamaz ve sadece bu hizmetleri sağlamak
                için bu Çerezleri kullanırız.
              </p>
            </li>
            <li>
              <p>
                <strong>Çerez Politikası / Bildirim Kabul Çerezleri</strong>
              </p>
              <p>Tür: Kalıcı Çerezler</p>
              <p>Yürütücü: Biz</p>
              <p>
                Amaç: Bu Çerezler, kullanıcıların Web sitesinde çerez
                kullanımını kabul edip etmediğini belirler.
              </p>
            </li>
            <li>
              <p>
                <strong>Fonksiyonellik Çerezleri</strong>
              </p>
              <p>Tür: Kalıcı Çerezler</p>
              <p>Yürütücü: Biz</p>
              <p>
                Amaç: Bu Çerezler, Web sitesini kullanırken yaptığınız seçimleri
                hatırlamanıza yardımcı olur, örneğin giriş bilgilerinizi veya
                dil tercihinizi hatırlamak gibi. Bu Çerezlerin amacı, size daha
                kişisel bir deneyim sunmak ve Web sitesini her kullandığınızda
                tercihlerinizi yeniden girmeniz gerekmemesini sağlamaktır.
              </p>
            </li>
          </ul>

          <p>
            Çerezlerimizi ve çerezlere ilişkin seçeneklerinizi daha fazla bilgi
            için lütfen Çerez Politikamızı veya Gizlilik Politikamızın Çerezler
            bölümünü ziyaret edin.
          </p>
          <h2>Kişisel Verilerinizin Kullanımı</h2>
          <p>Şirket, Kişisel Verileri aşağıdaki amaçlar için kullanabilir:</p>
          <ul>
            <li>
              <p>
                <strong>Servisimizi sağlamak ve sürdürmek</strong>, Servisimizin
                kullanımını izlemek dahil.
              </p>
            </li>
            <li>
              <p>
                <strong>Hesabınızı yönetmek:</strong> Servis kullanıcısı olarak
                kaydınızı yönetmek. Sağladığınız Kişisel Veriler, kayıtlı bir
                kullanıcı olarak erişebileceğiniz Servisin farklı işlevlerine
                erişim sağlayabilir.
              </p>
            </li>
            <li>
              <p>
                <strong>Bir sözleşmenin yerine getirilmesi amacıyla:</strong>{" "}
                Ürünlerin, öğelerin veya hizmetlerin satın alım sözleşmesinin
                geliştirilmesi, uygunluğu ve yerine getirilmesi veya Servis
                aracılığıyla Bizimle yapılan başka bir sözleşme.
              </p>
            </li>
            <li>
              <p>
                <strong>Siz ile iletişim kurmak:</strong> Sizi e-posta, telefon
                aramaları, SMS veya diğer elektronik iletişim formları gibi
                eşdeğer elektronik iletişim yollarıyla iletişim kurmak, örneğin,
                güvenlik güncellemeleri gibi güncellemeler veya bilgilendirici
                iletişimlerle ilgili bilgileri bildirmek veya bunları
                uygulamanız gerektiğinde veya makul olduğunda.
              </p>
            </li>
            <li>
              <p>
                <strong>
                  Sizlere haberler, özel teklifler ve satın alınan veya
                  sorgulanan ürünler veya hizmetlerle benzer olan diğer ürünler,
                  hizmetler ve etkinlikler hakkında genel bilgi sağlamak:
                </strong>{" "}
                Bu tür bilgileri almamayı tercih etmediğiniz sürece, zaten satın
                aldığınız veya sorguladığınız ürünlerle benzer olan diğer
                ürünler, hizmetler ve etkinlikler hakkında sizi
                bilgilendirebiliriz.
              </p>
            </li>
            <li>
              <p>
                <strong>Taleplerinizi yönetmek:</strong> Taleplerinizi
                karşılamak ve yönetmek.
              </p>
            </li>
            <li>
              <p>
                <strong>İş devirleri için:</strong> Kişisel Verilerinizi, bazı
                veya tüm varlıklarımızın birleşmesini, devralmasını, yeniden
                yapılandırılmasını, yeniden düzenlenmesini, tasfiye edilmesini
                veya başka bir satışını veya transferini değerlendirmek veya
                gerçekleştirmek için kullanabiliriz, bu varlıkların devam eden
                bir iş olarak veya iflas, tasfiye veya benzer bir işlem parçası
                olarak devredildiği birleşme, devralma, yeniden yapılandırma,
                yeniden düzenleme veya benzer bir işlem parçası olarak, Bizim
                tarafımızdan Servis kullanıcılarımız hakkında tutulan Kişisel
                Veriler dahil.
              </p>
            </li>
            <li>
              <p>
                <strong>Diğer amaçlar için</strong>: Bilgi analizi, kullanım
                eğilimlerini belirleme, tanıtım kampanyalarımızın etkinliğini
                belirleme ve Servisimizi, ürünlerimizi, hizmetlerimizi,
                pazarlamamızı ve deneyiminizi değerlendirme ve iyileştirme gibi
                diğer amaçlar için bilginizi kullanabiliriz.
              </p>
            </li>
          </ul>

          <p>Kişisel bilgilerinizi aşağıdaki durumlarda paylaşabiliriz:</p>
          <ul>
            <li>
              <strong>Hizmet Sağlayıcılar ile:</strong> Hizmetimizin kullanımını
              izlemek ve analiz etmek, sizi iletişim kurmak için Kişisel
              bilgilerinizi Hizmet Sağlayıcıları ile paylaşabiliriz.
            </li>
            <li>
              <strong>İş devirleri için:</strong> Şirket varlıklarının
              birleşmesi, Şirket varlıklarının satışı, finansman veya işimizin
              tamamının veya bir kısmının başka bir şirkete satışı ile ilgili
              olarak Kişisel bilgilerinizi paylaşabilir veya devredebiliriz veya
              paylaşımda bulunabiliriz.
            </li>
            <li>
              <strong>Affiliate (Ortaklarımız) ile:</strong> Bilgilerinizi
              ortaklarımızla paylaşabiliriz; bu durumda bu ortakların bu
              Gizlilik Politikasını kabul etmelerini bekleyeceğiz. Ortaklarımız,
              ana şirketimizi ve diğer bağlı şirketleri, ortak girişim ortakları
              veya diğer şirketleri içerebilir ki bu şirketleri kontrol ediyoruz
              veya kontrol ediliyoruz.
            </li>
            <li>
              <strong>İş ortakları ile:</strong> Bilgilerinizi iş ortaklarımızla
              paylaşabiliriz size belirli ürünler, hizmetler veya promosyonlar
              sunmak için.
            </li>
            <li>
              <strong>Diğer kullanıcılar ile:</strong> Kişisel bilgilerinizi
              diğer kullanıcılarla paylaştığınızda veya diğer kullanıcılarla
              kamusal alanlarda etkileşimde bulunduğunuzda, bu bilgiler tüm
              kullanıcılar tarafından görüntülenebilir ve dışarıya kamusal
              olarak dağıtılabilir.
            </li>
            <li>
              <strong>Onayınız ile:</strong> Kişisel bilgilerinizi başka bir
              amaçla sizin onayınız ile paylaşabiliriz.
            </li>
          </ul>
          <h2>Kişisel Verilerinizin Saklanması</h2>
          <p>
            Şirket, Kişisel Verilerinizin sadece bu Gizlilik Politikasında
            belirtilen amaçlar doğrultusunda gerekli olduğu sürece saklar.
            Kişisel Verilerinizi, yasal yükümlülüklerimize uygun olarak saklamak
            (örneğin, verilerinizi saklamak için yasal yükümlülüğümüz varsa),
            anlaşmazlıkları çözmek ve yasal anlaşmalarımızı ve politikalarımızı
            uygulamak için gerektiği kadar saklayacak ve kullanacaktır.
          </p>
          <p>
            Şirket ayrıca İşitsel Verileri içsel analiz amaçları için
            saklayacaktır. İşitsel Veriler genellikle daha kısa bir süre için
            saklanır, ancak bu verilerin güvenliği güçlendirmek veya
            Servisimizin işlevselliğini iyileştirmek veya bu verilerin daha uzun
            süre saklanması gerektiğinde yasal olarak saklanması gereken
            durumlar haricinde.
          </p>
          <h2>Kişisel Verilerinizin Transferi</h2>
          <p>
            Bilgileriniz, dahil olmak üzere Kişisel Veriler, Şirket'in işletme
            ofislerinde ve işlemde yer alan tarafların bulunduğu diğer herhangi
            bir yerde işlenir. Bu, bu bilgilerin, veri koruma yasalarının kendi
            bölgenizden farklı olabileceği devletiniz, iliniz, ülkeniz veya
            diğer hükümet düzenlemelerinin bulunabileceği yerler dışındaki
            bilgisayarlara aktarılabileceği ve sürdürülebileceği anlamına gelir.
          </p>
          <p>
            Bu Gizlilik Politikasını kabul ederek ve bu tür bilgileri
            göndererek, bu transferi kabul ettiğinizi belirtirsiniz.
          </p>

          <p>
            Şirket, verilerinizin güvenli bir şekilde işlenmesini ve bu Gizlilik
            Politikası'na uygun olarak işlenmesini sağlamak için makul olarak
            gerekli tüm adımları atacaktır ve Kişisel Verilerinizin güvenliği
            dahil olmak üzere yerinde uygun kontrollerin bulunmadığı bir
            kuruluşa veya ülkeye Kişisel Verilerinizin aktarılması
            gerçekleşmeyecektir.
          </p>
          <h2>Kişisel Verilerinizi Silme</h2>
          <p>
            Sizin hakkınızda topladığımız Kişisel Verileri silme veya silmemize
            yardımcı olma hakkınıza sahipsiniz.
          </p>
          <p>
            Servisimiz size Servis içinden Sizin hakkınızda bazı bilgileri silme
            yeteneği sağlayabilir.
          </p>
          <p>
            Hesabınız varsa, Kişisel bilgilerinizi yönetmenize izin veren hesap
            ayarları bölümünü ziyaret ederek her zaman bilgilerinizi
            güncelleyebilir, düzeltebilir veya silebilirsiniz. Ayrıca, bize
            başvurarak Sizin tarafınızdan sağlanan herhangi bir kişisel bilgiye
            erişim, düzeltme veya silme talep edebilirsiniz.
          </p>
          <p>
            Ancak lütfen unutmayın ki, yasal bir yükümlülüğümüz veya yasal
            dayanak bulunduğunda belirli bilgileri saklamamız gerekebilir.
          </p>
          <h2>Kişisel Verilerinizin Açıklanması</h2>
          <h3>İşlem Faaliyetleri</h3>
          <p>
            Şirket bir birleşme, satın alma veya varlık satışına dahilse,
            Kişisel Verileriniz aktarılabilir. Kişisel Verilerinizin aktarılması
            ve farklı bir Gizlilik Politikası'na tabi olması önce size
            bildirilecektir.
          </p>
          <h3>Yasal İşlemler</h3>
          <p>
            Belirli durumlarda, Şirket yasal bir yükümlülük gerektiriyorsa veya
            kamusal otoriteler (örneğin bir mahkeme veya hükümet kurumu)
            tarafından yapılan geçerli taleplere yanıt olarak Kişisel
            Verilerinizi açıklamak zorunda kalabilir.
          </p>
          <h3>Diğer yasal gereklilikler</h3>
          <p>
            Şirket, böyle bir işlemin gerekli olduğuna inanıyorsa Kişisel
            Verilerinizi şu amaçlarla açıklayabilir:
          </p>
          <ul>
            <li>Yasal bir yükümlülüğe uymak</li>
            <li>Şirket'in haklarını veya mülkiyetini korumak ve savunmak</li>
            <li>
              Servis ile ilgili olası yanlış davranışları önlemek veya
              araştırmak
            </li>
            <li>
              Servis'in kullanıcılarının veya genel halkın kişisel güvenliğini
              korumak
            </li>
            <li>Hukuki sorumluluktan korumak</li>
          </ul>

          <h2>Kişisel Verilerinizin Güvenliği</h2>
          <p>
            Kişisel Verilerinizin güvenliği bizim için önemlidir, ancak lütfen
            unutmayın ki İnternet üzerinden iletim veya elektronik depolama
            yöntemi %100 güvenli değildir. Kişisel Verilerinizi korumak için
            ticari olarak kabul edilebilir yöntemleri kullanmaya çalışsak da,
            mutlak güvenliğini garanti edemeyiz.
          </p>

          <h1>Çocukların Gizliliği</h1>
          <p>
            Servisimiz 13 yaşın altındaki herhangi bir kişiye hitap etmez.
            Bilinçli olarak 13 yaşın altındaki herhangi bir kişiden kişisel
            olarak tanımlanabilir bilgi toplamıyoruz. Eğer bir ebeveyn veya
            vasip iseniz ve çocuğunuzun bize Kişisel Veri sağladığını fark
            ederseniz, lütfen bize ulaşın. Eğer, ebeveyn onayı gerekiyorsa ve
            ülkeniz ebeveyn onayını gerektiriyorsa, bilgiyi toplamadan önce
            ebeveyninizin onayını gerekebiliriz.
          </p>

          <h1>Diğer Web Sitelerine Bağlantılar</h1>
          <p>
            Servisimiz, tarafımızca işletilmeyen diğer web sitelerine
            bağlantılar içerebilir. Üçüncü taraf bir bağlantıya tıklarsanız, o
            üçüncü tarafın sitesine yönlendirileceksinizdir. Her ziyaret
            ettiğiniz site için Gizlilik Politikasını gözden geçirmenizi
            kesinlikle tavsiye ederiz.
          </p>
          <p>
            Üçüncü taraf sitelerin içeriği, gizlilik politikaları veya
            uygulamaları üzerinde hiçbir kontrolümüz yoktur ve herhangi bir
            sorumluluk kabul etmiyoruz.
          </p>

          <h1>Bu Gizlilik Politikasının Değişiklikleri</h1>
          <p>
            Gizlilik Politikamızı zaman zaman güncelleyebiliriz. Yeni Gizlilik
            Politikasını bu sayfada yayınlayarak sizi bilgilendireceğiz.
          </p>
          <p>
            Değişikliğin yürürlüğe girmesinden önce size e-posta yoluyla ve/veya
            Servisimiz üzerinde belirgin bir bildiri ile bilgilendireceğiz ve bu
            Gizlilik Politikasının en üstünde "Son güncelleme" tarihini
            güncelleyeceğiz.
          </p>
          <p>
            Lütfen bu Gizlilik Politikasını periyodik olarak gözden geçirmeniz
            önerilir. Bu Gizlilik Politikasındaki değişiklikler, bu sayfada
            yayınlandığında yürürlüğe girer.
          </p>

          <h1>Bize Ulaşın</h1>
          <p>
            Bu Gizlilik Politikası hakkında herhangi bir sorunuz varsa, bize
            aşağıdaki yollarla ulaşabilirsiniz:
          </p>
          <ul>
            <li>E-posta: info@kepicker.com</li>
          </ul>
        </div>
      ) : (
        <div className="content">
          <p>Sürüm 1.0</p>

          <p>
            https://kepicker.com/ adresinde bulunan Kepicker web sitesi,
            Kepicker LLC'ye ait telif haklarına sahip bir çalışmadır. Site'nin
            belirli özellikleri, ilgili özelliklerle bağlantılı olarak Site'de
            yayınlanacak olan ek kurallar, şartlar veya kurallara tabi olabilir.
          </p>

          <p>
            Tüm bu ek kurallar, yönergeler ve kurallar bu Şartlar ile referans
            yoluyla bu Şartlara dahil edilir.
          </p>

          <p>
            Bu Kullanım Koşulları, Sitenin kullanımınızı denetleyen yasal olarak
            bağlayıcı koşulları açıklar. SİTEYE GİRİŞ YAPARAK, BU ŞARTLARI İHLAL
            ETMEDİĞİNİZİ KABUL EDİYORSUNUZ ve bu Şartlara girmek için yetkinliğe
            ve kapasiteye sahip olduğunuzu beyan ediyorsunuz. SİTEYE ERİŞMEK
            İÇİN EN AZ 18 YAŞINDA OLMANIZ GEREKİR. BU ŞARTLARIN TÜM HÜKÜMLERİNİ
            TARTIŞIYORSANIZ, SİTEYE GİRMEYİ VEYA KULLANMAYI REDDEDİN.
          </p>

          <p>
            Bu şartlar, anlaşmazlıkları çözmek için Bölüm 10.2'de bireysel bir
            temelde arabuluculuk kullanmayı gerektirir ve ayrıca bir anlaşmazlık
            durumunda size sunulan çözüm seçeneklerini sınırlar.
          </p>

          <h2>Site Erişimi</h2>

          <p>
            <strong>Bu Şartlara Tabi.</strong> Şirket, Site'ye yalnızca kendi
            kişisel, ticari olmayan kullanımınız için erişim hakkı verir ve bu
            haklar devredilemez, münhasır olmayan, geri alınabilir ve
            sınırlıdır.
          </p>

          <p>
            <strong>Bazı Kısıtlamalar.</strong> Bu Şartlarda size verilen haklar
            aşağıdaki kısıtlamalara tabidir: (a) Site'yi satamaz, kiraya
            veremez, devredemez, devir edemez, dağıtamaz, barındıramaz veya
            başka ticari amaçlarla kullanamazsınız; (b) Site'nin herhangi bir
            bölümünü değiştiremez, türev işler yapamaz, sökemez, ters derleyemez
            veya tersine mühendislik yapamazsınız; (c) Site'ye benzer veya
            rekabetçi bir web sitesi oluşturmak amacıyla Site'ye erişemezsiniz;
            ve (d) burada açıkça belirtilmedikçe, Site'nin hiçbir bölümü
            kopyalanamaz, çoğaltılamaz, dağıtılamaz, yeniden yayınlanamaz,
            indirilemez, görüntülenemez, yayınlanamaz veya iletilmemelidir, aksi
            belirtilmedikçe, gelecekteki sürümler, güncellemeler veya Site'nin
            işlevselliğine eklenen diğer özellikler bu Şartlara tabi olacaktır.
            Site'deki tüm telif hakkı ve diğer mülkiyet bildirimleri, bunların
            tüm kopyalarında saklı kalmalıdır.
          </p>

          <p>
            Şirket, sizi bilgilendirmeksizin Site'yi değiştirme, askıya alma
            veya sona erdirme hakkını saklı tutar. Site'nin değiştirilmesi,
            kesintiye uğraması veya sona erdirilmesi durumunda Şirket'in size
            veya üçüncü taraflara karşı herhangi bir sorumluluğu olmadığını
            kabul ettiniz.
          </p>

          <p>
            <strong>Destek veya Bakım Yok.</strong> Site ile ilgili olarak size
            herhangi bir destek sağlama yükümlülüğü olmadığını kabul edersiniz.
          </p>

          <p>
            Size ait olmayan herhangi bir Kullanıcı İçeriği hariç, Site ve
            içeriğinin tüm fikri mülkiyet haklarının, telif hakkı, patent,
            ticari marka ve ticari sırlar dahil, Şirket veya Şirket'in
            tedarikçileri tarafından sahip olduğunun farkındasınızdır. Bu
            Şartlar ve Site'ye erişim size, Bölüm 2.1'de belirtilen sınırlı
            erişim hakları dışında herhangi bir fikri mülkiyet hakları, başlık
            veya çıkar vermez. Şirket ve tedarikçileri, bu Şartlar'da verilmeyen
            tüm hakları saklar.
          </p>

          <h2>Üçüncü Taraf Bağlantıları ve Reklamlar; Diğer Kullanıcılar</h2>

          <p>
            <strong>Üçüncü Taraf Bağlantıları ve Reklamlar.</strong> Site üçüncü
            taraf web siteleri ve hizmetlerine bağlantılar içerebilir ve/veya
            üçüncü taraflara ait reklamları gösterebilir. Bu tür Üçüncü Taraf
            Bağlantıları ve Reklamlar Şirket'in kontrolü altında değildir ve
            Şirket Üçüncü Taraf Bağlantıları ve Reklamlardan sorumlu değildir.
            Şirket, size bu Üçüncü Taraf Bağlantıları ve Reklamlara sadece bir
            kolaylık olarak erişim sağlar ve Üçüncü Taraf Bağlantıları ve
            Reklamlar hakkında herhangi bir inceleme, onay, izleme, onaylama,
            garanti veya herhangi bir temsilde bulunmaz. Tüm Üçüncü Taraf
            Bağlantılarını ve Reklamları kendi riskiniz altında kullanırsınız ve
            bunu yaparken uygun bir düzeyde dikkat ve takdir uygulamalısınız.
            Üçüncü Taraf Bağlantıları ve Reklamlardan herhangi birine
            tıkladığınızda ilgili üçüncü tarafın şartları ve politikaları
            geçerli olur, bu da üçüncü tarafın gizlilik ve veri toplama
            uygulamalarını içerir.
          </p>

          <p>
            <strong>Diğer Kullanıcılar.</strong> Her Site kullanıcısı, kendi
            Kullanıcı İçeriği'nin tamamen sorumlusudur. Kullanıcı İçeriği'yi
            kontrol etmediğimizden, siz veya başkaları tarafından sağlanan
            Kullanıcı İçeriği'nin sorumluluğunun size ait olduğunu kabul
            edersiniz. Şirket'in bu tür etkileşimlerin sonucu olarak meydana
            gelen herhangi bir kayıp veya hasardan sorumlu olmayacağınızı kabul
            edersiniz. Site kullanıcıları arasında bir anlaşmazlık varsa,
            müdahil olma yükümlülüğümüz olmadığını kabul edersiniz.
          </p>

          <p>
            İşte bu nedenle Şirket ve bizim memurlarımız, çalışanlarımız,
            acentelerimiz, haleflerimiz ve devralanlarımız tarafından, Site ile
            doğrudan veya dolaylı olarak ortaya çıkmış veya doğrudan veya
            dolaylı olarak ilgili olan veya ilgili olan, her türlü geçmiş,
            mevcut ve gelecekteki anlaşmazlık, iddia, anlaşmazlık, talep, hak,
            yükümlülük, sorumluluk, eylem ve sebep dahil her türlü türden ve
            doğadan kayıp veya zarardan, şikayeti sonsuza dek serbest bırakır ve
            feragat ederiz. Eğer bir California sakiniyseniz, yukarıdaki ile
            ilgili olarak, "bir genel feragatname, alacaklı tarafından borçluyla
            olan anlaşmasını önemli ölçüde etkileyeceği bilinse bile kredi
            verici tarafından lehine var olmadığını bilmediği veya
            şüphelenmediği iddiaları genişletmez" şeklindeki California sivil
            kanun maddesi 1542'yi feragat edersiniz.
          </p>

          <p>
            <strong>Cookies ve Web Beacons.</strong> Diğer herhangi bir web
            sitesi gibi, Kepicker 'cookies' kullanır. Bu çerezler,
            ziyaretçilerin tercihlerini ve ziyaretçilerin eriştiği veya ziyaret
            ettiği web sitesinin sayfalarını içeren bilgileri saklamak için
            kullanılır. Bilgiler, kullanıcıların tarayıcı türüne ve/veya diğer
            bilgilere dayalı olarak web sayfası içeriğimizi özelleştirerek
            kullanıcı deneyimini optimize etmek için kullanılır.
          </p>

          <h2>Reddiyeler</h2>

          <p>
            Site "olduğu gibi" ve "mevcut olduğu gibi" sunulur ve şirket ile
            tedarikçilerimiz, açık, zımni veya yasal her türlü garanti ve
            koşulları, ticari olarak uygunluk, belirli bir amaca uygunluk,
            başlık, sessiz zevk, doğruluk veya ihlal etmeme dahil olmak üzere
            her türlü garanti veya koşulu açıkça reddederler.  Biz ve
            tedarikçilerimiz, Site'nin gereksinimlerinizi karşılayacağını,
            kesintisiz, zamanında, güvenli veya hata olmadan kullanılabilir
            olacağını, doğru, güvenilir, virüs veya diğer zararlı kodlardan
            arınmış, eksiksiz, yasal veya güvenli olacağını garanti etmezler. 
            Geçerli yasa, Site ile ilgili herhangi bir garantiyi gerektiriyorsa,
            tüm bu garantiler kullanımın ilk kullanıldığı tarihten itibaren
            doksan (90) gün süreyle sınırlıdır.
          </p>

          <p>
            Bazı yargı bölgeleri zımni garanti reddini kabul etmez, bu nedenle
            yukarıdaki reddetme sizin için geçerli olmayabilir.  Bazı yargı
            bölgeleri zımni garanti süresini sınırlama hakkını tanımaz, bu
            nedenle yukarıdaki sınırlama sizin için geçerli olmayabilir.
          </p>

          <h2>Sorumluluğun Sınırlanması</h2>

          <p>
            Kanunun izin verdiği maksimum ölçüde, hiçbir koşulda şirket veya
            tedarikçilerimiz size veya üçüncü taraflara karşı kaybedilen karlar,
            kaybedilen veriler, yedek ürün tedarik maliyetleri veya bu Koşullar
            veya Site kullanımınıza ilişkin olarak ortaya çıkan veya
            ilişkilendirilen örnek, dolaylı, sonuçsal, örnek, tesadüfi, özel
            veya cezai zararlardan sorumlu tutulamaz.  Site'ye erişim ve
            kullanımınız tamamen sizin inisiyatifiniz ve riskinizdedir ve
            cihazınıza veya bilgisayar sistemine gelebilecek herhangi bir hasar
            veya veri kaybından yalnızca siz sorumlu olacaksınız.
          </p>

          <p>
            Kanunun izin verdiği maksimum ölçüde, burada aksine yer alan her
            şeye rağmen, bu Anlaşma'dan kaynaklanan herhangi bir zarar için size
            olan sorumluluğumuz her zaman en fazla elli ABD doları (ABD $50) ile
            sınırlı olacaktır. Birden fazla talep olması bu sınırı
            genişletmeyecektir.  Tedarikçilerimizin bu Anlaşma'dan veya
            ilişkilendirilen herhangi bir türden sorumluluğu olmayacağını kabul
            edersiniz.
          </p>

          <p>
            Bazı yargı bölgeleri tesadüfi veya sonuçsal zararların sınırlanması
            veya hariç tutulmasına izin vermez, bu nedenle yukarıdaki sınırlama
            veya hariç tutma sizin için geçerli olmayabilir.
          </p>

          <p>
            <strong>Süre ve Sonlandırma.</strong> Bu bölümde belirtilenlere tabi
            olarak, bu Şartlar, Site'yi kullanırken tam kuvvet ve etkide
            kalacaktır.  Şirket, Site'yi kullanma hakkınızı herhangi bir nedenle
            kendi münhasır takdirine bağlı olarak her zaman askıya alabilir veya
            sona erdirebilir, bu Şartlara aykırı Site kullanımı da dahil olmak
            üzere.  Bu Şartlar kapsamındaki haklarınızın sona ermesi durumunda,
            Hesabınız ve Site'ye erişme ve kullanma hakkınız derhal sona
            erecektir.  Hesabınızın sona erdirilmesinin, Hesabınızla
            ilişkilendirilen Kullanıcı İçeriğinin canlı veritabanlarımızdan
            silinmesi anlamına gelebileceğini anlıyorsunuz.  Şirket, bu Şartlar
            altındaki haklarınızın sona ermesinden dolayı sizinle hiçbir
            sorumluluğu olmayacaktır.  Bu Şartlar altındaki haklarınız sona
            erdikten sonra dahi, bu Şartlar'ın aşağıdaki hükümleri geçerliliğini
            koruyacaktır: Bölümler 2 ile 2.5, Bölüm 3 ve Bölümler 4 ile 10.
          </p>

          <h2>Telif Hakkı Politikası.</h2>

          <p>
            Şirket, diğerlerinin fikri mülkiyetine saygı duyar ve sitemizin
            kullanıcılarından aynı özeni göstermelerini ister.  Sitemizle ilgili
            olarak, telif hakkı hukukuna saygı gösteren ve telif hakkı dahil
            fikri mülkiyet haklarını sürekli olarak ihlal eden çevrimiçi sitemiz
            kullanıcılarının çıkarılmasını ve silinmesini sağlayan bir telif
            hakkı politikası benimsemiş ve uygulamış bulunmaktayız.  Eserin
            telif hakkı(s)inin, sitemiz kullanımı yoluyla, bir kullanıcımızın
            telif hakkı(s)ini hukuka aykırı olarak ihlal ettiğine inanıyorsanız
            ve iddia edilen ihlal edici materyalin kaldırılmasını istiyorsanız,
            aşağıdaki bilgileri içeren bir yazılı bildirim (17 U.S.C. § 512(c)
            uyarınca) şeklinde belirlenen Telif Hakkı Temsilcisine sunulmalıdır:
          </p>

          <ul>
            <li>sizin fiziksel veya elektronik imzanız;</li>
            <li>
              iddia ettiğiniz ihlal edilen telif hakkına sahip olduğunuzu iddia
              edilen telif hakkına sahip olduğunuz iddia edilen telif hakkına
              sahip olduğunuzu iddia edilen telif hakkına sahip olduğunuzu iddia
              edilen telif hakkına sahip olduğunuzu iddia edilen telif hakkına
              sahip olduğunuzu iddia edilen telif hakkına sahip olduğunuzu;
            </li>
            <li>
              servislerimizdeki iddia ettiğiniz ihlali ve kaldırmamızı
              istediğiniz materyali tanımlama;
            </li>
            <li>böyle bir materyali bulmamıza izin verecek yeterli bilgi;</li>
            <li>adresiniz, telefon numaranız ve e-posta adresiniz;</li>
            <li>
              itiraz edilen materyalin telif hakkı sahibi, temsilcisi veya yasa
              gereği telif hakkı sahibi tarafından yetkilendirilmediğine dair
              iyi niyetli bir inanç taşıdığınıza dair bir beyan; ve
            </li>
            <li>
              bildirimin bilgilerinin doğru olduğuna ve yalan beyanda bulunmanın
              17 U.S.C. § 512(f) uyarınca, bize verilen yazılı bildirim ve telif
              hakkı ihlali iddiasıyla bağlantılı olarak tarafımızdan tahakkuk
              eden herhangi bir zarar, maliyet ve avukat ücretlerine karşı
              tarafınızı otomatik olarak sorumlu tuttuğuna dair bir beyan.
            </li>
          </ul>

          <p>
            Lütfen unutmayın ki, 17 U.S.C. § 512(f) uyarınca, yazılı bildirimde
            malzemenin maddi gerçeği yanıltması, ihlal eden tarafın tazminat
            sorumluluğuna yol açar.
          </p>

          <h2>Genel</h2>

          <p>
            Bu Şartlar ara sıra revize edilebilir ve önemli değişiklikler
            yaparsak, sizi en son sağladığınız e-posta adresinize bir e-posta
            göndererek ve/veya değişikliklere ilişkin duyuruyu sitemizde önemli
            bir şekilde yayınlayarak sizi bilgilendirebiliriz.  Sizin en güncel
            e-posta adresinizi bize sağlama sorumluluğu sizindir.  Size son
            sağladığınız e-posta adresinin geçerli olmaması durumunda, size
            gönderilen değişiklikleri açıklayan e-postayı gönderimimiz, yine de
            değişikliklerin duyurulduğu şekilde etkili bir bildirim
            oluşturacaktır.  Bu Şartlar'da yapılan herhangi bir değişiklik, size
            e-posta iletişimimizin bir e-posta bildirimini size göndermemizin
            ardından otuz (30) takvim günü içinde veya değişikliklerin sitemizde
            duyurulmasından otuz (30) takvim günü içinde en erken etkili
            olacaktır.  Bu değişiklikler, sitemizin yeni kullanıcıları için
            hemen etkili olacaktır.  Bu tür değişikliklerin duyurulmasını
            takiben sitemizi kullanmaya devam etmek, bu değişiklikleri kabul
            ettiğinizin ve bu değişikliklerin koşullarına ve şartlarına bağlı
            olmayı kabul ettiğinizin bir göstergesi olacaktır. 
          </p>

          <p>
            <strong>Uyuşmazlık Çözümü.</strong> Bu Tahkim Anlaşması'nı
            dikkatlice okuyun. Şirket ile olan sözleşmenizin bir parçasıdır ve
            haklarınızı etkiler.  Zorunlu BAĞLAYICI TAHKİM VE SINIF DAVA REDDİNİ
            içerir.
          </p>

          <p>
            <strong>Tahkim Anlaşması'nın Uygulanabilirliği.</strong> Şirket
            tarafından sağlanan herhangi bir ürün veya hizmetin kullanımı veya
            Şirket ile bağlantılı olan Telif Hakkı veya küçük iddia mahkemesinde
            çözülemeyen tüm iddialar ve uyuşmazlıklar, bu Tahkim Anlaşması'nın
            koşulları altında bireysel olarak bağlayıcı tahkim yoluyla
            çözülecektir.  Aksi kararlaştırılmadıkça, tüm tahkim işlemleri
            İngilizce yapılacaktır. Bu Tahkim Anlaşması, size, Şirket ve
            Şirketin yan kuruluşları, bağlı kuruluşları, temsilcileri,
            çalışanları, hukuki öncüleri, halefleri ve devralanları, ayrıca
            Şartlar altında sunulan hizmetlerin veya malzemelerin yetkili veya
            yetkisiz kullanıcıları veya yararlanıcıları dahil olmak üzere tüm
            kullanıcılar veya yararlanıcılar için geçerlidir.
          </p>

          <p>
            <strong>
              Bildirim Gerekliliği ve Gayriresmi Uyuşmazlık Çözümü.
            </strong>{" "}
            Taraflardan herhangi biri tahkime başvurmadan önce, tarafın, iddia
            veya uyuşmazlığın doğasını ve temelini ve talep edilen rahatlama ve
            dilekçeyi tanımlayan bir Uyuşmazlık Bildirimi'nin diğer tarafa
            yazılı olarak gönderilmesi gerekmektedir.  Şirket'e gönderilen Bir
            Bildiri, Delaware, ABD'ye gönderilmelidir. Bildiri alındıktan sonra,
            siz ve Şirket iddia veya uyuşmazlığı gayriresmi olarak çözmeye
            çalışabilirsiniz.  İlgili Bildiri alındıktan sonra, siz ve Şirket
            Bildiri alındıktan sonra otuz (30) gün içinde iddiayı veya
            uyuşmazlığı çözmezseniz, her iki taraf da bir tahkim işlemi
            başlatabilir.  Herhangi bir tarafın yaptığı uzlaşma teklifinin
            miktarı, tahkimcinin herhangi bir tarafın hak kazandığı ödül
            miktarını belirledikten sonra tahkimciye açıklanmamalıdır.
          </p>

          <p>
            <strong>Tahkim Kuralları.</strong> Tahkim, bu bölümde belirtilenler
            dışında bir tahkim hizmeti sunan kurumsal bir tahkim hizmeti
            sağlayıcısı olarak kurulmuş olan American Arbitration Association
            (AAA) tarafından başlatılacaktır.  Tahkim mümkün değilse, taraflar
            bir alternatif ADR Sağlayıcı seçmeye anlaşmalıdır.  ADR
            Sağlayıcı'nın kuralları, Şartlar ile çelişmeyen hükümler dışında,
            tahkimin tüm yönlerini düzenler.  Tahkim üzerine hükmeden AAA
            Tüketici Tahkim Kuralları, adr.org web sitesinde çevrimiçi olarak
            veya 1-800-778-7879 numaralı AAA'yı arayarak temin edilebilir. 
            Tahkim, tek, tarafsız bir tahkimci tarafından yürütülecektir.  Talep
            edilen ödül miktarı on Bin Amerikan Doları (ABD $10,000.00) veya
            daha az olan herhangi bir iddia veya uyuşmazlık, rahatlama talep
            eden tarafın seçeneğine göre, görünmeme dayalı bağlayıcı tahkim
            yoluyla çözülebilir.  Talep edilen ödül miktarı On Bin Amerikan
            Doları (ABD $10,000.00) veya daha fazla olan iddia veya
            uyuşmazlıklar için bir duruşma hakkı, Tahkim Kuralları tarafından
            belirlenecektir.  Herhangi bir duruşma, ikamet ettiğiniz yerden 100
            mil içinde bir yerde yapılacaktır, ancak Amerika Birleşik Devletleri
            dışında ikamet ediyorsanız ve taraflar başka bir şekilde
            anlaşmazlarsa.  Amerika Birleşik Devletleri dışında ikamet
            ediyorsanız, tahkimci, taraflara herhangi bir sözlü duruşmanın
            tarihini, saati ve yerini makul bir şekilde bildirecektir.  Tahkimci
            tarafından verilen ödülün herhangi bir mahkemeye kaydedilmesi
            mümkündür.  Tahkimci size, tahkim başlatmadan önce size yaptığımız
            son uzlaşma teklifinden daha büyük bir ödül verirse, Şirket size
            ödül veya 2.500,00 ABD Doları (ABD $2,500.00) tutarında daha büyük
            olanı ödeyecektir.  Her taraf, tahkimden kaynaklanan kendi
            masraflarını ve ödemelerini taşıyacak ve ADR Sağlayıcı'nın
            ücretlerinin ve masraflarının eşit payını ödeyecektir.
          </p>

          <p>
            <strong>Görünmeme Dayalı Tahkim İçin Ek Kurallar.</strong> Görünmeme
            dayalı tahkim seçilmişse, tahkim telefonla, çevrimiçi ve/veya sadece
            yazılı başvurulara dayalı olarak yapılacaktır; belirli yöntem,
            tahkim başlatan tarafından seçilecektir. Taraflarca aksi
            kararlaştırılmadıkça, tahkim kişisel görünüşler veya tanıklar
            içermeyecektir.
          </p>

          <p>
            <strong>Zaman Sınırları.</strong> Siz veya Şirket tahkimi
            başlatırsanız, tahkim eylemi ilgili iddiaya ilişkin olarak AAA
            Kuralları'nda belirtilen herhangi bir zamanaşımı süresi içinde ve
            ilgili iddiaya ilişkin olarak AAA Kuralları tarafından belirlenen
            herhangi bir süre içinde başlatılmalı ve/veya talep edilmelidir.
          </p>

          <p>
            <strong>Tahkimcinin Yetkisi.</strong> Tahkim başlatılırsa, tahkimci
            sizin ve Şirketin haklarını ve yükümlülüklerini kararlaştıracaktır
            ve uyuşmazlık başka hiçbir mesele ile birleştirilmeyecek veya başka
            hiçbir davayla veya taraf ile birleştirilmeyecektir.  Tahkimci,
            iddiayı tümüyle veya bir kısmını sonuçlandırmaya yönelik hareketleri
            kabul etme yetkisine sahip olacaktır.  Tahkimci, para cezaları verme
            yetkisine sahip olacak ve uygulanabilir hukuk, AAA Kuralları ve
            Şartlar uyarınca bir bireyin kullanabileceği herhangi bir parasal
            olmayan çözüm veya rahatlama verme yetkisine sahip olacaktır. 
            Tahkimci, ödülün temel bulgularını ve sonuçlarını açıklayan yazılı
            bir ödül ve karar bildirisi yayınlayacaktır.  Tahkimci, mahkeme
            hakim bir hakim gibi bireysel bir temelde rahatlama verme yetkisine
            sahiptir.  Tahkimcinin ödülü size ve Şirket'e karşı nihai ve
            bağlayıcıdır.
          </p>

          <p>
            <strong>Jüri Duruşmasının Reddi.</strong> TARAFLAR, MAHKEMEYE GİTME
            VE BİR HAKİM VEYA JÜRİ ÖNÜNDE YARGILAMA HAKLARINI REDDETMEKTEDİRLER,
            bunun yerine tüm iddiaların ve uyuşmazlıkların bu Tahkim Anlaşması
            uyarınca tahkim yoluyla çözüleceğini seçmektedirler.  Tahkim
            prosedürleri genellikle mahkemede uygulanabilir kurallardan daha
            sınırlı, daha verimli ve daha az maliyetli olup, mahkemede çok
            sınırlı bir gözden geçirme ile karşı karşıyadır.  Herhangi bir dava,
            sizin ve Şirket arasında bir tahkim ödülünü iptal etmek veya
            uygulamak veya başka bir şekilde mahkemede ortaya çıkarsa, SİZ VE
            ŞİRKET BİR HAKİM JÜRİ DURUŞMASI HAKLARINI REDDEDİYORSUNUZ, bunun
            yerine uyuşmazlığın bir hakim tarafından çözüleceğini seçiyorsunuz.
          </p>

          <p>
            <strong>Sınıf veya Birleştirilmiş Davaların Reddi.</strong> Bu
            tahkim anlaşması kapsamındaki tüm iddialar ve uyuşmazlıklar,
            bireysel bir temelde tahkim veya dava edilmeli ve başka hiçbir
            müşteri veya kullanıcının iddiaları diğer müşteri veya
            kullanıcıların iddiaları ile birleştirilip birleştirilmeyeceği veya
            birleştirilmeyeceği sorunu özellikle ayrı ayrı çözülemez.
          </p>

          <p>
            <strong>Gizlilik.</strong> Tahkim işleminin tüm yönleri sıkı bir
            şekilde gizli olacaktır. Taraflar, yasaya aykırı olmadıkça gizliliği
            koruma konusunda anlaşmışlardır. Bu paragraf, bu Anlaşmayı
            uygulamak, bir tahkim ödülünü uygulamak veya injunktif veya eşitlik
            sağlamak için yasal olarak gereken herhangi bir bilgiyi bir
            mahkemeye sunmaktan alıkoymayacaktır.
          </p>

          <p>
            <strong>Ayrılabilirlik.</strong> Bu Tahkim Anlaşması'nın herhangi
            bir bölümü veya bölümleri, yetkili bir mahkeme tarafından yasaya
            aykırı veya uygulanamaz bulunursa, bu belirli bölüm veya bölümler
            işe yaramaz ve etkisiz olacak ve Anlaşmanın geri kalanı tam kuvvet
            ve etkisiyle devam edecektir.
          </p>

          <p>
            <strong>Vazgeçme Hakkı.</strong> Bu Tahkim Anlaşması'nda belirtilen
            hakların ve sınırlamaların herhangi biri veya tümü, iddia edilen
            tarafça feragat edilebilir. Bu tür bir feragat, bu Tahkim
            Anlaşması'nın diğer herhangi bir bölümünü feragat etmez veya
            etkilemez.
          </p>

          <p>
            <strong>Anlaşmanın Devamı.</strong> Bu Tahkim Anlaşması, Şirket ile
            ilişkinizin sona ermesini takiben geçerliliğini koruyacaktır.
          </p>

          <p>
            <strong>Küçük İhtilaflar Mahkemesi.</strong> Her iki taraf da
            yukarıdakilere rağmen, küçük ihtilaflar mahkemesinde bireysel bir
            dava açabilir.
          </p>

          <p>
            <strong>Acil Eşitlik Sağlama.</strong> Yukarıdakilere rağmen,
            taraflardan biri, tahkim sırasında durumu korumak için bir eyalet
            veya federal mahkemede acil eşitlik sağlamak için başvurabilir.
            Geçici önlemlerin istenmesi, bu Tahkim Anlaşması altındaki diğer
            haklardan veya yükümlülüklerden herhangi birinin feragat edildiği
            anlamına gelmez.
          </p>

          <p>
            <strong>Tahkime Tabi Olmayan İddialar.</strong> Yukarıdakilere
            rağmen, iftira iddiaları, Bilgisayar Sahtekarlık ve Suistimal
            Yasası'na aykırılık ve diğer tarafın patent, telif hakkı, ticari
            marka veya ticari sırlarının ihlali veya izinsiz kullanımı bu Tahkim
            Anlaşması'na tabi olmayacaktır.
          </p>

          <p>
            Herhangi bir durumda yukarıdaki Tahkim Anlaşması tarafından
            taraflara mahkemede dava açma izni verildiğinde, taraflar bu amaçlar
            için California, ABD İli İçindeki mahkemelerin kişisel yargı
            yetkisine sunulmayı kabul ederler.
          </p>

          <p>
            Site, ABD ihracat kontrol yasalarına tabi olabilir ve diğer
            ülkelerdeki ihracat veya ithalat düzenlemelerine tabi olabilir.
            Şirket'ten edinilen ABD teknik verilerini veya bu verileri kullanan
            ürünleri, ABD ihracat yasaları veya düzenlemelerine aykırı olarak
            doğrudan veya dolaylı olarak ihraç etmeyeceğinizi kabul edersiniz.
          </p>

          <p>
            Şirket, 10.8. Bölümde belirtilen adreste bulunmaktadır. Eğer bir
            California sakini iseniz, şikayetlerinizi California Tüketici İşleri
            Departmanı Tüketici Ürünleri Bölümü Şikayet Yardım Birimi'ne yazılı
            olarak 400 R Street, Sacramento, CA 95814 adresine veya (800)
            952-5210 numaralı telefonla ileterek bildirebilirsiniz.
          </p>

          <p>
            <strong>Elektronik İletişim.</strong> Aranızdaki iletişimler,
            Site'yi kullanıyor olmanızı veya bize e-posta gönderiyor olmanızı,
            veya Şirket'in Site üzerinde bildirimler yayınladığınızı veya
            e-posta ile sizinle iletişim kurduğunu kullanır. Sözleşme gereği
            olarak (a) Şirket'ten elektronik bir formda iletişim almayı kabul
            edersiniz; ve (b) Şirket'in size elektronik olarak sağladığı tüm
            koşul ve şartlar, anlaşmalar, bildirimler, açıklamalar ve diğer
            iletişimlerin, sert kopya yazılı formda olsa aynı yasal yükümlülüğü
            karşılamış olacağını kabul edersiniz.
          </p>

          <p>
            <strong>Tam Koşullar.</strong> Bu Koşullar, Site kullanımına ilişkin
            sizin ve bizim aramızdaki anlaşmanın tamamını oluşturur. Bu
            Koşulların herhangi bir hakkını veya hükmünü kullanmamış veya
            uygulamamış olmamız, böyle bir hakkın veya hükmün feragat edildiği
            anlamına gelmez. Bu Koşullardaki bölüm başlıkları sadece kolaylık
            sağlamak amacıyla vardır ve yasal veya sözleşmeye dayalı bir etkisi
            yoktur. "İçerir" kelimesi "sınırlama olmaksızın içerir" anlamına
            gelir. Bu Koşullardaki herhangi bir hüküm yasaya aykırı veya
            uygulanamaz ise, bu Koşulların diğer hükümleri etkilenmeden kalacak
            ve yasaya izin verilen en fazla ölçüde geçerli ve uygulanabilir hale
            getirilmiş kabul edilecektir. Şirket ile ilişkiniz bağımsız bir
            yüklenici olarak bir ilişkidir ve hiçbir taraf diğerinin temsilcisi
            veya ortağı değildir. Bu Koşullarda belirtilen koşullar ve hükümler
            devralanlar için bağlayıcı olacaktır.
          </p>

          <p>
            <strong>Gizliliğiniz.</strong> Lütfen Gizlilik Politikamızı okuyun.
            Daha fazla bilgi için lütfen Gizlilik Politikamızı inceleyin.
            Herhangi bir sorunuz veya endişeniz varsa, lütfen bize ulaşmaktan
            çekinmeyin.
          </p>

          <p>
            <strong>Telif/Hak Bilgileri.</strong> Telif Hakkı ©. Tüm hakları
            saklıdır. Site'de görünen tüm ticari markalar, logolar ve hizmet
            markaları bizim mülkiyetimizde veya diğer üçüncü tarafların
            mülkiyetinde olan varlıklardır. Bu Markaları, önceden yazılı iznimiz
            veya Markaların sahibi olabilecek böyle üçüncü tarafın izni
            olmaksızın kullanmanıza izin verilmez.
          </p>
          <h4>
            Kepicker'ın Google API'lerinden alınan bilgileri kullanması ve başka
            herhangi bir uygulamaya aktarması, Sınırlı Kullanım gereksinimleri
            de dahil olmak üzere{" "}
            <a
              href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
              target="_blank"
            >
              <u>Google API Hizmetleri Kullanıcı Verileri Politikasına</u>
            </a>{" "}
            uygun olacaktır.
          </h4>
          <h2>İletişim Bilgileri</h2>

          <p>Adres: Delaware, ABD</p>
          <p>E-posta: info@kepicker.com</p>
        </div>
      )}
    </>
  );
};

export default TrTerms;
