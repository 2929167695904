import React from "react";

const DeTerms = ({ isPolicy }) => {
  return (
    <>
      {isPolicy ? (
        <div className="content">
          <p>Zuletzt aktualisiert: 21. Oktober 2023</p>
          <p>
            Diese Datenschutzrichtlinie beschreibt unsere Richtlinien und
            Verfahren zur Erfassung, Nutzung und Offenlegung Ihrer Informationen
            bei der Nutzung des Dienstes und informiert Sie über Ihre
            Datenschutzrechte und wie das Gesetz Sie schützt.
          </p>
          <p>
            Wir verwenden Ihre persönlichen Daten, um den Dienst bereitzustellen
            und zu verbessern. Durch die Nutzung des Dienstes stimmen Sie der
            Erfassung und Verwendung von Informationen gemäß dieser
            Datenschutzrichtlinie zu.
            <br />
            <strong>
              Unsere Datenschutzrichtlinie gewährleistet, dass sowohl Ihre als
              auch die persönlichen Daten unserer anderen Benutzer sicher sind.
              Diese Richtlinie gibt klar an, welche Informationen erfasst
              werden, wie sie verwendet werden und wie sie geschützt werden. Wir
              respektieren Ihre Privatsphäre und arbeiten mit größter Sorgfalt
              daran, Ihre Informationen bestmöglich zu schützen.
            </strong>
          </p>
          <h1>Auslegung und Definitionen</h1>
          <h2>Auslegung</h2>
          <p>
            Die Wörter, deren Anfangsbuchstabe großgeschrieben ist, haben unter
            den folgenden Bedingungen definierte Bedeutungen. Die folgenden
            Definitionen haben die gleiche Bedeutung, unabhängig davon, ob sie
            im Singular oder im Plural erscheinen.
          </p>
          <h2>Definitionen</h2>
          <p>Zu den Zwecken dieser Datenschutzrichtlinie gehören:</p>

          <ul>
            <li>
              <p>
                <strong>Konto</strong> bezeichnet ein einzigartiges Konto, das
                für Sie erstellt wurde, um auf unseren Dienst oder Teile unseres
                Dienstes zuzugreifen.
              </p>
            </li>
            <li>
              <p>
                <strong>Affiliate</strong> bedeutet eine Einrichtung, die von
                einer Partei kontrolliert wird, die von einer Partei
                kontrolliert wird oder unter gemeinsamer Kontrolle mit einer
                Partei steht, wobei "Kontrolle" den Besitz von 50 % oder mehr
                der Anteile, Eigenkapitalbeteiligungen oder anderer Wertpapiere
                bedeutet, die zur Wahl von Direktoren oder anderer leitender
                Organe berechtigt sind.
              </p>
            </li>
            <li>
              <p>
                <strong>Unternehmen</strong> (in dieser Vereinbarung als "das
                Unternehmen", "Wir", "Uns" oder "Unser" bezeichnet) bezieht sich
                auf Kepicker Software LLC, Delaware - USA.
              </p>
            </li>
            <li>
              <p>
                <strong>Cookies</strong> sind kleine Dateien, die von einer
                Website auf Ihrem Computer, Mobilgerät oder einem anderen Gerät
                platziert werden und Informationen zu Ihrer Browser-Historie auf
                dieser Website und für viele andere Zwecke enthalten.
              </p>
            </li>
            <li>
              <p>
                <strong>Land</strong> bezieht sich auf: Delaware, Vereinigte
                Staaten
              </p>
            </li>
            <li>
              <p>
                <strong>Gerät</strong> bezeichnet jedes Gerät, das auf den
                Dienst zugreifen kann, wie einen Computer, ein Mobiltelefon oder
                ein digitales Tablet.
              </p>
            </li>
            <li>
              <p>
                <strong>Persönliche Daten</strong> sind Informationen, die sich
                auf eine identifizierte oder identifizierbare Person beziehen.
              </p>
            </li>
            <li>
              <p>
                <strong>Dienst</strong> bezieht sich auf die Website.
              </p>
            </li>
            <li>
              <p>
                <strong>Dienstanbieter</strong> bezeichnet natürliche oder
                juristische Personen, die im Auftrag des Unternehmens Daten
                verarbeiten. Hierbei handelt es sich um Drittanbieterunternehmen
                oder Einzelpersonen, die vom Unternehmen beauftragt werden, den
                Dienst zu erleichtern, den Dienst im Auftrag des Unternehmens
                bereitzustellen, Dienstleistungen im Zusammenhang mit dem Dienst
                durchzuführen oder dem Unternehmen bei der Analyse der Nutzung
                des Dienstes zu unterstützen.
              </p>
            </li>
            <li>
              <p>
                <strong>Nutzungsdaten</strong> beziehen sich auf automatisch
                erfasste Daten, die entweder durch die Nutzung des Dienstes
                generiert werden oder aus der Service-Infrastruktur selbst
                stammen (z. B. die Dauer eines Seitenbesuchs).
              </p>
            </li>
            <li>
              <p>
                <strong>Website</strong> bezieht sich auf Kepicker, erreichbar
                unter{" "}
                <a
                  href="https://kepicker.com"
                  rel="external nofollow noopener"
                  target="_blank"
                >
                  https://kepicker.com
                </a>
              </p>
            </li>
            <li>
              <p>
                <strong>Sie</strong> bedeutet die Person, die auf den Dienst
                zugreift oder den Dienst verwendet, oder das Unternehmen oder
                eine andere juristische Person, die im Namen einer solchen
                Person auf den Dienst zugreift oder ihn verwendet, wie es
                zutrifft.
              </p>
            </li>
          </ul>

          <h1>Sammlung und Verwendung Ihrer persönlichen Daten</h1>
          <div>
            <h2>Datenschutzrichtlinie für die Kepicker Chrome-Erweiterung</h2>
            <p>
              <strong>Zuletzt aktualisiert:</strong> 17.01.2024
            </p>

            <h3>Über diese Richtlinie</h3>
            <p>
              Dieser Abschnitt behandelt die Datenverarbeitungspraktiken der
              Kepicker Chrome-Erweiterung und ergänzt die allgemeinen
              Datenschutzpraktiken, die in unserer Hauptdatenschutzrichtlinie
              beschrieben sind.
            </p>

            <h3>1. Datenerhebung in der Erweiterung</h3>
            <h4>Arten der gesammelten Daten</h4>
            <p>
              Die Kepicker-Erweiterung sammelt E-Mail-Adressen von Benutzern
              während des Registrierungs- und Anmeldeprozesses. Dies ist die
              Hauptart der persönlichen Daten, die wir innerhalb der Erweiterung
              verarbeiten.
            </p>

            <h4>Methode der Datenerhebung</h4>
            <p>
              Benutzer-E-Mail-Adressen werden direkt über die Benutzereingabe
              während der Registrierung oder Anmeldung gesammelt.
            </p>

            <h3>2. Verwendung der Daten</h3>
            <h4>Zweck der Datenerhebung</h4>
            <p>
              Die Erhebung von E-Mail-Adressen dient in erster Linie der
              Erstellung und Verwaltung von Benutzerkonten, der Erleichterung
              von Anmeldeprozessen und der Gewährleistung eines sicheren Zugangs
              zu unseren Erweiterungsfunktionen.
            </p>

            <h4>Datenverarbeitung</h4>
            <p>
              Wir verarbeiten oder analysieren E-Mail-Daten nicht über die
              Kontoverwaltung und Authentifizierung hinaus.
            </p>

            <h3>3. Datenweitergabe und Offenlegung</h3>
            <h4>Weitergabe an Dritte für Zahlungsabwicklungen</h4>
            <p>
              Für Käufe über Kepicker teilen wir notwendige Daten, wie
              E-Mail-Adressen und Namen, mit Stripe, unserem Partner für
              Zahlungsabwicklungen, ausschließlich zur Erleichterung von
              Zahlungstransaktionen.
            </p>

            <h4>Rechtliche Anforderungen</h4>
            <p>
              Wir können personenbezogene Daten weitergeben, wenn wir gesetzlich
              dazu verpflichtet sind oder um rechtlichen Prozessen zu
              entsprechen.
            </p>

            <h3>4. Einwilligung und Kontrolle der Nutzer</h3>
            <h4>Mechanismus zur Einwilligung</h4>
            <p>
              Die Einwilligung der Benutzer zur Erhebung von E-Mail-Adressen
              wird während des Registrierungsprozesses eingeholt. Benutzer
              werden über die Datenerhebung informiert und müssen dieser
              zustimmen, bevor sie fortfahren.
            </p>

            <h4>Kontrolloptionen für Benutzer</h4>
            <p>
              Benutzer können ihre E-Mail-Informationen aktualisieren oder die
              Löschung ihres Kontos über die Kontoeinstellungen in der
              Erweiterung anfordern. Anfragen zur Datenlöschung werden gemäß den
              geltenden Datenschutzgesetzen bearbeitet.
            </p>

            <h3>5. Sicherheit der Daten</h3>
            <p>
              Wir ergreifen verschiedene Sicherheitsmaßnahmen, um Benutzerdaten
              zu schützen, einschließlich sicherer Datenübertragung und
              -speicherung. Unser Engagement für Datensicherheit erstreckt sich
              auch auf unsere Interaktionen mit Drittanbietern wie Stripe.
            </p>

            <h3>6. Kontaktinformationen</h3>
            <p>
              Wenn Sie Fragen oder Bedenken bezüglich unserer
              Datenverarbeitungspraktiken innerhalb der Kepicker-Erweiterung
              haben, kontaktieren Sie uns bitte unter{" "}
              <a href="mailto:info@kepicker.com">info@kepicker.com</a>.
            </p>

            <p>
              Bitte beachten Sie, dass diese Datenschutzrichtlinie Änderungen
              und Aktualisierungen unterliegt, die auf dieser Seite
              widergespiegelt werden. Benutzer werden ermutigt, unsere
              Richtlinie regelmäßig zu überprüfen.
            </p>
          </div>
          <h3>Persönliche Daten</h3>
          <p>
            Während der Nutzung unseres Dienstes können wir Sie bitten, uns
            bestimmte persönlich identifizierbare Informationen zur Verfügung zu
            stellen, die dazu verwendet werden können, Sie zu kontaktieren oder
            zu identifizieren. Persönlich identifizierbare Informationen können,
            sind jedoch nicht beschränkt auf:
          </p>
          <ul>
            <li>
              <p>E-Mail-Adresse</p>
            </li>
            <li>
              <p>Vorname und Nachname</p>
            </li>
            <li>
              <p>Telefonnummer</p>
            </li>
            <li>
              <p>Adresse, Bundesland, Provinz, PLZ/Postleitzahl, Stadt</p>
            </li>
            <li>
              <p>Nutzungsdaten</p>
            </li>
          </ul>
          <h3>Nutzungsdaten</h3>
          <p>
            Nutzungsdaten werden automatisch erfasst, wenn der Dienst verwendet
            wird.
          </p>
          <p>
            Nutzungsdaten können Informationen wie die Internetprotokolladresse
            Ihres Geräts (z. B. IP-Adresse), Browsertyp, Browserversion, die von
            Ihnen besuchten Seiten unseres Dienstes, Datum und Uhrzeit Ihres
            Besuchs, die auf diesen Seiten verbrachte Zeit, eindeutige
            Gerätekennungen und andere diagnostische Daten enthalten.
          </p>
          <p>
            Wenn Sie auf den Dienst über ein Mobilgerät zugreifen, können wir
            bestimmte Informationen automatisch erfassen, einschließlich, jedoch
            nicht beschränkt auf den Typ des von Ihnen verwendeten Mobilgeräts,
            Ihre eindeutige Gerätekennung, die IP-Adresse Ihres Mobilgeräts, Ihr
            mobiles Betriebssystem, den Typ des von Ihnen verwendeten mobilen
            Internetbrowsers, eindeutige Gerätekennungen und andere
            diagnostische Daten.
          </p>

          <p>
            Wir können auch Informationen sammeln, die Ihr Browser sendet, wann
            immer Sie unseren Dienst besuchen oder wenn Sie auf den Dienst über
            ein Mobilgerät zugreifen.
          </p>
          <h3>Tracking-Technologien und Cookies</h3>
          <p>
            Wir verwenden Cookies und ähnliche Tracking-Technologien, um die
            Aktivität auf unserem Dienst zu verfolgen und bestimmte
            Informationen zu speichern. Verwendete Tracking-Technologien sind
            Beacons, Tags und Skripte, um Informationen zu sammeln und zu
            verfolgen sowie unseren Dienst zu verbessern und zu analysieren. Die
            von uns verwendeten Technologien können Folgendes umfassen:
          </p>
          <ul>
            <li>
              <strong>Cookies oder Browser-Cookies.</strong> Ein Cookie ist eine
              kleine Datei, die auf Ihrem Gerät platziert wird. Sie können Ihren
              Browser anweisen, alle Cookies abzulehnen oder anzuzeigen, wenn
              ein Cookie gesendet wird. Wenn Sie jedoch keine Cookies
              akzeptieren, können Sie einige Teile unseres Dienstes
              möglicherweise nicht nutzen. Sofern Sie Ihre Browsereinstellung
              nicht so angepasst haben, dass sie Cookies ablehnt, kann unser
              Dienst Cookies verwenden.
            </li>
            <li>
              <strong>Web Beacons.</strong> Bestimmte Abschnitte unseres
              Dienstes und unsere E-Mails können kleine elektronische Dateien
              enthalten, die als Web Beacons bekannt sind (auch als Clear Gifs,
              Pixel Tags und Single-Pixel Gifs bezeichnet) und es dem
              Unternehmen ermöglichen, beispielsweise Benutzer zu zählen, die
              diese Seiten besucht haben oder eine E-Mail geöffnet haben, sowie
              andere damit verbundene Website-Statistiken (zum Beispiel die
              Erfassung der Beliebtheit eines bestimmten Abschnitts und die
              Überprüfung der System- und Serverintegrität).
            </li>
          </ul>
          <p>
            Cookies können "Persistent" oder "Session" Cookies sein. Persistent
            Cookies verbleiben auf Ihrem persönlichen Computer oder Mobilgerät,
            wenn Sie offline gehen, während Session Cookies gelöscht werden,
            sobald Sie Ihren Webbrowser schließen. Weitere Informationen zu
            Cookies finden Sie auf der{" "}
            <a
              href="https://www.kaspersky.com/resource-center/definitions/cookies"
              target="_blank"
            >
              Kaspersky-Website
            </a>{" "}
            Artikel.
          </p>
          <p>
            Wir verwenden sowohl Session- als auch Persistent Cookies für die
            unten aufgeführten Zwecke:
          </p>

          <ul>
            <li>
              <p>
                <strong>Notwendige / Essential Cookies</strong>
              </p>
              <p>Art: Session Cookies</p>
              <p>Verwaltet von: Uns</p>
              <p>
                Zweck: Diese Cookies sind notwendig, um Ihnen die auf der
                Website verfügbaren Dienste zur Verfügung zu stellen und es
                Ihnen zu ermöglichen, einige ihrer Funktionen zu nutzen. Sie
                helfen bei der Authentifizierung von Benutzern und verhindern
                betrügerische Nutzung von Benutzerkonten. Ohne diese Cookies
                können die von Ihnen angeforderten Dienste nicht bereitgestellt
                werden, und wir verwenden diese Cookies nur, um Ihnen diese
                Dienste zur Verfügung zu stellen.
              </p>
            </li>
            <li>
              <p>
                <strong>
                  Cookies-Richtlinien / Hinweis zur Zustimmung zu Cookies
                </strong>
              </p>
              <p>Art: Persistente Cookies</p>
              <p>Verwaltet von: Uns</p>
              <p>
                Zweck: Diese Cookies identifizieren, ob Benutzer die Verwendung
                von Cookies auf der Website akzeptiert haben.
              </p>
            </li>
            <li>
              <p>
                <strong>Funktionscookies</strong>
              </p>
              <p>Art: Persistente Cookies</p>
              <p>Verwaltet von: Uns</p>
              <p>
                Zweck: Diese Cookies ermöglichen es uns, die von Ihnen
                getroffenen Auswahlmöglichkeiten bei der Nutzung der Website zu
                speichern, z. B. Ihre Anmeldeinformationen oder
                Spracheinstellungen zu speichern. Der Zweck dieser Cookies
                besteht darin, Ihnen ein persönlicheres Erlebnis zu bieten und
                zu verhindern, dass Sie Ihre Präferenzen bei jeder Nutzung der
                Website erneut eingeben müssen.
              </p>
            </li>
          </ul>
          <p>
            Weitere Informationen zu den von uns verwendeten Cookies und Ihren
            Cookie-Einstellungen finden Sie in unserer Cookies-Richtlinie oder
            im Cookie-Abschnitt unserer Datenschutzrichtlinie.
          </p>
          <h2>Verwendung Ihrer persönlichen Daten</h2>
          <p>
            Das Unternehmen kann persönliche Daten für folgende Zwecke
            verwenden:
          </p>
          <ul>
            <li>
              <p>
                <strong>
                  Um unseren Dienst bereitzustellen und aufrechtzuerhalten
                </strong>
                , einschließlich der Überwachung der Nutzung unseres Dienstes.
              </p>
            </li>
            <li>
              <p>
                <strong>Zur Verwaltung Ihres Kontos:</strong> zur Verwaltung
                Ihrer Registrierung als Benutzer des Dienstes. Die von Ihnen
                bereitgestellten persönlichen Daten können Ihnen Zugang zu
                verschiedenen Funktionen des Dienstes geben, die Ihnen als
                registriertem Benutzer zur Verfügung stehen.
              </p>
            </li>
            <li>
              <p>
                <strong>Zur Erfüllung eines Vertrags:</strong> zur Entwicklung,
                Erfüllung und Durchführung des Kaufvertrags für die von Ihnen
                gekauften Produkte, Waren oder Dienstleistungen oder für jeden
                anderen Vertrag mit uns über den Dienst.
              </p>
            </li>
            <li>
              <p>
                <strong>Zur Kontaktaufnahme mit Ihnen:</strong> Zur
                Kontaktaufnahme mit Ihnen per E-Mail, Telefonanrufen, SMS oder
                anderen elektronischen Kommunikationsformen, wie
                Push-Benachrichtigungen von mobilen Anwendungen, bezüglich
                Updates oder informativen Mitteilungen im Zusammenhang mit den
                Funktionen, Produkten oder vertraglich vereinbarten
                Dienstleistungen, einschließlich Sicherheitsupdates, wenn dies
                für deren Umsetzung notwendig oder vernünftig ist.
              </p>
            </li>
            <li>
              <p>
                <strong>Um Ihnen</strong> Nachrichten, Sonderangebote und
                allgemeine Informationen zu anderen Waren, Dienstleistungen und
                Veranstaltungen zu geben, die wir anbieten und die denen ähnlich
                sind, die Sie bereits gekauft oder angefragt haben, sofern Sie
                sich nicht gegen den Erhalt solcher Informationen entschieden
                haben.
              </p>
            </li>
            <li>
              <p>
                <strong>Zur Verwaltung Ihrer Anfragen:</strong> Zur Bearbeitung
                und Verwaltung Ihrer Anfragen an uns.
              </p>
            </li>
            <li>
              <p>
                <strong>Für Unternehmensübertragungen:</strong> Wir können Ihre
                Informationen verwenden, um eine Fusion, einen Verkauf, eine
                Ausgliederung, eine Umstrukturierung, eine Auflösung oder einen
                anderen Verkauf oder die Übertragung eines Teils oder aller
                unserer Vermögenswerte zu bewerten oder durchzuführen, sei es
                als laufendes Geschäft oder im Rahmen eines Konkursverfahrens,
                einer Liquidation oder eines ähnlichen Verfahrens, bei dem
                personenbezogene Daten, die wir über unsere Dienstnutzer haben,
                zu den übertragenen Vermögenswerten gehören.
              </p>
            </li>
            <li>
              <p>
                <strong>Für andere Zwecke</strong>: Wir können Ihre
                Informationen für andere Zwecke verwenden, wie z. B.
                Datenanalyse, Identifizierung von Nutzungstrends, Feststellung
                der Effektivität unserer Werbekampagnen und zur Bewertung und
                Verbesserung unseres Dienstes, unserer Produkte,
                Dienstleistungen, Marketingmaßnahmen und Ihrer Erfahrung.
              </p>
            </li>
          </ul>

          <p>
            Wir können Ihre persönlichen Informationen in folgenden Situationen
            teilen:
          </p>
          <ul>
            <li>
              <strong>Mit Dienstleistern:</strong> Wir können Ihre persönlichen
              Informationen mit Dienstleistern teilen, um die Nutzung unseres
              Dienstes zu überwachen und zu analysieren sowie um Sie zu
              kontaktieren.
            </li>
            <li>
              <strong>Für Unternehmensübertragungen:</strong> Wir können Ihre
              persönlichen Informationen im Zusammenhang mit oder während
              Verhandlungen über eine Fusion, den Verkauf von
              Unternehmensvermögen, Finanzierungen oder den Erwerb unseres
              Unternehmens oder eines Teils davon durch ein anderes Unternehmen
              teilen oder übertragen.
            </li>
            <li>
              <strong>Mit Tochtergesellschaften:</strong> Wir können Ihre
              Informationen mit unseren Tochtergesellschaften teilen, in diesem
              Fall werden wir von diesen Tochtergesellschaften verlangen, diese
              Datenschutzrichtlinie zu respektieren. Zu den
              Tochtergesellschaften gehören unsere Muttergesellschaft und andere
              Tochtergesellschaften, Joint Venture-Partner oder andere
              Unternehmen, die wir kontrollieren oder die sich unter unserer
              gemeinsamen Kontrolle befinden.
            </li>
            <li>
              <strong>Mit Geschäftspartnern:</strong> Wir können Ihre
              Informationen mit unseren Geschäftspartnern teilen, um Ihnen
              bestimmte Produkte, Dienstleistungen oder Aktionen anzubieten.
            </li>
            <li>
              <strong>Mit anderen Benutzern:</strong> Wenn Sie persönliche
              Informationen teilen oder auf andere Weise in den öffentlichen
              Bereichen mit anderen Benutzern interagieren, können diese
              Informationen von allen Benutzern eingesehen und außerhalb
              öffentlich verbreitet werden.
            </li>
            <li>
              <strong>Mit Ihrer Zustimmung:</strong> Wir können Ihre
              persönlichen Informationen für jeden anderen Zweck mit Ihrer
              Zustimmung offenlegen.
            </li>
          </ul>
          <h2>Aufbewahrung Ihrer persönlichen Daten</h2>
          <p>
            Das Unternehmen wird Ihre persönlichen Daten nur so lange
            aufbewahren, wie es für die in dieser Datenschutzrichtlinie
            festgelegten Zwecke erforderlich ist. Wir werden Ihre persönlichen
            Daten aufbewahren und verwenden, soweit dies erforderlich ist, um
            unseren rechtlichen Verpflichtungen nachzukommen (zum Beispiel, wenn
            wir gesetzlich verpflichtet sind, Ihre Daten aufzubewahren, um
            geltenden Gesetzen nachzukommen), Streitigkeiten beizulegen und
            unsere rechtlichen Vereinbarungen und Richtlinien durchzusetzen.
          </p>

          <p>
            Das Unternehmen wird auch Nutzungsdaten zu internen Analysezwecken
            aufbewahren. Nutzungsdaten werden in der Regel für einen kürzeren
            Zeitraum aufbewahrt, es sei denn, diese Daten werden zur Stärkung
            der Sicherheit oder zur Verbesserung der Funktionalität unseres
            Dienstes verwendet, oder wir sind gesetzlich verpflichtet, diese
            Daten über längere Zeiträume aufzubewahren.
          </p>
          <h2>Übertragung Ihrer persönlichen Daten</h2>
          <p>
            Ihre Informationen, einschließlich personenbezogener Daten, werden
            in den Betriebsbüros des Unternehmens und an anderen Orten
            verarbeitet, an denen sich die an der Verarbeitung beteiligten
            Parteien befinden. Dies bedeutet, dass diese Informationen an
            Computer außerhalb Ihres Bundeslandes, Ihrer Provinz, Ihres Landes
            oder einer anderen staatlichen Gerichtsbarkeit übertragen und
            gespeichert werden können, in der die Datenschutzgesetze von denen
            in Ihrer Gerichtsbarkeit abweichen können.
          </p>
          <p>
            Ihre Zustimmung zu dieser Datenschutzrichtlinie, gefolgt von Ihrer
            Übermittlung solcher Informationen, stellt Ihre Zustimmung zu dieser
            Übertragung dar.
          </p>
          <p>
            Das Unternehmen wird alle erforderlichen Schritte unternehmen, um
            sicherzustellen, dass Ihre Daten sicher behandelt werden und in
            Übereinstimmung mit dieser Datenschutzrichtlinie erfolgt, und keine
            Übertragung Ihrer personenbezogenen Daten wird an eine Organisation
            oder ein Land erfolgen, sofern nicht angemessene Kontrollen
            vorhanden sind, einschließlich der Sicherheit Ihrer Daten und
            anderer persönlicher Informationen.
          </p>
          <h2>Löschen Ihrer persönlichen Daten</h2>
          <p>
            Sie haben das Recht, die Löschung oder die Bitte um Unterstützung
            bei der Löschung der von uns über Sie gesammelten personenbezogenen
            Daten zu verlangen.
          </p>
          <p>
            Unser Dienst kann Ihnen die Möglichkeit bieten, bestimmte
            Informationen über Sie innerhalb des Dienstes zu löschen.
          </p>
          <p>
            Sie können Ihre Informationen jederzeit aktualisieren, ändern oder
            löschen, indem Sie sich in Ihr Konto einloggen, sofern Sie eines
            haben, und den Abschnitt für Kontoeinstellungen besuchen, der es
            Ihnen ermöglicht, Ihre persönlichen Informationen zu verwalten. Sie
            können sich auch an uns wenden, um Zugang zu korrigieren oder zu
            löschen jede personenbezogene Information, die Sie uns zur Verfügung
            gestellt haben.
          </p>
          <p>
            Bitte beachten Sie jedoch, dass wir bestimmte Informationen behalten
            müssen, wenn wir dazu gesetzlich verpflichtet oder dazu berechtigt
            sind.
          </p>
          <h2>Offenlegung Ihrer persönlichen Daten</h2>
          <h3>Unternehmensgeschäfte</h3>
          <p>
            Wenn das Unternehmen an einer Fusion, einem Erwerb oder einem
            Verkauf von Vermögenswerten beteiligt ist, können Ihre persönlichen
            Daten übertragen werden. Wir werden Ihnen Benachrichtigung geben,
            bevor Ihre persönlichen Daten übertragen werden und einer anderen
            Datenschutzrichtlinie unterliegen.
          </p>
          <h3>Strafverfolgung</h3>
          <p>
            Unter bestimmten Umständen kann das Unternehmen gesetzlich
            verpflichtet sein, Ihre persönlichen Daten offenzulegen, wenn dies
            gesetzlich vorgeschrieben ist oder in Reaktion auf gültige Anfragen
            von Behörden (z. B. Gericht oder Regierungsbehörde) erforderlich
            ist.
          </p>
          <h3>Weitere gesetzliche Anforderungen</h3>
          <p>
            Das Unternehmen kann Ihre persönlichen Daten in gutem Glauben
            offenlegen, wenn eine solche Maßnahme notwendig ist, um:
          </p>
          <ul>
            <li>Einer gesetzlichen Verpflichtung nachzukommen</li>
            <li>
              Die Rechte oder das Eigentum des Unternehmens zu schützen und zu
              verteidigen
            </li>
            <li>
              Mögliche Fehlverhalten im Zusammenhang mit dem Dienst zu
              verhindern oder zu untersuchen
            </li>
            <li>
              Die persönliche Sicherheit der Benutzer des Dienstes oder der
              Öffentlichkeit zu schützen
            </li>
            <li>Haftung rechtlich abzusichern</li>
          </ul>
          <h2>Sicherheit Ihrer persönlichen Daten</h2>
          <p>
            Die Sicherheit Ihrer persönlichen Daten ist uns wichtig, aber denken
            Sie daran, dass keine Übertragungsmethode über das Internet oder
            elektronische Speichermethode zu 100% sicher ist. Obwohl wir uns
            bemühen, kommerziell akzeptable Mittel zum Schutz Ihrer persönlichen
            Daten zu verwenden, können wir deren absolute Sicherheit nicht
            garantieren.
          </p>
          <h1>Datenschutz für Kinder</h1>

          <p>
            Unser Dienst richtet sich nicht an Personen unter 13 Jahren. Wir
            sammeln nicht wissentlich personenbezogene Daten von Personen unter
            13 Jahren. Wenn Sie ein Elternteil oder Erziehungsberechtigter sind
            und feststellen, dass Ihr Kind uns personenbezogene Daten zur
            Verfügung gestellt hat, kontaktieren Sie uns bitte. Wenn uns bekannt
            wird, dass wir personenbezogene Daten von Personen unter 13 Jahren
            ohne Überprüfung der elterlichen Zustimmung gesammelt haben,
            ergreifen wir Maßnahmen, um diese Informationen von unseren Servern
            zu entfernen.
          </p>
          <p>
            Wenn wir auf die Einwilligung als Rechtsgrundlage für die
            Verarbeitung Ihrer Informationen angewiesen sein sollten und Ihr
            Land die Zustimmung eines Elternteils erfordert, können wir die
            Zustimmung Ihrer Eltern benötigen, bevor wir diese Informationen
            sammeln und verwenden.
          </p>
          <h1>Links zu anderen Websites</h1>
          <p>
            Unser Dienst kann Links zu anderen Websites enthalten, die nicht von
            uns betrieben werden. Wenn Sie auf einen Link von Dritten klicken,
            werden Sie zu der Website dieses Dritten weitergeleitet. Wir
            empfehlen Ihnen dringend, die Datenschutzrichtlinie jeder Website zu
            überprüfen, die Sie besuchen.
          </p>
          <p>
            Wir haben keine Kontrolle über und übernehmen keine Verantwortung
            für den Inhalt, die Datenschutzrichtlinien oder Praktiken von
            Websites oder Diensten Dritter.
          </p>
          <h1>Änderungen dieser Datenschutzrichtlinie</h1>
          <p>
            Wir können unsere Datenschutzrichtlinie von Zeit zu Zeit
            aktualisieren. Wir werden Sie über etwaige Änderungen informieren,
            indem wir die neue Datenschutzrichtlinie auf dieser Seite
            veröffentlichen.
          </p>
          <p>
            Wir werden Sie per E-Mail und/oder durch einen prominenten Hinweis
            auf unserem Dienst vor der Wirksamkeit der Änderung informieren und
            das Datum "Zuletzt aktualisiert" oben in dieser
            Datenschutzrichtlinie aktualisieren.
          </p>
          <p>
            Sie werden empfohlen, diese Datenschutzrichtlinie von Zeit zu Zeit
            auf Änderungen zu überprüfen. Änderungen dieser
            Datenschutzrichtlinie werden wirksam, wenn sie auf dieser Seite
            veröffentlicht werden.
          </p>
          <h1>Kontaktieren Sie uns</h1>

          <p>
            Wenn Sie Fragen zu dieser Datenschutzrichtlinie haben, können Sie
            uns kontaktieren:
          </p>
          <ul>
            <li>Per E-Mail: info@kepicker.com</li>
          </ul>
        </div>
      ) : (
        <div className="content">
          <p>Version 1.0</p>

          <p>
            Die Website von Kepicker unter https://kepicker.com/ ist ein
            urheberrechtlich geschütztes Werk von Kepicker LLC. Bestimmte
            Funktionen der Website können zusätzlichen Richtlinien, Bedingungen
            oder Regeln unterliegen, die im Zusammenhang mit solchen Funktionen
            auf der Website veröffentlicht werden.
          </p>

          <p>
            Alle diese zusätzlichen Bedingungen, Richtlinien und Regeln werden
            durch Verweis in diese Nutzungsbedingungen aufgenommen.
          </p>

          <p>
            Diese Nutzungsbedingungen beschreiben die rechtlich bindenden
            Bedingungen und Konditionen, die Ihre Nutzung der Website regeln.
            DURCH DIE ANMELDUNG AUF DER WEBSITE ERKLÄREN SIE SICH DAMIT
            EINVERSTANDEN, DIESE BEDINGUNGEN EINZUHALTEN, und Sie erklären, dass
            Sie die Autorität und die Fähigkeit haben, diese Bedingungen
            einzugehen. SIE SOLLTEN MINDESTENS 18 JAHRE ALT SEIN, UM AUF DIE
            WEBSITE ZUGREIFEN ZU KÖNNEN. WENN SIE MIT ALLEN BESTIMMUNGEN DIESER
            BEDINGUNGEN NICHT EINVERSTANDEN SIND, MELDEN SIE SICH NICHT AUF DER
            WEBSITE AN UND/ODER NUTZEN SIE DIESE NICHT.
          </p>

          <p>
            Diese Bedingungen erfordern die Anwendung von Schiedsverfahren gemäß
            Abschnitt 10.2 auf individueller Basis zur Beilegung von
            Streitigkeiten und beschränken auch die Ihnen im Falle einer
            Streitigkeit zur Verfügung stehenden Rechtsbehelfe.
          </p>

          <h2>Zugang zur Website</h2>

          <p>
            <strong>Unter Vorbehalt dieser Bedingungen.</strong> Das Unternehmen
            gewährt Ihnen eine nicht übertragbare, nicht exklusive,
            widerrufliche, eingeschränkte Lizenz zum Zugriff auf die Website
            ausschließlich für Ihre persönliche, nicht kommerzielle Nutzung.
          </p>

          <p>
            <strong>Bestimmte Einschränkungen.</strong> Die Ihnen in diesen
            Bedingungen gewährten Rechte unterliegen den folgenden
            Einschränkungen: (a) Sie dürfen die Website nicht verkaufen,
            vermieten, verleasen, übertragen, abtreten, verteilen, hosten oder
            anderweitig kommerziell nutzen; (b) Sie dürfen keine Änderungen
            vornehmen, abgeleitete Werke erstellen, Teile der Website zerlegen,
            umkehren oder rückwärts kompilieren oder rückwärts entwickeln; (c)
            Sie dürfen die Website nicht zugreifen, um eine ähnliche oder
            konkurrierende Website zu erstellen; und (d) soweit hierin nicht
            ausdrücklich anders angegeben, darf kein Teil der Website kopiert,
            reproduziert, verteilt, erneut veröffentlicht, heruntergeladen,
            angezeigt, veröffentlicht oder übertragen werden, es sei denn, es
            ist anderweitig angegeben, dass jede zukünftige Veröffentlichung,
            Aktualisierung oder andere Hinzufügung zur Funktionalität der
            Website diesen Bedingungen unterliegt. Alle Urheberrechts- und
            anderen Eigentumsvermerke auf der Website müssen auf allen Kopien
            davon erhalten bleiben.
          </p>

          <p>
            Das Unternehmen behält sich das Recht vor, die Website mit oder ohne
            Benachrichtigung an Sie zu ändern, auszusetzen oder einzustellen.
            Sie stimmen zu, dass das Unternehmen Ihnen oder Dritten gegenüber
            nicht für Änderungen, Unterbrechungen oder die Beendigung der
            Website oder eines Teils davon haftbar gemacht wird.
          </p>

          <p>
            <strong>Keine Unterstützung oder Wartung.</strong> Sie erklären sich
            damit einverstanden, dass das Unternehmen keine Verpflichtung hat,
            Ihnen Unterstützung in Verbindung mit der Website zu gewähren.
          </p>

          <p>
            Mit Ausnahme von etwaigen von Ihnen bereitgestellten
            Benutzerinhalten sind Ihnen alle geistigen Eigentumsrechte,
            einschließlich Urheberrechten, Patenten, Marken und
            Geschäftsgeheimnissen, an der Website und ihrem Inhalt bewusst im
            Besitz des Unternehmens oder der Lieferanten des Unternehmens.
            Beachten Sie, dass diese Bedingungen und der Zugang zur Website
            Ihnen keine Rechte, Titel oder Interessen an geistigen
            Eigentumsrechten verleihen, außer den in Abschnitt 2.1 ausdrücklich
            gewährten Zugangsrechten. Das Unternehmen und seine Lieferanten
            behalten sich alle nicht in diesen Bedingungen gewährten Rechte vor.
          </p>

          <h2>Links zu Websites Dritter und Werbung; Andere Benutzer</h2>

          <p>
            <strong>Links zu Websites Dritter und Werbung.</strong> Die Website
            kann Links zu Websites und Diensten von Dritten enthalten und/oder
            Werbung von Dritten anzeigen. Solche Links zu Websites Dritter und
            Werbung unterliegen nicht der Kontrolle des Unternehmens, und das
            Unternehmen ist nicht verantwortlich für Links zu Websites Dritter
            und Werbung. Das Unternehmen stellt Ihnen den Zugriff auf diese
            Links zu Websites Dritter und Werbung nur als Service zur Verfügung
            und überprüft, genehmigt, überwacht, befürwortet, garantiert oder
            macht keine Aussagen zu Links zu Websites Dritter und Werbung. Sie
            nutzen alle Links zu Websites Dritter und Werbung auf eigene Gefahr
            und sollten dabei ein angemessenes Maß an Vorsicht und Diskretion
            walten lassen. Wenn Sie auf einen der Links zu Websites Dritter und
            Werbung klicken, gelten die entsprechenden Geschäftsbedingungen und
            Richtlinien des Dritten, einschließlich der Datenschutz- und
            Datensammelpraktiken des Dritten.
          </p>

          <p>
            <strong>Andere Benutzer.</strong> Jeder Benutzer der Website ist
            allein verantwortlich für seinen eigenen Benutzerinhalt. Da wir
            keinen Einfluss auf Benutzerinhalte haben, erkennen Sie an und
            stimmen zu, dass wir nicht für Benutzerinhalte verantwortlich sind,
            unabhängig davon, ob sie von Ihnen oder anderen bereitgestellt
            werden. Sie erklären sich damit einverstanden, dass das Unternehmen
            nicht für Verluste oder Schäden haftbar gemacht wird, die im
            Zusammenhang mit solchen Interaktionen entstehen. Wenn es eine
            Streitigkeit zwischen Ihnen und einem anderen Benutzer der Website
            gibt, sind wir nicht verpflichtet, uns daran zu beteiligen.
          </p>

          <p>
            Sie entbinden hiermit das Unternehmen und unsere Beamten,
            Mitarbeiter, Agenten, Nachfolger und Bevollmächtigten von jeder
            vergangenen, gegenwärtigen und zukünftigen Streitigkeit, Klage,
            Kontroverse, Forderung, Recht, Verpflichtung, Haftung, Handlung und
            Ursache jeder Art und Natur, die direkt oder indirekt aus der
            Website entstanden ist oder damit in Zusammenhang steht. Wenn Sie
            Einwohner von Kalifornien sind, verzichten Sie hiermit im
            Zusammenhang mit dem Vorstehenden auf Abschnitt 1542 des
            kalifornischen Zivilgesetzbuchs, der besagt: "Eine allgemeine
            Freigabe erstreckt sich nicht auf Ansprüche, von denen der Gläubiger
            nicht weiß oder vermutet, dass sie zu seinen Gunsten zum Zeitpunkt
            der Unterzeichnung der Freigabe existieren, die, wenn sie ihm
            bekannt wären, seine Vereinbarung mit dem Schuldner wesentlich
            beeinflusst hätten."
          </p>

          <p>
            <strong>Cookies und Web Beacons.</strong> Wie jede andere Website
            verwendet Kepicker 'Cookies'. Diese Cookies dienen dazu,
            Informationen wie die Präferenzen der Besucher und die von den
            Besuchern aufgerufenen oder besuchten Seiten der Website zu
            speichern. Die Informationen werden verwendet, um das
            Benutzererlebnis zu optimieren, indem der Inhalt unserer Webseite
            basierend auf dem Browsertyp der Besucher und/oder anderen
            Informationen angepasst wird.
          </p>

          <h2>Haftungsausschlüsse</h2>

          <p>
            Die Website wird "wie besehen" und "wie verfügbar" bereitgestellt,
            und das Unternehmen und unsere Lieferanten lehnen ausdrücklich alle
            Gewährleistungen und Bedingungen jeglicher Art ab, ob ausdrücklich,
            implizit oder gesetzlich, einschließlich aller Gewährleistungen oder
            Bedingungen hinsichtlich der Marktgängigkeit, Eignung für einen
            bestimmten Zweck, Titel, ruhigen Genusses, Genauigkeit oder
            Nichtverletzung. Wir und unsere Lieferanten garantieren nicht, dass
            die Website Ihren Anforderungen entspricht, auf eine
            unterbrechungsfreie, rechtzeitige, sichere oder fehlerfreie Basis
            zur Verfügung steht oder genau, zuverlässig, frei von Viren oder
            anderem schädlichen Code, vollständig, legal oder sicher ist. Sofern
            geltendes Recht keine Gewährleistungen in Bezug auf die Website
            vorschreibt, sind alle derartigen Gewährleistungen auf neunzig (90)
            Tage ab dem Datum der ersten Nutzung begrenzt.
          </p>

          <p>
            <strong>Begrenzung der Haftung</strong>
          </p>

          <p>
            Soweit gesetzlich zulässig, haftet das Unternehmen oder unsere
            Lieferanten Ihnen oder Dritten gegenüber in keinem Fall für
            entgangene Gewinne, verlorene Daten, Beschaffungskosten für
            Ersatzprodukte oder für indirekte, Folge-, exemplarische, zufällige,
            besondere oder strafrechtliche Schäden, die sich aus oder im
            Zusammenhang mit diesen Bedingungen oder Ihrer Nutzung oder
            Unfähigkeit zur Nutzung der Website ergeben, selbst wenn das
            Unternehmen auf die Möglichkeit solcher Schäden hingewiesen wurde.
            Der Zugriff auf und die Nutzung der Website erfolgen nach Ihrem
            eigenen Ermessen und Risiko, und Sie sind allein verantwortlich für
            etwaige Schäden an Ihrem Gerät oder Computersystem oder für den
            Verlust von Daten, die daraus resultieren.
          </p>

          <p>
            Soweit gesetzlich zulässig, unabhängig von etwaigen abweichenden
            Bestimmungen in diesen Bedingungen ist unsere Haftung Ihnen
            gegenüber für jegliche Schäden, die sich aus oder im Zusammenhang
            mit dieser Vereinbarung ergeben, auf höchstens fünfzig US-Dollar (50
            US-Dollar) beschränkt. Die Existenz mehrerer Ansprüche wird diese
            Grenze nicht erweitern. Sie erklären sich damit einverstanden, dass
            unsere Lieferanten keinerlei Haftung jeglicher Art im Zusammenhang
            mit dieser Vereinbarung haben.
          </p>

          <p>
            In einigen Rechtsordnungen ist die Beschränkung oder der Ausschluss
            der Haftung für zufällige oder Folgeschäden nicht zulässig, daher
            gilt die oben genannte Beschränkung oder der Ausschluss
            möglicherweise nicht für Sie.
          </p>

          <p>
            <strong>Laufzeit und Kündigung.</strong> Vorbehaltlich dieses
            Abschnitts bleiben diese Bedingungen in vollem Umfang in Kraft und
            wirksam, solange Sie die Website nutzen. Wir können Ihre Rechte zur
            Nutzung der Website jederzeit und aus beliebigem Grund nach unserem
            alleinigen Ermessen aussetzen oder beenden, einschließlich bei
            Verstößen gegen diese Bedingungen. Nach Beendigung Ihrer Rechte
            gemäß diesen Bedingungen wird Ihr Konto und Ihr Recht, auf die
            Website zuzugreifen und sie zu nutzen, sofort beendet. Sie
            verstehen, dass eine Beendigung Ihres Kontos die Löschung Ihrer mit
            Ihrem Konto verknüpften Benutzerinhalte aus unseren Live-Datenbanken
            beinhalten kann. Das Unternehmen haftet Ihnen keineswegs für eine
            Beendigung Ihrer Rechte gemäß diesen Bedingungen. Auch nach
            Beendigung Ihrer Rechte gemäß diesen Bedingungen bleiben die
            folgenden Bestimmungen dieser Bedingungen in Kraft: Abschnitte 2 bis
            2.5, Abschnitt 3 und Abschnitte 4 bis 10.
          </p>

          <h2>Urheberrechtspolitik.</h2>

          <p>
            <strong>Urheberrechtspolitik.</strong> Das Unternehmen respektiert
            das geistige Eigentum anderer und bittet die Benutzer unserer
            Website, dasselbe zu tun. Im Zusammenhang mit unserer Website haben
            wir eine Richtlinie zur Einhaltung des Urheberrechts verabschiedet
            und umgesetzt, die die Entfernung von rechtsverletzenden Materialien
            und die Kündigung von Benutzern unserer Online-Website vorsieht, die
            wiederholte Verletzer von geistigen Eigentumsrechten, einschließlich
            Urheberrechten, sind. Wenn Sie glauben, dass einer unserer Benutzer
            durch die Nutzung unserer Website das Urheberrecht an einem Werk auf
            rechtswidrige Weise verletzt (s), und Sie das angeblich
            rechtsverletzende Material entfernen möchten, müssen die folgenden
            Informationen in Form einer schriftlichen Benachrichtigung (gemäß 17
            U.S.C. § 512 (c)) an unseren ausgewiesenen Urheberrechtsbeauftragten
            übermittelt werden:
          </p>

          <ul>
            <li>Ihre physische oder elektronische Unterschrift;</li>
            <li>
              Identifikation der urheberrechtlich geschützten Arbeit (en), von
              denen Sie behaupten, dass sie verletzt wurden;
            </li>
            <li>
              Identifikation des Materials auf unseren Diensten, von dem Sie
              behaupten, dass es verletzt ist und das Sie entfernen möchten;
            </li>
            <li>
              Ausreichende Informationen, um uns die Lokalisierung eines solchen
              Materials zu ermöglichen;
            </li>
            <li>Ihre Adresse, Telefonnummer und E-Mail-Adresse;</li>
            <li>
              Eine Erklärung, dass Sie in gutem Glauben glauben, dass die
              Verwendung des beanstandeten Materials nicht vom
              Urheberrechtsinhaber, seinem Vertreter oder nach dem Gesetz
              autorisiert ist; und
            </li>
            <li>
              Eine Erklärung, dass die Informationen in der Benachrichtigung
              korrekt sind und unter Strafe des Meineids, dass Sie entweder der
              Eigentümer des angeblich verletzten Urheberrechts sind oder
              berechtigt sind, im Namen des Urheberrechtsinhabers zu handeln.
            </li>
          </ul>

          <p>
            Bitte beachten Sie, dass gemäß 17 U.S.C. § 512 (f) jede
            Falschdarstellung von Tatsachen in einer schriftlichen
            Benachrichtigung automatisch die beschwerdeführende Partei für
            etwaige Schäden, Kosten und Anwaltsgebühren haftbar macht, die uns
            im Zusammenhang mit der schriftlichen Benachrichtigung und der
            Behauptung einer Urheberrechtsverletzung entstehen.
          </p>

          <h2>Allgemeines</h2>

          <p>
            Diese Bedingungen unterliegen gelegentlichen Änderungen, und wenn
            wir wesentliche Änderungen vornehmen, können wir Sie per E-Mail an
            die von Ihnen zuletzt angegebene E-Mail-Adresse und/oder durch
            auffällige Veröffentlichung einer Mitteilung über die Änderungen auf
            unserer Website benachrichtigen. Sie sind verantwortlich für die
            Bereitstellung Ihrer aktuellsten E-Mail-Adresse. Falls die von Ihnen
            zuletzt angegebene E-Mail-Adresse nicht gültig ist, stellt unsere
            Versendung der E-Mail mit einer solchen Mitteilung dennoch eine
            wirksame Benachrichtigung über die in der Mitteilung beschriebenen
            Änderungen dar. Änderungen dieser Bedingungen werden zum frühesten
            Zeitpunkt wirksam, der dreißig (30) Kalendertage nach Versendung
            einer E-Mail-Benachrichtigung an Sie oder dreißig (30) Kalendertage
            nach Veröffentlichung einer Mitteilung über die Änderungen auf
            unserer Website ist. Diese Änderungen werden für neue Benutzer
            unserer Website sofort wirksam. Die fortgesetzte Nutzung unserer
            Website nach Mitteilung über derartige Änderungen bedeutet Ihre
            Anerkennung dieser Änderungen und Ihr Einverständnis, an die
            Bedingungen und Konditionen solcher Änderungen gebunden zu sein.
            Streitbeilegung. Bitte lesen Sie diese Schiedsvereinbarung
            sorgfältig durch. Sie ist Teil Ihres Vertrags mit dem Unternehmen
            und beeinflusst Ihre Rechte. Sie enthält Verfahren für DIE
            VERBINDLICHE SCHIEDSVEREINBARUNG UND EINEN VERZICHT AUF SAMMELKLAGE.
          </p>

          <p>
            <strong>Geltungsbereich der Schiedsvereinbarung.</strong> Alle
            Ansprüche und Streitigkeiten im Zusammenhang mit den Bedingungen
            oder der Nutzung von Produkten oder Dienstleistungen, die von der
            Firma bereitgestellt werden und die nicht informell oder vor Gericht
            für kleine Ansprüche beigelegt werden können, werden gemäß den
            Bestimmungen dieser Schiedsvereinbarung auf individueller Basis
            beigelegt. Sofern nicht anders vereinbart, werden alle
            Schiedsverfahren in englischer Sprache durchgeführt. Diese
            Schiedsvereinbarung gilt für Sie und das Unternehmen sowie für
            Tochtergesellschaften, verbundene Unternehmen, Vertreter,
            Mitarbeiter, Vorgänger im Interesse, Rechtsnachfolger und
            Abtretungsempfänger sowie für alle autorisierten oder nicht
            autorisierten Benutzer oder Begünstigten von Dienstleistungen oder
            Waren, die gemäß den Bedingungen erbracht werden.
          </p>

          <p>
            <strong>
              Erfordernis einer Benachrichtigung und informelle Streitbeilegung.
            </strong>{" "}
            Bevor eine der Parteien Schiedsverfahren anstreben darf, muss die
            Partei zunächst der anderen Partei eine schriftliche Mitteilung über
            den Streitfall senden, in der die Art und den Grund des Anspruchs
            oder Streits sowie die gewünschte Abhilfe beschrieben werden. Eine
            Benachrichtigung an das Unternehmen sollte an folgende Adresse
            gesendet werden: Delaware, USA. Nach Erhalt der Benachrichtigung
            können Sie und das Unternehmen versuchen, den Anspruch oder
            Streitfall informell beizulegen. Wenn Sie und das Unternehmen den
            Anspruch oder Streitfall nicht innerhalb von dreißig (30) Tagen nach
            Erhalt der Benachrichtigung beilegen, kann jede Partei ein
            Schiedsverfahren einleiten. Die Höhe eines Vergleichsangebots, das
            von einer Partei gemacht wird, darf dem Schiedsrichter erst nach
            Bestimmung des Betrags des Anspruchs, auf den jede Partei Anspruch
            hat, mitgeteilt werden.
          </p>

          <p>
            <strong>Regeln für das Schiedsverfahren.</strong> Das
            Schiedsverfahren wird durch die American Arbitration Association
            (AAA), einem etablierten Anbieter für alternative Streitbeilegung,
            der Schiedsverfahren gemäß diesem Abschnitt anbietet, eingeleitet.
            Wenn AAA nicht für das Schiedsverfahren zur Verfügung steht, werden
            die Parteien sich auf die Auswahl eines alternativen ADR-Anbieters
            einigen. Die Regeln des ADR-Anbieters regeln alle Aspekte des
            Schiedsverfahrens, soweit diese Regeln nicht im Widerspruch zu den
            Bedingungen stehen. Die AAA Consumer Arbitration Rules, die das
            Schiedsverfahren regeln, sind online unter adr.org verfügbar oder
            telefonisch unter 1-800-778-7879 erhältlich. Das Schiedsverfahren
            wird von einem einzigen neutralen Schiedsrichter durchgeführt.
            Ansprüche oder Streitigkeiten, bei denen die Gesamtsumme der
            geforderten Entschädigung weniger als zehntausend US-Dollar
            (10.000,00 USD) beträgt, können nach Wahl der Partei, die
            Erleichterung sucht, durch ein bindendes, nicht auf Erscheinen
            basierendes Schiedsverfahren beigelegt werden. Für Ansprüche oder
            Streitigkeiten, bei denen die Gesamtsumme der geforderten
            Entschädigung zehntausend US-Dollar (10.000,00 USD) oder mehr
            beträgt, wird das Recht auf eine Anhörung durch die Schiedsregeln
            bestimmt. Jede Anhörung wird an einem Ort innerhalb von 100 Meilen
            von Ihrem Wohnsitz abgehalten, es sei denn, Sie wohnen außerhalb der
            Vereinigten Staaten, und es sei denn, die Parteien einigen sich
            anders. Wenn Sie außerhalb der USA wohnen, wird der Schiedsrichter
            den Parteien rechtzeitig Datum, Uhrzeit und Ort etwaiger mündlicher
            Anhörungen mitteilen. Ein Urteil über den vom Schiedsrichter
            erlassenen Schiedsspruch kann vor jedem zuständigen Gericht
            vollstreckt werden. Wenn der Schiedsrichter Ihnen einen höheren
            Betrag zuspricht als das letzte Vergleichsangebot, das das
            Unternehmen Ihnen vor Einleitung des Schiedsverfahrens gemacht hat,
            wird das Unternehmen Ihnen den höheren Betrag oder 2.500,00 USD
            zahlen, je nachdem, welcher Betrag höher ist. Jede Partei trägt ihre
            eigenen Kosten und Ausgaben, die aus dem Schiedsverfahren entstehen,
            und zahlt einen gleich großen Anteil an den Gebühren und Kosten des
            ADR-Anbieters.
          </p>

          <p>
            <strong>
              Zusätzliche Regeln für schiedsgerichtliche Verfahren ohne
              persönliche Anhörung.
            </strong>{" "}
            Wenn ein schiedsgerichtliches Verfahren ohne persönliche Anhörung
            gewählt wird, erfolgt das Schiedsgerichtsverfahren telefonisch,
            online und/oder ausschließlich auf der Grundlage schriftlicher
            Unterlagen; die genaue Vorgehensweise wird von der Partei
            festgelegt, die das Schiedsverfahren einleitet. Das Schiedsverfahren
            beinhaltet keine persönliche Anhörung der Parteien oder Zeugen, es
            sei denn, die Parteien vereinbaren etwas anderes.
          </p>

          <p>
            <strong>Fristen.</strong> Wenn Sie oder das Unternehmen ein
            Schiedsverfahren anstreben, muss die Schiedsgerichtsklage innerhalb
            der Verjährungsfrist und innerhalb einer von den AAA-Regeln für den
            betreffenden Anspruch festgelegten Frist eingeleitet und/oder
            gefordert werden.
          </p>

          <p>
            <strong>Zuständigkeit des Schiedsrichters.</strong> Wenn ein
            Schiedsverfahren eingeleitet wird, entscheidet der Schiedsrichter
            über die Rechte und Verpflichtungen von Ihnen und dem Unternehmen,
            und der Streitfall wird nicht mit anderen Angelegenheiten oder
            Parteien zusammengefasst oder mit diesen verbunden. Der
            Schiedsrichter hat die Befugnis, Anträge zu stellen, die einen Teil
            oder den gesamten Anspruch betreffen. Der Schiedsrichter hat die
            Befugnis, Geldentschädigungen zu gewähren und jede nicht-monetäre
            Maßnahme oder Erleichterung zu gewähren, die einer Person nach
            geltendem Recht, den AAA-Regeln und den Bedingungen zur Verfügung
            steht. Der Schiedsrichter erlässt einen schriftlichen Schiedsspruch
            und eine Entscheidungsbegründung, in der die wesentlichen
            Feststellungen und Schlussfolgerungen beschrieben werden, auf denen
            der Schiedsspruch beruht. Der Schiedsrichter hat die gleiche
            Befugnis, Erleichterungen auf individueller Basis zu gewähren, wie
            ein Richter in einem Gerichtsverfahren. Der Schiedsspruch des
            Schiedsrichters ist endgültig und bindend für Sie und das
            Unternehmen.
          </p>

          <p>
            <strong>Verzicht auf Juryverfahren.</strong> DIE PARTEIEN VERZICHTEN
            HIERMIT AUF IHRE VERFASSUNGSMÄßIGEN UND GESETZLICHEN RECHTE, VOR
            EINEM GERICHT UND VOR EINEM RICHTER ODER EINER JURY EIN VERFAHREN
            ABZUHALTEN, und wählen stattdessen, dass alle Ansprüche und
            Streitigkeiten gemäß dieser Schiedsvereinbarung durch
            Schiedsverfahren beigelegt werden sollen. Schiedsverfahrensverfahren
            sind in der Regel begrenzter, effizienter und kostengünstiger als
            Regeln, die vor Gericht gelten, und unterliegen einer sehr
            begrenzten Überprüfung durch ein Gericht. Im Falle einer
            gerichtlichen Auseinandersetzung zwischen Ihnen und dem Unternehmen
            vor einem Bundes- oder Landesgericht zur Anfechtung oder
            Durchsetzung eines Schiedsspruchs oder anderweitig, VERZICHTEN SIE
            UND DAS UNTERNEHMEN AUF ALLE RECHTE AUF EIN JURYVERFAHREN und wählen
            stattdessen, dass die Streitigkeit von einem Richter beigelegt wird.
          </p>

          <p>
            <strong>
              Verzicht auf Sammelklagen oder konsolidierte Verfahren.
            </strong>{" "}
            Alle Ansprüche und Streitigkeiten im Rahmen dieser
            Schiedsvereinbarung müssen auf individueller Basis durch
            Schiedsverfahren oder Gerichtsverfahren beigelegt werden und nicht
            auf Sammelbasis, und Ansprüche von mehr als einem Kunden oder
            Benutzer können nicht gemeinsam oder konsolidiert mit Ansprüchen
            eines anderen Kunden oder Benutzers geschlichtet oder vor Gericht
            gebracht werden.
          </p>

          <p>
            <strong>Vertraulichkeit.</strong> Alle Aspekte des Schiedsverfahrens
            unterliegen strenger Vertraulichkeit. Die Parteien verpflichten sich
            zur Wahrung der Vertraulichkeit, es sei denn, dies ist gesetzlich
            anders vorgeschrieben. Dieser Absatz hindert eine Partei nicht
            daran, vor einem Gericht alle zur Durchsetzung dieser Vereinbarung,
            zur Durchsetzung eines Schiedsspruchs oder zur Beantragung von
            einstweiligen Verfügungen oder rechtlichen Maßnahmen erforderlichen
            Informationen vorzulegen.
          </p>

          <p>
            <strong>Trennbarkeit.</strong> Falls Teile oder Teile dieser
            Schiedsvereinbarung nach geltendem Recht von einem Gericht mit
            Zuständigkeit für ungültig oder nicht durchsetzbar befunden werden,
            haben diese spezifischen Teile oder Teile keine Wirkung und werden
            abgetrennt, und der Rest der Vereinbarung bleibt in vollem Umfang in
            Kraft und Wirkung.
          </p>

          <p>
            <strong>Recht zum Verzicht.</strong> Alle oder einige der in dieser
            Schiedsvereinbarung festgelegten Rechte und Beschränkungen können
            von der Partei, gegen die der Anspruch geltend gemacht wird,
            aufgehoben werden. Ein solcher Verzicht hebt keinen anderen Teil
            dieser Schiedsvereinbarung auf oder beeinflusst ihn.
          </p>

          <p>
            <strong>Fortbestand der Vereinbarung.</strong> Diese
            Schiedsvereinbarung bleibt auch nach Beendigung Ihrer Beziehung zum
            Unternehmen in Kraft.
          </p>

          <p>
            <strong>Kleinstreitsachen.</strong> Nichtsdestotrotz kann entweder
            Sie oder das Unternehmen eine individuelle Klage vor dem
            Kleinstreitsachen-Gericht anstrengen.
          </p>

          <p>
            <strong>Notfallmaßnahmen für einstweilige Verfügungen.</strong> Jede
            Partei kann jedoch vor einem staatlichen oder bundesstaatlichen
            Gericht Notfallmaßnahmen für einstweilige Verfügungen beantragen, um
            den Status quo während des Schiedsverfahrens aufrechtzuerhalten. Ein
            Antrag auf vorläufige Maßnahmen gilt nicht als Verzicht auf andere
            Rechte oder Verpflichtungen aus dieser Schiedsvereinbarung.
          </p>

          <p>
            <strong>Nicht dem Schiedsverfahren unterliegende Ansprüche.</strong>{" "}
            Ungeachtet des Vorstehenden unterliegen Ansprüche wegen Verleumdung,
            Verstoß gegen den Computer Fraud and Abuse Act sowie Verletzung oder
            widerrechtliche Aneignung des Patents, Urheberrechts, Markenrechts
            oder Geschäftsgeheimnisses der anderen Partei nicht dieser
            Schiedsvereinbarung.
          </p>

          <p>
            In allen Fällen, in denen die vorstehende Schiedsvereinbarung den
            Parteien die Möglichkeit bietet, vor Gericht zu klagen, vereinbaren
            die Parteien hiermit, sich der persönlichen Gerichtsbarkeit der
            Gerichte im US-Bundesstaat Kalifornien für solche Zwecke zu
            unterwerfen.
          </p>

          <p>
            Die Website kann US-Exportkontrollgesetzen unterliegen und kann
            Export- oder Importbestimmungen in anderen Ländern unterliegen. Sie
            erklären sich damit einverstanden, keine US-Technologie-Daten, die
            von der Firma erworben wurden, oder Produkte, die solche Daten
            verwenden, direkt oder indirekt in Verletzung der US-Exportgesetze
            oder -vorschriften zu exportieren, erneut zu exportieren oder zu
            übertragen.
          </p>

          <p>
            Das Unternehmen hat seinen Sitz unter der Adresse in Abschnitt 10.8.
            Wenn Sie ein kalifornischer Bewohner sind, können Sie Beschwerden an
            die Complaint Assistance Unit der Division of Consumer Product des
            California Department of Consumer Affairs richten, indem Sie sich
            schriftlich an 400 R Street, Sacramento, CA 95814, oder telefonisch
            unter (800) 952-5210 wenden.
          </p>

          <p>
            <strong>Elektronische Kommunikation.</strong> Die Kommunikation
            zwischen Ihnen und dem Unternehmen erfolgt elektronisch, unabhängig
            davon, ob Sie die Website nutzen, uns E-Mails senden oder das
            Unternehmen Mitteilungen auf der Website veröffentlicht oder per
            E-Mail mit Ihnen kommuniziert. Zu vertraglichen Zwecken (a) stimmen
            Sie zu, von der Firma elektronische Mitteilungen zu erhalten; und
            (b) erklären Sie sich damit einverstanden, dass alle Bedingungen,
            Vereinbarungen, Mitteilungen, Offenlegungen und andere
            Kommunikationen, die die Firma Ihnen elektronisch zur Verfügung
            stellt, jede rechtliche Verpflichtung erfüllen, die solche
            Kommunikationen erfüllen würden, wenn sie in schriftlicher Form
            vorliegen würden.
          </p>

          <p>
            <strong>Gesamte Bedingungen.</strong> Diese Bedingungen stellen die
            gesamte Vereinbarung zwischen Ihnen und uns in Bezug auf die Nutzung
            der Website dar. Unser Unterlassen der Ausübung oder Durchsetzung
            eines Rechts oder einer Bestimmung dieser Bedingungen stellt keine
            Verzicht auf dieses Recht oder diese Bestimmung dar. Die
            Abschnittsüberschriften in diesen Bedingungen dienen nur der
            Bequemlichkeit und haben keine rechtliche oder vertragliche Wirkung.
            Das Wort "einschließlich" bedeutet "einschließlich, aber nicht
            beschränkt auf". Wenn eine Bestimmung dieser Bedingungen für
            ungültig oder nicht durchsetzbar befunden wird, bleiben die anderen
            Bestimmungen dieser Bedingungen unberührt, und die ungültige oder
            nicht durchsetzbare Bestimmung wird so geändert, dass sie im
            gesetzlich zulässigen Umfang gültig und durchsetzbar ist. Ihr
            Verhältnis zum Unternehmen ist das eines unabhängigen
            Auftragnehmers, und keine der Parteien ist Vertreter oder Partner
            der anderen. Diese Bedingungen und Ihre Rechte und Pflichten hierin
            dürfen von Ihnen ohne vorherige schriftliche Zustimmung des
            Unternehmens nicht abgetreten, unterbeauftragt, übertragen oder
            anderweitig übertragen werden, und jeder Versuch einer solchen
            Abtretung, Unterbeauftragung, Übertragung oder eines solchen
            Verstoßes wird nichtig und nichtig. Das Unternehmen kann diese
            Bedingungen frei übertragen. Die in diesen Bedingungen festgelegten
            Bedingungen und Bestimmungen sind für Abtretungsempfänger
            verbindlich.
          </p>

          <p>
            <strong>Ihre Privatsphäre.</strong> Bitte lesen Sie unsere
            Datenschutzrichtlinie. Weitere Informationen finden Sie in unserer
            Datenschutzrichtlinie. Wenn Sie Fragen oder Bedenken haben, zögern
            Sie bitte nicht, uns zu kontaktieren.
          </p>

          <p>
            <strong>Urheber-/Markeninformationen.</strong> Urheberrecht ©. Alle
            Rechte vorbehalten. Alle auf der Website angezeigten Marken, Logos
            und Dienstleistungsmarken sind unser Eigentum oder das Eigentum
            anderer Dritter. Sie dürfen diese Marken nicht ohne unsere vorherige
            schriftliche Zustimmung oder die Zustimmung solcher Dritter, die die
            Marken besitzen könnten, verwenden.
          </p>
          <h4>
            Die Nutzung und Übertragung von Informationen, die Kepicker von
            <a
              href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
              target="_blank"
            >
              <u>Google APIs erhält</u>
            </a>
            an jede andere App unterliegt der Benutzerdatenrichtlinie für Google
            API-Dienste, einschließlich der Anforderungen zur eingeschränkten
            Nutzung
          </h4>
          <h2>Kontaktinformationen</h2>

          <p>Adresse: Delaware, USA</p>
          <p>E-Mail: info@kepicker.com</p>
        </div>
      )}
    </>
  );
};

export default DeTerms;
