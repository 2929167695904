import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Table, Tag, Space } from "antd";
import translate from "../../../shared/utils/translations";
import EmptyIcon from "../../../../assets/img/empty.png";

const CurrentPlanHistory = ({ isInvoice, setInvoice, data, fetchInvoice }) => {
  let navigate = useNavigate();

  const columns = [
    {
      title: translate("Invoice"),
      dataIndex: "payment_intent_id",
      key: "payment_intent_id",
      render: (item) => (
        <span className="table_bold_text w_icn ellipsis_That">
          <span className="icn">
            <FeatherIcon icon={"file"} />
          </span>{" "}
          {item}
        </span>
      ),
    },
    {
      title: translate("Billing Date"),
      dataIndex: "created_at",
      key: "created_at",
      render: (item) => (
        <>
          <div className="column_info">{translate("Billing Date")}</div>
          {item?.replace("T", " - ")?.split(".")[0]}
        </>
      ),
    },
    {
      title: translate("Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (item, record) => (
        <>
          <div className="column_info">{translate("Amount")}</div>
          {item + " " + record.currency.toUpperCase()}
        </>
      ),
    },
    {
      title: translate("Plan"),
      dataIndex: "payment_type",
      key: "payment_type",
      render: (item) => (
        <>
          <div className="column_info">{translate("Plan")}</div>
          {item}
        </>
      ),
    },

    {
      title: translate("Status"),
      key: "status",
      dataIndex: "status",
      render: (status) => (
        <>
          <Tag
            key={status}
            className={`default__tag ${
              status === "fail" ? " unpaid" : " paid"
            }`}
          >
            {status?.capitalize()}
          </Tag>
        </>
      ),
    },
    {
      title: translate("Action"),
      key: "action",
      className: "table_actin",
      render: (text, record) => (
        <>
          <div className="column_info">{translate("Details")}</div>
          <a className="table__lnk" target="_blank" href={record?.invoice_url}>
            {record?.status == "failed"
              ? translate("Pay Now!")
              : translate("Show Invoice")}
          </a>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="sessions__table">
        <Table
          columns={columns}
          dataSource={data}
          pagination={{
            locale: {
              items_per_page: translate("Page"),
            },
            position: ["top", "bottom"],
          }}
          locale={{
            emptyText: (
              <span className="empty__tble">
                <img src={EmptyIcon} />
              </span>
            ),
          }}
          className="card_m"
        />
      </div>
    </>
  );
};

export default CurrentPlanHistory;
