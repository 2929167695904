import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import DiscoveryTable from "./_discovery_table";
import translate from "../../../shared/utils/translations";

const Discovery = ({ setPage }) => {
  useEffect(() => {
    setPage("dashboard-admin");
    localStorage.setItem("dashboard-type", "dashboard-admin");
  });

  const [isItem, setItem] = useState("Custom item #1");
  return (
    <>
      <div className="outer__inner">
        <div className="onex_wl bg_white">
          <div className="onex_wl__wrapper">
            <div className="upload__head">
              <h2 className="upload__title h2">Discovery</h2>
              {/* <a
                    className="button-stroke button-small upload__button"
                    href="#!"
                  >
                    More filter
                  </a> */}
            </div>
            <div className="onex_wl__list">
              <div className="keys__enabled d_block">
                <div className="keys__info">
                  If you want your friends to register with a discount, you can
                  invite them with the URL you created.
                </div>
                <div className="keys__list">
                  <div className="keys__item ss">
                    <div className="activity__top ss">
                      <div className="nav">
                        <button
                          className={`nav__link cursorp ${
                            isItem === "Custom item #1" ? " active" : " "
                          }`}
                          onClick={() => setItem("Custom item #1")}
                        >
                          Custom item #1
                        </button>
                        <button
                          className={`nav__link cursorp ${
                            isItem === "Custom item #2" ? " active" : " "
                          }`}
                          onClick={() => setItem("Custom item #2")}
                        >
                          Custom item #2
                        </button>
                        <button
                          className={`nav__link cursorp ${
                            isItem === "Custom item #3" ? " active" : " "
                          }`}
                          onClick={() => setItem("Custom item #3")}
                        >
                          Custom item #3
                        </button>
                        <button
                          className={`nav__link cursorp ${
                            isItem === "Custom item #4" ? " active" : " "
                          }`}
                          onClick={() => setItem("Custom item #4")}
                        >
                          Custom item #4
                        </button>
                      </div>

                      <div className="right_items">
                        <form className="activity__form">
                          <input
                            className="activity__input"
                            type="text"
                            name="search"
                            placeholder={translate("Search")}
                            required=""
                          />
                          <button className="activity__result">
                            <svg className="icon icon-search">
                              <use xlinkHref="#icon-search"></use>
                            </svg>
                          </button>
                        </form>
                        <div className="onex_ts__select">
                          <div className="select__field">
                            <select className="select nice-select  app d_block">
                              <option>Filter item #1</option>
                              <option>Filter item #2</option>
                              <option>Filter item #3</option>
                            </select>
                          </div>
                        </div>
                        <div className="activity__item activity__item_calendar js-activity-item ">
                          <button className="button-stroke button-small activity__button js-activity-button">
                            <span>Export</span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <DiscoveryTable />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Discovery;
