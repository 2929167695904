import React, { useState } from "react";
import { Tooltip, Modal, Button, Input } from "antd";
import translate from "../../../shared/utils/translations";

// erdem gy
const TrackingMarketplacesModal = ({
  isMarketplacesModal,
  setMarketplacesModal,
}) => {
  return (
    <>
      <Modal
        title={translate("Marketplace Selections")}
        centered
        open={isMarketplacesModal}
        onOk={() => setMarketplacesModal(false)}
        onCancel={() => setMarketplacesModal(false)}
        footer={null}
      >
        <div className="coppyy_Asin_Area">
          <p className="ant-copiedd-text">
            {translate(
              "You can track this product in multiple Amazon locales."
            )}
          </p>
          <div className="mrktss__sare">
            <div className="setting__fieldset">
              <div className="radio__boxt rrt">
                {/* ITEM 1 */}
                <label className={`checkbox s_check rd`} for="com">
                  <input
                    className="checkbox__input"
                    type="checkbox"
                    name="marketplaces"
                    id="com"
                    defaultChecked
                  />

                  <span className="checkbox__inner">
                    <svg className="icon icon-plus">
                      <use xlinkHref="#icon-plus"></use>
                    </svg>
                    <svg className="icon icon-check">
                      <use xlinkHref="#icon-check"></use>
                    </svg>
                    <span className="checkbox__text">amazon.com</span>
                  </span>
                </label>

                {/* ITEM 2 */}
                <label className={`checkbox s_check rd`} for="co_uk">
                  <input
                    className="checkbox__input"
                    type="checkbox"
                    name="marketplaces"
                    id="co_uk"
                  />

                  <span className="checkbox__inner">
                    <svg className="icon icon-plus">
                      <use xlinkHref="#icon-plus"></use>
                    </svg>
                    <svg className="icon icon-check">
                      <use xlinkHref="#icon-check"></use>
                    </svg>
                    <span className="checkbox__text">amazon.co.uk</span>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TrackingMarketplacesModal;
