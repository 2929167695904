import React, { useEffect, useLayoutEffect, useState } from "react";
import FeatherIcon from "feather-icons-react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import translate from "../../../utils/translations";
import { Button, Tooltip } from "antd";
import Swal from "sweetalert2";
import toast, { Toaster } from "react-hot-toast";

const MySheets = ({
  sheets,
  setSelectedSheet,
  createNewSheet,
  sheetName,
  setSheetName,
  deleteSheet,
}) => {
  const openInNewTab = (href) => {
    const handleClick = () => {
      window.open(href, "_blank");
    };

    return handleClick;
  };

  const handleInputChange = (e) => {
    setSheetName(e.target.value);
  };

  return (
    <>
      <div className="keys__item">
        <div className="setting__list">
          <div className="setting__section">
            <div className="setting__category cvvf">
              {translate("My Sheets")}

              <div className="new_ampt_colmn tt">
                <input
                  type="text"
                  className="field__input"
                  placeholder={translate("Sheet name..")}
                  value={sheetName}
                  onChange={handleInputChange}
                ></input>
                <Tooltip
                  title={translate("Add New Google Sheet")}
                  placement={"right"}
                >
                  <button
                    onClick={() => {
                      if (sheetName.trim() === "") {
                        toast.error("Please enter a sheet name before adding!");
                      } else {
                        createNewSheet();
                      }
                    }}
                    className="button-stroke button-small activity__button js-activity-button cc link"
                  >
                    <FeatherIcon icon={"plus"} />
                  </button>
                </Tooltip>
              </div>
            </div>
            <div className="setting__fieldset">
              <ul>
                {sheets?.map((sheet, index) => {
                  return (
                    <li
                      className={`saved_setting_itm ${
                        sheet?.is_default ? "active" : ""
                      }`}
                    >
                      <div
                        onClick={() => {
                          setSelectedSheet(sheet.id);
                        }}
                        className="saved_setting_title"
                      >
                        <span>{sheet.sheet_title}</span>
                      </div>
                      <div className="saved_setting_actions">
                        <div className="d_flex_center">
                          <Tooltip title={translate("Delete")} placement="top">
                            <Button
                              onClick={() => deleteSheet(sheet.id)}
                              icon={<FeatherIcon icon={"trash"} />}
                              className={"table_icon"}
                            />
                          </Tooltip>
                          <Tooltip
                            title={translate("Access Sheet")}
                            placement="top"
                          >
                            <Button
                              icon={<FeatherIcon icon={"eye"} />}
                              className={"table_icon"}
                              onClick={openInNewTab(sheet?.sheet_url)}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MySheets;
