import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import UserContext from "../../shared/utils/userContext";
import { requestHandler } from "../../shared/utils/helpers";
import translate from "../../shared/utils/translations";
import Swal from "sweetalert2";
import toast, { Toaster } from "react-hot-toast";
import LineChart from "../../common/charts/line";
import MultipleLine from "../../common/charts/multipleLine";
import ColumnChart from "../../common/charts/column";
import { Helmet } from "react-helmet";

const DashboardAdmin = ({ setPage }) => {
  const loggedInUser = useContext(UserContext);
  let navigate = useNavigate();
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [isSwitch, setSwitch] = useState("report");
  const [dashboardData, setDashboardData] = useState([]);
  const [asinListData, setAsinListData] = useState([]);
  const [paymentData, setPaymentData] = useState([]);
  const [stockData, setStockData] = useState([]);
  const [ticketData, setTicketData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [proxyData, setProxyData] = useState([]);

  const getData = async () => {
    const res = await requestHandler(
      "get",
      "/admin/overview",
      null,
      true,
      navigate,
      "/login"
    );
    console.log("res", res);
    setData(res?.data);
  };
  const getDashboardData = async () => {
    const res = await requestHandler(
      "get",
      "/admin/dashboard",
      null,
      true,
      navigate,
      "/login"
    );
    console.log("res", res);
    setDashboardData(res?.data);
    setAsinListData(res?.data?.asinListCreated);
    setPaymentData(res?.data?.paymentCount);
    setStockData(res?.data?.stockCheckCount);
    setTicketData(res?.data?.ticketAmount);
    setUserData(res?.data?.userCount);
  };
  const getProxyData = async () => {
    const res = await requestHandler(
      "get",
      "/admin/proxylogs",
      null,
      true,
      navigate,
      "/login"
    );
    console.log("res", res);
    setProxyData(res?.data);
  };
  useEffect(() => {
    getData();
    getStatusMessage();
    getDashboardData();
    getProxyData();
  }, []);

  const getStatusMessage = async () => {
    const res = await requestHandler(
      "get",
      "/admin/statusMessage",
      null,
      true,
      navigate,
      "/login"
    );
    console.log("res", res);
    setMessage(res?.data);
  };

  const setStatusMessage = async () => {
    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Are you sure about performing this action?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await requestHandler(
          "post",
          "/admin/statusMessage",
          { message },
          true,
          navigate,
          "/login"
        );
        toast.success(translate("Your message has been sent successfully!.."));
      }
    });
  };

  const resetStatusMessage = async () => {
    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Are you sure about performing this action?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await requestHandler(
          "delete",
          "/admin/statusMessage",
          true,
          navigate,
          "/login"
        );
        toast.success(translate("Message has been resetted successfuly!"));
        setMessage("");
      }
    });
  };

  useEffect(() => {
    setPage("dashboard-admin");
    localStorage.setItem("dashboard-type", "dashboard-admin");
  });
  return (
    <>
      <Helmet>
        <title>{translate("Dashboard Admin")}</title>
      </Helmet>
      <div className={`outer__inner xxc_b`}>
        <div className="onex_wl admin">
          <div className="onex_wl__wrapper pos_relative">
            <div className="onex_wl__main">
              <div className="onex_wl__line flx_cc">
                <h4 className="onex_wl__title h4">{translate("Dashboard")}</h4>

                <div className="admin__switch">
                  <div className="nav">
                    <button
                      className={`nav__link cursorp ${
                        isSwitch === "report" ? " active" : " "
                      }`}
                      onClick={() => setSwitch("report")}
                    >
                      {translate("Report Mode")}
                    </button>
                    <button
                      className={`nav__link cursorp ${
                        isSwitch === "chart" ? " active" : " "
                      }`}
                      onClick={() => setSwitch("chart")}
                    >
                      {translate("Chart Mode")}
                    </button>
                  </div>
                  <NavLink
                    to="/client-dashboard"
                    className="up_btn token_bt cursorp"
                  >
                    <span>{translate("Switch to Client")}</span>
                    <FeatherIcon icon={"arrow-up-right"} />
                  </NavLink>
                </div>
              </div>
            </div>

            <div
              className={`onex_wl__charts ${
                isSwitch === "chart" ? " active" : " "
              }`}
            >
              <div className="onex_wl__item">
                <div className="main_area">
                  {/* <div className="clients_area">
                    <div className="top">
                      <div className="clients_chart">
                        <div className="chart_top">
                          <span className="chart_title">{translate("Balance Activity")}</span>
                          <div className="select__field">
                            <select className="select nice-select  app d_block">
                              <option>Last Year</option>
                              <option>Last Month</option>
                              <option>Last Week</option>
                            </select>
                          </div>
                        </div>
                        <ColumnChart />
                      </div>
                    </div>
                  </div> */}
                  <div className="clients_area">
                    <div className="top">
                      <div className="clients_chart">
                        <div className="chart_top">
                          <span className="chart_title">
                            {translate("Asin list created")}
                          </span>
                          {/* <div className="select__field">
                            <select className="select nice-select  app d_block">
                              <option>Last Year</option>
                              <option>Last Month</option>
                              <option>Last Week</option>
                            </select>
                          </div> */}
                        </div>
                        <LineChart data={asinListData} />
                      </div>
                    </div>
                  </div>

                  <div className="clients_area">
                    <div className="top">
                      <div className="clients_chart">
                        <div className="chart_top">
                          <span className="chart_title">
                            {translate("Stock checked")}
                          </span>
                          {/* <div className="select__field">
                            <select className="select nice-select  app d_block">
                              <option>Last Year</option>
                              <option>Last Month</option>
                              <option>Last Week</option>
                            </select>
                          </div> */}
                        </div>
                        <LineChart data={stockData} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main_area">
                  {/* <div className="clients_area">
                    <div className="top">
                      <div className="clients_chart">
                        <div className="chart_top">
                          <span className="chart_title">{translate("Balance Activity")}</span>
                          <div className="select__field">
                            <select className="select nice-select  app d_block">
                              <option>Last Year</option>
                              <option>Last Month</option>
                              <option>Last Week</option>
                            </select>
                          </div>
                        </div>
                        <ColumnChart />
                      </div>
                    </div>
                  </div> */}
                  <div className="clients_area">
                    <div className="top">
                      <div className="clients_chart">
                        <div className="chart_top">
                          <span className="chart_title">
                            {translate("Ticket created")}
                          </span>
                          {/* <div className="select__field">
                            <select className="select nice-select  app d_block">
                              <option>Last Year</option>
                              <option>Last Month</option>
                              <option>Last Week</option>
                            </select>
                          </div> */}
                        </div>
                        <LineChart data={ticketData} />
                      </div>
                    </div>
                  </div>

                  <div className="clients_area">
                    <div className="top">
                      <div className="clients_chart">
                        <div className="chart_top">
                          <span className="chart_title">
                            {translate("Payment accepted")}
                          </span>
                          {/* <div className="select__field">
                            <select className="select nice-select  app d_block">
                              <option>Last Year</option>
                              <option>Last Month</option>
                              <option>Last Week</option>
                            </select>
                          </div> */}
                        </div>
                        <LineChart data={paymentData} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main_area">
                  {/* <div className="clients_area">
                    <div className="top">
                      <div className="clients_chart">
                        <div className="chart_top">
                          <span className="chart_title">{translate("Balance Activity")}</span>
                          <div className="select__field">
                            <select className="select nice-select  app d_block">
                              <option>Last Year</option>
                              <option>Last Month</option>
                              <option>Last Week</option>
                            </select>
                          </div>
                        </div>
                        <ColumnChart />
                      </div>
                    </div>
                  </div> */}
                  <div className="clients_area">
                    <div className="top">
                      <div className="clients_chart">
                        <div className="chart_top">
                          <span className="chart_title">
                            {translate("Users registered")}
                          </span>
                          {/* <div className="select__field">
                            <select className="select nice-select  app d_block">
                              <option>Last Year</option>
                              <option>Last Month</option>
                              <option>Last Week</option>
                            </select>
                          </div> */}
                        </div>
                        <LineChart data={userData} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main_area">
                  <div className="clients_area">
                    <div className="top">
                      <div className="clients_chart">
                        <div className="chart_top">
                          <span className="chart_title">{"Proxy Logs"}</span>
                          {/* <div className="select__field">
                            <select className="select nice-select  app d_block">
                              <option>Last Year</option>
                              <option>Last Month</option>
                              <option>Last Week</option>
                            </select>
                          </div> */}
                        </div>
                        <MultipleLine data={proxyData} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={`onex_wl__list ${
                isSwitch === "chart" ? " " : " active"
              }`}
            >
              <div className="admin__Cards">
                <div className="admin__card ww100">
                  <img src="/assets/img/balance.svg"></img>
                  <div>
                    <h3>
                      <span>{translate("Balance")}</span>
                    </h3>
                    <div className="card__cntnt">
                      <div className="total__inf">
                        $
                        {data?.payments?.amount > 0
                          ? data?.payments?.amount
                          : 0}
                      </div>
                      <div className="details__Card">
                        <div className="detail__card">
                          <h5>
                            <span className="st__detail"></span>
                            {translate("Total Paid")}
                          </h5>
                          <div className="nn_nmbr">
                            {data?.payments?.count > 0
                              ? data?.payments?.count
                              : 0}{" "}
                            {translate("times")}
                          </div>
                        </div>

                        <div className="detail__card">
                          <h5>
                            <span className="st__detail green"></span>
                            {translate("Paid Users")}
                          </h5>
                          <div className="nn_nmbr">{data?.users?.paid}</div>
                        </div>
                        <div className="detail__card">
                          <h5>
                            <span className="st__detail green"></span>Basic
                          </h5>
                          <div className="nn_nmbr">{data?.users?.tier2}</div>
                        </div>
                        <div className="detail__card">
                          <h5>
                            <span className="st__detail green"></span>Plus
                          </h5>
                          <div className="nn_nmbr">{data?.users?.tier3}</div>
                        </div>
                        <div className="detail__card">
                          <h5>
                            <span className="st__detail green"></span>Pro
                          </h5>
                          <div className="nn_nmbr">{data?.users?.tier4}</div>
                        </div>
                        <div className="detail__card">
                          <h5>
                            <span className="st__detail red"></span>
                            {translate("Canceled Users")}
                          </h5>
                          <div className="nn_nmbr">
                            {data?.users?.stripeCanceledSubscriptionCount}
                          </div>
                        </div>
                        <div className="detail__card">
                          <h5>
                            <span className="st__detail blue"></span>
                            {translate("Stripe Customers")}
                          </h5>
                          <div className="nn_nmbr">
                            {data?.users?.stripeCustomerCount}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card__footr">
                      <NavLink
                        to="/balance"
                        className="up_btn token_bt cursorp"
                      >
                        <span>{translate("Learn more")}</span>
                        <FeatherIcon icon={"arrow-up-right"} />
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="admin__card ww100">
                  <img src="/assets/img/clients.svg"></img>
                  <div>
                    <h3>
                      <span>{translate("Users")}</span>
                    </h3>
                    <div className="card__cntnt">
                      <div className="total__inf">{data?.users?.total}</div>

                      <div className="details__Card">
                        <div className="detail__card">
                          <h5>
                            <span className="st__detail green"></span>
                            {translate("Connected Users")}
                          </h5>
                          <div className="nn_nmbr">
                            {data?.users?.marketConnected}
                          </div>
                        </div>
                        <div className="detail__card">
                          <h5>
                            <span className="st__detail green"></span>
                            {translate("NA Store")}
                          </h5>
                          <div className="nn_nmbr">
                            {data?.users?.marketConnectedNA}
                          </div>
                        </div>
                        <div className="detail__card">
                          <h5>
                            <span className="st__detail green"></span>
                            {translate("EU Store")}
                          </h5>
                          <div className="nn_nmbr">
                            {data?.users?.marketConnectedEU}
                          </div>
                        </div>
                        <div className="detail__card">
                          <h5>
                            <span className="st__detail green"></span>
                            {translate("FE Store")}
                          </h5>
                          <div className="nn_nmbr">
                            {data?.users?.marketConnectedFE}
                          </div>
                        </div>
                        <div className="detail__card">
                          <h5>
                            <span className="st__detail red"></span>
                            {translate("Not Connected Store")}
                          </h5>
                          <div className="nn_nmbr">
                            {data?.users?.total - data?.users?.marketConnected}
                          </div>
                        </div>
                        <div className="detail__card">
                          <h5>
                            <span className="st__detail blue"></span>
                            {translate("Google Connected Users")}
                          </h5>
                          <div className="nn_nmbr">
                            {data?.users?.googleSheetConnected}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card__footr">
                      <NavLink to="/users" className="up_btn token_bt cursorp">
                        <span>{translate("Learn more")}</span>
                        <FeatherIcon icon={"arrow-up-right"} />
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="admin__card">
                  <img src="/assets/img/tickets.svg"></img>
                  <div>
                    <h3>
                      <span>{translate("Tickets")}</span>
                    </h3>
                    <div className="card__cntnt">
                      <div className="total__inf">
                        {data?.tickets?.total > 0 ? data?.tickets?.total : 0}
                      </div>
                      <div className="details__Card">
                        <div className="detail__card">
                          <h5>
                            <span className="st__detail green"></span>
                            {translate("Closed")}
                          </h5>
                          <div className="nn_nmbr">
                            {data?.tickets?.closed > 0
                              ? data?.tickets?.closed
                              : 0}
                          </div>
                        </div>
                        <div className="detail__card">
                          <h5>
                            <span className="st__detail red"></span>
                            {translate("Open")}
                          </h5>
                          <div className="nn_nmbr">
                            {data?.tickets?.open > 0 ? data?.tickets?.open : 0}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card__footr">
                      <NavLink
                        to="/admin-tickets"
                        className="up_btn token_bt cursorp"
                      >
                        <span>{translate("Learn more")}</span>
                        <FeatherIcon icon={"arrow-up-right"} />
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="admin__card">
                  <img src="/assets/img/support.svg"></img>
                  <div>
                    <h3>
                      <span>{translate("Web support")}</span>
                    </h3>
                    <div className="card__cntnt">
                      <div className="total__inf">
                        {data?.supports > 0 ? data?.supports : 0}
                      </div>
                      <div className="details__Card">
                        <div className="detail__card">
                          {translate("Messages")}
                        </div>
                      </div>
                    </div>
                    <div className="card__footr">
                      <NavLink
                        to="/admin-support"
                        className="up_btn token_bt cursorp"
                      >
                        <span>{translate("Learn more")}</span>
                        <FeatherIcon icon={"arrow-up-right"} />
                      </NavLink>
                    </div>
                  </div>
                </div>

                <div className="admin__card">
                  <div style={{ width: "100%" }}>
                    <h3>
                      <span>{translate("Extension Status Message")}</span>
                    </h3>
                    <div className="card__cntnt ststs">
                      <input
                        className="field__input"
                        type="text"
                        placeholder={translate("Type status message..")}
                        value={message}
                        onChange={(e) => {
                          setMessage(e.target.value);
                        }}
                      />{" "}
                      <div className="btns__areat">
                        <button
                          className="button-small setting__button"
                          onClick={setStatusMessage}
                        >
                          {translate("Send")}
                        </button>
                        <button
                          className="button-small button-stroke setting__button"
                          onClick={resetStatusMessage}
                        >
                          {translate("Reset")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardAdmin;
