import React, { useState, useEffect } from "react";
import UserContext from "./userContext";
import { Auth } from "aws-amplify";

const UserProvider = ({ children }) => {
  const [user, setUser] = useState("init");
  console.log("user provider rendered");

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        console.log("User Data:", currentUser);
        setUser(currentUser);
      } catch (error) {
        console.log("Error fetching user", error);
        setUser(null);
      }
    };

    fetchUser();
  }, []);

  return (
    user !== "init" && <UserContext.Provider value={user?.signInUserSession?.idToken?.payload}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
