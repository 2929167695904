import React, { useEffect, useLayoutEffect, useState } from "react";
import { Drawer, Tooltip } from "antd";
import translate from "../../../../shared/utils/translations";

// erdem gy

const OtherInfosDrawer = ({ isOtherInfos, setOtherInfos, children }) => {
  return (
    <>
      <Drawer
        title={translate("Other Infos")}
        placement="right"
        onClose={() => setOtherInfos(false)}
        open={isOtherInfos}
        width={"50%"}
        className="payment__-drw tkns"
      >
        <>
          <div className={`buy_tokens_detials gghh`}>
            <div className="other__insf__area">{children}</div>
          </div>
        </>
      </Drawer>
    </>
  );
};

export default OtherInfosDrawer;
