import React, { useEffect, useState } from "react";
import { Table, Tag, Dropdown, Menu, Button, Tooltip } from "antd";
import FeatherIcon from "feather-icons-react";
import translate from "../../../../shared/utils/translations";
import EmptyIcon from "../../../../../assets/img/empty.png";
import { requestHandler } from "../../../../shared/utils/helpers";

const StockFetcher = ({ setAsinFetcher, asin, domain, mainData }) => {
  const [stockId, setStockId] = useState(null);
  const [data, setData] = useState([]);
  const [pooling, setPooling] = useState(false);
  const [summary, setSummary] = useState([]);
  const columns = [
    {
      title: translate("Seller Type"),
      dataIndex: "fulfilled_by",
      key: "fulfilled_by",
      sorter: (a, b) => a.fulfilled_by - b.fulfilled_by,
      render: (item) => (
        <>
          <div className="column_info">Seller Type</div>
          {item === "Amazon" ? "FBA" : "FBM"}
        </>
      ),
    },
    {
      title: translate("Seller Name"),
      dataIndex: "seller_name",
      key: "seller_name",
      sorter: (a, b) => a.seller_name - b.seller_name,
      render: (item, row) => (
        <>
          <div className="column_info">Seller Name</div>

          <div className="wthh_iccn">
            {row.buybox ? <img src="/assets/img/buybox_icn.png"></img> : <></>}

            {row?.seller_name
              ?.tolowercase()
              .includes(mainData?.additionalData?.brand?.tolowercase()) && (
              <img className="prvt_icn" src="/assets/img/prvtl.svg"></img>
            )}
            <a
              className="table__lnk"
              rel="noopener noreferrer"
              target="_blank"
              href={`${row.domain}/s?me=${row.seller_id}`}
            >
              {item}
            </a>
          </div>
        </>
      ),
    },
    {
      title: translate("Stock"),
      dataIndex: "stock",
      key: "stock",
      sorter: (a, b) => a.stock - b.stock,
      render: (item, row) => (
        <>
          <div className="column_info">{translate("Stock")}</div>
          <div className="wthh_iccn">
            {row.is_limited ? (
              <img src="/assets/img/maxx_icn.png"></img>
            ) : (
              <></>
            )}
            {item}
          </div>
        </>
      ),
    },
    {
      title: translate("Price"),
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => a.price - b.price,
      render: (item, row) => (
        <>
          <div className="column_info">{translate("Price")}</div>

          {item}
        </>
      ),
    },
    {
      title: translate("Ratings"),
      dataIndex: "rating",
      key: "rating",
      sorter: (a, b) => a.rating - b.rating,
      render: (item) => (
        <>
          <div className="column_info">{translate("Ratings")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Rating Count"),
      dataIndex: "rating_count",
      key: "rating_count",
      sorter: (a, b) => a.rating_count - b.rating_count,
      render: (item) => (
        <>
          <div className="column_info">{translate("Rating Count")}</div>
          {item}%
        </>
      ),
    },
    {
      title: translate("Delivery Time"),
      dataIndex: "delivery_date",
      key: "delivery_date",
      sorter: (a, b) => a.delivery_date - b.delivery_date,
      render: (item) => (
        <>
          <div className="column_info">{translate("Delivery Time")}</div>
          {item}
        </>
      ),
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   className: "table_actin",
    //   render: (text, record) => (
    //     <>
    //       <div className="column_info">Details</div>

    //       <div className="flex_table_buttons">
    //         <Tooltip title={"Fetch Seller Asins"} placement="top">
    //           <Button
    //             onClick={() => setAsinFetcher(true)}
    //             icon={<FeatherIcon icon="package" />}
    //             className={"table_icon"}
    //           />
    //         </Tooltip>
    //       </div>
    //     </>
    //   ),
    // },
  ];

  const startFetchingStocks = async (asin, domain) => {
    console.log("Fetching Stocks for Asin: ", asin);
    const res = await requestHandler(
      "post",
      `/stockFetcherTrigger`,
      {
        asin,
        domain: `https://${domain}`,
      },
      true,
      null,
      null
    );
    setPooling(true);
    setStockId(res.data.stockId);

    console.log("Data: ", data);
  };

  const refreshStocks = async (stockId) => {
    console.log("Refreshing Stocks for Stock Id: ", stockId);
    const res = await requestHandler(
      "get",
      `/stock/${stockId}`,
      {
        stockId,
      },
      true,
      null,
      null
    );

    setSummary(res?.data?.summary);
    console.log("Summary: ", res?.data?.summary, intervalId);
    setData(res?.data?.sellers);
    if (
      res?.data?.summary?.status === "done" ||
      res?.data?.summary?.status === "failed"
    ) {
      setPooling(false);
    }
    console.log("Data: ", data);
  };

  const restartStocks = async () => {
    setData([]);
    startFetchingStocks(asin, domain);
  };

  useEffect(() => {
    if (!stockId) {
      startFetchingStocks(asin, domain);
    }
  }, [asin, domain]);

  let intervalId = null;

  const startPooling = (seconds, refreshStocks, stockId) => {
    // Ensure we clear any existing intervals before starting a new one
    if (intervalId !== null) {
      clearInterval(intervalId);
    }

    intervalId = setInterval(() => {
      refreshStocks(stockId);
    }, seconds * 1000);

    console.log("Pooling Started", intervalId);
  };

  const stopPooling = () => {
    clearInterval(intervalId);
    intervalId = null;
    console.log("Pooling Stopped");
  };

  useEffect(() => {
    console.log("Pooling: ", pooling, stockId);
    if (
      stockId &&
      pooling &&
      summary?.status !== "done" &&
      summary?.status !== "failed"
    ) {
      console.log("Pooling Started", stockId);
      startPooling(5, refreshStocks, stockId);
    } else {
      stopPooling();
    }

    // Cleanup on component unmount or when dependencies change
    return () => {
      stopPooling();
    };
  }, [pooling, stockId, summary?.status]);

  return (
    <>
      <div className="product__cmp_info stck">
        <div className="stock__Table_card">
          {/* started - done */}
          <div className="st__lftt">
            <div className="stts__kp">
              <div className={`circle-loader__kpp ${summary?.status}`}>
                <div className="checkmark__kpp draw__kpp"></div>
              </div>
              <h5>
                {summary?.status === "done"
                  ? translate("Stock Fetching Completed")
                  : summary?.status === "failed"
                  ? translate("Stock Fetching Failed")
                  : translate("Stock Fetching In Progress")}
              </h5>
            </div>

            <div className="res__actionsk">
              {/* <button className="pbi__btnn">Refresh</button> */}
              {["done", "failed"]?.includes(summary?.status) && (
                <button className="pbi__btnn rest">
                  {translate("Restart")}
                </button>
              )}
            </div>
          </div>
          <div className="st__mddlle">
            <div className="res__infs">
              <div className="res__inf_card">
                <h5>
                  <span></span>
                  {translate("All")}
                </h5>
                <span>{summary?.total_stock}</span>
              </div>
              <div className="res__inf_card fba">
                <h5>
                  <span></span>FBA
                </h5>
                <span>
                  {summary?.total_fba_stock ? summary?.total_fba_stock : 0}
                </span>
              </div>
              <div className="res__inf_card fbm">
                <h5>
                  <span></span>FBM
                </h5>
                <span>
                  {summary?.total_fbm_stock ? summary?.total_fbm_stock : 0}
                </span>
              </div>
              {/* <div className="res__inf_card amz">
                <h5>
                  <span></span>AMZ
                </h5>
                <span>
                  {}
                </span>
              </div> */}
            </div>
          </div>
          <div className="st__rghth">
            <div className="iccn_infos">
              <div className="iccn_info">
                <img src="/assets/img/buybox_icn.png"></img>{" "}
                <p>
                  {translate(
                    `Buybox - The buybox value of the crowded area of the target store is taken as reference.`
                  )}
                </p>
              </div>
              <div className="iccn_info tt">
                <img src="/assets/img/prvtl.svg"></img>{" "}
                <p>
                  {translate(
                    `Private Label - Indicates that the seller with the icon is Private Label.`
                  )}
                </p>
              </div>
              <div className="iccn_info">
                <img src="/assets/img/maxx_icn.png"></img>{" "}
                <p>
                  {translate(
                    "Means this seller has limited max order quantity"
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="minii__tablee dif__ablee">
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            locale={{
              emptyText: (
                <span className="empty__tble">
                  <img src={EmptyIcon} />
                </span>
              ),
            }}
            className=" has_pag_btn no"
            rowClassName={(record) =>
              record.seller_type === "FBA"
                ? "fba-row"
                : record.seller_type === "FBM"
                ? "fbm-row"
                : record.seller_type === "AMZ"
                ? "amz-row"
                : ""
            }

            // fba-row, fba-row-light
            // fbm-row, fbm-row-light
            // amz-row, amz-row-light
          />
        </div>
      </div>
    </>
  );
};

export default StockFetcher;
