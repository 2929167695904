import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import translate from "../../shared/utils/translations";
import { Helmet } from "react-helmet";

const Consulting = ({ setPage }) => {
  useEffect(() => {
    setPage("landing");
  });
  return (
    <>
    <Helmet>
      <title>{translate("Amazon Consulting Services | Amazon Seller Experts")}</title>
      <meta
        name="description"
        content={translate(
          "Amazon seller consulting services boost your sales through an in-depth Amazon marketing strategy analysis and customized Amazon management solutions."
        )}
      />
    </Helmet>
    <div className="outer__inner landng">
      <div className="section article">
        <div className="section article__body">
          <div className="article__center center">
            <div className="article__head">
              <h1 className="article__title h1">
              {translate("Amazon Consulting Services")}
              </h1>
              <p className="lnd__p">{translate("Kepicker is the only all-in-one amazon consulting services agency dedicated to growing your brand on the world’s largest marketplace.")}</p>
            </div>
            <div className="player tmt">
              <div className="player__preview">
              <img src="/assets/img/amazon_consulting.png" alt="Amazon Consulting Services" />
              </div>
            </div>
          </div>
        </div>
        <div className="article__wrap">
          <div className="article__center center">
            <div className="content ppost">
              <h2>{translate("Ready to GROW on Amazon? We are the Amazon Experts!")}</h2>
              <p>
              {translate("Kepicker is the only all-in-one amazon consulting services agency dedicated to growing your brand on the world’s largest marketplace. The Kepicker mission is to achieve explosive growth for your brand by utilizing advanced operational marketing strategies and a proprietary set of eCommerce tools. The entire Kepicker team is entirely dedicated to raising your brand above the 3 million sellers on the marketplace through constant ingenuity and courageous innovation.")}
              </p>
              <p>
              {translate("Amazon seller consulting services boost your sales through an in-depth Amazon marketing strategy analysis and customized Amazon management solutions. Kepicker, is your ideal partner in making your Amazon seller strategy reach its full potential. Achieve record-high sales with fine-tuned Amazon selling strategies, proven Amazon digital marketing techniques and Amazon optimization consulting expertise. Kepicker is your strategic partner for driving marketplace growth while eliminating extra work for your team. Contact us to speak with an Amazon consultant.")}
              </p>
              <h3>{translate("Amazon Training Solutions")}</h3>
              <p>
              {translate("At Kepicker, our dedicated Amazon experts have been providing sell-on-Amazon training for more than a decade. Corporate Amazon seller training is vital for businesses that wish to manage their account in-house. While free tutorials and videos are available, they become outdated quickly as the marketplace evolves. Very few dive into all the intricacies of selling on Amazon, and none are as relevant as an Amazon seller training program geared to your unique business.")}
              </p>
              <p>
              {translate("Kepicker teaches Amazon sellers how to set up a successful marketplace account and create top-notch product listings, all while following Amazon’s specific guidelines and rules. Our Amazon seller training program is ideal for the company that has the time and employees to oversee its account daily but requires straightforward, step-by-step guidance to set up and maintain its product listings. Knowing that Amazon is always evolving, our experts provide accurate, timely and reliable information as it pertains to what Amazon expects from sellers today.")}
              </p>
              
              <h4>{translate("What We Do?")}</h4>
              <p>
                <ul>
                  <li><strong>{translate("Amazon Recovery Services")}:</strong> {translate("Don’t let unnoticed errors and deductions eat into your hard-earned profits and unravel your progress and peace of mind.")}</li>
                  <li><strong>{translate("Amazon Account Management")}:</strong>  {translate("If your established business has the inventory and budget to sell on Amazon, but you haven’t yet begun, then you’re in the right place.")}</li>
                  <li><strong>{translate("Amazon Seller Central Consultants")}:</strong>  {translate("Are you a business owner looking to sell on Amazon? Do you want to maximize your success on the world’s largest online marketplace? Look no further!")}</li>
                  <li><strong>{translate("Amazon Vendor Central Consulting")}:</strong>  {translate("With the knowledge and experience to help our clients prosper on the largest online marketplace, our ecommerce consulting team develops profitable Amazon vendor account strategies tailored to your unique needs and goals.")}</li>
                </ul>
              </p> 
            </div>
          </div>
        </div>
      </div>
    </div>

<div className="section onex_p promo_b">
  <div className="onex_p__center center">
    <div className="onex_p__inner">
      <div className="onex_p__head">
        {/* <h2 className="onex_p__title h2">Nulla nec interdum felis</h2> */}
        <div className="onex_p__info info">
          {translate(
            "Looking for Amazon Consultancy Services?"
          )}
          <h2 className="releases__title h2 mt_40">
            <a target={"_blank"} href="tel:+905076106514">
            +90 (507) 610 65 14
            </a>
            <br />
            <a target={"_blank"} href="tel:+905545556218">
              +90 (554) 555 62 18
            </a>
          </h2>
          <div className="releases__info">
            <a target={"_blank"} href="mailto:info@kepicker.com">
              info@kepicker.com
            </a>
          </div>
        </div>
      </div>
      <div className="onex_p__wrapper">
        <a
          target="_blank"
          href="https://twitter.com/kepicker_"
          className="social_link twitter"
        >
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path d="M24.075 4.325c-.9.375-1.8.675-2.85.75 1.05-.6 1.8-1.575 2.175-2.7-.975.6-2.025.975-3.15 1.2C19.35 2.6 18.075 2 16.725 2c-2.7 0-4.95 2.175-4.95 4.95 0 .375.075.75.15 1.125C7.8 7.85 4.2 5.9 1.725 2.9a4.72 4.72 0 0 0-.675 2.475c0 1.725.9 3.225 2.175 4.125-.825 0-1.575-.225-2.25-.6v.075c0 2.4 1.725 4.35 3.975 4.8-.45.075-.825.15-1.275.15-.3 0-.6 0-.9-.075.6 1.95 2.475 3.375 4.575 3.45-1.65 1.35-3.825 2.1-6.15 2.1-.375 0-.825 0-1.2-.075 2.25 1.35 4.875 2.175 7.65 2.175 9.075 0 14.025-7.5 14.025-14.025v-.6c.9-.75 1.725-1.575 2.4-2.55z"></path>
          </svg>
        </a>
        <a
          target="_blank"
          href="https://www.instagram.com/kepicker"
          className="social_link instagram"
        >
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path d="M12 15.8a3.8 3.8 0 1 0 0-7.6 3.8 3.8 0 0 0-3.291 5.7A3.8 3.8 0 0 0 12 15.8h0zM16.749.6h-9.5A6.66 6.66 0 0 0 .6 7.251v9.5A6.66 6.66 0 0 0 7.251 23.4h9.5a6.66 6.66 0 0 0 6.649-6.651v-9.5A6.66 6.66 0 0 0 16.749.6zM12 17.7A5.71 5.71 0 0 1 6.3 12 5.71 5.71 0 0 1 12 6.3a5.71 5.71 0 0 1 5.7 5.7 5.71 5.71 0 0 1-5.7 5.7zm6.65-11.4a.95.95 0 0 1 0-1.9.95.95 0 1 1 0 1.9z"></path>
          </svg>
        </a>
        <a
          target="_blank"
          href="https://www.facebook.com/kepicker.software/"
          className="social_link facebook"
        >
          <svg width="24" height="24" viewBox="0 0 24 24">
            <path d="M9.347 23.25V13.5H6V9h3.347V5.857c0-3.423 2.167-5.107 5.221-5.107 1.463 0 2.72.109 3.086.158v3.577l-2.118.001c-1.661 0-2.036.789-2.036 1.947V9H18l-1.5 4.5h-3v9.75H9.347z"></path>
          </svg>
        </a>
      </div>
    </div>
  </div>
</div>
    </>
  );
};

export default Consulting;
