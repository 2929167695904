import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import translate from "../../shared/utils/translations";
import ReactDOM from "react-dom";
import ModalVideo from "react-modal-video";
import Lottie from "react-lottie-player";
import welcomeAnim from "../../../assets/animations/welcome.json";
import FeatherIcon from "feather-icons-react";

const HowItWorks = ({ setPage }) => {
  useEffect(() => {
    setPage("landing");
    currentLanguage(localStorage.getItem("lang") || "EN");
  });

  const [currentLang, currentLanguage] = useState({});

  const [isOpen, setOpen] = useState(false);
  const [isVideo, setVideo] = useState("c4HTP6oAsUs");
  const [isOverflow, setOverflow] = useState(null);

  const [isInstalled, setInstalled] = useState(false);
  const [isGuide, setGuide] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const extensionInstalled = queryParams.get("installed");
    const extensionGuide = queryParams.get("guide");
    let originalOverflow = document.body.style.overflow;
    setOverflow(originalOverflow);

    if (extensionInstalled) {
      setInstalled(true);
      document.body.style.overflow = "hidden";
    }

    if (extensionGuide) {
      setGuide(true);
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = originalOverflow;
    };
  }, []);

  const resetOverflow = () => {
    if (isGuide && isInstalled) {
      console.log("first");
    } else {
      document.body.style.overflow = isOverflow;
    }
  };

  const resetOverflowForce = () => {
    document.body.style.overflow = isOverflow;
  };

  return (
    <>
      <Helmet>
        <title>{translate("How It Works")}</title>
        <meta
          name="description"
          content={translate(
            "Begin your journey in Kepicker - Streamline your business, enhance market analysis, and boost sales effortlessly. Learn more now!"
          )}
        />
      </Helmet>

      <ModalVideo
        channel="youtube"
        youtube={{ mute: 0, autoplay: 0 }}
        isOpen={isOpen}
        videoId={isVideo}
        onClose={() => setOpen(false)}
      />

      <div className="articles__area">
        <div className="articles__area_title">
          <FeatherIcon icon={"info"} />
          <h5>{translate("Helpful Articles")}</h5>
        </div>
        <div className="item__artcle">
          <NavLink
            to="/how-to-update-chrome-extensions"
            className="tertiary-cta "
          >
            {" "}
            {translate("How to update Chrome extensions?")}{" "}
          </NavLink>
          <FeatherIcon icon={"arrow-up-right"} />
        </div>
        {currentLang === "EN" || currentLang === "DE" ? (
          <>
            <div className="item__artcle">
              <a
                href="https://onexyazilim.com/amazon/kepicker/en/asin-fetcher-grabber-collector-tools-and-seller-finder-extension"
                target="_blank"
                className="tertiary-cta "
              >
                Amazon ASIN Grabber Tools
              </a>
              <FeatherIcon icon={"arrow-up-right"} />
            </div>
            <div className="item__artcle">
              <a
                href="https://onexyazilim.com/amazon/kepicker/en/amazon-fba-fee-calculator-roi-shipping-costs"
                target="_blank"
                className="tertiary-cta "
              >
                Amazon FEE Calculators
              </a>
              <FeatherIcon icon={"arrow-up-right"} />
            </div>
            <div className="item__artcle">
              <a
                href="https://onexyazilim.com/amazon/kepicker/en/amazon-keyword-tool-product-research-and-analysis"
                target="_blank"
                className="tertiary-cta "
              >
                Amazon Product Analysis Tools
              </a>
              <FeatherIcon icon={"arrow-up-right"} />
            </div>
            <div className="item__artcle">
              <a
                href="https://onexyazilim.com/amazon/kepicker/en/all-amazon-calculators"
                target="_blank"
                className="tertiary-cta "
              >
                Explore Amazon Calculators
              </a>
              <FeatherIcon icon={"arrow-up-right"} />
            </div>
          </>
        ) : (
          <>
            <div className="item__artcle">
              <a
                href="https://onexyazilim.com/amazon/kepicker/tr/amazon-hesaplama-rehberi"
                target="_blank"
                className="tertiary-cta "
              >
                Amazon Maliyet Hesaplama
              </a>
              <FeatherIcon icon={"arrow-up-right"} />
            </div>
            <div className="item__artcle">
              <a
                href="https://onexyazilim.com/amazon/kepicker/tr/amazon-urun-inceleme-rehberi"
                target="_blank"
                className="tertiary-cta "
              >
                Amazon'da Ürün İnceleme
              </a>
              <FeatherIcon icon={"arrow-up-right"} />
            </div>
            <div className="item__artcle">
              <a
                href="https://onexyazilim.com/amazon/kepicker/tr/amazon-fba-urun-bulma-ve-maliyet-hesaplama"
                target="_blank"
                className="tertiary-cta "
              >
                Fba Depo, Kargo Ücretleri
              </a>
              <FeatherIcon icon={"arrow-up-right"} />
            </div>
            <div className="item__artcle">
              <a
                href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-satis-yapmak-icin-gerekli-sermaye"
                target="_blank"
                className="tertiary-cta "
              >
                Amazon'da Satış Yapmak İçin Gerekli Sermaye
              </a>
              <FeatherIcon icon={"arrow-up-right"} />
            </div>
          </>
        )}
      </div>

      <div className={`welcome__mdl_area ${isInstalled && " show"}`}>
        <div className="welcome__mdl">
          <button
            onClick={() => {
              setInstalled(false);
              resetOverflow();
            }}
            className={`close_ui_btn`}
          >
            <FeatherIcon icon={"x"} />
          </button>
          <div className="left__ar">
            <Lottie
              className="ovw_lotenr"
              loop
              animationData={welcomeAnim}
              play
            />
          </div>
          <div className="right__ar">
            <h4>{translate("Welcome to Kepicker!")}</h4>
            <p>
              {translate(
                "By joining Kepicker, you've now further empowered your journey in the Amazon marketplace! To help you easily find everything you need, start exploring our features. If you have any questions or suggestions, feel free to reach out to us anytime."
              )}
              <br />
              <span>
                {translate(
                  "Make a difference on Amazon with Kepicker! Quickly compare markets, discover the most profitable products, and multiply your sales."
                )}{" "}
              </span>
            </p>
            <div className="link__scf">
              <NavLink to="/blog" className="tertiary-cta ">
                {translate("Blog")}{" "}
              </NavLink>
              <NavLink to="/support" className="tertiary-cta ">
                {translate("Help Center")}{" "}
              </NavLink>
              <a
                href="#!"
                onClick={() => {
                  setInstalled(false);
                  resetOverflow();
                }}
                className="tertiary-cta "
              >
                {translate("How It Works")}{" "}
              </a>
              {/* <NavLink to="/privacy-policy" className="tertiary-cta ">
                {translate("Terms of Use")}{" "}
              </NavLink> */}
            </div>

            <div className="open__guide">
              <p>
                {translate(
                  "Many users have problems installing the plugin because they did not read the getting started guide!"
                )}
              </p>
              <a
                href="#!"
                onClick={() => {
                  setGuide(true);
                }}
                className="tertiary-cta "
              >
                {translate("Beginner's Guide")}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={`user__gide ${isGuide && " show"}`}>
        <div className="card__kpp wlcm marketplace-step modal__it">
          <button
            onClick={() => {
              setGuide(false);
              resetOverflow();
            }}
            className={`close_ui_btn`}
          >
            <FeatherIcon icon={"x"} />
          </button>

          <div className="itmm__wlcc">
            <h3>
              <span>{translate("Hello")}</span>
              <img src="/assets/img/wave.png"></img>
            </h3>
            <p>
              {translate(
                "Have you tried following the suggestions in this list in order?"
              )}
            </p>
          </div>
          <div className="card__kpp_welcome">
            <div className="itmm__wlcc">
              <h4>{translate("Beginner's Guide")}</h4>
              <ul>
                <li>
                  <img src="/assets/img/store.png"></img>
                  <div className="inf__thk">
                    <div className="title__thk">
                      1. {translate("Connect your marketplace.")}
                    </div>
                    <a
                      href="https://www.youtube.com/watch?v=DObvOu2-98Q&list=PLxi7_IEBzcmj2eio4ZJTsAID6AD08s3t8&index=8"
                      target="_blank"
                      className="desc__thk"
                    >
                      {translate("How can I connect my marketplace?")}
                    </a>
                  </div>
                </li>
                <li>
                  <img src="/assets/img/setting.png"></img>
                  <div className="inf__thk">
                    <div className="title__thk">
                      2. {translate("Edit marketplace options.")}
                    </div>
                    <NavLink
                      to="/marketplace-settings"
                      className="desc__thk"
                      onClick={() => {
                        setInstalled(false);
                        setGuide(false);
                        resetOverflowForce();
                      }}
                    >
                      {translate("Where are my marketplace settings?")}
                    </NavLink>
                  </div>
                </li>
                <li>
                  <img src="/assets/img/cloud-download.png"></img>
                  <div className="inf__thk">
                    <div className="title__thk">
                      3. {translate("Download the Kepicker extension.")}
                    </div>
                    <a
                      href="https://chromewebstore.google.com/detail/kepicker/hnpablpopdlabbiopkihkmlfmkgpihdc?hl=en"
                      target="_blank"
                      className="desc__thk"
                    >
                      {translate("Where do I download it?")}
                    </a>
                  </div>
                </li>
                <li>
                  <img src="/assets/img/agenda.png"></img>
                  <div className="inf__thk">
                    <div className="title__thk">
                      4. {translate("Now you are ready to use the extension.")}
                    </div>
                    <NavLink
                      to="/how-it-works"
                      className="desc__thk"
                      onClick={() => {
                        setInstalled(false);
                        setGuide(false);
                        resetOverflowForce();
                      }}
                    >
                      {translate("User guide for the extension?")}
                    </NavLink>
                  </div>
                </li>
              </ul>
            </div>

            <div className="itmm__wlcc">
              <h4>{translate("Still having problems?")}</h4>

              <div className="contact__arnvb">
                <div className="contact__arnv_itm">
                  <img src="/assets/img/ticket.png"></img>
                  <div className="title__thk">{translate("Create Ticket")}</div>
                  <NavLink
                    to="/tickets"
                    className="desc__thk"
                    onClick={() => {
                      setInstalled(false);
                      setGuide(false);
                      resetOverflowForce();
                    }}
                  >
                    {translate("Create a Support Request")}
                  </NavLink>
                </div>
                <div className="contact__arnv_itm">
                  <img src="/assets/img/whatsapp.png"></img>
                  <div className="title__thk">
                    {translate("Whatsapp Support")}
                  </div>
                  <a
                    href="https://wa.me/+905076106514?text=Merhabalar,%20Kepicker%20eklentisi%20ile%20ilgili%20destek%20almak%20istiyorum.%20Yardımcı%20olabilir%20misiniz?"
                    target="_blank"
                    className="desc__thk"
                  >
                    +90 507 610 65 14
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="outer__inner landng gg">
        <div className="section article">
          <div className="section article__body">
            <div className="article__center center">
              <div className="article__head">
                <h1 className="article__title h1">
                  {translate("How It Works")}
                </h1>
                <p className="hiwy__pp">
                  {translate(
                    "Begin your journey in Kepicker - Streamline your business, enhance market analysis, and boost sales effortlessly. Learn more now!"
                  )}
                </p>
                <div className="breadcrumbs hiwy" style={{ display: "block" }}>
                  <img src="/assets/img/user_guide.jpg"></img>
                </div>
              </div>
            </div>
          </div>
          <div className="article__wrayt">
            <div className="article__centered center">
              <div className="how__itt_area">
                <div className="footer-push">
                  <div className="footer-push-content section-width">
                    <div className="item how">
                      <div className="icon"></div>
                      <div className="text">
                        <h3>{translate("Blogs, Latest News")}</h3>
                        <p>
                          {translate(
                            "Here our blogs provide some tips and guides for you to find the best products for your marketplace."
                          )}
                        </p>
                        <NavLink to="/blog" className="tertiary-cta ">
                          {" "}
                          {translate("Latest News ‍& Updates")}{" "}
                        </NavLink>
                      </div>
                    </div>
                    <div className="item faq">
                      <div className="icon"></div>
                      <div className="text">
                        <h3>{translate("Help Center")}</h3>
                        <p>
                          {translate(
                            "Contact us to learn how we can help your business or get answer to technical questions about Kepicker."
                          )}{" "}
                        </p>
                        <NavLink to="/support" className="tertiary-cta ">
                          {" "}
                          {translate("Browse the Help Center")}{" "}
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="how_videos_Area">
                <div className="video__itm">
                  <div
                    className="video__ifrm"
                    onClick={() => {
                      setVideo("oifTTb9vx6Y");
                      setOpen(true);
                    }}
                  >
                    <div className="video__poster">
                      <span className="rbt-btn rounded-player-2 sm-size popup-video position-to-top with-animation">
                        <span className="play-icon"></span>
                      </span>
                      <h4>{translate("How to update Chrome extensions?")}</h4>
                    </div>
                  </div>
                </div>

                <div className="video__itm">
                  <div
                    className="video__ifrm"
                    onClick={() => {
                      setVideo("aLVENf_FOBM");
                      setOpen(true);
                    }}
                  >
                    <div className="video__poster">
                      <span className="rbt-btn rounded-player-2 sm-size popup-video position-to-top with-animation">
                        <span className="play-icon"></span>
                      </span>
                      <h4>
                        {translate(
                          "1. What is Kepicker Software? What Does It Do? Introduction to Training.."
                        )}
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="video__itm">
                  <div
                    className="video__ifrm"
                    onClick={() => {
                      setVideo("k84g3kSnLmI");
                      setOpen(true);
                    }}
                  >
                    <div className="video__poster">
                      <span className="rbt-btn rounded-player-2 sm-size popup-video position-to-top with-animation">
                        <span className="play-icon"></span>
                      </span>
                      <h4>
                        {translate("2. Account Opening: How Can I Register?")}
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="video__itm">
                  <div
                    className="video__ifrm"
                    onClick={() => {
                      setVideo("DObvOu2-98Q");
                      setOpen(true);
                    }}
                  >
                    <div className="video__poster">
                      <span className="rbt-btn rounded-player-2 sm-size popup-video position-to-top with-animation">
                        <span className="play-icon"></span>
                      </span>
                      <h4>
                        {translate(
                          "3. Connecting Amazon Seller Central Account (Store), Downloading the Extension"
                        )}
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="video__itm">
                  <div
                    className="video__ifrm"
                    onClick={() => {
                      setVideo("XtF0HOMMLBA");
                      setOpen(true);
                    }}
                  >
                    <div className="video__poster">
                      <span className="rbt-btn rounded-player-2 sm-size popup-video position-to-top with-animation">
                        <span className="play-icon"></span>
                      </span>
                      <h4>
                        {translate(
                          "4. How Can I Configure Marketplace Settings?"
                        )}
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="video__itm">
                  <div
                    className="video__ifrm"
                    onClick={() => {
                      setVideo("ONU7H5EMH0U");
                      setOpen(true);
                    }}
                  >
                    <div className="video__poster">
                      <span className="rbt-btn rounded-player-2 sm-size popup-video position-to-top with-animation">
                        <span className="play-icon"></span>
                      </span>
                      <h4>
                        {translate(
                          "5. Asin SPY, Asin Extractor, Uploader Tool Tools"
                        )}
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="video__itm">
                  <div
                    className="video__ifrm"
                    onClick={() => {
                      setVideo("ylSDxCLW848");
                      setOpen(true);
                    }}
                  >
                    <div className="video__poster">
                      <span className="rbt-btn rounded-player-2 sm-size popup-video position-to-top with-animation">
                        <span className="play-icon"></span>
                      </span>
                      <h4>
                        {translate(
                          "6. Compare Marketplaces; Product Comparison Panel, ASIN Management"
                        )}
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="video__itm">
                  <div
                    className="video__ifrm"
                    onClick={() => {
                      setVideo("dBgjKLOG-zk");
                      setOpen(true);
                    }}
                  >
                    <div className="video__poster">
                      <span className="rbt-btn rounded-player-2 sm-size popup-video position-to-top with-animation">
                        <span className="play-icon"></span>
                      </span>
                      <h4>
                        {translate(
                          "7. Product Based Info (Metrics, ROI, Calculation, Keepa Data, Stock Fetching, Google Sheets..)"
                        )}
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="video__itm">
                  <div
                    className="video__ifrm"
                    onClick={() => {
                      setVideo("kK7fnU3T4W8");
                      setOpen(true);
                    }}
                  >
                    <div className="video__poster">
                      <span className="rbt-btn rounded-player-2 sm-size popup-video position-to-top with-animation">
                        <span className="play-icon"></span>
                      </span>
                      <h4>
                        {translate(
                          "8. Using Kepicker Dashboard: Asin 360°, Ticket, Plan, Settings.."
                        )}
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="video__itm">
                  <div
                    className="video__ifrm"
                    onClick={() => {
                      setVideo("Jp2F58fcYxk");
                      setOpen(true);
                    }}
                  >
                    <div className="video__poster">
                      <span className="rbt-btn rounded-player-2 sm-size popup-video position-to-top with-animation">
                        <span className="play-icon"></span>
                      </span>
                      <h4>
                        {translate(
                          "Learn Kepicker Software in 1 Hour (Complete from Start to Finish in One Video)!"
                        )}
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="video__itm"></div>
                <div className="video__itm"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWorks;
