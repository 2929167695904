import React, { useState, useEffect } from "react";

import { NavLink, useNavigate } from "react-router-dom";

import translate from "../../../shared/utils/translations";
import FeatherIcon from "feather-icons-react";
import { Table, Tag, Dropdown, InputNumber, Button, Tooltip } from "antd";
import { FilterFilled } from "@ant-design/icons";
import EmptyIcon from "../../../../assets/img/empty.png";
import Swal from "sweetalert2";
import toast, { Toaster } from "react-hot-toast";
import { requestHandler } from "../../../shared/utils/helpers";
import NoImage from "../../../../assets/img/no_image.png";

const FavoritesTable = ({ data, removeFavorite, refreshData }) => {
  let navigate = useNavigate();
  const [isTableSort, setTableSort] = useState(() => {
    const savedSort = localStorage.getItem("tableSort");
    return savedSort !== null ? savedSort : "list";
  });
  const [resetTrigger, setResetTrigger] = useState(false);

  const resetFilters = () => {
    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Are you sure about performing this action?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setResetTrigger((prev) => !prev);
        toast.success(translate("All Filters cleared!"));
      }
    });
  };

  const [filterValues, setFilterValues] = useState({
    roi: { min: undefined, max: undefined },
    sales_rank: { min: undefined, max: undefined },
    base_domain_converted_price: { min: undefined, max: undefined },
    target_domain_price: { min: undefined, max: undefined },
    monthly_sold: { min: undefined, max: undefined },
  });

  useEffect(() => {
    if (resetTrigger) {
      setFilterValues({
        roi: { min: undefined, max: undefined },
        sales_rank: { min: undefined, max: undefined },
        base_domain_converted_price: { min: undefined, max: undefined },
        target_domain_price: { min: undefined, max: undefined },
        monthly_sold: { min: undefined, max: undefined },
      });
    }
  }, [resetTrigger]);

  const getNumericColumnFilterProps = (dataIndex, title) => ({
    filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: "15px" }}>
        <h6>{translate("Filter by")}</h6>
        <div className="fltt_bddy">
          <InputNumber
            placeholder={translate("Min")}
            value={filterValues[dataIndex]?.min}
            onChange={(value) =>
              setFilterValues((prev) => ({
                ...prev,
                [dataIndex]: { ...prev[dataIndex], min: value },
              }))
            }
            style={{ width: "100%" }}
          />
          <InputNumber
            placeholder={translate("Max")}
            value={filterValues[dataIndex]?.max}
            onChange={(value) =>
              setFilterValues((prev) => ({
                ...prev,
                [dataIndex]: { ...prev[dataIndex], max: value },
              }))
            }
            style={{ width: "100%" }}
          />
        </div>

        <div className="fltt__btnss">
          <Button
            type="primary"
            onClick={() => {
              setSelectedKeys([
                [filterValues[dataIndex]?.min, filterValues[dataIndex]?.max],
              ]);
              confirm();
            }}
            size="small"
            style={{ width: "100%" }}
          >
            {translate("Apply")}
          </Button>
          <Button
            onClick={() => {
              setFilterValues((prev) => ({
                ...prev,
                [dataIndex]: { min: undefined, max: undefined },
              }));
              setSelectedKeys([]);
              clearFilters();
            }}
            size="small"
            style={{ width: "100%" }}
          >
            {translate("Reset")}
          </Button>
        </div>
      </div>
    ),
    filterIcon: (filtered) => (
      <FilterFilled style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      const [min, max] = value || []; // Directly get min and max from array
      const recordValue = record[dataIndex]; // Get the value for the current column

      if (min !== undefined && max !== undefined) {
        return recordValue >= min && recordValue <= max; // Filter between min and max
      } else if (min !== undefined) {
        return recordValue >= min; // Filter greater than or equal to min
      } else if (max !== undefined) {
        return recordValue <= max; // Filter less than or equal to max
      }
      return true; // If no filter is applied, return all records
    },
    render: (item) => <>{item}</>,
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  useEffect(() => {
    localStorage.setItem("tableSort", isTableSort);
  }, [isTableSort]);
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const deleteItem = (asin) => {
    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Are you sure about performing this action?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        toast.success(translate("Your action was successful!"));
        removeFavorite(asin);
      }
    });
  };

  const deleteItems = async () => {
    console.log("selectedRowKeys", selectedRowKeys);

    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Are you sure about performing this action?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await requestHandler(
          "delete",
          `/asin/favorites`,
          { favoriteIds: selectedRowKeys },
          true,
          null,
          null
        )
          .then(async () => {
            toast.success(translate("Your action was successful!"));
            setSelectedRowKeys([]);
            await refreshData();
          })
          .catch((error) => {
            toast.error(translate("Something went wrong!"));
          });
      }
    });
  };

  const createAsinList = async () => {
    console.log("selectedRowKeys", selectedRowKeys);
    const asins = selectedRowKeys.map(
      (key) => data.find((item) => item.id === key).asin
    );
    console.log("asins", asins);
    await requestHandler(
      "post",
      "/asinLists",
      { asinArr: asins, name: "Selected Favorites", from: "Import" },
      true,
      null,
      null
    ).then(async () => {
      toast.success(translate("Your action was successful!"));
      setSelectedRowKeys([]);

      //navigate("/asin360");
      navigate("/asin360");
    });
  };
  const refresh = async (favoriteId) => {
    console.log("refresh", favoriteId);
    await requestHandler(
      "put",
      `/asin/refresh/${favoriteId}`,
      null,
      true,
      null,
      null
    ).then(async () => {
      toast.success(translate("Your action was successful!"));
      await refreshData();
    });
  };

  const makeReadable = (text) => {
    if (!text) {
      return "";
    }

    return text
      .split("_")
      .map((word) => {
        let words = word.split(/(?=[A-Z])/);
        return words
          .map((w) => w.charAt(0).toUpperCase() + w.slice(1).toLowerCase())
          .join(" ");
      })
      .join(" ");
  };

  const columns = [
    {
      title: translate("Image"),
      dataIndex: "image",
      key: "image",
      width: 150,
      minWidth: 100,
      render: (src) => (
        <>
          <img className="product-photo" src={src || NoImage} alt="" />
        </>
      ),
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      width: 150,
      minWidth: 100,
      render: (item) => (
        <>
          <div className="column_info">ASIN</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Title"),
      dataIndex: "title",
      key: "title",
      width: 200,
      minWidth: 100,
      render: (item) => (
        <>
          <div className="column_info">{translate("Title")}</div>
          {item?.length > 50 ? item.slice(0, 50) + "..." : item}
        </>
      ),
    },
    {
      title: translate("Source price"),
      dataIndex: "base_domain_converted_price",
      key: "base_domain_converted_price",
      width: 120,
      minWidth: 100,
      sorter: (a, b) =>
        a.base_domain_converted_price - b.base_domain_converted_price,
      ...getNumericColumnFilterProps(
        "base_domain_converted_price",
        "Source Price"
      ),
      render: (item) => (
        <>
          <div className="column_info">{translate("Source price")}</div>
          {item ? item : " - "}
        </>
      ),
    },
    {
      title: translate("Target price"),
      dataIndex: "target_domain_price",
      key: "target_domain_price",
      width: 120,
      minWidth: 100,
      sorter: (a, b) => a.target_domain_price - b.target_domain_price,
      ...getNumericColumnFilterProps("target_domain_price", "Target Price"),
      render: (item) => (
        <>
          <div className="column_info">{translate("Target price")}</div>
          {item ? item : " - "}
        </>
      ),
    },

    {
      title: translate("ROI"),
      dataIndex: "roi",
      key: "roi",
      width: 120,
      minWidth: 100,
      sorter: (a, b) => a.roi - b.roi,
      ...getNumericColumnFilterProps("roi", "ROI"),
      render: (item) => (
        <>
          <div className="column_info">{translate("ROI")}</div>
          <span
            className={`roi__tag 
            ${
              item >= 40
                ? " kp_green"
                : item < 40 && item >= 15
                ? " kp_green_soft"
                : item < 15 && item >= 5
                ? " kp_blue"
                : item < 5 && item >= 0
                ? " kp_orange"
                : item < 0
                ? " kp_red"
                : " "
            }
            `}
          >
            {item ? item : " - "}
            {item && "%"}
          </span>
        </>
      ),
    },
    {
      title: translate("Is Amazon Selling?"),
      dataIndex: "is_amazon_seller",
      key: "is_amazon_seller",
      width: 150,
      minWidth: 100,
      sorter: (a, b) =>
        a.is_amazon_seller === b.is_amazon_seller
          ? 0
          : a.is_amazon_seller
          ? -1
          : 1,
      filters: [
        {
          text: "True",
          value: "filled",
        },
        {
          text: "False",
          value: "empty",
        },
      ],
      onFilter: (value, record) => {
        if (value === "filled") {
          return (
            record.is_amazon_seller !== null &&
            record.is_amazon_seller !== undefined &&
            record.is_amazon_seller !== false &&
            record.is_amazon_seller !== ""
          );
        } else if (value === "empty") {
          return (
            record.is_amazon_seller === null ||
            record.is_amazon_seller === undefined ||
            record.is_amazon_seller === false ||
            record.is_amazon_seller === ""
          );
        }
        return false;
      },
      render: (item) => (
        <>
          <div className="column_info">{translate("Is Amazon Selling?")}</div>
          <span className={`table__tag ${item ? "is_true" : "is_false"}`}>
            {item ? "True" : "False"}
          </span>
        </>
      ),
    },
    {
      title: translate("Monthly Sold"),
      dataIndex: "monthly_sold",
      key: "monthly_sold",
      width: 150,
      minWidth: 100,
      sorter: (a, b) => a.monthly_sold - b.monthly_sold,
      ...getNumericColumnFilterProps("monthly_sold", "Monthly Sold"),
      render: (item) => (
        <>
          <div className="column_info">{translate("Monthly Sold")}</div>
          <strong>{item ? item : " - "}</strong>
        </>
      ),
    },
    {
      title: translate("BSR Drops (30/90/180)"),
      width: 150,
      minWidth: 100,
      sorter: (a, b) => a.sales_rank_drops_30 - b.sales_rank_drops_30,
      render: (item, record) => {
        return (
          <>
            {`${
              record.sales_rank_drops_30 ? record.sales_rank_drops_30 : " - "
            }/${
              record.sales_rank_drops_90 ? record.sales_rank_drops_90 : " - "
            }/${
              record.sales_rank_drops_180 ? record.sales_rank_drops_180 : " - "
            }`}
          </>
        );
      },
    },

    {
      title: translate("Total Offer Count"),
      dataIndex: "total_seller_count",
      key: "total_seller_count",
      width: 120,
      minWidth: 100,
      sorter: (a, b) => a.total_seller_count - b.total_seller_count,
      render: (item) => (
        <>
          <div className="column_info">{translate("Total Offer Count")}</div>
          {item ? item : " - "}
        </>
      ),
    },
    {
      title: translate("FBA Seller Count"),
      dataIndex: "fba_seller_count",
      key: "fba_seller_count",
      width: 120,
      minWidth: 100,
      sorter: (a, b) => a.fba_seller_count - b.fba_seller_count,
      render: (item) => (
        <>
          <div className="column_info">{translate("FBA Seller Count")}</div>
          {item ? item : " - "}
        </>
      ),
    },

    {
      title: translate("FBM Seller Count"),
      dataIndex: "fbm_seller_count",
      key: "fbm_seller_count",
      width: 120,
      minWidth: 100,
      sorter: (a, b) => a.fbm_seller_count - b.fbm_seller_count,
      render: (item) => (
        <>
          <div className="column_info">{translate("FBM Seller Count")}</div>
          {item ? item : " - "}
        </>
      ),
    },
    {
      title: translate("Sales Rank Category"),
      dataIndex: "sales_rank_category",
      key: "sales_rank_category",
      width: 150,
      minWidth: 100,
      render: (item) => (
        <>
          <div className="column_info">{translate("Sales Rank Category")}</div>
          {makeReadable(item ? item : " - ")}
        </>
      ),
    },
    {
      title: translate("Sales Rank"),
      dataIndex: "sales_rank",
      key: "sales_rank",
      width: 150,
      minWidth: 100,
      sorter: (a, b) => a.sales_rank - b.sales_rank,
      ...getNumericColumnFilterProps("sales_rank", "Sales Rank"),
      render: (item) => (
        <>
          <div className="column_info">{translate("Sales Rank")}</div>
          <strong>{item ? item : " - "}</strong>
        </>
      ),
    },
    {
      title: translate("Brand"),
      dataIndex: "brand",
      key: "brand",
      width: 100,
      minWidth: 100,
      sorter: (a, b) => a.brand.localeCompare(b.brand),
      render: (item) => (
        <>
          <div className="column_info">{translate("Brand")}</div>
          {item ? item : " - "}
        </>
      ),
    },
    {
      title: translate("Product Group"),
      dataIndex: "product_group",
      key: "product_group",
      width: 150,
      minWidth: 100,
      render: (item) => (
        <>
          <div className="column_info">{translate("Product Group")}</div>
          {item ? item : " - "}
        </>
      ),
    },
    {
      title: translate("Product Type"),
      dataIndex: "product_type_name",
      key: "product_type_name",
      width: 150,
      minWidth: 100,
      render: (item) => (
        <>
          <div className="column_info">{translate("Product Type")}</div>
          <div className="up__textt">{item ? item : " - "}</div>
        </>
      ),
    },
    {
      title: translate("Item Dimensions"),
      dataIndex: "item_dimensions",
      key: "item_dimensions",
      width: 150,
      minWidth: 100,
      render: (item) => (
        <>
          <div className="column_info">{translate("Item Dimensions")}</div>
          {item ? item : " - "}
        </>
      ),
    },
    {
      title: translate("Package Dimensions"),
      dataIndex: "package_dimensions",
      key: "package_dimensions",
      width: 150,
      minWidth: 100,
      render: (item) => (
        <>
          <div className="column_info">{translate("Package Dimensions")}</div>
          {item ? item : " - "}
        </>
      ),
    },
    {
      title: translate("Preference Name"),
      dataIndex: "name",
      key: "name",
      width: 150,
      minWidth: 100,
      render: (item) => (
        <>
          <div className="column_info">{translate("Preference Name")}</div>
          <div className="prff_namme">{item ? item : " - "}</div>
        </>
      ),
    },

    {
      title: translate("Created / Updated date"),
      key: "created_at",
      dataIndex: "created_at",
      width: 150,
      minWidth: 100,

      render: (item, record) => (
        console.log("record", record),
        (
          <>
            <div className="">{new Date(item).toLocaleString()}</div>
            <div className="">
              {new Date(record.updated_at).toLocaleString()}
            </div>
          </>
        )
      ),
    },
    {
      title: translate("Action"),
      key: "action",
      width: 150,
      minWidth: 100,
      fixed: "right",
      className: "table_actin",
      render: (text, record) => (
        <>
          <div className="column_info">{translate("Details")}</div>

          <div className="flex_table_buttons new__fix">
            <Tooltip title={translate("Refresh")} placement="top">
              <Button
                onClick={() => refresh(record.id)}
                icon={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-repeat "
                  >
                    <g>
                      <polyline points="17 1 21 5 17 9"></polyline>
                      <path d="M3 11V9a4 4 0 0 1 4-4h14"></path>
                      <polyline points="7 23 3 19 7 15"></polyline>
                      <path d="M21 13v2a4 4 0 0 1-4 4H3"></path>
                    </g>
                  </svg>
                }
                className={"table_icon"}
              />
            </Tooltip>
            <Tooltip
              title={translate("Show Product Based Info")}
              placement="left"
            >
              <NavLink
                className={"pbi__link table_icon"}
                to={`/product-based-info/${record.asin}`}
                target="_blank"
              >
                <FeatherIcon icon={"package"} />
              </NavLink>
            </Tooltip>

            <Tooltip title={translate("Delete")} placement="top">
              <Button
                onClick={() => deleteItem(record.asin)}
                icon={<FeatherIcon icon="trash" />}
                className={"table_icon"}
              />
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div
        className={`dif__ablee fav_Tbl_wrapper ${
          isTableSort === "list" ? " " : " block_m"
        }`}
      >
        <div className="merged___area">
          <div className="table__sortn">
            <span
              onClick={() => setTableSort("list")}
              className={`${isTableSort === "list" ? "active" : ""}`}
            >
              <FeatherIcon icon={"list"} />
            </span>
            <span
              onClick={() => setTableSort("grid")}
              className={`${isTableSort === "grid" ? "active" : ""}`}
            >
              <FeatherIcon icon={"grid"} />
            </span>
          </div>

          <button
            onClick={resetFilters}
            className="button-stroke button-small activity__button js-activity-button cc link"
          >
            <span>
              <FeatherIcon icon={"filter"} /> {translate("Reset Filters")}
            </span>
          </button>
          <button
            onClick={deleteItems}
            disabled={selectedRowKeys?.length < 2}
            className="button-stroke button-small activity__button js-activity-button cc link"
          >
            <span>
              <FeatherIcon icon={"trash"} /> {translate("Delete")}
            </span>
          </button>
          <button
            onClick={createAsinList}
            disabled={selectedRowKeys?.length < 2}
            className="button-stroke button-small activity__button js-activity-button cc link"
          >
            <span>
              <FeatherIcon icon={"archive"} /> {translate("Create ASIN List")}
            </span>
          </button>
        </div>
        <Table
          key={`${resetTrigger}-table__fvttsd`}
          columns={columns}
          rowSelection={rowSelection}
          dataSource={data?.map((item) => ({ ...item, key: item.id }))}
          pagination={{
            locale: {
              items_per_page: translate("Page"),
            },
            position: ["topRight", "bottomRight"],
          }}
          locale={{
            emptyText: (
              <span className="empty__tble">
                <img src={EmptyIcon} />
              </span>
            ),
          }}
          className="mob_bts cmp__table has_pag_btn rest_table favr_tbl"
        />
      </div>
    </>
  );
};

export default FavoritesTable;
