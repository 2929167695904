import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { requestHandler } from "../utils/helpers";
import translate from "../utils/translations";

const Notifications = ({
  notifications,
  setNotifications,
  notificationPopupVisible,
  setNotificationPopupVisible,
}) => {
  const notify = () => toast.success(translate("Notifications have been cleared successfully!.."));
  const [isBtnLoading, setBtnLoading] = useState(false);
  const submit = () => {
    setBtnLoading(true);
    requestHandler("put", "/notifications", null, true, null, null).then(
      (res) => {
        console.log("notifications", res);
        setNotifications([]);
        notify();
        setBtnLoading(false);
      }
    );
  };

  return (
    <div
      className={`header__item header__item_notification js-header-item ml_c ${
        notificationPopupVisible && " active"
      }`}
    >
      <button
        className={`header__head js-header-head ${
          notifications?.filter((i) => !i.is_read)?.length && "active"
        } `}
        onClick={() => setNotificationPopupVisible(!notificationPopupVisible)}
      >
        <svg className="icon icon-notification">
          <use xlinkHref="#icon-notification"></use>
        </svg>
      </button>
      <div className="header__body js-header-body">
        <div className="header__title">{translate("Notifications")}</div>
        <div className="header__list noti">
          {notifications?.length > 0 ? (
            <>
              {notifications.every((notification) => notification?.is_read) ? (
                <div className="empty_box_div">
                  <img src="/assets/img/empty-box.png"></img>
                </div>
              ) : (
                <>
                  {notifications
                    ?.filter((i) => !i?.is_read)
                    .map((notification, index) => {
                      return (
                        <span
                          className={`header__notification ${
                            !notification?.is_read && "header__notification_new"
                          }`}
                          key={index}
                        >
                          <div className="header__subtitle">
                            {notification?.title}
                          </div>
                          <div className="header__subtitle">
                            {notification?.message}
                          </div>
                          <div className="header__date">
                            {
                              notification?.created_at
                                ?.replace("T", " - ")
                                ?.split(".")[0]
                            }
                          </div>
                        </span>
                      );
                    })}
                </>
              )}
            </>
          ) : (
            <div className="empty_box_div">
              <img src="/assets/img/empty-box.png"></img>
            </div>
          )}
        </div>
        <div className="header__btns">
          <NavLink
            className="button-small header__button"
            to="/notifications"
            onClick={() => setNotificationPopupVisible(false)}
          >
            {translate("View all")}
          </NavLink>
          <button
            className="button-stroke button-small header__button"
            onClick={submit}
          >
            {translate("Clear here")}{" "}
            {isBtnLoading && <span className="btn-spinner colored"></span>}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
