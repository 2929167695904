import React from "react";

const Select = ({ label, options, customClass, onChange, value, name }) => {
  return (
    <>
      <div className={`field ${customClass && customClass}`}>
        <div className="field__label">{label}</div>
        <div className="field__wrap">
          <div className="select__field">
            <select className="select nice-select app d_block" onChange={onChange} value={value} name={name}>
              {options.map((option, index) => (
                <option key={index} value={option}>
                  {option.replace("https://www.", "")}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );
};

export default Select;
