import React from "react";
import FeatherIcon from "feather-icons-react";
import translate from "../../../../shared/utils/translations";
import { Tooltip, Progress } from "antd";

const RoiFees = ({ mainData, targetOffersData, customClass }) => {
  const conicColors = {
    "100%": "#e60045",
    "0%": "#009688",
  };

  let percent = mainData?.roi;

  const getStrokeColor = (percentValue) => {
    if (percentValue <= 1) return "#ef0404";
    if (percentValue <= 30) return "#f39c12";
    if (percentValue <= 50) return "#8bc34a";
    if (percentValue <= 75) return "#27ae60";
    if (percentValue <= 99999999) return "#16a085";
    return "#7f8c8d";
  };

  return (
    <>
      <div className={`product__roi_info ${customClass && customClass}`}>
        <Progress
          type="dashboard"
          percent={Math.min(percent, 100) <= 2 ? 1 : Math.min(percent, 100)}
          strokeColor={getStrokeColor(Math.min(percent, 100))}
          format={() => (
            <div className="roi__title">
              <span>Roi</span>
              <div
                style={{
                  color: getStrokeColor(Math.min(percent, 100)),
                }}
                className="roi_valuue"
              >
                {percent}%
              </div>
            </div>
          )}
        />

        <div className="fees__area">
          <div className="fees_item">
            <h4>
              <img src="/assets/img/icons8-profit-64.png" />{" "}
              {translate("Profit")}
            </h4>
            <span>
              {parseFloat(mainData?.profit).toFixed(2)}{" "}
              {targetOffersData?.currency}
            </span>
          </div>
          <div className="fees_item">
            <h4>
              <img src="/assets/img/icons8-coin-nft-64.png" />{" "}
              {translate("Inbound Cost")}
            </h4>
            <span>
              {parseFloat(mainData?.shippingCost).toFixed(2)}{" "}
              {targetOffersData?.currency}
            </span>
          </div>
          <div className="fees_item tt">
            <h4>
              <img src="/assets/img/icons8-card-accepted-64.png" />{" "}
              {translate("Fees")}
            </h4>
            <span>
              {parseFloat(mainData?.fees).toFixed(2)}
              {targetOffersData?.currency}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoiFees;
