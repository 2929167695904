import React from "react";
import { Button, Drawer } from "antd";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Select from "../../../common/form_elements/select";
import Input from "../../../common/form_elements/input";
import Textarea from "../../../common/form_elements/textarea";
import { useNavigate } from "react-router-dom";
import { requestHandler } from "../../../shared/utils/helpers";
import SelectWithOptions from "../../../common/form_elements/select_with_modified_options";
import translate from "../../../shared/utils/translations";

const NewTicketDrawer = ({ isNewDrawer, setNewDrawer, refresh }) => {
  const navigate = useNavigate();
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [formData, setFormData] = useState({});

  const createTicket = async () => {
    setBtnLoading(true);
    console.log("create ticket", formData);
    if (
      !formData.subject ||
      !formData.description ||
      !formData.topic ||
      !formData.priority
    ) {
      toast.error(translate("Please fill in all fields!"));
      setBtnLoading(false);
      return;
    }
    const ticket = await requestHandler(
      "post",
      `/tickets`,
      formData,
      true,
      navigate,
      "/login"
    );
    if (ticket) {
      toast.success(translate("Ticket created successfully!"));
      setBtnLoading(false);
      setNewDrawer(false);
      setFormData({});
      refresh();
    }
  };

  const onChange = (value, name) => {
    console.log(value, name);
    setFormData({ ...formData, [name]: value });
  };

  return (
    <>
      <Drawer
        title={translate("New Ticket Form")}
        placement="right"
        onClose={() => setNewDrawer(false)}
        open={isNewDrawer}
        width={"40%"}
      >
        <>
          <div className="question__form c_ticket">
            <div className="question__fieldset">
              <SelectWithOptions
                label={translate("Select a topic")}
                options={[
                  {
                    value: "Account and Payment",
                    text: translate("Account and Payment"),
                  },
                  { value: "Bug / Issue", text: translate("Bug / Issue") },
                  {
                    value: "Feature Request",
                    text: translate("Feature Request"),
                  },
                  { value: "Other", text: translate("Other") },
                ]}
                onChange={onChange}
                value={formData.topic}
                name="topic"
                customClass="mt_40"
              />
              <SelectWithOptions
                label={translate("Priority level")}
                options={[
                  { value: 1, text: translate("Low") },
                  { value: 2, text: translate("Medium") },
                  { value: 3, text: translate("High") },
                  { value: 4, text: translate("Urgent") },
                ]}
                onChange={onChange}
                value={formData.priority}
                name="priority"
                customClass="mt_40"
              />
              <Input
                id="subject"
                name="subject"
                label={translate("Subject")}
                type="text"
                onChange={onChange}
                value={formData.subject}
                placeholder={translate("Subject")}
                customClass="mt_40"
              />

              <Textarea
                name="description"
                label={translate("Message")}
                value={formData.message}
                onChange={onChange}
                placeholder={translate("Say something please...")}
                customClass="mt_40"
              />
            </div>
            <div className="question__btns">
              <button
                className="button-small question__button app"
                onClick={createTicket}
              >
                {translate("Send message")}
                {isBtnLoading && <span className="btn-spinner"></span>}
              </button>
            </div>
          </div>
        </>
      </Drawer>
    </>
  );
};

export default NewTicketDrawer;
