import React from "react";

// https://www.svgrepo.com/collection/generica-tiny-interface-icons/2

// https://www.svgrepo.com/collection/iconsax-line-oval-icons/

// https://www.svgrepo.com/collection/solar-broken-line-icons/

export const SVGAdmin = () => {
  return (
    <svg
      className="icont n__icn icon-home-n"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12 15L12 18" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M22 12.2039V13.725C22 17.6258 22 19.5763 20.8284 20.7881C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.7881C2 19.5763 2 17.6258 2 13.725V12.2039C2 9.91549 2 8.77128 2.5192 7.82274C3.0384 6.87421 3.98695 6.28551 5.88403 5.10813L7.88403 3.86687C9.88939 2.62229 10.8921 2 12 2C13.1079 2 14.1106 2.62229 16.116 3.86687L18.116 5.10812C20.0131 6.28551 20.9616 6.87421 21.4808 7.82274"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const SVGBalance = () => {
  return (
    <svg
      className="icont n__icn icon-home-n"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 8H10"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 10.5C22 10.4226 22 9.96726 21.9977 9.9346C21.9623 9.43384 21.5328 9.03496 20.9935 9.00214C20.9583 9 20.9167 9 20.8333 9H18.2308C16.4465 9 15 10.3431 15 12C15 13.6569 16.4465 15 18.2308 15H20.8333C20.9167 15 20.9583 15 20.9935 14.9979C21.5328 14.965 21.9623 14.5662 21.9977 14.0654C22 14.0327 22 13.5774 22 13.5"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="18" cy="12" r="1" />
      <path
        d="M13 4C16.7712 4 18.6569 4 19.8284 5.17157C20.6366 5.97975 20.8873 7.1277 20.965 9M10 20H13C16.7712 20 18.6569 20 19.8284 18.8284C20.6366 18.0203 20.8873 16.8723 20.965 15M9 4.00093C5.8857 4.01004 4.23467 4.10848 3.17157 5.17157C2 6.34315 2 8.22876 2 12C2 15.7712 2 17.6569 3.17157 18.8284C3.82475 19.4816 4.69989 19.7706 6 19.8985"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const SVGTiers = () => {
  return (
    <svg
      className="icont n__icn icon-home-n"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.5 14L17 14" strokeWidth="2" strokeLinecap="round" />
      <path d="M7 14H7.5" strokeWidth="2" strokeLinecap="round" />
      <path d="M7 10.5H7.5" strokeWidth="2" strokeLinecap="round" />
      <path d="M7 17.5H7.5" strokeWidth="2" strokeLinecap="round" />
      <path d="M10.5 10.5H17" strokeWidth="2" strokeLinecap="round" />
      <path d="M10.5 17.5H17" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M8 3.5C8 2.67157 8.67157 2 9.5 2H14.5C15.3284 2 16 2.67157 16 3.5V4.5C16 5.32843 15.3284 6 14.5 6H9.5C8.67157 6 8 5.32843 8 4.5V3.5Z"
        stroke="#1C274C"
        strokeWidth="2"
      />
      <path
        d="M21 16.0002C21 18.8286 21 20.2429 20.1213 21.1215C19.2426 22.0002 17.8284 22.0002 15 22.0002H9C6.17157 22.0002 4.75736 22.0002 3.87868 21.1215C3 20.2429 3 18.8286 3 16.0002V13.0002M16 4.00195C18.175 4.01406 19.3529 4.11051 20.1213 4.87889C21 5.75757 21 7.17179 21 10.0002V12.0002M8 4.00195C5.82497 4.01406 4.64706 4.11051 3.87868 4.87889C3.11032 5.64725 3.01385 6.82511 3.00174 9"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const SVGUsers = () => {
  return (
    <svg
      className="icont n__icn icon-home-n"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9" cy="6" r="4" strokeWidth="2" />
      <path
        d="M15 9C16.6569 9 18 7.65685 18 6C18 4.34315 16.6569 3 15 3"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M5.88915 20.5843C6.82627 20.8504 7.88256 21 9 21C12.866 21 16 19.2091 16 17C16 14.7909 12.866 13 9 13C5.13401 13 2 14.7909 2 17C2 17.3453 2.07657 17.6804 2.22053 18"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M18 14C19.7542 14.3847 21 15.3589 21 16.5C21 17.5293 19.9863 18.4229 18.5 18.8704"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const SVGSellers = () => {
  return (
    <svg
      className="icont n__icn icon-home-n"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 21.5V18.5C9.5 17.5654 9.5 17.0981 9.70096 16.75C9.83261 16.522 10.022 16.3326 10.25 16.201C10.5981 16 11.0654 16 12 16C12.9346 16 13.4019 16 13.75 16.201C13.978 16.3326 14.1674 16.522 14.299 16.75C14.5 17.0981 14.5 17.5654 14.5 18.5V21.5"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M21 22H9M3 22H5.5" strokeWidth="2" strokeLinecap="round" />
      <path d="M19 22V15" strokeWidth="2" strokeLinecap="round" />
      <path d="M5 22V15" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M11.9999 2H7.47214C6.26932 2 5.66791 2 5.18461 2.2987C4.7013 2.5974 4.43234 3.13531 3.89443 4.21114L2.49081 7.75929C2.16652 8.57905 1.88279 9.54525 2.42867 10.2375C2.79489 10.7019 3.36257 11 3.99991 11C5.10448 11 5.99991 10.1046 5.99991 9C5.99991 10.1046 6.89534 11 7.99991 11C9.10448 11 9.99991 10.1046 9.99991 9C9.99991 10.1046 10.8953 11 11.9999 11C13.1045 11 13.9999 10.1046 13.9999 9C13.9999 10.1046 14.8953 11 15.9999 11C17.1045 11 17.9999 10.1046 17.9999 9C17.9999 10.1046 18.8953 11 19.9999 11C20.6373 11 21.205 10.7019 21.5712 10.2375C22.1171 9.54525 21.8334 8.57905 21.5091 7.75929L20.1055 4.21114C19.5676 3.13531 19.2986 2.5974 18.8153 2.2987C18.332 2 17.7306 2 16.5278 2H16"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SVGAsins = () => {
  return (
    <svg
      className="icont n__icn icon-home-n"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 12H9M16 12H12" strokeWidth="2" strokeLinecap="round" />
      <path d="M16 8H15M12 8H8" strokeWidth="2" strokeLinecap="round" />
      <path d="M8 16H13" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M3 14V10C3 6.22876 3 4.34315 4.17157 3.17157C5.34315 2 7.22876 2 11 2H13C16.7712 2 18.6569 2 19.8284 3.17157C20.4816 3.82476 20.7706 4.69989 20.8985 6M21 10V14C21 17.7712 21 19.6569 19.8284 20.8284C18.6569 22 16.7712 22 13 22H11C7.22876 22 5.34315 22 4.17157 20.8284C3.51839 20.1752 3.22937 19.3001 3.10149 18"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const SVGAffiliates = () => {
  return (
    <svg
      className="icont n__icn icon-home-n"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 17C9.85038 16.3697 10.8846 16 12 16C13.1154 16 14.1496 16.3697 15 17"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <ellipse cx="15" cy="10.5" rx="1" ry="1.5" fill="#1C274C" />
      <ellipse cx="9" cy="10.5" rx="1" ry="1.5" fill="#1C274C" />
      <path
        d="M22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C21.5093 4.43821 21.8356 5.80655 21.9449 8"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const SVGSupport = () => {
  return (
    <svg
      className="icont n__icn icon-home-n"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="4" strokeWidth="2" />
      <path d="M15 9L19 5" strokeWidth="2" />
      <path d="M5 19L9 15" strokeWidth="2" />
      <path d="M9 9L5 5" strokeWidth="2" />
      <path d="M19 19L15 15" strokeWidth="2" />
      <path
        d="M9.41235 2.33892C11.0533 1.89775 12.8289 1.86936 14.5882 2.34078C19.9229 3.7702 23.0887 9.25357 21.6593 14.5882C20.2299 19.9229 14.7465 23.0887 9.41185 21.6593C4.07719 20.2299 0.911364 14.7465 2.34078 9.41185C2.8122 7.65248 3.72457 6.12901 4.92711 4.92847"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const SVGReports = () => {
  return (
    <svg
      className="icont n__icn icon-home-n"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="4" strokeWidth="2" />
      <circle cx="19" cy="5" r="3" strokeWidth="2" />
      <path d="M7 14H16" strokeWidth="2" strokeLinecap="round" />
      <path d="M7 17.5H13" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M2 12C2 16.714 2 19.0711 3.46447 20.5355C4.92893 22 7.28595 22 12 22C16.714 22 19.0711 22 20.5355 20.5355C22 19.0711 22 16.714 22 12V10.5M13.5 2H12C7.28595 2 4.92893 2 3.46447 3.46447C2.49073 4.43821 2.16444 5.80655 2.0551 8"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const SVGEmail = () => {
  return (
    <svg
      className="icont n__icn icon-home-n"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 12C2 15.7712 2 17.6569 3.17157 18.8284C4.34315 20 6.22876 20 10 20H14C17.7712 20 19.6569 20 20.8284 18.8284C22 17.6569 22 15.7712 22 12C22 11.0542 22.0185 10.7271 22 10M13 4H10C6.22876 4 4.34315 4 3.17157 5.17157C2.51839 5.82475 2.22937 6.69989 2.10149 8"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M6 8L7.66438 9.38699M15.8411 9.79908C14.0045 11.3296 13.0861 12.0949 12 12.0949C11.3507 12.0949 10.7614 11.8214 10 11.2744"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="19" cy="5" r="3" strokeWidth="2" />
    </svg>
  );
};

export const SVGTickets = () => {
  return (
    <svg
      className="icont n__icn icon-home-n"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 18L10.29 20.29C10.514 20.5156 10.7804 20.6946 11.0739 20.8168C11.3674 20.9389 11.6821 21.0018 12 21.0018C12.3179 21.0018 12.6326 20.9389 12.9261 20.8168C13.2196 20.6946 13.486 20.5156 13.71 20.29L16 18H18C19.0609 18 20.0783 17.5786 20.8284 16.8285C21.5786 16.0783 22 15.0609 22 14V7C22 5.93913 21.5786 4.92178 20.8284 4.17163C20.0783 3.42149 19.0609 3 18 3H6C4.93913 3 3.92172 3.42149 3.17157 4.17163C2.42142 4.92178 2 5.93913 2 7V14C2 15.0609 2.42142 16.0783 3.17157 16.8285C3.92172 17.5786 4.93913 18 6 18H8Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 9H7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 12H7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SVGTickets2 = () => {
  return (
    <svg
      className="iconx vv"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 18L10.29 20.29C10.514 20.5156 10.7804 20.6946 11.0739 20.8168C11.3674 20.9389 11.6821 21.0018 12 21.0018C12.3179 21.0018 12.6326 20.9389 12.9261 20.8168C13.2196 20.6946 13.486 20.5156 13.71 20.29L16 18H18C19.0609 18 20.0783 17.5786 20.8284 16.8285C21.5786 16.0783 22 15.0609 22 14V7C22 5.93913 21.5786 4.92178 20.8284 4.17163C20.0783 3.42149 19.0609 3 18 3H6C4.93913 3 3.92172 3.42149 3.17157 4.17163C2.42142 4.92178 2 5.93913 2 7V14C2 15.0609 2.42142 16.0783 3.17157 16.8285C3.92172 17.5786 4.93913 18 6 18H8Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 9H7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 12H7"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SVGAsin360 = () => {
  return (
    <svg
      className="icont n__icn icon-home-n"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.92 2.25997L19.43 5.76997C20.19 6.17997 20.19 7.34997 19.43 7.75997L12.92 11.27C12.34 11.58 11.66 11.58 11.08 11.27L4.57 7.75997C3.81 7.34997 3.81 6.17997 4.57 5.76997L11.08 2.25997C11.66 1.94997 12.34 1.94997 12.92 2.25997Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.61 10.13L9.66 13.16C10.41 13.54 10.89 14.31 10.89 15.15V20.8701C10.89 21.7001 10.02 22.2301 9.28 21.8601L3.23 18.83C2.48 18.45 2 17.68 2 16.84V11.12C2 10.29 2.87 9.76005 3.61 10.13Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.39 10.13L14.34 13.16C13.59 13.54 13.11 14.31 13.11 15.15V20.8701C13.11 21.7001 13.98 22.2301 14.72 21.8601L20.77 18.83C21.52 18.45 22 17.68 22 16.84V11.12C22 10.29 21.13 9.76005 20.39 10.13Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const SVGHistory = () => {
  return (
    <svg
      className="icont n__icn icon-home-n"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.25 2C11.25 1.58579 11.5858 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12C1.25 11.5858 1.58579 11.25 2 11.25C2.41421 11.25 2.75 11.5858 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75C11.5858 2.75 11.25 2.41421 11.25 2Z"
        fill="#1C274C"
      />
      <path
        opacity="0.5"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.10012 2.39808C9.24928 2.7845 9.05695 3.21868 8.67053 3.36785C8.52909 3.42245 8.38933 3.48042 8.25134 3.54168C7.87275 3.70975 7.4296 3.5391 7.26154 3.16051C7.09347 2.78193 7.26412 2.33878 7.64271 2.17071C7.80321 2.09946 7.9658 2.03201 8.13034 1.96849C8.51677 1.81932 8.95095 2.01166 9.10012 2.39808ZM5.64824 4.2408C5.93391 4.54075 5.92232 5.01549 5.62237 5.30115C5.5127 5.40559 5.40559 5.5127 5.30115 5.62237C5.01549 5.92232 4.54075 5.93391 4.2408 5.64824C3.94085 5.36258 3.92927 4.88785 4.21493 4.5879C4.3362 4.46056 4.46056 4.3362 4.5879 4.21493C4.88785 3.92927 5.36258 3.94085 5.64824 4.2408ZM3.16051 7.26154C3.5391 7.42961 3.70975 7.87276 3.54168 8.25134C3.48042 8.38933 3.42245 8.52909 3.36785 8.67053C3.21868 9.05695 2.7845 9.24928 2.39808 9.10012C2.01166 8.95095 1.81932 8.51677 1.96849 8.13035C2.03201 7.9658 2.09946 7.80321 2.17071 7.64271C2.33878 7.26412 2.78193 7.09347 3.16051 7.26154Z"
        fill="#1C274C"
      />
      <path
        opacity="0.5"
        d="M12 8.25C12.4142 8.25 12.75 8.58579 12.75 9V12.25H16C16.4142 12.25 16.75 12.5858 16.75 13C16.75 13.4142 16.4142 13.75 16 13.75H12C11.5858 13.75 11.25 13.4142 11.25 13V9C11.25 8.58579 11.5858 8.25 12 8.25Z"
        fill="#1C274C"
      />
    </svg>
  );
};
export const SVGKeywordTool = () => {
  return (
    <svg
      className="icont n__icn icon-home-n"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.28 13.61C15.15 14.74 13.53 15.09 12.1 14.64L9.51001 17.22C9.33001 17.41 8.96001 17.53 8.69001 17.49L7.49001 17.33C7.09001 17.28 6.73001 16.9 6.67001 16.51L6.51001 15.31C6.47001 15.05 6.60001 14.68 6.78001 14.49L9.36001 11.91C8.92001 10.48 9.26001 8.86001 10.39 7.73001C12.01 6.11001 14.65 6.11001 16.28 7.73001C17.9 9.34001 17.9 11.98 16.28 13.61Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.45 16.28L9.59998 15.42"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3945 10.7H13.4035"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export const SVGBarcode = () => {
  return (
    <svg
      className="icont n__icn icon-home-n"
      viewBox="-0.5 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.99023 8.56V16.56C1.99023 17.6209 2.41166 18.6383 3.1618 19.3884C3.91195 20.1386 4.92937 20.56 5.99023 20.56H17.9902C19.0511 20.56 20.0685 20.1386 20.8187 19.3884C21.5688 18.6383 21.9902 17.6209 21.9902 16.56V8.56C21.9902 7.49913 21.5688 6.48171 20.8187 5.73157C20.0685 4.98142 19.0511 4.56 17.9902 4.56H5.99023C4.92937 4.56 3.91195 4.98142 3.1618 5.73157C2.41166 6.48171 1.99023 7.49913 1.99023 8.56Z"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.99023 8.56V16.56"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.99023 12.56V16.56"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.99023 8.56V9.56"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.9902 8.56V16.56"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 16.5V16.56"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 8.56V13.56"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.9902 13.56V16.56"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.9902 8.56V10.56"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
