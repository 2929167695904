import React, { useRef, useEffect } from "react";
import translate from "../utils/translations";

export default function OutsideAlerter(props) {
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          //   alert("You clicked outside of me!");
          props.setMenu && props.setMenu(false);
          props.setLanguage && props.setLanguage(false);
          props.setNotificationPopupVisible &&
            props.setNotificationPopupVisible(false);
          props.setUser && props.setUser(false);
          props.setMore && props.setMore(false);
          props.setSheetsDrop && props.setSheetsDrop(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return <div ref={wrapperRef}>{props.children}</div>;
}
