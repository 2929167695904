import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import TicketsTable from "./_tickets_table";
import TicketsDrawer from "./_tickets_drawer";
import NewTicketDrawer from "./_new_ticket_drawer";
import { requestHandler } from "../../../shared/utils/helpers";
import { useNavigate } from "react-router-dom";
import translate from "../../../shared/utils/translations";
import { Helmet } from "react-helmet";

const Tickets = ({ setPage }) => {
  const navigate = useNavigate();
  useEffect(() => {
    setPage("dashboard-client");
    localStorage.setItem("dashboard-type", "dashboard-client");
  });

  const [isItem, setItem] = useState("All");
  const [isDrawer, setDrawer] = useState(false);
  const [isNewDrawer, setNewDrawer] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [buffer, setBuffer] = useState([]); //for search
  const [selectedTicket, setSelectedTicket] = useState({});
  const [query, setQuery] = useState(""); //for search
  const getTickets = async () => {
    const ticketsData = await requestHandler("get", `/tickets`, null, true, navigate, "/login").then((res) => res?.data);
    setTickets(ticketsData);
    setBuffer(ticketsData);
  };

  useEffect(() => {
    getTickets();
  }, []);

  useEffect(() => {
    if (isItem === "All") {
      setBuffer(tickets);
    } else if (isItem === "open") {
      setBuffer(tickets.filter((ticket) => ticket.status === "open"));
    } else if (isItem === "closed") {
      setBuffer(tickets.filter((ticket) => ticket.status === "closed"));
    }
  }, [isItem]);

  useEffect(() => {
    console.log("selectedTicket", selectedTicket);
  }, [selectedTicket]);

  useEffect(() => {
    console.log("buffer", buffer);
    //filter if ticket has query in subject or description
    if (query) {
      setBuffer(
        tickets.filter(
          (ticket) =>
            ticket?.subject?.toLowerCase()?.includes(query?.toLowerCase()) ||
            ticket?.description?.toLowerCase()?.includes(query?.toLowerCase())
        )
      );
    }
  }, [query]);
  useEffect(() => {
    // setSelectedTicket();
  }, [isDrawer]);
  return (
    <>
    <Helmet>
      <title>{translate("Tickets")}</title>
    </Helmet>
      <TicketsDrawer isDrawer={isDrawer} setDrawer={setDrawer} selectedTicket={selectedTicket} />
      <NewTicketDrawer isNewDrawer={isNewDrawer} setNewDrawer={setNewDrawer} refresh={getTickets} />

      <div className="outer__inner">
        <div className="onex_wl bg_white">
          <div className="onex_wl__wrapper">
            <div className="upload__head">
              <h2 className="upload__title h2">{translate("Tickets")}</h2>
              {/* <a
                    className="button-stroke button-small upload__button"
                    href="#!"
                  >
                    More filter
                  </a> */}
            </div>
            <div className="onex_wl__list">
              <div className="keys__enabled d_block">
                <div className="keys__info">
                  {translate("Have a question - how can we help you? Get expert help using Kepicker, any time you need it.")}
                </div>
                <div className="keys__list">
                  <div className="keys__item ss">
                    <div className="activity__top ss">
                      <div className="nav">
                        <button
                          className={`nav__link cursorp ${isItem === "All" ? " active" : " "}`}
                          onClick={() => setItem("All")}
                        >
                          {translate("All")}
                        </button>
                        <button
                          className={`nav__link cursorp ${isItem === "open" ? " active" : " "}`}
                          onClick={() => setItem("open")}
                        >
                          {translate("Active")}
                        </button>
                        <button
                          className={`nav__link cursorp ${isItem === "closed" ? " active" : " "}`}
                          onClick={() => setItem("closed")}
                        >
                          {translate("Done")}
                        </button>
                      </div>

                      <div className="right_items">
                        <div className="activity__form">
                          <input
                            className="activity__input"
                            type="text"
                            name="search"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            placeholder={translate("Search")}
                            required=""
                          />
                          <span className="activity__result">
                            <svg className="icon icon-search">
                              <use xlinkHref="#icon-search"></use>
                            </svg>
                          </span>
                        </div>
                        <div className="activity__item activity__item_calendar js-activity-item ">
                          <button
                            className="button-stroke button-small activity__button js-activity-button"
                            onClick={() => setNewDrawer(true)}
                          >
                            <span>{translate("New Ticket")}</span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <TicketsTable isDrawer={isDrawer} setDrawer={setDrawer} data={buffer} setSelectedTicket={setSelectedTicket} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tickets;
