import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import ProductsTable from "./_products_table";
import FeatherIcon from "feather-icons-react";
import { requestHandler } from "../../../shared/utils/helpers";
import translate from "../../../shared/utils/translations";

import Swal from "sweetalert2";

const KeywordTool = ({ setPage }) => {
  useEffect(() => {
    setPage("dashboard-client");
    localStorage.setItem("dashboard-type", "dashboard-client");
  });

  const [isItem, setItem] = useState("All");

  const data = [
    {
      key: 1,
      image_url:
        "https://m.media-amazon.com/images/I/81+oIotFGqL._AC_SX679_.jpg",
      asin: "B08RD4DS3J",
      title: "Foot Massager Machine",
      sales_rank: 83166,
      sales_rank_category: "Health And Beauty",
      brand: "ROTAI",
    },
    {
      key: 2,
      image_url:
        "https://m.media-amazon.com/images/I/81+oIotFGqL._AC_SX679_.jpg",
      asin: "B08RD4DS3J",
      title: "Foot Massager Machine",
      sales_rank: 83166,
      sales_rank_category: "Health And Beauty",
      brand: "ROTAI",
    },
    {
      key: 3,
      image_url:
        "https://m.media-amazon.com/images/I/81+oIotFGqL._AC_SX679_.jpg",
      asin: "B08RD4DS3J",
      title: "Foot Massager Machine",
      sales_rank: 83166,
      sales_rank_category: "Health And Beauty",
      brand: "ROTAI",
    },
    {
      key: 4,
      image_url:
        "https://m.media-amazon.com/images/I/81+oIotFGqL._AC_SX679_.jpg",
      asin: "B08RD4DS3J",
      title: "Foot Massager Machine",
      sales_rank: 83166,
      sales_rank_category: "Health And Beauty",
      brand: "ROTAI",
    },
    {
      key: 5,
      image_url:
        "https://m.media-amazon.com/images/I/81+oIotFGqL._AC_SX679_.jpg",
      asin: "B08RD4DS3J",
      title: "Foot Massager Machine",
      sales_rank: 83166,
      sales_rank_category: "Health And Beauty",
      brand: "ROTAI",
    },
  ];

  const [isStarted, setStarted] = useState(false);
  const [isStopped, setStopped] = useState(false);
  const [marketplace, setMarketplace] = useState("");
  const [keyword, setKeyword] = useState("");
  const startSearch = () => {
    if (!keyword || !marketplace) {
      toast.error(
        translate("Please enter a keyword and select a marketplace!")
      );
      return;
    }

    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Start search?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setStarted(true);
        toast.success(translate("Search process started!"));
      }
    });
  };

  const stopSearch = () => {
    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Stop search?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setStopped(true);
        toast.success(translate("Searching has been stopped!"));
      }
    });
  };

  const cancelSearch = () => {
    Swal.fire({
      icon: "warning",
      title: translate("Cancel search?"),
      text: translate("Will everything be cancelled? Are you sure?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setStarted(false);
        setStopped(false);
        toast.success(translate("All search canceled!"));
      }
    });
  };

  return (
    <>
      <div className="outer__inner new__page">
        <div className="onex_wl bg_white">
          <div className="onex_wl__wrapper">
            <div className="upload__head hhg">
              <div className="ttle_c">
                <h2 className="upload__title h2">
                  {translate("Keyword Tool")}
                </h2>
                <div className="keys__info">
                  {translate(
                    "Search for products in Amazon stores by entering keywords; filter, save, compare.."
                  )}
                </div>
              </div>
              {/* <div className="comparsion__hdr">
                <div className="cmp_lft">
                  <div className="cmp_inf">
                    <h5>Total Saved Asin List</h5>
                    <span>4</span>
                  </div>
                  <div className="cmp_inf">
                    <h5>Total All Asins</h5>
                    <span>365</span>
                  </div>
                </div>
              </div>
              <a
                className="button-stroke button-small upload__button"
                href="#!"
              >
                More filter
              </a> */}
              {/* <button className="button-stroke button-small activity__button js-activity-button">
                <span>
                  <FeatherIcon icon={"download"} /> {translate("Download")}
                </span>
              </button> */}
            </div>
            <div className="onex_wl__list">
              <div className="keys__enabled d_block">
                <div className="keys__list">
                  <div className="keys__item ss">
                    <div className="activity__top ss">
                      {!isStarted ? (
                        <div className="search__keywrd_Area">
                          <div className="onex_ts__select">
                            <div className="select__field">
                              <select
                                className="select nice-select  app d_block"
                                value={marketplace}
                                onChange={(e) => setMarketplace(e.target.value)}
                              >
                                <option value="">
                                  {translate("Select marketplace")}
                                </option>
                                <option value="amazon.com">amazon.com</option>
                                <option value="amazon.ca">amazon.ca</option>
                                <option value="amazon.co.uk">
                                  amazon.co.uk
                                </option>
                              </select>
                            </div>
                          </div>
                          <form
                            className="activity__form"
                            onSubmit={(e) => e.preventDefault()}
                          >
                            <input
                              className="activity__input"
                              type="text"
                              name="search"
                              placeholder={translate("Keyword")}
                              required=""
                              value={keyword}
                              onChange={(e) => setKeyword(e.target.value)}
                            />
                            <button className="activity__result">
                              <svg className="icon icon-search">
                                <use xlinkHref="#icon-search"></use>
                              </svg>
                            </button>
                          </form>
                          <button
                            onClick={startSearch}
                            className="button button-small activity__button js-activity-button"
                          >
                            <span>{translate("Start")}</span>
                          </button>
                        </div>
                      ) : (
                        <div className="search__keywrd_Area PRCCSS">
                          <div className="st__lftt">
                            <div className="stts__kp">
                              {/* started - done */}
                              <div
                                className={`circle-loader__kpp ${
                                  isStopped && " done"
                                }`}
                              >
                                <div className="checkmark__kpp draw__kpp"></div>
                              </div>
                              <h5>
                                {!isStopped
                                  ? translate("In Progress")
                                  : translate("Done")}
                              </h5>
                            </div>

                            <div className="res__actionsk">
                              {!isStopped ? (
                                <button
                                  onClick={stopSearch}
                                  className="pbi__btnn rest"
                                >
                                  {translate("Stop")}
                                </button>
                              ) : (
                                <button
                                  onClick={cancelSearch}
                                  className="pbi__btnn rest"
                                >
                                  {translate("Cancel All Search!")}
                                </button>
                              )}
                            </div>
                          </div>

                          <div className="res__inffoss">
                            <div className="cmp_lft">
                              <div className="cmp_inf">
                                <h5>{translate("Total Number of Products")}</h5>
                                <span>224</span>
                              </div>
                            </div>
                          </div>

                          <div className="filter__slsr">
                            <div className="activity__form">
                              <label>{translate("Sales rank filter")}</label>
                              <div className="inptss_flktr">
                                <input
                                  className="activity__input"
                                  type="number"
                                  name="min"
                                  placeholder={translate("Min")}
                                  required=""
                                />
                                <input
                                  className="activity__input"
                                  type="number"
                                  name="max"
                                  placeholder={translate("Max")}
                                  required=""
                                />
                              </div>
                            </div>
                            <button className="button button-small activity__button js-activity-button">
                              <span>{translate("Apply")}</span>
                            </button>
                          </div>
                        </div>
                      )}

                      <div className="right_items">
                        <div className="activity__item activity__item_calendar js-activity-item ">
                          <button className="button-stroke button-small activity__button js-activity-button">
                            <span>
                              <FeatherIcon icon={"download"} />{" "}
                              {translate("Download")}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <ProductsTable data={data} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default KeywordTool;
