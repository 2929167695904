import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Table, Tag, Button } from "antd";
import axios from "axios";
import translate from "../../../shared/utils/translations";

const UsersTable = ({
  setNewDrawer,
  setDrawer,
  setUserDetailsDrawer,
  data,
  deleteUser,
  setSelectedUser,
  toggleTrusted,
}) => {
  let navigate = useNavigate();
  const [isBtnLoading, setBtnLoading] = useState(false);
  const submit = () => {
    setBtnLoading(true);
    setTimeout(() => {
      notify();
      setBtnLoading(false);
      setNewDrawer(false);
    }, 1200);
  };
  const notify = () => toast.success(translate("User deleted successfully!.."));

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (key) => "##" + key,
    },
    {
      title: "Referencer",
      dataIndex: "Reference",
      key: "Reference",
      render: (item, record) => (
        <>
          <div className="column_info">{"Reference"}</div>
          {record?.referencer_email
            ? record?.referencer_first_name?.capitalize() +
              " " +
              record?.referencer_last_name?.capitalize()
            : "No Reference"}
        </>
      ),
    },
    {
      title: translate("Tier"),
      dataIndex: "tier_name",
      key: "tier_name",
      render: (item) => (
        <>
          <div className="column_info">{translate("Tier")}</div>
          {item?.capitalize()}
        </>
      ),
    },
    {
      title: translate("Name"),
      render: (item, record) => (
        <>
          <div className="column_info">{translate("Name")}</div>
          {item?.first_name?.capitalize() + " " + item?.last_name?.capitalize()}
        </>
      ),
    },
    {
      title: translate("Email"),
      dataIndex: "email",
      key: "email",
      render: (item) => (
        <>
          <div className="column_info">{translate("Email")}</div>
          {item}
        </>
      ),
    },
    {
      title: "Trusted",
      dataIndex: "is_trusted",
      key: "is_trusted",
      render: (item) => <>{item ? "Trusted" : "Not Trusted"}</>,
    },
    {
      title: "Market Connected",
      render: (item, record) => (
        <>
          <div className="column_info">{"Market Connected"}</div>
          {record?.has_seller_account_eu ||
          record?.has_seller_account_na ||
          record?.has_seller_account_fe
            ? "True"
            : "False"}
        </>
      ),
    },
    {
      title: translate("Token Count"),
      dataIndex: "token_count",
      key: "token_count",
      render: (item) => (
        <>
          <div className="column_info">{translate("Token Count")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Asin Count"),
      dataIndex: "asin_count",
      key: "asin_count",
      render: (item) => (
        <>
          <div className="column_info">{translate("Asin Count")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Phone"),
      dataIndex: "phone_number",
      render: (item) => (
        <>
          <div className="column_info">{translate("Phone")}</div>

          {item}
        </>
      ),
    },

    {
      title: translate("Action"),
      key: "action",
      width: "120px",
      className: "table_actin",
      render: (text, record) => (
        <>
          <div className="column_info">{translate("Details")}</div>
          <div className="d_flex_center">
            <Button
              onClick={() => deleteUser(record.id)}
              icon={<FeatherIcon icon={"trash"} />}
              className={"table_icon"}
            />
            <Button
              onClick={() => {
                setSelectedUser(record);
                console.log("record", record);
                setUserDetailsDrawer(true);
              }}
              icon={<FeatherIcon icon={"info"} />}
              className={"table_icon"}
            />
            <Button
              onClick={() => {
                setSelectedUser(record);
                setNewDrawer(true);
              }}
              icon={<FeatherIcon icon={"edit"} />}
              className={"table_icon"}
            />
            <button
              onClick={() => {
                toggleTrusted(record.id, record.is_trusted);
              }}
              icon={<FeatherIcon icon={"edit"} />}
              className={"table_icon"}
            >
              {record.is_trusted ? "Untrust" : "Trust"}
            </button>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          locale: {
            items_per_page: translate("Page"),
          },
          position: ["top", "bottom"],
        }}
        className="card_m"
      />
    </>
  );
};

export default UsersTable;
