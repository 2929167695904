import React, { useState, useEffect } from "react";

import { NavLink, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Table, Tag, Dropdown, Menu, Button, Tooltip } from "antd";
import EmptyIcon from "../../../../assets/img/empty.png";
import Swal from "sweetalert2";
import translate from "../../../shared/utils/translations";
import toast, { Toaster } from "react-hot-toast";
import { Line, Circle } from "rc-progress";

const ProductsTable = ({ data }) => {
  let navigate = useNavigate();
  const [isTableSort, setTableSort] = useState(() => {
    const savedSort = localStorage.getItem("tableSort2");
    return savedSort !== null ? savedSort : "list";
  });
  useEffect(() => {
    localStorage.setItem("tableSort2", isTableSort);
  }, [isTableSort]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 1;
  const hasDeleted = selectedRowKeys.length > 0;

  const mergeItems = () => {
    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Are you sure about performing this action?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setSelectedRowKeys([]);
        toast.success(translate("Your action was successful!"));
      }
    });
  };

  const deleteItems = () => {
    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Are you sure about performing this action?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setSelectedRowKeys([]);
        toast.success(translate("Your action was successful!"));
      }
    });
  };

  const columns = [
    {
      title: translate("Image"),
      dataIndex: "image_url",
      key: "image_url",
      width: 150,
      minWidth: 100,
      render: (item) => (
        <>
          <div className="column_info">{translate("Image")}</div>
          <img className="table__immg" src={item}></img>
        </>
      ),
    },
    {
      title: "Asin",
      dataIndex: "asin",
      key: "asin",
      width: 150,
      minWidth: 100,
      render: (item) => (
        <>
          <div className="column_info">Asin</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Title"),
      dataIndex: "title",
      key: "title",
      width: 200,
      minWidth: 100,
      render: (item, row) => (
        <>
          <div className="column_info">{translate("Title")}</div>
          <a className="table__lnk" target="_blank" href={`${row.domain}`}>
            {item}
          </a>
        </>
      ),
    },
    {
      title: translate("Sales Rank"),
      dataIndex: "sales_rank",
      key: "sales_rank",
      width: 200,
      minWidth: 100,
      sorter: (a, b) => a.sales_rank - b.sales_rank,
      render: (item) => (
        <>
          <div className="column_info">{translate("Sales Rank")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Sales Rank Category"),
      dataIndex: "sales_rank_category",
      key: "sales_rank_category",
      width: 200,
      minWidth: 100,
      render: (item) => (
        <>
          <div className="column_info">{translate("Sales Rank Category")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Brand"),
      dataIndex: "brand",
      key: "brand",
      width: 200,
      minWidth: 100,
      sorter: (a, b) => a.brand - b.brand,
      render: (item) => (
        <>
          <div className="column_info">{translate("Brand")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Action"),
      key: "action",
      fixed: "right",
      width: 100,
      minWidth: 80,
      className: "table_actin",
      render: (text, record) => (
        <>
          <div className="column_info">{translate("Details")}</div>

          <Tooltip title={translate("Show Product Based Info")} placement="top">
            <NavLink
              className={"pbi__link table_icon"}
              to="/product-based-info"
              target="_blank"
            >
             <FeatherIcon icon={"package"} />
            </NavLink>
          </Tooltip>
        </>
      ),
    },
  ];
  return (
    <>
      <div
        className={`dif__ablee ${isTableSort === "list" ? " " : " block_m"}`}
      >
        <div className="merged___area">
          <div className="table__sortn">
            <span
              onClick={() => setTableSort("list")}
              className={`${isTableSort === "list" ? "active" : ""}`}
            >
              <FeatherIcon icon={"list"} />
            </span>
            <span
              onClick={() => setTableSort("grid")}
              className={`${isTableSort === "grid" ? "active" : ""}`}
            >
              <FeatherIcon icon={"grid"} />
            </span>
          </div>
          <button
            onClick={mergeItems}
            disabled={!hasSelected}
            className="button-stroke button-small activity__button js-activity-button cc link"
          >
            <span>
              <FeatherIcon icon={"archive"} />{" "}
              {translate("Merge & Save to Asin List")}
            </span>
          </button>
          <button
            onClick={deleteItems}
            disabled={!hasDeleted}
            className="button-stroke button-small activity__button js-activity-button cc link"
          >
            <span>
              <FeatherIcon icon={"trash"} /> {translate("Delete")}
            </span>
          </button>
          <span>
            {hasDeleted
              ? `${translate("Selected items - ")} ${selectedRowKeys.length}`
              : ""}
          </span>
        </div>
        <Table
          columns={columns}
          dataSource={data}
          rowSelection={rowSelection}
          pagination={{
            locale: {
              items_per_page: translate("Page"),
            },
            position: ["topRight", "bottomRight"],
          }}
          locale={{
            emptyText: (
              <span className="empty__tble">
                <img src={EmptyIcon} />
              </span>
            ),
          }}
          className="colclose has_pag_btn mob_bts"
        />
      </div>
    </>
  );
};

export default ProductsTable;
