export const setLogged = (e) => (dispatch) => {
  dispatch({
    type: "SET_LOGGED",
    payload: e,
  });
};

export const setDarkMode = (e) => (dispatch) => {
  dispatch({
    type: "SET_DARKMODE",
    payload: e,
  });
};
