import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as settingsActions from "../../hooks/actions";
import translate from "../utils/translations";
import FeatherIcon from "feather-icons-react";

const Footer = ({
  isLogged,
  setLogged,
  setDarkMode,
  isDarkMode,
  setSidebar,
}) => {
  const goTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <footer className="footer">
        <div className="footer__center center">
          <div className="footer__row">
            <div className="footer__col">
              <NavLink
                className="footer__logo"
                to="/"
                onClick={() => setSidebar(false)}
              >
                <img
                  className="some-icon"
                  src="/assets/img/logo-dark4.png"
                  alt=""
                />
                <img
                  className="some-icon-dark"
                  src="/assets/img/logo-dark4.png"
                  alt=""
                />
              </NavLink>
              {/* <div className="footer__theme desk">
                <div>
                  {" "}
                  <svg className="icon icon-bulb">
                    <use xlinkHref="#icon-bulb"></use>
                  </svg>
                  Dark theme
                </div>

                <label className="theme js-theme">
                  <input
                    className="theme__input"
                    type="checkbox"
                    checked={isDarkMode}
                    onChange={() => setDarkMode(!isDarkMode)}
                  />
                  <span className="theme__inner">
                    <span className="theme__box"></span>
                  </span>
                </label>
              </div> */}
              <div className="footer__theme desk">
                <h3>Kepicker LLC</h3>
                <p>
                  {translate(
                    "Compare and filter prices - Increase your revenue with Kepicker."
                  )}
                </p>
              </div>
            </div>
            <div className="footer__col">
              <div className="footer__menu">
                <NavLink
                  className="footer__link"
                  to="/"
                  onClick={() => setSidebar(false)}
                >
                  {translate("Home")}
                </NavLink>
                <NavLink
                  className="footer__link"
                  to="/about"
                  onClick={() => setSidebar(false)}
                >
                  {translate("About us")}
                </NavLink>
                <NavLink
                  className="footer__link"
                  to="/support"
                  onClick={() => setSidebar(false)}
                >
                  {translate("Support")}
                </NavLink>
                <NavLink
                  className="footer__link"
                  to="/blog"
                  onClick={() => setSidebar(false)}
                >
                  Blog
                </NavLink>
                <NavLink
                  className="footer__link"
                  to="/pricing"
                  onClick={() => setSidebar(false)}
                >
                  {translate("Pricing")}
                </NavLink>
                <NavLink
                  className="footer__link"
                  to="/how-it-works"
                  onClick={() => setSidebar(false)}
                >
                  {translate("How It Works")}
                </NavLink>
                <NavLink
                  className="footer__link"
                  to="/terms"
                  onClick={() => setSidebar(false)}
                >
                  {translate("Terms")}
                </NavLink>
                <NavLink
                  className="footer__link"
                  to="/privacy-policy"
                  onClick={() => setSidebar(false)}
                >
                  {translate("Privacy")}
                </NavLink>
              </div>
            </div>
            <div className="footer__col mbc">
              <a
                className="vrfy__link"
                target="_blank"
                href="https://sellercentral.amazon.com.tr/selling-partner-appstore/dp/amzn1.sp.solution.d1a57a0a-e0d5-404f-b399-9598caa8841b?mons_sel_locale=tr_TR"
              >
                <img src="/assets/img/amz_verified.png"></img>
              </a>
              <span className="vrfy__link">
                <img src="/assets/img/stripe.png"></img>
              </span>
              {/* <div className="footer__theme">
                <div>
                  {" "}
                  <svg className="icon icon-bulb">
                    <use xlinkHref="#icon-bulb"></use>
                  </svg>
                  Dark theme
                </div>

                <label className="theme js-theme">
                  <input
                    className="theme__input"
                    type="checkbox"
                    // checked={isDarkMode}
                    // onChange={() => setDarkMode(!isDarkMode)}
                  />
                  <span className="theme__inner">
                    <span className="theme__box"></span>
                  </span>
                </label>
              </div> */}
            </div>
          </div>
          <div className="footer__bottom">
            <div className="footer__copyright">
              {translate("Copyright © Kepicker LLC. All rights reserved")} -{" "}
              <span>
                powered by{" "}
                <a href="https://onexyazilim.com/" target="_blank">
                  Onex Software
                </a>
              </span>
            </div>

            <div className="footer__socials">
              <ul className="social-icon social-default justify-content-start">
                <li>
                  <a
                    href="https://www.facebook.com/kepicker.software/"
                    target="_blank"
                  >
                    <FeatherIcon icon={"facebook"} />
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/kepicker_" target="_blank">
                    <FeatherIcon icon={"twitter"} />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/kepicker" target="_blank">
                    <FeatherIcon icon={"instagram"} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/kepicker/"
                    target="_blank"
                  >
                    <FeatherIcon icon={"linkedin"} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLogged: state.settings.isLogged,
  isDarkMode: state.settings.isDarkMode,
});

const mapDispatchToProps = (dispatch) => ({
  setLogged: (e) => dispatch(settingsActions.setLogged(e)),
  setDarkMode: (e) => dispatch(settingsActions.setDarkMode(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
