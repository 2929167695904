import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Table, Tag, Dropdown, Menu, Button } from "antd";
import translate from "../../../shared/utils/translations";

const DiscoveryTable = ({
  data,
  setSelectedAffiliate,
  setDrawer,
  deleteAffiliate,
}) => {
  let navigate = useNavigate();

  const columns = [
    {
      title: translate("User"),
      render: (item, record) => (
        <>
          <div className="column_info">{translate("User")}</div>
          {record?.first_name} {record?.last_name}
        </>
      ),
    },

    {
      title: translate("Affiliate Code"),
      dataIndex: "affiliate_code",
      key: "affiliate_code",
      render: (item) => (
        <>
          <div className="column_info">{translate("Affiliate Code")}</div>
          {item}
        </>
      ),
    },

    {
      title: translate("Affiliate Type"),
      dataIndex: "token_based",
      key: "token_based",
      render: (item) => (
        <>
          <div className="column_info">{translate("Affiliate Type")}</div>
          {item ? translate("Token") : translate("Percentage")}
        </>
      ),
    },
    {
      title: translate("Token Amount"),
      dataIndex: "comparison_token_amount",
      key: "comparison_token_amount",
      render: (item) => (
        <>
          <div className="column_info">{translate("Token Amount")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Commission Rate"),
      dataIndex: "commision_rate",
      key: "commision_rate",
      render: (item) => (
        <>
          <div className="column_info">{translate("Commission Rate")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Add Token on Register"),
      dataIndex: "add_token_on_register",
      key: "add_token_on_register",
      render: (item) => (
        <>
          <div className="column_info">{translate("Add Token on Register")}</div>
          <Tag className={item ? "default__tag" : "error__tag"}>
          {item ? translate("Yes") : translate("No")}
          </Tag>
          
        </>
      ),
    },
    {
      title: translate("Limit"),
      dataIndex: "limit_count",
      key: "limit_count",
      render: (item) => (
        <>
          <div className="column_info">{translate("Limit")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Limit Used"),
      dataIndex: "limit_used",
      key: "limit_used",
      render: (item) => (
        <>
          <div className="column_info">{translate("Limit Used")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Action"),
      key: "action",
      className: "table_actin",
      render: (text, record) => (
        <>
          <div className="column_info">{translate("Details")}</div>
          <div className="d_flex_center bb">
            {record?.limit_used > 0 ? (
              <></>
            ) : (
              <Button
                onClick={() => deleteAffiliate(record.id)}
                icon={<FeatherIcon icon={"trash"} />}
                className={"table_icon"}
              />
            )}

            <Button
              onClick={() => {
                setSelectedAffiliate(record);
                setDrawer(true);
              }}
              icon={<FeatherIcon icon={"edit"} />}
              className={"table_icon"}
            />
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          locale: {
            items_per_page: translate("Page"),
          },
          position: ["top", "bottom"],
        }}
        className="card_m"
      />
    </>
  );
};

export default DiscoveryTable;
