import React, { useEffect, useState } from "react";
import SellersTable from "./sellers_table";
import Input from "../../../common/form_elements/input";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { requestHandler } from "../../../shared/utils/helpers";
import translate from "../../../shared/utils/translations";
import Swal from "sweetalert2";

const AsinLists = ({ setPage }) => {
  const navigate = useNavigate();
  useEffect(() => {
    setPage("dashboard-admin");
    localStorage.setItem("dashboard-type", "dashboard-admin");
  });
  const [formData, setFormData] = useState({ update_required: false });
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");
  const [buffer, setBuffer] = useState([]);
  useEffect(() => {
    if (query) {
      const filteredData = data?.filter((item) => {
        return item?.email?.toLowerCase()?.includes(query?.toLowerCase());
      });
      setBuffer(filteredData);
    } else {
      setBuffer(data);
    }
  }, [query]);
  const getSellers = async () => {
    const incomingData = await requestHandler("get", `/admin/sellers`, null, true, navigate, "/login");
    console.log(incomingData);
    setData(incomingData?.data);
    setBuffer(incomingData?.data);
  };

  const addProtection = (id) => {
    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Are you sure about performing this action?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        requestHandler("put", `/admin/seller/${id}/addProtection`, null, true, navigate, "/login")
          .then((res) => {
            console.log("post success", res);
            getSellers();
          })
          .catch((err) => {
            console.log("post error", err);
            setData([]);
          });
      }
    });
  };

  const removeProtection = (id) => {
    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Are you sure about performing this action?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        requestHandler("put", `/admin/seller/${id}/removeProtection`, null, true, navigate, "/login")
          .then((res) => {
            console.log("post success", res);
            getSellers();
          })
          .catch((err) => {
            console.log("post error", err);
            setData([]);
          });
      }
    });
  };

  useEffect(() => {
    getSellers();
  }, []);

  return (
    <>
      <div className="outer__inner">
        <div className="onex_wl bg_white">
          <div className="onex_wl__wrapper">
            <div className="upload__head">
              <h2 className="upload__title h2">{translate("Sellers")}</h2>
              {/* <a
                    className="button-stroke button-small upload__button"
                    href="#!"
                  >
                    More filter
                  </a> */}
            </div>
            <div className="onex_wl__list">
              <div className="keys__enabled d_block">
                <div className="keys__list">
                  <div className="keys__item ss uu">
                    <div className="activity__top ss">
                      <div className="right_items">
                        <form className="activity__form">
                          <input
                            className="activity__input"
                            type="text"
                            name="search"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            placeholder={translate("Search")}
                            required=""
                          />
                          <button className="activity__result">
                            <svg className="icon icon-search">
                              <use xlinkHref="#icon-search"></use>
                            </svg>
                          </button>
                        </form>
                      </div>
                      {/* <div className="activity__item activity__item_calendar js-activity-item">
                        <p className="js-activity-item-label">{translate("Update ASIN lists status:")}</p>
                        <div className="big___btnss">
                          <button
                            onClick={() => waitAll()}
                            className="button-stroke button-small activity__button js-activity-button ellipsis_That"
                          >
                            <span>{translate("Processing to Waiting")}</span>
                          </button>
                          <button
                            onClick={() => startAll()}
                            className="button-stroke button-small activity__button js-activity-button ellipsis_That ml-35"
                          >
                            <span>{translate("Waiting to Processing")}</span>
                          </button>
                        </div>
                      </div> */}

                      <div className="right_items"></div>
                    </div>

                    <SellersTable data={buffer} addProtection={addProtection} removeProtection={removeProtection} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AsinLists;
