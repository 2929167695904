import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { Table, Tag, Dropdown, Menu, Button, Tooltip } from "antd";
import translate from "../../../shared/utils/translations";
import EmptyIcon from "../../../../assets/img/empty.png"

const TicketsTable = ({ isDrawer, setDrawer, data, setSelectedTicket }) => {
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (item) => (
        <>
          <div className="column_info">ID</div>
          <div>{item}</div>
        </>
      ),
    },
    {
      title: translate("Subject"),
      dataIndex: "subject",
      key: "subject",
      render: (item) => (
        <>
          <div className="column_info">{translate("Subject")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Topic"),
      dataIndex: "topic",
      key: "topic",
      render: (item) => (
        <>
          <div className="column_info">{translate("Topic")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Priority"),
      dataIndex: "priority",
      key: "priority",
      render: (item) => (
        <>
          <div className="column_info">{translate("Priority")}</div>
          {item}
        </>
      ),
    },
    {
      title: translate("Created / Updated date"),
      dataIndex: "created_at",
      key: "created_at",
      render: (item) => (
        <>
          <div className="column_info">
            {translate("Created / Updated date")}
          </div>
          {item.replace("T", " ")?.split(".")[0]}
        </>
      ),
    },
    {
      title: translate("Status"),
      key: "status",
      dataIndex: "status",
      render: (status) => (
        <>
          <Tag
            key={status}
            className={` ${
              status === "closed" ? " default__tag" : " error__tag"
            }`}
          >
            {status?.capitalize()}
          </Tag>
          {/* {status.map((tag) => {
            // let color = "#52c41a";
            // if (tag === "Done") {
            //   color = "#820014";
            // }
            // if (tag === "Active") {
            //   color = "#237804";
            // }
            return (
              <Tag key={tag} className={` ${tag === "Active" ? " default__tag" : " error__tag"}`}>
                {tag}
              </Tag>
            );
          })} */}
        </>
      ),
    },
    {
      title: translate("Action"),
      key: "action",
      className: "table_actin",
      render: (text, record) => (
        <>
          <div className="column_info">{translate("Details")}</div>
          <Tooltip
                            title={translate(
                              "Show"
                            )}
                            placement="top"
                          >
                           <Button
            onClick={() => {
              console.log(record, "clicked");
              setSelectedTicket(record);
              setDrawer(true);
            }}
            icon={<FeatherIcon icon={"message-square"} />}
            className={"table_icon"}
          />
                          </Tooltip>
          
        </>
      ),
    },
  ];

  // const data = [
  //   {
  //     key: "1",
  //     subject: "ERR_CONNECTION_RESET",
  //     topic: "Account and Payment",
  //     cuDate: "16.01.2023 21:23:05 / 	16.01.2023 21:26:00",
  //     status: ["Active"],
  //   },
  //   {
  //     key: "2",
  //     subject: "The upload failed. The server indicated HTTP error 500 (Internal Error).",
  //     topic: "Other",
  //     cuDate: "16.01.2023 21:23:05 / 	16.01.2023 21:26:00",
  //     status: ["Done"],
  //   },
  // ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={{
          locale: {
            items_per_page: translate("Page"),
          },
          position: ["top", "bottom"],
        }}
        locale={{ emptyText: (<span className="empty__tble">
          <img src={EmptyIcon} />
          </span>)
         }}
        className="card_m"
      />
    </>
  );
};

export default TicketsTable;
