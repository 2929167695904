import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PageLoader from "../common/loaders/loader_page";

export default function BackToTop({ isPage = { isPage } }) {
  const { pathname } = useLocation();
  const [isPageLoaded, setPageLoaded] = useState(false);

  // const pageControl = () => {
  //   if (
  //     window.location.pathname == "/blog" ||
  //     window.location.pathname == "/blog-details"
  //   ) {
  //     document.body.classList.add("not--scroll");
  //   } else {
  //     document.body.classList.remove("not--scroll");
  //   }
  // };

  useEffect(() => {
    setPageLoaded(true);
    // pageControl();
    document.body.classList.remove("no--scroll");
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setTimeout(
      () => {
        setPageLoaded(false);
      },
      isPage === "dashboard-client"
        ? 1200 
        :  window.location.pathname == "/pricing" ? 1600
        :  window.location.pathname == "/my-plan" ? 1400
        : 500
    );
  }, [pathname]);

  return <>
  {isPageLoaded ? <PageLoader isPage={isPage} /> : null}
  </>;
}
