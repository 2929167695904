import React, { useEffect, useState, useRef, useContext } from "react";
import FeatherIcon from "feather-icons-react";
import { NavLink } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import { signOut } from "../utils/auth";
import Swal from "sweetalert2";
import translate from "../utils/translations";
import UserContext from "../../shared/utils/userContext";
import { SVGTickets, SVGTickets2 } from "../../common/other_elements/svg_icons";

const User = ({ setUser, isUser, setLogged, isPage }) => {
  let navigate = useNavigate();
  const location = useLocation();

  const logout = async () => {
    Swal.fire({
      icon: "warning",
      title: translate("Log out"),
      text: translate("Are you sure want to log out?"),
      confirmButtonText: translate("Log Out"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        toast.success(translate("Logout successful!.."));
        await signOut();

        let languageSavedStorage = localStorage.getItem("lang") || "EN";
        let warningSavedStorage = localStorage.getItem("checkedAttentionSaved");
        let bannerSavedStorage = localStorage.getItem(
          "isHeaderBannerClosed12345"
        );

        localStorage.clear();

        if (languageSavedStorage) {
          localStorage.setItem("lang", languageSavedStorage);
        }
        if (warningSavedStorage) {
          localStorage.setItem("checkedAttentionSaved", warningSavedStorage);
        }
        if (bannerSavedStorage) {
          localStorage.setItem("isHeaderBannerClosed12345", bannerSavedStorage);
        }

        navigate("/");
        setUser(false);
        setLogged(false);
        window.location.reload(false);
      } else if (result.isDenied) {
      }
    });
  };

  const loggedInUser = useContext(UserContext);
  const [isAdmin, setAdmin] = useState(false);
  useEffect(() => {
    console.log(loggedInUser);
    if (loggedInUser) {
      if (loggedInUser?.role === "admin") {
        setAdmin(true);
      } else {
        setAdmin(false);
      }
    }
  }, [loggedInUser]);

  return (
    <>
      <div
        className={`header__item header__item_user js-header-item ${
          isUser && " active"
        }`}
      >
        <a
          className="header__login js-popup-open"
          href="#!"
          onClick={() => setUser(!isUser)}
        >
          <svg className="icon icon-user">
            <use xlinkHref="#icon-user"></use>
          </svg>
        </a>
        <div className="header__body js-header-body">
          {isPage !== "dashboard-admin" ? (
            <div className="header__group">
              <div className="header__menu">
                {isAdmin && (
                  <NavLink
                    className="header__link"
                    to="/admin-dashboard"
                    onClick={() => setUser(false)}
                  >
                    <FeatherIcon icon="repeat" className={"iconx"} />
                    {translate("Switch to Admin")}
                  </NavLink>
                )}
                <NavLink
                  className="header__link"
                  to="/client-dashboard"
                  onClick={() => setUser(false)}
                >
                  <FeatherIcon icon="monitor" className={"iconx"} />
                  {translate("Dashboard")}
                </NavLink>
              </div>
              <div className="header__menu">
                <NavLink
                  className={`header__link ${
                    location.pathname === "/personal-settings"
                      ? " active"
                      : location.pathname === "/security-settings"
                      ? " active"
                      : location.pathname === "/notification-settings"
                      ? " active"
                      : location.pathname === "/integration-settings"
                      ? " active"
                      : " "
                  }`}
                  to="/personal-settings"
                  onClick={() => setUser(false)}
                >
                  <FeatherIcon icon="settings" className={"iconx"} />
                  {translate("Settings")}
                </NavLink>
                <NavLink
                  className="header__link"
                  to="my-plan"
                  onClick={() => setUser(false)}
                >
                  <FeatherIcon icon="award" className={"iconx"} />
                  {translate("My Plan")}
                </NavLink>
                <NavLink
                  className="header__link"
                  to="/tickets"
                  onClick={() => setUser(false)}
                >
                  <SVGTickets2 />
                  {translate("Tickets")}
                </NavLink>
              </div>
            </div>
          ) : (
            <div className="header__group">
              <div className="header__menu">
                <NavLink
                  className="header__link"
                  to="/client-dashboard"
                  onClick={() => setUser(false)}
                >
                  <FeatherIcon icon="repeat" className={"iconx"} />
                  {translate("Switch to Client")}
                </NavLink>
                <NavLink
                  className="header__link"
                  to="/admin-dashboard"
                  onClick={() => setUser(false)}
                >
                  <FeatherIcon icon="monitor" className={"iconx"} />
                  {translate("Dashboard")}
                </NavLink>
              </div>
              <div className="header__menu">
                <NavLink
                  className={`header__link ${
                    location.pathname === "/personal-settings"
                      ? " active"
                      : location.pathname === "/security-settings"
                      ? " active"
                      : location.pathname === "/notification-settings"
                      ? " active"
                      : location.pathname === "/integration-settings"
                      ? " active"
                      : " "
                  }`}
                  to="/personal-settings"
                  onClick={() => setUser(false)}
                >
                  <FeatherIcon icon="settings" className={"iconx"} />
                  {translate("Settings")}
                </NavLink>
                <NavLink
                  className="header__link"
                  to="balance"
                  onClick={() => setUser(false)}
                >
                  <FeatherIcon icon="award" className={"iconx"} />
                  {translate("Balance")}
                </NavLink>
                <NavLink
                  className="header__link"
                  to="/admin-support"
                  onClick={() => setUser(false)}
                >
                  <SVGTickets2 />
                  {translate("Tickets")}
                </NavLink>
              </div>
            </div>
          )}

          <div className="header__btns">
            <a
              className="button button-small header__button reset cursorp"
              onClick={logout}
            >
              {translate("Log out")}
            </a>
            {/* <button className="button-stroke button-small header__button">
           Account
          </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default User;
