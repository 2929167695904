import React, { Component, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const LineChart = ({ data }) => {
  console.log(data);
  const [series, setSeries] = useState([
    {
      name: "Total Clients",
      data: [0, 40, 75, 51, 42, 109, 100],
    },
  ]);
  const [options, setOptions] = useState({});

  // const [series, setSeries] = useState([
  //   {
  //     name: "Total Clients",
  //     data: [0, 40, 75, 51, 42, 109, 100],
  //   },
  //   {
  //     name: "Free",
  //     data: [0, 44, 66, 52, 41, 8, 14],
  //   },
  //   {
  //     name: "Basic",
  //     data: [0, 15, 22, 30, 28, 52, 41],
  //   },
  //   {
  //     name: "Plus",
  //     data: [0, 18, 33, 44, 12, 55, 33],
  //   },
  //   {
  //     name: "Pro",
  //     data: [0, 25, 53, 21, 14, 57, 59],
  //   },
  // ]);

  useEffect(() => {
    console.log(data);
    if (data && data.length > 0) {
      setSeries([{ name: "data", data: data?.map((i) => i.count) }]);
      setOptions({
        chart: {
          type: "area",
          height: 350,
          zoom: {
            enabled: true,
          },
          toolbar: {
            autoSelected: "pan",
            show: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: [2, 1],
        },
        xaxis: {
          categories: data?.map((i) => i?.date?.split("T")[0]),
        },
        yaxis: {
          min: 0,
          tickAmount: 4,
          opposite: false,
        },
        grid: {
          strokeDashArray: 4,
          position: "back",
          yaxis: {
            lines: {
              show: false,
            },
          },
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        colors: ["#3B71FE", "#9e9e9e", "#58C27D", "#9c27b0", "#ff9800"],
        fill: {
          gradient: {
            enabled: true,
            opacityFrom: 0.55,
            opacityTo: 0,
          },
        },
        legend: {
          horizontalAlign: "center",
          offsetY: 5,
          fontSize: "10px",
        },
        markers: {
          size: [4, 3, 2],
          strokeColor: ["#fff", "#fff", "#fff"],
          strokeWidth: [3, 2, 1],
          hover: {
            size: 5,
          },
        },
      });
    }
  }, [data]);

  useEffect(() => {
    console.log(series);
    console.log(options);
  }, [series, options]);

  return (
    <>
      <div id="chart" className="apex_chart pb_20">
        <ReactApexChart options={options} series={series} type="area" height={300} />
      </div>
    </>
  );
};

export default LineChart;
