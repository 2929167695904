import React from "react";
import { Button, Drawer } from "antd";
import { useState } from "react";
import Select from "../../common/form_elements/select";
import Input from "../../common/form_elements/input";
import Checkbox from "../../common/form_elements/checkbox";
import toast, { Toaster } from "react-hot-toast";

const FilterDrawer = ({ isDrawer, setDrawer }) => {
  const notify = () =>
    toast.success("Settings have been saved successfully!..");
  const [isItem, setItem] = useState("Custom item #1");
  return (
    <>
      <Drawer
        title="Filters	"
        placement="right"
        onClose={() => setDrawer(false)}
        open={isDrawer}
        width={"50%"}
      >
        <>
          <div className={`upload__item d_block drawer_top_bar`}>
            <div className="nav">
              <button
                className={`nav__link cursorp ${
                  isItem === "Custom item #1" ? " active" : " "
                }`}
                onClick={() => setItem("Custom item #1")}
              >
                Custom item #1
              </button>
              <button
                className={`nav__link cursorp ${
                  isItem === "Custom item #2" ? " active" : " "
                }`}
                onClick={() => setItem("Custom item #2")}
              >
                Custom item #2
              </button>
              <button
                className={`nav__link cursorp ${
                  isItem === "Custom item #3" ? " active" : " "
                }`}
                onClick={() => setItem("Custom item #3")}
              >
                Custom item #3
              </button>
              <button
                className={`nav__link cursorp ${
                  isItem === "Custom item #4" ? " active" : " "
                }`}
                onClick={() => setItem("Custom item #4")}
              >
                Custom item #4
              </button>
            </div>
          </div>

          <div className={`upload__item d_block`}>
            <div className="upload__category mm">Select Components</div>
            <div className="upload__note">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolores,
              provident?
            </div>
            <div className="upload__fieldset">
              <div className="upload__row">
                <div className="upload__col upload__col_w33">
                  <Select
                    label="Custom option ##1"
                    options={["All", "Option ##1", "Option ##2"]}
                  />
                </div>
                <div className="upload__col upload__col_w33">
                  <Select
                    label="Custom option ##2"
                    options={["All", "Option ##1", "Option ##2"]}
                  />
                </div>
                <div className="upload__col upload__col_w33">
                  <Select
                    label="Custom option ##3"
                    options={["All", "Option ##1", "Option ##2"]}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={`upload__item d_block`}>
            <div className="upload__category mm">Input Components</div>
            <div className="upload__note">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolores,
              provident?
            </div>
            <div className="upload__row">
              <div className="upload__col upload__col_w33">
                <Input
                  id="cx1"
                  name="cx1"
                  label="Custom option ##1"
                  type="number"
                  placeholder="Custom option ##1"
                />
              </div>
              <div className="upload__col upload__col_w33">
                <Input
                  id="cx2"
                  name="cx2"
                  label="Custom option ##2"
                  type="number"
                  placeholder="Custom option ##2"
                />
              </div>
              <div className="upload__col upload__col_w33">
                <Input
                  id="cx3"
                  name="cx3"
                  label="Custom option ##3"
                  type="number"
                  placeholder="Custom option ##3"
                />
              </div>
            </div>
          </div>

          <div className={`upload__item d_block`}>
            <div className="upload__category mm">
              Input Components with Symbol
            </div>
            <div className="upload__note">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolores,
              provident?
            </div>
            <div className="upload__row">
              <div className="upload__col upload__col_w33">
                <Input
                  id="cs1"
                  name="cs1"
                  label="Custom option ##1"
                  type="number"
                  placeholder="Custom option ##1"
                  customClass="field_map"
                  fieldMap="%"
                />
              </div>
              <div className="upload__col upload__col_w33">
                <Input
                  id="cs2"
                  name="cs2"
                  label="Custom option ##2"
                  type="number"
                  placeholder="Custom option ##2"
                  customClass="field_map"
                  fieldMap="$"
                />
              </div>
              <div className="upload__col upload__col_w33">
                <Input
                  id="cs3"
                  name="cs3"
                  label="Custom option ##3"
                  type="number"
                  placeholder="Custom option ##3"
                  customClass="field_map"
                  fieldMap="@"
                />
              </div>
            </div>
          </div>

          <div className={`upload__item d_block`}>
            <div className="upload__category mm">Checkbox Components</div>
            <div className="upload__note">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolores,
              provident?
            </div>
            <div className="upload__row">
              <div className="upload__col upload__col_w25">
                <Checkbox label="Custom option ##1" defaultChecked={true} />
              </div>
              <div className="upload__col upload__col_w25">
                <Checkbox label="Custom option ##1" defaultChecked={true} />
              </div>
              <div className="upload__col upload__col_w25">
                <Checkbox label="Custom option ##1" defaultChecked={true} />
              </div>
            </div>
          </div>

          <div className="upload__foot">
            <button
              className="button upload__button cursorp"
              onClick={() => {
                setDrawer(false);
                notify();
              }}
            >
              <span>Save</span>
            </button>
            <button
              className="button-stroke upload__button js-upload-button"
              onClick={() => setDrawer(false)}
            >
              Cancel
            </button>
          </div>
        </>
      </Drawer>
    </>
  );
};

export default FilterDrawer;
