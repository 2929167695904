import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import FavoritesTable from "./_favorites_table";
import { Helmet } from "react-helmet";

import translate from "../../../shared/utils/translations";
import {
  downloadAsinListExcelWithCustomColumns,
  requestHandler,
} from "../../../shared/utils/helpers";

const Favorites = ({ setPage }) => {
  useEffect(() => {
    setPage("dashboard-client");
    localStorage.setItem("dashboard-type", "dashboard-client");
  });
  const [favorites, setFavorites] = useState([]);
  const [buffer, setBuffer] = useState([]);

  const getFavoriteAsins = async () => {
    const favorites = await requestHandler(
      "get",
      "/asin/favorites",
      null,
      true,
      null,
      null
    ).then((res) => {
      if (res.error) {
        toast.error(res.error);
        return [];
      }
      console.log("res", res?.data);
      setFavorites(res?.data);
      setBuffer(res?.data);
    });
  };

  const removeFavorite = async (asin) => {
    await requestHandler(
      "put",
      `/asin/favorite/${asin}`,
      { asin, title: "", image: "" },
      true,
      null,
      null
    ).then((res) => {
      if (res.error) {
        toast.error(res.error);
        return [];
      }
      console.log("res", res?.data);
      getFavoriteAsins();
    });
  };

  useEffect(() => {
    getFavoriteAsins();
  }, []);

  const [isItem, setItem] = useState("Custom item #1");
  return (
    <>
      <Helmet>
        <title>{translate("Favorite Products")}</title>
      </Helmet>
      <div className="outer__inner">
        <div className="onex_wl bg_white">
          <div className="onex_wl__wrapper">
            <div className="upload__head fvvbg">
              <div className="lftt__ibbn">
                <h2 className="upload__title h2">
                  {translate("Favorite Products")}
                </h2>

                <div className="keys__info">
                  {translate(
                    "You can see all the products you have favorited with the plugin here."
                  )}
                </div>
              </div>
              <div className="rightt__ibbn">
                <button
                  disabled={favorites.length === 0}
                  onClick={() => {
                    downloadAsinListExcelWithCustomColumns(
                      favorites,
                      "favorites"
                    );
                  }}
                  class="button-stroke button-small activity__button js-activity-button"
                >
                  <span>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-download "
                    >
                      <g>
                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                        <polyline points="7 10 12 15 17 10"></polyline>
                        <line x1="12" y1="15" x2="12" y2="3"></line>
                      </g>
                    </svg>{" "}
                    {translate("Download")}
                  </span>
                </button>
              </div>
            </div>
            <div className="onex_wl__list">
              <div className="keys__enabled d_block">
                <div className="keys__list">
                  <div className="keys__item ss ggjk">
                    <FavoritesTable
                      data={buffer}
                      removeFavorite={removeFavorite}
                      refreshData={getFavoriteAsins}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Favorites;
