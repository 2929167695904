import React, { useEffect, useState } from "react";
import VersionsTable from "./_versions_table";
import Input from "../../../common/form_elements/input";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { requestHandler } from "../../../shared/utils/helpers";
import translate from "../../../shared/utils/translations";
import Swal from "sweetalert2";

import toast, { Toaster } from "react-hot-toast";

const Versions = ({ setPage }) => {
  const navigate = useNavigate();
  useEffect(() => {
    setPage("dashboard-admin");
    localStorage.setItem("dashboard-type", "dashboard-admin");
  });
  const [formData, setFormData] = useState({ update_required: false });
  const [data, setData] = useState([]);

  const onChange = (e, name) => {
    setFormData({ ...formData, [name]: e });
  };

  const getVersions = async () => {
    const incomingData = await requestHandler(
      "get",
      `/admin/versions`,
      null,
      true,
      navigate,
      "/login"
    );
    console.log(incomingData);
    setData(incomingData?.data);
  };

  const send = () => {
    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Are you sure about performing this action?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log(formData);
        requestHandler(
          "post",
          `/admin/versions`,
          formData,
          true,
          navigate,
          "/login"
        )
          .then((res) => {
            console.log("post success", res);
            getVersions();
          })
          .catch((err) => {
            console.log("post error", err);
            setData([]);
          });
        setVersion(false);
      }
    });
  };

  const deleteVersion = (id) => {
    Swal.fire({
      icon: "warning",
      title: translate("Delete?"),
      text: translate("Are you sure you want to delete this?"),
      confirmButtonText: translate("Delete"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        requestHandler(
          "delete",
          `/admin/versions/${id}`,
          null,
          true,
          navigate,
          "/login"
        )
          .then((res) => {
            console.log("post success", res);
            getVersions();
          })
          .catch((err) => {
            console.log("post error", err);
            setData([]);
          });
      }
    });
  };
  useEffect(() => {
    getVersions();
  }, []);

  const [isVersion, setVersion] = useState(false);

  return (
    <>
      <div className="outer__inner">
        <div className="onex_wl bg_white">
          <div className="onex_wl__wrapper">
            <div className="upload__head">
              <h2 className="upload__title h2">{translate("Versions")}</h2>

              
              {/* <a
                    className="button-stroke button-small upload__button"
                    href="#!"
                  >
                    More filter
                  </a> */}
            </div>
            <div className="onex_wl__list">
              <div className="keys__enabled d_block">
                <div className="keys__list">
                  <div className="keys__item ss uu">
                    <div className="activity__top ss">
                      <div className="activity__item activity__item_calendar js-activity-item ">
                        <button
                          onClick={() => setVersion(true)}
                          className="button-stroke button-small activity__button js-activity-button"
                        >
                          <span>{translate("New Version")}</span>
                        </button>

                        <p className="url__copyj">Update URL: <div onClick={() => {navigator.clipboard.writeText("https://kepicker.com/how-to-update-chrome-extensions");toast.success(translate("Copied to Clipboard!"))}} className="url__linl">https://kepicker.com/how-to-update-chrome-extensions</div></p>
                      </div>

                      <div className={`version__mdl ${isVersion && " show"}`}>
                        <div className="version__mdl_body">
                          <div className="mdl__header">
                            <h4>{translate("Manage Update")}</h4>
                          </div>
                          <div className="mdl___cntent">
                            <Input
                              id="version"
                              name="version"
                              label={translate("Version No.")}
                              type="text"
                              placeholder={translate("Version No.")}
                              cusstomClass="field_icon"
                              hasIcon="icon-version"
                              onChange={onChange}
                            />
                            <Input
                              id="description"
                              name="description"
                              label={translate("Update Content")}
                              type="text"
                              placeholder={translate("Update Content")}
                              cusstomClass="field_icon"
                              hasIcon="icon-description"
                              onChange={onChange}
                            />

                            <Input
                              id="update_url"
                              name="update_url"
                              label={translate("Update URL")}
                              type="text"
                              placeholder={translate("Update URL")}
                              cusstomClass="field_icon"
                              hasIcon="icon-update_url"
                              onChange={onChange}
                            />

                            <div className="field">
                              <div className="field__label">
                                {translate("Is an update required?")}
                              </div>
                              <select
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    ["update_required"]:
                                      parseInt(e.target.value) > 0,
                                  });
                                }}
                                className="field__input"
                                name="update_required"
                                id="update_required"
                              >
                                <option value="0">{translate("No")}</option>
                                <option value="1">{translate("Yes")}</option>
                              </select>
                            </div>
                          </div>
                          <div className="mdl___footer">
                            <button
                              onClick={() => setVersion(false)}
                              className={`button-stroke onex_d__button js-scroll`}
                            >
                              {translate("Cancel")}
                            </button>
                            <button
                              className={`button services__button`}
                              onClick={() => send()}
                            >
                              {translate("Send")}
                            </button>
                          </div>
                        </div>
                      </div>

                      <div className="right_items"></div>
                    </div>

                    <VersionsTable data={data} deleteVersion={deleteVersion} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Versions;
