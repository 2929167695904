import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import * as settingsActions from "../../hooks/actions";
import translate from "../utils/translations";

import FeatherIcon from "feather-icons-react";
import { SVGTickets2 } from "../../common/other_elements/svg_icons";

const FooterMobile = ({
  isLogged,
  setLogged,
  setDarkMode,
  isDarkMode,
  isSidebar,
  setSidebar,
  isPage,
}) => {
  useEffect(() => {
    if (isSidebar) {
      document.body.classList.add("not--scroll");
    } else {
      document.body.classList.remove("not--scroll");
    }
  }, [isSidebar]);

  return (
    <>
      <footer className="footer__mobile">
        <nav className="mobile_menu_nav">
          {isPage === "dashboard-client" ? (
            <ul>
              <li>
                <NavLink
                  to="/client-dashboard"
                  role="button"
                  className="d-flex justify-content-center align-items-center link"
                >
                  <i className="m__icn">
                    <FeatherIcon icon="monitor" className={"iconx"} />
                  </i>
                  <span>{translate("Overview2")}</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/tickets"
                  role="button"
                  className="d-flex justify-content-center align-items-center link"
                >
                  <i className="m__icn">
                    <SVGTickets2 />
                  </i>
                  <span>{translate("Tickets-mm")}</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/my-plan"
                  role="button"
                  className="d-flex justify-content-center align-items-center link"
                >
                  <i className="m__icn">
                    <FeatherIcon icon="award" className={"iconx"} />
                  </i>
                  <span>{translate("Plan")}</span>
                </NavLink>
              </li>
              <li>
                <a
                  onClick={() => setSidebar(!isSidebar)}
                  role="button"
                  className={`d-flex justify-content-center align-items-center link ${
                    isSidebar && " active"
                  }`}
                >
                  <i className="m__icn">
                    <FeatherIcon icon="menu" className={"iconx"} />
                  </i>
                  <span>Sidenav</span>
                </a>
              </li>
            </ul>
          ) : (
            <></>
          )}

          {isPage === "dashboard-admin" ? (
            <ul>
              <li>
                <NavLink
                  to="/admin-dashboard"
                  role="button"
                  className="d-flex justify-content-center align-items-center link"
                >
                  <i className="m__icn">
                    <FeatherIcon icon="monitor" className={"iconx"} />
                  </i>
                  <span>{translate("Overview2")}</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin-tickets"
                  role="button"
                  className="d-flex justify-content-center align-items-center link"
                >
                  <i className="m__icn">
                    <SVGTickets2 />
                  </i>
                  <span>{translate("Tickets-mm")}</span>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/balance"
                  role="button"
                  className="d-flex justify-content-center align-items-center link"
                >
                  <i className="m__icn">
                    <FeatherIcon icon="award" className={"iconx"} />
                  </i>
                  <span>{translate("Balance")}</span>
                </NavLink>
              </li>
              <li>
                <a
                  onClick={() => setSidebar(!isSidebar)}
                  role="button"
                  className={`d-flex justify-content-center align-items-center link ${
                    isSidebar && " active"
                  }`}
                >
                  <i className="m__icn">
                    <FeatherIcon icon="menu" className={"iconx"} />
                  </i>
                  <span>Sidenav</span>
                </a>
              </li>
            </ul>
          ) : (
            <></>
          )}

          {isPage === "landing" ? (
            <>
              {isLogged ? (
                <ul>
                  <li>
                    <NavLink
                      to="/client-dashboard"
                      role="button"
                      className="d-flex justify-content-center align-items-center link"
                    >
                      <i className="m__icn">
                        <FeatherIcon icon="monitor" className={"iconx"} />
                      </i>
                      <span>{translate("Overview2")}</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/tickets"
                      role="button"
                      className="d-flex justify-content-center align-items-center link"
                    >
                      <i className="m__icn">
                        <SVGTickets2 />
                      </i>
                      <span>{translate("Tickets-mm")}</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/my-plan"
                      role="button"
                      className="d-flex justify-content-center align-items-center link"
                    >
                      <i className="m__icn">
                        <FeatherIcon icon="award" className={"iconx"} />
                      </i>
                      <span>{translate("Plan")}</span>
                    </NavLink>
                  </li>
                </ul>
              ) : (
                <ul>
                  <li>
                    <NavLink
                      to="/login"
                      role="button"
                      className="d-flex justify-content-center align-items-center link"
                    >
                      <i className="m__icn">
                        <FeatherIcon icon="monitor" className={"iconx"} />
                      </i>
                      <span>{translate("Overview2")}</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/login"
                      role="button"
                      className="d-flex justify-content-center align-items-center link"
                    >
                      <i className="m__icn">
                        <SVGTickets2 />
                      </i>
                      <span>{translate("Tickets-mm")}</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/login"
                      role="button"
                      className="d-flex justify-content-center align-items-center link"
                    >
                      <i className="m__icn">
                        <FeatherIcon icon="award" className={"iconx"} />
                      </i>
                      <span>{translate("Plan")}</span>
                    </NavLink>
                  </li>
                </ul>
              )}
            </>
          ) : (
            <></>
          )}
        </nav>
      </footer>
    </>
  );
};

const mapStateToProps = (state) => ({
  isLogged: state.settings.isLogged,
  isDarkMode: state.settings.isDarkMode,
});

const mapDispatchToProps = (dispatch) => ({
  setLogged: (e) => dispatch(settingsActions.setLogged(e)),
  setDarkMode: (e) => dispatch(settingsActions.setDarkMode(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FooterMobile);
