import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Table } from "antd";
import { requestHandler } from "../../../shared/utils/helpers";
import { useNavigate } from "react-router-dom";
import translate from "../../../shared/utils/translations";
import Swal from "sweetalert2";
const Support = ({
  setPage,
  setUserDetailsDrawer = { setUserDetailsDrawer },
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    setPage("dashboard-admin");
    localStorage.setItem("dashboard-type", "dashboard-admin");
  });
  const [data, setData] = useState([]);
  const getSupport = async () => {
    const res = await requestHandler(
      "get",
      "/admin/supports",
      null,
      true,
      navigate,
      "/login"
    );
    console.log(res);
    if (res && res.data) {
      setData(res.data);
    }
  };
  useEffect(() => {
    getSupport();
  }, []);
  const deleteSupport = async (id) => {
    Swal.fire({
      icon: "warning",
      title: translate("Delete?"),
      text: translate("Are you sure you want to delete this?"),
      confirmButtonText: translate("Delete"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const res = await requestHandler(
          "delete",
          `/admin/support/${id}`,
          null,
          true,
          navigate,
          "/login"
        );
        console.log(res);
        if (res && res.data) {
          getSupport();
        }
      }
    });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (item) => (
        <>
          <div className="column_info">ID</div>
          <div>## {item}</div>
        </>
      ),
    },
    {
      title: translate("Name"),
      key: "name",
      dataIndex: "name",
      render: (text) => (
        <>
          <div className="column_info">{translate("Name")}</div>
          <div>{text}</div>
        </>
      ),
    },
    {
      title: translate("Email"),
      key: "email",
      dataIndex: "email",
      render: (text) => (
        <>
          <div className="column_info">{translate("Email")}</div>
          <div>{text}</div>
        </>
      ),
    },
    {
      title: translate("Subject"),
      key: "subject",
      dataIndex: "subject",
      render: (text) => (
        <>
          <div className="column_info">{translate("Subject")}</div>
          <div>{text}</div>
        </>
      ),
    },
    {
      title: translate("Message"),
      key: "message",
      dataIndex: "message",
      className: "long__inf",
      render: (text) => (
        <>
          <div className="column_info">{translate("Message")}</div>
          <div>{text}</div>
        </>
      ),
    },
    {
      title: translate("Created Date"),
      key: "created_at",
      dataIndex: "created_at",
      render: (text) => (
        <>
          <div className="column_info">{translate("Created Date")}</div>
          <div>{text.replace("T", " - ").split(".")[0]}</div>
        </>
      ),
    },
    {
      title: translate("Action"),
      key: "action",
      render: (item) => (
        <>
          <div className="column_info">{translate("Action")}</div>
          <div>
            <a
              className="button-stroke button-small upload__button"
              href="#!"
              onClick={() => {
                deleteSupport(item.id);
              }}
            >
              {translate("Delete")}
            </a>
          </div>
        </>
      ),
    },
  ];
  return (
    <>
      <div className="outer__inner">
        <div className="onex_wl bg_white">
          <div className="onex_wl__wrapper">
            <div className="upload__head">
              <h2 className="upload__title h2">
                {translate("Web Support Form Messages")}
              </h2>
              {/* <a
                    className="button-stroke button-small upload__button"
                    href="#!"
                  >
                    More filter
                  </a> */}
            </div>
            <div className="onex_wl__list">
              <div className="keys__enabled d_block">
                <div className="keys__list">
                  <div className="keys__item ss uu">
                    <div className="activity__top ss">
                      <div className="right_items"></div>
                    </div>

                    <Table
                      columns={columns}
                      dataSource={data}
                      pagination={{
                        locale: {
                          items_per_page: translate("Page"),
                        },
                        position: ["top", "bottom"],
                      }}
                      className="card_m"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Support;
