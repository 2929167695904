import React, { useEffect, useState } from "react";
import translate from "../../../shared/utils/translations";
import Marquee from "react-fast-marquee";

const TagsCloud = () => {
  useEffect(() => {
    currentLanguage(localStorage.getItem("lang") || "EN");
  });
  const [currentLang, currentLanguage] = useState({});

  return (
    <>
      <div className={`section_tags__clouds ${currentLang}`}>
        <div className="onex_pl__center center pos_rel">
          <div className="tags__clouds">
            {/* <h6>{translate("Tags Cloud")}</h6> */}
            <ul className="tr__tags">
              <Marquee>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-yurtdisinda-avrupada-turkiyede-en-cok-satilan-urunler"
                  >
                    Amazon'da en çok satılan ürünler
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-yurtdisinda-avrupada-turkiyede-en-cok-satilan-urunler"
                  >
                    Amazon'da Türkiye'den ne satabilirim
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-yurtdisinda-avrupada-turkiyede-en-cok-satilan-urunler"
                  >
                    Amazon'da en çok satılan Türk ürünleri
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-yurtdisinda-avrupada-turkiyede-en-cok-satilan-urunler"
                  >
                    Amazon Avrupa'da en çok satılan ürünler
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-yurtdisinda-avrupada-turkiyede-en-cok-satilan-urunler"
                  >
                    Amazon yurtdışında en çok satılan ürünler
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-karli-urunler"
                  >
                    Amazon'da karlı ürünler
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-karli-urunler"
                  >
                    Amazon'da en çok ne satar
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-karli-urunler"
                  >
                    Amazon'dan satış yapmak karlı mı
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-satis-yapmak"
                  >
                    Amazon'da satış yapmak
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-satis-yapmak"
                  >
                    Amazon üzerinden satış yapmak
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-satis-yapmak"
                  >
                    Amazon'da satış yapmak zor mu
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-satis-yapmak-icin-gerekli-sermaye"
                  >
                    Amazon satış yapmak için ne kadar para lazım
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-satis-yapmak-icin-gerekli-sermaye"
                  >
                    Amazon için ne kadar para lazım
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-satis-yapmak-icin-gerekli-sermaye"
                  >
                    Amazon'a ne kadar sermaye gerekir
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-para-kazanmak"
                  >
                    Amazon'dan nasıl para kazanılır
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-para-kazanmak"
                  >
                    Amazon'da para kazanmak mümkün mü
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-para-kazanmak"
                  >
                    Amazon'da para kazanılır mı
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-urun-karsilastirma-rehberi"
                  >
                    Ürün Karşılaştırma Nasıl Yapılır
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-urun-karsilastirma-rehberi"
                  >
                    Ürün Karşılaştırma Rehberi
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-urun-karsilastirma-rehberi"
                  >
                    Kargo ve Taşıma Maliyetleri
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-urun-ve-ulkeler-arasi-fiyat-karsilastirma"
                  >
                    Amazon ürün karşılaştırma
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-urun-ve-ulkeler-arasi-fiyat-karsilastirma"
                  >
                    Amazon ürün karşılaştırma nasıl yapılır
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-urun-ve-ulkeler-arasi-fiyat-karsilastirma"
                  >
                    Amazon ülkeler arası fiyat karşılaştırma
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-urun-ve-ulkeler-arasi-fiyat-karsilastirma"
                  >
                    Amazon ürün araştırması nasıl yapılır
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-satilan-urunler"
                  >
                    Amazon tarafından satılan ürünler
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-satilan-urunler"
                  >
                    Amazon'da hangi ürün ne kadar satılıyor
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-satilan-urunler"
                  >
                    Amazon'da hangi ürünleri satabilirim
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/en-iyi-amazon-yazilimlari"
                  >
                    En iyi Amazon yazılımları
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/en-iyi-amazon-yazilimlari"
                  >
                    Amazon için hangi yazılım kullanılır
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/en-iyi-amazon-yazilimlari"
                  >
                    Amazon için yazılımlar
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-urun-arastirma-programlari"
                  >
                    Amazon ürün araştırma programları
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-urun-arastirma-programlari"
                  >
                    Amazon ürün bulma programı ücretsiz
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-dropshipping-yazilimlari-ve-sermaye-gereksinimleri"
                  >
                    Amazon dropshipping
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-dropshipping-yazilimlari-ve-sermaye-gereksinimleri"
                  >
                    Amazon dropshipping yazılımları
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-dropshipping-yazilimlari-ve-sermaye-gereksinimleri"
                  >
                    Amazon'da dropshipping yapmak
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-dropshipping-yazilimlari-ve-sermaye-gereksinimleri"
                  >
                    Amazon dropshipping ne kadar sermaye gerekli
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-fba-para-kazanma-ve-sermaye-gereksinimleri"
                  >
                    Amazon FBA ile para kazanma
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-fba-para-kazanma-ve-sermaye-gereksinimleri"
                  >
                    Amazon FBA için ne kadar sermaye gerekli
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-fba-para-kazanma-ve-sermaye-gereksinimleri"
                  >
                    Amazon FBA başarı hikayeleri
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-fba-para-kazanma-ve-sermaye-gereksinimleri"
                  >
                    Amazon FBA Karlı mı
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-fba-urun-bulma-ve-maliyet-hesaplama"
                  >
                    Amazon FBA ürün bulma
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-fba-urun-bulma-ve-maliyet-hesaplama"
                  >
                    Amazon FBA hesaplama
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-fba-urun-bulma-ve-maliyet-hesaplama"
                  >
                    Amazon FBA maliyet hesaplama
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-fba-urun-bulma-ve-maliyet-hesaplama"
                  >
                    Amazon FBA depo ücretleri
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-fba-urun-bulma-ve-maliyet-hesaplama"
                  >
                    Amazon FBA kargo ücretleri
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-fba-urun-bulma-ve-maliyet-hesaplama"
                  >
                    Amazon FBA fiyatları
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-asin-indirme-ve-magaza-kopyalama"
                  >
                    Amazon mağaza kopyalama
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-asin-indirme-ve-magaza-kopyalama"
                  >
                    Amazon ASIN bulma
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-asin-indirme-ve-magaza-kopyalama"
                  >
                    Amazon ASIN kopyalama
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-asin-indirme-ve-magaza-kopyalama"
                  >
                    Amazon ASIN listesi indirme
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-asin-indirme-ve-magaza-kopyalama"
                  >
                    Amazon ASIN sorgulama
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-urun-inceleme-rehberi"
                  >
                    Amazon ürün inceleme nasıl yapılır
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-urun-inceleme-rehberi"
                  >
                    Amazon ürün inceleme blogları
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-urun-inceleme-rehberi"
                  >
                    Amazon trend ürünleri
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-rekabet-analizi-ve-pazar-arastirma"
                  >
                    Amazon rekabet analizi araçları
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-rekabet-analizi-ve-pazar-arastirma"
                  >
                    Amazon ürün izleme araçları
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-rekabet-analizi-ve-pazar-arastirma"
                  >
                    Amazon pazar araştırma araçları
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-hesaplama-rehberi"
                  >
                    Amazon kar HESAPLAMA
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-hesaplama-rehberi"
                  >
                    Amazon maliyet hesaplama
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-hesaplama-rehberi"
                  >
                    Amazon Komisyon Oranları
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/en-iyi-amazon-dropshipping-yazilimlari-listesi"
                  >
                    En iyi Amazon Dropshipping yazılımları
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/en-iyi-amazon-dropshipping-yazilimlari-listesi"
                  >
                    Dropshipping yazılım fiyatları
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/en-iyi-amazon-dropshipping-yazilimlari-listesi"
                  >
                    Amazon Dropshipping Kar HESAPLAMA
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-dropshipping-rehberi"
                  >
                    Amazon Dropshipping ürün bulma
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-dropshipping-rehberi"
                  >
                    Dropshipping ürün bulma siteleri
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-dropshipping-rehberi"
                  >
                    Amazon Dropshipping ürün bulma programı
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-yazilimlari-rehberi"
                  >
                    Amazon'da kullanılan programlar
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-yazilimlari-rehberi"
                  >
                    Amazon analiz programları
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazon-yazilimlari-rehberi"
                  >
                    Amazon chrome eklentileri
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-satis-rehberi"
                  >
                    Amazon'da satış yapmak için gerekenler
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-satis-rehberi"
                  >
                    Amazon'da nasıl satış yapabilirim
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-satis-rehberi"
                  >
                    Amazon Avrupa'da satış yapmak için gerekenler
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-satis-rehberi"
                  >
                    Amazon'da satış yapmak için şirket gerekli mi
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-satis-rehberi"
                  >
                    Amazon'da mağaza açmak
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-urun-bulmak"
                  >
                    Amazon'da ürün bulmak
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-urun-bulmak"
                  >
                    Amazon'da satılacak ürün bulmak
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-urun-bulmak"
                  >
                    Amazonda bir ürünün ne kadar satıldığını nasıl öğrenebilirim
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-urun-bulmak"
                  >
                    Amazon ürün satış istastikleri
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-urun-bulmak"
                  >
                    Amazon ürün tarama
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-urun-bulmak"
                  >
                    Satıcısı Amazon olan ürünleri filtreleme
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-urun-bulma-programi"
                  >
                    Amazon ürün bulma programı
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-urun-bulma-programi"
                  >
                    Amazon ürün bulma programı ücretsiz
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-urun-bulma-programi"
                  >
                    Amazon'da ürün bulma yazılımı
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/tr/amazonda-urun-bulma-programi"
                  >
                    Amazon ürün yükleme programı
                  </a>
                </li>
              </Marquee>
            </ul>

            <ul className="en__tags">
              <Marquee>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/asin-fetcher-grabber-collector-tools-and-seller-finder-extension"
                  >
                    ASIN fetcher
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/asin-fetcher-grabber-collector-tools-and-seller-finder-extension"
                  >
                    ASIN collector
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/asin-fetcher-grabber-collector-tools-and-seller-finder-extension"
                  >
                    ASIN fetcher extension
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/asin-fetcher-grabber-collector-tools-and-seller-finder-extension"
                  >
                    Amazon ASIN grabber Chrome extension
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/asin-fetcher-grabber-collector-tools-and-seller-finder-extension"
                  >
                    Amazon ASIN grabber tool
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/asin-fetcher-grabber-collector-tools-and-seller-finder-extension"
                  >
                    Amazon seller finder
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-roi-formula-calculator-fba"
                  >
                    Amazon ROI calculator
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-roi-formula-calculator-fba"
                  >
                    Amazon ROI app
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-roi-formula-calculator-fba"
                  >
                    Amazon ROI formula
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-roi-formula-calculator-fba"
                  >
                    What is a good roi for Amazon FBA
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-fba-fee-calculator-roi-shipping-costs"
                  >
                    Amazon FBA calculator
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-fba-fee-calculator-roi-shipping-costs"
                  >
                    Amazon FBA ROI calculator
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-fba-fee-calculator-roi-shipping-costs"
                  >
                    Amazon FEE calculator
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-fba-fee-calculator-roi-shipping-costs"
                  >
                    FBA shipping calculator
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/fba-hunter-amazon-product-research"
                  >
                    FBA Hunter
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/fba-hunter-amazon-product-research"
                  >
                    Amazon product hunter
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/fba-hunter-amazon-product-research"
                  >
                    Amazon product research
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/fba-hunter-amazon-product-research"
                  >
                    Amazon product hunting
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-seller-tool-lists"
                  >
                    amazon seller tools chrome extension
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-seller-tool-lists"
                  >
                    amazon seller tools free
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-seller-tool-lists"
                  >
                    amazon tools list
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-seller-tool-lists"
                  >
                    Best Amazon seller tools
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-seller-tool-lists"
                  >
                    amazon seller payment
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-seller-tool-lists"
                  >
                    Amazon product hunting
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-seller-tool-lists"
                  >
                    Seller tools Chrome extension
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-seller-tool-lists"
                  >
                    amazon seller ai tools
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-tools-and-hazmat-check-programs"
                  >
                    Amazon Tools
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-tools-and-hazmat-check-programs"
                  >
                    amazon tools list
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-tools-and-hazmat-check-programs"
                  >
                    amazon repricer tools
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-tools-and-hazmat-check-programs"
                  >
                    Amazon Hazmat Check Tools
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-tools-and-hazmat-check-programs"
                  >
                    Amazon seller Hazmat program
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-tools-and-hazmat-check-programs"
                  >
                    Amazon hazmat check
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-keyword-tool-product-research-and-analysis"
                  >
                    Amazon keyword tool
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-keyword-tool-product-research-and-analysis"
                  >
                    Amazon product research tool
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-keyword-tool-product-research-and-analysis"
                  >
                    Amazon product analysis tool
                  </a>
                </li>

                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-keyword-tool-product-research-and-analysis"
                  >
                    Amazon calculator
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-keyword-tool-product-research-and-analysis"
                  >
                    Amazon calculator seller central
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-keyword-tool-product-research-and-analysis"
                  >
                    amazon roi calculator
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-keyword-tool-product-research-and-analysis"
                  >
                    Amazon revenue calculator
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-keyword-tool-product-research-and-analysis"
                  >
                    amazon profit calculator
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-keyword-tool-product-research-and-analysis"
                  >
                    amazon giveaway calculator
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-keyword-tool-product-research-and-analysis"
                  >
                    Amazon calculator au
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-keyword-tool-product-research-and-analysis"
                  >
                    Amazon calculator ca
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-keyword-tool-product-research-and-analysis"
                  >
                    Amazon calculator us
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href="https://onexyazilim.com/amazon/kepicker/en/amazon-keyword-tool-product-research-and-analysis"
                  >
                    Amazon calculator uk
                  </a>
                </li>
              </Marquee>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default TagsCloud;
