import React from "react";
import translate from "../../../shared/utils/translations";

const LoginLogs = ({ data }) => {
  const transformData = (d) => {
    //split by day and count the number of logs
    const days = d.map((item) => item?.login_time?.split("T")[0]);
    const uniqueDays = [...new Set(days)];
    const logs = uniqueDays
      .sort((a, b) => new Date(b) - new Date(a))
      .map((day) => {
        return {
          day: day,
          count: days.filter((d) => d === day).length,
        };
      });
    console.log(logs);
    return logs;
  };

  return (
    <>
      <div className="logs__area_kk">
        <ul>
          <li key={-1}>
            <span className="ll_time">Day</span>
            <span className="ll_content">Login Count</span>
            <span className="ll_type"></span>
          </li>
          {data?.length ? (
            transformData(data)?.map((item, index) => (
              <li key={index}>
                <span className="ll_time">{item?.day}</span>
                <span className="ll_content">{item?.count}</span>
                <span className="ll_type"></span>
              </li>
            ))
          ) : (
            <li>
              <span className="ll_time">{translate("No logs found!")}</span>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default LoginLogs;
