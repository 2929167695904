import React, { useEffect } from "react";
import { Button, Drawer } from "antd";
import { useState } from "react";
import Logs from "./_logs";
import Activities from "./_activities";
import Billing from "./_billing";
import Preferences from "./_preferences";
import toast, { Toaster } from "react-hot-toast";
import Swal from "sweetalert2";
import { requestHandler } from "../../../shared/utils/helpers";
import { useNavigate } from "react-router-dom";
import translate from "../../../shared/utils/translations";
import LoginLogs from "./_login_logs";

const UserDetailsDrawer = ({
  isUserDetailsDrawer,
  setUserDetailsDrawer,
  selectedUser,
}) => {
  const navigate = useNavigate();
  const [isTab, setTab] = useState("Logs");
  const [user, setUser] = useState({});
  const [logs, setLogs] = useState([]);
  const [loginData, setLoginData] = useState([]);
  const [payments, setPayments] = useState([]);
  const [preferences, setPreferences] = useState([]);

  const getSelectedUser = async (user) => {
    console.log("getSelectedUser", user);
    const res = await requestHandler(
      "get",
      `/admin/userDetails/${user.id}`,
      null,
      true,
      navigate,
      "/"
    );
    console.log(res);
    if (res && res.data) {
      setUser(res.data?.user);
      setLogs(res.data?.logs);
      setPayments(res.data?.payments);
      setPreferences(res.data?.preferences);
      setLoginData(res.data?.loginLogs);
    }
  };

  useEffect(() => {
    console.log("selected user changed", selectedUser);
    selectedUser && getSelectedUser(selectedUser);
  }, [selectedUser]);

  const suspendUser = async () => {
    Swal.fire({
      icon: "warning",
      title: translate("Suspend User"),
      text: translate("Are you sure want to suspend this user?"),
      confirmButtonText: translate("Suspend"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: translate("Please wait.."),
          html: translate("Account suspension has started."),
          timer: 2000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
          },
          willClose: () => {
            toast.success(translate("Account suspension was successful!"));
            Swal.fire(translate("Suspend Successful!"), "", "success");
          },
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            console.log("I was closed by the timer");
          }
        });
      } else if (result.isDenied) {
      }
    });
  };

  return (
    <>
      <Drawer
        title={translate("User Details")}
        placement="right"
        onClose={() => setUserDetailsDrawer(false)}
        open={isUserDetailsDrawer}
        width={"50%"}
        className="user__-drw clnts"
      >
        <>
          <div className="user__wrapper">
            <div className="main__details">
              <div className="main_headr">
                <div className="m__left">
                  <h3>{user?.first_name + " " + user?.last_name}</h3>
                  <span>{user?.email}</span>

                  <div className="usr__tags">
                    <div
                      className={`user__stst ${
                        user?.has_seller_account_na ? "active" : ""
                      } `}
                    >
                      NA
                    </div>
                    <div
                      className={`user__stst ${
                        user?.has_seller_account_eu ? "active" : ""
                      } `}
                    >
                      EU{" "}
                    </div>
                    <div
                      className={`user__stst ${
                        user?.has_seller_account_fe ? "active" : ""
                      } `}
                    >
                      FE{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="main__cards_ar">
                <div className="main_d_card">
                  <h3>{translate("Phone")}</h3>
                  <span>{user?.phone}</span>
                </div>
                <div className="main_d_card">
                  <h3>{translate("Address")}</h3>
                  <span>{user?.address}</span>
                </div>
                <div className="main_d_card">
                  <h3>{translate("Plans")}</h3>
                  <span>{user?.user_tier}</span>
                </div>
                <div className="main_d_card">
                  <h3>{translate("Period")}</h3>
                  <span>{translate("Monthly")}</span>
                </div>

                <div className="main_d_card">
                  <h3>{translate("Registered")}</h3>
                  <span>
                    {user?.created_at?.replace("T", "")?.split(".")[0]}
                  </span>
                </div>
              </div>

              <div className="logs__card">
                <ul className="tab__btns">
                  <li>
                    <button
                      onClick={() => setTab("Logs")}
                      className={`tab_b ${isTab === "Logs" && " active"}`}
                    >
                      {translate("Logs")}
                    </button>
                  </li>

                  <li>
                    <button
                      onClick={() => setTab("Billing")}
                      className={`tab_b ${isTab === "Billing" && " active"}`}
                    >
                      {translate("Billing History")}
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => setTab("Preferences")}
                      className={`tab_b ${
                        isTab === "Preferences" && " active"
                      }`}
                    >
                      {translate("Preferences")}
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => setTab("LoginLogs")}
                      className={`tab_b ${isTab === "LoginLogs" && " active"}`}
                    >
                      {translate("Login Logs")}
                    </button>
                  </li>
                </ul>

                <div className="tabs_area_kk">
                  {isTab === "Logs" && <Logs data={logs} />}
                  {isTab === "Billing" && <Billing data={payments} />}
                  {isTab === "Preferences" && (
                    <Preferences data={preferences} />
                  )}
                  {isTab === "LoginLogs" && <LoginLogs data={loginData} />}
                </div>
              </div>
            </div>
          </div>
        </>
      </Drawer>
    </>
  );
};

export default UserDetailsDrawer;
