import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const LineChart = ({ data }) => {
  const [regionSeries, setRegionSeries] = useState([]);
  const [errorSeries, setErrorSeries] = useState([]);
  const [domainSeries, setDomainSeries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [regionOptions, setRegionOptions] = useState({});
  const [errorOptions, setErrorOptions] = useState({});
  const [domainOptions, setDomainOptions] = useState({});

  const processData = (data) => {
    const logCounts = {
      region: {},
      error: {},
      domain: {},
    };
    const totalCounts = {};
    const datesSet = new Set();

    data.forEach((row) => {
      const region = row.region;
      const error = row.error;
      const domain = row.domain;
      const date = new Date(row.created_at_date).toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      });

      datesSet.add(date);

      if (!logCounts.region[region]) {
        logCounts.region[region] = {};
      }
      if (!logCounts.error[error]) {
        logCounts.error[error] = {};
      }
      if (!logCounts.domain[domain]) {
        logCounts.domain[domain] = {};
      }

      if (!logCounts.region[region][date]) {
        logCounts.region[region][date] = 0;
      }
      if (!logCounts.error[error][date]) {
        logCounts.error[error][date] = 0;
      }
      if (!logCounts.domain[domain][date]) {
        logCounts.domain[domain][date] = 0;
      }

      logCounts.region[region][date] += parseInt(row.log_count, 10);
      logCounts.error[error][date] += parseInt(row.log_count, 10);
      logCounts.domain[domain][date] += parseInt(row.log_count, 10);

      if (!totalCounts[date]) {
        totalCounts[date] = 0;
      }
      totalCounts[date] += parseInt(row.log_count, 10);
    });

    const allDates = Array.from(datesSet).sort(
      (a, b) => new Date(a) - new Date(b)
    );
    setCategories(allDates);

    const createSeries = (counts) => {
      return Object.keys(counts).map((key) => {
        const data = allDates.map((date) => counts[key][date] || 0);
        return {
          name: key,
          data: data,
        };
      });
    };

    setRegionSeries(createSeries(logCounts.region));
    setErrorSeries(createSeries(logCounts.error));
    setDomainSeries(createSeries(logCounts.domain));
  };

  useEffect(() => {
    if (data && data.length > 0) {
      processData(data);
    }
  }, [data]);

  useEffect(() => {
    const commonOptions = {
      chart: {
        type: "line",
        height: 350,
        zoom: {
          enabled: true,
        },
        toolbar: {
          autoSelected: "pan",
          show: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: [2, 1],
      },
      yaxis: {
        min: 0,
        tickAmount: 4,
        opposite: false,
      },
      grid: {
        strokeDashArray: 4,
        position: "back",
        yaxis: {
          lines: {
            show: false,
          },
        },
        xaxis: {
          lines: {
            show: true,
          },
        },
      },
      colors: ["#3B71FE", "#9e9e9e", "#58C27D", "#9c27b0", "#ff9800"],
      fill: {
        gradient: {
          enabled: true,
          opacityFrom: 0.55,
          opacityTo: 0,
        },
      },
      legend: {
        horizontalAlign: "center",
        offsetY: 5,
        fontSize: "10px",
      },
      markers: {
        size: [4, 3, 2],
        strokeColor: ["#fff", "#fff", "#fff"],
        strokeWidth: [3, 2, 1],
        hover: {
          size: 5,
        },
      },
    };

    setRegionOptions({
      ...commonOptions,
      xaxis: {
        categories: categories,
      },
    });

    setErrorOptions({
      ...commonOptions,
      xaxis: {
        categories: categories,
      },
    });

    setDomainOptions({
      ...commonOptions,
      xaxis: {
        categories: categories,
      },
    });
  }, [categories]);

  return (
    <>
      <div id="chart" className="apex_chart pb_20">
        <h2>Logs by Region</h2>
        <ReactApexChart
          options={regionOptions}
          series={regionSeries}
          type="line"
          height={350}
        />
        <h2>Logs by Error Messages</h2>
        <ReactApexChart
          options={errorOptions}
          series={errorSeries}
          type="line"
          height={350}
        />
        <h2>Logs by Domain</h2>
        <ReactApexChart
          options={domainOptions}
          series={domainSeries}
          type="line"
          height={350}
        />
      </div>
    </>
  );
};

export default LineChart;
