import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import UsersTable from "./_users_table";
import NewUserDrawer from "./_new_user_drawer";
import axios from "axios";
import { requestHandler } from "../../../shared/utils/helpers";
import translate from "../../../shared/utils/translations";
import Swal from "sweetalert2";

const Users = ({
  setPage,
  setUserDetailsDrawer,
  selectedUser,
  setSelectedUser,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    setPage("dashboard-admin");
    localStorage.setItem("dashboard-type", "dashboard-admin");
  });

  const [isDrawer, setDrawer] = useState(false);
  const [isNewDrawer, setNewDrawer] = useState(false);
  const [users, setUsers] = useState([]);
  const [buffer, setBuffer] = useState([]); //for search
  const [query, setQuery] = useState("");
  const [type, setType] = useState("all");
  const [tier, setTier] = useState("all");
  const getUsers = async () => {
    const users = await requestHandler(
      "get",
      `/admin/users`,
      null,
      true,
      navigate,
      "/login"
    ).then((res) => res?.data);
    setUsers(users);
    setBuffer(users);
    //updateSelectedUser
    if (selectedUser?.id) {
      const selected = users?.find((item) => item?.id === selectedUser?.id);
      setSelectedUser(selected);
    }
    console.log(users);
  };
  const deleteUser = async (id) => {
    Swal.fire({
      icon: "warning",
      title: translate("Delete?"),
      text: translate("Are you sure you want to delete this?"),
      confirmButtonText: translate("Delete"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const users = await requestHandler(
          "delete",
          `/admin/user/${id}`,
          null,
          true,
          navigate,
          "/login"
        ).then((res) => res?.data);
        setUsers(users);
        console.log(users);
      }
    });
  };
  const sendTokenToUser = (id) => {
    requestHandler(
      "put",
      `/admin/sendTokens/${id}`,
      { token_count: 1000 },
      true,
      navigate,
      "/login"
    ).then((res) => {
      console.log(res);
      getUsers();
    });
  };
  const sendAsinToken = (id) => {
    requestHandler(
      "put",
      `/admin/sendAsinTokens/${id}`,
      { token_count: 1000 },
      true,
      navigate,
      "/login"
    ).then((res) => {
      console.log(res);
      getUsers();
    });
  };
  const resetUserTokens = (id) => {
    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Are you sure about performing this action?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log("resetUserTokens", id);
        requestHandler(
          "delete",
          `/admin/resetTokens/${id}`,
          null,
          true,
          navigate,
          "/login"
        ).then((res) => {
          console.log(res);
          getUsers();
        });
      }
    });
  };
  const toggleTrusted = (id, is_trusted) => {
    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Are you sure about performing this action?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        console.log("resetUserTokens", id);
        requestHandler(
          "put",
          `/admin/toggleUserTrusted/${id}`,
          { is_trusted: !is_trusted },
          true,
          navigate,
          "/login"
        ).then((res) => {
          console.log(res);
          getUsers();
        });
      }
    });
  };
  useEffect(() => {
    getUsers();
  }, []);
  useEffect(() => {
    console.log("selected user changed", selectedUser);
  }, [selectedUser]);
  useEffect(() => {
    if (query) {
      const filteredData = users?.filter((item) => {
        return (
          item?.email?.toLowerCase()?.includes(query?.toLowerCase()) ||
          item?.first_name?.toLowerCase()?.includes(query?.toLowerCase()) ||
          item?.last_name?.toString()?.includes(query?.toLowerCase()) ||
          item?.role?.toLowerCase()?.includes(query?.toLowerCase())
        );
      });
      setBuffer(filteredData);
    } else {
      setBuffer(users);
    }
  }, [query]);
  useEffect(() => {
    if (type === "all") {
      setBuffer(users);
    } else {
      const filteredData = users?.filter((item) => {
        return item?.role?.toLowerCase() === type;
      });
      setBuffer(filteredData);
    }
  }, [type]);
  useEffect(() => {
    if (tier === "all") {
      setBuffer(users);
    } else {
      const filteredData = users?.filter((item) => {
        return item?.tier_id?.toString() === tier;
      });
      setBuffer(filteredData);
    }
  }, [tier]);
  return (
    <>
      <NewUserDrawer
        isNewDrawer={isNewDrawer}
        setNewDrawer={setNewDrawer}
        selectedUser={selectedUser}
        refresh={getUsers}
        sendTokenToUser={sendTokenToUser}
        sendAsinToken={sendAsinToken}
        resetUserTokens={resetUserTokens}
      />

      <div className="outer__inner">
        <div className="onex_wl bg_white">
          <div className="onex_wl__wrapper">
            <div className="upload__head">
              <h2 className="upload__title h2">
                {translate("Users Management")}
              </h2>
              {/* <a
                    className="button-stroke button-small upload__button"
                    href="#!"
                  >
                    More filter
                  </a> */}
            </div>
            <div className="onex_wl__list">
              <div className="keys__enabled d_block">
                <div className="keys__list">
                  <div className="keys__item ss uu">
                    <div className="activity__top ss ">
                      <div className="activity__item activity__item_calendar js-activity-item ">
                        <button
                          className="button-stroke button-small activity__button js-activity-button"
                          onClick={() => setNewDrawer(true)}
                        >
                          <span>{translate("New User")}</span>
                        </button>
                      </div>
                      <div className="right_items">
                        <form className="activity__form">
                          <input
                            className="activity__input"
                            type="text"
                            name="search"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            placeholder={translate("Search")}
                            required=""
                          />
                          <button className="activity__result">
                            <svg className="icon icon-search">
                              <use xlinkHref="#icon-search"></use>
                            </svg>
                          </button>
                        </form>
                        <div className="onex_ts__select">
                          <div className="select__field">
                            <select
                              value={type}
                              onChange={(e) => {
                                setType(e.target.value);
                              }}
                              className="select nice-select  app d_block"
                            >
                              <option value="all">{translate("All")}</option>
                              <option value="admin">
                                {translate("Admin")}
                              </option>
                              <option value="free">
                                {translate("Clients")}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="onex_ts__select">
                          <div className="select__field">
                            <select
                              value={tier}
                              onChange={(e) => {
                                setTier(e.target.value);
                              }}
                              className="select nice-select  app d_block"
                            >
                              <option value="all">{translate("All")}</option>
                              <option value="1">{translate("Free")}</option>
                              <option value="2">{translate("Basic")}</option>
                              <option value="3">{translate("Plus")}</option>
                              <option value="4">{translate("Pro")}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    Found: {buffer?.length}
                    <UsersTable
                      isDrawer={isDrawer}
                      setDrawer={setDrawer}
                      setNewDrawer={setNewDrawer}
                      setUserDetailsDrawer={setUserDetailsDrawer}
                      data={buffer}
                      deleteUser={deleteUser}
                      setSelectedUser={setSelectedUser}
                      toggleTrusted={toggleTrusted}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
