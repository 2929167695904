import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";

import translate from "../../../shared/utils/translations";
import { NavLink, useParams } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { requestHandler } from "../../../shared/utils/helpers";

import EmptyIcon from "../../../../assets/img/empty.png";
// erdem gy
const BarcodeReaderProducts = ({ setPage }) => {
  const { barcode } = useParams();
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  console.log(barcode);
  useEffect(() => {
    setPage("dashboard-client");
    localStorage.setItem("dashboard-type", "dashboard-client");
  });

  useEffect(() => {
    const handleScrollToTop = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener("load", handleScrollToTop);

    return () => window.removeEventListener("load", handleScrollToTop);
  }, []);

  useEffect(() => {
    requestHandler("post", "/productHunter/keyword", {
      keyword: barcode,
      domain: "www.amazon.com",
      asin: null,
    })
      .then((res) => {
        console.log(res.data);
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className="outer__inner">
        <div className="onex_wl bg_white">
          <div className="onex_wl__wrapper readr_err">
            <div className="barcode_readder_wrapper">
              {!isLoading ? (
                <div className="barcode_readr__body">
                  <div className="rdr_b_hdr">
                    {data?.length > 0 ? (
                      <>
                        <h5>{translate("Product Selection")}</h5>

                        <p>
                          {translate(
                            "Click on the product whose results you want to view."
                          )}
                        </p>
                      </>
                    ) : (
                      <>
                        <h5>{translate("Product Not Found!")}</h5>

                        <p>{translate("Try Again or Scan Another Barcode!")}</p>
                      </>
                    )}
                  </div>
                  <div className="rdr_b_middle">
                    <div className="prdcts__care">
                      {data?.length > 0 ? (
                        data?.map((item, index) => {
                          return (
                            <div className="prdt__itm">
                              <div className="prdt__left">
                                <div className="prdtcs__immg">
                                  <img src={item.image} />
                                </div>

                                <div className="prdt__infss">
                                  <div className="onex_ts__prd_h">
                                    {item.title}
                                  </div>
                                </div>
                              </div>
                              <div className="prdt__right">
                                {" "}
                                <NavLink
                                  className="prdt_link"
                                  to={`/product-based-info-manual/${item.asin}`}
                                >
                                  {" "}
                                  <FeatherIcon
                                    icon="chevron-right"
                                    className={"icon f_icn"}
                                  />
                                </NavLink>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="no__data">
                          <span className="empty__tble">
                            <img src={EmptyIcon} />
                          </span>
                          <h4>{translate("Product Not Found!")}</h4>
                          <p>
                            {translate("Try Again or Scan Another Barcode!")}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="barcode_readr__body">
                  <div className="rdr_b_hdr">
                    <h5>{translate("Please wait..")}</h5>

                    <p>{translate("Product Scanning Started!")}</p>
                  </div>
                  <div className="rdr_b_middle ghhj">
                    <img src="/assets/img/barcode_hero.png"></img>
                    <img
                      className="brd_scanng"
                      src="/assets/img/barcode_scanning.png"
                    ></img>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BarcodeReaderProducts;
