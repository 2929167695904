import React, { useEffect, useLayoutEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import { Helmet } from "react-helmet";
import { notify, requestHandler } from "../../shared/utils/helpers";
import translate from "../../shared/utils/translations";
import FeatherIcon from "feather-icons-react";
import Lottie from "react-lottie-player";
import Rocket from "../../../assets/animations/rocket.json";
import Slow from "../../../assets/animations/slow.json";

const Pricing = ({
  setPage,
  isPaymentDrawer,
  setPaymentDrawer,
  paymentData,
  setPaymentData,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    setPage("landing");
    window.addEventListener("scroll", listenScrollEvent);

    return () => window.removeEventListener("scroll", listenScrollEvent);
  });

  const [plan, setPlan] = useState("basic");
  const [tiers, setTiers] = useState([]);
  const [user, setUser] = useState();

  const [letFilterFixed, setFilterFixed] = useState(false);

  const [isWarn, setWarn] = useState(false);
  const [isTier, setIsTier] = useState(null);
  const [isType, setType] = useState("Upgrade plan");
  const [isLoader, setLoader] = useState(false);

  const listenScrollEvent = (event) => {
    if (window.scrollY < 400) {
      return setFilterFixed(false);
    } else if (window.scrollY >= 400) {
      return setFilterFixed(true);
    }
  };
  const getTiers = async () => {
    const res = await requestHandler(
      "get",
      "/tiers",
      null,
      false,
      () => {},
      null
    );
    setTiers(res?.data);
  };
  const getProfile = async () => {
    const res = await requestHandler(
      "get",
      "/profile",
      null,
      false,
      () => {},
      null
    );
    setUser(res?.data);
  };

  useEffect(() => {
    if (tiers) {
      setPaymentData(tiers.find((item) => item.tier_name === plan));
    }
  }, [plan, tiers]);

  useEffect(() => {
    console.log("user changed", user);
  }, [user]);

  useEffect(() => {
    const loggedIn = localStorage.getItem("idToken");
    getTiers();
    loggedIn && getProfile();
  }, []);

  const requestUpdateConfirmation = (tier) => {
    setIsTier(tier);
    setWarn(true);
  };

  const confirmUpdateSubscription = async () => {
    if (!isTier) return;

    setLoader(true);
    setWarn(false);
    try {
      const res = await requestHandler(
        "post",
        "/payment/subscription/update",
        isTier,
        true
      );
      console.log(res);
      if (res?.data?.status === "canceled") {
        setPlan(isTier?.tier_name);
        setPaymentDrawer(true);
      } else if (res?.data?.error) {
        notify(translate("Something went wrong, please try again!"), "error");
      } else {
        notify(
          translate("Subscription update request successful. Check your email!")
        );
        setTimeout(() => {
          navigate("/my-plan");
        }, 2000);
      }
    } catch (err) {
      console.log(err);
      notify(
        translate("Subscription update request failed. Try again later"),
        "error"
      );
    } finally {
      setLoader(false);
    }
  };

  const decideButton = (item, user) => {
    console.log("tier id and user tier id", item?.tier_id, user?.tier_id, item);

    if (!user) {
      return (
        <NavLink className="button view__button" to="/signup">
          {translate("Create free account")}
        </NavLink>
      );
    } else {
      if (item?.tier_price === 0) {
        return <></>;
      } else if (item?.id === user?.tier_id) {
        return (
          <>
            <span
              className="button view__button crr__pln crrnt"
              onClick={() => {}}
            >
              {translate("Current plan")}
            </span>
            <span className="pricing__tag crrnt">
              {translate("Current plan")}
            </span>
          </>
        );
      } else if (item?.id === user?.next_tier_id) {
        return (
          <>
            <span
              className="button view__button crr__pln nxt"
              onClick={() => {}}
            >
              {translate("Next plan")}
            </span>
            <span className="pricing__tag nxtt">{translate("Next plan")}</span>
          </>
        );
      } else if (
        user?.subscription_id === "canceled" ||
        user?.subscription_id === null ||
        user?.next_payment_due === null
      ) {
        return (
          <button
            className="button view__button"
            onClick={() => {
              setPlan(item?.tier_name);
              setPaymentDrawer(true);
            }}
          >
            {translate("Upgrade plan")}
          </button>
        );
      } else {
        if (user?.tier_id < item?.id) {
          return (
            <button
              className="button view__button"
              onClick={() => {
                requestUpdateConfirmation(item);
                setType("Upgrade plan");
              }}
            >
              {translate("Upgrade plan")}
            </button>
          );
        } else {
          return (
            <button
              className="button view__button"
              onClick={() => {
                requestUpdateConfirmation(item);
                setType("Downgrade plan");
              }}
            >
              {translate("Downgrade plan")}
            </button>
          );
        }
      }
    }
  };

  const [isTier1, setTier1] = useState(false);
  const [isTier2, setTier2] = useState(false);
  const [isTier3, setTier3] = useState(false);
  const [isTier4, setTier4] = useState(false);

  return (
    <>
      <Helmet>
        <title>{translate("Pricing")}</title>
        <meta
          name="description"
          content={translate(
            "Ready to have a better Amazon experience? Great way to grow your earnings without spending too much time and money. Do it easily, quickly and practically!"
          )}
        />
      </Helmet>

      <div className={`welcome__mdl_area llm ${isLoader && " show"}`}>
        <div className="welcome__mdl">
          <div className="loader__pay"></div>
        </div>
      </div>

      <div className={`welcome__mdl_area subb_tt ${isWarn && " show"}`}>
        <div className="welcome__mdl">
          <button
            onClick={() => {
              setWarn(false);
            }}
            className={`close_ui_btn`}
          >
            <FeatherIcon icon={"x"} />
          </button>
          <div className="left__ar">
            {isType === "Upgrade plan" ? (
              <Lottie className="ovw_lotenr" loop animationData={Rocket} play />
            ) : (
              <Lottie className="ovw_lotenr" loop animationData={Slow} play />
            )}
          </div>
          <div className="right__ar">
            <h4>{translate(isType)}?</h4>
            <p>
              {isType === "Upgrade plan"
                ? translate(
                    "You are about to upgrade your package! This will provide you with additional features and capacities, offering the opportunity to further grow your business. Once the upgrade process is completed, you will start benefiting from the advantages of your new plan in the next billing period."
                  )
                : translate(
                    "You have chosen to downgrade your package. This action may result in the limitation or removal of some of your features. Please consider the impact of this change on your business."
                  )}
              <br />
              <div className="warn__mesg">
                <FeatherIcon icon={"alert-circle"} />
                <p>
                  {translate(
                    "Changing packages too frequently may cause your account to be suspended!"
                  )}
                </p>
              </div>
              <span>
                {isType === "Upgrade plan"
                  ? translate("Do you wish to proceed?")
                  : translate(
                      "Are you sure you want to switch to a lower package?"
                    )}
              </span>
            </p>
            <div className="link__scf">
              <div className="onex_d__btns">
                <button
                  className="button button-small onex_d__button js-scroll"
                  onClick={() => {
                    confirmUpdateSubscription();
                  }}
                >
                  {translate(isType)}
                </button>
                <button
                  className="button-stroke button-small onex_d__button js-scroll"
                  onClick={() => {
                    setWarn(false);
                  }}
                >
                  {translate("Cancel")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="outer__inner landng gg">
        <div className="section onex_d pos_rel">
          <div className="onex_d__center center">
            <div className="onex_d__head mm">
              <h1 className="onex_d__title h1">
                {translate("Ready to have a better Amazon experience?")}
              </h1>
              <div className="onex_d__info">
                {translate("Plans designed to grow your business.")}
              </div>
              <div className="sub__infs tt">
                <div className="sub__inf">
                  <i>
                    <FeatherIcon icon={"check"} />
                  </i>{" "}
                  {translate("No Credit Card Required")}
                </div>
                <div className="sub__inf">
                  <i>
                    <FeatherIcon icon={"check"} />
                  </i>{" "}
                  {translate("Token Based Free Trial")}
                </div>
              </div>
              <div className="onex_d__badge desk bounce-slide">
                ★★★{" "}
                {translate(
                  "Buy Our $50 or $100 Packages and Get Extra Tokens for FREE!"
                )}{" "}
                ★★★
              </div>
              <div className="onex_d__badge mobile bounce-slide">
                ★★★
                <br />
                {translate(
                  "Buy Our $50 or $100 Packages and Get Extra Tokens for FREE!"
                )}
                <br />
                ★★★
              </div>

              <span className="wow__info">
                {translate(
                  "+2K Comparison Tokens for $50 Package & +5K Comparison Tokens for $100 Package!"
                )}
              </span>
            </div>

            <div className="price__bg">
              <span className="sp1">
                <span className="sp2">
                  <img
                    className="si1"
                    alt=""
                    aria-hidden="true"
                    src="/assets/img/patterns_3.svg"
                  />
                </span>
                <img
                  className="si2"
                  alt="Map"
                  src="/assets/img/patterns_3.svg"
                  decoding="async"
                  data-nimg="intrinsic"
                />
              </span>
            </div>
          </div>
        </div>

        <div className="section app section-mb0 question sec_pricing">
          {/* <div className={`question__center center ${letFilterFixed && " fixed"}`}>
            <div className="question__form">
              <div className="question__fieldset">
                <div className="question__field">
                  <div className="question__variants">
                    <label className="question__radio">
                      <input
                        className="question__input"
                        type="radio"
                        value="monthly"
                        id="monthly"
                        name="bill"
                        onChange={handleChange}
                        defaultChecked
                      />
                      <span className="question__inner">
                        <span className="question__text">Bill monthly</span>
                      </span>
                    </label>
                    <label className="question__radio">
                      <input
                        className="question__input"
                        type="radio"
                        value="yearly"
                        id="yearly"
                        name="bill"
                        onChange={handleChange}
                      />
                      <span className="question__inner">
                        <span className="question__text">Bill yearly</span>
                        <span className="question__text_small">Save 2 months free</span>
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div className="container">
            <div className="pricing_area">
              {tiers
                ?.filter((i) => i.type === "subscription")
                .map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`pricing_box${
                        item.tier_price === 0 ? " free" : ""
                      }

                      ${item.id === 1 && isTier1 ? " details" : ""}
                      ${item.id === 2 && isTier2 ? " details" : ""}
                      ${item.id === 3 && isTier3 ? " details" : ""}
                      ${item.id === 4 && isTier4 ? " details" : ""}
                      
                      `}
                    >
                      <div className="pricing_wrap">
                        <div className="p_badge">
                          <img src={item?.tier_image}></img>
                        </div>
                        <div className="p_title ">
                          {item?.tier_price / 100 == 50 ? (
                            <>
                              <span className="kp_disc_price_area">
                                <s className="kp_big_price">$60</s>
                                <span className="kp_discounted_price">
                                  ${item?.tier_price / 100}
                                </span>
                              </span>
                            </>
                          ) : (
                            <>${item?.tier_price / 100}</>
                          )}

                          <span className="p_subtext">
                            {translate("Monthly")}
                          </span>
                        </div>
                        <div
                          className={`p_sub_title${
                            item.tier_price === 0 ? " free" : ""
                          }`}
                        >
                          {item?.tier_name?.capitalize()}
                        </div>
                        <div className="p_text">
                          {item.id === 1
                            ? translate(
                                "Free Access for Beginners, Basic Features."
                              )
                            : item.id === 2
                            ? translate(
                                "Economical and Powerful for Your Fundamental Needs."
                              )
                            : item.id === 3
                            ? translate(
                                "Boost Your Sales with Advanced Features."
                              )
                            : translate(
                                "Fully Equipped, Superior Performance for Professionals."
                              )}
                          <br />
                          {/* {item?.tier_price === 0 && (
                            <strong>
                              {translate("No credit card required.")}
                            </strong>
                          )} */}
                        </div>

                        {item.id === 1 ? (
                          <>
                            <button
                              onClick={() => setTier1(!isTier1)}
                              className="view_det tier_1"
                            >
                              {isTier1
                                ? translate("Hide Details")
                                : translate("View Details")}{" "}
                              <FeatherIcon icon={"chevron-right"} />
                            </button>
                            <div className="p_list">
                              <ul>
                                <li>
                                  <div className="p_icon tt ii">
                                    <FeatherIcon icon={"alert-circle"} />
                                  </div>
                                  <span className="wrn__itm ll">
                                    {translate("Store registration required!")}
                                    <Tooltip
                                      title={translate(
                                        "North America, Europe, Far East; if you want to action with whichever of these three marketplaces, you need to connect the marketplaces you want to action with. Because the system works using your SP-API."
                                      )}
                                      placement="right"
                                    >
                                      <span className="icn_info"></span>
                                    </Tooltip>
                                  </span>
                                </li>
                                <li>
                                  <div className="p_icon tt ii bh">
                                    <FeatherIcon icon={"star"} />
                                  </div>
                                  <span className="wrn__itm">
                                    {translate(
                                      "Business Prime discounts from amazon.com."
                                    )}
                                  </span>
                                </li>
                                <li>
                                  <div className="p_icon">
                                    <div className="p_img"></div>
                                  </div>
                                  <span>{translate("Asin Spy")}</span>
                                </li>
                                <li>
                                  <div className="p_icon">
                                    <div className="p_img"></div>
                                  </div>
                                  <span>{translate("Asin Extractor")}</span>
                                </li>
                                <li>
                                  <div className="p_icon">
                                    <div className="p_img"></div>
                                  </div>
                                  <span>{translate("Uploader Tool")}</span>
                                </li>

                                <li className="pp_has_ext">
                                  <div className="p_icon">
                                    <div className="p_img"></div>
                                  </div>
                                  <span>
                                    <div className="has_extras">
                                      {translate("Product Based Info")}
                                      <br />
                                      <small className="subtitle_prrc">
                                        <span>
                                          <FeatherIcon
                                            className={"bullet__prc"}
                                            icon={"circle"}
                                          />
                                          {translate("1000 Actions")}
                                        </span>
                                        <span>
                                          <FeatherIcon
                                            className={"bullet__prc"}
                                            icon={"circle"}
                                          />
                                          {translate("For Once")}
                                        </span>
                                      </small>
                                      <ul className="p_extras_area">
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Product Metrics")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("ROI Calculation")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Kepicker Calculator")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Keepa Data")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Stock Fetcher")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Fees Comparison")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Additional Infos")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Currency Converter")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Dimensional Measurement")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate(
                                            "Add Product to Favorites"
                                          )}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate(
                                            "Take Notes About the Product"
                                          )}
                                        </li>
                                      </ul>
                                    </div>
                                  </span>
                                </li>

                                <li className="pp_has_ext off">
                                  <div className="p_icon">
                                    <FeatherIcon icon={"x"} />
                                  </div>
                                  <span>
                                    <div className="has_extras">
                                      {translate("Buybox Information")}
                                      <br />
                                      <ul className="p_extras_area">
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate(
                                            "Buybox MIN. for Kepicker Calculator."
                                          )}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate(
                                            "Buybox MAX. for Kepicker Calculator."
                                          )}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate(
                                            "Buybox AVG. for Kepicker Calculator."
                                          )}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate(
                                            "Extra Buybox Price for Keepa Data"
                                          )}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate(
                                            "30/60/90 Days Seller Buybox Percentage Information"
                                          )}
                                        </li>
                                      </ul>
                                    </div>
                                  </span>
                                </li>
                                <li>
                                  <div className="p_icon">
                                    <div className="p_img"></div>
                                  </div>
                                  <span>
                                    <div className="has_extras">
                                      {translate("Compare Marketplaces")}
                                      <br />
                                      <small className="subtitle_prrc">
                                        <span>
                                          <FeatherIcon
                                            className={"bullet__prc"}
                                            icon={"circle"}
                                          />
                                          {translate("1000 Products")}
                                        </span>
                                        <span>
                                          <FeatherIcon
                                            className={"bullet__prc"}
                                            icon={"circle"}
                                          />
                                          {translate("For Once")}
                                        </span>
                                      </small>
                                    </div>
                                  </span>
                                </li>
                                <li className="off">
                                  <div className="p_icon">
                                    <FeatherIcon icon={"x"} />
                                  </div>
                                  <span>{translate("Paid Extra Token")}</span>
                                </li>
                                <li className="off">
                                  <div className="p_icon">
                                    <FeatherIcon icon={"x"} />
                                  </div>
                                  <span>
                                    {translate("Store Protection")}
                                    <Tooltip
                                      title={translate(
                                        "You can protect your store with this feature. Our AsinSpy tool won't be available for your seller's market!"
                                      )}
                                      placement="right"
                                    >
                                      <span className="icn_info"></span>
                                    </Tooltip>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </>
                        ) : item.id === 2 ? (
                          <>
                            <button
                              onClick={() => setTier2(!isTier2)}
                              className="view_det tier_1"
                            >
                              {isTier2
                                ? translate("Hide Details")
                                : translate("View Details")}{" "}
                              <FeatherIcon icon={"chevron-right"} />
                            </button>
                            <div className="p_list">
                              <ul>
                                <li>
                                  <div className="p_icon tt ii">
                                    <FeatherIcon icon={"alert-circle"} />
                                  </div>
                                  <span className="wrn__itm ll">
                                    {translate("Store registration required!")}
                                    <Tooltip
                                      title={translate(
                                        "North America, Europe, Far East; if you want to action with whichever of these three marketplaces, you need to connect the marketplaces you want to action with. Because the system works using your SP-API."
                                      )}
                                      placement="right"
                                    >
                                      <span className="icn_info"></span>
                                    </Tooltip>
                                  </span>
                                </li>
                                <li>
                                  <div className="p_icon tt ii bh">
                                    <FeatherIcon icon={"star"} />
                                  </div>
                                  <span className="wrn__itm">
                                    {translate(
                                      "Business Prime discounts from amazon.com."
                                    )}
                                  </span>
                                </li>
                                <li>
                                  <div className="p_icon">
                                    <div className="p_img"></div>
                                  </div>
                                  <span>{translate("Asin Spy")}</span>
                                </li>
                                <li>
                                  <div className="p_icon">
                                    <div className="p_img"></div>
                                  </div>
                                  <span>{translate("Asin Extractor")}</span>
                                </li>
                                <li>
                                  <div className="p_icon">
                                    <div className="p_img"></div>
                                  </div>
                                  <span>{translate("Uploader Tool")}</span>
                                </li>

                                <li className="pp_has_ext">
                                  <div className="p_icon">
                                    <div className="p_img"></div>
                                  </div>
                                  <span>
                                    <div className="has_extras">
                                      {translate("Product Based Info")}
                                      <br />
                                      <small className="subtitle_prrc">
                                        <span>
                                          <FeatherIcon
                                            className={"bullet__prc"}
                                            icon={"circle"}
                                          />
                                          {translate("1000 Actions")}
                                        </span>
                                        <span>
                                          <FeatherIcon
                                            className={"bullet__prc"}
                                            icon={"circle"}
                                          />
                                          <FeatherIcon
                                            className={"bullet__prc"}
                                            icon={"circle"}
                                          />
                                          {translate("Daily")}
                                        </span>
                                      </small>
                                      <ul className="p_extras_area">
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Product Metrics")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("ROI Calculation")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Kepicker Calculator")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Keepa Data")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Stock Fetcher")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Fees Comparison")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Additional Infos")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Currency Converter")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Dimensional Measurement")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate(
                                            "Add Product to Favorites"
                                          )}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate(
                                            "Take Notes About the Product"
                                          )}
                                        </li>
                                      </ul>
                                    </div>
                                  </span>
                                </li>

                                <li className="pp_has_ext off">
                                  <div className="p_icon">
                                    <FeatherIcon icon={"x"} />
                                  </div>
                                  <span>
                                    <div className="has_extras">
                                      {translate("Buybox Information")}
                                      <br />
                                      <ul className="p_extras_area">
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate(
                                            "Buybox MIN. for Kepicker Calculator."
                                          )}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate(
                                            "Buybox MAX. for Kepicker Calculator."
                                          )}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate(
                                            "Buybox AVG. for Kepicker Calculator."
                                          )}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate(
                                            "Extra Buybox Price for Keepa Data"
                                          )}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate(
                                            "30/60/90 Days Seller Buybox Percentage Information"
                                          )}
                                        </li>
                                      </ul>
                                    </div>
                                  </span>
                                </li>
                                <li>
                                  <div className="p_icon">
                                    <div className="p_img"></div>
                                  </div>
                                  <span>
                                    <div className="has_extras">
                                      {translate("Compare Marketplaces")}
                                      <br />
                                      <small className="subtitle_prrc">
                                        <span>
                                          <FeatherIcon
                                            className={"bullet__prc"}
                                            icon={"circle"}
                                          />
                                          {translate("1000 Products")}
                                        </span>
                                        <span>
                                          <FeatherIcon
                                            className={"bullet__prc"}
                                            icon={"circle"}
                                          />
                                          <FeatherIcon
                                            className={"bullet__prc"}
                                            icon={"circle"}
                                          />
                                          {translate("Monthly")}
                                        </span>
                                      </small>
                                    </div>
                                  </span>
                                </li>
                                <li>
                                  <div className="p_icon">
                                    <div className="p_img"></div>
                                  </div>
                                  <span>{translate("Paid Extra Token")}</span>
                                </li>
                                <li className="off">
                                  <div className="p_icon">
                                    <FeatherIcon icon={"x"} />
                                  </div>
                                  <span>
                                    {translate("Store Protection")}
                                    <Tooltip
                                      title={translate(
                                        "You can protect your store with this feature. Our AsinSpy tool won't be available for your seller's market!"
                                      )}
                                      placement="right"
                                    >
                                      <span className="icn_info"></span>
                                    </Tooltip>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </>
                        ) : item.id === 3 ? (
                          <>
                            <span className="popul_tag">
                              {translate("Most Popular")}
                            </span>
                            <button
                              onClick={() => setTier3(!isTier3)}
                              className="view_det tier_1"
                            >
                              {isTier3
                                ? translate("Hide Details")
                                : translate("View Details")}{" "}
                              <FeatherIcon icon={"chevron-right"} />
                            </button>
                            <div className="p_list">
                              <ul>
                                <li>
                                  <div className="p_icon tt ii">
                                    <FeatherIcon icon={"alert-circle"} />
                                  </div>
                                  <span className="wrn__itm ll">
                                    {translate("Store registration required!")}
                                    <Tooltip
                                      title={translate(
                                        "North America, Europe, Far East; if you want to action with whichever of these three marketplaces, you need to connect the marketplaces you want to action with. Because the system works using your SP-API."
                                      )}
                                      placement="right"
                                    >
                                      <span className="icn_info"></span>
                                    </Tooltip>
                                  </span>
                                </li>
                                <li>
                                  <div className="p_icon tt ii bh">
                                    <FeatherIcon icon={"star"} />
                                  </div>
                                  <span className="wrn__itm">
                                    {translate(
                                      "Business Prime discounts from amazon.com."
                                    )}
                                  </span>
                                </li>
                                <li>
                                  <div className="p_icon">
                                    <div className="p_img"></div>
                                  </div>
                                  <span>{translate("Asin Spy")}</span>
                                </li>
                                <li>
                                  <div className="p_icon">
                                    <div className="p_img"></div>
                                  </div>
                                  <span>{translate("Asin Extractor")}</span>
                                </li>
                                <li>
                                  <div className="p_icon">
                                    <div className="p_img"></div>
                                  </div>
                                  <span>{translate("Uploader Tool")}</span>
                                </li>

                                <li className="pp_has_ext">
                                  <div className="p_icon">
                                    <div className="p_img"></div>
                                  </div>
                                  <span>
                                    <div className="has_extras">
                                      {translate("Product Based Info")}
                                      <br />
                                      <small className="subtitle_prrc">
                                        <span>
                                          <FeatherIcon
                                            className={"bullet__prc"}
                                            icon={"circle"}
                                          />
                                          {translate("1000 Actions")}
                                        </span>
                                        <span>
                                          <FeatherIcon
                                            className={"bullet__prc"}
                                            icon={"circle"}
                                          />
                                          <FeatherIcon
                                            className={"bullet__prc"}
                                            icon={"circle"}
                                          />
                                          {translate("Daily")}
                                        </span>
                                      </small>
                                      <ul className="p_extras_area">
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Product Metrics")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("ROI Calculation")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Kepicker Calculator")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Keepa Data")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Stock Fetcher")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Fees Comparison")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Additional Infos")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Currency Converter")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Dimensional Measurement")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate(
                                            "Add Product to Favorites"
                                          )}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate(
                                            "Take Notes About the Product"
                                          )}
                                        </li>
                                      </ul>
                                    </div>
                                  </span>
                                </li>

                                <li className="pp_has_ext">
                                  <div className="p_icon">
                                    <div className="p_img"></div>
                                  </div>
                                  <span>
                                    <div className="has_extras">
                                      {translate("Buybox Information")}
                                      <br />
                                      <ul className="p_extras_area">
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate(
                                            "Buybox MIN. for Kepicker Calculator."
                                          )}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate(
                                            "Buybox MAX. for Kepicker Calculator."
                                          )}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate(
                                            "Buybox AVG. for Kepicker Calculator."
                                          )}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate(
                                            "Extra Buybox Price for Keepa Data"
                                          )}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate(
                                            "30/60/90 Days Seller Buybox Percentage Information"
                                          )}
                                        </li>
                                      </ul>
                                    </div>
                                  </span>
                                </li>
                                <li>
                                  <div className="p_icon">
                                    <div className="p_img"></div>
                                  </div>
                                  <span>
                                    <div className="has_extras">
                                      {translate("Compare Marketplaces")}
                                      <br />
                                      <small className="subtitle_prrc">
                                        <span>
                                          <FeatherIcon
                                            className={"bullet__prc"}
                                            icon={"circle"}
                                          />
                                          <FeatherIcon
                                            className={"bullet__prc"}
                                            icon={"circle"}
                                          />
                                          <FeatherIcon
                                            className={"bullet__prc"}
                                            icon={"circle"}
                                          />
                                          {translate("10K Products")}
                                        </span>
                                        <span>
                                          <FeatherIcon
                                            className={"bullet__prc"}
                                            icon={"circle"}
                                          />
                                          <FeatherIcon
                                            className={"bullet__prc"}
                                            icon={"circle"}
                                          />
                                          {translate("Monthly")}
                                        </span>
                                      </small>
                                    </div>
                                  </span>
                                </li>
                                <li>
                                  <div className="p_icon">
                                    <div className="p_img"></div>
                                  </div>
                                  <span>{translate("Paid Extra Token")}</span>
                                </li>
                                <li className="off">
                                  <div className="p_icon">
                                    <FeatherIcon icon={"x"} />
                                  </div>
                                  <span>
                                    {translate("Store Protection")}
                                    <Tooltip
                                      title={translate(
                                        "You can protect your store with this feature. Our AsinSpy tool won't be available for your seller's market!"
                                      )}
                                      placement="right"
                                    >
                                      <span className="icn_info"></span>
                                    </Tooltip>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </>
                        ) : (
                          <>
                            <button
                              onClick={() => setTier4(!isTier4)}
                              className="view_det tier_1"
                            >
                              {isTier4
                                ? translate("Hide Details")
                                : translate("View Details")}{" "}
                              <FeatherIcon icon={"chevron-right"} />
                            </button>
                            <div className="p_list">
                              <ul>
                                <li>
                                  <div className="p_icon tt ii">
                                    <FeatherIcon icon={"alert-circle"} />
                                  </div>
                                  <span className="wrn__itm ll">
                                    {translate("Store registration required!")}
                                    <Tooltip
                                      title={translate(
                                        "North America, Europe, Far East; if you want to action with whichever of these three marketplaces, you need to connect the marketplaces you want to action with. Because the system works using your SP-API."
                                      )}
                                      placement="right"
                                    >
                                      <span className="icn_info"></span>
                                    </Tooltip>
                                  </span>
                                </li>
                                <li>
                                  <div className="p_icon tt ii bh">
                                    <FeatherIcon icon={"star"} />
                                  </div>
                                  <span className="wrn__itm">
                                    {translate(
                                      "Business Prime discounts from amazon.com."
                                    )}
                                  </span>
                                </li>
                                <li>
                                  <div className="p_icon">
                                    <div className="p_img"></div>
                                  </div>
                                  <span>{translate("Asin Spy")}</span>
                                </li>
                                <li>
                                  <div className="p_icon">
                                    <div className="p_img"></div>
                                  </div>
                                  <span>{translate("Asin Extractor")}</span>
                                </li>
                                <li>
                                  <div className="p_icon">
                                    <div className="p_img"></div>
                                  </div>
                                  <span>{translate("Uploader Tool")}</span>
                                </li>

                                <li className="pp_has_ext">
                                  <div className="p_icon">
                                    <div className="p_img"></div>
                                  </div>
                                  <span>
                                    <div className="has_extras">
                                      {translate("Product Based Info")}
                                      <br />
                                      <small className="subtitle_prrc">
                                        <span>
                                          <FeatherIcon
                                            className={"bullet__prc"}
                                            icon={"circle"}
                                          />
                                          <FeatherIcon
                                            className={"bullet__prc"}
                                            icon={"circle"}
                                          />
                                          {translate("2000 Actions")}
                                        </span>
                                        <span>
                                          <FeatherIcon
                                            className={"bullet__prc"}
                                            icon={"circle"}
                                          />
                                          <FeatherIcon
                                            className={"bullet__prc"}
                                            icon={"circle"}
                                          />
                                          {translate("Daily")}
                                        </span>
                                      </small>
                                      <ul className="p_extras_area">
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Product Metrics")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("ROI Calculation")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Kepicker Calculator")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Keepa Data")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Stock Fetcher")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Fees Comparison")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Additional Infos")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Currency Converter")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate("Dimensional Measurement")}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate(
                                            "Add Product to Favorites"
                                          )}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate(
                                            "Take Notes About the Product"
                                          )}
                                        </li>
                                      </ul>
                                    </div>
                                  </span>
                                </li>

                                <li className="pp_has_ext">
                                  <div className="p_icon">
                                    <div className="p_img"></div>
                                  </div>
                                  <span>
                                    <div className="has_extras">
                                      {translate("Buybox Information")}
                                      <br />
                                      <ul className="p_extras_area">
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate(
                                            "Buybox MIN. for Kepicker Calculator."
                                          )}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate(
                                            "Buybox MAX. for Kepicker Calculator."
                                          )}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate(
                                            "Buybox AVG. for Kepicker Calculator."
                                          )}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate(
                                            "Extra Buybox Price for Keepa Data"
                                          )}
                                        </li>
                                        <li>
                                          <FeatherIcon
                                            icon={"corner-down-right"}
                                          />{" "}
                                          {translate(
                                            "30/60/90 Days Seller Buybox Percentage Information"
                                          )}
                                        </li>
                                      </ul>
                                    </div>
                                  </span>
                                </li>
                                <li>
                                  <div className="p_icon">
                                    <div className="p_img"></div>
                                  </div>
                                  <span>
                                    <div className="has_extras">
                                      {translate("Compare Marketplaces")}
                                      <br />
                                      <small className="subtitle_prrc">
                                        <span>
                                          <FeatherIcon
                                            className={"bullet__prc"}
                                            icon={"circle"}
                                          />
                                          <FeatherIcon
                                            className={"bullet__prc"}
                                            icon={"circle"}
                                          />
                                          <FeatherIcon
                                            className={"bullet__prc"}
                                            icon={"circle"}
                                          />
                                          <FeatherIcon
                                            className={"bullet__prc"}
                                            icon={"circle"}
                                          />
                                          {translate("25K Products")}
                                        </span>
                                        <span>
                                          <FeatherIcon
                                            className={"bullet__prc"}
                                            icon={"circle"}
                                          />
                                          <FeatherIcon
                                            className={"bullet__prc"}
                                            icon={"circle"}
                                          />
                                          {translate("Monthly")}
                                        </span>
                                      </small>
                                    </div>
                                  </span>
                                </li>
                                <li>
                                  <div className="p_icon">
                                    <div className="p_img"></div>
                                  </div>
                                  <span>{translate("Paid Extra Token")}</span>
                                </li>
                                <li>
                                  <div className="p_icon">
                                    <div className="p_img"></div>
                                  </div>
                                  <span>
                                    {translate("Store Protection")}
                                    <Tooltip
                                      title={translate(
                                        "You can protect your store with this feature. Our AsinSpy tool won't be available for your seller's market!"
                                      )}
                                      placement="right"
                                    >
                                      <span className="icn_info"></span>
                                    </Tooltip>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </>
                        )}

                        {/* <div className="p_list">
                        <ul>
                          {item?.tier_description?.split(", ").map((item, index) => {
                            return (
                              <li>
                                <div className="p_icon">
                                  <div className="p_img"></div>
                                </div>
                                <span>{item}</span>
                              </li>
                            );
                          })}
                        </ul>
                      </div> */}
                        <div className="p_button">
                          {decideButton(item, user)}

                          {/* {item?.tier_price === 0 ? (
                          <NavLink className="button view__button" to="/signup">
                            Create free account
                          </NavLink>
                        ) : (
                          <button
                            className="button view__button"
                            onClick={() => {
                              setPlan(item?.tier_name);
                              setPaymentDrawer(true);
                            }}
                          >
                            Choose
                          </button>
                        )} */}
                        </div>
                      </div>
                    </div>
                  );
                })}

              {/* Free */}
            </div>
          </div>

          <div className="container add_price_pavkages">
            <div className="services__center center">
              <div className="services__head prc">
                <h2 className="services__title h2">
                  {translate("Let's Grow Together")}
                </h2>
                <div className="stage">
                  {translate(
                    "Ready to take your business to the next level? Contact us today to enhance your competitive edge in the marketplace with Kepicker."
                  )}
                </div>
              </div>
            </div>
            <div className="pricing_area">
              <div className="pricing_box">
                <div className="pricing_wrap">
                  <div className="p_badge ent">
                    <img src="/assets/img/enterprice.svg"></img>
                  </div>
                  <div className="p_title">Enterprise</div>
                  <div className="p_sub_title">{translate("Get in Touch")}</div>
                  <div className="p_text">
                    {translate(
                      "We are pleased to offer tailored solutions for corporate needs with our new Enterprise package. Designed to leverage the full benefits of Kepicker for businesses looking to elevate their Amazon selling experience to the next level, our Enterprise package is ideal for large-scale enterprises and high-volume sellers seeking to meet unique requirements."
                    )}
                  </div>
                  <div className="p_button">
                    <a
                      className="button view__button"
                      href="mailto:info@kepicker.com"
                    >
                      {translate("Contact Us")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section services">
          <div className="services__center center">
            <div className="services__head prc">
              <div className="p_badge">
                <img src="/assets/img/bolt.svg"></img>
              </div>
              <h2 className="services__title h2">
                {translate("Looking for a custom solution?")}
              </h2>
              <div className="stage">
                {translate(
                  "Or have an idea? Let's see if we can work together."
                )}
              </div>
              <a
                href="mailto:info@onexyazilim.com"
                className="button services__button"
              >
                {translate("Let's code")}
              </a>
            </div>
          </div>
        </div>

        <div className="section services">
          <div className="services__center center">
            <div className="services__head prc">
              <div className="p_badge">
                <img src="/assets/img/serious.svg"></img>
              </div>
              <h2 className="services__title h2">
                {translate("Not sure which pricing plan to pick?")}
              </h2>
              <div className="stage">
                {translate(
                  "Let our product specialists help you pick a perfect plan according to your team, requirements and use case."
                )}
              </div>
              <a
                href="mailto:info@kepicker.com"
                className="button services__button"
              >
                {translate("Let’s Talk")}
              </a>
            </div>
          </div>
        </div>

        <div className="section view">
          <div className="view__center center promo_sc">
            <div className="view__head">
              <div className="view__details">
                <div className="view__stage">
                  {translate(
                    "The newest software in class, design and business value."
                  )}
                </div>
                <h2 className="view__title h2">
                  {translate("Start growing your earnings!")}
                </h2>
                <p className="onex_p__info info">
                  {translate(
                    "Great way to grow your earnings without spending too much time and money."
                  )}
                </p>
              </div>
              <NavLink className="button view__button" to="/about">
                {translate("Learn More")}
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
