import React, { useState, useEffect, useContext } from "react";

import { NavLink, useNavigate, useParams } from "react-router-dom";
import Input from "../../common/form_elements/input";
import { signUp } from "../../shared/utils/auth";
import { notify } from "../../shared/utils/helpers";
import { emailChecker, passwordChecker } from "../../shared/utils/helpers";
import { Helmet } from "react-helmet";
import UserContext from "../../shared/utils/userContext";
import translate from "../../shared/utils/translations";
import Swal from "sweetalert2";

const Register = ({ setPage }) => {
  useEffect(() => {
    setPage("auth");
    currentLanguage(localStorage.getItem("lang") || "EN");
  });
  let navigate = useNavigate();
  let { code } = useParams();

  const [formData, setFormData] = useState({});
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const loggedInUser = useContext(UserContext);
  const [currentLang, currentLanguage] = useState({});

  const submit = async () => {
    if (
      !formData.password ||
      !formData.renewPassword ||
      !formData.email ||
      !formData.firstname ||
      !formData.lastname
    ) {
      setErrorMessage(translate("Please fill all fields!"));
      return;
    }
    if (formData.password !== formData.renewPassword) {
      setErrorMessage(translate("Passwords do not match!"));
      return;
    }
    if (formData.password.length < 8) {
      setErrorMessage(
        translate("Password must be at least 8 characters long!")
      );
      return;
    }
    //password must contain at least one number
    if (!/\d/.test(formData.password)) {
      setErrorMessage(translate("Password must contain at least one number!"));
      return;
    }
    //password must contain at least one letter
    if (!/[a-zA-Z]/.test(formData.password)) {
      setErrorMessage(translate("Password must contain at least one letter!"));
      return;
    }
    if (formData.phone && formData.phone.length < 10) {
      setErrorMessage(
        translate("Phone number must be at least 10 characters long!")
      );
      return;
    }
    setBtnLoading(true);
    const emailCheckerResult = emailChecker(formData.email);
    const passwordCheckerResult = passwordChecker(
      formData.password,
      formData.renewPassword
    );
    if (emailCheckerResult !== true) {
      setErrorMessage(emailCheckerResult);
      return;
    }
    if (passwordCheckerResult !== true) {
      setErrorMessage(passwordCheckerResult);
      return;
    }
    if (emailCheckerResult && passwordCheckerResult) {
      const user = await signUp(
        formData.email,
        formData.password,
        formData.email,
        formData.firstname,
        formData.lastname,
        code,
        formData.phone
      );
      if (user) {
        notify(
          translate("Please check your email for verification!"),
          "success"
        );
        setBtnLoading(false);
        console.log(user);

        Swal.fire({
          iconHtml: '<img class="msg___icnn" src="/assets/img/message.svg">',
          allowOutsideClick: false,
          title: translate("Please verify your email!"),
          text: translate(
            "You're almost there! We sent an verification email to the email address you registered with. If you don't see it, you may need to check your spam folder."
          ),
          confirmButtonText: translate("Go to Login page"),
          cancelButtonText: translate("Problem? Let's start again."),
          showCancelButton: true,
          customClass: {
            container: "msg___cnt",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/login");
          } else {
            window.location.reload(false);
          }
        });
      } else {
        setErrorMessage(translate("Something went wrong, please try again!"));
        setBtnLoading(false);
      }
    }
  };
  const onChange = (e, name) => {
    setFormData({ ...formData, [name]: e });
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  useEffect(() => {
    console.log(loggedInUser);
    if (loggedInUser) {
      if (loggedInUser?.role === "admin") {
        navigate("/admin-dashboard");
      } else {
        navigate("/client-dashboard");
      }
    }
  }, [loggedInUser]);

  return (
    <>
      <Helmet>
        <title>{translate("Register (Affilliate)")}</title>
      </Helmet>
      <div className="outer__inner auth promo_rr">
        <div className="login">
          <div className="login__col bgb d_none"></div>
          <div className="login__col regstr">
            {code === "comfyplus" ? (
              <>
                <div className="flex__logos">
                  <NavLink className="login__logo" target="blank" to="/home">
                    <img src="/assets/img/logo-dark4.png" alt="" />
                    <img src="/assets/img/logo-dark4.png" alt="" />
                  </NavLink>
                  <a
                    rel="nofollow noopener"
                    className="login__logo"
                    target="blank"
                    href="https://www.comfyship.com/"
                  >
                    <img src="/assets/img/lansman-logos/comfyship.png" alt="" />
                    <img src="/assets/img/lansman-logos/comfyship.png" alt="" />
                  </a>
                </div>
                <div className="login__head">
                  <span>
                    Kepicker ve Comfyship Partnerliğinde; 16 Mart'ta Saat
                    22.00'de Büyük Lansman! Fırsatları Kaçırma, Online
                    Arbitrajda Yeni Bir Döneme Hazır Ol!
                  </span>
                </div>
              </>
            ) : code === "ursdan" ? (
              <>
                {" "}
                <div className="flex__logos">
                  <NavLink className="login__logo" target="blank" to="/home">
                    <img src="/assets/img/logo-dark4.png" alt="" />
                    <img src="/assets/img/logo-dark4.png" alt="" />
                  </NavLink>
                  <a
                    rel="nofollow noopener"
                    className="login__logo"
                    target="blank"
                    href="https://ursdanismanlik.com/"
                  >
                    <img
                      src="https://ursdanismanlik.com/wp-content/uploads/2023/10/WhatsApp_Gorsel_2023-10-26_saat_17.53.07_6fec6702-removebg-preview-e1698795400533.png"
                      alt=""
                    />
                    <img
                      src="https://ursdanismanlik.com/wp-content/uploads/2023/10/WhatsApp_Gorsel_2023-10-26_saat_17.53.07_6fec6702-removebg-preview-e1698795400533.png"
                      alt=""
                      style={{ width: "75px" }}
                    />
                  </a>
                </div>
                <div className="login__head">
                  <span>
                    URS Danışmanlık ve Kepicker İş Birliğine Özel Kodunuzla
                    Ekstra Token Kazanın!
                  </span>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="login__wrap">
              <div className="registration">
                <div className="registration__form">
                  <div className="registration__top">
                    <h3 className="registration__title h3">
                      {translate("Hoşgeldiniz!")}
                    </h3>
                  </div>
                  {/* <div className="registration__info">Or continue with email</div> */}
                  <div className="registration__fieldset">
                    <div className="flex_inps">
                      <Input
                        id="firstname"
                        name="firstname"
                        label={translate("First Name")}
                        type="text"
                        placeholder={translate("First Name")}
                        onChange={onChange}
                      />
                      <Input
                        id="lastname"
                        name="lastname"
                        label={translate("Last Name")}
                        type="text"
                        placeholder={translate("Last Name")}
                        onChange={onChange}
                      />
                    </div>
                    <Input
                      id="email"
                      name="email"
                      label="Email"
                      type="email"
                      placeholder="Email address"
                      onChange={onChange}
                    />
                    <Input
                      id="phone"
                      name="phone"
                      label={translate("Phone")}
                      type="tel"
                      placeholder={translate("Phone")}
                      onChange={onChange}
                    />
                    <Input
                      id="new-password"
                      name="password"
                      label={translate("Password")}
                      type="password"
                      placeholder={translate("Enter your password")}
                      isPass={true}
                      onChange={onChange}
                    />
                    <Input
                      id="renew-password"
                      name="renewPassword"
                      label={translate("Repeat New Password")}
                      type="password"
                      onChange={onChange}
                      placeholder={translate("Repeat your new password")}
                    />

                    {errorMessage && (
                      <p className="entry__error">{errorMessage}</p>
                    )}
                    <div className="registration__info">
                      {currentLang === "TR" ? (
                        <>
                          Giriş yaparak veya kaydolarak 18 yaşndan büyük
                          olduğunu ve{" "}
                          <NavLink
                            className="checkbox__link"
                            to="/privacy-policy"
                          >
                            {translate("Privacy Policy")}
                          </NavLink>{" "}
                          kabul etmiş olursun
                        </>
                      ) : currentLang === "DE" ? (
                        <>
                          Mit der Anmeldung stimme ich der{" "}
                          <NavLink
                            className="checkbox__link"
                            to="/privacy-policy"
                          >
                            {translate("Privacy Policy")}
                          </NavLink>{" "}
                          zu, dass ich mindestens 18 Jahre alt bin
                        </>
                      ) : (
                        <>
                          By signing up I agree that I’m 18 years of age or
                          older, to the{" "}
                          <NavLink
                            className="checkbox__link"
                            to="/privacy-policy"
                          >
                            {translate("Privacy Policy")}
                          </NavLink>
                        </>
                      )}
                      .
                    </div>
                  </div>
                  <button
                    className="button registration__button cursorp"
                    onClick={submit}
                  >
                    {translate("Sign up")}{" "}
                    {isBtnLoading && <span className="btn-spinner"></span>}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <img
            alt="auth-world-screen"
            src="https://partners.poweredbypercent.com/13c4f10f45b00276.png"
            className="AuthBackground_image__IINQD"
          ></img>
        </div>
      </div>
    </>
  );
};

export default Register;
