import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import AsinsTable from "./_asins_table";
import FeatherIcon from "feather-icons-react";
import ComparsionDrawer from "../comparison/comparison_drawer";
import {
  checkIfInputIsAsin,
  downloadAsinListExcel,
  requestHandler,
  splitArrayIntoChunks,
} from "../../../../shared/utils/helpers";
import axios from "axios";
import translate from "../../../../shared/utils/translations";
import { InboxOutlined } from "@ant-design/icons";
import { Modal, Input, Upload, Button } from "antd";
import { Helmet } from "react-helmet";
const ExcelJS = require("exceljs");

const SavedAsins = ({ setPage }) => {
  useEffect(() => {
    setPage("dashboard-client");
    localStorage.setItem("dashboard-type", "dashboard-client");
  });

  const [tab, setTab] = useState("All");
  const [isComparisonDrawer, setComparisonDrawer] = useState(false);
  const [asinLists, setAsinLists] = useState([]);
  const [bufferAsinLists, setBufferAsinLists] = useState([]);
  const [query, setQuery] = useState("");
  const [asinListInfo, setAsinListInfo] = useState({});
  const [selectedAsinLists, setSelectedAsinLists] = useState([]);
  const [selectedAsinList, setSelectedAsinList] = useState({});
  const [selectedAsinListData, setSelectedAsinListData] = useState([]);
  const [isDownloadActive, setDownloadActive] = useState(false);

  const filterByTab = (data, tab) => {
    if (tab === "All") {
      setBufferAsinLists(data);
    } else {
      if (tab === "Extractor") {
        setBufferAsinLists(data?.filter((item) => item.origin === "Page"));
      } else if (tab === "SPY") {
        setBufferAsinLists(
          data?.filter(
            (item) => item.origin === "Search" || item.origin === "Seller"
          )
        );
      } else if (tab === "Imported") {
        setBufferAsinLists(data?.filter((item) => item.origin === "Import"));
      } else if (tab === "Merged") {
        setBufferAsinLists(data?.filter((item) => item.origin === "Merged"));
      }
    }
  };
  const filterByQuery = (query) => {
    setBufferAsinLists(
      asinLists.filter(
        (item) =>
          item?.name?.toLowerCase()?.includes(query?.toLowerCase()) ||
          item?.preference_name
            ?.toLowerCase()
            ?.includes(query?.toLowerCase()) ||
          item?.origin?.toLowerCase()?.includes(query?.toLowerCase()) ||
          item?.seller_id?.toLowerCase()?.includes(query?.toLowerCase()) ||
          item?.status?.toLowerCase()?.includes(query?.toLowerCase())
      )
    );
  };
  const getAsinLists = async () => {
    const data = await requestHandler(
      "get",
      `/asinLists`,
      null,
      null,
      null,
      null
    ).then((res) => {
      setAsinListInfo({
        total: res?.data?.asin_lists?.length,
        totalAsins: res?.data?.asin_lists?.reduce(
          (acc, item) => acc + item.asin_count,
          0
        ),
        asinToken: res?.data?.asin_count,
        comparisonToken: res?.data?.token_count,
      });
      //check if there is an asin list with status "processing" and if so, wait 5 seconds and call the function again
      if (
        res?.data?.asin_lists?.filter((i) => i.status === "processing").length >
        0
      ) {
        console.log("Processing asin list found, waiting 5 seconds...");
        setTimeout(() => {
          getAsinLists();
        }, 5000);
      }
      return res?.data?.asin_lists;
    });
    setAsinLists(data);
    filterByTab(data, tab);

    console.log(data);
  };
  const mergeAsinLists = async (ids) => {
    const data = await requestHandler(
      "post",
      `/asinLists/merge`,
      { asinListIds: ids },
      null,
      null,
      null
    ).then((res) => {
      console.log(res);
      if (res?.status === 200) {
        setTab("Merged");
        getAsinLists();
        toast.success(translate("Asin lists merged successfully!"));
      } else {
        toast.error(translate("Failed to merge asin lists!"));
      }
    });
  };
  const deleteAsinLists = async (ids) => {
    const data = await requestHandler(
      "delete",
      `/asinLists/multiple`,
      { asinListIds: ids },
      null,
      null,
      null
    ).then((res) => {
      console.log(res);
      if (res?.status === 200) {
        getAsinLists();
        toast.success(translate("Asin lists deleted successfully!"));
      } else {
        toast.error(translate("Failed to delete asin lists!"));
      }
    });
  };

  const downloadSelectedAsinLists = async () => {
    const data = await requestHandler(
      "post",
      `/asinLists/getMultiple`,
      { asinListIds: selectedAsinLists },
      null,
      null,
      null
    ).then((res) => {
      console.log(res);
      if (res?.status === 200) {
        downloadAsinListExcel(res?.data, "Selected AsinLists");

        toast.success(translate("Asin lists downloaded successfully!"));
      } else {
        toast.error(translate("Failed to download asin lists!"));
      }
    });
  };
  const startComparisonProcess = async (id) => {
    const data = await requestHandler(
      "put",
      `/actions/startComparison/${id}`,
      null,
      null,
      null,
      null
    ).then((res) => {
      console.log(res);
      if (res?.status === 200) {
        getAsinLists();
        toast.success(translate("Comparison process started successfully!"));
      } else {
        toast.error(translate("Failed to start comparison process!"));
      }
    });
  };
  const stopComparisonProcess = async (id) => {
    const data = await requestHandler(
      "put",
      `/actions/stopComparison/${id}`,
      null,
      null,
      null,
      null
    ).then((res) => {
      console.log(res);

      getAsinLists();
      toast.success(translate("Comparison process stopped successfully!"));
    });
  };
  const restartComparisonProcess = async (id) => {
    const data = await requestHandler(
      "put",
      `/actions/restartComparison/${id}`,
      null,
      null,
      null,
      null
    ).then((res) => {
      console.log(res);
      if (res?.status === 200) {
        getAsinLists();
        toast.success(translate("Comparison process restarted successfully!"));
      } else {
        toast.error(translate("Failed to restart comparison process!"));
      }
    });
  };
  const getAsinListAsins = async (id) => {
    const data = await requestHandler(
      "get",
      `/asinLists/${id}`,
      null,
      null,
      null,
      null
    ).then((res) => {
      console.log(res);
      return res?.data;
    });
    return data;
  };

  useEffect(() => {
    getAsinLists();
  }, []);

  useEffect(() => {
    filterByTab(asinLists, tab);
  }, [tab]);

  useEffect(() => {
    filterByQuery(query);
  }, [query]);

  // IMPORT FILE
  const [modalUploadOpen, setModalUploadOpen] = useState(false);
  const [copiedItems, setCopiedItems] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const { Dragger } = Upload;
  const { TextArea } = Input;

  const handleImport = (e) => {
    //read the file using exceljs
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(data);
      const worksheet = workbook.getWorksheet(1);
      let asinList = [];
      worksheet.eachRow((row, rowNumber) => {
        if (rowNumber !== 1) {
          asinList.push(row.values[1]);
        }
      });
      console.log(asinList);
      if (asinList.length === 0) {
        toast.error(translate("No Asin found!"));
        setModalUploadOpen(false);
        return;
      }
      setCopiedItems(asinList.join("\n"));
    };
    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    console.log(copiedItems);
    if (
      copiedItems
        .split("\n")
        .map((i) => (checkIfInputIsAsin(i) ? i : null))
        .filter((i) => i).length > 1
    ) {
      saveAsinList(
        "imported Asin List",
        copiedItems
          .split("\n")
          .map((i) => (checkIfInputIsAsin(i) ? i : null))
          .filter((i) => i)
      );
    }
  }, [copiedItems]);

  const saveAsinList = async (name = "imported Asin List", asinList) => {
    if (asinList.length === 0) {
      toast.error(translate("No Asin found!"));
      setModalUploadOpen(false);
      return;
    }
    if (asinList.length > 5000) {
      const splittedArray = splitArrayIntoChunks(asinList, 5000);
      for (let i = 0; i < splittedArray.length; i++) {
        await saveAsinList(
          `${name}-(${i + 1}/${splittedArray.length})`,
          splittedArray[i]
        );
      }
      return false;
    }
    const response = await requestHandler("post", "/asinLists", {
      name,
      from: "Import",
      asinArr: asinList,
    });
    if (response?.status === 200) {
      getAsinLists();
      toast.success(translate("Asin list saved successfully!"));
      setCopiedItems("");
      setModalUploadOpen(false);
    } else {
      toast.error(translate("Failed to save asin list!"));
      setCopiedItems("");
      setModalUploadOpen(false);
    }
  };

  const props = {
    name: "file",
    multiple: false,
    beforeUpload: (file) => {
      console.log(file);
      const isExcel =
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel";
      if (!isExcel) {
        toast.error(translate("You can only upload Excel files!"));
        return Upload.LIST_IGNORE;
      }
      return true;
    },
    onChange(info) {
      const { status } = info.file;
      setFileList(info.fileList);
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        toast.success(translate("File uploaded successfully.!"));
      } else if (status === "error") {
        toast.error(translate("File upload failed!"));
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const uploadApply = () => {
    if (fileList.length === 0 && !copiedItems) {
      toast.error(translate("No files were uploaded!"));
      return;
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setModalUploadOpen(false);
    }, 3000);
  };

  const uploadCancel = () => {
    setCopiedItems("");
    setFileList([]);
    setModalUploadOpen(false);
  };
  return (
    <>
      <Helmet>
        <title>{translate("Asin 360°")}</title>
      </Helmet>
      <ComparsionDrawer
        isComparisonDrawer={isComparisonDrawer}
        setComparisonDrawer={setComparisonDrawer}
        selectedAsinList={selectedAsinList}
        restartComparisonProcess={restartComparisonProcess}
        getAsinListAsins={getAsinListAsins}
        startComparisonProcess={startComparisonProcess}
        stopComparisonProcess={stopComparisonProcess}
      />

      <Modal
        title={translate("Upload ASIN")}
        centered
        visible={modalUploadOpen}
        onOk={() => setModalUploadOpen(false)}
        onCancel={() => setModalUploadOpen(false)}
        footer={false}
      >
        <div className="card__file">
          <div className="drop_box__file">
            <h4>{translate("Select File Here.")}</h4>
            <p>{translate("Files Supported: .XLSX")}</p>
            <div className="upload__div">
              <input
                type="file"
                accept=".csv,.xlsx,.xls"
                onChange={handleImport}
              />
              <button className="btn__file">{translate("Choose File")}</button>
            </div>
          </div>
        </div>

        <div className="or__arrea">
          <div className="or__cntent">
            <span></span>
            {translate("OR")}
            <span></span>
          </div>
        </div>

        <div className="coppyy_Asin_Area">
          <p className="ant-copiedd-text">
            {translate("Paste the ASIN List You Copied.")}
          </p>
          <TextArea
            value={copiedItems}
            onChange={(e) => setCopiedItems(e.target.value)}
            placeholder={translate("Paste the ASIN List You Copied Here..")}
            autoSize={{
              minRows: 5,
              maxRows: 6,
            }}
          />
        </div>
      </Modal>

      <div className="outer__inner new__page">
        <div className="onex_wl bg_white">
          <div className="onex_wl__wrapper">
            <div className="upload__head hhg">
              <div className="ttle_c">
                <h2 className="upload__title h2">Asin 360°</h2>
                <div className="keys__info">
                  {translate(
                    "Manage, compare and view detailed analysis of your Asin lists."
                  )}
                </div>
              </div>
              <div className="comparsion__hdr">
                <div className="cmp_lft">
                  <div className="cmp_inf">
                    <h5>{translate("Total Saved Asin List")}</h5>
                    <span>{asinListInfo?.total ? asinListInfo?.total : 0}</span>
                  </div>
                  <div className="cmp_inf">
                    <h5>{translate("Total All Asins")}</h5>
                    <span>
                      {asinListInfo?.totalAsins ? asinListInfo?.totalAsins : 0}
                    </span>
                  </div>
                  <div className="cmp_inf">
                    <h5>{translate("Asin Tokens")}</h5>
                    <span>
                      {asinListInfo?.asinToken ? asinListInfo?.asinToken : 0}
                    </span>
                  </div>
                  <div className="cmp_inf">
                    <h5>{translate("Comparison Tokens")}</h5>
                    <span>
                      {asinListInfo?.comparisonToken
                        ? asinListInfo?.comparisonToken
                        : 0}
                    </span>
                  </div>
                </div>
              </div>
              {/* <a
                    className="button-stroke button-small upload__button"
                    href="#!"
                  >
                    More filter
                  </a> */}
            </div>
            <div className="onex_wl__list">
              <div className="keys__enabled d_block">
                <div className="keys__list">
                  <div className="keys__item ss">
                    <div className="activity__top ss">
                      <div className="nav">
                        <button
                          className={`nav__link cursorp ${
                            tab === "All" ? " active" : " "
                          }`}
                          onClick={() => setTab("All")}
                        >
                          {translate("All Asins")}
                        </button>
                        <button
                          className={`nav__link cursorp ${
                            tab === "Extractor" ? " active" : " "
                          }`}
                          onClick={() => setTab("Extractor")}
                        >
                          Asin Extractor
                        </button>
                        <button
                          className={`nav__link cursorp ${
                            tab === "SPY" ? " active" : " "
                          }`}
                          onClick={() => setTab("SPY")}
                        >
                          Asin SPY
                        </button>
                        <button
                          className={`nav__link cursorp ${
                            tab === "Imported" ? " active" : " "
                          }`}
                          onClick={() => setTab("Imported")}
                        >
                          {translate("Imported Asins")}
                        </button>
                        <button
                          className={`nav__link cursorp ${
                            tab === "Merged" ? " active" : " "
                          }`}
                          onClick={() => setTab("Merged")}
                        >
                          {translate("Merged Asin Lists")}
                        </button>
                      </div>

                      <div className="right_items">
                        <form className="activity__form">
                          <input
                            className="activity__input"
                            type="text"
                            name="search"
                            placeholder={translate("Search")}
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            required=""
                          />
                          <button className="activity__result">
                            <svg className="icon icon-search">
                              <use xlinkHref="#icon-search"></use>
                            </svg>
                          </button>
                        </form>
                        {/* <div className="onex_ts__select tablet-show">
                          <div className="select__field">
                            <select className="select nice-select  app d_block">
                              <option>Filter item #1</option>
                              <option>Filter item #2</option>
                              <option>Filter item #3</option>
                            </select>
                          </div>
                        </div>
                       <div className="onex_ts__select">
                          <div className="select__field">
                            <select className="select nice-select  app d_block">
                              <option>Filter item #1</option>
                              <option>Filter item #2</option>
                              <option>Filter item #3</option>
                            </select>
                          </div>
                        </div> */}
                        <div className="activity__item activity__item_calendar js-activity-item ffvv">
                          <button
                            onClick={() => setModalUploadOpen(true)}
                            className="button-stroke button-small activity__button js-activity-button"
                          >
                            <span>
                              <FeatherIcon icon={"upload-cloud"} />{" "}
                              {translate("Import")}
                            </span>
                          </button>
                          <button
                            onClick={() => {
                              downloadSelectedAsinLists();
                            }}
                            disabled={!isDownloadActive}
                            className="button-stroke button-small activity__button js-activity-button"
                          >
                            <span>
                              <FeatherIcon icon={"download"} />{" "}
                              {translate("Download")}
                            </span>
                          </button>
                          <button
                            onClick={getAsinLists}
                            className="button button-small activity__button js-activity-button"
                          >
                            <span>
                              <FeatherIcon icon={"repeat"} />{" "}
                              {translate("Refresh")}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <AsinsTable
                      isComparisonDrawer={isComparisonDrawer}
                      setComparisonDrawer={setComparisonDrawer}
                      data={bufferAsinLists?.map((i) => {
                        return {
                          ...i,
                          key: i.id,
                        };
                      })}
                      setSelectedAsinLists={setSelectedAsinLists}
                      selectedAsinLists={selectedAsinLists}
                      setSelectedAsinList={setSelectedAsinList}
                      selectedAsinList={selectedAsinList}
                      mergeAsinLists={mergeAsinLists}
                      deleteAsinLists={deleteAsinLists}
                      startComparisonProcess={startComparisonProcess}
                      stopComparisonProcess={stopComparisonProcess}
                      getAsinListAsins={getAsinListAsins}
                      refresh={getAsinLists}
                      setDownloadActive={setDownloadActive}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SavedAsins;
