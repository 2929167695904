import React, { useEffect } from "react";
import { Button, Drawer } from "antd";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Select from "../../../common/form_elements/select";
import Input from "../../../common/form_elements/input";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import translate from "../../../shared/utils/translations";

const NewEmailDrawer = ({
  isEmailDrawer,
  setEmailDrawer,
  setUserListDrawer,
  formData,
  setFormData,
}) => {
  const [value, setValue] = useState("");
  useEffect(() => {
    console.log("formData", formData);
    setFormData({ ...formData, message: value });
  }, [value]);

  return (
    <>
      <Drawer
        title={translate("Create Email")}
        placement="right"
        onClose={() => setEmailDrawer(false)}
        open={isEmailDrawer}
        width={"50%"}
      >
        <>
          <div className="question__form" style={{ maxWidth: "100%" }}>
            <div className="question__fieldset cvb">
              <Select
                label={translate("From")}
                options={[
                  "Select email",
                  "info@kepicker.com",
                  "noreply@kepicker.com",
                ]}
                customClass="mt_40"
                onChange={(e) => {
                  console.log("e.target.value", e.target.value);
                  setFormData({ ...formData, from: e.target.value });
                }}
              />

              <Input
                id="f_name"
                name="f_name"
                label={translate("Subject")}
                type="text"
                placeholder={translate("Subject")}
                customClass="mt_40"
                onChange={(e) => {
                  console.log("e.target.value", e);
                  setFormData({
                    ...formData,
                    subject: e,
                  });
                }}
              />

              <div className="field mt_40">
                <div className="field__label">{translate("Message")}</div>
                <div className="field__wrap">
                  <ReactQuill
                    theme="snow"
                    value={value}
                    onChange={setValue}
                    modules={{
                      toolbar: [
                        [{ header: "1" }, { header: "2" }, { font: [] }],
                        [{ size: [] }],
                        ["bold", "italic", "underline", "strike", "blockquote"],
                        [
                          { list: "ordered" },
                          { list: "bullet" },
                          { indent: "-1" },
                          { indent: "+1" },
                        ],
                        ["link"],
                        ["clean"],
                      ],
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="question__btns">
              <button
                onClick={() => {
                  if (
                    formData.subject &&
                    formData.message &&
                    formData.from !== "Select email"
                  ) {
                    setUserListDrawer(true);
                  } else {
                    toast.error("Please fill in all fields");
                  }
                }}
                className="button-small question__button app"
              >
                {translate("Next")}
              </button>
            </div>
          </div>
        </>
      </Drawer>
    </>
  );
};

export default NewEmailDrawer;
