import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import CurrentPlanHistory from "./_history";
import FeatherIcon from "feather-icons-react";
import { requestHandler } from "../../../shared/utils/helpers";
import translate from "../../../shared/utils/translations";
import toast, { Toaster } from "react-hot-toast";
import Swal from "sweetalert2";
import { Helmet } from "react-helmet";
import AddNewCard from "./_addNewCard";

const CurrentPlan = ({ setPage }) => {
  useEffect(() => {
    setPage("dashboard-client");
    localStorage.setItem("dashboard-type", "dashboard-client");
    currentLanguage(localStorage.getItem("lang") || "EN");
  });

  let navigate = useNavigate();

  const [isOrder, setOrder] = useState(false);
  const [isInvoice, setInvoice] = useState(false);
  const [payments, setPayments] = useState([]);
  const [user, setUser] = useState({});
  const [cards, setCards] = useState([]);
  const [newCardDrawer, setNewCardDrawer] = useState(false);

  const getPayments = async () => {
    const payments = await requestHandler(
      "get",
      "/balance",
      null,
      true,
      navigate,
      "/login"
    ).then((res) => res?.data);
    console.log("payments", payments);
    setPayments(payments?.balance);
    setCards(payments?.paymentMethods);
    setUser({
      ...payments?.user,
      default_payment_method:
        payments?.stripeCustomerData?.invoice_settings?.default_payment_method,
    });
  };
  useEffect(() => {
    getPayments();
  }, []);
  const fetchInvoice = async (paymentId) => {
    const invoice = await requestHandler(
      "get",
      `/balance/${paymentId}`,
      null,
      true,
      navigate,
      "/login"
    ).then((res) => res?.data);
    console.log("invoice", invoice);
    return invoice;
  };
  const cancelSubscription = async () => {
    Swal.fire({
      icon: "warning",
      title: translate("Unsubscribe"),
      text: translate(
        "Are you sure to cancel current subscription? (You will be able to use your current plan until the end of the next billing period)"
      ),
      confirmButtonText: translate("Unsubscribe"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: translate("Please wait.."),
          html: translate("Subscription cancellation started."),
          timer: 2000,
          timerProgressBar: true,
          didOpen: async () => {
            await requestHandler(
              "post",
              "/payment/subscription/cancel",
              { subscriptionId: user?.subscription_id },
              true,
              navigate,
              "/login"
            ).then((res) => {
              console.log("res", res);
              window.location.reload();
            });

            Swal.showLoading();
          },
          willClose: () => {
            toast.success(translate("Subscription cancellation successful!"));
            Swal.fire(translate("Unsubscribe Successful!"), "", "success");
          },
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            console.log("I was closed by the timer");
          }
        });
      } else if (result.isDenied) {
      }
    });
  };
  const [currentLang, currentLanguage] = useState({});

  const cancelNextPlan = () => {
    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Are you sure about performing this action?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: translate("Please wait.."),
          html: translate("Subscription cancellation started."),
          timer: 2000,
          timerProgressBar: true,
          didOpen: async () => {
            await requestHandler(
              "post",
              "/payment/subscription/cancelNextPlan",
              { subscriptionId: user?.subscription_id },
              true,
              navigate,
              "/login"
            ).then((res) => {
              console.log("res", res);
              window.location.reload();
            });

            Swal.showLoading();
          },
          willClose: () => {
            toast.success(translate("Subscription cancellation successful!"));
            Swal.fire(translate("Unsubscribe Successful!"), "", "success");
          },
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            console.log("I was closed by the timer");
          }
        });
      } else if (result.isDenied) {
      }
    });
  };

  const setCardAsDefault = async (paymentMethodId) => {
    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Are you sure about performing this action?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await requestHandler(
          "post",
          "/payment/selectDefaultPaymentMethod",
          { paymentMethodId, customerId: user?.stripe_customer_id },
          true,
          navigate,
          "/login"
        ).then((res) => {
          console.log("res", res);
          getPayments();
        });
        toast.success(
          translate("Your action has been completed successfully!")
        );
      }
    });
  };
  const deleteCard = async (paymentMethodId) => {
    Swal.fire({
      icon: "warning",
      title: translate("Are you sure?"),
      text: translate("Are you sure about performing this action?"),
      confirmButtonText: translate("Yes"),
      cancelButtonText: translate("Cancel"),
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await requestHandler(
          "post",
          "/payment/deletePaymentMethod",
          { paymentMethodId },
          true,
          navigate,
          "/login"
        ).then((res) => {
          console.log("res", res);
          getPayments();
        });
        toast.success(translate("Card deletion successful!"));
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>{translate("Plan & Billing")}</title>
      </Helmet>
      <AddNewCard
        isPaymentDrawer={newCardDrawer}
        setPaymentDrawer={setNewCardDrawer}
        refresh={getPayments}
      ></AddNewCard>
      <div className="outer__inner">
        <div className="onex_wl bg_white">
          <div className="onex_wl__wrapper">
            <div className="upload__head">
              <h2 className="upload__title h2">
                {translate("Plan & Billing")}
              </h2>
              {/* <a
                  className="button-stroke button-small upload__button"
                  href="#!"
                >
                  More filter
                </a> */}
            </div>
            <div className="keys__info">
              {translate(
                "You can switch plans according to your needs - we're so glad you're here."
              )}
            </div>
            <div className="onex_wl__list">
              <div className="keys__enabled d_block">
                <div className="keys__list">
                  <div className="keys__item">
                    <div className="sessions">
                      <div className="sessions__section">
                        <div className="plan_sc">
                          <div className="plan_top">
                            <div className="plan_title">
                              <div className="t_area">
                                <img
                                  className={`pln__imgg ss ${
                                    user?.user_tier === "free" ? " show" : " "
                                  }`}
                                  src="/assets/img/free_badge.svg"
                                ></img>
                                <img
                                  className={`pln__imgg ss ${
                                    user?.user_tier === "basic" ? " show" : " "
                                  }`}
                                  src="/assets/img/basic_badge.svg"
                                ></img>
                                <img
                                  className={`pln__imgg ss ${
                                    user?.user_tier === "plus" ? " show" : " "
                                  }`}
                                  src="/assets/img/plus_badge.svg"
                                ></img>
                                <img
                                  className={`pln__imgg ss ${
                                    user?.user_tier === "pro" ? " show" : " "
                                  }`}
                                  src="/assets/img/pro_badge.svg"
                                ></img>

                                <span className="t_i">
                                  {user?.user_tier?.capitalize()}{" "}
                                  {translate("plan")}
                                </span>
                                <span className="t_b">
                                  {translate("Monthly")}
                                </span>
                              </div>
                              <p className="nxt_pdue">
                                {translate("Next payment due")} -{" "}
                                <strong>
                                  {user?.next_payment_due
                                    ? new Date(
                                        user?.next_payment_due
                                      ).toLocaleDateString()
                                    : "..."}
                                </strong>
                              </p>
                              <p className="nxt_pdue tkn">
                                {translate(
                                  "Extra purchased comparison tokens expiry date"
                                )}{" "}
                                -{" "}
                                <strong>
                                  {user?.next_payment_due
                                    ? new Date(
                                        user?.next_payment_due
                                      ).toLocaleDateString()
                                    : "..."}
                                </strong>
                              </p>
                              {user?.next_tier_id ? (
                                <>
                                  <div className="warn__pln">
                                    <FeatherIcon icon={"alert-circle"} />
                                    <p className="nxt_pdue tkn">
                                      <span>
                                        {translate("Your next plan")}:{" "}
                                        <strong>
                                          {user?.next_user_tier?.capitalize()}
                                        </strong>
                                      </span>
                                      <span>
                                        {translate("will be updated at")} -{" "}
                                        <strong>
                                          {user?.next_payment_due
                                            ? new Date(
                                                user?.next_payment_due
                                              ).toLocaleDateString()
                                            : "..."}
                                        </strong>
                                      </span>
                                    </p>
                                    <button
                                      onClick={() => cancelNextPlan()}
                                      className="cnc_nxtt"
                                    >
                                      {translate("Cancel Next Plan")}
                                    </button>
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}

                              {currentLang === "TR" ? (
                                <>
                                  <p>
                                    Plan detaylarınız için{" "}
                                    <strong>
                                      <NavLink to="/pricing">
                                        {translate("Plan page")}
                                      </NavLink>
                                    </strong>
                                    'nı ziyaret edebilirsiniz.
                                  </p>
                                </>
                              ) : currentLang === "DE" ? (
                                <>
                                  <p>
                                    Einzelheiten zum Plan finden Sie auf der{" "}
                                    <strong>
                                      <NavLink to="/pricing">
                                        {translate("Plan page")}
                                      </NavLink>
                                    </strong>
                                    .
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p>
                                    You can visit the{" "}
                                    <strong>
                                      <NavLink to="/pricing">
                                        {translate("Plan page")}
                                      </NavLink>
                                    </strong>{" "}
                                    for plan details.
                                  </p>
                                </>
                              )}

                              {user?.user_tier !== "pro" && (
                                <>
                                  <NavLink className="up_btn" to="/pricing">
                                    <span>{translate("Upgrade plan")}</span>{" "}
                                    <FeatherIcon icon={"arrow-up-right"} />
                                  </NavLink>
                                  <div className="warn__card plln">
                                    <FeatherIcon icon={"alert-circle"} />
                                    {translate(
                                      "After upgrading your plan, in the extension; Update your plugin by clicking the Settings → Other Settings (At the bottom) → Synchronize button."
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="plan_value">
                              <span className="t_i">
                                $
                                {user?.tier_price
                                  ? parseInt(user?.tier_price) / 100
                                  : 0}
                              </span>
                              <span className="t_b">
                                {translate("per month")}
                              </span>

                              <div className="cancel__sub">
                                {user?.subscription_id === "canceled" ? (
                                  <p>{translate("Subscription Cancelled")}</p>
                                ) : !user?.subscription_id ? (
                                  <></>
                                ) : (
                                  <button
                                    className="button-stroke button-small sessions__button"
                                    onClick={() => cancelSubscription()}
                                  >
                                    {translate("Cancel Subscription")}
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>

                          {cards?.length > 0 && (
                            <div className="debit__card_kp">
                              <div className="debit__card_kp_header">
                                <p>{translate("Saved Cards")}</p>

                                {user?.stripe_customer_id && (
                                  <button
                                    onClick={() => {
                                      setNewCardDrawer(true);
                                    }}
                                    className="button-stroke button-small activity__button js-activity-button cc link"
                                  >
                                    {translate("Add New Card")}
                                    <i>
                                      <FeatherIcon icon={"plus"} />
                                    </i>
                                  </button>
                                )}
                              </div>
                              <div className="all_Cards">
                                {cards?.map((i) => {
                                  const isDefault =
                                    user?.default_payment_method === i.id;
                                  return (
                                    <div
                                      key={i?.card?.last4}
                                      className="card__bdoyy"
                                    >
                                      <div className="card__actionss">
                                        {isDefault && (
                                          <span className="active">
                                            {translate("Default Card")}
                                          </span>
                                        )}

                                        <div className="rght__itms">
                                          {!isDefault && (
                                            <button
                                              onClick={() => {
                                                setCardAsDefault(i.id);
                                              }}
                                              className="button-stroke button-small activity__button js-activity-button cc link ssmm"
                                            >
                                              {translate("Set as Default")}
                                              <FeatherIcon
                                                icon={"check-circle"}
                                              />
                                            </button>
                                          )}

                                          {!isDefault && (
                                            <>
                                              <div className="dividder"></div>
                                              <button
                                                onClick={() => {
                                                  deleteCard(i.id);
                                                }}
                                                className="button-stroke button-small activity__button js-activity-button cc link ssmm"
                                              >
                                                {translate(`Delete`)}
                                                <FeatherIcon icon={"trash"} />
                                              </button>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                      <div
                                        className={`credit-card-kp  selectable ${i?.card?.brand}`}
                                      >
                                        <div className="credit-card-kp-last4">
                                          {i?.card?.last4}
                                        </div>
                                        <div className="credit-card-kp-expiry">
                                          {i?.card?.exp_month +
                                            "/" +
                                            i?.card?.exp_year}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="sessions__btns"></div>
                      </div>
                      <div className="sessions__section mm">
                        <div className="bread_ccx">
                          <div className="bread_info">
                            <div className="sessions__title mm">
                              {translate("Billing and invoicing")}
                            </div>
                            <div className="sessions__desc">
                              {translate("Manage your plan details")}
                            </div>
                          </div>
                          {/* <button className="button-stroke sessions__button">
                      Download all
                    </button> */}
                        </div>

                        <CurrentPlanHistory
                          isInvoice={isInvoice}
                          setInvoice={setInvoice}
                          data={payments}
                          fetchInvoice={fetchInvoice}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CurrentPlan;
