import React from "react";
import FeatherIcon from "feather-icons-react";
import translate from "../../../../shared/utils/translations";

const DimensionsInfos = ({
  itemDimensions,
  packageDimensions,
  itemWeight,
  packageWeight,
}) => {
  return (
    <>
      <div className="product__dmns_info">
        <img src="/assets/img/icons8-front-view-64.png"></img>
        <div className="dmns_item">
          <h4>{translate("Item Dimensions")}</h4>
          <span>{itemDimensions}</span>
        </div>
        <div className="dmns_item">
          <h4>{translate("Package Dimensions")}</h4>
          <span>{packageDimensions}</span>
        </div>
        <div className="dmns_item">
          <h4>{translate("Item Weight")}</h4>
          <span>{itemWeight} Kg</span>
        </div>
        <div className="dmns_item">
          <h4>{translate("Package Weight")}</h4>
          <span>{packageWeight} Kg</span>
        </div>
      </div>
    </>
  );
};

export default DimensionsInfos;
