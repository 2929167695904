import React, { useEffect } from "react";
import { Drawer } from "antd";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import Select from "../../../common/form_elements/select";
import Input from "../../../common/form_elements/input";
import SelectWithOptions from "../../../common/form_elements/select_with_modified_options";
import { requestHandler } from "../../../shared/utils/helpers";
import translate from "../../../shared/utils/translations";

const TiersDrawer = ({ selectedTier, setDrawer, isDrawer, refresh }) => {
  console.log(selectedTier);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (selectedTier?.id) {
      setFormData(selectedTier);
    }
  }, [selectedTier]);

  const submit = () => {
    setBtnLoading(true);
    requestHandler(
      "put",
      `/admin/tiers/${formData?.id}`,
      formData,
      true,
      null,
      "/login"
    ).then((res) => {
      console.log(res);
      setDrawer(false);
      setBtnLoading(false);
      refresh();
    });
  };

  const onChange = (e, name) => {
    console.log("onchange", e, name);
    setFormData({ ...formData, [name]: e });
  };

  return (
    <>
      <Drawer
        title={translate("User Form")}
        placement="right"
        onClose={() => setDrawer(false)}
        open={isDrawer}
        width={"40%"}
      >
        <>
          <div className="question__form">
            <div className="question__fieldset cvb">
              <Input
                id="tier_slug"
                name="tier_slug"
                label="tier_slug"
                type="text"
                placeholder="tier_slug"
                customClass="mt_40"
                onChange={onChange}
                value={formData?.tier_slug}
              />
              <Input
                id="tier_description"
                name="tier_description"
                label="tier_description"
                type="text"
                placeholder="tier_description"
                customClass="mt_40"
                onChange={onChange}
                value={formData?.tier_description}
              />
              <Input
                id="tier_price"
                name="tier_price"
                label="tier_price on cents"
                type="integer"
                placeholder="tier_price"
                customClass="mt_40"
                onChange={onChange}
                value={formData?.tier_price}
              />
              <Input
                id="tier_image"
                name="tier_image"
                label="tier_image"
                type="text"
                placeholder="tier_image"
                customClass="mt_40"
                onChange={onChange}
                value={formData?.tier_image}
              />
              <Input
                id="comparison_token_to_add"
                name="comparison_token_to_add"
                label="comparison_token_to_add"
                type="text"
                placeholder="comparison_token_to_add"
                customClass="mt_40"
                onChange={onChange}
                value={formData?.comparison_token_to_add}
              />
              <Input
                id="asin_token_to_add"
                name="asin_token_to_add"
                label="asin_token_to_add"
                type="text"
                placeholder="asin_token_to_add"
                customClass="mt_40"
                onChange={onChange}
                value={formData?.asin_token_to_add}
              />
              <Input
                id="price_id"
                name="price_id"
                label="price_id"
                type="text"
                placeholder="price_id"
                customClass="mt_40"
                onChange={onChange}
                value={formData?.price_id}
              />
              <Input
                id="product_id"
                name="product_id"
                label="product_id"
                type="text"
                placeholder="product_id"
                customClass="mt_40"
                onChange={onChange}
                value={formData?.product_id}
              />
            </div>
            <div className="question__btns">
              <button
                className="button-small question__button app"
                onClick={submit}
              >
                {translate("Save")}
                {isBtnLoading && <span className="btn-spinner"></span>}
              </button>
            </div>
          </div>
        </>
      </Drawer>
    </>
  );
};

export default TiersDrawer;
