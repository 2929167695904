import React, { useEffect } from "react";

const Comfetti = () => {
  useEffect(() => {
    const colors = [
      "#3b71fe",
      "#51ae9d",
      "#ffe1ef",
      "#e9defe",
      "#82d19e",
      "#0142eb",
    ];

    for (let i = 0; i < 200; i++) {
      const randomRotation = Math.floor(Math.random() * 360);
      const randomWidth = Math.floor(
        Math.random() *
          Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
      );
      const randomHeight = Math.floor(
        Math.random() *
          Math.max(
            document.documentElement.clientHeight,
            window.innerHeight || 0
          )
      );
      const randomAnimationDelay = Math.floor(Math.random() * 10);
      const randomColor = colors[Math.floor(Math.random() * colors.length)];

      const confetti = document.createElement("div");
      confetti.className = "confetti";
      confetti.style.top = `${randomHeight}px`;
      confetti.style.left = `${randomWidth}px`;
      confetti.style.backgroundColor = randomColor;
      confetti.style.transform = `skew(15deg) rotate(${randomRotation}deg)`;
      confetti.style.animationDelay = `${randomAnimationDelay}s`;

      document.getElementById("confetti-wrapper").appendChild(confetti);
    }

    return () => {
      const confettiWrapper = document.getElementById("confetti-wrapper");
      if (confettiWrapper) {
        confettiWrapper.innerHTML = "";
      }
    };
  }, []);

  return (
    <>
      <div id="confetti-wrapper" className="confetti-wrapper"></div>
    </>
  );
};

export default Comfetti;
